import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-elective-courses',
  templateUrl: './elective-courses.component.html',
  styleUrls: ['./elective-courses.component.scss']
})
export class ElectiveCoursesComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1Uj0iREYtYW_jmq_7RzoG_3rcU43thGTo/preview', 
    'https://drive.google.com/file/d/10W4bXUb72U4teTl3vFDV4Cp-CMTAXQ1h/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('2022-23');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '2022-23') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2021-22') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
