import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-eighteen-nineteen',
  templateUrl: './c3-eighteen-nineteen.component.html',
  styleUrls: ['./c3-eighteen-nineteen.component.scss']
})
export class C3EighteenNineteenComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Reports/';
  result:{title:string,link:string}[]=[
    {
      title:'SWACHTTA PAKHWARA',
      link:this.c343Url + '1_2018-19-NCC_SWACHTTA-PAKHWARA_report.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c343Url + '2_2018-19-NCC_CLEANLINESS-DRIVE_report.pdf'
    },
    {
      title:'SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN',
      link:this.c343Url + '3_2018-19-NCC_SURGICAL-STRIKE _report.pdf'
    },
    {
      title:'DRUG CONTROL RALLY',
      link:this.c343Url + '4_2018-19-NCC_DRUG-CONTROL-RALLY_report.pdf'
    },
    {
      title:'SADAK SURAKSHA PRIKSHA',
      link:this.c343Url + '5_2018-19-NCC_SADAK-SURAKSHA-PRIKSHA_report.pdf'
    },
    {
      title:'BLOOD DONATION RALLY',
      link:this.c343Url + '6_2018-19-NCC__BLOOD-DONATION-RALLY_report.pdf'
    },
    {
      title:'BLOOD DONATION CAMP',
      link:this.c343Url + '7_2018-19-NCC_BLOOD-DONATION-CAMP_report.pdf'
    },
    {
      title:'FLAG DAY',
      link:this.c343Url + '8_2018-19-NCC_ FLAG-DAY_report.pdf'
    },
    {
      title:'TRAFFIC AWARNESS RALLY',
      link:this.c343Url + '9_2018-19-NCC__TRAFFIC-AWARNESS-RALLY_report.pdf'
    },
    {
      title:'WORLD WATER DAY',
      link:this.c343Url + '10_2018-19-NCC_WORLD-WATER-DAY_report.pdf'
    },
    {
      title:'BETI BACHAO BETI PADHAO RALLY',
      link:this.c343Url + '11_2018-19-NCC __BETI-BACHAO-BETI-PADHAO-RALLY_report.pdf'
    },
    {
      title:'ATC CAMP',
      link:this.c343Url + '12_2018-19-NCC_ATC-CAMP_report.pdf'
    },
    {
      title:'Helping the Poor & Needy person',
      link:this.c343Url + '13_2018-19-NSS_Helping-the-Poor_ Needy-person_report.pdf'
    },
    {
      title:'National level Road Safety Week',
      link:this.c343Url + '14_2018-19-NSS_National-level-Road-Safety-Week_report.pdf'
    },
    {
      title:'State level Road Safety Seminar',
      link:this.c343Url + '15_2018-19-NSS_State-level-Road-Safety_report.pdf'
    },
    {
      title:'Health and Blood Checkup Camp',
      link:this.c343Url + '16_2018-19-NSS_Health-and-Blood-Checkup-Camp_report.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
