<div id="showcase">
  <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
</div>
<div id="heading">
  <h1 style="text-transform: capitalize;">{{(menuName.length>25) ? (menuName | slice:0:25)+'...' : (menuName)}}</h1>
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
        <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{menuName}}</li>
      </ol>
    </nav>
</div>
<div id="blog">
    <div class="container-fluid" *ngIf="showBlog">
        <!-- <div class="row">
            <div class="col-12 text-center">
                <h3 class="card-title">Blogs</h3>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 mt-5">
                
            </div>
           
        </div> -->
        <!-- Start Blog -->
        <section class="blog section" id="blog">
            <div class="container1">
              <div class="row1">
                <div class="section-title padd-15">
                  <h2>Latest Blog</h2>
                </div>
              </div>
              <div class="row1"  >
                <div class="blog-item padd-15 mx-auto" *ngFor="let blog of blogs">
                  <div class="blog-item-inner shadow-dark">
                    <div class="blog-img">
                      <img [src]="blog.imgUrl" alt="Blog">
                      <div class="blog-date">
                        {{blog.author}}
                      </div>
                    </div>
                    <div class="blog-info">
                      <h4 class="blog-title">{{blog.title}}</h4>
                     
                        <p class="blog-description" [innerHtml]="blog.shortDes"></p>
                      <!-- <p class="blog-description">I need a job to get experience, but I need experience to get a job. Either way, you need a resume, and what you don't need is to panic.</p> -->
                      <p class="blog-tags"><a [routerLink]="['/blog',blog.$key]" (click)="hideBlog()"> Read More...</a></p>
                    </div>
                  </div>
                </div> 
                <!-- End Blog-item-->
                <!-- <div class="blog-item padd-15 mx-auto">
                  <div class="blog-item-inner shadow-dark">
                    <div class="blog-img">
                      <img src="/assets/img/blog/1.jpg" alt="Blog">
                      <div class="blog-date">
                        4 jun 2020
                      </div>
                    </div>
                    <div class="blog-info">
                      <h4 class="blog-title">Cuts For Men</h4>
                      <p class="blog-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo 
                        quam, eum alias id, repellendus magni, quas.</p>
                      <p class="blog-tags">Tags : <a href="#"> cuts</a> , <a href="#"> men</a></p>
                    </div>
                  </div>
                </div>  -->
                <!-- End Blog-item-->
                <!-- <div class="row1"  >
                <div class="blog-item padd-15 mx-auto" >
                  <div class="blog-item-inner shadow-dark">
                    <div class="blog-img">
                      <img src="/assets/img/blog/1.jpg" alt="Blog">
                      <div class="blog-date">
                        By Ms. Anchal Malhotra
                      </div>
                    </div>
                    <div class="blog-info">
                      <h4 class="blog-title">Ways to build a resume when you have zero experience.</h4>
                      <p class="blog-description">How do you write a resume with no experience?</p>
                      <p class="blog-description">I need a job to get experience, but I need experience to get a job. Either way, you need a resume, and what you don't need is to panic.</p>
                      <p class="blog-tags"><a [routerLink]="['/menu-detail/blog','1']" (click)="hideBlog()"> Read More...</a></p>
                    </div>
                  </div>
                </div>  -->
                 <!-- End Blog-item-->
              </div>

            
            </div>
          </section>
          <!-- End Blog -->

         
    </div>
    <div  *ngIf="!showBlog">
        <router-outlet></router-outlet>
    </div>
</div>

