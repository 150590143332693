<button
  *ngIf="type === 'facebook'"
  (click)="share()" class="btn btn-primary">
  <i class="fab fa-facebook"></i>
</button>

<button
  *ngIf="type === 'twitter'"
  (click)="share()" class="btn btn-primary">
  <i class="fab fa-twitter"></i>
</button>

<button
  *ngIf="type === 'whatsapp'"
  (click)="share()" class="btn btn-success">
  <i class="fab fa-whatsapp"></i>
</button>