import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-sc-twotwo-twothree',
  templateUrl: './sc-twotwo-twothree.component.html',
  styleUrls: ['./sc-twotwo-twothree.component.scss']
})
export class ScTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1wiNXFw1Y_SzxQc3IX1uVJNeqju-iz4o3/preview', 
    'https://drive.google.com/file/d/19mBOCVXX0WN8DYwqgbljIZNCRAA0SMNP/preview' ,
    'https://drive.google.com/file/d/1U4ZZdCwqECkYrh9bNWgZLfBs_14pTdqB/preview', 
    'https://drive.google.com/file/d/1x5KP1tuexH0BFQnoZ9GAVODasBr121Bn/preview',
    'https://drive.google.com/file/d/1MNBhuefiEhGigMqspTBk_zMtNhKqW50d/preview',
    'https://drive.google.com/file/d/1mmrQJUEHTVILOcdpPM8-o9gpJx1rzkgK/preview',
    'https://drive.google.com/file/d/18pJGgJyqepp8M5qHFf-fub5qebaU1hpo/preview',
    'https://drive.google.com/file/d/1rYkduBu-JmFIgQKiBvVcMTAIpijTfeqe/preview',
    'https://drive.google.com/file/d/1qVPULrJ5AkXBHaIU1Ib0wmXrFRG98Deu/preview',
    'https://drive.google.com/file/d/1XUeCRGA5xN9Ek78If571Tlqf5FaX7BEz/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    
  }
  hideLoader() {
    this.isLoading = false;
  }
}
