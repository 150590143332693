import { Component, OnInit } from '@angular/core';
import 'owl.carousel';
declare var $:any;
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.carousel').carousel({
      pause:false
    })
  }

  
}
