import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-criteria-five',
  templateUrl: './criteria-five.component.html',
  styleUrls: ['./criteria-five.component.scss']
})
export class CriteriaFiveComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 4 ){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if(url.length === 5){
      this.showSideMenu=false;
      this.fullWidth=true;
      
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
