<div id="centre">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Research Facility</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            College also provides congenial environment for research activities through assistance of different kinds for carrying out the major and minor research projects.
                             This assistance is in the form of formulation and preparation of proposals, providing infrastructure, sharing library resources and facilitating the completion of the research projects.
                              This has resulted in completion of two post doctoral fellowships, three major research projects and four minor research projects.
                             A number of teachers are completing their major and minor researches.</p>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>