import { Component, OnInit } from '@angular/core';
import 'owl.carousel';
declare var $:any;
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  message:string[]=[
    'Will forever be grateful for this fun-filled, enriching experience and value my education at Guru Nanak Khalsa College. GNKC has an incredibly unique education system that meets every need! The after-college programs are immensely useful and provide opportunities to do other activities along with core studies. ',
  ];
  constructor() { }

  ngOnInit(): void {
    $('.owl-carousel').owlCarousel({
      loop:true,
      margin:10,
      nav:true,
      autoplay:true,
    autoplayTimeout:2000,
    autoplayHoverPause:true,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:2
          },
          1000:{
              items:3
          }
      }
  })
  }

}
