import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BlogItemComponent } from "src/app/view/menu-detail/blog/blog-item/blog-item.component";
import { BlogComponent } from "src/app/view/menu-detail/blog/blog.component";

const route:Routes=[
    {path:'',component:BlogComponent,children:[
        {path:':id',component:BlogItemComponent}
    ]},
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class BlogRoutingModule{}