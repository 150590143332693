<div id="about-us">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Enquiry Form</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Enquiry Form</li>
            </ol>
          </nav>
    </div>
    <div>
        <iframe frameborder="0" style="height:2000px;width:99%;border:none;" src='https://data.odpay.in/odforms/form/GNKCAdmissionEnquiryForm1/formperma/KrdlDpy3OiXL8wcvG5DR9qTvXj-gtmLlmO34NZvQLj8'></iframe>
    </div>
</div>