import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1224-nineteen',
  templateUrl: './c1224-nineteen.component.html',
  styleUrls: ['./c1224-nineteen.component.scss']
})
export class C1224NineteenComponent implements OnInit {
  c3321Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result: { title: string, link: string }[] = [
    {
      title: 'Application of Electronics_Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Application of Electronics_Certificates.pdf'
    },
    {
      title: 'Communication Skills Cerificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Communication Skills Cerificates.pdf'
    },
    {
      title: 'Digital Marketing certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Digital Marketing certificates.pdf'
    },
    {
      title: 'Ecology Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Ecology Certificates.pdf'
    },
    {
      title: 'English and Soft skills Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_English and Soft skills Certificates.pdf'
    },
    {
      title: 'Financial Marketing Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Financial Marketing Certificates.pdf'
    },

    {
      title: 'Gender Equality Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Gender Equality Certificates.pdf'
    },
    {
      title: 'Human Values Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Human Values Certificates.pdf'
    },
    {
      title: 'Job Seeking Skills Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Job Seeking Skills Certificates.pdf'
    },
    {
      title: 'Mathematics for Competitive Exams Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Mathematics for Competitive Exams Certificates.pdf'
    },
    {
      title: 'Presentations skills Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Presentations skills Certificates.pdf'
    },
    {
      title: 'Web Designing Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Web Designing Certificates.pdf'
    },
    {
      title: 'Yoga Certificates',
      link: this.c3321Url + '1.2.2.4_2019-20_Yoga Certificates.pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
