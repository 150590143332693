import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-student-corner',
  templateUrl: './student-corner.component.html',
  styleUrls: ['./student-corner.component.scss']
})
export class StudentCornerComponent implements OnInit,OnDestroy {
  courseName:string;
  sub:Subscription;
  constructor(private showcaseService:ShowcaseService,private pathNameService:PathNameService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
    
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.courseName=res;
    });
  }

  splitUrl(){
    let url=location.pathname.split('/');
    this.courseName=url[url.length-1];
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
    this.sub.unsubscribe();
  }

}
