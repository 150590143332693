import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nineteenn-twenty',
  templateUrl: './nineteenn-twenty.component.html',
  styleUrls: ['./nineteenn-twenty.component.scss']
})
export class NineteennTwentyComponent implements OnInit {
  c3431Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1 Number of Extension and Outreach Programs_Photographs_5yr_SSR_Final/2019-20/';
  result:{title:string,link:string}[]=[
    {
      title:'ENVIRONMENT DAY',
      link:this.c3431Url + '17_ 2019 20 NCC_ENVIRONMENT DAY _photos.pdf'
    },
    {
      title:'UNITY DAY',
      link:this.c3431Url + '18_ 2019 20 NCC_UNITY DAY_photos.pdf'
    },
    {
      title:'ANTI CORRUPTION AWARENESS',
      link:this.c3431Url + '19_ 2019 20 NCC_ANTI CORRUPTION AWARENESS_photos.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c3431Url + '20_ 2019 20 NCC_ROAD SAFETY RALLY_photos.pdf'
    },
    {
      title:'DISTRIBUTION FOOD TO MIGRANTS',
      link:this.c3431Url + '21_ 2019 20 NCC_DISTRIBUTION FOOD TO MIGRANTS_photos.pdf'
    },
    {
      title:'MAINTAINANCE OF LAW AND DUTY',
      link:this.c3431Url + '22_ 2019 20 NCC_MAINTAINANCE OF LAW AND DUTY_photos.pdf'
    },
    {
      title:'SOCIAL SERVICE BY NCC CADETS',
      link:this.c3431Url + '23_ 2019 20 NCC_SOCIAL SERVICE BY NCC CADETS_photos.pdf'
    },
    {
      title:'National Voter Day',
      link:this.c3431Url + '24_ 2019-2020_NSS National Voter Day_photos.pdf'
    },
    {
      title:'NSS 7 Day Camp at Gdhauli',
      link:this.c3431Url + '25_ 2019-2020_NSS NSS 7 Day Camp at Gdhauli_photos.pdf'
    },
    {
      title:'Tree Plantation at Gdhauli',
      link:this.c3431Url + '26_ 2019-2020_NSS Tree Plantation at Gdhauli_photos.pdf'
    },
    {
      title:'National Deworming Day',
      link:this.c3431Url + '27_ 2019-2020_NSS National Deworming Day_photos.pdf'
    },
    {
      title:'ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019',
      link:this.c3431Url + '28_ 2019-20 YRC_ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019_photos.pdf'
    },
    {
      title:'NUKKAD NATAK ON BLOOD DONATION',
      link:this.c3431Url + '29_ 2019-20 YRC_NUKKAD NATAK ON BLOOD DONATION_photos.pdf'
    },
    {
      title:'26TH ANNUAL BLOOD DONATION CAMP',
      link:this.c3431Url + '30_ 2019-20 YRC_26TH ANNUAL BLOOD DONATION CAMP_photos.pdf'
    },
    {
      title:'NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION',
      link:this.c3431Url + '31_ 2019-20 YRC_NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION_photos.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
