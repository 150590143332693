<div id="health">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Health & Fitness Centre</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Guru Nanak Khalsa College, Yamunanagar has excellent sports and recreation facilities on campus, with dedicated facilities for Tennis, Basketball, Badminton, Volley Ball, Yoga and various other sports.
                        </p>
                        <p class="lead text-justify">
                            Students participate regularly in Inter Collegiate, Inter University, All India University and State Level Tournaments and have won laurels for the college.
                        </p>
                        <p class="lead text-justify">
                            <i>Keeping in mind the students health and in order to help them stay fit and healthy, all hostels are equipped with gym and fitness centre facilities - treadmill, exercise bikes and more of the latest fitness equipment.
                                 The central gym for men at our college is also equipped with body building equipment.
                                  All gyms and fitness centers are open both in the morning and evening hours.As a  healthy body and a healthy mind with Yoga so Yoga sessions are conducted for students to help them maintain both healthy body and mind.
                            </i>
                        </p>
                        <div id="gymCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#gymCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#gymCarousel" data-slide-to="1"></li>
                              <li data-target="#gymCarousel" data-slide-to="2"></li>
                              <li data-target="#gymCarousel" data-slide-to="3"></li>
                             
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/health/1.jpg" alt="health" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/health/5.JPG" alt="health" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/health/6.JPG" alt="health" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/health/7.JPG" alt="health" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#gymCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#gymCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>