import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-two',
  templateUrl: './one-two.component.html',
  styleUrls: ['./one-two.component.scss']
})
export class OneTwoComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
