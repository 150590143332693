<div class="container-fluid pdf">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg">

                <!-- <h5 class="card-title text-center"></h5> -->
                <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

            </div>
        </div>


    </div>
</div>