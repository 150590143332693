import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private courseArt:{name:string,title:string,creteria:string}[]=[
    {name:'M.A. Social Work',
    title:'A Person who has passed one of the following examinations of this university or an examination recognized as equivalent thereto, shall be eligible to join the First Semester of the course.',
    creteria:'Bachelor’s degree in any discipline with 50% marks for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).'},
    {name:'M.A. Punjabi',
    title:'A Person who has passed one of the following examinations of this university or an examination recognized as equivalent thereto, shall be eligible to join the First Semester of the course.',
    creteria:'B.A.(Hons.) in Punjabi or any discipline obtaining at least 45% marks for general category and 42.75% for SC/ST/Differently abled (5% less of 50%).'},
    {name:'M.A. Public Admn.',
    title:'A Person who has passed one of the following examinations of this university or an examination recognized as equivalent thereto, shall be eligible to join the First Semester of the course.',
    creteria:'B.A.(Hons.) in any discipline obtaining at least 50% marks for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).<br> OR  <br> B.A.(Hons.) in Public Administration/Pol.Sc./History/Sociology/Economic as one of the papers with 45% marks for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).'}
  ]
  private  coursesCreteria:{name:string,sem1:string,sem2:string,title:string,creteria1:string,creteria2:string}[]=[
    {name:'M.Sc. Biotechnology',
    sem1:'B.Sc. in Biological Sciences with atleast 50% marks in aggregate for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).',
    sem2:'Students should have cleared 50% of the papers in Semester I and II. Pass marks for each paper is 40%. However, promotion to the III Semester can be given on provisional basis till the result of semester II is declared.',
    title:'A person who has passed any of the qualifying examination from this University or any other examination recognized as equivalent thereto shall be eligible to join the first semester of any M.Sc. Course.',
    creteria1:'Eligibility Criteria for M.Sc. I (Semester I)',
    creteria2:'Eligibility Criteria for M.Sc. II (Semester III)'},
    {name:'M.Sc. Mathematics',
    sem1:'B.A./B.Sc. with Mathematics / Mathematics (Hons.) with 50% marks in aggregate for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).',
    sem2:'Students should have cleared 50% of the papers in Semester I and II. Pass marks for each paper is 40%. However, promotion to the III Semester can be given on provisional basis till the result of semester II is declared.',
    title:'A person who has passed any of the qualifying examination from this University or any other examination recognized as equivalent thereto shall be eligible to join the first semester of any M.Sc. Course.',
    creteria1:'Eligibility Criteria for M.Sc. I (Semester I)',
    creteria2:'Eligibility Criteria for M.Sc. II (Semester III)'},
    {name:'M.Sc. Ind. Chem. (Pharmaceuticals)',
    sem1:'B.Sc. with Chemistry/Industrial Chemistry as one of the subjects with atleast 50% marks in aggregate for general category and 47.5% for SC/ST/Differently abled (5% less of 50%).',
    sem2:'Students should have cleared 50% of the papers in Semester I and II. Pass marks for each paper is 40%. However, promotion to the III Semester can be given on provisional basis till the result of semester II is declared.',
    title:'A person who has passed any of the qualifying examination from this University or any other examination recognized as equivalent thereto shall be eligible to join the first semester of any M.Sc. Course.',
    creteria1:'Eligibility Criteria for M.Sc. I (Semester I)',
    creteria2:'Eligibility Criteria for M.Sc. II (Semester III)'},
    {name:'M.Sc. Physics',
    sem1:'B.Sc. with Physics and Mathematics with atleast 50% marks in aggregate for general category and 47.5% for SC/ST/Differently abled (5% less of 50%)',
    sem2:'Students should have cleared 50% of the papers in Semester I and II. Pass marks for each paper is 40%. However, promotion to the III Semester can be given on provisional basis till the result of semester II is declared.',
    title:'A person who has passed any of the qualifying examination from this University or any other examination recognized as equivalent thereto shall be eligible to join the first semester of any M.Sc. Course.',
    creteria1:'Eligibility Criteria for M.Sc. I (Semester I)',
    creteria2:'Eligibility Criteria for M.Sc. II (Semester III)'},

    {name:'M.Com.',
    sem1:'Semester I : A candidate who has passed B.Com. or BBA or B.T.M. with 45% marks in the aggregate for general candidates and 42.75% for SC/ST/Differently abled. or B.A./B.Sc. with Economics/Mathematics examination of KUK or any other examination recognised as equivalent thereto with at least 45% marks in the aggregate for general candidates and 42.75% for SC/ST/Differently abled.',
    sem2:'Studentsshouldhavecleared50%ofthepapersinSemesterIandII. Passmarksforeachpaperis 40%. However, promotion to the III Semester can be given on provisional basis till the result of semester II is declared.',
    title:'',
    creteria1:'Eligibility Criteria for M.Com. I (Semester I)',
    creteria2:'Eligibility Criteria for M.Com. II (Semester III)'}
    
  ];


  private courseBsc:{name:string,sem1:string,sub:string[],sem2:string[],title:string,creteria1:string,creteria2:string,comp:string[]}[]=[
    {name:'Bachelor of Life Sciences',sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Medical degree programme opting the following combinations. \n Note: The student is required to Select 3 major courses, one minor course and one Multidisciplinary course. For one multidisciplinary course, the student has to choose any one course other than opted for the major, minor and the courses studied at 10+1 and 10+2 level. This can be from any other program offered by the college, other than the Bachelor of Life Sciences.',
    sub:[],
    sem2:[],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Biology and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'',
    creteria2:'<br> <b>Major Subjects :</b> <br>Botany, Zoology, Industrial Microbiology, Chemistry <br><b>Minor Subject:</b><br> Industrial Microbiology, Chemistry',
  comp:[]},
    
  {name:'Bachelor of Physical Sciences',
  sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Non-Medical opting the following combinations. Note: The student is required to select 3 major courses, one minor course and one multidisciplinary course from the from the list given below For one multidisciplinary course, the student has to choose any one course other than opted for the major, minor and the courses studied at 10+1 and 10+2 level. This can be from any other program offered by the college, other than the Bachelor of Physical Sciences.',
    sub:[],
    sem2:[],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Mathematics and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'',
    creteria2:'<br> <b>Major Subjects:</b> <br> Physics, Chemistry, Mathematics, Statistics, Industrial Chemistry, Computer Science, Computer Applications, Geography <br><b>Minor Subjects:</b><br> Physics, Chemistry, Mathematics, Statistics, Geography. ',
    comp:[]},
    
    {name:'B.Sc. (Computer Science)',
    sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Non-Medical opting the following combinations.',
    sub:['Physics','Mathematics',' Computer Sc.'],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Mathematics and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'Eligibility for Admission to the I Sem. of the Course',
    creteria2:'Eligibility Criteria for B.Sc./B.C.A. III / V Sem.',
    comp:['Along with these candidate is required to offer following compulsory subjects:','English for I year students ','Hindi/Punjabi/Sanskrit (any one) for II year students','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree)']},
    
    {name:'B.Sc. (Computer Application)',
    sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Non-Medical opting the following combinations.',
    sub:['Physics','Mathematics','Computer Application'],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Mathematics and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'Eligibility for Admission to the I Sem. of the Course',
    creteria2:'Eligibility Criteria for B.Sc./B.C.A. III / V Sem.',
    comp:['Along with these candidate is required to offer following compulsory subjects:','English for I year students ','Hindi/Punjabi/Sanskrit (any one) for II year students','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree)']},
    
    {name:'B.Sc. (Industrial Chemistry)',
    sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Medical degree programme opting the following combinations.',
    sub:['Industrial Chemistry','Zoology','Botany'],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Biology and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'Eligibility for Admission to the I Sem. of the Course',
    creteria2:'Eligibility Criteria for B.Sc./B.C.A. III / V Sem.',
    comp:['Along with these candidate is required to offer following compulsory subjects:','English for I year students ','Hindi/Punjabi/Sanskrit (any one) for II year students','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree)']},
    
    {name:'B.Sc. (Industrial Microbiology)',
    sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Medical degree programme opting the following combinations.',
    sub:['Industrial Microbiology','Zoology','Botany'],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Biology and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'Eligibility for Admission to the I Sem. of the Course',
    creteria2:'Eligibility Criteria for B.Sc./B.C.A. III / V Sem.',
    comp:['Along with these candidate is required to offer following compulsory subjects:','English for I year students ','Hindi/Punjabi/Sanskrit (any one) for II year students','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree)']},

    {name:'B.Sc. (Industry Chemistry)',
    sem1:'40% with five subjects including English for general category and 38% for SC/ST/Differently abled shall be eligible to join B.Sc. Non-Medical opting the following combinations.',
    sub:['Physics','Mathematics','Ind. Chem.'],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'These courses are meant for students having five subjects including Physics, Chemistry, Mathematics and English at +2 level of Board of School Education, Haryana and any other equivalent examination recognised by Academic Council of Kurukshetra University, Kurukshetra.',
    creteria1:'Eligibility for Admission to the I Sem. of the Course',
    creteria2:'Eligibility Criteria for B.Sc./B.C.A. III / V Sem.',
    comp:['Along with these candidate is required to offer following compulsory subjects:','English for I year students ','Hindi/Punjabi/Sanskrit (any one) for II year students','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree)']},

    {name:'B.C.A. (Bachelor of Computer Applications)',
    sem1:'Students who pass their 10+2 Examination from any stream with at least 50% in aggregate with English as one of subjects’ professional education to meet the requirement of industry and to provide strong base for higher education in the field of Computer',
    sub:[],
    sem2:[],
    title:'Bachelor of Computer Applications is a special degree programme for providing highly advanced',
    creteria1:'',
    creteria2:'Eligibility:Students who pass their 10+2 Examination with at least 50% in aggregate with English as one of subjects from any stream for general category and 47.5% for SC/ST/differently abled ',
    comp:[]},

    {name:'B.Com. General',
    sem1:'',
    sub:[],
    sem2:[],
    title:'<b>Students who pass their 10+2 Examination with at least 40% in aggregate with English as one of subjects. 10+2 (Commerce) with 40% marks in aggregate for General category and 38% for SC/ST/differently abled or 10+2 with Science or Art subjects having 50% marks in aggregate for General category and 47.5% for SC/ST/differently abled</b>',
    creteria1:'',
    creteria2:'',
    comp:[]},

    {name:'B.Com. (C.A.)',
    sem1:'',
    sub:[],
    sem2:[],
    title:'<b>Students who pass their 10+2 Examination with at least 40% in aggregate with English as one of subjects. 10+2 (Commerce) with 40% marks in aggregate for General category and 38% for SC/ST/differently abled or 10+2 with Science or Art subjects having 50% marks in aggregate for General category and 47.5% for SC/ST/differently abled</b>',
    creteria1:'',
    creteria2:'',
    comp:[]},

    {name:'B.Com. (Hons.)',
    sem1:'A person shall be eligible to join the first semester class of B.Com. General Course, if he/she has passed any of the following examination with English as one of the subject :10+2 Commerce with 40% marks in aggregate for general category and 38% for SC/ST/Differently abled or 10+2 with Science or Arts subject shaving 50% marks in aggregate for general category and 47.5% for SC/ST/Differently abled',
    sub:[''],
    sem2:['Promotion to III sem is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.','Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.','Pass marks for each paper is 35%.'],
    title:'',
    creteria1:'Eligibility Criteria for Semester I',
    creteria2:'Eligibility Criteria for Semester III/V of any B.Com./B.B.A. class',
    comp:['Along with optional papers in any degree programme from commerce stream the candidate is required to offer following compulsory subjects:','Environmental Studies (Qualifying papers during the degree)','Computer Awareness for non computer subject combinations (Qualify papers during the degree ']},

    {name:'B.B.A.',
    sem1:'A candidate who has 50% marks in 10+2 examination in any stream of Board of School of Education,Haryana or any other examination recognised as equivalent there to with English as one of the subjects. <br> Bachelor of Business Administration is an undergraduate degree focused on business administration. The Bachelor of Business Administration program focuses on both the in-depth study of Management along with acquisition of professional and entrepreneurial skills. The core courses in a BBA programme cover various areas of Business Administration such as Accounting, Business Organisation, Human Resources, Business Communication, Business Law.',
    sub:[],
    sem2:[],
    title:'',
    creteria1:'',
    creteria2:'',
    comp:[]}
  ];

  private physical:{name:string,sem1:string[],sem2:string[],creteria1:string,creteria2:string}[]=[
    {name:'P.G. Diploma in Yoga',
    sem1:['Bachelor degree from K.U.K. or any other examination recognised as equivalent thereto with atleast 45% marks in aggregate for general category and 42.75% for SC/ST/Differently abled (5% less of 45%)'],
    sem2:[''],creteria1:'Eligibility Criteria for PG Diploma in Yoga Therapy',creteria2:''},

    {name:'B.Sc. (Physical Education, Health Education and Sports)',
    sem1:['has attained the age between 17 years and 20 years on 31st December of the year of admission (relaxable upto 25 years in the case of National/International sports persons or members of schedule caste/tribe).',
    'has participated in sports and games at State Level which may be State Schools/State Inter-Districts and State Inter-Collegiates (Intermediate); and',
    'has passed any one of the following examinations with atleast 35% marks and with English as one of the subject shall be eligible to join semester I of B.Sc. Physical Education, Health Education and Sports :',
    '10+2 from Board of School Education, Haryana with five subjects including English as one of the subjects OR an examination recognized as equivalent there to.',
    'Certificate in Physical Education of at least one year duration from any recognised Institution with total period of 12 years schooling OR an examination recognized as equivalent ther to',
    'Any other examination recognised by the academic council as equivalent to (a) and (b) above.'],
    sem2:['Promotion to III semester is allowed for the students who have cleared 50% of papers in Sem. I and Sem. II.',
    'Promotion to V Semester is only for those who have cleared all papers of semester I and 50% rest of the papers of semester II, III and IV.',
    'Pass marks for each paper is 35%.',
    'Promotion to the III / V Semester is on provisional basis till the result of semester II / IV is declared. The candidate will be allowed to get admission in semester V only if he/she has cleared all the papers of sem. I.'],
    creteria1:'Eligibility Criteria for B.Sc. Sports (Semester I)',creteria2:'Eligibility Criteria for B.Sc. Sports (Semester III/V)'}
  ];

  private ugc:{name:string,title:string,creteria:string,detail:string}[]=[
    {name:'Diploma in Retail Management ',title:'This UGC sponsored programme offers a diploma course for working people/house wives/any other seeking job in private commercial sector like Malls/Offices/Insurance Sector/Banks, etc.',
    creteria:'EligibilityvCriteria for Diploma Courses',detail:'10+2 in any discipline. Age no bar.'},
    {name:'Diploma in Banking and Financial Services. ',title:'This UGC sponsored programme offers a diploma course for working people/house wives/any other seeking job in private commercial sector like Malls/Offices/Insurance Sector/Banks, etc.',
    creteria:'Eligibility Criteria for Diploma Courses',detail:'10+2 in any discipline. Age no bar.'}
  ];

  private education:{name:string,creteria:string,title:string,detail1:string[],note:string[],detail2:string[]}[]=[
    {name:'B.Ed.',
    creteria:'Eligibility Criteria for B.Ed. Course (2 Year) ',
    title:'',
    detail1:['Candidates with at least 50% marks either in the Bachelor\'s Degree or in the Master\'s Degree in Sciences/Social Sciences/Humanity, Bachelor\'s in Engineering or Technology with specialization in Science and Mathematics with 55% marks or any other qualification equivalent thereto, are eligible for admission to the programme. ',
      'In case where specific relaxation of 5% marks in the eligibility condition is to be given to SC/ST/ Differently Abled candidates, the minimum less marks against 55% and 50% marks shall be calculated as under: 55 marks-2.75 marks=52.25 marks (5/100×55=2.75); 50 marks-2.50 marks=47.50 marks (5/100×50=2.50).'
    ],
    note:[''],
    detail2:['']},

    {name:'B.P.Ed.',
    creteria:'Eligibility Criteria for B.P.Ed. I',
    title:'A person who has obtained the following qualifications shall be eligible to join the course:',
    detail1:['Bachelor\'s Degree in any discipline with 50% marks and having at least participation in Inter-College/Inter-Zonal/District competition in Sports and Games as recognized by the AIU/IOA or',
    'Bachelor\'s Degree in Physical Education with 45% marks. or',
    'Bachelor\'s Degree in any discipline with 45% marks and studied Physical Education as compulsory/elective subject. or',
    'Bachelor\'s Degree with 45% marks and having participated in National/Inter-University/State competitions or secured 1st or 2nd or 3rd position in Inter-college/Inter-Zonal/District competition in Sports and Game as recognized by the AIU/IOA. or',
    'Bachelor\'s degree with participation in international competitions or secured 1st or 2nd or 3rd position in National/Inter-University competition in Sports and Games as recognized by the AIU/IOA. or All the eligible candidates will be required to qualify the physical efficiency test (PET). However, the criteria and the specifications of the individual events of the Canadian Test will be decided by the College Department of Physical Education.',
    'Graduation with 45% marks and at least 3 years teaching experience (for deputed in service candidates i.e. trained physical education teachers/coaches).'],
    note:[' Sports certificates acquired after passing +2 examination will be considered. Further no sports/games certificate will be considered for admission without its gradation issued from the Director, Sports and Youth Welfare of the state concerned. In the case of Inter college position/Inter-university participation and AIU position gradation/verification certificate issued by the Director, Sports of the University concerned can be considered. In the case of Inter College participation verification certificate issued by the Principal of the concerned college will be considered. In the sports/games where no gradation certificate is being issued by the concerned state/U.T. in such cases the certificate must be verified by the concerned Director, Sports of the State/U.T. The certificate issued by the Federation alone will not be considered. ',
    'Married female selected candidates will have to submit a certificate from RMO/MO of the University Health Centre that she is not in family way, along with an undertaking that if during the course of study, it is found that she is in family way, her admission would be liable to be cancelled IPSO-FACTO and all dues paid by her shall be forfeited.'],
    detail2:['All the eligible candidates will be required to qualify the physical efficiency test (PET). However, the criteria and the specifications of the individual events of the Canadian Test will be decided by the College Department of Physical Education.']}
  ];

  private BSCpo:string[]=[
  //   `<h2>B.SC. WITH BOTANY</h2>
  //   <h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Knowledge and understanding of plants structure.</p></li>
  //     <li><p>Students learn to carry out practical work.</p></li>
  //     <li><p>Communication of scientific idea.</p></li>
  //     <li><p>Student will be able to apply the scientific method to question in botany by formulating testable hypotheses.</p></li>
  //     <li><p>Explain how plants function at the level of genes, cells, tissues.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>Students will get additional skill of applying digital literacy to varied areas of biological studies by learning latest techniques involving computers like –Analyze and interpret the significance of data by using statistical software.</p></li>
  //     <li><p>Handling instruments required for photography of plants.</p></li>
  //     <li><p>Evaluate information about plants.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>To follow the 3 R’s – Reduce, Recycle and Reuse and promote the principles of Sustainable development of Environment.</p></li>
  //     <li><p>To sensitize the use of biodegradable, cost efficient, environment friendly and green chemicals and procedures.</p></li>
  //     <li><p>To minimize the use of chemicals and reduce the environmental pollution after knowing the concepts of Botany.</p></li>
  //   </ul>
  
  //   <h2>B.SC. WITH ZOOLOGY</h2>
  //   <h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Understanding the morphology and functional characteristics of animals at cellular and tissue level.</p></li>
  //     <li><p>Enhancing technical skills of students.</p></li>
  //     <li><p>Theoretical and practical knowledge about the Environmental Science, aqua culture and pest management etc.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>In-depth theoretical and practical knowledge of Zoology.</p></li>
  //     <li><p>Understanding the technical aspects of various technologies used in the field.</p></li>
  //     <li><p>Helps in addressing the biological challenges faced by human beings.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>After studyingthe course,  the students get well versed with diversified topics like immunology, recombinant DNA technology, plant tissue culture, biochemistry, Molecular Biology, zoological nomenclature, classification, developmental stages, economical importance of lower and higher invertebrates, hematology, histology of mammals, aquaculture and pest management.</p></li>
  //     <li><p>The student can pursue higher studies like M.Sc. and Ph. D. in Zoology, Entomology, Fish and Fisheries, Wildlife, Marine Biology etc.</p></li>
  //     <li><p>They can also become Medical Representative of various Pharma companies.</p></li>
  //   </ul>
  
  //   <h2>B.SC. WITH CHEMISTRY</h2>
  //   <h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Knowledge about fundamentals of chemical and scientific theories.</p></li>
  //     <li><p>Assess the properties of all elements.</p></li>
  //     <li><p>Quality and Quality analysis of chemicals in laboratories.</p></li>
  //     <li><p>Discuss the position of elements and their properties, bounding, hybridization, and structure in different compound using theories.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>In-depth theoretical and practical knowledge of Chemistry.</p></li>
  //     <li><p>To apply different concepts of organic, inorganic, physical, analytical and spectroscopic techniques of chemistry in various fields.</p></li>
  //     <li><p>Understanding the technical aspects of various technologies used in the field of chemistry.</p></li>
  //     <li><p>Helps in addressing the biochemical challenges faced by human beings.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Disciplinary knowledge and understanding of fundamentals and principles of Chemistry.</p></li>
  //     <li><p>Learn about the different concepts, laws, theories, application and reactions.</p></li>
  //     <li><p>To equip the students with theoretical and practical aspects of some industrially important chemical compounds and conceptual understanding of phase equilibrium, electrochemistry, thermodynamics and their applications in allied field.</p></li>
  //   </ul>
  
  //   <h2>B. SC. WITH PHYSICS</h2>
  //   <h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Able to explain physics applies to phenomena in the world around than.</p></li>
  //     <li><p>Recognize physics methods and principles and address problems related to them.</p></li>
  //     <li><p>Basics knowledge about the transistor semiconductors, amplifier, electromagnetism.</p></li>
  //     <li><p>Problem solving ability related to nuclear physics, solid states, noon physics and atomic and macules structure.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>Ability to develop experimental tools.</p></li>
  //     <li><p>Analyze problems and develop correct solutions using laws.</p></li>
  //     <li><p>Acquire knowledge about classical mechanics, quantum mechanics, electromagnetism, and optics.</p></li>
  //     <li><p>Learn how to design and conduct experiments.</p></li>
  //     <li><p>Learn about minimize contributing variables.</p></li>
  //     <li><p>Apply conceptual understanding of Physics.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Learn about concepts of Modern Physics.</p></li>
  //     <li><p>Relationship between observation and theory as well as Practical.</p></li>
  //     <li><p>Concept developed and changed over time.</p></li>
  //     <li><p>Acquire knowledge in the area of nuclear and practical physics.</p></li>
  //     <li><p>Relationship between electrical charge, field potential and magnetism.</p></li>
  //     <li><p>Skills to solve numerical problems.</p></li>
  //     <li><p>Understanding principles of optics.</p></li>
  //     <li><p>Thermal and statistical principal.</p></li>
  //     <li><p>Mathematical and Computer Techniques.</p></li>
  //   </ul>
  
  //   <h2>B. SC. WITH MATHEMATICS</h2>
  //   <h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Demonstrate the lignifying structure mathematics and relationships among them.</p></li>
  //     <li><p>Investigable and apply mathematical problems and solutions in a variety of contents.</p></li>
  //     <li><p>Develop basic manipulative skills in algebra, geometry, trigonometry and calculus.</p></li>
  //     <li><p>Know about sets, relations, functions and logical structures.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>The students are prepared for employability.</p></li>
  //     <li><p>In various technical positions like scientific assistants, quality control assistants, etc.</p></li>
  //     <li><p>As web based and application based designer and developer, tester, Data Entry Operator (DEO), etc.</p></li>
  //     <li><p>Through competitive exams like IT officers/bank employees in various public and private sectors.</p></li>
  //     <li><p>As entrepreneur to build, upgrade, test and maintain a computer system.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Learn about concepts of Mathematics.</p></li>
  //     <li><p>Leaners will acquire the skills like decision making, problem solving in day to day business affaires after studying the subject of mathematics.</p></li>
  //     <li><p>Skills to solve numerical problems.</p></li>
  //     <li><p>Mathematical and Computer Techniques.</p></li>
  //     <li><p>Learners will be able to do higher education and advance research in the field of mathematics.</p></li>
  //   </ul>`,
  //   '',
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Ability to work on computing design, evaluation and implementation.</p></li>
  //     <li><p>Analyze impacts of computing on individual organizations and society.</p></li>
  //     <li><p>Students will familiarize with the basics of operation system, DBMS, Data structure.</p></li>
  //     <li><p>Students will study about windows, software and various programming language.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //   <li><p>The students are prepared for employability.</p></li>
  //     <li><p>In various technical positions like scientific assistants, chemists, quality control assistants, etc.</p></li>
  //     <li><p>As web based and application based designer and developer, tester, data entry operator, etc.</p></li>
  //     <li><p>Through competitive exams like IT officers/bank employees in various public and private sectors,</p></li>
  //     <li><p>As entrepreneur to build, upgrade, test and maintain a computer system.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Understanding Hardware and software.</p></li>
  //     <li><p>Functions, components, and applications of computers.</p></li>
  //     <li><p>Fundamentals of Windows, DBMS, RDBMS, Data Mining.</p></li>
  //     <li><p>Various Programming languages.</p></li>
  //     <li><p>Ability to design good database.</p></li>
  //     <li><p>Various software engineering techniques.</p></li>
  //     <li><p>Tools and techniques of Data Mining.</p></li>
  //     <li><p>Concept of writing a programme.</p></li>
  //   </ul>`,
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Apply knowledge of computing fundamental to various real life applications.</p></li>
  //     <li><p>Design and develop applications to analyses and solve all computer science handing problem.</p></li>
  //     <li><p>Come to know about Multimedia, graphics, Lents, Images, memory management, file handing.</p></li>
  //     <li><p>Come to know about different programme language which helps to develop various applications.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>The students are prepared for employability.</p></li>
  //     <li><p>In various technical positions like scientific assistants, chemists, quality control assistants, etc.</p></li>
  //     <li><p>As web based and application based designer and developer, tester, data entry operator, etc.</p></li>
  //     <li><p>Through competitive exams like IT officers/bank employees in various public and private sectors,</p></li>
  //     <li><p>As entrepreneur to build, upgrade, test and maintain a computer system.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Introduction to Digital computer and fundamental architecture.</p></li>
  //     <li><p>Functions, components, and application of computers.</p></li>
  //     <li><p>Aware of RAM, ROM, SIZE, CACHE and virtual Memory.</p></li>
  //     <li><p>Basic Programming instructions and execution of instructions.</p></li>
  //     <li><p>Ability to design good database.</p></li>
  //     <li><p>Various software engineering techniques.</p></li>
  //     <li><p>Tools and techniques of Data Mining.</p></li>
  //     <li><p>Concept of Network in various fields.</p></li>
  //   </ul>`,
  //   ``,
  //   ``,
  //   ``,
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Students attain skills to open their venture in the area of hardware and software and networking.</p></li>
  //     <li><p>Students will have understanding of PC organization, Networks and Cloud Computing. They obtain analytical and problem solving skills.</p></li>
  //     <li><p>They will develop programming and logic development skills of multiple programming languages.</p></li>
  //     <li><p>Students attain employability skills to serve software, IT industry and government sectors.</p></li>
  //     <li><p>They will also have hand on experience on various web technologies and ethics. Students Develop real like applications for benefit of society.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>Students can have theoretical and practical knowledge of Computer System.</p></li>
  //     <li><p>Students will be prepared to make their career in the IT segments like Software services, Business Process Management (BPM/BPO), Technical support engineer, Content developer, Programmer and freelancing.</p></li>
  //     <li><p>Critical analyze problem and design the solution.</p></li>
  //     <li><p>Design cost effective hardware and software system architecture.</p></li>
  //     <li><p>Students can develop software development abilities.</p></li>
  //     <li><p>Students can also appear for various competitive exams including IT officers in various organizations and can pursue for higher studies.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Students can understand basic fundamentals of Computer System.</p></li>
  //     <li><p>Relation between hardware software. Different sorting selecting techniques.</p></li>
  //     <li><p>Explain windows, its features and hardware requirement of windows.</p></li>
  //     <li><p>Students learn different programming language like C, C++, Java, VB.</p></li>
  //     <li><p>Able to learn Binary logics, digital logics and combinational circurls.</p></li>
  //     <li><p>Ability to learn about, Database, Data structure and many more.</p></li>
  //   </ul>`,
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Students gain the knowledge of fundamentals of commerce and management, finance, economics, law and accounting.</p></li>
  //     <li><p>Able to think from commercial and professional point of view.</p></li>
  //     <li><p>Able to analyze relationship among commerce, trade, industry, services and management.</p></li>
  //     <li><p>Theories and planning about relative selling and economic development.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>Students will learn relevant financial accounting skills, thereby applying both quantitative and qualitative knowledge to their future careers in business.</p></li>
  //     <li><p>Leaners will gain systematic and subject skills in various disciplines of commerce, business, accounting, economics, finance, auditing and marketing.</p></li>
  //     <li><p>Learners will be able to recognize features and roles of businessmen, entrepreneur, managers, consultant, which will help learners to possess knowledge and other soft skills and to react aptly when confronted with critical decision making.</p></li>
  //     <li><p>Leaners will acquire the skills like decision making, problem solving in day to day business affairs.</p></li>
  //     <li><p>Learners will be able to do higher education and advance research in the field of commerce and finance.</p></li>
  //     <li><p>Learners will be able to prove proficiency with the ability to engage in competitive exams like CA, CS, ICWA and other courses.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>This provides specialized understanding of concepts of policies, issues of accounting along with theoretical and practical knowledge. </p></li>
  //     <li><p>This programme provides Industries, Banking, Sectors, Insurance companies, Financing Companies, Ware Housing etc.</p></li>
  //     <li><p>This programme can train professionals.</p></li>
  //     <li><p>Students can get skills regarding various aspects like Marketing Manager, Selling Manager and our all Administrative abilities of the company.</p></li>
  //     <li><p>Capability of the students of make decisions at personal and professional level will increase after completion of this course.</p></li>
  //   </ul>`,
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Students will have the practical knowledge of accounting.</p></li>
  //     <li><p>Know about the popular quantitative tool used in business and accounting.</p></li>
  //     <li><p>Along with the fundament knowledge of commerce, student will be aware of basic of computer.</p></li>
  //     <li><p>Inculcate the knowledge of various programming concepts.</p></li>
  //     <li><p>Knowledge of RDBMS, VB, Web designing.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>The students are prepared for employability.</p></li>
  //     <li><p>In various technical positions like scientific assistants, quality control assistants, etc.</p></li>
  //     <li><p>As web based and application based designer and developer, tester, data entry operator, etc.</p></li>
  //     <li><p>Through competitive exams like IT officers/bank employees in various public and private sectors,</p></li>
  //     <li><p>As entrepreneur to build, upgrade, test and maintain a computer system.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>Students will be able to solve problems using computer programming and getting familiar with the fundamentals concepts of computers.</p></li>
  //     <li><p>Students will acquire the skills in modular programming, object oriented programming and web page programming.</p></li>
  //     <li><p>Students will be able to do higher education and advance research in the field of computer application and information technology.</p></li>
  //     <li><p>Students will learn relevant financial accounting skills, thereby applying both quantitative and qualitative knowledge to their future careers in business.</p></li>
  //     <li><p>Students will gain systematic and subject skills in various disciplines of commerce, business, accounting, finance, auditing along with computer fundamentals.</p></li>
  //   </ul>`,
  //   ``,
  //   ''
  ];
  private MSCpo:string[]=[
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Basic knowledge of structure and function of major bio-molecules.</p></li>
  //     <li><p>Understanding of various metabolic processes.</p></li>
  //     <li><p>Describe about immunology, antibodies, types of vaccines, Genetic aspects.</p></li>
  //     <li><p>Biotechnology course describe various diversified topics likeRecombinant DNA Technology, Animal Cell Culture, Plant Tissue Culture, Biochemistry, and Molecular Biology.</p></li>
  //     <li><p>Also equip the students with practical aspects of some industrially important chemical compounds and conceptual understanding of phase equilibrium, electrochemistry, thermodynamics and their applications in allied field.</p></li>
  //   </ul>
  //   <h3>Program Specific Outcomes (PSO)</h3>
  //   <ul>
  //     <li><p>Students can elucidate basic concepts of Bio-technologies and Bioremediation.</p></li>
  //     <li><p>To enable students to apply and extend their knowledge and understanding of subject to experimental learning and critical thinking on laboratory reagents, the proper handling of chemical waste, aseptic procedures for isolation and identification of microorganisms, biochemical analysis of micro and macromolecules, water and soil analysis etc.</p></li>
  //     <li><p>To enhance employability the course focuses on skill development and enhancement in specific areas such as plant tissue culture, molecular diagnostics, microbiology, aquaculture, rearing of industrially important insects and others.</p></li>
  //   </ul>
  //   <h3>Course Outcome (CO)</h3>
  //   <ul>
  //     <li><p>To enable students to apply and extend their knowledge and understanding of subject to experimental learning and critical thinking on laboratory reagents and the proper handling of chemical waste.</p></li>
  //     <li><p>To enable students to apply and extend their knowledge and understanding of subject to experimental learning and critical thinking on aseptic procedures for isolation and identification of microorganisms, biochemical analysis of micro and macromolecules, water and soil analysis etc.</p></li>
  //     <li><p>Students will get additional skill of applying digital literacy to varied areas of biological studies by learning latest techniques involving computers like –Analyze and interpret the significance of data by using statistical software.Handling instruments required for microphotography of microbes. Fermentors, etc.</p></li>
  //   </ul>`,
  //   '',
  //   '',
  //   '',
  //   ''
  ];
  private physicalpo:string[]=[
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Adoption of Integral approach of Yoga Therapy to common ailments.</p></li>
  //     <li><p>Imparting skills to introduce Yoga for health to society.</p></li>
  //     <li><p>Promote positive health, fore mention of sports related health problems.</p></li>
  //     <li><p>Students practice yogic Asanas, Prayamaand Meditation.</p></li>
  //     <li><p>Also learn naturopathy which helps in curing various diseases.</p></li>
  //   </ul>`,
  //   ''
  ];

  private educationpo:string[]=[
  //   '',
  //   `<h3>Programme Outcome</h3>
  //   <ul>
  //     <li><p>Encourages a holistic approach based on socio-ecological perspective.</p></li>
  //     <li><p>Graduates could opt for higher education and research for the social science.</p></li>
  //     <li><p>Transform as competent leaders with updated and diverse leadership skills in various fields.</p></li>
  //     <li><p>Give the foundation of physical as well as health education, technology in physical education.</p></li>
  //     <li><p>Give them training of Athletics, games, formal lesion (Drill, Marching).</p></li>
  //   </ul>`
  ]
  constructor() { }

  getCourseCreteria(){
    return this.coursesCreteria.slice();
  }

  getCourseBsc(){
    return this.courseBsc.slice();
  }
  getphysicalData(){
    return this.physical.slice();
  }

  getUgc(){
    return this.ugc.slice();
  }
  getEducation(){
    return this.education.slice();
  }
  getCourseArt(){
    return this.courseArt.slice();
  }
  getBSCpo(){
    return this.BSCpo.slice();
  }
  getMSCpo(){
    return this.MSCpo.slice();
  }
  getPhysicalpo(){
    return this.physicalpo.slice();
  }
  getEducationpo(){
    return this.educationpo.slice();
  }
}
