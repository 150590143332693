<div id="co-po">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>CO - PO</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a  (click)="changeRoute('B. Pharmacy')">B. Pharmacy</a>
                        </li>
                        <li class="second">
                            <a  (click)="changeRoute('M. Pharmacy')">M. Pharmacy</a>
                        </li>
                        
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    
                                    <h5 class="card-title text-center">CO - PO</h5>
    
                                    <div class="container-fluid d-flex justify-content-center align-items-center h-100" id="loader" *ngIf="isLoading">
                                        <app-loader></app-loader>
                                    </div>
                                    <div id="detail" class="text-center">
                                        <pdf-viewer [src]="src" 
                                        [render-text]="true"
                                        style="display: block;"
                                        id="list-view"
                                        
                                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
    
                                </div>
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>