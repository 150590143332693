import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';
declare var $:any;
@Component({
  selector: 'app-c7-seven-two-one',
  templateUrl: './c7-seven-two-one.component.html',
  styleUrls: ['./c7-seven-two-one.component.scss']
})
export class C7SevenTwoOneComponent implements OnInit {
  showBest=true;
  policies:{title:string,link:string}[]=[];
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-7/7.2.1_Best Practices/7.2.1_Best Practice 1 Women Empowerment.pdf',
    '/assets/pdf/NAAC/Criterion-7/7.2.1_Best Practices/7.2.1_Best Practice 2 Clean_Green and Eco_Friendly Environment.pdf',];
  src: string;

  isLoading = true;
  constructor( private ssrService:SsrService) { }

  ngOnInit(): void {
    this.changeRoute('1');
    this.policies= this.ssrService.getPolicies();
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
      this.showBest=true;
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      // this.src = this.pdfSrc[1];
      this.showBest=false;
    }
  }

  hideLoader() {
    this.isLoading = false;
  }
}
