import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss']
})
export class InfrastructureComponent implements OnInit,OnDestroy {
  routeName='';
   sub:Subscription;
  constructor(
    private showcaseService:ShowcaseService,
    private pathNameService:PathNameService
  ) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.routeName=res;
    });
  }
  splitUrl(){
    let url=location.pathname.split('/');
    this.routeName=url[url.length-1];
    
  }

  changeRoute(route:string){
    this.routeName=route;
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
