import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoysRoomComponent } from 'src/app/view/infrastructure/boys-room/boys-room.component';
import { CanteenComponent } from 'src/app/view/infrastructure/canteen/canteen.component';
import { EdusatComponent } from 'src/app/view/infrastructure/edusat/edusat.component';
import { GirlsRoomComponent } from 'src/app/view/infrastructure/girls-room/girls-room.component';
import { GurudwaraComponent } from 'src/app/view/infrastructure/gurudwara/gurudwara.component';
import { HallComponent } from 'src/app/view/infrastructure/hall/hall.component';
import { HostelComponent } from 'src/app/view/infrastructure/hostel/hostel.component';
import { IctLabComponent } from 'src/app/view/infrastructure/ict-lab/ict-lab.component';
import { InfrastructureComponent } from 'src/app/view/infrastructure/infrastructure.component';
import { LabsComponent } from 'src/app/view/infrastructure/labs/labs.component';
import { LanguageLabComponent } from 'src/app/view/infrastructure/language-lab/language-lab.component';
import { LibraryComponent } from 'src/app/view/infrastructure/library/library.component';
import { PhotocopyingComponent } from 'src/app/view/infrastructure/photocopying/photocopying.component';
import { PlayFieldsComponent } from 'src/app/view/infrastructure/play-fields/play-fields.component';
import { RouterModule } from '@angular/router';
import { InfrastructureRoutingModule } from './infrastructure-routing.module';
import { AudioComponent } from 'src/app/view/infrastructure/audio/audio.component';
import { CompetitiveComponent } from 'src/app/view/infrastructure/competitive/competitive.component';
import { DigitalComponent } from 'src/app/view/infrastructure/digital/digital.component';
import { EthicsComponent } from 'src/app/view/infrastructure/ethics/ethics.component';
import { HealthComponent } from 'src/app/view/infrastructure/health/health.component';
import { KheloComponent } from 'src/app/view/infrastructure/khelo/khelo.component';
import { RecordingComponent } from 'src/app/view/infrastructure/recording/recording.component';
import { VirtualComponent } from 'src/app/view/infrastructure/virtual/virtual.component';
import { SeminarHallComponent } from 'src/app/view/infrastructure/seminar-hall/seminar-hall.component';
import { OpenAccessComponent } from 'src/app/view/infrastructure/library/open-access/open-access.component';
import { SubscribedComponent } from 'src/app/view/infrastructure/library/subscribed/subscribed.component';
import { AboutComponent } from 'src/app/view/infrastructure/library/about/about.component';



@NgModule({
  declarations: [
    InfrastructureComponent,
    LibraryComponent,
    HostelComponent,
    HallComponent,
    GurudwaraComponent,
    LabsComponent,
    LanguageLabComponent,
    IctLabComponent,
    CanteenComponent,
    PlayFieldsComponent,
    BoysRoomComponent,
    GirlsRoomComponent,
    EdusatComponent,
    PhotocopyingComponent,
    VirtualComponent,
    RecordingComponent,
    AudioComponent,
    HealthComponent,
    KheloComponent,
    CompetitiveComponent,
    DigitalComponent,
    EthicsComponent,
    SeminarHallComponent,
    SubscribedComponent,
    OpenAccessComponent,
    AboutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InfrastructureRoutingModule
  ]
})
export class InfrastructureModule { }
