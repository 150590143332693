import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-menu-detail',
  templateUrl: './menu-detail.component.html',
  styleUrls: ['./menu-detail.component.scss']
})
export class MenuDetailComponent implements OnInit,OnDestroy ,AfterViewInit,AfterViewChecked{

  menuName:string;
  sub:Subscription;
  constructor(private showcaseService:ShowcaseService,
    private pathNameService:PathNameService,
    private cdRef:ChangeDetectorRef) { 
      
    showcaseService.hideShowcase();
    
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.menuName=res;
    });

  }
  ngAfterViewInit(){
    
  }
  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    this.menuName=url[url.length-1];
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
    this.sub.unsubscribe();
  }

}
