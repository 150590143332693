<div id="fdp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">FDPs</h4>
                    <div class="card-body text-justify">
                        <h5>TOPIC/THEME : - One Week FDP for preparing E-Content</h5>
                        <hr>
                        <p class="lead text-justify">Lead by: <strong>Dr. Rajinder Singh Walia, Prof. Shaweta Sachdeva, Prof. Manender Dutt</strong></p>
                        <p class="lead text-justify">Attandees: - <strong>All Grant-in-Aid and Self Finance Faculty</strong></p>
                        <p class="lead text-justify">One Week FDP was organized by Guru Nanak Khalsa College Yamunanagar for 
                            in March 02, 2020 to March 09, 2020 for preparing E-Content like PPT, Google Site, Forms, Quizzes etc.
                            </p>
                            <div class="card-body text-justify">
                                <div class="row text-center">
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/1.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/2.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/3.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/4.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/5.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/6.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/7.jpg" alt="fdp" class="img-fluid"></div>
                                    <div class="col-sm-12 col-md-6 mt-2"><img src="/assets/img/fdp/8.jpg" alt="fdp" class="img-fluid"></div>
                                </div>
                            </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>