import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-two-two-one',
  templateUrl: './ct-two-two-one.component.html',
  styleUrls: ['./ct-two-two-one.component.scss']
})
export class CtTwoTwoOneComponent implements OnInit {
  c221Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'2.2.1_(1) Data template',
      link:this.c221Url + '2.2.1_(1) Data template.pdf'
    },
    {
      title:'2.2.1_(2) Teachers during 2021-22 ',
      link:this.c221Url + '2.2.1_(2) Teachers during 2021-22 .pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
