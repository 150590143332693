import { Injectable } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';

@Injectable({
  providedIn: 'root'
})
export class FacultyService {

  private economics: Faculty[] = [
    // {name:'Dr. Ravi Kapoor',img:'/assets/img/faculty/economics/1.JPG',position:'Associate Professor',degree:'M.A., PGDPM, Ph.D., D.Lit.'},
    { name: 'Dr. Amadeep Kaur', img: '/assets/img/faculty/economics/2.jpeg', position: 'Assistant Professor', degree: 'M.A., NET, Ph.D. ' },
    // {name:'Ms. Harmeet Kaur',img:'/assets/img/faculty/economics/3.jpg',position:'Assistant Professor',degree:'MBA, MA, M.Phil, B.Ed '}
  ];
  private english: Faculty[] = [
    // {name:'Dr. Rajinder Singh Walia',img:'/assets/img/faculty/english/1.JPG',position:'Associate Professor',degree:'M.A.English & Mass Comm., M.Lit., Ph.D. (CIEFL, Hyderabad), MBA, UGC Post Doctoral Fellow'},
    { name: 'Dr. Kamalpreet Kaur', img: '/assets/img/faculty/english/2.JPG', position: 'Associate Professor', degree: 'M.A., M. Phil. Ph.D.' },
    { name: 'Dr. Catherine Masih', img: '/assets/img/faculty/english/3.JPG', position: 'Associate Professor', degree: 'M.A., M.Phil., Ph.D.' },
    { name: 'Ms. Renu', img: '/assets/img/faculty/english/5.JPG', position: 'Assistant Professor', degree: 'M.A., B.Ed., M.Ed., PGDEE ' },
    // {name:'Mr. Parveen ',img:'/assets/img/faculty/english/4.jpg',position:'Assistant Professor',degree:'M.A., M. Phil., NET (2 Times)'},
    // {name:'Dr. Anita Abrol',img:'/assets/img/faculty/english/6.jpeg',position:'Assistant Professor & Soft Skills Trainer',degree:'M.A M.Phil,Ph D (ENGLISH)'},
    { name: 'Dr. Ramandeep Mahal', img: '/assets/img/faculty/english/7.jpg', position: 'Assistant Professor', degree: 'M.A., M.Phil,Ph.D.' }
  ];
  private hindi: Faculty[] = [
    { name: 'Dr. (Mrs.) Pratima Sharma', img: '/assets/img/faculty/hindi/1.JPG', position: 'Associate Professor', degree: 'B.A. (Hons.), M.A., M.Phil., Ph.D.' },
    // {name:'Dr. Hari Kiran Kaur',img:'/assets/img/faculty/hindi/2.JPG',position:'Associate Professor',degree:'B.A. (Hons.), M.A., M.Phil., Ph.D.'},
    { name: 'Dr. Vinay Chandel', img: '/assets/img/faculty/hindi/3.JPG', position: 'Associate Professor', degree: 'M.A. (Hindi), M.A. (Edu.), Ph.D.' },
    { name: 'Dr. Karamjit Kaur', img: '/assets/img/faculty/hindi/4.jpg', position: 'Associate Professor', degree: 'Ph.D., M.A., M.Phil' }
  ];

  private punjabi: Faculty[] = [
    { name: 'Dr. N.P. Singh', img: '/assets/img/faculty/punjabi/1.JPG', position: 'Associate Professor', degree: 'M.A., Ph.D.' },
    { name: 'Dr. Tilak Raj', img: '/assets/img/faculty/punjabi/2.JPG', position: 'Associate Professor', degree: 'M.A., Ph.D.,.NET' },
    // {name:'Ms. Hardeep Kaur',img:'/assets/img/faculty/punjabi/3.JPG',position:'Assistant Professor',degree:'M.A.'}
    { name: 'Mr. Sunil Nagra', img: '/assets/img/faculty/punjabi/3.jpeg', position: 'Assistant Professor', degree: 'M.A., M.Phil, NET' },
    { name: 'Mr. Amrit Pal Singh', img: '/assets/img/faculty/punjabi/4.jpeg', position: 'Assistant Professor', degree: 'M.A., M.Phil., B.Ed., CTET Qualified, NET' },
  ];
  private history: Faculty[] = [
    { name: 'Dr. Anurag', img: '/assets/img/faculty/history/1.JPG', position: 'Associate Professor', degree: 'M.A., M.Phil., Ph.D.' },
    { name: 'Ms. Manisha', img: '/assets/img/faculty/history/3.jpeg', position: 'Assistant Professor', degree: 'M.A., UGC NET' }
  ];
  private social: Faculty[] = [
    // {name:'Dr. Anand Parkash Mishra',img:'/assets/img/faculty/social/1.jpg',position:'Associate Professor',degree:'M.A., M.Phil., Ph.D.'},
    { name: 'Dr. Sahib Singh', img: '/assets/img/faculty/social/2.jpeg', position: 'Associate Professor', degree: 'M.A., Ph.D.' },
    { name: 'Dr. Hemant Kumar Mishra', img: '/assets/img/faculty/social/3.JPG', position: 'Associate Professor', degree: 'M.A., M.Phil., Ph.D.' },
    { name: 'Ms. Anika Sharma', img: '/assets/img/faculty/social/4.jpg', position: 'Assistant Professor', degree: 'MSW, M.A (Sociology)' },
    { name: 'Ms. Shilpi Bakshi', img: '/assets/img/faculty/social/5.jpg', position: 'Assistant Professor', degree: 'MSW, MA ECO, B.ed' }
  ];
  private sanskrit: Faculty[] = [
    // {name:'Ms. Loveneen Grover ',img:'/assets/img/faculty/sanskrit/1.jpg',position:'Assistant Professor',degree:'M.A (SANSKRIT), M.A (HINDI) M.PHIL, NET'}
    { name: 'Ms. Shalini ', img: '/assets/img/faculty/sanskrit/2.jpeg', position: 'Assistant Professor', degree: 'M.A., B.Ed.' }
  ];
  private music: Faculty[] = [
    // {name:'Mr. Manmohan ',img:'/assets/img/faculty/music/1.JPG',position:'Assistant Professor',degree:'M.A. Music'},
    { name: 'Dr. Babita Singhania', img: '/assets/img/faculty/music/3.jpeg', position: 'Assistant Professor', degree: 'M.A., M.PHIL, PH.D., B.ED, PARBHAKAR IN KATHAK DANCE COURSE' }
  ];
  private politicalScience: Faculty[] = [
    { name: 'Dr. Payal Lamba', img: '/assets/img/faculty/political/5.jpg', position: 'Assistant Professor', degree: 'M.A, B.Ed., M.Phil. Ph.D.' },
  ];
  private publicAdministration: Faculty[] = [
    // {name:'Dr. Meenu Kapur',img:'/assets/img/faculty/political/1.jpeg',position:'Associate Professor',degree:'M.A., Ph.D.'},
    { name: 'Dr. Iqbal Singh', img: '/assets/img/faculty/political/2.JPG', position: 'Associate Professor', degree: 'M.A., Ph.D.' },
    { name: 'Dr. Baljit Singh', img: '/assets/img/faculty/political/3.jpeg', position: 'Associate Professor', degree: 'M.A., M.Phil., Ph.D.' },
    // {name:'Dr. Uday Bhan Singh ',img:'/assets/img/faculty/political/4.JPG',position:'Associate Professor',degree:'M.A. (Pub.Ad. & Mass Comm.), M.Phil.,  Ph.D., PGD in Translation, Cert. in Community Radio, Cert. in film Appreciation Course, Cert. in Human Right Education'},
    // { name: 'Dr. Payal Lamba', img: '/assets/img/faculty/political/5.jpg', position: 'Assistant Professor', degree: 'M.A, B.Ed., M.Phil. Ph.D.' },
    { name: 'Mr. Mohit Kumar', img: '/assets/img/faculty/political/6.jpeg', position: 'Assistant Professor', degree: 'M.A. POL. SCI., UGC NET' },
    { name: 'Ms Shivay Sharma', img: '/assets/img/faculty/political/7.jpeg', position: 'Assistant Professor', degree: 'M.A.,Pub.Ad' },
  ];
  private geography: Faculty[] = [
    // {name:'Mr. Jitender Kumar',img:'/assets/img/faculty/geo/1.jpg',position:'Assistant Professor',degree:'M.Sc., M.Phil, NET, JRF'},
    // {name:'Ms. Krishna ',img:'/assets/img/faculty/geo/2.jpeg',position:'Assistant Professor',degree:'M.Sc, M.Phil, B.Ed'},
    { name: 'Mr. Etender Singh', img: '/assets/img/faculty/geo/3.jpeg', position: 'Assistant Professor', degree: 'M.Sc., NET, M.Phil, PGDGARD' },
    { name: 'Mr. Vishal', img: '/assets/img/faculty/geo/4.jpg', position: 'Assistant Professor', degree: 'M.S.C. GEOGRPAHY, UGC NET' },
  ];
  private physics: Faculty[] = [
    { name: 'Ms. Santosh Kurra', img: '/assets/img/faculty/physics/1.JPG', position: 'Associate Professor', degree: 'M.Sc., M.Phil.' },
    // {name:'Dr. Indra Kapoor',img:'/assets/img/faculty/physics/2.JPG',position:'Associate Professor',degree:'M.Sc., M.Phil. (Gold Medalist), Ph.D'},
    { name: 'Dr. M.P. Aggarwal', img: '/assets/img/faculty/physics/3.JPG', position: 'Associate Professor', degree: 'M.Sc. (Gold Medalist), M.Phil., Ph.D., PGDCA' },
    { name: 'Prof Bal Krishan ', img: '/assets/img/faculty/physics/4.JPG', position: 'Coordinator, M.Sc.(Physics)', degree: 'M.Sc., M.Phil., Post B.Sc. Dip. in Computer Science & Applications, Dip. in Remedial English' },
    // {name:'Ms. Neelam ',img:'/assets/img/faculty/physics/5.jpg',position:'Assistant Professor',degree:'M.Sc., B.Ed., CTET'},
    { name: 'Ms. Pratibha Sharma', img: '/assets/img/faculty/physics/6.jpeg', position: 'Assistant Professor', degree: 'M.Sc., B.Ed. ' },
    // {name:'Ms. Himani Riyal',img:'/assets/svg/staff/2.svg',position:'Assistant Professor',degree:'M.Sc. '},
    // {name:'Ms. Shipra Sareen ',img:'/assets/img/faculty/physics/7.jpeg',position:'Assistant Professor',degree:'M.Sc , B.sc ,B.ed'},
    { name: 'Dr. Mandeep Kaur', img: '/assets/img/faculty/physics/8.jpeg', position: 'Assistant Professor', degree: 'M.Sc., B.Ed., Ph.D.' },
    // {name:'Dr. Manpuneet Kaur',img:'/assets/img/faculty/physics/9.jpeg',position:'Assistant Professor',degree:'Ph.D. (in Atomic and molecular Physics)'},
    { name: 'Dr. Anju Nigam ', img: '/assets/img/faculty/physics/10.jpeg', position: 'Assistant Professor', degree: 'PH.D., M.SC.PHYSICS' },
    { name: 'Ms. Aditi ', img: '/assets/img/faculty/physics/11.jpeg', position: 'Assistant Professor', degree: 'M.Sc., B.Ed.' },
  ];

  private math: Faculty[] = [
    { name: 'Ms. Gurvinder Kaur', img: '/assets/img/faculty/math/1.jpeg', position: 'Associate Professor', degree: 'M.Sc., M.Phil., PGDCA' },

    { name: 'Ms. Asha Chhabra', img: '/assets/img/faculty/math/2.jpg', position: 'Assistant Professor', degree: 'MCA, M.Sc., B.Ed., PGDCA, A.D.S.E.' },
    // {name:'Ms. Pranjali',img:'/assets/img/faculty/math/4.jpeg',position:'Assistant Professor',degree:'M.Sc., CTET, HTET (All Levels)'},
    // {name:'Mr.  Ankit Saini',img:'/assets/img/faculty/math/5.jpeg',position:'Assistant Professor',degree:'M.Sc., NET, UPTET, CTET'},
    { name: 'Ms. Pooja Khurana', img: '/assets/img/faculty/math/7.jpg', position: 'Assistant Professor', degree: 'Msc. Maths, B.ed' },
    { name: 'Ms. Arshdeep Kaur', img: '/assets/img/faculty/math/3.jpeg', position: 'Assistant Professor', degree: 'M.Sc., B.Ed.' },
    { name: 'Ms. Manju', img: '/assets/img/faculty/math/8.jpeg', position: 'Assistant Professor', degree: 'M.SC., GATE, NET' },
    { name: 'Ms. Pardeep', img: '/assets/img/faculty/math/9.jpeg', position: 'Assistant Professor', degree: 'M.Sc.' },
    // {name:'Mr. Jagdeep',img:'/assets/svg/staff/2.svg',position:'Assistant Professor',degree:'M.Sc. '}
    // {name:'Mr. Shubham Walia',img:'/assets/img/faculty/math/6.jpeg',position:'Assistant Professor',degree:'M.Sc.(Maths), NET, GATE(2 times)'},
  ];
  private zoology: Faculty[] = [
    { name: 'Dr. Gyan Bhushan', img: '/assets/img/faculty/zoo/1.JPG', position: 'Associate Professor', degree: 'M.Sc., Ph.D.' },
    { name: 'Dr. Neelam Behl', img: '/assets/img/faculty/zoo/2.jpeg', position: 'Associate Professor', degree: 'M.Sc., M.Phil., Ph.D.' }
  ];

  private chemistry: Faculty[] = [
    { name: 'Dr. Rameshwar Dass', img: '/assets/img/faculty/chemistry/1.jpeg', position: 'Associate Professor', degree: 'M.Sc., M.Phil, Ph.D.' },
    // {name:'Dr. Lalita Choudhary',img:'/assets/img/faculty/chemistry/2.JPG',position:'Assistant Professor',degree:'M.Sc., B.Ed., NET, Ph.D. '},
    // {name:'Ms. Ruchika',img:'/assets/img/faculty/chemistry/3.jpg',position:'Assistant Professor',degree:''}
  ];
  private botony: Faculty[] = [
    { name: 'Dr. Amarjit Singh', img: '/assets/img/faculty/botony/1.JPG', position: 'Associate Professor', degree: 'M.Sc., M.Phil, Ph.D.' },
    { name: 'Dr. Varsha Nigam', img: '/assets/img/faculty/botony/2.jpeg', position: 'Associate Professor', degree: 'M.Sc., Ph.D.' }
  ];
  private computer: Faculty[] = [
    // {name:'Dr. Bharat Bhushan',img:'/assets/svg/staff/2.svg',position:'Assistant Professor',degree:'M.Sc., M.C.A., Ph.D'},
    { name: 'Dr. Kiranpal Singh', img: '/assets/img/faculty/computer/2.JPG', position: 'Assistant Professor', degree: 'M.Sc. M.Phil., GATE' },
    // {name:'Mr. Manender Dutt ',img:'/assets/img/faculty/computer/3.jpg',position:'Assistant Professor',degree:'MCA, M.Sc., Google Certified Educator, PGT Level-3 Qualified'},
    // {name:'Ms. Priyanka',img:'/assets/img/faculty/computer/4.jpg',position:'Assistant Professor',degree:'MCA, M.Sc.'},
    { name: 'Ms. Bhawna Sharma', img: '/assets/img/faculty/computer/5.JPG', position: 'Assistant Professor', degree: 'M.Sc., HTET-PGT, UGC NET' },
    { name: 'Ms. Jasmeen Kaur', img: '/assets/img/faculty/computer/6.JPG', position: 'Assistant Professor', degree: 'M.Sc.,B.Ed, Diploma in ECE, HTET-PGT, UGC NET' },
    { name: 'Ms. Anuradha', img: '/assets/img/faculty/computer/7.JPG', position: 'Assistant Professor', degree: 'M.tech., B.tech., Diploma' },
    // {name:'Ms. Tanu Uppal',img:'/assets/img/faculty/computer/8.jpg',position:'Assistant Professor',degree:'MCA'},
    // {name:'Ms. Ravneet Bhatia',img:'/assets/img/faculty/computer/9.jpeg',position:'Assistant Professor',degree:'M.Sc.'},
    { name: 'Ms. Minni Sharma', img: '/assets/img/faculty/computer/10.jpeg', position: 'Assistant Professor', degree: 'M.Sc. Computer Science, MCA' },
    { name: 'Ms. Dolly Arora', img: '/assets/img/faculty/computer/11.jpg', position: 'Assistant Professor', degree: 'MCA' },
    { name: 'Ms. Richa Sahni', img: '/assets/img/faculty/computer/12.jpeg', position: 'Assistant Professor', degree: 'MCA' },
    { name: 'Ms. Nikita', img: '/assets/svg/staff/2.svg', position: 'Assistant Professor', degree: 'MCA,M.Tech.' },
  ];
  private industrial: Faculty[] = [
    { name: 'Dr. Pritam Singh', img: '/assets/img/faculty/industry/1.jpg', position: 'Associate Professor', degree: 'M.Sc., M.Phil, Ph.D.' },
    { name: 'Dr. (Mrs.) Vijay Sharma', img: '/assets/img/faculty/industry/2.JPG', position: 'Associate Professor', degree: 'M.Sc, B.Ed., M.Phil, Ph.D.,' },
    // {name:'Dr. Anil Kumar Bishnoi',img:'/assets/svg/staff/2.svg',position:'Associate Professor',degree:'M.Sc., Ph.D'},
    // {name:'Ms. Akanksha',img:'/assets/img/faculty/industry/4.JPG',position:'Assistant Professor',degree:'M.Sc. Industrial Chemistry '},
  ];
  private microbiology: Faculty[] = [
    { name: 'Dr. Neena Puri', img: '/assets/img/faculty/micro/1.JPG', position: 'Associate Professor', degree: 'M.Sc., Ph.D.' }
  ];
  private biotechnology: Faculty[] = [
    // {name:'Dr. Meenakshi Kalia',img:'/assets/img/faculty/bio/1.JPG',position:'Associate Professor',degree:'M.Sc., M.Phil.'},
    // {name:'Ms. Kanika Kamboj',img:'/assets/img/faculty/bio/2.JPG',position:'Assistant Professor',degree:'M. Sc.'},
    // {name:'Ms. Komal Malhotra',img:'/assets/img/faculty/bio/3.jpg',position:'Assistant Professor',degree:'M. Sc (Forensic Science & Toxicology)'}
    // {name:' Dr. Monica Sharma',img:'/assets/img/faculty/bio/1.jpeg',position:'Associate Professor',degree:'M.Sc.(Biotechnology), Ph.D, NET (Life Sciences), GATE (Biotechnology)'},
    // {name:'Ms. Preeti Handa',img:'/assets/img/faculty/bio/2.jpeg',position:'Assistant Professor',degree:'M.Sc.(Biotechnology), B.Ed.'},
    // {name:'Ms. Yasmin',img:'/assets/img/faculty/bio/3.jpeg',position:'Assistant Professor',degree:'M.sc.(Biotechnology)'}
    { name: 'Dr. Nidhi Mahendru', img: '/assets/img/faculty/bio/4.jpg', position: 'Assistant Professor', degree: ' B.Sc., M.Sc. Biotechnology, Ph.D' },
    { name: 'Ms. Avantika', img: '/assets/img/faculty/bio/5.jpeg', position: 'Assistant Professor', degree: ' M.Sc. Biotechnology' },
    { name: 'Ms. Yasmin', img: '/assets/img/faculty/bio/6.jpeg', position: 'Assistant Professor', degree: ' M.Sc. Biotechnology' },
  ];
  private commerce: Faculty[] = [
    // {name:'Dr. Alok Goyal',img:'/assets/img/faculty/commerce/1.JPG',position:'Associate Professor',degree:'M.Com., M.Phil., Ph.D.'},
    { name: 'Dr. Jaswinder Kaur', img: '/assets/img/faculty/commerce/2.JPG', position: 'Associate Professor', degree: 'M.Com., M.Phil., Ph.D.' },
    { name: 'Dr. Sanjay Arora', img: '/assets/img/faculty/commerce/3.JPG', position: 'Associate Professor', degree: 'M.Com., M.Phil., Ph.D.' },
    { name: 'Dr. Ashok Khurana', img: '/assets/img/faculty/commerce/4.jpeg', position: 'Associate Professor', degree: 'M.Com., M.Phil., Ph.D.' },
    // {name:'Dr. P.K Malik',img:'/assets/img/faculty/commerce/5.JPG',position:'Associate Professor',degree:'M.Com., Ph.D., UGC Post Doctoral Fellow'},
    { name: 'Dr. R.S. Vohra', img: '/assets/img/faculty/commerce/6.JPG', position: 'Associate Professor', degree: 'M.Com., Ph.D.' },
    { name: 'Ms. Seema Jangra', img: '/assets/img/faculty/commerce/8.JPG', position: 'Assistant Professor', degree: 'M.com, M.Phil.,B.Ed.' },
    { name: 'Dr. Shaweta Sachdeva ', img: '/assets/img/faculty/commerce/7.jpeg', position: 'Assistant Professor', degree: 'M.Sc., M.C.A, M.Phil., Ph.D.' },
    // {name:'Ms. Shiwani Chawla',img:'/assets/svg/staff/2.svg',position:'Assistant Professor',degree:'MBA, M.Com. B.Ed.'},
    { name: 'Ms. Pooja Rani', img: '/assets/img/faculty/commerce/10.JPG', position: 'Assistant Professor', degree: 'M.C.A' },
    { name: 'Ms. Shilpi', img: '/assets/img/faculty/commerce/11.JPG', position: 'Assistant Professor', degree: 'M.Com, M.B.A.' },
    { name: 'Mr. Jaspreet Singh', img: '/assets/img/faculty/commerce/16.jpeg', position: 'Assistant Professor', degree: 'M.com (Gold Medalist) , UGC NET' },
    // {name:'Ms. Smriti',img:'/assets/img/faculty/commerce/12.JPG',position:'Assistant Professor',degree:'M.Com'},
    // {name:'Ms. Ramanjot Kaur',img:'/assets/img/faculty/commerce/13.JPG',position:'Assistant Professor',degree:'M.Com, NET'},
    { name: 'Ms. Nishi ', img: '/assets/img/faculty/commerce/14.JPG', position: 'Assistant Professor', degree: 'M.Com' },
    // {name:'Ms. Neha',img:'/assets/img/faculty/commerce/15.JPG',position:'Assistant Professor',degree:'M.Com'},
    { name: 'Ms. Sonam Saini', img: '/assets/img/faculty/commerce/17.jpeg', position: 'Assistant Professor', degree: ' M.com, NET' },
    // {name:'Ms. Rajni Vats',img:'/assets/img/faculty/commerce/18.jpeg',position:'Assistant Professor',degree:'MBA,B.Ed.'},
    { name: 'Ms. Mangeet Kaur', img: '/assets/img/faculty/commerce/19.jpeg', position: 'Assistant Professor', degree: '(M.com, B.Ed.)' },
    // { name: 'Ms. Gurleen Kaur', img: '/assets/img/faculty/commerce/20.jpeg', position: 'Assistant Professor', degree: 'M.com.' },

  ];

  private physical: Faculty[] = [
    // {name:'Dr. Harinder Singh Kang ',img:'/assets/img/faculty/physical/3.JPG',position:'Associate Professor',degree:'B.P.E.(Gold Medalist), M.A. (Phy. Ed.) (Gold Medalist), M.Phil., Dip. in Sports Med. and Physiotherapy, Crt. Course in Sports Psychology and GTMT, Ph.D., PGDBM, M.B.A ,D.Lit.'},
    // {name:'Dr. (Mrs.) Amrita Pritam',img:'/assets/img/faculty/physical/1.JPG',position:'Associate Professor',degree:'M.A. (Hindi), D.P.Ed., M.P.Ed., Ph.D., Certi. Course in Yoga'},
    { name: 'Dr. Bodh Raj', img: '/assets/img/faculty/physical/2.jpg', position: 'Associate Professor', degree: 'B.P.Ed., M.A. (Phy. Ed.), M.Phil., Ph.D.' },

    { name: 'Dr. Ranjit Singh', img: '/assets/img/faculty/physical/4.JPG', position: 'Associate Professor', degree: 'B.P.E., M.A. (Phy. Ed.), M.Phil., Ph.D., Certificate Course in Yoga and Lawn Tennis.' },
    { name: 'Dr. Sanjay Vij', img: '/assets/img/faculty/physical/5.jpeg', position: 'Associate Professor', degree: 'M.A. (Phy. Ed.), D.P.Ed., Ph.D.' },
    { name: 'Dr. Parveen Kumar', img: '/assets/img/faculty/physical/16.jpeg', position: 'Associate Professor', degree: 'D.P.Ed.,M.P.Ed.,M.Phil, Ph.D., Cert. Course in Athletics, Cert. Course in Yoga' },
    { name: 'Mr. Shiv Kumar', img: '/assets/img/faculty/physical/8.jpeg', position: 'Assistant Professor', degree: 'M.P.Ed., PG Diploma in Yoga ' },
    { name: 'Ms. Bobby Devi', img: '/assets/img/faculty/physical/9.jpeg', position: 'Assistant Professor', degree: 'M.A. Yoga, UGC NET' },
    // {name:'Mr. Deepak',img:'/assets/img/faculty/physical/11.jpg',position:'Assistant Professor',degree:'M.P.Ed., PG Diploma in Yoga Sciences, NIS Diploma in Sports Coaching'},
    { name: 'Mr. Mangal Singh', img: '/assets/img/faculty/physical/12.jpeg', position: 'Assistant Professor', degree: 'M.P.Ed., B.P.Ed.' },
    { name: 'Mr. Dheeraj', img: '/assets/img/faculty/physical/13.jpeg', position: 'Assistant Professor', degree: 'M.P.Ed., B.P.Ed., ' },
    { name: 'Mr. Kawal Preet Singh', img: '/assets/img/faculty/physical/14.jpeg', position: 'Assistant Professor', degree: 'M.P.Ed., B.P.Ed.' },
    { name: 'Mr. Amritpal Singh', img: '/assets/img/faculty/physical/15.jpeg', position: 'Assistant Professor', degree: 'Bp.ed, Mp.ed., PSTET, CET.' },
    { name: 'Mr. Manmohan', img: '/assets/img/faculty/physical/17.jpeg', position: 'Assistant Professor', degree: 'B.p.ed, m.p.ed, PG diploma in Yoga' },
  ];

  private education: Faculty[] = [
    // {name:'Dr. Rajesh Kumar',img:'/assets/img/faculty/education/1.jpeg',position:'Associate Professor',degree:'M.P.Ed, M.Phil., Ph.D.'},
    // {name:'Dr. Subhash Chikara',img:'/assets/img/faculty/education/3.JPG',position:'Associate Professor',degree:'M.P.ED, M.PHIL, PGDBM, Ph.D.'},
    { name: 'Mr. Arun Kumar', img: '/assets/img/faculty/education/2.jpg', position: 'Assistant Professor', degree: 'M.P.Ed, M.Phil., .NET' },
    { name: 'Dr. Joshpreet Singh Sandhu ', img: '/assets/img/faculty/physical/7.jpeg', position: 'Assistant Professor', degree: ', M.P.Ed., M.Phil., Ph.D.' },
    // {name:'Mr. Jagdeep Singh Ghumman',img:'/assets/img/faculty/education/4.jpg',position:'Assistant Professor',degree:'M.P.Ed., NET'},
    { name: 'Ms. Davinder Kaur ', img: '/assets/img/faculty/education/5.jpg', position: 'Assistant Professor', degree: 'M.P.Ed, M.Phil., .NET' },
    { name: 'Mr. Anurodh Kumar ', img: '/assets/img/faculty/education/6.jpeg', position: 'Assistant Professor', degree: 'M. P. Ed., NET' },
    { name: 'Ms. Gurvinder Kaur', img: '/assets/img/faculty/education/7.jpeg', position: 'Assistant Professor', degree: 'M.A., M.P.Ed., M.Phill.' },
  ];
  private education1: Faculty[] = [
    { name: 'Dr. Jagat Singh', img: '/assets/img/faculty/education1/1.jpeg', position: 'HOD', degree: 'M.A (Hindi,), M.Ed., PRABHAKAR, NET (Education and Hindi), Ph.D. (Education)' },
    // {name:'Mr. Mohan Lal',img:'/assets/img/faculty/education1/2.jpeg',position:'Assistant Professor',degree:'M.Ed, M.A. Eco., M.A. Eng., .NET'},
    { name: 'Mr. Pardeep Kumar', img: '/assets/img/faculty/education1/3.JPG', position: 'Assistant Professor', degree: 'M.Ed, M.Sc. Maths, M.Phil., .NET' },
    { name: 'Dr. Sangeeta Rani', img: '/assets/img/faculty/education1/4.jpeg', position: 'Assistant Professor', degree: 'M.Sc. Chem., M.Ed, M.Phil., .NET,Ph.D.' },
    { name: 'Ms. Kavita Devi ', img: '/assets/img/faculty/education1/5.jpeg', position: 'Assistant Professor', degree: 'M.Ed,  M.A. Hindi., .NET' },
    { name: 'Mr. Ram kumar ', img: '/assets/img/faculty/education1/6.JPG', position: 'Assistant Professor', degree: 'M.A(Fine Art),  B.Ed., M.A(Social Work)' },
    { name: 'Mr. Raju ', img: '/assets/img/faculty/education1/7.jpg', position: 'Assistant Professor', degree: 'M.A.(English and History), M.Ed., HTET(PGT- English), NET (English), NET(Education)' },
    { name: 'Dr Kamal Kumar Bhardwaj ', img: '/assets/img/faculty/education1/8.jpg', position: 'Assistant Professor', degree: 'Ph.D,UGC NET,M.Ed.,M.Sc.Chemistry,M.A. Sociology)' },
    { name: 'Ms Pooja Rani ', img: '/assets/img/faculty/education1/9.jpg', position: 'Assistant Professor', degree: 'M.Ed., UGC NET,M.A. Pub-Ad,M.A. Sociology' }
  ];
  private community: Faculty[] = [
    { name: 'Dr. Sanjay Arora', img: '/assets/img/faculty/commerce/3.JPG', position: 'Associate Professor', degree: 'M.Com., M.Phil., Ph.D.' },
    { name: 'Ms. Pooja Rana ', img: '/assets/img/faculty/community/2.jpeg', position: 'Assistant Professor', degree: 'M.Com, B.Ed. ' },
    { name: 'Dr. Shaweta Sachdeva ', img: '/assets/img/faculty/commerce/7.jpeg', position: 'Assistant Professor', degree: 'M.Sc., M.C.A, M.Phil., Ph.D.' },
    { name: 'Mr. Jaspreet Singh', img: '/assets/img/faculty/commerce/16.jpeg', position: 'Assistant Professor', degree: 'M.com (Gold Medalist) , UGC NET' },
    { name: 'Ms. Renu', img: '/assets/img/faculty/english/5.JPG', position: 'Assistant Professor', degree: 'M.A., B.Ed., M.Ed., PGDEE ' },
    // {name:'Ms. Ashima kathuria ',img:'/assets/img/faculty/community/3.JPG',position:'Assistant Professor',degree:'M.A.'},
    // {name:'Ms. Sonam parnami',img:'/assets/img/faculty/community/4.jpeg',position:'Assistant Professor',degree:'MBA'},
  ];
  constructor() { }

  getEconomics() {
    return this.economics.slice();
  }
  getEnglish() {
    return this.english.slice();
  }
  getHindi() {
    return this.hindi.slice();
  }
  getPunjabi() {
    return this.punjabi.slice();
  }
  getHistory() {
    return this.history.slice();
  }
  getSocial() {
    return this.social.slice();
  }
  getPoliticalScience() {
    return this.politicalScience.slice();
  }
  getPublicAdministration() {
    return this.publicAdministration.slice();
  }
  getGeography() {
    return this.geography.slice();
  }
  getPhysics() {
    return this.physics.slice();
  }
  getMath() {
    return this.math.slice();
  }
  getZoology() {
    return this.zoology.slice();
  }
  getChemistry() {
    return this.chemistry.slice();
  }
  getBotony() {
    return this.botony.slice();
  }
  getIndustrial() {
    return this.industrial.slice();
  }
  getComputer() {
    return this.computer.slice();
  }
  getMicrobiology() {
    return this.microbiology.slice();
  }
  getBiotechnology() {
    return this.biotechnology.slice();
  }
  getCommerce() {
    return this.commerce.slice();
  }
  getPhysical() {
    return this.physical.slice();
  }
  getEducation() {
    return this.education.slice();
  }
  getEducation1() {
    return this.education1.slice();
  }
  getSanskrit() {
    return this.sanskrit.slice();
  }
  getMusic() {
    return this.music.slice();
  }
  getCommunity() {
    return this.community.slice();
  }
}
