<div id="ict-lab">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">ICT Lab</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Well furnished ICT lab was established in 2012 equipped with more than 20 computer systems having i-3 processor with LAN connected and wi-fi facility.
                             The LCD projector and the interactive board facility is also available in this lab.
                             This ICT lab is facilitating the learning and teaching processes for the benefits of the students and the teachers.</p>
                             <div id="ictCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#ictCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#ictCarousel" data-slide-to="1"></li>
                                  <li data-target="#ictCarousel" data-slide-to="2"></li>
                                
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/ict/1.jpeg" alt="ict" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/ict/2.jpeg" alt="ict" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/ict/3.JPG" alt="ict" class="img-fluid rounded">
                                  </div>
                                 
                                </div>
                                <a class="carousel-control-prev" href="#ictCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#ictCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/ict/1.jpeg" alt="ict" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/ict/2.jpeg" alt="ict" class="img-fluid rounded">
                            </div>
                           
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>