<div id="services">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Community Services</h4>
                    <div class="card-body">
                        <p class="lead text-justify">The College is closely associated with a number of community-oriented activities and programmes either independently or in collaboration with GOs/NGOs like Red Cross, FPAI, ‘Utthan’ and other local bodies.
                             Our NCC, NSS, Youth Red Cross units along with Eco Club, Rotract Club, Red Ribbon Club, Adventure Activity Club, Legal Literacy Cell, Social Worker’s Association and Women Cell have made positive contributions in organizing and supporting various community welfare programmes in the city.
                              A remarkable attempt has been undertaken under UGC sponsored Women Empowerment and Community Upliftment programme in which vocational training in cutting and tailoring, cosmetology, embroidery along with tree plantation, medicine – distribution and health check-up camp were organized at village Gadhauli, Yamuna Nagar.
                        </p>
                        <div id="servicesCarousel" class="carousel slide mb-4" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#servicesCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#servicesCarousel" data-slide-to="1"></li>
                              <li data-target="#servicesCarousel" data-slide-to="2"></li>
                              <li data-target="#servicesCarousel" data-slide-to="3"></li>
                              <li data-target="#servicesCarousel" data-slide-to="4"></li>
                              <li data-target="#servicesCarousel" data-slide-to="5"></li>
                              <li data-target="#servicesCarousel" data-slide-to="6"></li>
                              <li data-target="#servicesCarousel" data-slide-to="7"></li>
                              <li data-target="#servicesCarousel" data-slide-to="8"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/services/4.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/services/5.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/services/6.jpg" alt="services" class="img-fluid rounded">
                              </div>

                              <div class="carousel-item">
                                <img src="/assets/img/services/7.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/services/8.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/services/9.jpg" alt="services" class="img-fluid rounded">
                              </div>

                              <div class="carousel-item">
                                <img src="/assets/img/services/10.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/services/11.jpg" alt="services" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/services/12.jpg" alt="services" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#servicesCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#servicesCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                         <!-- <div class="row text-center">
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/4.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/5.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/6.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/7.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/8.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/9.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/10.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/11.jpg" alt="services" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-12 col-md-6 mt-3">
                                <img src="/assets/img/services/12.jpg" alt="services" class="img-fluid rounded">
                            </div>
                        </div> -->

                        <iframe src="https://drive.google.com/file/d/1cY0k9Um3veNoROCromyiXsACd6Q7B3Al/preview" width="100%" height="980" allow="autoplay"></iframe>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>