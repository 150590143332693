import { Component, OnInit } from '@angular/core';
declare var $: any
@Component({
  selector: 'app-ict',
  templateUrl: './ict.component.html',
  styleUrls: ['./ict.component.scss']
})
export class IctComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Educational Trip.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Essay writing.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Expert Talk.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Extension lecture.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Field trips.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Flipped Classrooms.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Group assignment.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_GROUP PROJECTS.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_ICT TOOLS.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Mentor.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Peer tutoring.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_PPT Contest.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_PRACTICAL.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Quiz.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Seminar.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_TRAINING PROGRAMS.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_WEBINAR.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.3.1_ STUDENT CENTRIC AND ICT TOOLS/2.3.1_Workshop.pdf'];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('2021');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.six').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.six').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.ten').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.ten').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[15];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }
}
