import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-two-six-three',
  templateUrl: './ct-two-six-three.component.html',
  styleUrls: ['./ct-two-six-three.component.scss']
})
export class CtTwoSixThreeComponent implements OnInit {
  src: string = '/assets/pdf/NAAC/DVV/2.6.3_Result_5years.pdf'
  constructor() { }

  ngOnInit(): void {
  }

}
