import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-four-one',
  templateUrl: './two-four-one.component.html',
  styleUrls: ['./two-four-one.component.scss']
})
export class TwoFourOneComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
    
  }
}
