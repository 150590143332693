import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowNcteService {

  
  private showNcte:boolean=true;
  ncteChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showNcte=true;
    this.ncteChange.next(true);
  }
  hide(){
    this.showNcte=false;
    this.ncteChange.next(false);
  }
}
