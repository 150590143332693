import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ct-four-one-one',
  templateUrl: './ct-four-one-one.component.html',
  styleUrls: ['./ct-four-one-one.component.scss']
})
export class CtFourOneOneComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'Audited Balance sheet and Income and Expenditure account_2021-22',
      link:this.c241Url + 'Audited Balance sheet and Income and Expenditure account_2021-22.pdf'
    },
    {
      title:'Audited Balance sheet and Income and Expenditure account_2020-21',
      link:this.c241Url + 'Audited Balance sheet and Income and Expenditure account_2020-21.pdf'
    },
    {
      title:'Audited Balance sheet and Income and Expenditure account_2019-20',
      link:this.c241Url + 'Audited Balance sheet and Income and Expenditure account_2019-20.pdf'
    },
    {
      title:'Audited Balance sheet and Income and Expenditure account_2018-19',
      link:this.c241Url + 'Audited Balance sheet and Income and Expenditure account_2018-19.pdf'
    },
    {
      title:'Audited Balance sheet and Income and Expenditure account_2017-18',
      link:this.c241Url + 'Audited Balance sheet and Income and Expenditure account_2017-18.pdf'
    }
  ]


  // isLoading = true;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  
}
