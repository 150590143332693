import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-twenty-twentyone',
  templateUrl: './c3-twenty-twentyone.component.html',
  styleUrls: ['./c3-twenty-twentyone.component.scss']
})
export class C3TwentyTwentyoneComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Reports/';

  result:{title:string,link:string}[]=[
    {
      title:'VOLUNTARY SERVICE DURING COVID -19',
      link:this.c343Url + '32_ 2020-21_NCC_VOLUNTARY-SERVICE _report.pdf'
    },
    {
      title:'ARMED FORCED FLAG DAY',
      link:this.c343Url + '33_ 2020-21_NCCARMED-FORCED-FLAG-DAY_report.pdf'
    },
    {
      title:'SWATCHHTA PAKHWARA',
      link:this.c343Url + '34_ 2020-21_NCC_SWATCHHTA-PAKHWARA_report.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c343Url + '35_ 2020-21_NCC_CLEANLINESS-DRIVE_report.pdf'
    },
    {
      title:'NATIONAL GIRLS HEALTH SERVICE WEEK',
      link:this.c343Url + '36_ 2020-21_NCC_NATIONAL-GIRLS-HEALTH _report.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c343Url + '37_ 2020-21_NCC__ROAD-SAFETY-RALLY_report.pdf'
    },
    {
      title:'DONATION TO SAHEED NISHANT SHARMA',
      link:this.c343Url + '38_ 2020-21-NCC_DONATION _report.pdf'
    },
    {
      title:'NO PLASTIC USE ABHIYAN',
      link:this.c343Url + '39_ 2020-21_NCC_NO-PLASTIC-USE-ABHIYAN_report.pdf'
    },
    {
      title:'NASHA MUKTI DIWAS',
      link:this.c343Url + '40_ 2020-21_NCC_NASHA-MUKTI-DIWAS_report.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
