import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-one-four-one',
  templateUrl: './ct-one-four-one.component.html',
  styleUrls: ['./ct-one-four-one.component.scss']
})
export class CtOneFourOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/1.4.1.2_Action Taken Report on Feed Back.pdf',
    '/assets/pdf/NAAC/DVV/1.4 1_3_Sample Filled in feedback forms from stakeholders.pdf',
    '/assets/pdf/NAAC/DVV/1.4.1.4_Communication with the affiliating University for the Feedback.pdf',
    '/assets/pdf/NAAC/DVV/1.4.1_5 Certificate by head of the HEI.pdf',
  ];
  src: string;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  heading4=false;


  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.heading4 = false;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.heading4 = false;
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = true;
      this.src = this.pdfSrc[3];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
