<div id="e-content">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>E-Content</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">E-Content
                </li>
            </ol>
        </nav>
    </div>
    <!-- <div class="container-fluid mt-5">
        <div class="row">

            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <header class="header">
                        <h5>E-Content</h5>
                    </header>
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/e-contents/arts" (click)="checkRoute()">Arts</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/e-contents/b-ed" (click)="checkRoute()">B.Ed</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/e-contents/commerce" (click)="checkRoute()">Commerce</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/e-contents/science" (click)="checkRoute()">Science</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/e-contents/sports-science" (click)="checkRoute()">Sports Science</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div> -->
    <div class="card shadow-lg colorBlue">
        <div class="row">
            <div class="col-12 text-center mt-5">

                <h2>E-Content</h2>
                <!-- <iframe
                    src="https://docs.google.com/spreadsheets/d/1FbAsetfKDSWUzEjbnFtKYWsSIGEK_kcF/edit#gid=490603385"
                    width="100%" height="1100px" allow="autoplay"></iframe> -->
            </div>
        </div>

    </div>
</div>