import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from 'src/app/view/not-found/not-found.component';
import { NfoundComponent } from 'src/app/view/nfound/nfound.component';



@NgModule({
  declarations: [
    NotFoundComponent,
    NfoundComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    NotFoundComponent,
    NfoundComponent
  ]
})
export class NotFoundModule { }
