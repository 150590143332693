import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-five-one-two',
  templateUrl: './ct-five-one-two.component.html',
  styleUrls: ['./ct-five-one-two.component.scss']
})
export class CtFiveOneTwoComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';
  link:{name:string,src:string}[]=[
    {
      name:'5.1.2_Reports  of the events_5 years_1',
      src: this.c241Url + '5.1.2_Reports  of the events_5 years_1.pdf'
    },
    {
      name:'5.1.2_Photosgraphs of the events_5 Years_2',
      src: this.c241Url + '5.1.2_Photosgraphs of the events_5 Years_2.pdf'
    },
    {
      name:'5.1.2_Attendance sheet with signature of students_5Years_4',
      src: this.c241Url + '5.1.2_Attendance sheet with signature of students_5Years_4.pdf'
    },
    {
      name:'5.1.2_Certificates _Lang_Yoga_ICT_Add-on Courses_5 years_5',
      src: this.c241Url + '5.1.2_Certificates _Lang_Yoga_ICT_Add-on Courses_5 years_5.pdf'
    },
    {
      name:'5.1.2_List of students who received certificates_5Years_5 (A)',
      src: this.c241Url + '5.1.2_List of students who received certificates_5Years_5 (A).pdf'
    },
  ];
  selectedValue:{name:string,src:string};
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[0];

    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[3];
    }
    if (id === '5') {
      $(`five`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`three`).removeClass('show');
      this.selectedValue=this.link[4];
    }
  }

}
