<div id="heading">
    <h1>Messages</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Messages</li>
        </ol>
    </nav>
</div>

<div id="messages">
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col-12">
                <h3>Visionary Management</h3>
                <hr class="pb-2 rounded">
            </div>
        </div>
        <!---<div class="row mt-5 mb-5 line" id="patron">
            <div class="col-12 col-md-12 col-lg-4 messagePatron text-center">
                <div class="image">
                    <img src="/assets/img/message/1.jpeg" alt="Patron" class="img-fluid w-100 rounded">
                    <div class="category-banner">
                        Patron
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8 detail text-justify">
                <h2>S. Bhupinder Singh Jauhar</h2>
                <p class="lead">Guru Nanak Khalsa College was founded in 1968 when the world was celebrating 500th Birth
                    Anniversary of Sri Guru Nanak Dev Ji, a great saint and philosopher from India.
                    Right from its inception the college committed itself to follow the philosophy and teachings of this
                    great Guru ji who believed in the oneness of humanity and envisioned a casteless society where every
                    body had equal opportunity to grow and succeed.
                    Today, in its Golden Jubilee year, Guru Nanak Khalsa College, Yamuna Nagar is one of the premier
                    institutions of Haryana imparting career-oriented and ethical education to one and all.
                    It has already received top most grading and honours from NAAC and Kurukshetra University on account
                    of its outstanding achievements in the fields of academics, sports, community services and extra
                    curricular activities. The management, students and staff of the college have always striven to take
                    the college on the path of excellence through their consistent and dedicated efforts.
                    I extend my best wishes to the students, teachers and the entire team of Guru Nanak Khalsa
                    Institutions for doing tremendous work to take the college to new heights and earnestly desire that
                    students of this college prove to be important assets to our society and country.</p>
            </div>
        </div>
        <hr class="divider">-->
        <div class="row mt-5 mb-5" id="president">
            <div class="col-12 col-md-12 col-lg-4 messagePresident text-center">
                <div class="image">
                    <img src="/assets/img/message/6.png" alt="President" class="img-fluid w-100 rounded">
                    <div class="category-banner">
                        President
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8 detailP text-justify">
                <h2>S. Randeep Singh Jauhar</h2>
                <p class="lead">We at Guru Nanak Khalsa College are always engaged with activities for holistic development
                  of our students and help them become good citizens of our country. Guru Nanak Khalsa College
                  stands committed to ensure that every individual leads a happy and contended life while
                  discharging his/her duties towards society at large. We strongly believe in forward-thinking and
                  focussing on providing students with a high-quality education and leading skill to gainful
                  employment.
                </p>
                <p class="lead">Guru Nanak Khalsa College, Yamuna Nagar is one of the premier institutions of Haryana
                  imparting career-oriented and ethical education to one and all. It has already created a name for
                  itself on account of its outstanding achievements in the fields of academics, sports, community
                  services and extra- curricular activities. The management, students and staff of the college have
                  always striven to take the college on the path of excellence through their consistent and dedicated
                  efforts. Continuing with the tradition of achieving excellence, the college got re-accredited with
                  ‘A’ grade by NAAC for the third cycle as well.</p>

                <p class="lead">I wish all students and staff the very best in their noble endeavour and hope the college will
                  progress leaps and bounds in the coming days.</p>
            </div>
        </div>
        <hr class="divider">
        <div class="row mt-5 mb-5" id="principal">
            <div class="col-12 col-md-12 col-lg-4 messagePrincipal text-center">
                <div class="image">
                    <img src="/assets/img/message/7.png" alt="Principal" class="img-fluid w-100 rounded"
                        style="object-fit: cover;">
                    <div class="category-banner">
                        Principal
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8 detail text-justify">
                <h2>Dr.(Major) Harinder Singh Kang</h2>
                <p class="lead">It gives me immense pleasure and a feeling of accomplishment to head Guru Nanak Khalsa College,
Yamuna Nagar, one of the leading institutions of Haryana where students from every nook and
corner of the country receive education. The college has rendered stellar services in the fields of
education, sports, culture, sustainable development, community welfare and empowerment in its
last fifty five years. We always try to cultivate students who not only have sound academic
knowledge and skills but also have a unique ethical attitude to serve the community and nation.
Alongside a wide array of job-oriented courses, we organise a variety of programmes to improve our
students' overall personality and thinking. On behalf of our management and faculty I extend a very
warm and affectionate welcome to our new students. I am happy to tell you that your college has
won top honours in the fields of academics, sports and community welfare activities and soon you
too will be learning to contribute meaningfully to society and nation.
                </p>
                <p class="lead">The objective of the is college to provide career-oriented quality education through innovative
delivery methods. The college continues to remain committed in its objectives of nurturing its
students with high moral values and having a deep connection with society and environment. The
college has been deeply committed to the propagation of patriotism and secularism. At the same
time we need to have a balanced perspective towards nature and economic progress. Our planet
Earth needs to be nurtured with love and care. We need to care for the environment which is so vital
for our existence. At Guru Nanak Khalsa College we try to achieve this with full dedication.
                </p>
                <p class="lead">I wish you all a joyful learning experience at the college!</p>
            </div>
        </div>
    </div>
</div>
