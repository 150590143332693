import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AudioComponent } from "src/app/view/infrastructure/audio/audio.component";
import { BoysRoomComponent } from "src/app/view/infrastructure/boys-room/boys-room.component";
import { CanteenComponent } from "src/app/view/infrastructure/canteen/canteen.component";
import { CompetitiveComponent } from "src/app/view/infrastructure/competitive/competitive.component";
import { DigitalComponent } from "src/app/view/infrastructure/digital/digital.component";
import { EdusatComponent } from "src/app/view/infrastructure/edusat/edusat.component";
import { EthicsComponent } from "src/app/view/infrastructure/ethics/ethics.component";
import { GirlsRoomComponent } from "src/app/view/infrastructure/girls-room/girls-room.component";
import { GurudwaraComponent } from "src/app/view/infrastructure/gurudwara/gurudwara.component";
import { HallComponent } from "src/app/view/infrastructure/hall/hall.component";
import { HealthComponent } from "src/app/view/infrastructure/health/health.component";
import { HostelComponent } from "src/app/view/infrastructure/hostel/hostel.component";
import { IctLabComponent } from "src/app/view/infrastructure/ict-lab/ict-lab.component";
import { InfrastructureComponent } from "src/app/view/infrastructure/infrastructure.component";
import { KheloComponent } from "src/app/view/infrastructure/khelo/khelo.component";
import { LabsComponent } from "src/app/view/infrastructure/labs/labs.component";
import { LanguageLabComponent } from "src/app/view/infrastructure/language-lab/language-lab.component";
import { AboutComponent } from "src/app/view/infrastructure/library/about/about.component";
import { LibraryComponent } from "src/app/view/infrastructure/library/library.component";
import { OpenAccessComponent } from "src/app/view/infrastructure/library/open-access/open-access.component";
import { SubscribedComponent } from "src/app/view/infrastructure/library/subscribed/subscribed.component";
import { PlayFieldsComponent } from "src/app/view/infrastructure/play-fields/play-fields.component";
import { RecordingComponent } from "src/app/view/infrastructure/recording/recording.component";
import { SeminarHallComponent } from "src/app/view/infrastructure/seminar-hall/seminar-hall.component";
import { VirtualComponent } from "src/app/view/infrastructure/virtual/virtual.component";

const route:Route[]=[
    {path:'',component:InfrastructureComponent,children:[
        {path:'library',component:LibraryComponent,children:[
            {path:'about',component:AboutComponent},
            {path:'subscribed',component:SubscribedComponent},
            {path:'open-access',component:OpenAccessComponent}
        ]},
        {path:'Girls-Hostel',component:HostelComponent},
        {path:'Gymnasium-Hall',component:HallComponent},
        {path:'Gurudwara-Sahib',component:GurudwaraComponent},
        {path:'Labs',component:LabsComponent},
        {path:'Language-Lab',component:LanguageLabComponent},
        {path:'ICT-Lab',component:IctLabComponent},
        {path:'Canteen',component:CanteenComponent},
        {path:'Play-Fields',component:PlayFieldsComponent},
        {path:'Girls-Common-Room',component:GirlsRoomComponent},
        {path:'Boys-Common-Room',component:BoysRoomComponent},
        {path:'EDUSAT',component:EdusatComponent},
        {path:'virtual',component:VirtualComponent},
        {path:'recording',component:RecordingComponent},
        {path:'audio',component:AudioComponent},
        {path:'health',component:HealthComponent},
        {path:'khelo',component:KheloComponent},
        {path:'competitive',component:CompetitiveComponent},
        {path:'digital',component:DigitalComponent},
        {path:'ethics',component:EthicsComponent},
        {path:'seminar-hall',component:SeminarHallComponent},
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class InfrastructureRoutingModule{}