<div id="unique" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="font-weight-bold">Infrastructure at a Glance</h2>
            </div>
        </div>
        <div id="uniqueCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#uniqueCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#uniqueCarousel" data-slide-to="1"></li>
                <li data-target="#uniqueCarousel" data-slide-to="2"></li>
                <li data-target="#uniqueCarousel" data-slide-to="3"></li>
                <li data-target="#uniqueCarousel" data-slide-to="4"></li>
              </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row unique-detail">

                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/1.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Khelo India Center</h4>
                                <!-- <p> of Sports Authority of India in Weightlifting</p> -->
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/2.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Seminar Hall</h4>
                                <!-- <h4>Overall Championship Winner</h4>
                                <p>in Sports at Kurukshetra University Kurukshetra, in both Men and Women Sections</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/3.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Lecture Recording Studio</h4>
                                <!-- <h4>Scholarships</h4>
                                <p>65Lacs Awarded to Students by Jamna Auto Industries, Govt. of Haryana and Kurukshetra University. </p> -->
                               
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row unique-detail">

                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/4.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Audio visual Hall </h4>
                                
                                <!-- <h4>Wi-Fi Enabled Campus</h4>
                                <p> with facility of LMS for Blended Mode of Learning for latest requirements.</p> -->
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/5.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Multipurpose <br> Gym Hall</h4>
                                <!-- <p>Multipurpose Gymnasium Hall for Overall Development of Students.</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/6.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Fitness Hall</h4>
                                <!-- <h4>SportsHub</h4>
                                <p>Participations and Positions of more  than 100 Sports Persons Every Year in National and International Games</p> -->
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row unique-detail">

                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/7.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>15 Smart Classrooms </h4>
                                <!-- <h4>Cultural and Talent</h4>
                                <p> Fests to Promote Extracurricular Activities.</p> -->
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/8.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Well Equipped Labs</h4>
                                <!-- <h4>Alumini</h4>
                                <p>Well Placed Alumini Motivating Juniors to Shine in Their Fields.</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/9.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>High Speed <br> Internet With <br> Wi-Fi Access </h4>
                                <!-- <h4>Courses</h4>
                                <p>10 Add-On Courses</p> -->
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row unique-detail">

                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/10.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Well Stocked Library With Competitive Exams Corner and E-learning Section</h4>
                                <!-- <h4>VIP Visit</h4>
                                <p> 20+ Celebrity/VIP Visit</p> -->
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/11.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Research and Innovation Centre</h4>
                                <!-- <h4>NCC</h4>
                                <p>16+ Cadets Serving The Nation Through NCC</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/12.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Open Air Theatre For Campus  Activities</h4>
                                <!-- <h4>Faculties</h4>
                                <p>47+ Ph.D. Holder Faculties</p> -->
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row unique-detail">

                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/13.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Vibrant Environment For Extracurricular Activities</h4>
                                <!-- <h4>VIP Visit</h4>
                                <p> 20+ Celebrity/VIP Visit</p> -->
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/14.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Language Laboratory</h4>
                                <!-- <h4>NCC</h4>
                                <p>16+ Cadets Serving The Nation Through NCC</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                        <div class="card mx-auto" >
                            <div class="image"><img src="/assets/svg/unique/15.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                            <div class="card-body d-flex flex-column justify-content-center">
                                <h4>Learning Management System</h4>
                                <!-- <h4>Faculties</h4>
                                <p>47+ Ph.D. Holder Faculties</p> -->
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#uniqueCarousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#uniqueCarousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        <!-- <div class="row unique-detail">

            <div class="col-12 col-md-6 col-lg-4 mb-5">
                <div class="card mx-auto" >
                    <div class="image"><img src="/assets/svg/unique/1.svg" alt="Games" class="img-fluid rounded-circle h-100"></div>
                    <div class="card-body d-flex flex-column justify-content-center">
                        <h4>Khelo India Center</h4>
                        <p> of Sports Authority of India in Weightlifting</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-5">
                <div class="card mx-auto" >
                    <div class="image"><img src="/assets/svg/unique/2.svg" alt="Champion" class="img-fluid rounded-circle h-100"></div>
                    <div class="card-body d-flex flex-column justify-content-center">
                        <h4>Overall Championship Winner</h4>
                        <p>in Sports at Kurukshetra University Kurukshetra, in both Men and Women Sections</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-5 mx-auto">
                <div class="card mx-auto" >
                    <div class="image"><img src="/assets/svg/unique/3.svg" alt="" class="img-fluid rounded-circle h-100"></div>
                    <div class="card-body d-flex flex-column justify-content-center">
                        <h4>SportsHub</h4>
                        <p>Participations and Positions of more  than 100 Sports Persons Every Year in National and International Games</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>



<!-- <div id="unique">
    <div class="container-fluid">
        <div class="col-12">
            <h2 class="font-weight-bold text-left">What Makes GNKC Unique?</h2>
        </div>
    </div>
    <div class="unique-detail">
        <div class="container-fluid">
            <div class="row d-flex flex-row-reverse">
                <div class="card top-card card1">
                    <div class="card-body">
                        <h1>47+</h1>
                        
                        <h5>Highly Qualified Faculty Members With PhD.
                        </h5>
                    </div>
                </div>
                <div class="card top-card card2">
                    <h1>16+</h1>
                        
                        <h5> Cadets Selected as Commissioned Officers in the Indian Army.
                        </h5>
                </div>
                <div class="card  top-card card3">
                    <h1>10+</h1>
                        
                        <h5> Add – On Courses at Graduation Level for Every Student to Sharpen their Skills.
                        </h5>
                </div>
            </div>
            <div class="card bottom-card">
                <div class="card-body">
                    <h1>65 Lacs+</h1>
                        
                        <h5 class="text-left"> Scholarships Awarded to Students by Jamna Auto Industries, Govt. of  Haryana and Kurukshetra University to Enhance the Talents of Meritorious Students, Outstanding Sportspersons, Economically Weaker Students and Minorities Students.
                        </h5>
                </div>
            </div>
        </div>
        
    </div>
</div> -->