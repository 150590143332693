<div id="women">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Women Cell</h4>
                    <div class="card-body">
                        <p class="lead text-justify">Women cell of the college is managed by senior teachers from all faculties and it organizes programs on various issues such as Women Empowerment, Gender Sensitization, Beti Bachao Beti Padhao, Women Literacy, Women Safety, Women Fitness and health awareness for all the girl students of college. 
                        </p>
                        <p class="lead text-justify">The programs are usually in the form of expert-talks, seminars, rallies, declamations, debates, slogan writing, poetical & painting competitions & workshops.
                        </p>
                         <div class="row">
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/1.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/2.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/3.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/4.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/5.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/6.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/7.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/8.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/9.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/10.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/11.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/12.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/13.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/14.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/15.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/16.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/17.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/18.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/19.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/20.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/21.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/22.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/23.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/24.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/25.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/26.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/27.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/28.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/29.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/30.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/31.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/women/32.jpeg" alt="women" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>