import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-b-ed',
  templateUrl: './b-ed.component.html',
  styleUrls: ['./b-ed.component.scss']
})
export class BEdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
