<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria5"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>5.2.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">List of students placed</a>
                    </li>
                    <li class="two">
                        <a (click)="changeRoute('2')">Placement of outgoing students</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">Placement proofs</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div id="five-two-one" *ngIf="showTable">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <h4 class="card-title text-center" *ngIf="heading1">List of students placed</h4>
                                <div class="card-body text-justify">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <!-- <th scope="col">S.NO.</th> -->
                                                <th scope="col">TITLE</th>
                                                <th scope="col">URL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of result; let i = index;">
                                                <!-- <th scope="row">{{i+41}}</th> -->
                                                <td class="title">{{item.title}}</td>
                                                <td class="link"><a href="{{item.link}}" target="_blank"><i
                                                            class="fas fa-eye"></i></a> </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <div class="container-fluid pdf" *ngIf="!showTable">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading2">List of students placed</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">Placement of outgoing students</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>