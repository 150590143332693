<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" *ngIf="showSideMenu">
            <a routerLink="/naac/ssr/criteria2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>2.7</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-7/2-7-1" >2.7.1</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>