import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';

@Component({
  selector: 'app-kiranpal',
  templateUrl: './kiranpal.component.html',
  styleUrls: ['./kiranpal.component.scss']
})
export class KiranpalComponent implements OnInit {
  result:{name:string ,title:string, link:string} [] = [];
  constructor(private ssrService : SsrService) { }

  ngOnInit(): void {
    this.result = this.ssrService.getKiranpal();
  }

}
