import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c5-five-one-one',
  templateUrl: './c5-five-one-one.component.html',
  styleUrls: ['./c5-five-one-one.component.scss']
})
export class C5FiveOneOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-5/5.1.1_Scholarship data/2021-22 Scholarship.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.1.1_Scholarship data/2020-21 Scholarship.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.1.1_Scholarship data/2019-20 Scholarship.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.1.1_Scholarship data/2018-19 Scholarship.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.1.1_Scholarship data/2017-18 scholarship.pdf', ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    
    

  }
  hideLoader() {
    this.isLoading = false;
  }

}
