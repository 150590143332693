import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-twentyone-twentytwo',
  templateUrl: './c3-twentyone-twentytwo.component.html',
  styleUrls: ['./c3-twentyone-twentytwo.component.scss']
})
export class C3TwentyoneTwentytwoComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Reports/';

  result:{title:string,link:string}[]=[
    {
        title: 'HARYANA DAY',
        link:this.c343Url + '41_2021-22_NCC_HARYANA-DAY_report.pdf'
    },
    {
        title: 'ARMED FORCES FLAG DAY',
        link:this.c343Url + '42_2021-22_NCC_ARMED-FORCES-FLAG-DAY_report.pdf'
    },
    {
        title: 'NADI UTSAV',
        link:this.c343Url + '43_2021-22_NCC_NADI-UTSAV_report.pdf'
    },
    {
        title: 'NISHANT SHARMA SAHEEDI DIWAS',
        link:this.c343Url + '44_2021-22_NCC_NISHANT-SHARMA-SAHEEDI-DIWASs_report.pdf'
    },
    {
        title: 'OPPORTUNITY FOR GIRLS IN ARMY',
        link:this.c343Url + '45_2021-22_NCC_OPPORTUNITY-FOR-GIRLS-IN-ARMY_report.pdf'
    },
    {
        title: 'WORLD DRUG DAY RALLY',
        link:this.c343Url + '46_2021-22_NCC_WORLD-DRUG-DAY-RALLY_Report.pdf'
    },
    {
        title: 'ONE DAY VACCINATION CAMP',
        link:this.c343Url + '47_2021-22_NSS_ONE-DAY-VACCINATION-CAMP_Report.pdf'
    },
    {
        title: 'CLEANINESS DRIVE',
        link:this.c343Url + '48_2021-22_NSS_CLEANINESS-DRIVE_Report.pdf'
    },
    {
        title: 'AATAMNIRBHAR PROGRAM',
        link:this.c343Url + '49_2021-22_NSS_AATAMNIRBHAR-PROGRAM_Report.pdf'
    },
    {
        title: 'MEGHA COVID VACCINATION CAMP',
        link:this.c343Url + '50_2021-22_NSS_MEGHA-COVID-VACCINATION-CAMP_Report.pdf'
    },
    {
        title: 'NSS DAY CELEBRATION',
        link:this.c343Url + '51_2021-22_NSS__NSS-DAY-CELEBRATION_Report.pdf'
    },
    {
        title: 'PULSE POLIO CAMPAIGN',
        link:this.c343Url + '52_2021-22_NSS_PULSE-POLIO-CAMPAIGN_Report.pdf'
    },
    {
        title: 'POSHANMAH CAMP',
        link:this.c343Url + '53_2021-22_NSS_POSHANMAH-CAMP_Report.pdf'
    },
    {
        title: 'UNIVERSITY LEVEL PRE-RD TRIAL CAMP',
        link:this.c343Url + '54_2021-22_NSS__UNIVERSITY-LEVEL-CAMP_Report.pdf'
    },
    {
        title: 'NORHT ZONE PRE-RD CAMP, BIYANI  UNIVERSITY, JAIPUR',
        link:this.c343Url + '55_2021-22_NSS_NORHT-ZONE-CAMP_JAIPUR_Report.pdf'
    },
    {
        title: 'STATE LEVEL NSS CAMP',
        link:this.c343Url + '56_2021-22_NSS_STATE-LEVEL-NSS-CAMP_Report.pdf'
    },
    {
        title: 'NATIONAL SEMINAR AND CLEANINESS DRIVE AT GVT COLLEGE AHARWALA',
        link:this.c343Url + '57_2021-22_NSS_NATIONAL-SEMINAR-AND-CLEANINESS_Report.pdf'
    },
    {
        title: 'UNIVERSITY LEVEL NSS CAMP, Mullana',
        link:this.c343Url + '58_2021-22_NSS_UNIVERSITY-LEVEL-NSS-CAMP_Report.pdf'
    },
    {
        title: 'NATIONAL INTERGRATION CAMP AT KUK',
        link:this.c343Url + '59_2021-22_NSS_NATIONAL-INTERGRATION-CAMP_Report.pdf'
    },
    {
        title: 'ARMED FORCED FLAG DAY DONATION COLLECTED CAMP',
        link:this.c343Url + '60_2021-22_NSS_ARMED-FORCED-FLAG-DAY-DONATION _Report.pdf'
    },
    {
        title: 'SEMINAR ON GURUPURAB',
        link:this.c343Url + '61_2021-22_NSS_SEMINAR-ON-GURUPURAB_Report.pdf'
    },
    {
        title: 'COLLEGE FOUNDATION DAY CELEBRATION',
        link:this.c343Url + '62_2021-22_NSS_COLLEGE-FOUNDATION-DAY_Report.pdf'
    },
    {
        title: 'GURU RAVIDAS JAYANTI',
        link:this.c343Url + '63_2021-22_NSS_GURU-RAVIDAS-JAYANTI_Report.pdf'
    },
    {
        title: 'ANPURNA WEEK',
        link:this.c343Url + '64_2021-22_Rotract_ANPURNA-WEEK_Report.pdf'
    },
    {
        title: 'MANGO DONATION ',
        link:this.c343Url + '65_2021-22_Rotract_MANGO-DONATION_Report.pdf'
    },
    {
        title: 'SHAHADAT',
        link:this.c343Url + '66_2021-22_Rotract_SHAHADAT_Report.pdf'
    },
    {
        title: 'AZADI DIVAS',
        link:this.c343Url + '67_2021-22_Rotract_AZADI DIVAS_Report.pdf'
    },
    {
        title: 'RAKHI WITH MEN IN KHAKHI',
        link:this.c343Url + '68_2021-22_Rotract_RAKHI WITH MEN IN KHAKHI_Report.pdf'
    },
    {
        title: 'SIKSHA ',
        link:this.c343Url + '69_2021-22_Rotract_SIKSHA_Report.pdf'
    },
    {
        title: 'HEALTH CHECKUP CAMP',
        link:this.c343Url + '70_2021-22-Health-check-up_Report.pdf'
    },
    {
        title: 'KHUSIYO KI DIWALI',
        link:this.c343Url + '71_2021-22_Rotract_KHUSIYO-KI-DIWALI_Report.pdf'
    },
    {
        title: 'MASK DISTRIBUTION DRIVE',
        link:this.c343Url + '72_2021-22_Rotract_MASK-DISTRIBUTION-DRIVE_Report.pdf'
    },
    {
        title: 'CHRISTMAS DAY',
        link:this.c343Url + '73_2021-22_Rotract_CHRISTMAS-DAY_Report.pdf'
    },
    {
        title: 'SMILE DAY',
        link:this.c343Url + '74_2021-22_Rotract_SMILE DAY_Report.pdf'
    },
    {
        title: 'NEW YEAR CELEBRATION',
        link:this.c343Url + '75_2021-22_Rotract_NEW-YEAR-CELEBRATION_Report.pdf'
    },
    {
        title: 'CLOTHES DONATION DRIVE',
        link:this.c343Url + '76_2021-22_Rotract_CLOTHES-DONATION-DRIVE_Report.pdf'
    },
    {
        title: 'BONFIRE',
        link:this.c343Url + '77_2021-22_Rotract_BONFIRE_Report.pdf'
    },
    {
        title: 'MAHADAN 7.0 FIRST CAMP',
        link:this.c343Url + '78_2021-22_Rotract_MAHADAN-7.0-FIRST-CAMP_Report.pdf'
    },
    {
        title: 'MAHADAN 7.0 SECOND CAMP',
        link:this.c343Url + '79_2021-22_Rotract_MAHADAN-7.0-SECOND-CAMP_Report.pdf'
    },
    {
        title: 'MASS MARRIAGE',
        link:this.c343Url + '80_2021-22_Rotract_MASS-MARRIAGE_Report.pdf'
    },
    {
        title: 'ROTTI DAY',
        link:this.c343Url + '81_2021-22_Rotract_ROTTI-DAY_Report.pdf'
    },
    {
        title: 'CHABEEL ',
        link:this.c343Url + '82_2021-22_Rotract_CHABEEL_Report.pdf'
    },
    {
        title: 'COVID-19 VACCINATION CAMP ',
        link:this.c343Url + '83_ 2021-22_YRC_COVID-19-VACCINATION-CAMP_Report.pdf'
    },
    {
        title: 'COVID-19 VACCINATION CAMP ',
        link:this.c343Url + '84_ 2021-22_YRC_COVID-19-VACCINATION-CAMP_Report.pdf'
    },
    {
        title: 'POLIO DROP DUTY',
        link:this.c343Url + '85_ 2021-22_YRC_POLIO-DROP-DUTY__Report.pdf'
    },
    {
        title: 'BLOOD,SUGAR,EYE,&HEALTH CHECKUP CAMP',
        link:this.c343Url + '86_ 2021-22_YRC_HEALTH-CHECKUP-CAMP_Report.pdf'
    },
    {
        title: 'GURUPARV CELEBRATION',
        link:this.c343Url + '87_ 2021-22_YRC_GURUPARV-CELEBRATION_Report.pdf'
    },
    {
        title: 'POSTER MAKING &SLOGAN WRITING COMPITITION ON THEME OF BLOOD DONATION',
        link:this.c343Url + '88_ 2021-22_YRC_BLOOD-DONATION-POSTER-MAKING_Report.pdf'
    },
    {
        title: 'BLOOD DONATION CAMP',
        link:this.c343Url + '89_ 2021-22_YRC_BLOOD-DONATION-CAMP__Report.pdf'
    },
    {
        title: '\"\"\"ME AND MY BUDDY\"\"(TO HELP OTHER)DURING LOCKDOWN \"',
        link:this.c343Url + '90_ 2021-22_YRC_ME-AND-MY-BUDDY_Report.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
