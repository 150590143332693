import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-co-po',
  templateUrl: './co-po.component.html',
  styleUrls: ['./co-po.component.scss']
})
export class CoPoComponent implements OnInit {
  pdfSrc:string[]=[
    '/assets/pdf/B. Pharmacy.pdf',
    '/assets/pdf/M. Pharm All CO PO.pdf',
  ];
  src:string;

  isLoading=true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('B. Pharmacy');
  }

  changeRoute(year:string){
    this.isLoading=true;
    if(year=='B. Pharmacy'){
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src=this.pdfSrc[0];
    }
    if(year=='M. Pharmacy'){
      $('.first').removeClass('active');
      $('.second').addClass('active');
      this.src=this.pdfSrc[1];
    }

  }
  hideLoader(){
    this.isLoading=false;
  }

}
