import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c5-five-two-one',
  templateUrl: './c5-five-two-one.component.html',
  styleUrls: ['./c5-five-two-one.component.scss']
})
export class C5FiveTwoOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Higher Education/21-22 higher edu final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Higher Education/20-21 hugher edu final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Higher Education/19-20 higher edu. final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Higher Education/18-19 higher edu. final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Higher Education/17-18 higher edu. final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Placement/2021- 22 Placement final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Placement/2020-21 Placement Final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Placement/2019-20 Placement Final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Placement/2018-19 Placement final.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.2.1 Placement/2017-18 Placement Final.pdf',];
  src: string;
  constructor() { }

  isLoading = false;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[3];
    }
    if (id === '5') {
      $(`five`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[4];
    }
    if (id === '6') {
      $(`six`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[5];
    }
    if (id === '7') {
      $(`seven`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`five`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[6];
    }
    if (id === '8') {
      $(`eight`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`five`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[7];
    }
    if (id === '9') {
      $(`nine`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`five`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[8];
    }
    if (id === '10') {
      $(`ten`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`five`).removeClass('show');
      this.src = this.pdfSrc[9];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }

}
