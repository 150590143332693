<div class="container course-detail">
  <div class="box-default3 shadow-lg">
    <h4>M.Sc. (Two Year Duration)</h4>
    <hr class="pb-1 rounded">
    <div class="row">
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/25.svg">
                  <h3>Biotechnology</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.Sc. Biotechnology</p>
                  <p>(20 seats)</p>
                      <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/26.svg">
                  <h3>Mathematics</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.Sc. Mathematics</p>
                  <p>(60 seats)</p>
                      <a  class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <!-- <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/27.svg">
                  <h3>Industrial Chemistry (Pharmaceuticals)</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.Sc. Ind. Chem. (Pharmaceuticals)</p>
                  <p>(45 seats)</p>
                      <a class="btn btn-block" (click)="setModal(2)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div> -->
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/28.svg">
                  <h3>Physics</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.Sc. Physics</p>
                  <p>(30 seats)</p>
                      <a class="btn btn-block" (click)="setModal(3)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>
  
  
 
  
</div>
<div class="container course-detail mt-5">
  <div class="box-default3 shadow-lg">
    <h4>B.Sc. (Three Year Duration)</h4>
    <hr class="pb-1 rounded">
    <div class="row">
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/2.svg">
                  <h3>Life Sciences</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>Life Sciences</p>
                  <!-- <p>(80 seats)</p> -->
                      <a class="btn btn-block" (click)="setBModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/1.svg">
                  <h3>Physical Sciences</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>Physical Sciences</p>
                  <!-- <p>Physical Sciences</p> -->
                      <a  class="btn btn-block" (click)="setBModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <!-- <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/29.svg">
                  <h3>Computer Science</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.Sc. (Computer Science)</p>
                      <a class="btn btn-block" (click)="setBModal(2)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/30.svg">
                  <h3>Computer Application</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.Sc. (Computer Application)</p>
                      <a class="btn btn-block" (click)="setBModal(3)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/31.svg">
                  <h3>Industrial Chemistry</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.Sc. (Industrial Chemistry)</p>
                      <a class="btn btn-block" (click)="setBModal(4)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/32.svg">
                  <h3>Industrial Microbiology</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.Sc. (Industrial Microbiology)</p>
                      <a class="btn btn-block" (click)="setBModal(5)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div> -->
      <!-- <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/32.svg">
                  <h3>Industry Chemistry</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.Sc. (Industry Chemistry)</p>
                      <a class="btn btn-block" (click)="setBModal(6)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div> -->
    </div>
  </div>
  
 
 
  
</div>
<div class="container course-detail mt-5">
  <div class="box-default3 bca  shadow-lg">
    <h4>B.C.A (Three Year Duration)</h4>
    <hr class="pb-1 rounded">
    <div class="row">
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/8.svg">
                  <h3>B.C.A.</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.C.A. (Bachelor of Computer Applications)</p>
                  <p>AICTE Approved</p>
                      <a class="btn btn-block" (click)="setBModal(7)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  
  
 
  
</div>

<div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="courseModelTitle">{{ modalTitle }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 *ngIf="title" class="title" [innerHTML]="title"></h5>
        <h3 *ngIf="creteria1" class="creteria1" [innerHTML]="creteria1"></h3>
        <p *ngIf="sem1" class="sem1" [innerHTML]="sem1"></p>
        <ol *ngIf="sub && sub.length > 0" class="sub">
          <li *ngIf="sub[0]" class="sub1" [innerHTML]="sub[0]"></li>
          <li *ngIf="sub[1]" class="sub2" [innerHTML]="sub[1]"></li>
          <li *ngIf="sub[2]" class="sub3" [innerHTML]="sub[2]"></li>
        </ol>
        <h3 *ngIf="creteria2" class="creteria2" [innerHTML]="creteria2"></h3>
        <p *ngIf="sem2" class="sem2" [innerHTML]="sem2"></p>
        <ol *ngIf="sem2b && sem2b.length > 0" class="sem2b">
          <li *ngIf="sem2b[0]" class="sem2b1" [innerHTML]="sem2b[0]"></li>
          <li *ngIf="sem2b[1]" class="sem2b2" [innerHTML]="sem2b[1]"></li>
          <li *ngIf="sem2b[2]" class="sem2b3" [innerHTML]="sem2b[2]"></li>
        </ol>
        <h5 *ngIf="compHeading" class="comp-heading" [innerHTML]="compHeading"></h5>
        <ol *ngIf="compDetail && compDetail.length > 0" class="comp-detail">
          <li *ngIf="compDetail[0]" class="comp-detail1" [innerHTML]="compDetail[0]"></li>
          <li *ngIf="compDetail[1]" class="comp-detail2" [innerHTML]="compDetail[1]"></li>
          <li *ngIf="compDetail[2]" class="comp-detail3" [innerHTML]="compDetail[2]"></li>
        </ol>
        <div *ngIf="poPso" class="po-pso" [innerHTML]="poPso"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="courseModelTitle"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <!-- <div class="modal-body">
        <h5 class="title"></h5>
        <h3 class="creteria1"></h3>
        <p class="sem1"></p>
        <ol class="sub">
          <li class="sub1"></li>
          <li class="sub2"></li>
          <li class="sub3"></li>
        </ol>
        <h3 class="creteria2"></h3>
        <p class="sem2"></p>
        <ol class="sem2b">
          <li class="sem2b1"></li>
          <li class="sem2b2"></li>
          <li class="sem2b3"></li>
        </ol>
        <h5 class="comp-heading"></h5>
        <ol class="comp-detail">
          <li class="comp-detail1"></li>
          <li class="comp-detail2"></li>
          <li class="comp-detail3"></li>
        </ol>
        <div class="po-pso">
          
        </div>
      </div> -->
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div> -->