import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsrService {
  url = 'https://gnkc.ac.in/assets/pdf/NAAC/ppt/';
  private ppt:{name:string ,title:string, link:string} [] = 
  [
    {
        "name": "DR AMANDEEP KAUR",
        "title": "CLASSICAL THEORY OF EMPLOYEMENT",
        "link": this.url + "classical theory ppt-22.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INDIFFERENCE CURVE",
        "link": this.url + "consumer equilibrium ppt-15.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "CONSUMPTION FUNCTION",
        "link": this.url + "consumption ppt-7.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "PRICE ELASTICITY OF DEMAND",
        "link": this.url + "Elasticity-Of-Demand ppt-4.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "ELASTICITY OF DEMAND",
        "link": this.url + "Elasticity-Of-Demand ppt.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "HICKS THEORY OF TRADE CYCLE",
        "link": this.url + "Hicks Theory ppt-14.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INCOME ELASTICITY OF DEMAND",
        "link": this.url + "Income elasticity ppt-5.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INDIFFERENCE CURVE ANALYSIS",
        "link": this.url + "Indifference curve ppt-9.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INFLATION",
        "link": this.url + "Inflation ppt-8.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INVESTMENT MULTIPLIER",
        "link": this.url + "investment multiplier ppt-2.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "INVESTMENT FUNCTION",
        "link": this.url + "investment ppt-16.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "LAW OF DEMINISHING MARGINAL UTILITY",
        "link": this.url + "Law of diminishing marginal utility ppt-6.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "THEORY OF COST",
        "link": this.url + "Modern theory of cost ppt-18.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "NATIONAL INCOME",
        "link": this.url + "national income ppt-1.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "PHASES OF TRADE CYCLE",
        "link": this.url + "Phases of trade cycle ppt-12.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "LAW OF VARIABLE PROPORTIONS",
        "link": this.url + "Production variable proportion ppt-10.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "LAW OF RETURNS TO SCALE",
        "link": this.url + "Returns to scale ppt-11.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "SAMUELSON THEORY OF TRADE CYCLE",
        "link": this.url + "Samuelson Theory ppt-13.pdf"
    },
    {
        "name": "DR AMANDEEP KAUR",
        "title": "TRADITIONAL THEORY OF COST",
        "link": this.url + "Traditional theory of cost ppt-17.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "UNIT 2",
        "link": this.url + "WEB DESIGNING FUNDAMENTALS-Website bca v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "UNIT 1",
        "link": this.url + "WEB DESIGNING FUNDAMENTALS-Introduction to WWW bca v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "HTML",
        "link": this.url + "WEB DESIGNING FUNDAMENTALS-Introduction to HTML BCA V SEM.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "IMAGES,TABLES,FORMS AND FRAMES",
        "link": this.url + "WEB DESIGNING FUNDAMENTALS-Images  Table,Form _ Frames in HTML BCA V SEM.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "TOOLS OF STRUCTURED ANALYSIS",
        "link": this.url + "Tools of Structured Analysis.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SYSTEM PLANNING",
        "link": this.url + "System planning.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SYSTEM DEVELOPMENT LIFE CYCLE",
        "link": this.url + "System Development Life Cycle.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "STRUCTURED ANALYSIS AND TOOLS",
        "link": this.url + "SOFTWARE ENGG.-Structured Analysis _ Tools bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "PROJECT MONITORING AND SOFTWARE TESTING",
        "link": this.url + "SOFTWARE ENGG.-Software Testing Strategies bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SOFTWARE PROJECT PLANNING",
        "link": this.url + "SOFTWARE ENGG.-Software Project Planning bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SOFTWRAE MAINTENANCE",
        "link": this.url + "SOFTWARE ENGG.-Software Maintenance bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SDLC, PROJECT MODELS",
        "link": this.url + "SOFTWARE ENGG.-SDLC bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SOFTWARE ENGINEERING",
        "link": this.url + "SOFTWARE ENGG.-Introduction bca iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SYSTEM CONCEPT",
        "link": this.url + "sad1.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "MULTIMEDIA TOOLS",
        "link": this.url + "MMT-Unit 1 BCA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "MULTIMEDIA TOOLS",
        "link": this.url + "MMT-Multimedia Tools BCA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "IMAGES",
        "link": this.url + "MMT-Images BCA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "IMAGE AND VIDEO COMPRESSION",
        "link": this.url + "MMT-Image _ Video Compression BCA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "DIGITAL AUDIO",
        "link": this.url + "MMT-Digital Audio BCA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "PRINTERS",
        "link": this.url + "FCWOS-Printers BSc CA i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "NUMBER SYSTEM",
        "link": this.url + "FCWOS-Number System BSc CA i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "MONITOR",
        "link": this.url + "FCWOS-Monitor BSc CA i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "COMPUTER GENERATIONS",
        "link": this.url + "FCWOS-Computer Generations BSc CA i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "WORKING WITH GRAPHICS",
        "link": this.url + "DTP-Working With Graphics BSc CA v SEM.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "PAGEMKER",
        "link": this.url + "DTP-Pagemaker BSc CA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "EDITING PUBLICATION ",
        "link": this.url + "DTP-Editing Publication BSc CA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "DESKTOPPUBLISHING",
        "link": this.url + "DTP-Desktop Publishing BSc CA v sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "TREE TRAVERSAL",
        "link": this.url + "DS-Tree Traversal BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "STRINGS",
        "link": this.url + "DS-Strings BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "STACK AND QUEUE",
        "link": this.url + "DS-Stack _ Queue BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "LINKED LIST",
        "link": this.url + "DS-Linked List BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "LINKED LIST AND TYPES",
        "link": this.url + "DS-Linked List _ Its Types BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "INTRODUCTION TO DATA STRUCTURE",
        "link": this.url + "DS-Introduction to Data Structures BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "GRAPHS",
        "link": this.url + "DS-Graphs BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "ARRAYS",
        "link": this.url + "DS-Arrays BSc CS iii sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "STORAGE DEVICES",
        "link": this.url + "CPF-Storage Devices BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "SEARCHING,SORTING AND MERGING",
        "link": this.url + "CPF-Searching,Sorting _ Merging BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "PROBLEM SOLVING CONCEPT",
        "link": this.url + "CPF-Problem Solving Concepts Bsc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "OPERATING SYSTEM",
        "link": this.url + "CPF-Operating System BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "COMPUTER LANGUAGES",
        "link": this.url + "CPF-Computer Languages BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "COMPUTER HISTORY",
        "link": this.url + "CPF-Computer History BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "COMPUTER HARDWARE AND SOFTWARE",
        "link": this.url + "CPF-Computer Hardware _ Software BSc CS i sem.pdf"
    },
    {
        "name": "PROF ANURADHA",
        "title": "COMPUTER FUNDAMENTALS",
        "link": this.url + "CPF-Computer Fundamentals BSc CS i sem.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "BA3 SEMESTER HISTORY SYLLABUS",
        "link": this.url + "BA3 SEMESTER HISTORY SYLABUS.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "HISTORY OF MUGAL EMPEROR HUMAYU",
        "link": this.url + "HISTORY OF MUGAL EMPEROR HUMAYU.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "MAP WORK",
        "link": this.url + "MAP  PPT [1526 AD].pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "MUGAL EMPIRE AT THE DEATH OF AKBAR",
        "link": this.url + "MAP PPT[ AKBAR DEATH ].pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "MAJOR CENTERS OF THE REVOLT OF 1857 AD",
        "link": this.url + "MAP WORK—PPT 1857 REVOLT.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "ROMAN CIVILIZATION",
        "link": this.url + "PPT ,ROMAN CIVILIZATION.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "EVOLUTION OF ISLAMIC STATE",
        "link": this.url + "PPT ABBASID CALIPH.pdf"
    },
    {
        "name": "DR ANURAG",
        "title": "SHERSHAH SURI AND HIS ADMINISTRATION",
        "link": this.url + "SHERSHAH SURI AND HIS ADMINISTRATION.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "MAKETING REASERACH",
        "link": this.url + "CHAPTER 01_ Marketing_Research_upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "MAKETING REASERACH PROCESS",
        "link": this.url + "CHAPTER 02_ MARKETING_ RESEARCH_ PROCESS_ upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "DEFINING THE MARKETING RESEARCH PROBLEM",
        "link": this.url + "CHAPTER 03_DEFINING_THE_MARKETING_ RESEARCH_ PROBLEM_upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "EXPLORATORY RESEARCH DESIGN",
        "link": this.url + "CHAPTER 4 EXPLORATORY RESEARCH DESIGN.upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "PRIVATE COMPANY",
        "link": this.url + "CHAPTER 4 Private Company ..upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "DESCRIPTIVE REASEARCH",
        "link": this.url + "CHAPTER 05 _Descriptive Research design...2...GNKC 2020.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "MEMORANDUM OF ASSOCIATION",
        "link": this.url + "CHAPTER 5 Memorandum of Association GNKC.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "PROMOTION AND FORMATION OF COMPANY",
        "link": this.url + "CHAPTER 5 PROMOTION AND FORMATION OF COMPANY ..upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "ARTICLES OF ASSOCIATION",
        "link": this.url + "CHAPTER 7 Articles of Association Final..upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "SHARES AND STOCKS",
        "link": this.url + "CHAPTER 9 SHARES AND STOCKS.upl.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "INTRODUCTION TO SERVICES MARKETING",
        "link": this.url + "CHAPTER_SM_1_ INTRODUCTION TO SERVICES MARKETING_AK.pdf"
    },
    {
        "name": "DR ASHOK KHURANA",
        "title": "SERVICES MARKETING MIX",
        "link": this.url + "CHAPTER_SM_2_ SERVICES MARKETING MIX_AK.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "VRML",
        "link": this.url + "presentation5MMT.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "MULTIMEDIA AUTHORING",
        "link": this.url + "presentation4MMT.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "COMPONENTS OF AI",
        "link": this.url + "presentation3AI.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "MULTIMEDIA TOOLS",
        "link": this.url + "presentation3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "MULTIMEDIA AND HYPERMEDIA",
        "link": this.url + "presentation2MMT.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "AI PROBLEMS",
        "link": this.url + "presentation2AI.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "MULTIMEDIA",
        "link": this.url + "Presentation1MMT.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "PEOPLE AND COMPUTERS",
        "link": this.url + "presentation1AI.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "OOPs ORIENTED PROGRAMMING",
        "link": this.url + "ppt2.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "VIRTUAL MEMORY",
        "link": this.url + "OPERATINGSYSTEM-VIRTUALMEMORY-BCA-5SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "OPERATING SYSTEM",
        "link": this.url + "OPERATINGSYSTEM-UNIT-I-BCA-5SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "CPU SCHEDULING",
        "link": this.url + "OPERATINGSYSTEM-SCHEDULING-BCA-5SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "DEADLOCK",
        "link": this.url + "OPERATINGSYSTEM-I-DEADLOCK-BCA-5SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "FILE HANDLING",
        "link": this.url + "OPERATINGSYSTEM-FILEHANDLING-BCA-5SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "LINUX COMMANDS",
        "link": this.url + "linux commands.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "IPv4 AND IPv6",
        "link": this.url + "IPv4 and IPv6.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "TREES",
        "link": this.url + "Data Structures-Trees- BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "TREE TRAVERSAL",
        "link": this.url + "Data Structures-Tree Traversal-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "STRINGS",
        "link": this.url + "Data Structures-Strings-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "STACK AND QUEUE",
        "link": this.url + "Data Structures-Stack _ Queue-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "LINKED LIST AND ITS TYPES",
        "link": this.url + "Data Structures-Linked List _ Its Types-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "INTRODUCTION TO DATA STUCTURE",
        "link": this.url + "Data Structures-Introduction to Data Structures-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "ARRAYS",
        "link": this.url + "Data Structures-Arrays-BSC CS-3.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "PRESENTATION ON C++",
        "link": this.url + "C - -UNIT-IV-BCA-3SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "STATIC DATA MEMBER & MEMBER FUNCTION",
        "link": this.url + "C - -UNIT-I-BCA-3SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "C++ BASIC CONCEPTS",
        "link": this.url + "C - -INTRODUCTION TO C - -BCA-3SEM.pdf"
    },
    {
        "name": "PROF BHAWNA SHARMA",
        "title": "CONSTRUCTOR AND DESTRUCTOR",
        "link": this.url + "C - -CONSTRUCTRUCTOR_DESTRUCTOR-BCA-3SEM.pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "BALANCING BEAM ",
        "link": this.url + "BALANCING BEAM B.Sc-1.pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "CHARACTERISTICS OF SPORTS TRAINING",
        "link": this.url + "Characteristics of sports training-B.Sc-3 (1).pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "HISTORY OF GYMNASTICS",
        "link": this.url + "History of gymnastics .pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "KINDS OF GYMNASTICS",
        "link": this.url + "kinds of gymnastics b sc llnd.pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "MEANING , DEFINITION , TYPES AND PRINCIPLES OF TRAINING LOAD",
        "link": this.url + "MEANING , DEFINITION , TYPES AND PRINCIPLES OF TRAINING LOAD.pdf"
    },
    {
        "name": "DR BODHRAJ ",
        "title": "RULES AND REGULATIONS GYMNASTICS COMPETITION",
        "link": this.url + "Rules and regulations gymnastics competiins.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "BONE STRUCTURE",
        "link": this.url + "Bone structure.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "FIRST AID",
        "link": this.url + "FIRST AID.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "TEAM HANDBALL",
        "link": this.url + "PPTGallery_20230406_111251.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "THROW IN THROW OUT",
        "link": this.url + "PPTGallery_20230406_111540.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "PASSING",
        "link": this.url + "PPTGallery_20230406_111741.pdf"
    },
    {
        "name": "PROF DHEERAJ",
        "title": "TYPES OF SHOTS",
        "link": this.url + "PPTGallery_20230406_111841.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "DISABILITY",
        "link": this.url + "DISABILITY.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "FIRST AID",
        "link": this.url + "FIRST AID.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "INTRODUCTION",
        "link": this.url + "INTRODUCTION.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "MOTIVATION",
        "link": this.url + "Motivation.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "NATURE OF LEARNING",
        "link": this.url + "NATURE OF LEARNING.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "PERSONALITY",
        "link": this.url + "PERSONALITY.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "PSYCHOLOGY-AN OVERVIEW",
        "link": this.url + "PSYCHOLOGY-AN OVERVIEW.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "SAFETY EDUCATION",
        "link": this.url + "SAFETY EDUCATION.pdf"
    },
    {
        "name": "DR AMRITA PRITAM",
        "title": "TRANSFER OF TRAINING",
        "link": this.url + "TRANSFER OF TRAINING.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "BRITISH LEGACY OVER IND ADMIN",
        "link": this.url + "BRITISH LEGACY OVER IND ADMIN.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "FEATURES OF INDIAN ADMIN",
        "link": this.url + "FEATURES OF INDIAN ADMIN.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "FUNDAMENTAL RIGHTS",
        "link": this.url + "FUNDAMENTAL RIGHTS.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "PRESENTATION ON CHIEF MINISTER",
        "link": this.url + "Presentation  on Chief Minister.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "PRESENTATION ON PRIMEN MINISTER",
        "link": this.url + "Presentation on Prime Minister.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "PRESENTATION ON UNION COUNSIL",
        "link": this.url + "Presentation on Union Council.pdf"
    },
    {
        "name": "DR BALJEET SINGH",
        "title": "ROLE OF INDIAN ADMIN IN SOCIOECO",
        "link": this.url + "ROLE OF INDIAN ADMIN IN SOCIOECO.pdf"
    },
    {
        "name": "DR GURVINDER KAUR",
        "title": "LINEAR PROGRAMMING",
        "link": this.url + "mathematics (1).pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "MEANING , NATURE AND SCOPE OF PUB AD",
        "link": this.url + "ppt 1.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "PUBLIC AND PRIVATE SECTOR IN INDIA",
        "link": this.url + "ppt 2.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "CONCEPT OF ORGANIZATION AND ADMINSTRATION",
        "link": this.url + "ppt 3.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "COMMUNICATION IN ORGANIZATION",
        "link": this.url + "ppt 4.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "CHIEF EXECUTIVES AND ADMINISTRATIVE LEADERSHIP",
        "link": this.url + "ppt 5.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "74th AMENDMENT ACT",
        "link": this.url + "ppt 6.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "LEADERSHIP IN PUB AD",
        "link": this.url + "ppt 7.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "URBANIZATION",
        "link": this.url + "ppt 8.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "CONCEPT AND COMPONENTS OF URBAN GOVERNANCE",
        "link": this.url + "ppt 9.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "LEGISTLATURE",
        "link": this.url + "ppt 10.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "JUDICIARY",
        "link": this.url + "ppt 11.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "SCIENTIFIC MANAGEMENT",
        "link": this.url + "ppt 15.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "INTRODUCTION TO ADVERTISING",
        "link": this.url + "ppt 16.pdf"
    },
    {
        "name": "DR IQBAL SINGH",
        "title": "BUREAUCRACY",
        "link": this.url + "ppt 17.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "ADVERTISING  MEDIA",
        "link": this.url + "ADVERTISING  MEDIA.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "ADVERTISING MESSAGE",
        "link": this.url + "ADVERTISING MESSAGE.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "ADVERTISING OBJECTIVES",
        "link": this.url + "ADVERTISING OBJECTIVES.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "C0NTRACTUAL CAPACITY OF THE PARTIES (COMPETENCY)",
        "link": this.url + "C0NTRACTUAL CAPACITY OF THE PARTIES (COMPETENCY).pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "COMMUCNICATION PROCESS IN ADVERTISING",
        "link": this.url + "COMMUCNICATION PROCESS IN ADVERTISING.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONDITIONS AND WARRANTIES",
        "link": this.url + "conditions and warranties.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONSIDERATION",
        "link": this.url + "CONSIDERATION.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONSUMER BEHAVIOUR",
        "link": this.url + "CONSUMER BEHAVIOUR.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONSUMER PROTECTION ACT 1986",
        "link": this.url + "CONSUMER PROTECTION ACT 1986.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONTINGENT CONTRACTS",
        "link": this.url + "CONTINGENT CONTRACTS.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONTRACTS OF AGENCY",
        "link": this.url + "CONTRACTS OF AGENCY.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONTRACTS OF BAILMENT AND PLEDGE",
        "link": this.url + "CONTRACTS OF BAILMENT AND PLEDGE.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "CONTRACTS OF INDEMNITY AND GUARANTEE",
        "link": this.url + "CONTRACTS OF INDEMNITY AND GUARANTEE.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "DISCHARGE OF CONTRACT",
        "link": this.url + "DISCHARGE OF CONTRACT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "ECONOMIC AND SOCIAL ASPECTS OF ADVERTISING",
        "link": this.url + "ECONOMIC AND SOCIAL ASPECTS OF ADVERTISING.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "ESSENTIAL ELEMENTS OF A VALID CONTRACT",
        "link": this.url + "ESSENTIAL ELEMENTS OF A VALID CONTRACT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "FREE CONSENT",
        "link": this.url + "FREE CONSENT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "INTRODUCTION TO BUSINESS LAW",
        "link": this.url + "INTRODUCTION TO BUSINESS LAW.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "INTRODUCTION TO MARKETING",
        "link": this.url + "Introduction to Marketing.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "MARKETING ENVIRONMENT",
        "link": this.url + "MARKETING ENVIRONMENT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "MARKETING MIX",
        "link": this.url + "MARKETING MIX.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "OFFER AND ACCEPTANCE",
        "link": this.url + "OFFER AND ACCEPTANCE.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "PERFORMANCE OF CONTRACT",
        "link": this.url + "PERFORMANCE OF CONTRACT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "PRODUCT",
        "link": this.url + "PRODUCT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "QUASI CONTRACTS",
        "link": this.url + "QUASI CONTRACTS.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "SALES OF GOODS ACT 1930",
        "link": this.url + "SALES OF GOODS ACT 1930.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "SUITS FOR BREACH OF CONTRACT AND  SALE BY AUCTION",
        "link": this.url + "SUITS FOR BREACH OF CONTRACT AND  SALE BY AUCTION.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "TRANSFER OF PROPERTY IN GOODS",
        "link": this.url + "transfer of property in goods.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "UNLAWFUL CONSIDERATION AND OBJECT",
        "link": this.url + "UNLAWFUL CONSIDERATION AND OBJECT.pdf"
    },
    {
        "name": "DR JASWINDER KAUR",
        "title": "UNPAID SELLER",
        "link": this.url + "UNPAID SELLER.pdf"
    },
    {
        "name": "DR JOSHPREET SANDHU",
        "title": "PHYSIOLOGY OF EXERCISE",
        "link": this.url + "Physiology of Exercise 4th Sem.pdf"
    },
    {
        "name": "DR JOSHPREET SANDHU",
        "title": "PHYSIOLOGY OF EXERCISE",
        "link": this.url + "Physiology of Exercise 3rd Sem.pdf"
    },
    {
        "name": "DR JOSHPREET SANDHU",
        "title": "KINESEOLOGY",
        "link": this.url + "Kinesiology (Paper GÇô III) 6th Sem.pdf"
    },
    {
        "name": "DR JOSHPREET SANDHU",
        "title": "BIO-MECHANICS",
        "link": this.url + "BIO-MECHANICS 5th Sem.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "WHERE THE MIND IS WITHOUT FEAR",
        "link": this.url + "Where the Mind is without Fear.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "WHEN YOU ARE OLD",
        "link": this.url + "When You are Old.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "THE WORLD IS TOO MUCH WITH US",
        "link": this.url + "THE WORLD IS TOO MUCH WITH US.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "THE BANGLE SELLER",
        "link": this.url + "The Bangle Sellers.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "SONNET XVIII",
        "link": this.url + "SONNET XVIII.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "ODE ON A GRECIAN URN",
        "link": this.url + "Ode on a Grecian Urn.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "MY LAST DUCHESS",
        "link": this.url + "My Last Duchess.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "KNOW THEN THYSELF",
        "link": this.url + "Know Then Thyself.pdf"
    },
    {
        "name": "DR KAMALPREET KAUR",
        "title": "ANOTHER WOMAN",
        "link": this.url + "Another Woman.pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "COLPITTS OSCILLATOR",
        "link": this.url + "Colpitts Oscillator.pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "OP-AMP ",
        "link": this.url + "op-amp ......pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "PHASE SHIFT OSCILLATOR",
        "link": this.url + "Phase Shift Oscillator.pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "RELAXATION AND FEEDBACK OSCILLATORS",
        "link": this.url + "Relaxation and Feedback Oscillators.pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "TWIN T OSCILLATOR",
        "link": this.url + "Twin T oscillator.pdf"
    },
    {
        "name": "DR MANPUNEET KAUR",
        "title": "WEIN BRIDGE OSCILLATOR",
        "link": this.url + "Wein Bridge oscillator.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "BUDGET_PUBLIC_ADMN",
        "link": this.url + "budget_Public_Admn.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "DEPARTMENT OF HEALTH AND FAMILY WELFARE AT STATE",
        "link": this.url + "Department of Health and Family Welfare at State.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "EVOLUTION OF PUBLIC SECTOR IN INDIA PPT",
        "link": this.url + "EVOLUTION OF PUBLIC SECTOR IN INDIA ppt.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "F.W.RIGGS ",
        "link": this.url + "F.W.RIGGS PPT.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "INDUSTRIAL POLICY RESOLUTIONS INDIA_1",
        "link": this.url + "INDUSTRIAL POLICY RESOLUTIONS INDIA_1.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "MEDICAL COUNCIL OF INDIA",
        "link": this.url + "Medical Council of India.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "ORGANISATIONAL BEHAVIOUR",
        "link": this.url + "ORGANISATIONAL BEHAVIOUR.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "PRESIDENT",
        "link": this.url + "PRESIDENT.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "PROMOTION FOR HIGHER CIVIL SERVICE IN INDIA",
        "link": this.url + "PROMOTION FOR HIGHER CIVIL SERVICE IN INDIA.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "PUBLIC PERSONNEL ADMINISTRATION",
        "link": this.url + "Public Personnel Administration.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "RECRUITMENT FOR CIVIL SERVICES IN INDIA",
        "link": this.url + "Recruitment for Civil Services in India.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "SCIENTIFIC MANAGEMENT APPROACH",
        "link": this.url + "SCIENTIFIC MANAGEMENT APPROACH.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "STRESS MANAGEMENT",
        "link": this.url + "STRESS MANAGEMENT.pdf"
    },
    {
        "name": "DR MEENU KAPUR",
        "title": "TRAINING FOR HIGHER CIVIL SERVICE IN INDIA",
        "link": this.url + "TRAINING FOR HIGHER CIVIL SERVICE IN INDIA.pdf"
    },
    {
        "name": "DR M P AGGARWAL",
        "title": "INTERFRENCE OF BIPRISM",
        "link": this.url + "Copy of ppt01-1.pdf"
    },
    {
        "name": "DR M P AGGARWAL",
        "title": "FRANHOFFER DIFFRACTION BY SINGLE SLIT",
        "link": this.url + "Copy of ppt02-1.pdf"
    },
    {
        "name": "DR M P AGGARWAL",
        "title": "NEWTON'S RINGS",
        "link": this.url + "Copy of ppt04-1.pdf"
    },
    {
        "name": "DR M P AGGARWAL",
        "title": "ZONE PLATE",
        "link": this.url + "Copy of ppt05-1.pdf"
    },
    {
        "name": "DR M P AGGARWAL",
        "title": "MPA - COMPUTER ORGANISATION",
        "link": this.url + "MPA - COMPUTER ORGANISATION.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "WAX BATH THERAPY",
        "link": this.url + "WAX BATH THERAPY.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "TUBERCULOSIS",
        "link": this.url + "TUBERCULOSIS.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "kyphosis",
        "link": this.url + "kyphosis.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "KHO KHO DIAGRAM ",
        "link": this.url + "KHO KHO DIAGRAM (1).pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "HEART FAILURE ",
        "link": this.url + "HEART FAILURE (1).pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "EXERCISE THERAPY",
        "link": this.url + "EXERCISE THERAPY.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "EQUIPMENTS USED IN KHO KHO ",
        "link": this.url + "EQUIPMENTS USED IN KHO KHO (1).pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "CRYOTHERAPY",
        "link": this.url + "CRYOTHERAPY.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "COOLING DOWN ",
        "link": this.url + "COOLING DOWN (1).pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "CAUSES OF POOR POSTURE",
        "link": this.url + "CAUSES OF POOR POSTURE.pdf"
    },
    {
        "name": "DR RANJIT SINGH",
        "title": "BASKET BALL COURT",
        "link": this.url + "BASKET BALL COURT.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "VIJAYNAGAREMPIRE",
        "link": this.url + "VijaynagarEmpire.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "INVASIONS OF MUHAMMAD GHORI",
        "link": this.url + "Invasions of Muhammad Ghori.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "INVASION OF MAHMUD GHAZNAVI",
        "link": this.url + "Invasion of Mahmud Ghaznavi.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "HARSHAVARDHANEMPIRE",
        "link": this.url + "harshavardhanempire.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "DOWNFALL OF DELHI SULTANATE",
        "link": this.url + "Downfall Of Delhi Sultanate.pdf"
    },
    {
        "name": "DR RAVINDER KAUR",
        "title": "BAHAMANI SULTNATE",
        "link": this.url + "Bahamani Sultnate.pdf"
    },
    {
        "name": "DR SANGEETA ",
        "title": "BLOOM_S TAXNOMY",
        "link": this.url + "BLOOM_S TAXNOMY.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "CELL WALL GNKC",
        "link": this.url + "CELL WALL GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "CHLOROPLAST GNKC",
        "link": this.url + "CHLOROPLAST GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "DIVERSITY IN PLANTS GNKC",
        "link": this.url + "DIVERSITY IN PLANTS GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "ECOSYSTEM GNKC ",
        "link": this.url + "ECOSYSTEM gnkc .pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "FIX PHOTOSYNTHESIS INTR ",
        "link": this.url + "FIX PHOTOSYNTHESIS INTR 1.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "FOREST RESOURCES GNKC",
        "link": this.url + "FOREST RESOURCES GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "MITOCHONDRIA GNKC",
        "link": this.url + "Mitochondria GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "PHOTOSYNTHESIS 2 FIX",
        "link": this.url + "photosynthesis 2 fix.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "PLANT TISSUE GNKC",
        "link": this.url + "PLANT TISSUE GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "PLANT TISSUE 2 GNKC",
        "link": this.url + "PLANT TISSUE 2 GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "PLASMA MEMBRANE GNKC",
        "link": this.url + "PLASMA MEMBRANE GNKC.pdf"
    },
    {
        "name": "DR VARSHA NIGAM",
        "title": "TRANSPIRATION GNKC",
        "link": this.url + "TRANSPIRATION GNKC.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "SPICES",
        "link": this.url + "Spices.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "PRESENTATION ON BACTERIA",
        "link": this.url + "Presentation on Bacteria.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "PHYTOGEOGRAPHY",
        "link": this.url + "Phytogeography.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "MEDICNAL PLANTS",
        "link": this.url + "Medicnal plants.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "FUNGI CHARACTERS",
        "link": this.url + "Fungi characters.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "BIOGEOCHEMICAL CYCLES",
        "link": this.url + "Biogeochemical cycles.pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "ALGAE CHARACTERISTICS.",
        "link": this.url + "Algae characteristics..pdf"
    },
    {
        "name": "DR AMARJEET SINGH",
        "title": "AIR POLLUTION",
        "link": this.url + "Air pollution.pdf"
    },
    {
        "name": "DR A P MISHRA",
        "title": "SOCIAL SECURITY ",
        "link": this.url + "Social Security PPT.pdf"
    },
    {
        "name": "DR A P MISHRA",
        "title": "SOCIAL REFORM MOVEMENTS IN INDIA ",
        "link": this.url + "Social Reform Movements in India PPT.pdf"
    },
    {
        "name": "DR A P MISHRA",
        "title": "SOCIAL JUSTICE ",
        "link": this.url + "Social Justice PPT.pdf"
    },
    {
        "name": "DR A P MISHRA",
        "title": "BIDDLE_S MODEL ",
        "link": this.url + "BIDDLE_S MODEL PPT.pdf"
    },
    {
        "name": "DR HARI KIRAN KAUR",
        "title": "HINDI SAHITYA KA ITIHAAS",
        "link": this.url + "DOC-20230208-WA0019_.pdf"
    },
    {
        "name": "DR HARI KIRAN KAUR",
        "title": "HINDI AALOCHANA",
        "link": this.url + "DOC-20230208-WA0021_.pdf"
    },
    {
        "name": "DR HARI KIRAN KAUR",
        "title": "HINDI SAHITYA KA ITIHAAS",
        "link": this.url + "DOC-20230208-WA0022_.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "COMMUNICATION ",
        "link": this.url + "communication ppt ss.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "FUND RIGHT AND FUND DUTIES ",
        "link": this.url + "FUND RIGHT AND FUND DUTIES ppt ss.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "SOCIAL PLANNING",
        "link": this.url + "ppt ss.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "PRINCIPLES OF STATE POLICY ",
        "link": this.url + "PRINCIPLES OF STATE POLICY ppt ss.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "SOCIAL ADJUSTMENT ",
        "link": this.url + "SOCIAL ADJUSTMENT ppt ss.pdf"
    },
    {
        "name": "DR SAHIB SINGH",
        "title": "SOCIAL GROUP ",
        "link": this.url + "social group ppt ss.pdf"
    },
    {
        "name": "PROF ETENDER",
        "title": "FUND",
        "link": this.url + "fund.pdf"
    },
    {
        "name": "PROF ETENDER",
        "title": "INTRODUCTION TO GIS, GPS AND REMOTE SENSING",
        "link": this.url + "Introduction to GIS, GPS and remote sensing.pdf"
    },
    {
        "name": "PROF ETENDER",
        "title": "L10-AARIAL PHOTOGRAPHY",
        "link": this.url + "L10-AarialPhotography.pdf"
    },
    {
        "name": "PROF ETENDER",
        "title": "LECTURE14 INTRO ECONOMIC ACTIVITIES",
        "link": this.url + "lecture14Introeconomicactivities.pdf"
    },
    {
        "name": "PROF ETENDER",
        "title": "THREE-TYPES-OF-VOLCANOES",
        "link": this.url + "Three-Types-of-Volcanoes.pdf"
    },
    {
        "name": "DR JAGAT ",
        "title": "MICRO TEACHING",
        "link": this.url + "Micro Teaching (Dr. Jagat Singh).pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "BASIC COMPUTER ORGANISATION AND DESIGN",
        "link": this.url + "Basic computer organisation and design.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "BINARY LOGIC",
        "link": this.url + "BINARY LOGIC.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "ROUTING",
        "link": this.url + "CN.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "COMBINATIONAL CIRCUITS",
        "link": this.url + "COMBINATIONAL CIRCUITS.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "CONSTRUCTORS OR DESTRUCTORS UNIT(II)",
        "link": this.url + "Constructors or destructors unit(II).pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "CONVERSION UNIT1",
        "link": this.url + "CONVERSION UNIT1.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "DIGITAL LOGIC",
        "link": this.url + "DIGITAL LOGIC.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "GENERAL REGISTER ORGANIZATION",
        "link": this.url + "GENERAL REGISTER ORGANIZATION.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "INSTRUCTION CYCLES OF A BASIS COMPUTER UNIT 1",
        "link": this.url + "Instruction cycles of a basis computer UNIT 1.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "MANIPULATION IN C++ UNIT(III)",
        "link": this.url + "manipulation in C++ unit(III).pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "MEMORY AND INPUT OUPUT DEVICES",
        "link": this.url + "MEMORY AND INPUT OUPUT DEVICES.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "NETWORKING PRESENTATION",
        "link": this.url + "networking presentation.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "NUMBER REPRESENATATION",
        "link": this.url + "NUMBER REPRESENATATION.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "OBJECT-ORIENTED-PROGRAMMING (UNIT- I)",
        "link": this.url + "Object-Oriented-Programming (Unit- I).pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "OOPS",
        "link": this.url + "OOPS.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "POLYMORPHISM C++ (UNIT-IV)",
        "link": this.url + "Polymorphism C++ (unit-IV).pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "SOFTWARE ENGINEERING",
        "link": this.url + "PPT1.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "PROGRAMMING WITH VB",
        "link": this.url + "ppt3.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "REGISTER TRANSFER AND MICROOPERATIONS",
        "link": this.url + "Register transfer and microoperations.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "DBMS",
        "link": this.url + "unit 1_ unit 2.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "DATABASE DEVELOPMENT LIFE CYCLE",
        "link": this.url + "UNIT 2.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "DATA MODELS",
        "link": this.url + "unit 3.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "COMPUTER NETWORK",
        "link": this.url + "UNIT-2.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "SOFTWARE PROJECT PLANNING",
        "link": this.url + "UNIT-4.pdf"
    },
    {
        "name": "PROF JASMEEN KAUR",
        "title": "VISUAL BASIC UNIT 2",
        "link": this.url + "visual basic unit 2.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "BASIC GROOMING",
        "link": this.url + "basic grooming.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "BASIC HYGIENE PPT",
        "link": this.url + "basic hygiene ppt.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "CONSUMER BEHAVIOUR",
        "link": this.url + "CONSUMER BEHAVIOUR.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "CONSUMER PERCEPTION",
        "link": this.url + "CONSUMER PERCEPTION.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "CUSTOMER DEALING",
        "link": this.url + "customer dealing.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "E-TAILING",
        "link": this.url + "E-TAILING.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "INTRODUCTION TO RETAILING",
        "link": this.url + "Introduction to retailing.pdf"
    },
    {
        "name": "PROF JASPREET SINGH",
        "title": "STRESS MGT",
        "link": this.url + "STRESS MGT..pdf"
    },
    {
        "name": "PROF KANIKA",
        "title": "BACTERIA",
        "link": this.url + "BACTERIA.pdf"
    },
    {
        "name": "PROF  KAVITA",
        "title": "ACTION RESEARCH",
        "link": this.url + "ACTION RESEARCH.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "ARRAY AND STRING IN C",
        "link": this.url + "Array and String in C.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "C LANGUAGE",
        "link": this.url + "c.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "CLASSES AND OBJECTS",
        "link": this.url + "Classes and Objects.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "CODING SCHEMES",
        "link": this.url + "Coding Schemes.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "COMPUTER FUNDAMENTALS",
        "link": this.url + "Computer Fundamentals.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "FLIP FLOP PART ONE",
        "link": this.url + "FLIP FLOP PART ONE.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "FLIP FLOP PART TWO",
        "link": this.url + "FLIP FLOP PART TWO.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "INFORMATION TECHNOLOGY",
        "link": this.url + "IT.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "LINKED LIST",
        "link": this.url + "Linked List.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "NETWORK ROUTING",
        "link": this.url + "Network routing.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "NETWORK SECURITY",
        "link": this.url + "Network Security.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "QUEUE",
        "link": this.url + "Queue.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "SOFTWARE DESIGN",
        "link": this.url + "Software Design.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "SOFTWARE MAINTENANCE",
        "link": this.url + "Software Maintenance.pdf"
    },
    {
        "name": "DR KIRANPAL SINGH VIRK",
        "title": "STACK",
        "link": this.url + "Stack.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "SCHRODINGER(QM)",
        "link": this.url + "SCHRODINGER(QM).pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "POSTULATES(QM)",
        "link": this.url + "Postulates(QM).pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "PARTICLE PHYSICS SEM-II",
        "link": this.url + "PARTICLE PHYSICS SEM-II.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "FIRST AND SECOND ORDER CORRECTION (SEM II)",
        "link": this.url + "FIRST AND SECOND ORDER CORRECTION (SEM II).pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "EXPECTATION VALUE(QM) SEM_I",
        "link": this.url + "EXPECTATION VALUE(QM) SEM_I.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "DIRAC NOTATION(QM)",
        "link": this.url + "DIRAC NOTATION(QM).pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "COMPTON AND PP SEMII",
        "link": this.url + "COMPTON AND PP SEMII.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "BASICS OF QUANTUM MECHANICS",
        "link": this.url + "BASICS of Quantum mechanics.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "BASIC INTERACTION SEMII",
        "link": this.url + "BASIC INTERACTION SEMII.pdf"
    },
    {
        "name": "DR MANDEEP KAUR",
        "title": "(NUCLEAR PHYSICS)PHOTOELECTRIC EFFECT ",
        "link": this.url + "(NUCLEAR PHYSICS)PHOTOELECTRIC EFFECT .pdf"
    },
    {
        "name": "DR N P SINGH",
        "title": "SAHIT SIDHHANT TE VIHARAKA ALOCHANA",
        "link": this.url + "Presentation (2)ਸਾਹਿਤ ਸਿਧਾਂਤ ਤੇ ਵਿਹਾਰਕ ਆਲੋਚਨਾ.pdf"
    },
    {
        "name": "DR N P SINGH",
        "title": "PUNJABI BAATCHEET",
        "link": this.url + "Presentation (3).pdf"
    },
    {
        "name": "DR N P SINGH",
        "title": "PUNJABI AALOCHANA",
        "link": this.url + "ਪੰਜਾਬੀ ਆਲੋਚਨਾ m. a.pdf"
    },
    {
        "name": "PROF NEELAM SINGH",
        "title": "RAMAN SPECTROSCOPY",
        "link": this.url + "RAMAN PPT 2.pdf"
    },
    {
        "name": "PROF NEELAM SINGH",
        "title": "ROTATIONAL SPECTROSCOPY",
        "link": this.url + "rotational PPT2.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "ALGAE",
        "link": this.url + "Algae.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "CAREER PROSPECTS",
        "link": this.url + "career prospects.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "FERMENTATION",
        "link": this.url + "fermentation.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "INTRODUCTION",
        "link": this.url + "introduction.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "MEDIUM COMPOSITION",
        "link": this.url + "medium composition.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "OPTION IN LIFE SCIENCE",
        "link": this.url + "option in life science.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "PHOSPHOROUS TO",
        "link": this.url + "phosphorous to.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "S CYCLE",
        "link": this.url + "S cycle.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "SOIL AND CARBON",
        "link": this.url + "soil and carbon.pdf"
    },
    {
        "name": "DR NEENA PURI",
        "title": "SOIL MICROBIOL",
        "link": this.url + "soil microbiol.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "ANTISEPTICS AND DISINFECTANTS",
        "link": this.url + "Antiseptics and disinfectants.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "BIOGEO-CHEMICAL-CYCLE",
        "link": this.url + "biogeo-chemical-cycle.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "CELL COMMUNICATION",
        "link": this.url + "cell communication.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "CHAPTER 6 ",
        "link": this.url + "Chapter 6 ppt.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "CHLOROPLAST TRANSFORMATION MSC BT115",
        "link": this.url + "Chloroplast transformation Msc BT115.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "CRISPAR CAS 9",
        "link": this.url + "Crispar cas 9.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "LAFAILLE WINE ADDITIVES 8-19-16",
        "link": this.url + "LaFaille wine additives 8-19-16.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "M4-HG-ANTIBIOTICS",
        "link": this.url + "m4-hg-antibiotics.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "RECOMBINANT DNA",
        "link": this.url + "RECOMBINANT DNA.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "STEPWISE EUKARYOTIC TRANSLATION (1)",
        "link": this.url + "stepwise eukaryotic translation (1).pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "TN PPT MSC",
        "link": this.url + "TN ppt MSC.pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "INTRODUCTION TO AUDITING",
        "link": this.url + "INTRODUCTION TO AUDITING....pdf"
    },
    {
        "name": "DR NIDHI",
        "title": "INTRODUCTION TO MANAGEMENT-CONVERTED",
        "link": this.url + "INTRODUCTION TO MANAGEMENT-converted.pdf"
    },
    {
        "name": "PROF PARDEEP",
        "title": "ROLE OF COMMUNITY",
        "link": this.url + "Role of Community (Pardeep).pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "ANATOMY AND PHYSIOLOGY 1ST SEM",
        "link": this.url + "ANATOMY AND PHYSIOLOGY 1ST SEM.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "ANATOMY OF HEART",
        "link": this.url + "ANATOMY OF HEART.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "HAMMER THROW",
        "link": this.url + "HAMMER THROW.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "KIDNEY HINDI",
        "link": this.url + "KIDNEY HINDI.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "KIDNEY",
        "link": this.url + "KIDNEY.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "OVER HAND SERVICE",
        "link": this.url + "Over hand service.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "PANCREAS AND ITS FUNCTION",
        "link": this.url + "PANCREAS AND ITS FUNCTION.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "POLE VAULT PPT 1",
        "link": this.url + "POLE VAULT PPT 1.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "RULE AND REGULATION OF HURDLE RACE",
        "link": this.url + "RULE AND REGULATION OF HURDLE RACE.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "RUNNING BETWEEN THE HURDLE",
        "link": this.url + "RUNNING BETWEEN THE HURDLE.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "SIDE ARM SERVICE",
        "link": this.url + "SIDE ARM SERVICE.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "STARTING TO FIRST HURDLE 1",
        "link": this.url + "STARTING TO FIRST HURDLE 1.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "STRUCTURE OF CELL AND FUNCTION OF CELL",
        "link": this.url + "STRUCTURE OF CELL and function of cell.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "TRAILING LEG IN HURDLE",
        "link": this.url + "TRAILING LEG IN HURDLE.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "TYPES AND CHARACTERSTICS OF CELL",
        "link": this.url + "TYPES AND CHARACTERSTICS OF CELL.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "UNDER HAND SERVICE IN VOLLEYBALL",
        "link": this.url + "UNDER HAND SERVICE IN VOLLEYBALL.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "UPPER HAND SERVICE",
        "link": this.url + "UPPER HAND SERVICE.pdf"
    },
    {
        "name": "DR PARVEEN KUMAR",
        "title": "WARMING UP",
        "link": this.url + "WARMING UP.pdf"
    },
    {
        "name": "PROF POOJA RANI",
        "title": "ARRAYS",
        "link": this.url + "Arrays.pdf"
    },
    {
        "name": "PROF POOJA RANI",
        "title": "CONSTRUCTOR",
        "link": this.url + "Constructor.pdf"
    },
    {
        "name": "PROF POOJA RANI",
        "title": "DATA TYPES",
        "link": this.url + "Data types.pdf"
    },
    {
        "name": "PROF POOJA RANI",
        "title": "EXCEPTION HANDLING",
        "link": this.url + "Exception handling.pdf"
    },
    {
        "name": "PROF POOJA RANI",
        "title": "JAVA",
        "link": this.url + "Java ppt.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "ATOMIC FORM FACTOR 1",
        "link": this.url + "Atomic form factor 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BCS THEORY 1",
        "link": this.url + "BCS THEORY 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BRAGGSLAW PS 1",
        "link": this.url + "braggslaw PS 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BRAVIOUS LATTICE 1",
        "link": this.url + "BRAVIOUS LATTICE 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BRILLOUIN ZONE 1 (1)",
        "link": this.url + "BRILLOUIN ZONE 1 (1).pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BRILLOUIN ZONE 1",
        "link": this.url + "BRILLOUIN ZONE 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "BRILLOUINZONEANDWIGNER PS (1)",
        "link": this.url + "brillouinzoneandwigner PS (1).pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "CRYSTAL STRUCTURE 1",
        "link": this.url + "CRYSTAL STRUCTURE 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "DRUDE’S CLASSICAL THEORY OF ELECTRICAL CONDUCTION1",
        "link": this.url + "Drude’s classical theory of Electrical Conduction1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "ENERGY BAND 1",
        "link": this.url + "ENERGY BAND 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "HALL EFFECT 1",
        "link": this.url + "HALL EFFECT 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "JOSPHSON EFFECT 1",
        "link": this.url + "josphson effect 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "KRONIG PENNY MODEL 1",
        "link": this.url + "KRONIG PENNY MODEL 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "LAUEMETHOD 1",
        "link": this.url + "lauemethod 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "MILLER_INDICES-CONVERTED PS 1",
        "link": this.url + "Miller_Indices-converted PS 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "RECIPROCAL LATTICE",
        "link": this.url + "Reciprocal Lattice.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "SUPERCONDUCTORS-1",
        "link": this.url + "superconductors-1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "SYMMETRY OPERATIONS 1",
        "link": this.url + "SYMMETRY OPERATIONS 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "TYPES OF SUPERCONDUCTORS 1",
        "link": this.url + "TYPES OF SUPERCONDUCTORS 1.pdf"
    },
    {
        "name": "DR PRATIBHA SHARMA",
        "title": "X-RAYPOWDERDIFFRACTION PS 1",
        "link": this.url + "x-raypowderdiffraction PS 1.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " AGRICULTURE INCOME",
        "link": this.url + "21-22 Agriculture Income.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " APPEALS AND REVISION PPT - FINAL - COPY",
        "link": this.url + "21-22 Appeals and Revision PPT - final - Copy.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " BASIC TERMS",
        "link": this.url + "21-22 basic terms.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " CAPITAL GAIN",
        "link": this.url + "21-22 capital Gain.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " COMPUTATION OF TAX LIABILITY OF HUF",
        "link": this.url + "21-22 Computation of tax liability of HUF.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " DEDUCTIONS UNDER SECTION 80C-80U",
        "link": this.url + "21-22 Deductions Under section 80C-80U.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " HOUSE PROPERTY ",
        "link": this.url + "21-22 House Property 21-22.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " INCOME TAX AUTHORITIES PPT",
        "link": this.url + "21-22 Income Tax Authorities ppt.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " OTHER SOURCES KHALSA COLLEGE",
        "link": this.url + "21-22 other sources khalsa college.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " PENALTIES",
        "link": this.url + "21-22 Penalties.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " PROCEDURE OF FILING RETURN",
        "link": this.url + "21-22 Procedure of Filing Return.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " PROSECUTION UNDER INCOME TAX ACT",
        "link": this.url + "21-22 Prosecution under income tax ACT.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " RECOVERY OF TAX - COPY",
        "link": this.url + "21-22 Recovery of Tax - Copy.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " REFUND OF TAX",
        "link": this.url + "21-22 Refund of Tax.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " RESIDENTIAL STUATUS",
        "link": this.url + "21-22 Residential stuatus.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": " SALARY",
        "link": this.url + "21-22 salary.pdf"
    },
    {
        "name": "PROF R S VOHRA",
        "title": "ADVANCE PAYMENT OF TAX",
        "link": this.url + "21-22ADVANCE PAYMENT OF TAX.pdf"
    },
    {
        "name": "PROF RAJU",
        "title": "ROLE OF FAMILY",
        "link": this.url + "Role of Family (Raju).pdf"
    },
    {
        "name": "PROF RAJU",
        "title": "SOCIAL AND CULTURAL CHANGE",
        "link": this.url + "social and cultural change.pdf"
    },
    {
        "name": "PROF RAVNEET KAUR",
        "title": "UNIT 3",
        "link": this.url + "Ca Unit-3-1.pdf"
    },
    {
        "name": "PROF RAVNEET KAUR",
        "title": "COMPUTER LANGUAGES",
        "link": this.url + "Computer _ programing fundamentals,Computer languages,BCA-1st year.pdf"
    },
    {
        "name": "PROF RAVNEET KAUR",
        "title": "CONCEPT OF PROBLEM SOLVING",
        "link": this.url + "Computer _ programming fundamental, Concept of Problem solving, BCA-1st year.pdf"
    },
    {
        "name": "PROF RAVNEET KAUR",
        "title": "MEMORY UNIT",
        "link": this.url + "Computer _ programming fundamental, Memory Unit, BCA 1.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "ACTIVE PASSIVE",
        "link": this.url + "active passive.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "CLAUSES",
        "link": this.url + "Clauses.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "HOW-TO-TRANSFORM-SENTENCE.3971497.POWERPOINT",
        "link": this.url + "How-to-Transform-Sentence.3971497.powerpoint.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "INTONATION",
        "link": this.url + "intonation.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "PPT ON NON FINITE VERBS",
        "link": this.url + "ppt on Non finite verbs.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "PREPOSITIONS 1_0",
        "link": this.url + "Prepositions 1_0.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "PREPOSITIONS-1",
        "link": this.url + "Prepositions-1.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "PRONOUNS",
        "link": this.url + "Pronouns.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "REPORTED_20SPEECH",
        "link": this.url + "Reported_20Speech.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "TENSES",
        "link": this.url + "tenses.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "TRANSCRIPTION",
        "link": this.url + "Transcription.pdf"
    },
    {
        "name": "PROF RENU",
        "title": "VERB_20TENSES",
        "link": this.url + "verb_20tenses.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "KTG",
        "link": this.url + "KTG.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "NANO-TECH",
        "link": this.url + "Nano-Tech.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "NUCLEAR",
        "link": this.url + "Nuclear.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "NUCLEARRADIATIONANDTHEIRINTERACTIONWITHMATTER",
        "link": this.url + "NuclearRadiationAndTheirInteractionWithMatter.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "SUPERCON-II",
        "link": this.url + "SuperCon-II.pdf"
    },
    {
        "name": "DR SANTOSH KURRA",
        "title": "SUPERCONDUCTIVITY-I",
        "link": this.url + "Superconductivity-I.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "1ST PPT INTRUDUCTION TO SUBJECT",
        "link": this.url + "1st PPT Intruduction to Subject.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "2ND PPT CHAPTER-I INTRODUCTION TO STATISTICS",
        "link": this.url + "2nd PPT Chapter-I Introduction to Statistics.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "3RD PPT CHAPTER-II COLLECTION OF DATA",
        "link": this.url + "3rd PPT Chapter-II Collection of Data.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "DISSOLUTION OF PARTNERSHIP",
        "link": this.url + "Dissolution of Partnership.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "HUMAN RESOURCE MANAGEMENT",
        "link": this.url + "Human Resource Management.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "INDIAN PARTNERSHIP ACT, 1932 AN OVERVIEW",
        "link": this.url + "INDIAN PARTNERSHIP ACT, 1932 AN OVERVIEW.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "KINDS OF NEGOTIABLE INSTRUMENT",
        "link": this.url + "Kinds of Negotiable Instrument.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "LIMITED LIABILITY OF PARTNERSHIP ACT 2008",
        "link": this.url + "Limited Liability of Partnership Act 2008.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "NEGOTIABLE INSTRUMENT",
        "link": this.url + "Negotiable Instrument.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "PROMOTION",
        "link": this.url + "Promotion.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "RECRUITMENT",
        "link": this.url + "Recruitment.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "REGISTRATION OF PARTNERSHIP FIRM",
        "link": this.url + "Registration of Partnership Firm.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "RIGHT TO INFORMATION ACT 2005",
        "link": this.url + "RIGHT TO INFORMATION ACT 2005.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "SELECTION",
        "link": this.url + "Selection.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "TRANING",
        "link": this.url + "Traning.pdf"
    },
    {
        "name": "PROF SEEMA",
        "title": "TRANSFER",
        "link": this.url + "Transfer.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "SAMAAS",
        "link": this.url + "samaas.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "KARAK",
        "link": this.url + "karak.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "DHAATU ROOP",
        "link": this.url + "धातु रूप.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "SHABAD ROOP 2",
        "link": this.url + "शब्द रूप 2.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "SHABAD ROOP",
        "link": this.url + "शब्द रूप.pdf"
    },
    {
        "name": "PROF SHALINI BHARDWAJ",
        "title": "SANDHI KI PARIBHASHA",
        "link": this.url + "संधि की परिभाषा.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "ARRAYS 1",
        "link": this.url + "Arrays 1.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "ATM",
        "link": this.url + "atm.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "BROADBAND-ISDN-",
        "link": this.url + "broadband-isdn-.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C BASICS-",
        "link": this.url + "C Basics-2-26.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C BASICS",
        "link": this.url + "C Basics.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C BITWISE OPERATORS",
        "link": this.url + "c bitwise operators-.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C CONTROL STRUCTURES ",
        "link": this.url + "c control structures pdf.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C CONTROL STRUCTURES PPT 2",
        "link": this.url + "c control structures ppt 2 pdf.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C OPERATORS FINAL MAIN",
        "link": this.url + "C operators final-2-37 main.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "C OPERATORS FINAL",
        "link": this.url + "C operators final.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CH 3 TO 5 COMPLETE",
        "link": this.url + "ch 3 to 5 complete.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CH 8,9, 10, 11",
        "link": this.url + "CH 8,9, 10, 11.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CHAPTER 01",
        "link": this.url + "chapter 01.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CHAPTER 02",
        "link": this.url + "chapter 02.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CHAPTER 6 AND 7 S",
        "link": this.url + "CHAPTER 6 AND 7 S.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "CRM ",
        "link": this.url + "CRM PPT.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "DATA STRUCTURE COMPLETE NOTES B.COM 3RD SEM",
        "link": this.url + "data structure complete notes b.com 3rd Sem.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "DECICION MAKING",
        "link": this.url + "decicion making.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "DECISION MAKING 2",
        "link": this.url + "Decision making 2.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "E-BANKING",
        "link": this.url + "e-banking-ppt.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "EGOVERNANCE ",
        "link": this.url + "egovernance ppt.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "FUNCTION IN C PROGRAM",
        "link": this.url + "function in c program.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "FUNCTIONS IN C",
        "link": this.url + "functions in C.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "ISDN- COMPLETE",
        "link": this.url + "ISDN- Complete.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "NETWORK TOPOLOGIES",
        "link": this.url + "network topologies.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "NOTES OF ERP BCOM V1 SEM  UNIT 2",
        "link": this.url + "notes of ERP bcom V1 sem pdf-45-68 unit 2.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "NOTES OF ERP BCOM V1 SEM UNIT 3",
        "link": this.url + "notes of ERP bcom V1 sem pdf-69-83 unit 3.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "NOTES OF ERP BCOM V1 SEM ",
        "link": this.url + "notes of ERP bcom V1 sem pdf.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "OPERATORS IN C PROGRAMMING",
        "link": this.url + "operators in c programming.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "OSI-MODEL ",
        "link": this.url + "osi-model - 2-12 pdf.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "PRINTFAND SCANF-FUNCTION",
        "link": this.url + "printfand scanf-function.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "STRINGS IN C",
        "link": this.url + "strings in C.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "TCP-IP",
        "link": this.url + "TCP-IP-2-25 (1).pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "TRANSMISSIONMEDIA",
        "link": this.url + "transmissionmedia-121024064451-phpapp02.pdf"
    },
    {
        "name": "DR SHAWETA SACHDEVA",
        "title": "TYPES OF NETWORK",
        "link": this.url + "TYPES OF NETWORK.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "ENTREPRENEURSHIP DEVELOPMENT",
        "link": this.url + "Entrepreneurship Development.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "INPUT TAX CREDIT",
        "link": this.url + "INPUT TAX CREDIT.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "INTRODUCTION TO GST (1)",
        "link": this.url + "introduction to GST (1).pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "INTRODUCTION TO GST (2)",
        "link": this.url + "introduction to GST (2).pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "INTRODUCTION TO GST",
        "link": this.url + "introduction to GST.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "LEVY AND COLLECTION OF TAX",
        "link": this.url + "LEVY AND COLLECTION OF TAX.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "REGISTRATION UNDER GST",
        "link": this.url + "registration under GST.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "SETTING UP OF A NEW BUSINESS",
        "link": this.url + "Setting up of a new Business.pdf"
    },
    {
        "name": "PROF SHILPI",
        "title": "TIME OF SUPPLY UNDER GST",
        "link": this.url + "Time of supply under GST.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "DRUDE’S CLASSICAL THEORY OF ELECTRICAL CONDUCTION1",
        "link": this.url + "Drude’s classical theory of Electrical Conduction1.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "ELECRICAL TRANSPORT IN 0D",
        "link": this.url + "elecrical transport in 0D.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "ELECTRICAL TRANSPORT IN 1D",
        "link": this.url + "electrical transport in 1D.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "FREEELECTRONINMETAL-1",
        "link": this.url + "freeelectroninmetal-1.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "HALL EFFECT PPT",
        "link": this.url + "hall effect ppt.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "KRONIG PENNY MODEL 1",
        "link": this.url + "KRONIG PENNY MODEL 1.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "MOTION OF ELECTRON AND HOLES,EFFECTIVE MASS",
        "link": this.url + "motion of electron and holes,effective mass.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "QUANTUM WELL,WIRE,DOT",
        "link": this.url + "quantum well,wire,dot.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "TIME DEPENDENT",
        "link": this.url + "ss 2 time dependent.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "TIME INDE",
        "link": this.url + "SS1 TIME INDE.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "FIRST AND SECOND ORDER CORRECTION",
        "link": this.url + "SS3.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "CENTRAL FIELD",
        "link": this.url + "SSS CENTRAL FIELD.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "CHARACTERISTIC PROPERTIES OF ELEMENTARY PARTICLES AND INTERACTIONS",
        "link": this.url + "SSS CHARACTERISTIC PROPERTIES OF ELEMENTARY PARTICLES AND INTERACTIONS.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "COMPTON EFFECT",
        "link": this.url + "SSS COMPTON EFFECT.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "ELEMENTARY PARTICLES (1)",
        "link": this.url + "SSS ELEMENTARY PARTICLES (1).pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "INTERACTION OF IONIZING RADIATION WITH MATTER",
        "link": this.url + "sss Interaction of ionizing radiation with matter.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "RADIATION INTERACTION",
        "link": this.url + "SSS radiation interaction.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "THEWKBAPPROXIMATION-",
        "link": this.url + "sss thewkbapproximation-.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "VARIATION",
        "link": this.url + "SSS VARIATION.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "PHOTOELECTRIC EFFECT",
        "link": this.url + "sssPHOTOELECTRIC EFFECT.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "CONSERVATION LAWS GOVERNING ELEMENTARY PARTICLES NEEDS EDITING",
        "link": this.url + "SSSS Conservation laws governing elementary particles NEEDS EDITING.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "STARK EFFECT",
        "link": this.url + "ssss stark effect.pdf"
    },
    {
        "name": "PROF SHIPRA",
        "title": "PARTICLE AND ANTI",
        "link": this.url + "SSSSS PARTICLE AND ANTI.pdf"
    },
    {
        "name": "PROF SMRITI",
        "title": "COST CLASSIFICATION ON NORMALITY AND RESPONSIBILTY CENTRE",
        "link": this.url + "cost classification on normality and responsibilty centre.pdf"
    },
    {
        "name": "PROF SMRITI",
        "title": "COST CLASSIFICATION",
        "link": this.url + "cost classification.pdf"
    },
    {
        "name": "PROF SMRITI",
        "title": "MEANING AND NATURE OF COST ACCOUNTING",
        "link": this.url + "Meaning and nature of cost accounting.pdf"
    },
    {
        "name": "PROF SMRITI",
        "title": "METHODS AND TECHNIQUES OF COST ACCOUNTING",
        "link": this.url + "Methods and techniques of cost accounting.pdf"
    },
    {
        "name": "PROF SMRITI",
        "title": "OBJECTIVES, IMPORTANCE, LIMITATION OF COST ACCOUNTING",
        "link": this.url + "objectives, importance, limitation of cost accounting.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "ADHUNIK PUNJABI SAHIT VICH BHAI VEER SINGH DA YOGDAN",
        "link": this.url + "Adhunik Punjabi Sahit Vich Bhai Veer Singh da Yogdan.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "AMRITA PRITAM DI KAV SAMVEDNA ",
        "link": this.url + "Amrita Pritam dI kav samvedna pdf.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "GURU NANAK KAL DI SUFI KAVITA",
        "link": this.url + "Guru Nanak Kal Di Sufi Kavita.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "HARYANA DI PUNJABI KAVITA",
        "link": this.url + "Haryana Di Punjabi Kavita.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "HARYANA VICH RACHIT PUNJABI KAVITA",
        "link": this.url + "haryana vich rachit punjabi kavita.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "IKANGI DI PRIBHASHA",
        "link": this.url + "Ikangi di Pribhasha.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "JARNAIL SINGH DI KAHANI KALA",
        "link": this.url + "Jarnail Singh Di Kahani Kala.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "KAHANI DI PRIBHASHA",
        "link": this.url + "Kahani di Pribhasha.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "NANAK KAL DI SUFI KAVITA",
        "link": this.url + "nanak kal di sufi kavita.pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "NATAK DI PRIBHASHA",
        "link": this.url + "Natak di Pribhasha(PDF).pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "NOVEL DEFINATION",
        "link": this.url + "Novel Defination(PDF).pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "PARVAASI SAHIT DA ARTH.",
        "link": this.url + "Parvaasi Sahit Da Arth.pptx(PDF).pdf"
    },
    {
        "name": "DR TILAK RAJ",
        "title": "PARVASI PUNJABI KAVITA DI SATHITI TE SAMBHAVNA",
        "link": this.url + "Parvasi Punjabi Kavita di sathiti te sambhavna.pdf"
    },
    {
        "name": "DR VINEY CHANDEL",
        "title": "DHRUVSWAMINI",
        "link": this.url + "DOC-20230324-WA0010_.pdf"
    },
    {
        "name": "DR VINEY CHANDEL",
        "title": "BHAKTI KAAL KI PARISTITHIYAAN",
        "link": this.url + "DOC-20230324-WA0011_.pdf"
    },
    {
        "name": "DR VINEY CHANDEL",
        "title": "MAITHILI SHARAN GUPT",
        "link": this.url + "DOC-20230324-WA0012_.pdf"
    },
    {
        "name": "DR VINEY CHANDEL",
        "title": "PRITHVIRAJ CHAUHAN KI AANKHEIN",
        "link": this.url + "DOC-20230324-WA0013_.pdf"
    }
];
c72Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion-7/7.2.1 (B). POLICIES FOR WOMEN EMPOWEMENT AND CLEAN AND GREEN ENVIRONMENT/';
private policies:{title:string,link:string}[]=[
    {
        title:'Gender Equity Policy',
        link: this.c72Url+ '1. Gender Equity Policy.pdf'
    },
    {
        title:'Grievance redressal policy',
        link: this.c72Url+ '2. Grievance redressal policy.pdf'
    },
    {
        title:'Prevention and Sexual Harassment Policy',
        link: this.c72Url+ '3. Prevention and Sexual Harassment Policy.pdf'
    },
    {
        title:'Anti Ragging Policy',
        link: this.c72Url+ '4. Anti Ragging Policy.pdf'
    },
    {
        title:'Emargency_First Aid Policy',
        link: this.c72Url+ '5. Emargency_First Aid Policy.pdf'
    },
    {
        title:'Green Policy',
        link: this.c72Url+ '6. Green Policy.pdf'
    },
    {
        title:'Waste Management Policy',
        link: this.c72Url+ '7. Waste Management Policy.pdf'
    },
    {
        title:'E-waste Management Policy',
        link: this.c72Url+ '8. E-waste Management Policy.pdf'
    },
    {
        title:'E-goverance Policy',
        link: this.c72Url+ '9. E-goverance Policy.pdf'
    },
];
c132Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 1/1.3.2/PROJECTS-2021-22/';
private session:{title:string,link:string}[]=[
    {
        title:'MA Public admn.',
        link: this.c132Url+ '1. MA Public admn..pdf'
    },
    {
        title:'M.Sc. BIO TECH.',
        link: this.c132Url+ '2. M.Sc. BIO TECH..pdf'
    },
    {
        title:'B.SC. IMB',
        link: this.c132Url+ '3. B.SC. IMB.pdf'
    },
    {
        title:'B.COM. 3rd SEM',
        link: this.c132Url+ '4. B.COM. 3RD SEM.pdf'
    },
    {
        title:'B.COM. 5TH SEM',
        link: this.c132Url+ '5. B.COM. 5TH SEM.pdf'
    },
    {
        title:'BBA',
        link: this.c132Url+ '6. BBA.pdf'
    },
    {
        title:'MSW SEM-2',
        link: this.c132Url+ '7. MSW SEM-2.pdf'
    },
    {
        title:'MSW SEM-4',
        link: this.c132Url+ '8. MSW SEM-4.pdf'
    },
    {
        title:'B.Ed 1st year',
        link: this.c132Url+ '9. B.Ed. 1ST YEAR.pdf'
    },
    {
        title:'B.Ed 2nd year',
        link: this.c132Url+ '10. B.Ed 2nd year.pdf'
    },
    {
        title:'EVS Projects',
        link: this.c132Url+ '11. EVS Projects.pdf'
    },
    {
        title:'B.SC SPORTS COMP. AWARNESS',
        link: this.c132Url+ '12. B.SC SPORTS COMP. AWARNESS.pdf'
    },
    {
        title:'B.P.ED. 2ND YEAR',
        link: this.c132Url+ '13. B.P.ED. -2ND YEAR.pdf'
    },
    {
        title:'M.SC. PHYSICS SEM-2',
        link: this.c132Url+ '14. M.SC. PHYSICS SEM 2.pdf'
    },
    {
        title:'M.SC. PHYSICS SEM-4',
        link: this.c132Url+ '15. M.SC. PHYSICS -SEM-4.pdf'
    },
    {
        title:'BA SOCIAL WORK SEM-4',
        link: this.c132Url+ '16. B.A. SOCIAL WORK SEM-4.pdf'
    },
    {
        title:'BA SOCIAL WORK SEM-6',
        link: this.c132Url+ '17. BA SOCIAL WORK SEM-6.pdf'
    },
    
];
c73Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion-7/7.3.1_Institutional Distinctiveness/';
private c7institutional:{title:string,link:string}[]=[
    {
        "title": "Activities conducted by sports department",
        "link": this.c73Url + "Activities conducted by sports department.pdf"
    },
    {
        "title": "Appreciation  letters of faculty",
        "link": this.c73Url + "Appriciation  letters of faculty.pdf"
    },
    {
        "title": "Bills of sports equipments",
        "link": this.c73Url + "Bills of sports equipments.pdf"
    },
    {
        "title": "Certificates of winners",
        "link": this.c73Url + "Certificates of winners.pdf"
    },
    {
        "title": "Faculty List",
        "link": this.c73Url + "Faculty List.pdf"
    },
    {
        "title": "Games competition  position",
        "link": this.c73Url + "Games chempition position.pdf"
    },
    {
        "title": "Games offered in the college",
        "link": this.c73Url + "Games offered in the college.pdf"
    },
    {
        "title": "Gems of the college (international medals winners and participant)",
        "link": this.c73Url + "Gems of the college (international medals winners and participant).pdf"
    },
    {
        "title": "Haryana Govt. Cash Awards_Scholarhip winners",
        "link": this.c73Url + "Haryana Govt. Cash Awards_Scholarhip winners.pdf"
    },
    {
        "title": "INDOOR AND OUTDOOR SPORTS FACILITIES",
        "link": this.c73Url + "INDOOR AND OUTDOOR SPORTS FACILITIES.pdf"
    },
    {
        "title": "Inspirers of Sports persons",
        "link": this.c73Url + "Inspirers of Sports persons.pdf"
    },
    {
        "title": "Inter college level winner and participants",
        "link": this.c73Url + "Inter college level winner and participants.pdf"
    },
    {
        "title": "JAI Freeship list of sports persons",
        "link": this.c73Url + "JAI Freeship list of sports persons.pdf"
    },
    {
        "title": "JAI Scholarship_Awardee sports persons",
        "link": this.c73Url + "JAI Scholarship_Awardee sports persons.pdf"
    },
    {
        "title": "JAI Scholarship_Freeship Policy",
        "link": this.c73Url + "JAI Scholarship_Freeship Policy.pdf"
    },
    {
        "title": "KUK OVERALL CHAMPIONSHIP",
        "link": this.c73Url + "KUK OVERALL CHAMPIONSHIP.pdf"
    },
    {
        "title": "List of our Prominent Sports Alumni 1968 onwards",
        "link": this.c73Url + "List of our Prominent Sports Alumni 1968 onwards.pdf"
    },
    {
        "title": "Medals winners in AIIU_North zone and national championship",
        "link": this.c73Url + "Medals winners in AIIU_North zone and national championship.pdf"
    },
    {
        "title": "Participation of players in AIIU _ North zone_Khelo India_National championship",
        "link": this.c73Url + "Participation of players in AIIU _ North zone_Khelo India_National championship.pdf"
    },
    {
        "title": "Scholarships and cash award winners of Harayana Govt. and University",
        "link": this.c73Url + "Scholarships and cash award winners of Harayana Govt. and University.pdf"
    },
    {
        "title": "Social Contribution",
        "link": this.c73Url + "Social Contribution.pdf"
    },
    {
        "title": "Sports Courses",
        "link": this.c73Url + "Sports Courses.pdf"
    },
    {
        "title": "Sports expenditure",
        "link": this.c73Url + "Sports expenditure.pdf"
    },
    {
        "title": "Sports LABS AND equipments",
        "link": this.c73Url + "Sports LABS AND equipments.pdf"
    },
    {
        "title": "Sports labs and equipmets PHOTOS",
        "link": this.c73Url + "Sports labs and equipmets PHOTOS.pdf"
    },
    {
        "title": "Sports policy and SOP",
        "link": this.c73Url + "Sports policy and SOP.pdf"
    },
    {
        "title": "Tournament championship and kuk camps",
        "link": this.c73Url + "Tournament championship and kuk camps.pdf"
    },
    {
        "title": "Track and fields record of the college",
        "link": this.c73Url + "Track and fiels record of the college.pdf"
    },
    {
        "title": "University cash award_scholarship winners",
        "link": this.c73Url + "University cash award_scholarship winners.pdf"
    }
];

c351Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.5.1_NO. OF FUNCTIONAL MOUs_COLLABORATIVE RESEARCH ACTIVITIES/';
private c351research:{title:string,link:string}[]=[
    {
        "title": "VIRTUAL SYSTEM, YAMUNA NAGAR (11 Projects)",
        "link": this.c351Url + "1 VIRTUAL SYSTEM, YAMUNA NAGAR (11 Projects).pdf"
    },
    {
        "title": "INDIAN INSTITUTE OF COMPUTER EDUCATION (02 Projects)",
        "link": this.c351Url + "2 INDIAN INSTITUTE OF COMPUTER EDUCATION (02 Projects).pdf"
    },
    {
        "title": "WEBCOM TECHNOLOGIES (3rd Sem_42 Projects)",
        "link": this.c351Url + "3 WEBCOM TECHNOLOGIES (3rd Sem_42 Projects).pdf"
    },
    {
        "title": "WEBCOM TECHNOLOGIES (5th Sem_34 Projects)",
        "link": this.c351Url + "4 WEBCOM TECHNOLOGIES (5th Sem_34 Projects).pdf"
    },
    {
        "title": "BHARAT VIKAS PARISHAD (01_Training)",
        "link": this.c351Url + "5 BHARAT VIKAS PARISHAD (01_Training).pdf"
    },
    {
        "title": "VARDHA_S REMEDIAL CENTRE ( 02_Field work Trainings)",
        "link": this.c351Url + "6 VARDHA_S REMEDIAL CENTRE ( 02_Field work Trainings).pdf"
    },
    {
        "title": "VARDHA_S REMEDIAL CENTRE ( 01_Field work Training)",
        "link": this.c351Url + "7 VARDHA_S REMEDIAL CENTRE ( 01_Field work Training).pdf"
    },
    {
        "title": "PUNJAB SPORTS HOUSE (02_Industry Visits)",
        "link": this.c351Url + "8 PUNJAB SPORTS HOUSE (02_Industry Visits).pdf"
    },
    {
        "title": "SMS LABANA GIRLS COLLEGE ( 01_Extension Lecture)",
        "link": this.c351Url + "9 SMS LABANA GIRLS COLLEGE ( 01_Extension Lecture).pdf"
    },
    {
        "title": "DISTRICT COUNCIL FOR CHILD WELFARE (01_Project)",
        "link": this.c351Url + "10 DISTRICT COUNCIL FOR CHILD WELFARE (01_Project).pdf"
    },
    {
        "title": "Ek soch Nai Soch ( 01 Lecture)",
        "link": this.c351Url + "11 Ek soch Nai Soch ( 01 Lecture).pdf"
    },
    {
        "title": "DAIS, DELHI ( 01 Activity on Weltland Day)",
        "link": this.c351Url + "12 DAIS, DELHI ( 01 Activity on Weltland Day).pdf"
    },
    {
        "title": "DAIS, DELHI ( 01 Activity on world water Day)",
        "link": this.c351Url + "13  DAIS, DELHI ( 01 Activity on world water Day).pdf"
    },
    {
        "title": "DNA LABS, DEHRADUN (01 Training)",
        "link": this.c351Url + "14 DNA LABS, DEHRADUN (01 Training).pdf"
    },
    {
        "title": "DNA LABS, DEHRADUN (03 Dissertations)",
        "link": this.c351Url + "15 DNA LABS, DEHRADUN (03 Dissertations).pdf"
    },
    {
        "title": "DAV PG COLLEGE, KARNAL ( 01 FDP)",
        "link": this.c351Url + "KUK OVERALL CHAMPIONSHIP.pdf"
    },
    {
        "title": "ONE STEP FOR OTHERS CHARITABLE SOCIETY (01 Training)",
        "link": this.c351Url + "17 ONE STEP FOR OTHERS CHARITABLE SOCIETY (01 Training).pdf"
    },
    {
        "title": "BABU ANANT RAM JANTA COLLEGE, KAUL (01 FDP)",
        "link": this.c351Url + "18 BABU ANANT RAM JANTA COLLEGE, KAUL (01 FDP).pdf"
    },
    {
        "title": "MAHARAJA AGGARSIAN COLLEGE, JAGADHRI ( 03 Activities)",
        "link": this.c351Url + "19 MAHARAJA AGGARSIAN COLLEGE, JAGADHRI ( 03 Activities).pdf"
    },
    {
        "title": "WEBCOM TECHNOLOGIES (49 Projects)",
        "link": this.c351Url + "20 WEBCOM TECHNOLOGIES (49 Projects).pdf"
    },
    {
        "title": "WEBCOM TECHNOLOGIES (42 Projects)",
        "link": this.c351Url + "21 WEBCOM TECHNOLOGIES (42 Projects).pdf"
    },
];

  constructor() { }
  getSession(){
    return this.session.slice();
  }
  getPolicies(){
    return this.policies.slice();
  }
  getC7Institutional(){
    return this.c7institutional.slice();
  }
  getC351research(){
    return this.c351research.slice();
  }
  getAmandeep() {
    return this.ppt.slice().filter(item => item.name === 'DR AMANDEEP KAUR')
  }
  getAnuradha() {
    return this.ppt.slice().filter(item => item.name === 'PROF ANURADHA')
  }
  getAnurag() {
    return this.ppt.slice().filter(item => item.name === 'DR ANURAG')
  }
  getAshok() {
    return this.ppt.slice().filter(item => item.name === 'DR ASHOK KHURANA')
  }
  getBhawna() {
    return this.ppt.slice().filter(item => item.name === 'PROF BHAWNA SHARMA')
  }
  getBodhraj() {
    return this.ppt.slice().filter(item => item.name === 'DR BODHRAJ')
  }
  getDheeraj() {
    return this.ppt.slice().filter(item => item.name === 'PROF DHEERAJ')
  }
  getAmrita() {
    return this.ppt.slice().filter(item => item.name === 'DR AMRITA PRITAM')
  }
  getBaljeet() {
    return this.ppt.slice().filter(item => item.name === 'DR BALJEET SINGH')
  }
  getGurvinder() {
    return this.ppt.slice().filter(item => item.name === 'DR GURVINDER KAUR')
  }




  getIqbal() {
    return this.ppt.slice().filter(item => item.name === 'DR IQBAL SINGH')
  }
  getJaswinder() {
    return this.ppt.slice().filter(item => item.name === 'DR JASWINDER KAUR')
  }
  getJoshan() {
    return this.ppt.slice().filter(item => item.name === 'DR JOSHPREET SANDHU')
  }
  getKamal() {
    return this.ppt.slice().filter(item => item.name === 'DR KAMALPREET KAUR')
  }
  getManpu() {
    return this.ppt.slice().filter(item => item.name === 'DR MANPUNEET KAUR')
  }
  getMeenu() {
    return this.ppt.slice().filter(item => item.name === 'DR MEENU KAPUR')
  }
  getMPagg() {
    return this.ppt.slice().filter(item => item.name === 'DR M P AGGARWAL')
  }
  getRanjit() {
    return this.ppt.slice().filter(item => item.name === 'DR RANJIT SINGH')
  }
  getRavinder() {
    return this.ppt.slice().filter(item => item.name === 'DR RAVINDER KAUR')
  }
  getSangeeta() {
    return this.ppt.slice().filter(item => item.name === 'DR SANGEETA ')
  }
  getVarsha() {
    return this.ppt.slice().filter(item => item.name === 'DR VARSHA NIGAM')
  }


  getAmarjeet() {
    return this.ppt.slice().filter(item => item.name === 'DR AMARJEET SINGH')
  }
  getAPMishra() {
    return this.ppt.slice().filter(item => item.name === 'DR A P MISHRA')
  }
  getHari() {
    return this.ppt.slice().filter(item => item.name === 'DR HARI KIRAN KAUR')
  }
  getSahib() {
    return this.ppt.slice().filter(item => item.name === 'DR SAHIB SINGH')
  }
  getEtender() {
    return this.ppt.slice().filter(item => item.name === 'PROF ETENDER')
  }
  getJagat() {
    return this.ppt.slice().filter(item => item.name === 'DR JAGAT ')
  }
  getJasmeen() {
    return this.ppt.slice().filter(item => item.name === 'PROF JASMEEN KAUR')
  }
  getJaspreet() {
    return this.ppt.slice().filter(item => item.name === 'PROF JASPREET SINGH')
  }
  getKanika() {
    return this.ppt.slice().filter(item => item.name === 'PROF KANIKA')
  }
  getKavita() {
    return this.ppt.slice().filter(item => item.name === 'PROF  KAVITA')
  }




  getKiranpal() {
    return this.ppt.slice().filter(item => item.name === 'DR KIRANPAL SINGH VIRK')
  }
  getMandeep() {
    return this.ppt.slice().filter(item => item.name === 'DR MANDEEP KAUR')
  }
  getNPSingh() {
    return this.ppt.slice().filter(item => item.name === 'DR N P SINGH')
  }
  getNeelam() {
    return this.ppt.slice().filter(item => item.name === 'PROF NEELAM SINGH')
  }
  getNeena() {
    return this.ppt.slice().filter(item => item.name === 'DR NEENA PURI')
  }
  getNidhi() {
    return this.ppt.slice().filter(item => item.name === 'DR NIDHI')
  }
  getPradeep() {
    return this.ppt.slice().filter(item => item.name === 'PROF PARDEEP')
  }
  getParveen() {
    return this.ppt.slice().filter(item => item.name === 'DR PARVEEN KUMAR')
  }
  getPooja() {
    return this.ppt.slice().filter(item => item.name === 'PROF POOJA RANI')
  }
  getPratibha() {
    return this.ppt.slice().filter(item => item.name === 'DR PRATIBHA SHARMA')
  }



  getRSVohra() {
    return this.ppt.slice().filter(item => item.name === 'PROF R S VOHRA')
  }
  getRaju() {
    return this.ppt.slice().filter(item => item.name === 'PROF RAJU')
  }
  getRavneet() {
    return this.ppt.slice().filter(item => item.name === 'PROF RAVNEET KAUR')
  }
  getRenu() {
    return this.ppt.slice().filter(item => item.name === 'PROF RENU')
  }
  getSantosh() {
    return this.ppt.slice().filter(item => item.name === 'DR SANTOSH KURRA')
  }
  getSeema() {
    return this.ppt.slice().filter(item => item.name === 'PROF SEEMA')
  }
  getShalini() {
    return this.ppt.slice().filter(item => item.name === 'PROF SHALINI BHARDWAJ')
  }
  getShaweta() {
    return this.ppt.slice().filter(item => item.name === 'DR SHAWETA SACHDEVA')
  }
  getShilpi() {
    return this.ppt.slice().filter(item => item.name === 'PROF SHILPI')
  }
  getShipra() {
    return this.ppt.slice().filter(item => item.name === 'PROF SHIPRA')
  }


  getSmriti() {
    return this.ppt.slice().filter(item => item.name === 'PROF SMRITI')
  }
  getTilak() {
    return this.ppt.slice().filter(item => item.name === 'DR TILAK RAJ')
  }
  getViney() {
    return this.ppt.slice().filter(item => item.name === 'DR VINEY CHANDEL')
  }
}