import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AlumniComponent } from "src/app/view/menu-detail/alumni/alumni.component";
import { CareerItemComponent } from "src/app/view/menu-detail/career/career-item/career-item.component";
import { CareerComponent } from "src/app/view/menu-detail/career/career.component";
import { ContactUsComponent } from "src/app/view/menu-detail/contact-us/contact-us.component";
import { MenuDetailComponent } from "src/app/view/menu-detail/menu-detail.component";
import { NaacItemComponent } from "src/app/view/menu-detail/naac/naac-item/naac-item.component";
import { NaacComponent } from "src/app/view/menu-detail/naac/naac.component";
import { NcteItemComponent } from "src/app/view/menu-detail/ncte/ncte-item/ncte-item.component";
import { NcteComponent } from "src/app/view/menu-detail/ncte/ncte.component";
import { NoticeItemComponent } from "src/app/view/menu-detail/notice/notice-item/notice-item.component";
import { NoticeComponent } from "src/app/view/menu-detail/notice/notice.component";
import { PlacementComponent } from "src/app/view/menu-detail/placement/placement.component";
import { StarsComponent } from "src/app/view/menu-detail/stars/stars.component";
import { TestimonialComponent } from "src/app/view/menu-detail/testimonial/testimonial.component";

const route:Route[]=[
    {path:'menu-detail',redirectTo:'/menu-detail/stars',pathMatch:'full'},
    {path:'',component:MenuDetailComponent,children:[
        {path:'stars',component:StarsComponent},
        {path:'placement',component:PlacementComponent},
        {path:'testimonial',component:TestimonialComponent},
        {path:'alumni',component:AlumniComponent},
        {path:'contact-us',component:ContactUsComponent},
        {path:'naac', component:NaacComponent,children:[
            {path:':id',component:NaacItemComponent}
        ]},
        {path:'ncte', component:NcteComponent,children:[
            {path:':id',component:NcteItemComponent}
        ]},
        {path:'notice',component:NoticeComponent,children:[
            {path:':id',component:NoticeItemComponent}
        ]},
        {path:'career',component:CareerComponent,children:[
            {path:':id',component:CareerItemComponent}
        ]}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class MenuDetailRoutingModule{}