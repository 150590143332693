import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  community:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.community=this.facultyService.getCommunity();
  }

}
