<div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <a routerLink="/naac/dvv/criteria7"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
        <nav id="secondary-navigation">
          <!-- Header -->
          <header class="header">
            <h5>7.1.3</h5>
          </header>
          <!-- /Header -->
          <ul>
            <li class="one">
              <a (click)="changeRoute('1')">Report on Beyond the Campus Environmental Promotion Activities_1</a>
            </li>
            <li class="two">
              <a (click)="changeRoute('2')">Action Taken Report and Achievement Report on Clean and Green Campus_2</a>
            </li>
            <li class="three">
              <a (click)="changeRoute('3')">Environment_Green and Energy Audit report_3</a>
            </li>
            <li class="four">
              <a (click)="changeRoute('4')">Certificate from the External Auditing Agency_4</a>
            </li>
            <li class="four">
                <a (click)="changeRoute('5')">Certificates of the Awards from Recognized Agency_5</a>
              </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-9">
        <div id="five-one-one">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card shadow-lg">
                  <h4 class="card-title text-center" >{{selectedValue.name}}</h4>
                  <div class="card-body text-justify">
                    <div id="detail" class="text-center">
                      <pdf-viewer [src]="selectedValue.src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                  </div>
                  </div>
                </div>
  
              </div>
            </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>