<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <!-- <a routerLink="/naac/ssr" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a> -->
      <nav id="secondary-navigation" *ngIf="showSideMenu">
        <!-- Header -->
        <header class="header">
          <h5>Gender Equality</h5>
        </header>
        <!-- /Header -->
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/activities/gender/2023-24" (click)="checkRoute()">2023-24</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/activities/gender/2022-23" (click)="checkRoute()">2022-23</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/activities/gender/2021-22" (click)="checkRoute()">2021-22</a>
          </li>

        </ul>
      </nav>
    </div>
    <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>