import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { PoCoComponent } from "src/app/view/research/po-co/po-co.component";
import { CentreComponent } from "src/app/view/research/centre/centre.component";
import { CollaborativeComponent } from "src/app/view/research/collaborative/collaborative.component";
import { FdpComponent } from "src/app/view/research/fdp/fdp.component";
import { GuestComponent } from "src/app/view/research/guest/guest.component";
import { MajorProjectComponent } from "src/app/view/research/major-project/major-project.component";
import { MinorProjectComponent } from "src/app/view/research/minor-project/minor-project.component";
import { PublicationComponent } from "src/app/view/research/publication/publication.component";
import { ResearchComponent } from "src/app/view/research/research.component";
import { ResultComponent } from "src/app/view/research/result/result.component";
import { ScholarshipComponent } from "src/app/view/research/scholarship/scholarship.component";
import { SeminarComponent } from "src/app/view/research/seminar/seminar.component";
import { StudentProjectComponent } from "src/app/view/research/student-project/student-project.component";
import { WorkshopComponent } from "src/app/view/research/workshop/workshop.component";
import { WTwotwoTwothreeComponent } from "src/app/view/research/workshop/w-twotwo-twothree/w-twotwo-twothree.component";
import { WTwooneTwotwoComponent } from "src/app/view/research/workshop/w-twoone-twotwo/w-twoone-twotwo.component";
import { SpTwotwoTwothreeComponent } from "src/app/view/research/student-project/sp-twotwo-twothree/sp-twotwo-twothree.component";
import { SpTwooneTwotwoComponent } from "src/app/view/research/student-project/sp-twoone-twotwo/sp-twoone-twotwo.component";
import { MouComponent } from "src/app/view/research/mou/mou.component";
import { ScTwotwoTwothreeComponent } from "src/app/view/research/scholarship/sc-twotwo-twothree/sc-twotwo-twothree.component";
import { ScTwooneTwotwoComponent } from "src/app/view/research/scholarship/sc-twoone-twotwo/sc-twoone-twotwo.component";

const route:Route[]=[
    {path:'',component:ResearchComponent,children:[
        {path:'research-centre',component:CentreComponent},
        {path:'major-research-project',component:MajorProjectComponent},
        {path:'minor-research-project',component:MinorProjectComponent},
        {path:'student-project',component:StudentProjectComponent, children: [
            {path:'2022-2023', component:SpTwotwoTwothreeComponent},
            {path:'2021-2022', component:SpTwooneTwotwoComponent},
        ]},
        {path:'publication',component:PublicationComponent},
        {path:'Seminar-conference',component:SeminarComponent},
        {path:'workshops',component:WorkshopComponent, children:[
            {path:'2022-2023', component:WTwotwoTwothreeComponent},
            {path:'2021-2022', component:WTwooneTwotwoComponent},
        ]},
        {path:'guest-lecture',component:GuestComponent},
        {path:'fdps',component:FdpComponent},
        {path:'scholarship',component:ScholarshipComponent, children: [
            {path:'2022-2023', component:ScTwotwoTwothreeComponent},
            {path:'2021-2022', component:ScTwooneTwotwoComponent},
        ]},
        {path:'collaborative',component:CollaborativeComponent},
        {path:'result',component:ResultComponent},
        {path:'pos-cos', component: PoCoComponent},
        {path:'mou', component: MouComponent},
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class ResearchRoutingModule{}