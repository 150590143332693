import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-lp-twotwo-twothree',
  templateUrl: './lp-twotwo-twothree.component.html',
  styleUrls: ['./lp-twotwo-twothree.component.scss']
})
export class LpTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1RzvAAS6nXIZA1dAXgKAJnrPasRtb6AkD/preview', 
    'https://drive.google.com/file/d/1zGogug6GiCffJgFbFKkpEM1AkEMoCjBR/preview' ,
    'https://drive.google.com/file/d/15pUWtvP7rK6BudwHLWsDftWxpkpe52TG/preview', 
    'https://drive.google.com/file/d/1bLxbZEhS5NGMaMfXASOpO_OG1tcDPY2Q/preview',
    'https://drive.google.com/file/d/1bhqJA8C_6N5ASTppsGZfnjqmbhQx6jTy/preview',
    'https://drive.google.com/file/d/1jVwKcfEVQkmqEiaHU56GWjlt4F4XP4mI/preview',
    'https://drive.google.com/file/d/1wWW6lgCSQR7MhdDYK1iCgoVGqKoyMWxh/preview',
    'https://drive.google.com/file/d/1ZAkEVX-6cELoknZN3g4cETSelK6k3bhV/preview',
    'https://drive.google.com/file/d/1q-Mwwi6P5kKrlEsIUSfyBf8dqv92egxH/preview',
    'https://drive.google.com/file/d/1gZWf4t-k96aKSHRN0zZznOAb7daHs6lV/preview',
    'https://drive.google.com/file/d/1SX6vTlM3EvXkSKW37tN72gEcdSwgIZuS/preview',
    'https://drive.google.com/file/d/1KMdTIWGoMtxCXG56-Mznm_tVXwU1b-0e/preview',
    'https://drive.google.com/file/d/1oW7ASGiPfBrO6MoZRU2KMmgqRWAjf-n1/preview',
    'https://drive.google.com/file/d/1SJgtZLuGtS-bauFF5AuLnmgeL9lPnra9/preview',
    'https://drive.google.com/file/d/1zh2hQuKsFo2SqY1NkdXxK44ugR9m8VQF/preview',
    'https://drive.google.com/file/d/1nOePrkkK9f3e_LSaVrfp9Yqv4sov67XS/preview',
    'https://drive.google.com/file/d/1d4sp1EE9fYSN5DSU3YoZIiBfWti2RqDb/preview',
    'https://drive.google.com/file/d/1KVtGgMMa30F-TA9xpy9y-hUthxVD3HMQ/preview',
    'https://drive.google.com/file/d/164VRczq8fYpkN5j-aEujLv8V5mcimyvj/preview',
    'https://drive.google.com/file/d/19WreVuJFYnZJfC7ibPzVpOW8PR8wQIC0/preview',
    'https://drive.google.com/file/d/120rgznL9Jox-Qeu23boIzs99HVlQdHc3/preview',
    'https://drive.google.com/file/d/19SUCZuyEE7QJSAG0b0v8EuAJIRK9lbu7/preview',
    'https://drive.google.com/file/d/1QhbGlhPBQNXjvpPhUgeJiK9skVTJwkcf/preview',
    'https://drive.google.com/file/d/1ws2tchasup19XJPezPYBYWNwvurOyzV2/preview',
    'https://drive.google.com/file/d/1J3h-iP5gddYLrFGPYqv00hSFxEF7ctD9/preview',
    'https://drive.google.com/file/d/1x4iYxoQBhSSYGNS0jb8xqU3ZfDpS2CXW/preview',
    'https://drive.google.com/file/d/1OYMTMSi_FvLDWg92uNwh6OSfkS5aevAK/preview',
    'https://drive.google.com/file/d/1c67mjusV15QlYrExWrIajDiVWYe_CXJN/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    if (year == '23') {
      $('.twentythree').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[22];
    }
    if (year == '24') {
      $('.twentyfour').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[23];
    }
    if (year == '25') {
      $('.twentyfive').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[24];
    }
    if (year == '26') {
      $('.twentysix').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[25];
    }
    if (year == '27') {
      $('.twentyseven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[26];
    }
    if (year == '28') {
      $('.twentyeight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twenty').removeClass('active');
      this.src = this.pdfSrc[27];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
