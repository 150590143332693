import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-w-twotwo-twothree',
  templateUrl: './w-twotwo-twothree.component.html',
  styleUrls: ['./w-twotwo-twothree.component.scss']
})
export class WTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1GRtqDNjpMbatobA3FfBAsehBCS5o3BpO/preview', 
    'https://drive.google.com/file/d/1_QvOb-glV5mCn_XGGhZ6kGVwsTiV4RXN/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
