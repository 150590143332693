import { Component, OnInit } from '@angular/core';
import { ResearchProject } from 'src/app/models/research-project.model';
import { ProjectService } from 'src/app/services/research/project.service';

@Component({
  selector: 'app-major-project',
  templateUrl: './major-project.component.html',
  styleUrls: ['./major-project.component.scss']
})
export class MajorProjectComponent implements OnInit {

  majorProject:ResearchProject[];
  constructor(private projectService:ProjectService) { }

  ngOnInit(): void {
    this.majorProject=this.projectService.getMajorProject();
  }

}
