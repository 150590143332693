<div id="home">
  <!-- <app-small-menu></app-small-menu> -->
  <app-bulletin></app-bulletin>
  <!-- <app-why></app-why> -->

  <!-- <app-why></app-why> -->
  <app-new-menu></app-new-menu>
  <!-- <app-scholarship></app-scholarship> -->
  <app-program></app-program>

  <!-- <app-about></app-about> -->
  <app-unique></app-unique>
  <app-news></app-news>
  <app-media></app-media>
  <app-testimonial></app-testimonial>
  <app-count></app-count>

  <app-awards></app-awards>

  <!-- <app-hiring></app-hiring> -->
  <!-- <app-count></app-count> -->



</div>

<!-- Modal -->
<!-- <div class="modal " id="myModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-lg " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>IMPORTANT NOTICE FOR KU ODD SEMESTER EXAM</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <pdf-viewer [src]="pdfSrc"
          [render-text]="true"
          [original-size]="false"
          style="width: 400px; height: 500px;min-width: 300px;"
          (click)="openUrl('https://examforms.kuk.ac.in/KukHome/LogIn.aspx')"
          ></pdf-viewer>
          
          <hr>
          <pdf-viewer [src]="pdfSrc1"
          [render-text]="true"
          [original-size]="false"
          style="width: 400px; height: 500px;min-width: 300px;"
          (click)="openUrl('https://surveyheart.com/form/637f34f90e707b41bcb9f086')"
          ></pdf-viewer>
          <hr>
          <pdf-viewer [src]="pdfSrc2"
          [render-text]="true"
          [original-size]="false"
          style="width: 400px; height: 500px;min-width: 300px;"
          (click)="openUrl('https://surveyheart.com/form/63804da7e31fa341e55a7052')"
          ></pdf-viewer>
          <hr>
          <pdf-viewer [src]="pdfSrc3"
          [render-text]="true"
          [original-size]="false"
          style="width: 400px; height: 500px;min-width: 300px;"
          (click)="openUrl('https://surveyheart.com/form/63804ad08fbb634201520d8a')"
          ></pdf-viewer>
        </div>
      <div class="modal-body">
        <div class="modal-body">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
            style="width: 400px; height: 500px;min-width: 300px;"></pdf-viewer>

          <hr>
          <h6><strong>IMPORTANT NOTICE FOR KU ODD SEMESTER EXAM 2022/JAN 2022 </strong></h6>
          <ol>
            <li>
              <h6><strong>The examination will be conducted in OFFLINE mode only.</strong></h6>
            </li>
            <li>
              <h6><strong>The candidate must check/verify the dates of examinations from the latest date-sheet placed on
                  the University Website i.e. <a href="www.kuk.ac.in" target="_blank">www.kuk.ac.in.</a></strong></h6>
            </li>
            <li>
              <h6><strong>Before attempting the question paper, the candidate shall ensure that the correct question
                  paper has been supplied to him/her by the College/Institute/Department concerned.</strong></h6>
            </li>
            <Li>
              <h6><strong>The candidate will be required to attempt the paper as per instructions printed in the
                  question paper.</strong></h6>
            </Li>
            <Li>
              <h6><strong>No complaint for attempting a wrong question paper by the candidate will be entertained later
                  on.</strong></h6>
            </Li>
            <Li>
              <h6><strong>The candidate must use blue/black pen only.</strong></h6>
            </Li>
            <Li>
              <h6><strong>The students who are suffering from COVID 19 or having symptoms should not come for the
                  examinations. Such candidates will submit a request to his/her Head of the Institute/Centre
                  Superintendent well in time along with a medical certificate/COVID report duly issued by the competent
                  authority (should not be older than 48 hours) then his/her examination will be conducted later on.
                </strong></h6>
            </Li>
            <Li>
              <h6><strong>The candidate will be allowed to bring the sanitizer and transparent water bottle.</strong>
              </h6>
            </Li>
            <Li>
              <h6><strong>Further, the instruction - The students will be allowed to attempt any questions instead of
                  attempting unit wise questions, if found may kindly be IGNORED.</strong></h6>
            </Li>
            <Li>
              <h6><strong>Exam timings will be <br><br> Morning: 10am to 1pm <br>Evening: 1.45pm to 4.45pm</strong></h6>
            </Li>
          </ol>
          <hr>

          <h6><strong>केयू विषम सेमेस्टर परीक्षा 2022/जनवरी 2022 के लिए महत्वपूर्ण सूचना</strong></h6>
          <ol>
            <li>
              <h6><strong>परीक्षा केवल ऑफलाइन मोड में आयोजित की जाएगी।</strong></h6>
            </li>
            <li>
              <h6><strong>उम्मीदवार को विश्वविद्यालय की वेबसाइट यानी <a href="www.kuk.ac.in"
                    target="_blank">www.kuk.ac.in</a> पर रखी गई नवीनतम डेट-शीट से परीक्षाओं की तारीखों की जांच / सत्यापन
                  करना चाहिए।</strong></h6>
            </li>
            <li>
              <h6><strong>प्रश्न पत्र का प्रयास करने से पहले, उम्मीदवार को यह सुनिश्चित करना होगा कि संबंधित
                  कॉलेज/संस्थान/विभाग द्वारा उसे सही प्रश्न पत्र प्रदान किया गया है।
                </strong></h6>
            </li>
            <Li>
              <h6><strong>उम्मीदवार को प्रश्न पत्र में छपे निर्देशों के अनुसार पेपर हल करना होगा।</strong></h6>
            </Li>
            <Li>
              <h6><strong>अभ्यर्थी द्वारा गलत प्रश्नपत्र हल करने की शिकायत पर बाद में विचार नहीं किया जाएगा।</strong>
              </h6>
            </Li>
            <Li>
              <h6><strong>अभ्यर्थी नीले/काले पेन का ही प्रयोग करें।</strong></h6>
            </Li>
            <Li>
              <h6><strong>जो छात्र COVID 19 से पीड़ित हैं या जिनमें लक्षण हैं, उन्हें परीक्षाओं के लिए नहीं आना चाहिए।
                  ऐसे उम्मीदवार अपने संस्थान के प्रमुख / केंद्र अधीक्षक को सक्षम प्राधिकारी द्वारा विधिवत जारी किए गए एक
                  चिकित्सा प्रमाण पत्र / COVID रिपोर्ट के साथ समय पर एक अनुरोध प्रस्तुत करेंगे (48 घंटे से अधिक पुराना
                  नहीं होना चाहिए) तब उनकी परीक्षा होगी बाद में आयोजित किया गया।
                </strong></h6>
            </Li>
            <Li>
              <h6><strong>अभ्यर्थी को सैनिटाइजर और पारदर्शी पानी की बोतल लाने की अनुमति होगी।</strong></h6>
            </Li>
            <Li>
              <h6><strong>इसके अलावा, निर्देश - छात्रों को यूनिट वार प्रश्नों के प्रयास के बजाय किसी भी प्रश्न को हल
                  करने की अनुमति दी जाएगी, यदि पाया जाता है तो कृपया ध्यान न दें।</strong></h6>
            </Li>
            <Li>
              <h6><strong>परीक्षा का समय <br><br> सुबह: सुबह 10 बजे से दोपहर 1 बजे तक। <br>शाम: दोपहर 1.45 से 4.45 बजे
                  तक।</strong></h6>
            </Li>
          </ol>

        </div>
      </div>
    </div>
  </div> -->



  <!-- <div class="modal " id="myModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered  modal-lg " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>IMPORTANT NOTICE FOR KU ODD SEMESTER EXAM</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-body">
            <img src="/assets/img/popup/popup1.jpeg" alt="Admission Open" class="img-fluid w-100">
          </div>
        </div>
      </div>
    </div> -->