import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-chemistry',
  templateUrl: './chemistry.component.html',
  styleUrls: ['./chemistry.component.scss']
})
export class ChemistryComponent implements OnInit {
  chemistry:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.chemistry=this.facultyService.getChemistry();
  }

}
