import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-hindi',
  templateUrl: './hindi.component.html',
  styleUrls: ['./hindi.component.scss']
})
export class HindiComponent implements OnInit {
  hindi:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.hindi=this.facultyService.getHindi();
  }

}
