


<div id="lesson">
  <div class="container-fluid">
      <div class="row">
          
          <div class="col-md-3" *ngIf="showSideMenu">
              <nav id="secondary-navigation">
                  <!-- Header -->
                  <header class="header">
                      <h5>Workshops</h5>
                  </header>
                  <!-- /Header -->
                  <ul>
                      <li routerLinkActive="active">
                          <a routerLink="/research/workshops/2022-2023" (click)="checkRoute()">2022-2023</a>
                      </li>
                      <li routerLinkActive="active">
                          <a routerLink="/research/workshops/2021-2022" (click)="checkRoute()">2021-2022</a>
                      </li>
                     
                  </ul>
              </nav>
          </div>
          <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
              <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div>