<!-- <div id="elective">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Elective Courses</h4>
                    <div class="card-body">
                        <iframe src="https://drive.google.com/file/d/10W4bXUb72U4teTl3vFDV4Cp-CMTAXQ1h/preview" width="100%" height="980" allow="autoplay"></iframe>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div> -->



<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>ELECTIVE COURSES</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('2022-23')">2022-23</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2021-22')">2021-22</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>