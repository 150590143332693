import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-add-on',
  templateUrl: './add-on.component.html',
  styleUrls: ['./add-on.component.scss']
})
export class AddOnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  setModal(name:string, id:string){
    $('#addTitle').html(name);
    $('#courseItem').attr('src', id);
   

  }
  clearLink(){
    $('#courseItem').attr('src', null);
  }
}
