import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  result:{title:string,link:string}[]=[
    {
      title:'2017-18',
      link:'https://drive.google.com/file/d/1QjfgX21lcGrVs87pflSWsB72jUcQR4E5/view?usp=sharing'
    },
    {
      title:'2018-19',
      link:'https://drive.google.com/file/d/1GeFafPS9sbsN6G1s-xSYyaMbURhIFX4Z/view?usp=sharing'
    },
    {
      title:'2019-20',
      link:'https://drive.google.com/file/d/16O5r1bz1ij_17o9di89zuC130tuNh7oF/view?usp=sharing'
    },
    {
      title:'2020-21',
      link:'https://drive.google.com/file/d/1sndyLFWFHaYKKw3oLQpXF6iEkuC6cMzR/view?usp=sharing'
    },
    {
      title:'2021-22',
      link:'https://drive.google.com/file/d/12ExGPp0jVp-3VHhwDDC8_ZF7CpWsLR5u/view?usp=sharing'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
