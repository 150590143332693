import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c6-six-two-two',
  templateUrl: './c6-six-two-two.component.html',
  styleUrls: ['./c6-six-two-two.component.scss']
})
export class C6SixTwoTwoComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion-6/E-goverance Policy.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }

}
