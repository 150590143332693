import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-public-administration',
  templateUrl: './public-administration.component.html',
  styleUrls: ['./public-administration.component.scss']
})
export class PublicAdministrationComponent implements OnInit {
  public:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.public=this.facultyService.getPublicAdministration();
  }
}
