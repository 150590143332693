<div id="notice-list" *ngIf="showNotice">
  <div class="container-fluid mt-5 px-lg-5">
      <div class="row">
          <div class="col-12 text-center">
              <h3 class="card-title">NOTICE Files</h3>
              <hr class="pb-1 rounded">
          </div>
      </div>
      <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col" class="w-50">Title</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let notice of notices; let i=index">
              <th scope="row">{{notice.date | date: 'dd/MM/yyyy'}}</th>
              <td (click)="hideNotice()"><a [routerLink]="['/menu-detail/notice',notice.path]">{{notice.title}}</a></td>
            </tr>
           
          </tbody>
        </table>
  </div>
</div>
<div *ngIf="!showNotice">
<router-outlet></router-outlet>
</div>

<!-- <iframe src="https://drive.google.com/file/d/1R2eIb9wFkdAMJSAv25wotU4JjONN2ZRc/preview"></iframe> -->