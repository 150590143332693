<div id="new-menu" class="mt-4" data-scroll>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class=" font-weight-bold heading"> Why GNKC?</h2>
                <hr class=" p-1" style="width: 300px;">
                <p class="font-weight-bold">Come discover our exciting and vibrant campus and find out why #Life is invigorating and fulfilling.</p>
            </div>
        </div>
        <div class="row text-center mt-2">
            <div class="d-none d-xl-block col-xl-1 mt-3"></div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/gallery">
                    <i class="fa fa-universal-access"></i>
                    <div class="card-body">
                        <h5>Gallery</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/menu-detail/stars" (click)="setUrl('Star Achiever')">
                    <i class="fa fa-bullseye"></i>
                    <div class="card-body">
                        <h5>Star Achievers</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/menu-detail/placement" (click)="setUrl('Placement Cell')">
                    <i class="fa fa-braille"></i>
                    <div class="card-body">
                        <h5>Placement Cell</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/courses/arts" (click)="setUrl('Arts')">
                    <i class="fa fa-id-badge"></i>
                    <div class="card-body">
                        <h5>10+ Job Oriented Courses</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/menu-detail/alumni" (click)="setUrl('Alumni')">
                    <i class="fa fa-life-ring"></i>
                    <div class="card-body">
                        <h5>Alumni</h5>
                    </div>
                </div>
            </div>
            <div class="d-none d-xl-block col-xl-1 mt-3"></div>

            <div class="d-none d-xl-block col-xl-1 mt-3"></div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/activities/ncc" (click)="setUrl('NCC')">
                    <i class="fa fa-clipboard"></i>
                    <div class="card-body">
                        <h5>Co-Curricular Activities</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/activities/sports" (click)="setUrl('Sports')">
                    <i class="fas fa-futbol"></i>
                    <div class="card-body">
                        <h5>Sports</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/research/research-centre" (click)="setUrl('Research Centre')">
                    <i class="fas fa-cogs"></i>
                    <div class="card-body">
                        <h5>Research</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/activities/community-services" (click)="setUrl('Community Services')">
                    <i class="far fa-handshake"></i>
                    <div class="card-body">
                        <h5>Community Service</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/" (click)="setUrl('Contact Us')">
                    <i class="fas fa-medal"></i>
                    <div class="card-body">
                        <h5>Upto 1.3 Crore Of Scholarships</h5>
                    </div>
                </div>
            </div>
            <div class="d-none d-xl-block col-xl-1 mt-3"></div>

            <div class="d-none d-xl-block col-xl-1 mt-3"></div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/research/Seminar-conference" (click)="setUrl('Seminars/Conferences')">
                    <i class="fas fa-headset"></i>
                    <div class="card-body">
                        <h5>Conferences</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/" (click)="setUrl('Contact Us')">
                    <i class="fas fa-globe-asia"></i>
                    <div class="card-body">
                        <h5>Dignitary Visit</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/activities/cultural" (click)="setUrl('Cultural Activities')">
                    <i class="far fa-calendar-check"></i>
                    <div class="card-body">
                        <h5>Cultural Activities</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card2 mx-auto" routerLink="/" (click)="setUrl('Contact Us')">
                    <i class="fab fa-creative-commons-sampling-plus"></i>
                    <div class="card-body">
                        <h5>Innovation</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-3">
                <div class="card card1 mx-auto" routerLink="/" (click)="setUrl('Contact Us')">
                    <i class="fas fa-file-contract"></i>
                    <div class="card-body">
                        <h5>MoUs</h5>
                    </div>
                </div>
            </div>
            <div class="d-none d-xl-block col-xl-1 mt-3"></div>
        </div>
        <!-- <div class="row text-center mt-2">
            
        </div>
        <div class="row text-center mt-2">
            
        </div> -->
    </div>
</div>