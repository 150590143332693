import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c6-six-three-three',
  templateUrl: './c6-six-three-three.component.html',
  styleUrls: ['./c6-six-three-three.component.scss']
})
export class C6SixThreeThreeComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/2021-22-Teaching Staff.pdf', 
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/2020-21-Teaching Staff.pdf', 
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/2019-20-Teaching Staff.pdf', 
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/2018-19-Teaching Staff.pdf', 
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/2017-18-Teaching Staff.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/Non teaching 2021-22.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/Non teaching 2020-21.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/Non teaching 2019-20.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.3.3_Teachers_Non-Teaching staff FDP/Non teaching 2018-19.pdf', ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    // this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.sixth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.sixth').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    

  }
  hideLoader() {
    this.isLoading = false;
  }
}
