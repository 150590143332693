import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c32-twentyone',
  templateUrl: './c32-twentyone.component.html',
  styleUrls: ['./c32-twentyone.component.scss']
})
export class C32TwentyoneComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
        title: 'National Webinar on Entrepreneurial development for young students',
        link: this.c3321Url + '9_ 22.9.21-EntreDev-For-Young-Students-DVV-Final.pdf'
    },
    {
        title: 'Workshop on IPR & its Role in Research and Innovation',
        link: this.c3321Url + '10_11.5.22-IPR-and-its-role-in-Research-DVV-Final.pdf'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
