import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aec',
  templateUrl: './aec.component.html',
  styleUrls: ['./aec.component.scss']
})
export class AecComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
