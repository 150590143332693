import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c34-eighteen-nineteen',
  templateUrl: './c34-eighteen-nineteen.component.html',
  styleUrls: ['./c34-eighteen-nineteen.component.scss']
})
export class C34EighteenNineteenComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Photos/';
  result:{title:string,link:string}[]=[
    {
        title: 'SWACHTTA PAKHWARA',
        link: this.c343Url + '1_2018-19-NCC_SWACHTTA-PAKHWARA_photos.pdf'
    },
    {
        title: 'CLEANLINESS DRIVE',
        link: this.c343Url + '2._2018-19 NCC_CLEANLINESS-DRIVE_photos.pdf'
    },
    {
        title: 'SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN',
        link: this.c343Url + '3_2018-19-NCC_SURGICAL-STRIKE-DAY-AND-SWACHHTA-ABHIYAN_photos.pdf'
    },
    {
        title: 'DRUG CONTROL RALLY',
        link: this.c343Url + '4_2018-19 NCC_DRUG-CONTROL-RALLY_photos.pdf'
    },
    {
        title: 'SADAK SURAKSHA PRIKSHA',
        link: this.c343Url + '5_2018-19 NCC_SADAK-SURAKSHA-PRIKSHA_photos.pdf'
    },
    {
        title: 'BLOOD DONATION RALLY',
        link: this.c343Url + '6_2018-19 NCC__BLOOD-DONATION-RALLY_ photos.pdf'
    },
    {
        title: 'BLOOD DONATION CAMP',
        link: this.c343Url + '7_2018-19 NCC_BLOOD-DONATION-CAMP_photos.pdf'
    },
    {
        title: 'FLAG DAY',
        link: this.c343Url + '8_2018-19-NCC_ FLAG-DAY_photos.pdf'
    },
    {
        title: 'TRAFFIC AWARNESS RALLY',
        link: this.c343Url + '9_2018-19-NCC__TRAFFIC-AWARNESS-RALLY_ photos.pdf'
    },
    {
        title: 'WORLD WATER DAY',
        link: this.c343Url + '10_2018-19-NCC_WORLD-WATER-DAY_photos.pdf'
    },
    {
        title: 'BETI BACHAO BETI PADHAO RALLY',
        link: this.c343Url + '11_2018-19-NCC__BETI-BACHAO-BETI-PADHAO-RALLY_photos.pdf'
    },
    {
        title: 'ATC CAMP',
        link: this.c343Url + '12_2018-19-NCC_ATCCAMP_photos.pdf'
    },
    {
        title: 'Helping the Poor & Needy person',
        link: this.c343Url + '13_2018-19-NSS_Helping-the-Poor_Needy-person_photos.pdf'
    },
    {
        title: 'National level Road Safety Week',
        link: this.c343Url + '14_2018-19-NSS_National-level-Road-Safety-Week__photos.pdf'
    },
    {
        title: 'State level Road Safety Seminar',
        link: this.c343Url + '15_2018-19-NSS_State-level-Road-Safety _photos.pdf'
    },
    {
        title: 'Health and Blood Checkup Camp',
        link: this.c343Url + '16_2018-19-NSS_Health-and-Blood-Checkup-Camp_photo.pdf'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
