import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c34-nineteen-twenty',
  templateUrl: './c34-nineteen-twenty.component.html',
  styleUrls: ['./c34-nineteen-twenty.component.scss']
})
export class C34NineteenTwentyComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Photos/';
  result:{title:string,link:string}[]=[
    {
        title: 'ENVIRONMENT DAY ',
        link: this.c343Url + '17_2019-20-NCC_ENVIRONMENT-DAY_photos.pdf'
    },
    {
        title: 'UNITY DAY',
        link: this.c343Url + '18_2019-20-NCC_UNITY-DAY_photos.pdf'
    },
    {
        title: 'ANTI CORRUPTION AWARENESS',
        link: this.c343Url + '19_2019-20-NCC_ANTI-CORRUPTION-AWARENESS_photos.pdf'
    },
    {
        title: 'ROAD SAFETY RALLY',
        link: this.c343Url + '20_2019-20-NCC_ROAD-SAFETY-RALLY_photos.pdf'
    },
    {
        title: 'DISTRIBUTION FOOD TO MIGRANTS',
        link: this.c343Url + '21_2019-20-NCC_DISTRIBUTION-FOOD-TO-MIGRANTS_photos.pdf'
    },
    {
        title: 'MAINTAINANCE OF LAW AND DUTY',
        link: this.c343Url + '22_2019-20-NCC_MAINTAINANCE-OF-LAW-AND-DUTY_photos.pdf'
    },
    {
        title: 'SOCIAL SERVICE BY NCC CADETS',
        link: this.c343Url + '23_2019-20-NCC_SOCIAL-SERVICE-BY-NCC-CADETS_photos.pdf'
    },
    {
        title: 'National Voter Day',
        link: this.c343Url + '24_2019-2020_NSS-National-Voter-Day_photos.pdf'
    },
    {
        title: 'NSS 7 Day Camp at Gdhauli',
        link: this.c343Url + '25_2019-2020_NSS-NSS-7-Day-Camp-at-Gdhauli_photos.pdf'
    },
    {
        title: 'Tree Plantation at Gdhauli',
        link: this.c343Url + '26_2019-2020_NSS-Tree-Plantation-at-Gdhauli_photos.pdf'
    },
    {
        title: 'National Deworming Day',
        link: this.c343Url + '27_2019-2020_NSS-National-Deworming-Day_photos.pdf'
    },
    {
        title: 'ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019',
        link: this.c343Url + '28_2019-20-YRC_ROAD-SAFETY-AWARENESS-PROGRAM,MOTOR-VEHCHCLE-ACT-2019_photos.pdf'
    },
    {
        title: 'NUKKAD NATAK ON BLOOD DONATION',
        link: this.c343Url + '29_2019-20-YRC_NUKKAD-NATAK-ON-BLOOD-DONATION_photos.pdf'
    },
    {
        title: '26TH ANNUAL BLOOD DONATION CAMP',
        link: this.c343Url + '30_2019-20-YRC_26TH-ANNUAL-BLOOD-DONATION-CAMP_photos.pdf'
    },
    {
        title: 'NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION',
        link: this.c343Url + '31_2019-20-YRC_NATIONAL-LEVEL-ONLINE-COLLAGE-MAKING-COMPETITION_photos.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
