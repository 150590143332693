// import { Component, OnInit } from '@angular/core';
// declare var $: any;

// @Component({
//   selector: 'app-sports',
//   templateUrl: './sports.component.html',
//   styleUrls: ['./sports.component.scss']
// })
// export class SportsComponent implements OnInit {
//   pdfSrc: string[] = [
//     'https://drive.google.com/file/d/1vKfhimEPokYOSThHCOAfmav9ry-_BNvb/preview',
//     'https://drive.google.com/file/d/1FsYxtXp23UdLpnmyh6YofhqYDq9veWFg/preview'
//   ];
//   src: string;
//   isLoading = true;
//   show22 = true;
//   show23 = true;
//   showSubmenu2023 = false;
//   activeRoute: string = '2023-24';

//   ngOnInit(): void {
//     this.changeRoute('2022-23');
//   }

//   toggleSubmenu(year: string) {
//     if (year === '2023-24') {
//       this.showSubmenu2023 = !this.showSubmenu2023;
//     }
//   }

//   changeRoute(year: string, route?: string) {
//     this.isLoading = true;
//     this.activeRoute = route ? route : year;

//     if (year === '2023-24') {
//       if (route === 'sports-events') {
//         $('.first').addClass('active');
//         $('.second').removeClass('active');
//         $('.third').removeClass('active');
//         this.src = this.pdfSrc[0];
//         this.show22 = false;
//         this.show23 = true;
//         this.showSubmenu2023 = true;
//       } else if (route === 'athletic-meet') {
//         $('.first').addClass('active');
//         $('.second').removeClass('active');
//         $('.third').removeClass('active');
//         this.src = this.pdfSrc[1];
//         this.show22 = true;
//         this.show23 = true;
//         this.showSubmenu2023 = true;
//       } else {
//         $('.first').addClass('active');
//         $('.second').removeClass('active');
//         $('.third').removeClass('active');
//         this.show22 = false;
//         this.show23 = false;
//         this.showSubmenu2023 = false;
//       }
//     }
//     if (year === '2022-23') {
//       $('.second').addClass('active');
//       $('.first').removeClass('active');
//       $('.third').removeClass('active');
//       this.src = this.pdfSrc[1];
//       this.show22 = false;
//       this.show23 = true;
//       this.showSubmenu2023 = false;
//     }
//     if (year === '2021-22') {
//       $('.third').addClass('active');
//       $('.first').removeClass('active');
//       $('.second').removeClass('active');
//       this.show22 = true;
//       this.show23 = false;
//       this.showSubmenu2023 = false;
//     }
//   }

//   hideLoader() {
//     this.isLoading = false;
//   }
// }
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1vKfhimEPokYOSThHCOAfmav9ry-_BNvb/preview',
    'https://drive.google.com/file/d/1FsYxtXp23UdLpnmyh6YofhqYDq9veWFg/preview'
  ];
  src: string;
  isLoading = true;
  show22 = true;
  show23 = true;
  showSubmenu2023 = false;
  activeRoute: string = '2023-24';
  activeYear: string = '2023-24'; // Add this property

  ngOnInit(): void {
    this.changeRoute('2022-23');
  }

  toggleSubmenu(year: string) {
    if (year === '2023-24') {
      this.showSubmenu2023 = !this.showSubmenu2023;
      if (this.showSubmenu2023) {
        this.activeYear = '2023-24';
      } else {
        this.activeYear = '';
      }
    }
  }

  changeRoute(year: string, route?: string) {
    this.isLoading = true;
    this.activeRoute = route ? route : year;
    this.activeYear = year; // Set the active year

    if (year === '2023-24') {
      if (route === 'sports-events') {
        this.src = this.pdfSrc[0];
        this.show22 = false;
        this.show23 = true;
        this.showSubmenu2023 = true;
      } else if (route === 'athletic-meet') {
        this.src = this.pdfSrc[1];
        this.show22 = true;
        this.show23 = true;
        this.showSubmenu2023 = true;
      } else {
        this.show22 = false;
        this.show23 = false;
        this.showSubmenu2023 = false;
      }
    } else if (year === '2022-23') {
      this.src = this.pdfSrc[1];
      this.show22 = false;
      this.show23 = true;
      this.showSubmenu2023 = false;
    } else if (year === '2021-22') {
      this.show22 = true;
      this.show23 = false;
      this.showSubmenu2023 = false;
    }

    this.hideLoader();
  }

  goBack() {
    this.showSubmenu2023 = false;
    this.show22 = true;
    this.show23 = true;
    this.activeYear = '';
  }

  hideLoader() {
    this.isLoading = false;
  }
}
