<div id="lab">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Language Lab</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                           
The college has a well-equiped, state-of-the art multimedia English language learning laboratry to impart practical training in communicative English. Another lab has been created with 10 computes and wi-fi internet access to give students opportunities for on-line language learning.</p>
                       
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-12 mt-3">
                                <img src="/assets/img/language/2.JPG" alt="language" class="img-fluid rounded">
                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>