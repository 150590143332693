import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesComponent } from 'src/app/view/activities/activities.component';
import { AdventureComponent } from 'src/app/view/activities/adventure/adventure.component';
import { ClubComponent } from 'src/app/view/activities/club/club.component';
import { CulturalComponent } from 'src/app/view/activities/cultural/cultural.component';
import { LegalCellComponent } from 'src/app/view/activities/legal-cell/legal-cell.component';
import { NccComponent } from 'src/app/view/activities/ncc/ncc.component';
import { NssComponent } from 'src/app/view/activities/nss/nss.component';
import { ServicesComponent } from 'src/app/view/activities/services/services.component';
import { SportsComponent } from 'src/app/view/activities/sports/sports.component';
import { VismadComponent } from 'src/app/view/activities/vismad/vismad.component';
import { WomenCellComponent } from 'src/app/view/activities/women-cell/women-cell.component';
import { YouthComponent } from 'src/app/view/activities/youth/youth.component';
import { RouterModule } from '@angular/router';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { FormsModule } from '@angular/forms';
import { NotFoundModule } from '../not-found/not-found/not-found.module';
import { SocialComponent } from 'src/app/view/activities/social/social.component';
import { GenderComponent } from 'src/app/view/activities/gender/gender.component';
import { CrossComponent } from 'src/app/view/activities/cross/cross.component';
import { PlacementCellComponent } from 'src/app/view/activities/placement-cell/placement-cell.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { GTwooneTwotwoComponent } from 'src/app/view/activities/gender/g-twoone-twotwo/g-twoone-twotwo.component';
import { GTwotwoTwothreeComponent } from 'src/app/view/activities/gender/g-twotwo-twothree/g-twotwo-twothree.component';
import { GTwothreeTwofourComponent } from 'src/app/view/activities/gender/g-twothree-twofour/g-twothree-twofour.component';



@NgModule({
  declarations: [
    ActivitiesComponent,
    NccComponent,
    NssComponent,
    SportsComponent,
    WomenCellComponent,
    CulturalComponent,
    LegalCellComponent,
    YouthComponent,
    ClubComponent,
    AdventureComponent,
    ServicesComponent,
    VismadComponent,
    SocialComponent,
    GenderComponent,
    CrossComponent,
    PlacementCellComponent,
    GTwotwoTwothreeComponent,
    GTwooneTwotwoComponent,
    GTwothreeTwofourComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ActivitiesRoutingModule,
    FormsModule,
    NotFoundModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule
  ]
})
export class ActivitiesModule { }
