import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-c5-five-one-two',
  templateUrl: './c5-five-one-two.component.html',
  styleUrls: ['./c5-five-one-two.component.scss']
})
export class C5FiveOneTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-5/5.1.2_SOFT SKILL/SOFT SKILL_LIFE SKILL_LANG. SKILL/5.1.2_2021-22_SOFT SKILL_LIFE SKILL_LANG. SKILL.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2_SOFT SKILL/SOFT SKILL_LIFE SKILL_LANG. SKILL/5.1.2_2020-21_SOFT SKILL_LIFE SKILL_LANG. SKILL.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2_SOFT SKILL/SOFT SKILL_LIFE SKILL_LANG. SKILL/5.1.2_2019-20_SOFT SKILL_LIFE SKILL_LANG. SKILL.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2_SOFT SKILL/SOFT SKILL_LIFE SKILL_LANG. SKILL/5.1.2_2018-19_SOFT SKILL_LIFE SKILL_LANG. SKILL.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2_SOFT SKILL/SOFT SKILL_LIFE SKILL_LANG. SKILL/5.1.2_2017-18 SOFT SKILL_LIFE SKILL_LANG. SKILL.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2/ICT SKILLS 5 YEARS/5.1.2_ICT 2021-22.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2/ICT SKILLS 5 YEARS/5.1.2_ICT_2020-21.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2/ICT SKILLS 5 YEARS/5.1.2_ICT 2019-20.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2/ICT SKILLS 5 YEARS/5.1.2_ICT 2018-19.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2/ICT SKILLS 5 YEARS/5.1.2_ICT 2017-18.pdf',
    '/assets/pdf/NAAC/Criterion-5/5.1.2_Proofs of capacity development and skill enhancement activities.pdf'];
  src: string;
  constructor() { }

  isLoading = false;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[3];
    }
    if (id === '5') {
      $(`five`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[4];
    }
    if (id === '6') {
      $(`six`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[5];
    }
    if (id === '7') {
      $(`seven`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`five`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[6];
    }
    if (id === '8') {
      $(`eight`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`five`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[7];
    }
    if (id === '9') {
      $(`nine`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`five`).removeClass('show');
      $(`ten`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[8];
    }
    if (id === '10') {
      $(`ten`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`five`).removeClass('show');
      $(`eleven`).removeClass('show');
      this.src = this.pdfSrc[9];
    }
    if (id === '11') {
      $(`eleven`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      $(`eight`).removeClass('show');
      $(`nine`).removeClass('show');
      $(`five`).removeClass('show');
      $(`ten`).removeClass('show');
      this.src = this.pdfSrc[10];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }
}
