import { Component, OnInit } from '@angular/core';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss']
})
export class HiringComponent implements OnInit {

  constructor(private pathNameService:PathNameService) { }

  ngOnInit(): void {
  }
  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }
}
