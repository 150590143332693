<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Functional MOUs(2022-2023)</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">IICE</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">CONTRIVERZ</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">WEBCOM</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">DNA</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">VARDA</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">DISTT COUNCIL CHILD WELFARE</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">MAHARAJA</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">FPAI</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>