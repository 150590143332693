import {  Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-one',
  templateUrl: './one-one.component.html',
  styleUrls: ['./one-one.component.scss']
})
export class OneOneComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
   
  }
}
