import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowNoticeService {

  private showNotice:boolean=true;
  noticeChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showNotice=true;
    this.noticeChange.next(true);
  }
  hide(){
    this.showNotice=false;
    this.noticeChange.next(false);
  }
}
