import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1214-twentyone',
  templateUrl: './c1214-twentyone.component.html',
  styleUrls: ['./c1214-twentyone.component.scss']
})
export class C1214TwentyoneComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title: 'Computer networking Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Computer networking Certificates.pdf'
    },
    {
      title: 'Computer Repair Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Computer Repair Certificates.pdf'
    },
    {
      title: 'Digital Marketing Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Digital Marketing Certificates.pdf'
    },
    {
      title: 'E-Learning Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_E-Learning Certificates.pdf'
    },
    {
      title: 'Financial Services Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Financial Services Certificates.pdf'
    },
    {
      title: 'Gender Sensitization Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Gender Sensitization Certificates.pdf'
    },
    {
      title: 'Geography and Remote Sensing Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Geography and Remote Sensing Certificates.pdf'
    },
    {
      title: 'Home electrician Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Home electrician Certificates.pdf'
    },
    {
      title: 'Human Rights Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Human Rights Certificates.pdf'
    },

    {
      title: 'Hygiene and Fitness Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Hygiene _ Fitness certificates.pdf'
    },
    {
      title: 'Library Science Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Library restorer Certificates.pdf'
    },
    {
      title: 'Medical lab. Technician Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Medical Lab technician Certificates.pdf'
    },
    {
      title: 'Nutrition and health Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Nutrition and health Certificates.pdf'
    },
    {
      title: 'Public relation management Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Public relation management Certificates.pdf'
    },
    {
      title: 'Yoga Certificates',
      link: this.c3321Url + '1.2.1.4_2021-22_Yoga Certificates.pdf'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
