<div id="hall">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Gymnasium Hall</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            College has a unique Haryana’s No. 1 multipurpose gymnasium hall (S.Shamsher Singh Jauhar Multipurpose Hall) with measurement of 24x36x12 meters where indoor games are practised and played and it is also used for organizing functions of different types.</p>
                        
                            <div id="gymCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#gymCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#gymCarousel" data-slide-to="1"></li>
                                  <li data-target="#gymCarousel" data-slide-to="2"></li>
                                  <li data-target="#gymCarousel" data-slide-to="3"></li>
                                 
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/gym/1.jpeg" alt="gym" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/gym/2.jpeg" alt="gym" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item ">
                                    <img src="/assets/img/gym/3.jpeg" alt="gym" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/gym/4.jpeg" alt="gym" class="img-fluid rounded">
                                  </div>
                                 
                                </div>
                                <a class="carousel-control-prev" href="#gymCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#gymCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gym/1.jpeg" alt="gym" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gym/2.jpeg" alt="gym" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gym/3.jpeg" alt="gym" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gym/4.jpeg" alt="gym" class="img-fluid rounded">
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>