import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c34-twenty-twentyone',
  templateUrl: './c34-twenty-twentyone.component.html',
  styleUrls: ['./c34-twenty-twentyone.component.scss']
})
export class C34TwentyTwentyoneComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Photos/';
  result:{title:string,link:string}[]=[
    {
        title: 'VOLUNTARY SERVICE DURING COVID -19',
        link: this.c343Url + '32_2020-21_NCC_VOLUNTARY-SERVICE-DURING-COVID-19_photos.pdf'
    },
    {
        title: 'ARMED FORCED FLAG DAY',
        link: this.c343Url + '33_2020-21_NCCARMED-FORCED-FLAG-DAY__photos.pdf'
    },
    {
        title: 'SWATCHHTA PAKHWARA',
        link: this.c343Url + '34_2020-21_NCC_SWATCHHTA-PAKHWARA_photos.pdf'
    },
    {
        title: 'CLEANLINESS DRIVE',
        link: this.c343Url + '35_2020-21_NCC_CLEANLINESS-DRIVE_photos.pdf'
    },
    {
        title: 'NATIONAL GIRLS HEALTH SERVICE WEEK',
        link: this.c343Url + '36_2020-21_NCC_NATIONAL-GIRLS-HEALTH-SERVICE-WEEK_photos.pdf'
    },
    {
        title: 'ROAD SAFETY RALLY',
        link: this.c343Url + '37_2020-21_NCC__ROAD-SAFETY-RALLY_photos.pdf'
    },
    {
        title: 'DONATION TO SAHEED NISHANT SHARMA',
        link: this.c343Url + '38_2020-21_NCC_DONATION-TO-SAHEED-NISHANT-SHARMA_photos.pdf'
    },
    {
        title: 'NO PLASTIC USE ABHIYAN',
        link: this.c343Url + '39_2020-21_NCC_NO-PLASTIC-USE-ABHIYAN_photos.pdf'
    },
    {
        title: 'NASHA MUKTI DIWAS',
        link: this.c343Url + '40_2020-21_NCC_NASHA-MUKTI-DIWAS_photos.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
