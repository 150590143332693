import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c5-five-one-four',
  templateUrl: './c5-five-one-four.component.html',
  styleUrls: ['./c5-five-one-four.component.scss']
})
export class C5FiveOneFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
