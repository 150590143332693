import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c6-six-four-one',
  templateUrl: './c6-six-four-one.component.html',
  styleUrls: ['./c6-six-four-one.component.scss']
})
export class C6SixFourOneComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion-6/Purchase Policy.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
