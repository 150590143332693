import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PathNameService {
  private url:string;
  urlChange=new BehaviorSubject<string>(null);
  constructor() { }

  setUrl(route:string){
      this.url=route;
      this.urlChange.next(this.url);
  }
}
