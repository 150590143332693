<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr/criteria3/3-4/3-4-3"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>3.4.3.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li>
                        <a>Extension Activities List Photographs</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/3-4-3-1/2021-22" >2021-22</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/3-4-3-1/2020-21" >2020-21</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/3-4-3-1/2019-20" >2019-20</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/3-4-3-1/2018-19" >2018-19</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <h4 class="text-center">Activities Photographs</h4>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>