import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { DataStorageService } from './service/data-storage/data-storage.service';
import { BlogService } from './services/blog/blog.service';
import { ImageService } from './services/image.service';
import { MediaService } from './services/media/media.service';
import { NewsService } from './services/news/news.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  showBook=true;
  private timer:Observable<any>;
  sub:Subscription;
  constructor(private imgService:ImageService,
    private mediaService:MediaService,
    private newsService:NewsService,
    private dataService:DataStorageService,
    private blogService:BlogService){}
  ngOnInit(){
    this.setTimer();
    this.dataService.fetchBulletin();
   
    
    
    
  }
  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    
  }
  setTimer(){
    this.showBook=true;
    this.timer=timer(3300);
    this.sub=this.timer.subscribe(()=>{
      this.showBook=false;
      
    });
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }
}
