import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ImportantService } from 'src/app/services/important/important.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
import { ShowImportantService } from 'src/app/services/showImportant/show-important.service';
declare var $:any;
@Component({
  selector: 'app-important-item',
  templateUrl: './important-item.component.html',
  styleUrls: ['./important-item.component.scss']
})
export class ImportantItemComponent implements OnInit {
  link: string = '';
  id:string;
  sub:Subscription;
  constructor(private showService:ShowImportantService,
    private route:ActivatedRoute,
    private importantService:ImportantService,
    private pathService:PathNameService) { }

  ngOnInit(): void {
    this.link='https://gnkc.ac.in/' + location.pathname;
    this.sub=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        this.pathService.setUrl(this.importantService.getTitle(this.id));
        $('.description').html(this.importantService.searchImportant(this.id));
       
      }
    )
  }
  ngOnDestroy(){
    this.showService.show();
    this.sub.unsubscribe();
  }
}
