<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" *ngIf="showSideMenu">
            <a routerLink="/naac/dvv/criteria1/1-2-2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>1.2.2.4 Certificates of Adon Courses</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4/2021-22" (click)="checkRoute()">2021-22</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4/2020-21" (click)="checkRoute()">2020-21</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4/2019-20" (click)="checkRoute()">2019-20</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4/2018-19" (click)="checkRoute()">2018-19</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4/2017-18" (click)="checkRoute()">2017-18</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <h4 class="text-center" *ngIf="showHeading">Certificates of Adon Courses</h4>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>