<div id="play">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Play Field</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            College through sports department provides the infrastructure and facilities for all the games including Basketball, Cricket, Volleyball, Weight-Lifting, Power-Lifting, Chess, Athletics, Aerobics, Gymnastics, Kho-Kho, Archery, Shooting, Softball, Football, Hockey, Handball, Swimming, Badminton, Lawn Tennis, Kabaddi, Table Tennis, Fencing, Judo, Boxing, Wrestling, Mal-khamb, Cycling, Taekwondo and Water Polo.</p>
                        <p class="lead">College has a state of the art multipurpose hall (24×36×12 mtrs.) where indoor games are practized and played. For outdoor games college has ground on the campus and 4.0 acres ground in Tejli and a tie-up with the Tejli Sports Stadium.</p>
                        <div id="playCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#playCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#playCarousel" data-slide-to="1"></li>
                              <li data-target="#playCarousel" data-slide-to="2"></li>
                              <li data-target="#playCarousel" data-slide-to="3"></li>
                             
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/play/1.jpeg" alt="play" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/play/2.jpeg" alt="play" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/play/3.jpeg" alt="play" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/play/4.jpeg" alt="play" class="img-fluid rounded">
                              </div>
                              
                            </div>
                            <a class="carousel-control-prev" href="#playCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#playCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div> 
                        <!-- <div class="row">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/play/1.jpeg" alt="play" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/play/2.jpeg" alt="play" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/play/3.jpeg" alt="play" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/play/4.jpeg" alt="play" class="img-fluid rounded">
                            </div>
                           
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>