import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-four-two',
  templateUrl: './two-four-two.component.html',
  styleUrls: ['./two-four-two.component.scss']
})
export class TwoFourTwoComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
    
  }

}
