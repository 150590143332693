import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-humanities',
  templateUrl: './humanities.component.html',
  styleUrls: ['./humanities.component.scss']
})
export class HumanitiesComponent implements OnInit {
  routeName='';
   sub:Subscription;
  constructor(
    private pathNameService:PathNameService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.routeName=res;
    });
  }

  splitUrl(){
    let url=location.pathname.split('/');
    this.routeName=url[url.length-1];
    
  }

  changeRoute(route:string){
    this.routeName=route;
  }
}
