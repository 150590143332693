import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss']
})
export class CellComponent implements OnInit {
  src: string ='/assets/pdf/SC-ST Cell.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
