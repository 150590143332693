import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1214-twenty',
  templateUrl: './c1214-twenty.component.html',
  styleUrls: ['./c1214-twenty.component.scss']
})
export class C1214TwentyComponent implements OnInit {
  c3321Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result: { title: string, link: string }[] = [
    {
      title: 'Computer Networking Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Computer Networking Certificates.pdf'
    },
    {
      title: 'Computer Repair Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Computer Repair Certificates.pdf'
    },
    {
      title: 'Digital Marketing Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Digital Marketing Certificates.pdf'
    },
    {
      title: 'E-learning Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_E-learning Certificates.pdf'
    },
    {
      title: 'Financial Services Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Financial Services Certificates.pdf'
    },
    {
      title: 'Human Rights Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Human Rights Certificates.pdf'
    },

    {
      title: 'Hygiene and Fitness Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Hygiene and Fitness Certificates.pdf'
    },
    {
      title: 'Library Science Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Library Science Certificates.pdf'
    },
    {
      title: 'Medical lab. Technician Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Medical lab. Technician Certificates.pdf'
    },
    {
      title: 'Yoga Certificates',
      link: this.c3321Url + '1.2.1.4_2020-21_Yoga Certificates.pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
