import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c6-six-five-one',
  templateUrl: './c6-six-five-one.component.html',
  styleUrls: ['./c6-six-five-one.component.scss']
})
export class C6SixFiveOneComponent implements OnInit {
  pdfSrc6='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_four filled in feed back forms from different stakholders.pdf';
  pdfSrc5='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_Analysis and ATR _5 years/6.5.1_Analysis and ATR 2021-22.pdf';
  pdfSrc4='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_Analysis and ATR _5 years/6.5.1_Analysis and ATR 2020-21.pdf';
  pdfSrc3='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_Analysis and ATR _5 years/6.5.1_Analysis and ATR 2019-20.pdf';
  pdfSrc2='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_Analysis and ATR _5 years/6.5.1_Analysis and ATR 2018-19.pdf';
  pdfSrc1='/assets/pdf/NAAC/Criterion-6/6.5.1_Feedback data/6.5.1_Analysis and ATR _5 years/6.5.1_Analysis and ATR 2017-18.pdf';
  constructor() { }

  isLoading = false;
  ngOnInit(): void {
  }

  setPage(id){
    if(id==='1'){
      $(`#pdf1`).addClass('show');
      $(`#pdf2`).removeClass('show');
      $(`#pdf3`).removeClass('show');
      $(`#pdf4`).removeClass('show');
      $(`#pdf5`).removeClass('show');
      $(`#pdf6`).removeClass('show');
    }
    if(id==='2'){
      $(`#pdf2`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $(`#pdf3`).removeClass('show');
      $(`#pdf4`).removeClass('show');
      $(`#pdf5`).removeClass('show');
      $(`#pdf6`).removeClass('show');
    }
    if(id==='3'){
      $(`#pdf3`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $(`#pdf2`).removeClass('show');
      $(`#pdf4`).removeClass('show');
      $(`#pdf5`).removeClass('show');
      $(`#pdf6`).removeClass('show');
    }
    if(id==='4'){
      $(`#pdf4`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $(`#pdf2`).removeClass('show');
      $(`#pdf3`).removeClass('show');
      $(`#pdf5`).removeClass('show');
      $(`#pdf6`).removeClass('show');
    }
    if(id==='5'){
      $(`#pdf5`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $(`#pdf2`).removeClass('show');
      $(`#pdf3`).removeClass('show');
      $(`#pdf4`).removeClass('show');
      $(`#pdf6`).removeClass('show');
    }
    if(id==='6'){
      $(`#pdf6`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $(`#pdf2`).removeClass('show');
      $(`#pdf3`).removeClass('show');
      $(`#pdf4`).removeClass('show');
      $(`#pdf5`).removeClass('show');
    }
    
  }
}
