import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  pdfSrc:string[]=[
    '/assets/pdf/Curriculam-feedback-23.pdf',
  ];
  src:string;

  isLoading=true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('2023');
  }

  changeRoute(year:string){
    this.isLoading=true;
    if(year=='2023'){
      $('.first').addClass('active');
      this.src=this.pdfSrc[0];
    }

  }
  hideLoader(){
    this.isLoading=false;
  }
}
