<div id="girl">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-lg">
          <h4 class="card-title text-center">Girl-Common-Room</h4>
          <div class="card-body text-justify">
            <p class="lead text-justify">
              The girls’ common room has seating capacity of 50 girl students with a carpet area of about 900 sq. ft.
              with attached bathroom and dressing room. Comfortable furniture is provided to the students to relax
              during the free periods.
              The common room has water cooler and LED TV to watch current events. New papers, magazines & indoor games
              are available on demand.
              Canteen service is available to the students from the main college canteen on telephonic request for which
              an intercom has been provided.
              Girls common room attendant is always ready to help.</p>
            <div class="row text-center">
              <div class="col-sm-12 col-md-12 mt-3">
                <img src="/assets/img/girls/4.JPG" alt="girls" class="img-fluid rounded">
              </div>

            </div>
            
            <!-- <div class="row">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/girls/1.jpeg" alt="girls" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/girls/2.jpeg" alt="girls" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/girls/3.jpeg" alt="girls" class="img-fluid rounded">
                            </div>
                            
                           
                        </div> -->
          </div>
        </div>

      </div>
    </div>


  </div>
</div>