<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr/criteria2/2-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>2.1.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-1/2-1-2/2-1-2-1">2.1.2.1</a>
                    </li>
                    <!-- <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-1/2-1-2/2-1-2-2">2.1.2.2</a>
                    </li> -->
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <h4 class="text-center">Reserved Categories Data</h4>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>