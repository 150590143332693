<div id="youth">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Youth Red Cross</h4>
                    <div class="card-body">
                        <p class="lead text-justify">Youth Red Cross Club is constituted in all the colleges for motivating the students towards social causes like Blood Donation, Vaccination Drives, Public Health Awareness, Services for the disabled, Services for the Economically Backward, Hospital Welfare Services, Maternity & Child Welfare, Family Welfare Services, etc. 
                        </p>
                        <p class="lead text-justify">College has two units of Youth Red Cross volunteers under the guidance of Youth Red Cross counselors <strong class="text-dark"> Prof. Bal Krishan.</strong> The college has special status in the field of blood donation in the State. This is evident from recognition by the State Govt in the form of Governor’s award to the college for six times. 
                        </p>
                        <p class="lead text-justify">The motivation in the students is very high and the number of donations by the students of the college is increasing year by year.
                             National and State level camps of Youth Red Cross are held every year.</p>
                        <p class="lead text-justify">The students represent college in state level Youth Red Cross Camps being organized every year by Red Cross Society, Govt of Haryana.
                        </p>
                         <div class="row">
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/1.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/2.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/3.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/4.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/5.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/6.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/youth/7.jpg" alt="youth" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>