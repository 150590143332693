import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NOTICE } from 'src/app/models/notice.model';
import { NoticeService } from 'src/app/services/notice/notice.service';
import { ShowNoticeService } from 'src/app/services/showNotice/show-notice.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {
  notices:NOTICE[]=[];
  showNotice=true;
  sub:Subscription[]=[];
  constructor(private noticeService:NoticeService,
    private showNoticeService:ShowNoticeService,
    private router:Router) { }

  ngOnInit(): void {
    this.noticeService.fetchNotice();
    
    this.sub[0] = this.noticeService.noticeChange.subscribe(notice=>{
      this.notices=notice;
    
    });
    this.sub[1]= this.showNoticeService.noticeChange.subscribe(value=>{
      this.showNotice = value;
    });
  }
  hideNotice(){
    this.showNoticeService.hide();
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.hideNotice();
      this.router.navigate(['/menu-detail/notice/'+url[3]]);

    }
    
  }
  ngOnDestroy(): void {
      this.sub.forEach(s=>{
        s.unsubscribe();
      })
  }
}
