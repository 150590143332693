<div id="cell">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg my-3 px-md-4">
                <h4 class="card-title text-center ">SC/ST Cell</h4>
                <div class="card-body">

                    <div id="sports">
                        <div class="container-fluid pdf">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card shadow-lg">
                                        <div id="detail" class="text-center">
                                            <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                                                id="list-view"></pdf-viewer>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>