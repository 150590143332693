import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-raging',
  templateUrl: './raging.component.html',
  styleUrls: ['./raging.component.scss']
})
export class RagingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
