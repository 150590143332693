<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria6"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>6.3.3</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">6.3.3.1 Faculty Development Program Data Template</a>
                    </li>
                    <li class="two">
                        <a (click)="changeRoute('2')">6.3.3.2 Number of teachers (FDP)</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">6.3.3.4 FDP relevant documents</a>
                    </li>
                    <li class="four">
                        <a (click)="changeRoute('4')">6.3.3.4 Non teaching staff (FDP)</a>
                    </li>
                    <li class="five">
                        <a (click)="changeRoute('5')">6.3.3.5 Year wise list</a>
                    </li>
                    <li class="six">
                        <a (click)="changeRoute('6')">6.3.3.6 Annual Reports</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9" *ngIf="showTable">
            <div id="two-four-two">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <h4 class="card-title text-center">FDP relevant documents</h4>
                                <div class="card-body text-justify">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                          <tr>
                                            <!-- <th scope="col">S.NO.</th> -->
                                            <th scope="col">TITLE</th>
                                            <th scope="col">URL</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let item of result; let i = index;">
                                            <!-- <th scope="row">{{i+41}}</th> -->
                                            <td class="title">{{item.title}}</td>
                                            <td class="link"><a href="{{item.link}}" target="_blank"><i class="fas fa-eye"></i></a> </td>
                                          </tr>
                                         
                                        </tbody>
                                      </table>
                                </div>
                            </div>
              
                        </div>
                    </div>
              
                   
                </div>
              </div>
        </div>
        <div class="col-md-9" *ngIf="showPdf">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading1">Faculty Development Program Data Template</h5>
                            <h5 class="card-title text-center" *ngIf="heading2">Number of teachers (FDP)</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">Non teaching staff (FDP)</h5>
                            <h5 class="card-title text-center" *ngIf="heading4">Year wise list</h5>
                            <h5 class="card-title text-center" *ngIf="heading5">Annual Reports</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                                    id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>