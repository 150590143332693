<div id="result">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <div class="card shadow-lg">
                  <h4 class="card-title text-center">Results</h4>
                  <div class="card-body text-justify">
                      <table class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th scope="col">S.NO.</th>
                              <th scope="col">TITLE</th>
                              <th scope="col">URL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of result; let i = index;">
                              <th scope="row">{{i+1}}</th>
                              <td class="title">{{item.title}}</td>
                              <td class="link"><a href="{{item.link}}" target="_blank"><i class="fas fa-eye"></i></a> </td>
                            </tr>
                           
                          </tbody>
                        </table>
                  </div>
              </div>

          </div>
      </div>

     
  </div>
</div>