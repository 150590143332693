import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';

@Component({
  selector: 'app-etender',
  templateUrl: './etender.component.html',
  styleUrls: ['./etender.component.scss']
})
export class EtenderComponent implements OnInit {
  result:{name:string ,title:string, link:string} [] = [];
  constructor(private ssrService : SsrService) { }

  ngOnInit(): void {
    this.result = this.ssrService.getEtender();
  }

}
