<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria1/1-2/1-2-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>1.2.1.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('1')">2021-22</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('2')">2020-21</a>
                    </li>
                    <li class="third">
                        <a (click)="changeRoute('3')">2019-20</a>
                    </li>
                    <li class="fourth">
                        <a (click)="changeRoute('4')">2018-19</a>
                    </li>
                    <li class="fifth">
                        <a (click)="changeRoute('5')">2017-18</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Addon Courses Attendance</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>