import { Component, OnInit } from '@angular/core';
import 'owl.carousel';
import { NewsService } from 'src/app/services/news/news.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $:any;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  //news:{name:string,imgUrl:string}[]=[];
  newsString=` <div class="owl-carousel documentation">`;
  news:any[]=[];
  isLoading=false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  constructor(private newService:NewsService) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.newService.getNewsList();
    this.newService.newsChange.subscribe(value=>{
      this.news=value;
      this.isLoading=false;
    })
    // this.newService.newsList.snapshotChanges().subscribe(
    //   list=>{
    //   this.news=  list.map(item=>{ return item.payload.val();})
      
    //   this.isLoading=false;
    //   // this.setCarousel(this.news.reverse());
    //   }
    // )
    //this.setCarousel();
  }


  setCarousel(news:any[]){
    for(let event of news){
      this.newsString += `<div class="item">
     <div class="card shadow-lg mx-auto" >
     <a href="${event.imgUrl}" data-lightbox="news">
      <img appLazyload class="card-img-top h-100" src="${event.imgUrl}" alt="Card image cap">
      </a>
      <div class="card-body">
        <a href=${event.subCategory} target="_blank">${event.caption}  <i class="fas fa-arrow-right"></i></a>
        <!-- <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, totam perspiciatis inventore laborum cupiditate ratione nam atque repellendus ipsam soluta, explicabo hic eos ea numquam temporibus enim eligendi in ex!</p> -->
        <!-- <a  class="btn btn-warning w-100">Know More</a> -->
      </div>
      
    </div>
    
      </div>`;
    }
  //  string = string.replace('undefined','');
  this.newsString+=`</div>`;
   $('.newsCarousel').append(this.newsString);
   this.isLoading=false;
  }

  ngAfterViewInit() {
    // setTimeout(function () {
    //   $('.documentation').owlCarousel({
    //     loop: true,
    //     margin: 10,
    //      nav: true,
    //      autoplay:true,
    //     autoplayTimeout:5000,
    //     autoplayHoverPause:true,
    //     responsive: {
    //       0: {
    //         items: 1
    //       },
    //       600: {
    //         items: 2
    //       },
    //       1000: {
    //         items: 3
    //       }
    //     }

    //   });
    // }, 300);
    // this.isLoading=false;
}


}
