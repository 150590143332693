<div id="ragging">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg my-3 px-md-4">
                <h4 class="card-title text-center ">Anti Ragging Cell</h4>
                <div class="card-body">
                    <iframe src="https://drive.google.com/file/d/1Bt45A7aDsObrYWcg8f_6m9rvUYxphwTm/preview" width="100%" height="640" allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>