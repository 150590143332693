// import { Component, OnInit } from '@angular/core';
// import { CourseService } from 'src/app/services/course/course.service';
// declare var $:any;
// @Component({
//   selector: 'app-post-graduate',
//   templateUrl: './post-graduate.component.html',
//   styleUrls: ['./post-graduate.component.scss']
// })
// export class PostGraduateComponent implements OnInit {
//   coursesCreteria:{name:string,sem1:string,sem2:string,title:string,creteria1:string,creteria2:string}[];

//   courseBsc:{name:string,sem1:string,sub:string[],sem2:string[],title:string,creteria1:string,creteria2:string,comp:string[]}[];
//   BSCPO:string[]=[];
//   MSCPO:string[]=[];
//   constructor(private courseService:CourseService) { }

//   ngOnInit(): void {
//       this.coursesCreteria=this.courseService.getCourseCreteria();
//       this.courseBsc=this.courseService.getCourseBsc();
//       this.BSCPO=this.courseService.getBSCpo();
//       this.MSCPO=this.courseService.getMSCpo();
//   }

//   setModal(i:number){
//     $('.sub').hide();
//     $('.sem2b').hide();
//     $('.comp-detail').hide();
//     let modal=document.getElementById('courseModelTitle');
//     modal.innerHTML=this.coursesCreteria[i].name;
//     let title=document.querySelector('.title');
//     title.innerHTML=this.coursesCreteria[i].title;
//     let cretera1=document.querySelector('.creteria1');
//     cretera1.innerHTML=this.coursesCreteria[i].creteria1;
//     let cretera2=document.querySelector('.creteria2');
//     cretera2.innerHTML=this.coursesCreteria[i].creteria2;
//     let sem1=document.querySelector('.sem1');
//     sem1.innerHTML=this.coursesCreteria[i].sem1;
//     let sem2=document.querySelector('.sem2');
//     sem2.innerHTML=this.coursesCreteria[i].sem2;
//     let po=document.querySelector('.po-pso');
//     po.innerHTML=this.MSCPO[i];
//   }

//   setBModal(i:number){
//     $('.sub').show();
//     $('.sem2b').show();
//     $('.comp-detail').show();
//     $('.po-pso').show();
//     if(i==7){
//       $('.sub').hide();
//     }
//     let modal=document.getElementById('courseModelTitle');
//     modal.innerHTML=this.courseBsc[i].name;
//     let title=document.querySelector('.title');
//     title.innerHTML=this.courseBsc[i].title;
//     let cretera1=document.querySelector('.creteria1');
//     cretera1.innerHTML=this.courseBsc[i].creteria1;
//     let subject=document.querySelector('.sub');
//     let subject1=document.querySelector('.sub1');
//     let subject2=document.querySelector('.sub2');
//     let subject3=document.querySelector('.sub3');
//     subject1.innerHTML=this.courseBsc[i].sub[0];
//     subject2.innerHTML=this.courseBsc[i].sub[1];
//     subject3.innerHTML=this.courseBsc[i].sub[2];
//     let cretera2=document.querySelector('.creteria2');
//     cretera2.innerHTML=this.courseBsc[i].creteria2;
//     let sem1=document.querySelector('.sem1');
//     sem1.innerHTML=this.courseBsc[i].sem1;
//     let sem2=document.querySelector('.sem2');
//     sem2.innerHTML='';
//     let sem2b=document.querySelector('.sem2b');
//     let sem2b1=document.querySelector('.sem2b1');
//     sem2b1.innerHTML=this.courseBsc[i].sem2[0];
//     let sem2b2=document.querySelector('.sem2b2');
//     sem2b2.innerHTML=this.courseBsc[i].sem2[1];
//     let sem2b3=document.querySelector('.sem2b3');
//     sem2b3.innerHTML=this.courseBsc[i].sem2[2];
    
//     let comp=document.querySelector('.comp-heading');
//     comp.innerHTML=this.courseBsc[i].comp[0];
//     let compDetail=document.querySelector('.comp-detail');
//     let detail1=document.querySelector('.comp-detail1');
//     detail1.innerHTML=this.courseBsc[i].comp[1];
//     let detail2=document.querySelector('.comp-detail2');
//     detail2.innerHTML=this.courseBsc[i].comp[2];
//     let detail3=document.querySelector('.comp-detail3');
//     detail3.innerHTML=this.courseBsc[i].comp[3];
//     let po=document.querySelector('.po-pso');
//     po.innerHTML=this.BSCPO[i];
//   }

// }
import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $: any;

@Component({
  selector: 'app-post-graduate',
  templateUrl: './post-graduate.component.html',
  styleUrls: ['./post-graduate.component.scss']
})
export class PostGraduateComponent implements OnInit {
  coursesCreteria: { name: string, sem1: string, sem2: string, title: string, creteria1: string, creteria2: string }[];
  courseBsc: { name: string, sem1: string, sub: string[], sem2: string[], title: string, creteria1: string, creteria2: string, comp: string[] }[];
  BSCPO: string[] = [];
  MSCPO: string[] = [];

  modalTitle: string;
  title: string;
  creteria1: string;
  creteria2: string;
  sem1: string;
  sem2: string;
  sub: string[];
  sem2b: string[];
  compHeading: string;
  compDetail: string[];
  poPso: string;

  constructor(private courseService: CourseService) { }

  ngOnInit(): void {
    this.coursesCreteria = this.courseService.getCourseCreteria();
    this.courseBsc = this.courseService.getCourseBsc();
    this.BSCPO = this.courseService.getBSCpo();
    this.MSCPO = this.courseService.getMSCpo();
  }

  setModal(i: number) {
    this.resetModalFields();
    const course = this.coursesCreteria[i];
    this.modalTitle = course.name;
    this.title = course.title || null;
    this.creteria1 = course.creteria1 || null;
    this.creteria2 = course.creteria2 || null;
    this.sem1 = course.sem1 || null;
    this.sem2 = course.sem2 || null;
    this.poPso = this.MSCPO[i] || null;
  }

  setBModal(i: number) {
    this.resetModalFields();
    const course = this.courseBsc[i];
    this.modalTitle = course.name;
    this.title = course.title || null;
    this.creteria1 = course.creteria1 || null;
    this.creteria2 = course.creteria2 || null;
    this.sem1 = course.sem1 || null;
    this.sem2 = '';
    this.sub = course.sub.length > 0 ? course.sub : null;
    this.sem2b = course.sem2.length > 0 ? course.sem2 : null;
    this.compHeading = course.comp.length > 0 ? course.comp[0] : null;
    this.compDetail = course.comp.length > 1 ? course.comp.slice(1) : null;
    this.poPso = this.BSCPO[i] || null;
  }

  resetModalFields() {
    this.modalTitle = null;
    this.title = null;
    this.creteria1 = null;
    this.creteria2 = null;
    this.sem1 = null;
    this.sem2 = null;
    this.sub = null;
    this.sem2b = null;
    this.compHeading = null;
    this.compDetail = null;
    this.poPso = null;
  }
}
