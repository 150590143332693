import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iiqa',
  templateUrl: './iiqa.component.html',
  styleUrls: ['./iiqa.component.scss']
})
export class IiqaComponent implements OnInit {
  iiqa:{title:string,url:string}[]=[
    {
      title:'Report',
      url:'https://drive.google.com/file/d/1JgCaL-so0_i-yhAokVf1nqxFR2kKpDHp/view?usp=sharing'
    },
    {
      title:'Declaration By College',
      url:'https://drive.google.com/file/d/1NwZ4yFTn5NdMQEeRaqomYYY10EPK8weO/view?usp=sharing'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }
}
