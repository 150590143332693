import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-six-three',
  templateUrl: './two-six-three.component.html',
  styleUrls: ['./two-six-three.component.scss']
})
export class TwoSixThreeComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 2/2.6.3.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }

}
