import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-two-four-two',
  templateUrl: './ct-two-four-two.component.html',
  styleUrls: ['./ct-two-four-two.component.scss']
})
export class CtTwoFourTwoComponent implements OnInit {
  c242Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {  
      title:'2.4.2_(1) Data template',
      link:this.c242Url + '2.4.2_(1) Data template.pdf'
    },
    {  
      title:'2.4.2_(2) Teachers with Ph.D. degrees and NET',
      link:this.c242Url + '2.4.2_(2) Teachers with Ph.D. degrees and NET.pdf'
    },
    {
      title:'2.4.2_Proofs of Ph.D and NET',
      link:this.c242Url + '2.4.2_Proofs-of-Ph.D-and-NET.pdf'
    },
    {
      title:'2.4.2 (4) English Translation Ph.D',
      link:this.c242Url + '2.4.2 (4) English Translation Ph.D.pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
