<div id="gurudwara">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Gurudwara-Sahib</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The college has built up a “Gurudwara” in the campus premises where students and faculty members pay their obeisance irrespective of their faiths. The monthly “Sankrant” programme is held in the Gurudwara Sahib.
                             The annual Founder’s Day is celebrated by the students, staff and management with fervour and “Ragi Jatthas” from different states of northern India vist and recite the “Gurbani” and “Shabads”.
                              The students, staff and the sangat get an opportunity to get connected with divinity.
                             It impacts religious, moral, ethical and social fabrics.</p>
                             <div id="gurudwaraCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#gurudwaraCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#gurudwaraCarousel" data-slide-to="1"></li>
                                  <li data-target="#gurudwaraCarousel" data-slide-to="2"></li>
                                 
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/gurudwara/4.JPG" alt="gurudwara" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/gurudwara/5.JPG" alt="gurudwara" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item ">
                                    <img src="/assets/img/gurudwara/6.JPG" alt="gurudwara" class="img-fluid rounded">
                                  </div>
                                 
                                </div>
                                <a class="carousel-control-prev" href="#gurudwaraCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#gurudwaraCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gurudwara/1.jpeg" alt="gurudwara" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gurudwara/2.jpeg" alt="gurudwara" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/gurudwara/3.jpeg" alt="gurudwara" class="img-fluid rounded">
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>