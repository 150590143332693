import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-seven-one',
  templateUrl: './two-seven-one.component.html',
  styleUrls: ['./two-seven-one.component.scss']
})
export class TwoSevenOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
