import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-vismad',
  templateUrl: './vismad.component.html',
  styleUrls: ['./vismad.component.scss']
})

export class VismadComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/VISMAD2022-23.pdf',
    '/assets/pdf/VISMAD2020-21.pdf',
  ];
  src: string;
  isLoading = true;
  showPdf:boolean;
  ngOnInit(): void {
    this.changeRoute('2022-23');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    
    if (year == '2022-23') {
      $('.second').removeClass('active');
      $('.first').addClass('active');
      this.src = this.pdfSrc[0]
      this.showPdf=true;
    }
    if (year == '2020-21') {
      $('.first').removeClass('active');
      $('.second').addClass('active');
      this.src = this.pdfSrc[1]
      this.showPdf=false;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
  
}