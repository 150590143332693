import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c-one-two-one-two',
  templateUrl: './c-one-two-one-two.component.html',
  styleUrls: ['./c-one-two-one-two.component.scss']
})
export class COneTwoOneTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1.2_Sample of Course Completion Certificates.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1_2021-22_Evidence of course completion.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1_2020-21_Evidence of course completion.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1_2019-20_Evidence of course completion.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1_2018-19_Evidence of course completion.pdf',
    '/assets/pdf/NAAC/Criterion 1/1.2.1.2_Evidence of course completion_5 Years/1.2.1_2017-18_Evidence of course completion.pdf' ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('sample');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == 'sample') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2021') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '2020') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '2019') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '2018') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '2017') {
      $('.sixth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    

  }
  hideLoader() {
    this.isLoading = false;
  }
}
