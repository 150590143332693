<div id="edusat">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">EDUSAT</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The college has a unique distinction of being the only non-government aided college having Edusat System for Satellite based education.
                             This system is a part of educational network established by Directorate of Higher Education, Government of Haryana.
                              It has a dual channel two way Audio-Video communication channel using satellite and DTH technology.
                               The students of the college attend the virtual classroom lectures through Edusat according to the programme schedule available for each subject during the session.
                             Ever since its inception, the system has developed craze amongst the students who are extremely benefitted with the use of advanced teaching-learning technology.</p>
                       
                         <div class="row text-center">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/edusat/1.jpeg" alt="edusat" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>