<div id="hindi">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Department of Hindi</h4>
                    <div class="card-body text-justify">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12 col-lg-6 mt-5" *ngFor="let staff of hindi;let i=index">
                                    <div class="our-team">
                                        <div class="picture">
                                          <img class="img-fluid" [src]="staff.img" [alt]="staff.name">
                                        </div>
                                        <div class="team-content">
                                          <h3 class="name">{{staff.name}}</h3>
                                          <h4 class="title">{{staff.degree}}</h4>
                                        </div>
                                        <h5>{{staff.position}}</h5>
                                        <ul class="social">
                                          <li><a href="#"  >{{staff.position}}</a></li>
                                          
                                        </ul>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>