import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c33-twentyone',
  templateUrl: './c33-twentyone.component.html',
  styleUrls: ['./c33-twentyone.component.scss']
})
export class C33TwentyoneComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.2.1 Books/';

  result:{title:string,link:string}[]=[
    {
        title: 'Dr. Sanjay Arora, Dr. Rajinder Singh Ahluwalia',
        link: this.c3321Url + '14_2021_Dr.-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr. Rajinder Singh Ahluwalia',
        link: this.c3321Url + '15_2021_Dr.-Rajinder-Singh-Ahluwalia.pdf'
    },
    {
        title: 'Dr. Ashok khurana',
        link: this.c3321Url + '16_2021_Dr.-Ashok-khurana.pdf'
    },
    {
        title: 'Dr. Ashok khurana',
        link: this.c3321Url + '17_2021_Dr.-Ashok-khurana.pdf'
    },
    {
        title: 'Dr. Gurvinder Kaur',
        link: this.c3321Url + '18_2021_Dr.-Gurvinder-Kaur.pdf'
    },
    {
        title: 'Mrs. Santosh Kurra, Dr. M.P.Aggarwal, Dr. Indra Kapur',
        link: this.c3321Url + '19_2021_Mrs.-Santosh-Kurra.pdf'
    },
    {
        title: 'Dr. Anurag',
        link: this.c3321Url + '20_2021_Dr.-Anurag.pdf'
    },
    {
        title: 'Dr. Ashok Khurana',
        link: this.c3321Url + '21_2021_Dr.-Ashok-Khurana.pdf'
    },
    {
        title: 'Dr. Rajinder Singh Ahluwalia',
        link: this.c3321Url + '22_2021_Dr.-Rajinder-Singh-Ahluwalia.pdf'
    },
    {
        title: 'Dr. Parveen Kumar',
        link: this.c3321Url + '23_2021_Dr.-Parveen-Kumar.pdf'
    },
    {
        title: 'Dr. Rajinder Singh Ahluwalia',
        link: this.c3321Url + '24_2021_Dr.-Rajinder-Singh-Ahluwalia.pdf'
    },
    {
        title: 'Dr. Sanjay Arora',
        link: this.c3321Url + '25_2021_Dr.-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr. Harinder Singh Kang',
        link: this.c3321Url + '26_2021_Dr.-Harinder-Singh-Kang.pdf'
    },
    {
        title: 'Dr. Tilak Raj',
        link: this.c3321Url + '27_2021_Dr.-Tilak-Raj.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
