import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
  styleUrls: ['./english.component.scss']
})
export class EnglishComponent implements OnInit {

  english:Faculty[];
  constructor( private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.english=this.facultyService.getEnglish();
  }

}
