<div id="commerce">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Faculty</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item"><a routerLink="/faculty/teaching-staff">Faculty</a></li>
              <li class="breadcrumb-item"><a >Commerce</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Department of Commerce and Management</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>