import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-two',
  templateUrl: './two-two.component.html',
  styleUrls: ['./two-two.component.scss']
})
export class TwoTwoComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
