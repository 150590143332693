import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NAAC } from 'src/app/models/naac.model';
import { NaacService } from 'src/app/services/naac/naac.service';
import { ShowNaacService } from 'src/app/services/showNaac/show-naac.service';

@Component({
  selector: 'app-naac',
  templateUrl: './naac.component.html',
  styleUrls: ['./naac.component.scss']
})
export class NaacComponent implements OnInit,OnDestroy {
  naacs:NAAC[]=[];
  showNaac=true;
  sub:Subscription[]=[];
  constructor(private naacService:NaacService,
    private showNaacService:ShowNaacService,
    private router:Router) { }

  ngOnInit(): void {
    this.naacService.fetchNaac();
    
    this.sub[0] = this.naacService.naacChange.subscribe(naac=>{
      this.naacs=naac;
    
    });
    this.sub[1]= this.showNaacService.naacChange.subscribe(value=>{
      this.showNaac = value;
    });
  }
  hideNaac(){
    this.showNaacService.hide();
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.hideNaac();
      this.router.navigate(['/menu-detail/naac/'+url[3]]);

    }
    
  }
  ngOnDestroy(): void {
      this.sub.forEach(s=>{
        s.unsubscribe();
      })
  }
}
