import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-three-one',
  templateUrl: './c1-one-three-one.component.html',
  styleUrls: ['./c1-one-three-one.component.scss']
})
export class C1OneThreeOneComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 1/1.3.1_CROSS CUTTING ISSUES.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
