<div id="library">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Library</h4>
                    <div class="card-body">
                        <p class="lead text-justify font-weight-bold text-dark">The College has a spacious fully computerised, airconditioned library with following facilities:
                        </p>
                        <ul>
                            <li>
                                <p class="lead">Independent study sections for boys, girls, P.G. students and teachers</p>
                            </li>
                            <li>
                                <p class="lead">Research section for Postgraduate students and teachers</p>
                            </li>
                            <li>
                                <p class="lead">Fully computerised Issue and Return section</p>
                            </li>
                            <li>
                                <p class="lead">Wi-Fi Internet access for on-line research and learning</p>
                            </li>
                            <li>
                                <p class="lead">Photo copy facilities</p>
                            </li>
                            <li>
                                <p class="lead">Newspaper and Magazine Section</p>
                            </li>
                        </ul>
                        <h5 class="title">Library Has...</h5>
                        <hr class="pb-1 rounded mt-0">
                        <p class="lead text-justify"><strong>BOOKS: </strong> The library is a proud possessor of over  80,000 books written in various languages like  Hindi, English, Sanskrit and Punjabi.</p>
                        <p class="lead text-justify">These books are shelved under four broad sections namely.</p>
                        <ul>
                            <li>
                                <h5 class="title">Reference Section</h5>
                                <p class="lead text-justify">Reference section includes books that are meant for  consultation in the Library only. For example,  Encyclopedias, Dictionaries, Year Books, some special  volumes of miscellaneous subjects.
                                </p>
                            </li>
                            <li>
                                <h5 class="title">General Section</h5>
                                <p class="lead text-justify">General Section includes books on various subjects like  Gender study,  Performing arts – Drama, Dance, Music;  painting, architecture etc.
                                </p>
                            </li>
                            <li>
                                <h5 class="title">Stacks Room</h5>
                                <p class="lead text-justify">Stacks Room stock the maximum collection of the Library  under specific department headings like History,  Economics, Mathematics etc.
                                </p>
                            </li>
                            <li>
                                <h5 class="title">Competition section</h5>
                            </li>
                        </ul>
                        <div id="libraryCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#libraryCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#libraryCarousel" data-slide-to="1"></li>
                              <li data-target="#libraryCarousel" data-slide-to="2"></li>
                              <li data-target="#libraryCarousel" data-slide-to="3"></li>
                              <li data-target="#libraryCarousel" data-slide-to="4"></li>
                              <li data-target="#libraryCarousel" data-slide-to="5"></li>
                              <li data-target="#libraryCarousel" data-slide-to="6"></li>
                              <li data-target="#libraryCarousel" data-slide-to="7"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/library/1.jpeg" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/2.jpeg" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/library/3.jpeg" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/4.jpeg" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/5.jpeg" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/7.JPG" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/8.JPG" alt="Library" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/library/9.JPG" alt="Library" class="img-fluid rounded">
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#libraryCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#libraryCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <h5 class="title mt-4">Special features</h5>
                        <hr class="pb-1 rounded mt-0" style="width: 150px;">
                        <ul>
                            <li>
                                <h5 class="title">Book Bank</h5>
                                <p class="lead text-justify">It has textbooks on various subjects for needy students. These books are issued for full semester.
                                </p>
                            </li>
                            <li>
                                <h5 class="title">Competition Corner</h5>
                                <p class="lead text-justify">It has books of competition for various exams. For example exams of UPSC, CDS, SSC, UGC-NET, JEE, NEET, CTET, Banking Railway etc.
                                </p>
                            </li>
                        </ul>
                        <h5 class="title">Free educational websites…</h5>
                        <hr class="pb-1 rounded mt-0" style="width: 250px;">
                        <ul>
                            <li>
                                <p class="lead text-justify">The National Digital Library (NDL) of India  <a href="https://ndl.iitkgp.ac.in/" style="text-decoration: none;">https://ndl.iitkgp.ac.in/</a></p>
                               
                            </li>
                            <li>
                                <p class="lead text-justify">E-PG Pathshala <a href=" http://epgp.inflibnet.ac.in/" style="text-decoration: none;"> http://epgp.inflibnet.ac.in/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify"> Rare Book Society of India  <a href="https://www.rarebooksocietyofindia.org/" style="text-decoration: none;">https://www.rarebooksocietyofindia.org/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify">NISCAIR Online Periodicals Repository  <a href="  http://nopr.niscair.res.in/" style="text-decoration: none;">  http://nopr.niscair.res.in/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify">Swayam  <a href="https://swayam.gov.in/" style="text-decoration: none;"> https://swayam.gov.in/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify">Directory of Open Access Journals  <a href=" https://doaj.org/" style="text-decoration: none;"> https://doaj.org/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify"> Directory of Open Access Journals  <a href="https://v2.sherpa.ac.uk/opendoar" style="text-decoration: none;">https://v2.sherpa.ac.uk/opendoar</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify">Directory of Open Access Books  <a href="https://www.doabooks.org/" style="text-decoration: none;">https://www.doabooks.org/</a></p>
                            </li>
                            <li>
                                <p class="lead text-justify">Free eBooks - Project Gutenberg  <a href="http://www.gutenberg.org/" style="text-decoration: none;">http://www.gutenberg.org/</a></p>
                            </li>
                        </ul>
                     
                         
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>