<div id="menu-detail">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1 style="text-transform: capitalize;">{{menuName }}</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{menuName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid  px-0">
        <router-outlet></router-outlet>
    </div>
</div>