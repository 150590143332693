import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CareerService } from 'src/app/services/career/career.service';
import { ShowCareerService } from 'src/app/services/showCareer/show-career.service';
import { Subscription } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-career-item',
  templateUrl: './career-item.component.html',
  styleUrls: ['./career-item.component.scss']
})
export class CareerItemComponent implements OnInit {
  id:string;
  sub:Subscription;
  urlPath='';
  constructor(private showCareerService:ShowCareerService,
    private route:ActivatedRoute,
    private careerService:CareerService,
    private router:Router) { 
      showCareerService.hide();
  }

  ngOnInit(): void {
    this.sub=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        const path=this.careerService.searchCareer(this.id);
        if(path){
          if(path.includes('view?usp=sharing')){
            this.urlPath=path.replace('view?usp=sharing','preview');
            $('#careerItem').attr('src', this.urlPath);
            this.urlPath=path;
          }else{
            this.urlPath=path;
            $('#careerItem').attr('src', path);
          
          }
        }else{
          this.router.navigate(['not-found']);
        }
        
        
      }
    )
  }

  ngOnDestroy(): void {
      this.showCareerService.show();
  }
}
