import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommerceComponent } from 'src/app/view/faculty/commerce/commerce.component';
import { ManagementComponent } from 'src/app/view/faculty/commerce/management/management.component';
import { FacultyComponent } from 'src/app/view/faculty/faculty.component';
import { AdministrationComponent } from 'src/app/view/faculty/humanities/administration/administration.component';
import { CommunicationComponent } from 'src/app/view/faculty/humanities/communication/communication.component';
import { EconomicsComponent } from 'src/app/view/faculty/humanities/economics/economics.component';
import { EnglishComponent } from 'src/app/view/faculty/humanities/english/english.component';
import { GeographyComponent } from 'src/app/view/faculty/humanities/geography/geography.component';
import { HindiComponent } from 'src/app/view/faculty/humanities/hindi/hindi.component';
import { HistoryComponent } from 'src/app/view/faculty/humanities/history/history.component';
import { HumanitiesComponent } from 'src/app/view/faculty/humanities/humanities.component';
import { PoliticalScienceComponent } from 'src/app/view/faculty/humanities/political-science/political-science.component';
import { PunjabiComponent } from 'src/app/view/faculty/humanities/punjabi/punjabi.component';
import { SanskritComponent } from 'src/app/view/faculty/humanities/sanskrit/sanskrit.component';
import { SocialComponent } from 'src/app/view/faculty/humanities/social/social.component';
import { BiotechnologyComponent } from 'src/app/view/faculty/science/biotechnology/biotechnology.component';
import { BotonyComponent } from 'src/app/view/faculty/science/botony/botony.component';
import { ChemistryComponent } from 'src/app/view/faculty/science/chemistry/chemistry.component';
import { ComputerScienceComponent } from 'src/app/view/faculty/science/computer-science/computer-science.component';
import { IndustrialChemistryComponent } from 'src/app/view/faculty/science/industrial-chemistry/industrial-chemistry.component';
import { MathematicsComponent } from 'src/app/view/faculty/science/mathematics/mathematics.component';
import { MicrobilogyComponent } from 'src/app/view/faculty/science/microbilogy/microbilogy.component';
import { PhysicsComponent } from 'src/app/view/faculty/science/physics/physics.component';
import { ScienceComponent } from 'src/app/view/faculty/science/science.component';
import { ZoologyComponent } from 'src/app/view/faculty/science/zoology/zoology.component';
import { HealthComponent } from 'src/app/view/faculty/sports-science/health/health.component';
import { SportsScienceComponent } from 'src/app/view/faculty/sports-science/sports-science.component';
import { RouterModule } from '@angular/router';
import { FacultyRoutingModule } from './faculty-routing.module';
import { BedComponent } from 'src/app/view/faculty/humanities/bed/bed.component';
import { MusicComponent } from 'src/app/view/faculty/humanities/music/music.component';
import { CommunityComponent } from 'src/app/view/faculty/community/community.component';
import { TeachingStaffComponent } from 'src/app/view/faculty/teaching-staff/teaching-staff.component';
import { EducationComponent } from 'src/app/view/faculty/education/education.component';
import { EduBedComponent } from 'src/app/view/faculty/education/edu-bed/edu-bed.component';
import { EduBpedComponent } from 'src/app/view/faculty/education/edu-bped/edu-bped.component';
import { PublicAdministrationComponent } from 'src/app/view/faculty/humanities/public-administration/public-administration.component';



@NgModule({
  declarations: [
    FacultyComponent,
    HumanitiesComponent,
    EconomicsComponent,
    EnglishComponent,
    SanskritComponent,
    GeographyComponent,
    HindiComponent,
    HistoryComponent,
    PoliticalScienceComponent,
    AdministrationComponent,
    PunjabiComponent,
    SocialComponent,
    CommunicationComponent,
    ScienceComponent,
    BotonyComponent,
    MathematicsComponent,
    PhysicsComponent,
    ComputerScienceComponent,
    ZoologyComponent,
    ChemistryComponent,
    IndustrialChemistryComponent,
    MicrobilogyComponent,
    BiotechnologyComponent,
    CommerceComponent,
    ManagementComponent,
    SportsScienceComponent,
    HealthComponent,
    BedComponent,
    MusicComponent,
    CommunityComponent,
    TeachingStaffComponent,
    EducationComponent,
    EduBedComponent,
    EduBpedComponent,
    PublicAdministrationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FacultyRoutingModule
  ]
})
export class FacultyModule { }
