<div id="landing">
    <div id="top">
        <div class="container-fluid">
            <!-- <nav class="navbar ml-lg-3" >
                <a href="/" class="navbar-brand"><img src="/assets/img/logo/3.png" alt="GNKC LOGO" class="img-fluid logo">&nbsp;GURU NANAK KHALSA COLLEGE</a>
            </nav> -->
            <iframe class=" ml-lg-5 my-5" frameborder="0" id="form" style="height:500px;max-width:450px;width:100%;min-width:350px;border:none;" src='https://data.odpay.in/odforms/form/GNKCQueryForm/formperma/QVFgAcgBZx-_iWQ81VCaKBbgaVuk5l8HodCLgK5de_4'></iframe>
        </div>
    </div>

    <div id="about">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="about-detail mx-auto ">
                        <h4 class="text-center" data-scroll>ABOUT School of Employability (SOE)</h4>
                        <p class="lead" data-scroll>School of Employability is one of the premier Corporate Training Institute of Yamuna Nagar and adjoining areas.
                             SOE was incepted in the year 2020 with a target to provide employment opportunities to every youth of Haryana and with the sole objective of Empowering Youth for Employment.
                              For the purpose of meeting the aspirations of the youth, we offer industry requirements-based program through our Skill Training Centre.
                             The training fee is supported by our industry partners under their CSR mandates for the employment skill development.
                        </p>
                        <br>
                        <p class="lead" data-scroll>
                            <strong>School of Employment (SOE)</strong> which was established in December, 2020 with the Vision and Mission mentioned herein
                        </p>
                    </div>
                   
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-2">
                    <div class="card about-title text-center pt-5">
                        <h4>ABOUT <br> School of <br> Employability (SOE)</h4>
                    </div>
                </div>
                <div class="col-10">
                    <div class="card about-detail mr-lg-5">
                        <p class="lead">
                            School of Employability is one of the premier Corporate Training Institute of Yamuna Nagar and adjoining areas.
                             SOE was incepted in the year 2020 with a target to provide employment opportunities to every youth of Haryana and with the sole objective of Empowering Youth for Employment.
                              For the purpose of meeting the aspirations of the youth, we offer industry requirements-based program through our Skill Training Centre.
                             The training fee is supported by our industry partners under their CSR mandates for the employment skill development. 
                        </p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div id="vission" class="my-4">
        <div class="container-fluid">
            <div class="card mx-auto">
                <div class="card-body mt-lg-3">
                    <div class="row text-center">
                        <div class="col-12 col-md-6 vision-detail" data-scroll>
                            <h4>Vision</h4>
                            <p class="lead">Serving the Society by creating gainful employment opportunities for the youth through high quality training and state-of-art delivery system.</p>
                        </div>
                        <div class="col-12 col-md-6" data-scroll>
                            <h4>Mission</h4>
                            <p class="lead">Aim to train over 1000 youth in a year to make them employable.</p>
                            <p class="lead">Provide hands on experience with the industry to create “job ready” professionals and to bridge the gap of quality human resources through internship.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="jamna">
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-md-6 borderRight">
                    <h4 data-scroll> Glimpses of Inauguration Ceremony <br> of Jamna Skill Training Centre, <br> School of Employability</h4>
                    <p class="lead" data-scroll>In this endeavor, SOE is supported by the Jamna Auto Industries Ltd. (JAI) by way of trainees’ fee funding and equipment facilitation on continuing basis under its CSR mandates for the employment skills development and NABARD (National Bank for Agriculture and Rural Development)</p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 col-md-6 mx-auto mb-4">
                            <img src="/assets/img/landing/jamna/1.jpg" alt="Jamna Skill Training Centre" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 mx-auto mb-4">
                            <img src="/assets/img/landing/jamna/2.jpg" alt="Jamna Skill Training Centre" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 mx-auto mb-4">
                            <img src="/assets/img/landing/jamna/3.jpg" alt="Jamna Skill Training Centre" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 mx-auto mb-4">
                            <img src="/assets/img/landing/jamna/4.jpg" alt="Jamna Skill Training Centre" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="scope" class="my-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="btn-group">
                        <button type="button" class="btn"
                        id="work-btn" 
                        data-toggle="collapse" 
                        data-target="#working" 
                        aria-expanded="true" 
                        aria-controls="Scope of Work"
                        (click)="showCard(10)">Scope of Work</button>
                        <button type="button" class="btn"
                        id="selection-btn" 
                        data-toggle="collapse" 
                        data-target="#selection" 
                        aria-expanded="true" 
                        aria-controls="Selection and Training Approach"
                        (click)="showCard(11)">Selection and Training Approach</button>
                    </div>
                    <div class="detail py-5 px-4">
                        <div class="collapse show multi-collapse" id="working">
                            <h4>Scope of Work (School of Employment)</h4>
                            <p class="lead">SOE offers industry oriented short-term courses in different regions of the State of Haryana to enhance the employability of the youth.</p>
                            <p class="lead">We engage with the employers across sectors for their manpower resources and skills requirements and provide training to eligible candidates through certified trainers and by means of expert guest lectures, practical training, field visits and on the job training. Based on the manpower resources needs in the industries, following programs are currently offered at the SOE in collaboration with the companies for employment and more courses will be added as per the industry demands.</p>
                        </div>
                        <div class="collapse multi-collapse" id="selection">
                            <h4>Selection and Training Approach</h4>
                            <p class="lead">Minimum qualification is 10+2 for the above courses. The training courses are promoted through the institute website and awareness camps at the villages and Educational Institutions in different regions of Haryana. Candidates meeting the basic qualification and companies’ requirements can apply through the website. Our approach for the selection and training is done as follows:-</p>
                            <ol>
                                <li>
                                    <p class="lead">Interaction/Counseling</p>
                                </li>
                                <li>
                                    <p class="lead">Personal Interview</p>
                                </li>
                                <li>
                                    <p class="lead">Admission</p>
                                </li>
                                <li>
                                    <p class="lead">Classroom/ Practical Training <span>  Job Offer Letter is extended to qualifying candidates after the training. The Learners, who fail to qualify for the job offer, are given added training to qualify for further suitable openings.</span></p>
                                </li>
                                <li>
                                    <p class="lead">Assessment & Certification <span>Jointly from Institute and the respective Industry Partners.</span></p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mt-5 mt-lg-0">
                    <div class="program py-5 px-4">
                        <h4>Program Offered</h4>
                        <ul>
                            <li>
                                <p class="lead">Development Finance training program <span>(NSQF Level – 4, 240 Hrs Classroom Training + 40 hours Practical training Programme)</span></p>
                                <p class="lead">Job roles: <span>Loan Officer, Credit Officer, Front line sales personal, Group mobilizer, Financer, Marketing Lead, Operation Executive, and Sales Executive.</span></p>
                            </li>
                            <li>
                                <p class="lead">Multimedia, Animation Special Effects <span>(NSQF Level – 4, 270 Hrs Classroom Training + 90 hours Practical training Programme).</span></p>
                                <p class="lead">Job roles: <span>Flash Animator, Art Director, BG Modeller, 3D Animator, Comic Book Artist, Concept Designer, Industrial Designer, Layout Artist, Film & Video Editor, Storyboard Artist, Videogame Designer, VFX Supervisor.</span></p>
                            </li>
                            <li>
                                <p class="lead"> Certificate Program in Business Data Analytics, <span>(NSQF Level – 4, 160 Hrs Theory + 320 hours Practical training Programme).</span></p>
                                <p class="lead">Job roles: <span>Business Data Analyst, Database manager, Data System Analyst, Business Data Analyst- Modeling and Profiling</span></p>
                            </li>
                            <li>
                                <p class="lead">Entrepreneurship Development Program for Micro Entrepreneurs <span>(NSQF Level-4, Training Hours-105)</span></p>
                                <p class="lead">Target Output: <span>Acquisition of entrepreneurial competencies, identification of business opportunities, Management of micro enterprise.</span></p>
                            </li>
                            <li>
                                <p class="lead">Medical Sales Representative, <span>(NSQF Level – 4, 105 Hrs Theory + 50 hours Practical training Programme).</span></p>
                                <p class="lead">Job roles: <span>Medical Sales Representative, Sales Associate, Business Development Executive.</span></p>
                            </li>
                            <li>
                                <p class="lead">Quality Control Chemist, <span>(NSQF Level – 5, 153 Hrs Theory + 310 hours Practical training Programme).</span></p>
                                <p class="lead">Job roles: <span>Analytical Chemist, Quality Officer, Instrumental analyst.</span></p>
                            </li>
                            <li>
                                <p class="lead">Production/ Manufacturing Chemist- Life Sciences, <span>(NSQF Level – 5, 128 Hrs Theory + 306 hours Practical training Programme).</span></p>
                                <p class="lead">Job Roles: <span>Production Chemist, Manufacturing Chemist.</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="glimpses">
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card mx-auto">
                        <div id="glimpsesCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <a href="/assets/img/landing/glimpses/1.jpg" data-lightbox="Inauguration Ceremonies"><img class="img-fluid" src="/assets/img/landing/glimpses/1.jpg" alt="Inauguration Ceremonies"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/glimpses/2.jpg" data-lightbox="Inauguration Ceremonies"><img class="img-fluid" src="/assets/img/landing/glimpses/2.jpg" alt="Inauguration Ceremonies"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/glimpses/3.jpg" data-lightbox="Inauguration Ceremonies"><img class="img-fluid" src="/assets/img/landing/glimpses/3.jpg" alt="Inauguration Ceremonies"></a>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#glimpsesCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#glimpsesCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <div class="card-body">
                            <h4>Glimpses of Inauguration Ceremonies</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card mx-auto">
                        <div id="trainingCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <a href="/assets/img/landing/training/1.jpeg" data-lightbox="Training Sessions"><img class="img-fluid" src="/assets/img/landing/training/1.jpeg" alt="Training Sessions"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/training/2.jpeg" data-lightbox="Training Sessions"><img class="img-fluid" src="/assets/img/landing/training/2.jpeg" alt="Training Sessions"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/training/3.jpeg" data-lightbox="Training Sessions"><img class="img-fluid" src="/assets/img/landing/training/3.jpeg" alt="Training Sessions"></a>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#trainingCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#trainingCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <div class="card-body">
                            <h4>Students During Training Sessions</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card mx-auto">
                        <div id="veloCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <a href="/assets/img/landing/velodictory/1.jpeg" data-lightbox="Valedictory"><img class="img-fluid" src="/assets/img/landing/velodictory/1.jpeg" alt="Valedictory"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/velodictory/2.jpeg" data-lightbox="Valedictory"><img class="img-fluid" src="/assets/img/landing/velodictory/2.jpeg" alt="Valedictory"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/velodictory/3.jpeg" data-lightbox="Valedictory"><img class="img-fluid" src="/assets/img/landing/velodictory/3.jpeg" alt="Valedictory"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/velodictory/4.jpeg" data-lightbox="Valedictory"><img class="img-fluid" src="/assets/img/landing/velodictory/4.jpeg" alt="Valedictory"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/velodictory/5.jpeg" data-lightbox="Valedictory"><img class="img-fluid" src="/assets/img/landing/velodictory/5.jpeg" alt="Valedictory"></a>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#veloCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#veloCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <div class="card-body">
                            <h4>Valedictory Ceremony</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card mx-auto">
                            <div id="selectedCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/selected/1.jpeg" data-lightbox="Glimpses of few Selected Students"><img class="img-fluid" src="/assets/img/landing/selected/1.jpeg" alt="Glimpses of few Selected Students"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/selected/2.jpeg" data-lightbox="Glimpses of few Selected Students"><img class="img-fluid" src="/assets/img/landing/selected/2.jpeg" alt="Glimpses of few Selected Students"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/selected/3.jpeg" data-lightbox="Glimpses of few Selected Students"><img class="img-fluid" src="/assets/img/landing/selected/3.jpeg" alt="Glimpses of few Selected Students"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/selected/4.jpeg" data-lightbox="Glimpses of few Selected Students"><img class="img-fluid" src="/assets/img/landing/selected/4.jpeg" alt="Glimpses of few Selected Students"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/selected/5.jpeg" data-lightbox="Glimpses of few Selected Students"><img class="img-fluid" src="/assets/img/landing/selected/5.jpeg" alt="Glimpses of few Selected Students"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#selectedCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#selectedCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                            <div class="card-body">
                                <h4>Glimpses of few Selected Students</h4>
                            </div>
                        </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card mx-auto">
                        <div id="workCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <a href="/assets/img/landing/work/1.jpeg" data-lightbox="Glimpses of the Students at Their Work Place"><img class="img-fluid" src="/assets/img/landing/work/1.jpeg" alt="Glimpses of the Students at Their Work Place"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/work/2.jpeg" data-lightbox="Glimpses of the Students at Their Work Place"><img class="img-fluid" src="/assets/img/landing/work/2.jpeg" alt="Glimpses of the Students at Their Work Place"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/work/3.jpeg" data-lightbox="Glimpses of the Students at Their Work Place"><img class="img-fluid" src="/assets/img/landing/work/3.jpeg" alt="Glimpses of the Students at Their Work Place"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/work/4.jpeg" data-lightbox="Glimpses of the Students at Their Work Place"><img class="img-fluid" src="/assets/img/landing/work/4.jpeg" alt="Glimpses of the Students at Their Work Place"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/work/5.jpeg" data-lightbox="Glimpses of the Students at Their Work Place"><img class="img-fluid" src="/assets/img/landing/work/5.jpeg" alt="Glimpses of the Students at Their Work Place"></a>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#workCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#workCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <div class="card-body">
                            <h4>Glimpses of the Students at Their Work Place</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card mx-auto">
                        <div id="workCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <a href="/assets/img/landing/awareness/1.jpeg" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/1.jpeg" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/awareness/2.jpeg" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/2.jpeg" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/awareness/3.jpeg" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/3.jpeg" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/awareness/4.jpeg" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/4.jpeg" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/awareness/5.png" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/5.png" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                              <div class="carousel-item">
                                <a href="/assets/img/landing/awareness/6.jpeg" data-lightbox="Glimpses of the Webinar/Seminar"><img class="img-fluid" src="/assets/img/landing/awareness/6.jpeg" alt="Glimpses of the Webinar/Seminar"></a>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#workCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#workCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <div class="card-body">
                            <h4>Awareness Programs Conducted by SOE</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="drive" class="my-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Placement <strong>Drive By</strong> </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5 col-lg-3">
                    <div class="btn-group-vertical">
                        <button type="button" class="btn "
                            id="axis-btn" 
                            data-toggle="collapse" 
                            data-target="#axis" 
                            aria-expanded="true" 
                            aria-controls="Axis Bank"
                            (click)="showCard(1)">Axis Bank</button>

                        <button type="button" class="btn "
                        id="bajaj-btn" 
                        data-toggle="collapse" 
                        data-target="#bajaj" 
                        aria-expanded="true" 
                        aria-controls="Bajaj Capital Ltd."
                        (click)="showCard(2)">Bajaj Capital Ltd.</button>

                        <button type="button" class="btn "
                        id="bulk-btn" 
                        data-toggle="collapse" 
                        data-target="#bulk" 
                        aria-expanded="true" 
                        aria-controls="Bulk SMS India"
                        (click)="showCard(3)">Bulk SMS India</button>

                        <button type="button" class="btn "
                        id="it-btn" 
                        data-toggle="collapse" 
                        data-target="#it" 
                        aria-expanded="true" 
                        aria-controls="Dr. IT Group"
                        (click)="showCard(4)">Dr. IT Group</button>

                        <button type="button" class="btn "
                        id="mediyork-btn" 
                        data-toggle="collapse" 
                        data-target="#mediyork" 
                        aria-expanded="true" 
                        aria-controls="Mediyork, Medna Biotech, MPPL"
                        (click)="showCard(5)">Mediyork, Medna Biotech, MPPL</button>

                        <button type="button" class="btn "
                        id="sangwan-btn" 
                        data-toggle="collapse" 
                        data-target="#sangwan" 
                        aria-expanded="true" 
                        aria-controls="Sangwan Polutry Farm Pvt. Ltd."
                        (click)="showCard(6)">Sangwan Polutry Farm Pvt. Ltd.</button>

                        <button type="button" class="btn "
                        id="tech-btn" 
                        data-toggle="collapse" 
                        data-target="#teches" 
                        aria-expanded="true" 
                        aria-controls="Tech Mahindra"
                        (click)="showCard(7)">Tech Mahindra</button>

                        <button type="button" class="btn "
                        id="tele-btn" 
                        data-toggle="collapse" 
                        data-target="#tele" 
                        aria-expanded="true" 
                        aria-controls="Teleperformance Ltd."
                        (click)="showCard(8)">Teleperformance Ltd.</button>

                        <button type="button" class="btn "
                        id="utkarsh-btn" 
                        data-toggle="collapse" 
                        data-target="#utkarsh" 
                        aria-expanded="true" 
                        aria-controls="Utkarsh Small Finance Bank"
                        (click)="showCard(9)">Utkarsh Small Finance Bank</button>

                    </div>
                </div>
                <div class="col-12 col-md-7 col-lg-9">
                    <!-- Axis Bank -->
                    <div class="collapse show multi-collapse" id="axis">
                        <div class="card card-body">
                            <div id="axisCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/axis/1.jpeg" data-lightbox="Axis Bank"><img class="d-block w-100" src="/assets/img/landing/axis/1.jpeg" alt="Axis Bank"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/axis/2.jpeg" data-lightbox="Axis Bank"><img class="d-block w-100" src="/assets/img/landing/axis/2.jpeg" alt="Axis Bank"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#axisCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#axisCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>
                      <!-- Bajaj Capital Ltd. -->
                      <div class="collapse multi-collapse" id="bajaj">
                        <div class="card card-body">
                            <div id="bajajCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/bajaj/1.jpeg" data-lightbox="Bajaj Capital Ltd."><img class="d-block w-100" src="/assets/img/landing/bajaj/1.jpeg" alt="Bajaj Capital Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/bajaj/2.jpeg" data-lightbox="Bajaj Capital Ltd."><img class="d-block w-100" src="/assets/img/landing/bajaj/2.jpeg" alt="Bajaj Capital Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/bajaj/3.jpeg" data-lightbox="Bajaj Capital Ltd."><img class="d-block w-100" src="/assets/img/landing/bajaj/3.jpeg" alt="Bajaj Capital Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/bajaj/4.jpeg" data-lightbox="Bajaj Capital Ltd."><img class="d-block w-100" src="/assets/img/landing/bajaj/4.jpeg" alt="Bajaj Capital Ltd."></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#bajajCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#bajajCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>

                      <!-- Bulk SMS India -->
                      <div class="collapse multi-collapse" id="bulk">
                        <div class="card card-body">
                            <div id="bulkCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/bulk/1.jpeg" data-lightbox="Bulk SMS India"><img class="d-block w-100" src="/assets/img/landing/bulk/1.jpeg" alt="Bulk SMS India"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/bulk/2.jpeg" data-lightbox="Bulk SMS India"><img class="d-block w-100" src="/assets/img/landing/bulk/2.jpeg" alt="Bulk SMS India"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#bulkCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#bulkCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>
                      <!--Dr. IT Group  -->
                      <div class="collapse multi-collapse" id="it">
                        <div class="card card-body">
                            <div id="itCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/it/1.jpeg" data-lightbox="Dr. IT Group"><img class="d-block w-100" src="/assets/img/landing/it/1.jpeg" alt="Dr. IT Group"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/it/2.jpeg" data-lightbox="Dr. IT Group"><img class="d-block w-100" src="/assets/img/landing/it/2.jpeg" alt="Dr. IT Group"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/it/3.jpeg" data-lightbox="Dr. IT Group"><img class="d-block w-100" src="/assets/img/landing/it/3.jpeg" alt="Dr. IT Group"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/it/4.jpeg" data-lightbox="Dr. IT Group"><img class="d-block w-100" src="/assets/img/landing/it/4.jpeg" alt="Dr. IT Group"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#itCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#itCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>
                      <!-- Mediyork, Medna Biotech, MPPL  -->

                      <div class="collapse multi-collapse" id="mediyork">
                        <div class="card card-body">
                            <div id="mediyorkCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/mediyork/1.jpeg" data-lightbox="Mediyork, Medna Biotech, MPPL"><img class="d-block w-100" src="/assets/img/landing/mediyork/1.jpeg" alt="Mediyork, Medna Biotech, MPPL"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/mediyork/2.jpeg" data-lightbox="Mediyork, Medna Biotech, MPPL"><img class="d-block w-100" src="/assets/img/landing/mediyork/2.jpeg" alt="Mediyork, Medna Biotech, MPPL"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#mediyorkCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#mediyorkCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>
                      <!-- Sangwan Polutry Farm Pvt. Ltd. -->
                      <div class="collapse multi-collapse" id="sangwan">
                        <div class="card card-body">
                            <div id="sangwanCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/sangwan/1.jpeg" data-lightbox="Sangwan Polutry Farm Pvt. Ltd."><img class="d-block w-100" src="/assets/img/landing/sangwan/1.jpeg" alt="Sangwan Polutry Farm Pvt. Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/sangwan/2.jpeg" data-lightbox="Sangwan Polutry Farm Pvt. Ltd."><img class="d-block w-100" src="/assets/img/landing/sangwan/2.jpeg" alt="Sangwan Polutry Farm Pvt. Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/sangwan/3.jpeg" data-lightbox="Sangwan Polutry Farm Pvt. Ltd."><img class="d-block w-100" src="/assets/img/landing/sangwan/3.jpeg" alt="Sangwan Polutry Farm Pvt. Ltd."></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#sangwanCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#sangwanCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>

                      <!-- Tech Mahindra -->
                      <div class="collapse multi-collapse" id="teches">
                        <div class="card card-body">
                            <div id="techCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/tech/1.jpeg" data-lightbox="Tech Mahindra"><img class="d-block w-100" src="/assets/img/landing/tech/1.jpeg" alt="Tech Mahindra"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/tech/2.jpeg" data-lightbox="Tech Mahindra"><img class="d-block w-100" src="/assets/img/landing/tech/2.jpeg" alt="Tech Mahindra"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#techCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#techCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>

                      <!-- Teleperformance Ltd. -->
                      <div class="collapse multi-collapse" id="tele">
                        <div class="card card-body">
                            <div id="teleCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/tele/1.jpeg" data-lightbox="Teleperformance Ltd."><img class="d-block w-100" src="/assets/img/landing/tele/1.jpeg" alt="Teleperformance Ltd."></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/tele/2.jpeg" data-lightbox="Teleperformance Ltd."><img class="d-block w-100" src="/assets/img/landing/tele/2.jpeg" alt="Teleperformance Ltd."></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#teleCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#teleCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>

                      <!-- Utkarsh Small Finance Bank -->
                      <div class="collapse multi-collapse" id="utkarsh">
                        <div class="card card-body">
                            <div id="utkarshCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <a href="/assets/img/landing/utkarsh/1.jpeg" data-lightbox="Utkarsh Small Finance Bank"><img class="d-block w-100" src="/assets/img/landing/utkarsh/1.jpeg" alt="Utkarsh Small Finance Bank"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/utkarsh/2.jpeg" data-lightbox="Utkarsh Small Finance Bank"><img class="d-block w-100" src="/assets/img/landing/utkarsh/2.jpeg" alt="Utkarsh Small Finance Bank"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/utkarsh/3.jpeg" data-lightbox="Utkarsh Small Finance Bank"><img class="d-block w-100" src="/assets/img/landing/utkarsh/3.jpeg" alt="Utkarsh Small Finance Bank"></a>
                                  </div>
                                  <div class="carousel-item">
                                    <a href="/assets/img/landing/utkarsh/4.jpeg" data-lightbox="Utkarsh Small Finance Bank"><img class="d-block w-100" src="/assets/img/landing/utkarsh/4.jpeg" alt="Utkarsh Small Finance Bank"></a>
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#utkarshCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#utkarshCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div id="scope">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">

                </div>
                <div class="col-6"></div>
            </div>
        </div>
    </div>
    <div id="glimpses" class="mx-lg-5 mb-3">
        <div class="container-fluid px-lg-4">
            <div class="row">
                <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <div class="glimses-detail">
                        <h6>Glimpses</h6>
                        <h2>Of Inauguration Ceremonies</h2>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div id="glimpsesCarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img class="img-fluid" src="/assets/img/landing/glimpses/1.jpg" alt="Inauguration Ceremonies">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/glimpses/2.jpg" alt="Inauguration Ceremonies">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/glimpses/3.jpg" alt="Inauguration Ceremonies">
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#glimpsesCarousel" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#glimpsesCarousel" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div id="training" class="mx-lg-5 mb-3">
        <div class="container-fluid px-lg-4">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div id="trainingCarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img class="img-fluid" src="/assets/img/landing/training/1.jpeg" alt="Training Sessions">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/training/2.jpeg" alt="Training Sessions">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/training/3.jpeg" alt="Training Sessions">
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#trainingCarousel" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#trainingCarousel" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-start align-items-center pl-lg-4">
                    <div class="training-detail">
                        <h6>Students</h6>
                        <h2> During Training Sessions</h2>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    <div id="glimpses" class="mx-lg-5 mb-3">
        <div class="container-fluid px-lg-4">
            <div class="row">
                <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <div class="glimses-detail">
                        <h6>Valedictory</h6>
                        <h2> Ceremony</h2>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div id="veloCarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img class="img-fluid" src="/assets/img/landing/velodictory/1.jpeg" alt="Valedictory">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/velodictory/2.jpeg" alt="Valedictory">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/velodictory/3.jpeg" alt="Valedictory">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/velodictory/4.jpeg" alt="Valedictory">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/velodictory/5.jpeg" alt="Valedictory">
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#veloCarousel" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#veloCarousel" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                </div>
            </div>
        </div>
    </div>

    <div id="training" class="mx-lg-5 mb-3">
        <div class="container-fluid px-lg-4">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div id="axisCarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img class="img-fluid" src="/assets/img/landing/axis/1.jpeg" alt="Axis Bank">
                          </div>
                          <div class="carousel-item">
                            <img class="img-fluid" src="/assets/img/landing/axis/2.jpeg" alt="Axis Bank">
                          </div>
                          
                        </div>
                        <a class="carousel-control-prev" href="#axisCarousel" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#axisCarousel" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-start align-items-center pl-lg-4">
                    <div class="training-detail">
                        <h6>Placement</h6>
                        <h2> Drive by Axis Bank</h2>
                    </div>
                </div>
              
            </div>
        </div>
    </div> -->
    <!-- <div id="glimpses" class="mx-lg-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4">
                    <div class="btn-group-vertical" role="group" aria-label="Basic example">
                        <button type="button" class="btn">Glimpses of Inauguration</button>
                        <button type="button" class="btn">Students during Training Sessions</button>
                        <button type="button" class="btn">Valedictory Ceremony</button>
                        <button type="button" class="btn">Placement Drive by Axis Bank</button>
                        <button type="button" class="btn">Placement Drive by Bajaj Capital Ltd.</button>
                        <button type="button" class="btn">Placement Drive by Bulk SMS India</button>
                        <button type="button" class="btn">Placement Drive by Dr. IT Group</button>
                        <button type="button" class="btn">Placement Drive by Mediyork</button>
                        <button type="button" class="btn">Placement Drive by Sangwan</button>
                        <button type="button" class="btn">Placement Drive by Tech Mahindra</button>
                        <button type="button" class="btn">Placement Drive by Teleperformance Ltd.</button>
                        <button type="button" class="btn">Placement Drive by Utkarsh</button>
                        <button type="button" class="btn">Students during Campus Placement</button>
                        <button type="button" class="btn">Glimpses of few Selected Students</button>
                        <button type="button" class="btn">Glimpses of the students</button>
                        <button type="button" class="btn">Awareness Programs Conducted</button>
                      </div>
                </div>
                <div class="col-8"></div>
            </div>
        </div>
    </div> -->
</div>

