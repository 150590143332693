import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-ct-seven-one-two',
  templateUrl: './ct-seven-one-two.component.html',
  styleUrls: ['./ct-seven-one-two.component.scss']
})
export class CtSevenOneTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/7.1.2_Policy Documents_1.pdf',
    '/assets/pdf/NAAC/DVV/7.1.2_Bills and Circulars_Notices_3.pdf'];
  src: string;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  showTable = false;
  c241Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1: { title: string, link: string }[] = [
    {
      title: '7.1.2 _1. Alternate sources of energy _ conservation',
      link: this.c241Url + '7.1.2 _1. Alternate sources of energy _ conservation.pdf'
    },
    {
      title: '7.1.2_2. Management of Degradable and Non Degradable Waste',
      link: this.c241Url + '7.1.2_2. Management of Degradable and Non Degradable Waste.pdf'
    },
    {
      title: '7.1.2_3. Water Conservation',
      link: this.c241Url + '7.1.2_3. Water Conservation.pdf'
    },
    {
      title: '7.1.2_4. Green Campus Intiative',
      link: this.c241Url + '7.1.2_4. Green Campus Intiative.pdf'
    },
    {
      title: '7.1.2_5. Disabled friendly environment',
      link: this.c241Url + '7.1.2_5. Disabled friendly environment.pdf'
    }
  ]

  result: { title: string, link: string }[] = [];

  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '3') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      this.result = this.result1;
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.showTable = true;
    }
    if (id === '1') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.showTable = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.showTable = false;
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
