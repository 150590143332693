import { Component, HostListener, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-e-content',
  templateUrl: './e-content.component.html',
  styleUrls: ['./e-content.component.scss']
})
export class EContentComponent implements OnInit {
  @HostListener("click") onClick() {
    this.checkRoute();
  }
  fullWidth = false;
  showSideMenu = true;
  constructor(private showcaseService: ShowcaseService) {
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    this.checkRoute();
  }


  splitUrl() {
    let url = location.pathname.split('/');
    if (url.length === 2) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }
}
