<div id="naac-list" *ngIf="showNaac">
    <div class="container-fluid mt-5 px-lg-5">
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="card-title">NAAC Files</h3>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" class="w-50">Title</th>
                <th scope="col" class="text-center">View</th>
                
              
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let naac of naacs; let i=index">
                <th scope="row">{{i+1}}</th>
                <td>{{naac.title}}</td>
                <td class="text-center" (click)="hideNaac()"> <a [routerLink]="['/menu-detail/naac',naac.path]"><i class="fas fa-eye" ></i></a> </td>
                
                <!-- href="{{naac.link}}" download target="_blank" -->
              </tr>
             
            </tbody>
          </table>
    </div>
</div>
<div *ngIf="!showNaac">
  <router-outlet></router-outlet>
</div>

<!-- <iframe src="https://drive.google.com/file/d/1R2eIb9wFkdAMJSAv25wotU4JjONN2ZRc/preview"></iframe> -->