import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-wp-content',
  templateUrl: './wp-content.component.html',
  styleUrls: ['./wp-content.component.scss']
})
export class WpContentComponent implements OnInit {
 
 constructor(
   private showcaseService:ShowcaseService,
   private pathNameService:PathNameService
 ) { 
   showcaseService.hideShowcase();
   
 }

 ngOnInit(): void {
   
 }
 
 
 ngOnDestroy(){
   this.showcaseService.showingShowcase();
 }
}
