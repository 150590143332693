import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c-one-two-two-one',
  templateUrl: './c-one-two-two-one.component.html',
  styleUrls: ['./c-one-two-two-one.component.scss']
})
export class COneTwoTwoOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 1/1.2.2.1_Addon Courses Enrolled Students/2021-22-AES.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.2.1_Addon Courses Enrolled Students/2020-21-AES.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.2.1_Addon Courses Enrolled Students/2019-20-AES.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.2.2.1_Addon Courses Enrolled Students/2018-19-AES.pdf',
    '/assets/pdf/NAAC/Criterion 1/1.2.2.1_Addon Courses Enrolled Students/2017-18-AES.pdf' ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('2021');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '2021') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2020') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '2019') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.fourth').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '2018') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '2017') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      
      this.src = this.pdfSrc[4];
    }
    

  }
  hideLoader() {
    this.isLoading = false;
  }
}
