import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c331-twentyone',
  templateUrl: './c331-twentyone.component.html',
  styleUrls: ['./c331-twentyone.component.scss']
})
export class C331TwentyoneComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.1.1 Research/';

  result:{title:string,link:string}[]=[
    {
        title: 'A Comparitve study of Physcial fitness  components of Athletes and Footballer of Saharnpur Distt.',
        link: this.c3321Url + '23_A-Comparitve-study-of-Physcial-fitness_2021-22.pdf'
    },
    {
        title: 'Covid -19 outbreak and Olympics',
        link: this.c3321Url + '24_Covid-19-outbreak-and-Olympics__2021-22.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
