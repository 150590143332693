import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-four-one',
  templateUrl: './c1-one-four-one.component.html',
  styleUrls: ['./c1-one-four-one.component.scss']
})
export class C1OneFourOneComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 6 || (url.length === 7 && (url[6]==='1-4-1-1' || url[6]==='1-4-1-3'))){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
