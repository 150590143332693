import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-one-two-one',
  templateUrl: './two-one-two-one.component.html',
  styleUrls: ['./two-one-two-one.component.scss']
})
export class TwoOneTwoOneComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.2.1/2.1.2.1_MINORITY CERTIFICATE.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
