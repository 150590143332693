<div id="calender">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>IQAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">IQAC</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Academic Calender 2019-20</li>
            </ol>
          </nav>
    </div>
    <div class="container mt-5" >
        <div class="card shadow-lg colorBlue">
            <div class="row">
                <div class="col-12 text-center mt-5">

                    <h2>Academic Calender 2019-20</h2>
                    <iframe src="https://drive.google.com/file/d/1QtD8LXRxKjKrOPlibrZXcqLLHL8hwamY/preview" width="100%" height="1100px" allow="autoplay"></iframe>
                </div>
            </div>
            
        </div>
    </div>
</div>