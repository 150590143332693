import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }
}
