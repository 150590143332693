import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { ActivitiesComponent } from "src/app/view/activities/activities.component";
import { AdventureComponent } from "src/app/view/activities/adventure/adventure.component";
import { ClubComponent } from "src/app/view/activities/club/club.component";
import { CrossComponent } from "src/app/view/activities/cross/cross.component";
import { CulturalComponent } from "src/app/view/activities/cultural/cultural.component";
import { GTwooneTwotwoComponent } from "src/app/view/activities/gender/g-twoone-twotwo/g-twoone-twotwo.component";
import { GTwothreeTwofourComponent } from "src/app/view/activities/gender/g-twothree-twofour/g-twothree-twofour.component";
import { GTwotwoTwothreeComponent } from "src/app/view/activities/gender/g-twotwo-twothree/g-twotwo-twothree.component";
import { GenderComponent } from "src/app/view/activities/gender/gender.component";
import { LegalCellComponent } from "src/app/view/activities/legal-cell/legal-cell.component";
import { NccComponent } from "src/app/view/activities/ncc/ncc.component";
import { NssComponent } from "src/app/view/activities/nss/nss.component";
import { PlacementCellComponent } from "src/app/view/activities/placement-cell/placement-cell.component";
import { ServicesComponent } from "src/app/view/activities/services/services.component";
import { SocialComponent } from "src/app/view/activities/social/social.component";
import { SportsComponent } from "src/app/view/activities/sports/sports.component";
import { VismadComponent } from "src/app/view/activities/vismad/vismad.component";
import { WomenCellComponent } from "src/app/view/activities/women-cell/women-cell.component";
import { YouthComponent } from "src/app/view/activities/youth/youth.component";

const route:Route[]=[
    {path:'',component:ActivitiesComponent,children:[
        {path:'ncc',component:NccComponent},
        {path:'nss',component:NssComponent},
        {path:'sports',component:SportsComponent},
        {path:'Women-cell',component:WomenCellComponent},
        {path:'cultural',component:CulturalComponent},
        {path:'legal-literacy-cell',component:LegalCellComponent},
        {path:'youth-red-cross',component:YouthComponent},
        {path:'eco-club',component:ClubComponent},
        {path:'adventure-club',component:AdventureComponent},
        // {path:'placement-cell',component:PlacementCellComponent},
        {path:'community-services',component:ServicesComponent},
        {path:'VISMAD',component:VismadComponent},
        // {path:'social',component:SocialComponent},
        {path:'gender',component:GenderComponent, children:[
            {path:'2023-24', component:GTwothreeTwofourComponent},
            {path:'2022-23', component:GTwotwoTwothreeComponent},
            {path:'2021-22', component:GTwooneTwotwoComponent},
        ]},
        {path:'cross-cutting',component:CrossComponent}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class ActivitiesRoutingModule{}