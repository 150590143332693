import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-second-best-practice',
  templateUrl: './second-best-practice.component.html',
  styleUrls: ['./second-best-practice.component.scss']
})
export class SecondBestPracticeComponent implements OnInit {

  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
