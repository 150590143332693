import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  newUrl='';
  path='';
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.convertingUrl();
    $('.carousel').carousel({
      interval: 1000
    });
    
  }

  convertingUrl(){
    let url=location.pathname.split('/');
    this.newUrl=url[url.length-1];
    if(this.newUrl.includes('.')){
      for(let i=0;i<this.newUrl.length;i++){
        this.newUrl=this.newUrl.replace('.','-');
      }
    }
    if(this.newUrl.includes('_')){
      for(let i=0;i<this.newUrl.length;i++){
        this.newUrl=this.newUrl.replace('_','-');
      }
    }
    for(let i=0;i<url.length-1;i++){
      this.path+=url[i]+'/';
    }
    this.path=this.path+this.newUrl;
    // console.log(this.path);
    this.router.navigate([this.path]);
    
  }
}
