import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-ct-five-three-two',
  templateUrl: './ct-five-three-two.component.html',
  styleUrls: ['./ct-five-three-two.component.scss']
})
export class CtFiveThreeTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/5.3.2_Revised data template according to the events of sports and cultural activities participation_1.pdf',
    '/assets/pdf/NAAC/DVV/5.3.2_Copy of circulars of Sports activities Participation of 5 years_3.pdf',
    '/assets/pdf/NAAC/DVV/5.3.2_Copy of Brochure of Cultural activities Participation of 5 years_3.pdf',
    '/assets/pdf/NAAC/DVV/5.3.2_List of events along with the list of participants year wise_4.pdf'
  ];
  src: string;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  heading4 = false;
  heading5 = false;
  showTable = false;
  c241Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1: { title: string, link: string }[] = [
    {
      title: '5.3.2_2021-22_Revised Documents Proofs of Data templates of Sports  and Cultural participation',
      link: this.c241Url + '5.3.2_2021-22_Revised Documents Proofs of Data templates of Sports  and Cultural participation.pdf'
    },
    {
      title: '5.3.2_2020-21_Revised Documents Proofs of Data templates of  Sports  and Cultural participation',
      link: this.c241Url + '5.3.2_2020-21_Revised Documents Proofs of Data templates of  Sports  and Cultural participation.pdf'
    },
    {
      title: '5.3.2_2019-20_Revised Documents Proofs of Data templates of Sports  and Cultural participation',
      link: this.c241Url + '5.3.2_2019-20_Revised Documents Proofs of Data templates of Sports  and Cultural participation.pdf'
    },
    {
      title: '5.3.2_2018-19_Revised Documents Proofs of Data templates of Sports  and Cultural participation',
      link: this.c241Url + '5.3.2_2018-19_Revised Documents Proofs of Data templates of Sports  and Cultural participation.pdf'
    },
    {
      title: '5.3.2_2017-18_Revised Documents Proofs of Data templates of Sports  and Cultural participation',
      link: this.c241Url + '5.3.2_2017-18_Revised Documents Proofs of Data templates of Sports  and Cultural participation.pdf'
    }
  ]

  result2: { title: string, link: string }[] = [
    {
      title: '5.3.2_2021-22_Reports of Cultural activities participation of each events',
      link: this.c241Url + '5.3.2_2021-22_Reports of Cultural activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2020-21_Reports of Cultural activities participation of each events',
      link: this.c241Url + '5.3.2_2020-21_Reports of Cultural activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2019-20_Reports of Cultural activities participation of each events',
      link: this.c241Url + '5.3.2_2019-20_Reports of Cultural activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2018-19_Reports of Cultural activities participation of each events',
      link: this.c241Url + '5.3.2_2018-19_Reports of Cultural activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2017-18_Reports of Cultural activities participation of each events',
      link: this.c241Url + '5.3.2_2017-18_Reports of Cultural activities participation of each events.pdf'
    }
  ]

  result3: { title: string, link: string }[] = [
    {
      title: '5.3.2_2021-22_Reports of Sports activities participation of each events',
      link: this.c241Url + '5.3.2_2021-22_Reports of Sports activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2019-20_Reports of Sports activities participation of each events',
      link: this.c241Url + '5.3.2_2019-20_Reports of Sports activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2018-19_Reports of Sports activities participation of each events',
      link: this.c241Url + '5.3.2_2018-19_Reports of Sports activities participation of each events.pdf'
    },
    {
      title: '5.3.2_2017-18_Reports of Sports activities participation of each events',
      link: this.c241Url + '5.3.2_2017-18_Reports of Sports activities participation of each events.pdf'
    },
  ]

  result: { title: string, link: string }[] = [];

  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.showTable = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.showTable = false;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.heading4 = false;
      this.heading5 = false;
      this.showTable = false;
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`two`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = true;
      this.heading5 = false;
      this.showTable = false;
      this.src = this.pdfSrc[3];
    }
    if (id === '5') {
      $(`five`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`seven`).removeClass('show');
      this.result = this.result1;
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = true;
      this.showTable = true;
    }
    if (id === '6') {
      $(`six`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`seven`).removeClass('show');
      this.result = this.result2;
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = true;
      this.showTable = true;
    }
    if (id === '7') {
      $(`seven`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`six`).removeClass('show');
      $(`five`).removeClass('show');
      this.result = this.result3;
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = true;
      this.showTable = true;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
