import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-three-four-three',
  templateUrl: './c3-three-four-three.component.html',
  styleUrls: ['./c3-three-four-three.component.scss']
})
export class C3ThreeFourThreeComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 6){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if((url.length === 7 && (url[6]==='2021-22' || url[6]==='2020-21' || url[6]==='2019-20' || url[6]==='2018-19'))){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
      this.showHeading = false;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
