import { Component, OnInit } from '@angular/core';

import { MediaService } from 'src/app/services/media/media.service';
import 'owl.carousel';
declare var $:any;
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit{
  
  imgList:any[];
  media:any[]=[];
  mediaString='';
  constructor(private service:MediaService) { }

  ngOnInit(): void {
    this.service.getMediaList();
    this.service.mediaList.snapshotChanges().subscribe(
      list=>{
      this.imgList=  list.map(item=>{ return item.payload.val();})
      this.imgList= this.imgList.reverse();
        for(let i=0;i<4;i++){
          this.media[i]=this.imgList[i];
        }
      }
    );
    
  }

 

}
