import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  pdfSrc = "/assets/pdf/N-23.pdf";
  pdfSrc1 = "/assets/pdf/Document1.pdf";
  pdfSrc2 = "/assets/pdf/Document2.pdf";
  pdfSrc3= "/assets/pdf/Document3.pdf";
  constructor() { }

  ngOnInit(): void {
    // $("#myModal").modal('show');
  }
  openUrl(id:string){
    window.open(id);
  }
  
}
