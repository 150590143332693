import { Component, OnInit } from '@angular/core';
import ScrollOut from "scroll-out";
import { BoardsService } from 'src/app/services/boards/boards.service';
declare var $:any;
@Component({
  selector: 'app-governing',
  templateUrl: './governing.component.html',
  styleUrls: ['./governing.component.scss']
})
export class GoverningComponent implements OnInit {
  so:any;
  boards:{name:string,designation:string}[]=[];
  constructor(private boardService:BoardsService) { }


  ngOnInit(): void {
    // this.jQuery();
    this.so = ScrollOut({
      onShown: function(el) {
        // remove the class
        el.classList.remove('animated');
    
        // force reflow
        void el.offsetWidth;
    
        // re-add the animated cl
        el.classList.add('animated');
      }
    });
    this.boards=this.boardService.getBoards();
  }
  // jQuery() {
  
  //   // Function which adds the 'animated' class to any '.animatable' in view
  //   var doAnimations = function() {
      
  //     // Calc current offset and get all animatables
  //     var offset = $(window).scrollTop() + $(window).height(),
  //         $animatables = $('.animatable');
      
  //     // Unbind scroll handler if we have no animatables
  //     if ($animatables.length == 0) {
  //       $(window).off('scroll', doAnimations);
  //     }
      
  //     // Check all animatables and animate them if necessary
  //     $animatables.each(function(i) {
  //        var $animatable = $(this);
  //       if (($animatable.offset().top + $animatable.height() - 20) < offset) {
  //         $animatable.removeClass('animatable').addClass('animated');
  //       }
  //     });
  
  //   };
    
  //   // Hook doAnimations on scroll, and trigger a scroll
  //   $(window).on('scroll', doAnimations);
  //   $(window).trigger('scroll');
  
  // };

}
