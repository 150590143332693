<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria5/5-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>5.1.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li>
                        <a>SOFT SKILL</a>
                    </li>
                    <li class="sub-menu one">
                        <a (click)="changeRoute('1')">2021-2022</a>
                    </li>
                    <li class="sub-menu two">
                        <a (click)="changeRoute('2')">2020-2021</a>
                    </li>
                    <li class="sub-menu three">
                        <a (click)="changeRoute('3')">2019-2020</a>
                    </li>
                    <li class="sub-menu four">
                        <a (click)="changeRoute('4')">2018-2019</a>
                    </li>
                    <li class="sub-menu five">
                        <a (click)="changeRoute('5')">2017-2018</a>
                    </li>
                    <li>
                        <a>ICT SKILL</a>
                    </li>
                    <li class="sub-menu six">
                        <a (click)="changeRoute('6')">2021-2022</a>
                    </li>
                    <li class="sub-menu seven">
                        <a (click)="changeRoute('7')">2020-2021</a>
                    </li>
                    <li class="sub-menu eight">
                        <a (click)="changeRoute('8')">2019-2020</a>
                    </li>
                    <li class="sub-menu nine">
                        <a (click)="changeRoute('9')">2018-2019</a>
                    </li>
                    <li class="sub-menu ten">
                        <a (click)="changeRoute('10')">2017-2018</a>
                    </li>
                    <li class="sub-menu eleven">
                        <a (click)="changeRoute('11')">Proofs of capacity development and skill enhancement activities</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">SKILLS ENHANCEMENTS</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>