<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/dvv/criteria2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>2.1.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">Complete data template_1</a>
                    </li>
                    <li class="two">
                        <a (click)="changeRoute('2')">Minority Certificate_6</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">Minority Certificate and guidelines-7</a>
                    </li>
                    <li class="four">
                        <a (click)="changeRoute('4')">Minority Certificate and guidelines-8</a>
                    </li>
                    <li class="five">
                        <a (click)="changeRoute('5')">Admission list of students indicating their category</a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="col-md-9" >
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading1">Complete data template_1</h5>
                            <h5 class="card-title text-center" *ngIf="heading2">Minority Certificate_6</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">Minority Certificate and guidelines-7</h5>
                            <h5 class="card-title text-center" *ngIf="heading4">Minority Certificate and guidelines-8</h5>
                            <h5 class="card-title text-center" *ngIf="heading5">Admission list of students indicating their category</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                                    id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>