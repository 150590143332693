import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OtpApiService {
  private phone:number;
  
  constructor(private http:HttpClient) { }

  sendOtp(phone:number){
    return this.http.get<{request_id:string,type:string}>(environment.otpURL+'/api/v5/otp?template_id=60952035def4ee4d386d4f13&mobile='+phone +'&authkey=217785A010VVjf5b0d19f6');
    
  }
  verifyOtp(otp:number,phone:number){
    return this.http.get<{message:string,type:string}>(environment.otpURL+'/api/v5/otp/verify?authkey=217785A010VVjf5b0d19f6&mobile='+phone+'&otp='+otp);
  }
  resendOtp(phone:number){
    return this.http.get<{message:string,type:string}>(environment.otpURL+'/api/v5/otp?template_id=60952035def4ee4d386d4f13&mobile='+phone +'&authkey=217785A010VVjf5b0d19f6');
  }
    setPhone(phone:number){
      this.phone=phone;
    }
    getPhone(){
      return this.phone;
    }
}
