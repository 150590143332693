import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-g-twothree-twofour',
  templateUrl: './g-twothree-twofour.component.html',
  styleUrls: ['./g-twothree-twofour.component.scss']
})
export class GTwothreeTwofourComponent implements OnInit {
  src:string = '/assets/pdf/women.pdf'
  src1:string = '/assets/pdf/women2.pdf'
  isLoading=true;
  constructor() { }

  ngOnInit(): void {
  }

  hideLoader(){
    this.isLoading=false;
  }

}
