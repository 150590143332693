<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/research/student-project"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Students Project</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">Post matric SC Scholarship 2022-23</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">Post matric BC OBC Father less BPL Scholarship 2022-23</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">Post Matric Minority Scholarship 2022-23</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">Goverment Scholarship for Meritorious Students 2022-23</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">Individual Scholarship at All India Inter University Level 2022-23</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">Individual Khelo India University Game Scholarship 2022-23</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">Goverment fee concession for Girls Students 2022-23</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">Jamna Auto Industries Scholarship Academic 2022-23</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">Jamna Auto Industries Scholarship for Sports Persons 2022-23</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">Scholarship by Philanthropists 2022-23</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>