<div id="grieveance">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-lg my-3 px-md-4">
        <h4 class="card-title text-center ">Grievance</h4>
        <div class="card-body">

          <div id="sports">
            <div class="container-fluid pdf">
              <div class="row">
                <div class="col-12">
                  <div class="card shadow-lg">
                    <div id="detail" class="text-center">
                      <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>

                    </div>

                  </div>
                </div>


              </div>
            </div>
            <!-- <iframe src="https://drive.google.com/file/d/1hXSEtXuHoRxnB6qkNWeHm8csx7Lsa86D/preview" width="100%"
            height="640" allow="autoplay"></iframe> -->
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col">S.NO.</th>
                  <th scope="col">TITLE</th>
                  <th scope="col">URL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td class="title">COLLEGIATE STUDENTS GRIEVANCE REDRESSAL COMPLAINT FORM</td>
                  <td class="link"><a href="https://forms.gle/dAzujYHY1Nfnv4rm9"
                      target="_blank">https://forms.gle/dAzujYHY1Nfnv4rm9</a> </td>
                </tr>

                <tr>
                  <th scope="row">2</th>
                  <td class="title">SC/ST/BC COMPLAINT FORM</td>
                  <td class="link"><a href="https://forms.gle/2ygb2U1t6E1jU18H9"
                      target="_blank">https://forms.gle/2ygb2U1t6E1jU18H9</a> </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>