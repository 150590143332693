<div id="phd">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <!-- <h4 class="card-title text-center">Ph.D Degrees</h4> -->
                    <div class="card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>