import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-twotwo-twothree',
  templateUrl: './dev-twotwo-twothree.component.html',
  styleUrls: ['./dev-twotwo-twothree.component.scss']
})
export class DevTwotwoTwothreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
