import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c6-six-one-one',
  templateUrl: './c6-six-one-one.component.html',
  styleUrls: ['./c6-six-one-one.component.scss']
})
export class C6SixOneOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-6/6.1.1_VISION MISSION_ADVISORY COMMITTEE_OTHERS COMMITTEES/6.1.1_ADVISORY COMMITTEE.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.1.1_VISION MISSION_ADVISORY COMMITTEE_OTHERS COMMITTEES/6.1.1_DECENTRALIZATION IN INSTITUTIONAL GOVERNANCE.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.1.1_VISION MISSION_ADVISORY COMMITTEE_OTHERS COMMITTEES/6.1.1_VISION MISSION.pdf',
    ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[2];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }
}
