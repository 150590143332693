<div id="gender">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <!-- <h4 class="card-title text-center">Gender Equality</h4> -->
                    <div class="card-body text-justify">

                        <iframe src="https://drive.google.com/file/d/1Tc8J8JXZPFjUPB2aj6Tk2vmw1LkescAM/preview"
                            width="100%" height="480" allow="autoplay"></iframe>
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>