import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-two-two',
  templateUrl: './c-two-two.component.html',
  styleUrls: ['./c-two-two.component.scss']
})
export class CTwoTwoComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
  }

}
