import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from 'src/app/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  private contacts:Contact[]=[];
  constructor(private http:HttpClient) { }

  fetchContacts(){
    this.http.get<Contact[]>(this.url+'/contacts.json').subscribe(res=>{
      this.contacts=res;
      // console.log(this.contacts);
    },error=>{
      console.log(error);
    });
  }

  setContacts(contact:Contact){
    this.contacts.push(contact);
    this.http.post<Contact[]>(this.url+'/contacts.json',contact).subscribe(
      res=>{
        alert("Your Feedback is successfully submitted. Thank You!");
      },error=>{
        alert("Something Wrong Happen. Please Try Again!");
      }
    );
  }
}
