<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria7/7-2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>7.2.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('1')">Best Practices</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('2')">(B). POLICIES FOR WOMEN EMPOWEMENT AND CLEAN AND GREEN ENVIRONMENT</a>
                    </li>
                    
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf" *ngIf="showBest">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Best Practices</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngIf="!showBest">
                <div class="row">
                    <div class="col-12">
                        <div class="card shadow-lg">
                            <h4 class="card-title text-center">(B). POLICIES FOR WOMEN EMPOWEMENT AND CLEAN AND GREEN ENVIRONMENT</h4>
                            <div class="card-body text-justify">
                                <table class="table table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.NO.</th>
                                        <th scope="col">TITLE</th>
                                        <th scope="col">URL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let item of policies; let i = index;">
                                        <th scope="row">{{i+1}}</th>
                                        <td class="title">{{item.title}}</td>
                                        <td class="link"><a href="{{item.link}}" target="_blank"><i class="fas fa-eye"></i></a> </td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                            </div>
                        </div>
          
                    </div>
                </div>
          
               
            </div>
    </div>
</div>