import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-three-three-two-one',
  templateUrl: './ct-three-three-two-one.component.html',
  styleUrls: ['./ct-three-three-two-one.component.scss']
})
export class CtThreeThreeTwoOneComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 6){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if((url.length === 7 && (url[6]==='2021' || url[6]==='2020' || url[6]==='2019' || url[6]==='2017'))){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
      this.showHeading = false;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
