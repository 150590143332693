import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-study',
  templateUrl: './self-study.component.html',
  styleUrls: ['./self-study.component.scss']
})
export class SelfStudyComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/SSR Report.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
