import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BulletinService } from 'src/app/service/bulletin/bulletin.service';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss']
})
export class BulletinComponent implements OnInit {
  bulletin:string='';
  sub:Subscription;
  constructor(private bulletinService:BulletinService) { }

  ngOnInit(): void {
    this.sub=this.bulletinService.changeBulletin.subscribe(
      res=>{
        document.querySelector('.bulletinMarque').innerHTML=res;
      }
    );
  }

}
