import { Injectable } from '@angular/core';
import { ResearchProject } from 'src/app/models/research-project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private majorProject:ResearchProject[]=[
    {name:'Dr. P.K. Malik',department:'Commerce',title:'Quality circles in Educational institutions of Higher Learning-A case study of Haryana',fund:'UGC',amount: ' 6,60,600'},
    {name:'Dr.Vijay Sharma',department:'Industrial Chemistry',title:'Studies on the treatment of industrial effluents from various industries in the twin cities of Yamunanagar and Jagadhri from indigenously prepared cation exchanger i.e. wheat straw and use of treated effluents as a potential fertilizer',fund:'UGC',amount: ' 9,24,000'},
    {name:'Dr. Sanjay Arora',department:'Commerce',title:'Management of stress-A study of AICTE approved Colleges in Haryana',fund:'UGC',amount: ' 7,56,200'},
    {name:'Dr.N.S.Virk',department:'Punjabi',title:'The concept of healthy society in shree guru granth Sahib through Simran: Theory and Practice',fund:'UGC',amount: ' 6,21,200'},
    {name:'Dr. Daljit Kaur',department:' Geography',title:'Impact of cropping pattern on Ground Water along Yamuna River since inception of Green revolution',fund:'UGC',amount: ' 10,62,300'},
    {name:'Dr. Mandeep Singh',department:'Economics',title:'Dynamics of Retail And Consumer growth in India',fund:'UGC',amount: ' 4,46,200'}
  ];
  private minorProject:ResearchProject[]=[
    {name:'Dr.Mandeep Singh',department:'Economics',title:'Economic Reforms in India',fund:'UGC',amount: ' 50,000'},
    {name:'Dr.Alok Goyal',department:'Commerce',title:'Non Performing Assets and public sector Banks in India',fund:'UGC',amount: ' 1,05,000'},
    {name:'Dr.Amrit kaur',department:'English',title:'A Endangered species: A study of the Girls Child Plight in the work of some Indian Woman Writers',fund:'UGC',amount: ' 60000'},
    {name:'Dr.Ashok Khurana',department:'Commerce',title:'Building effective customer Relationship management (CRM) through mobile messaging: A study of Customer Perspective',fund:'UGC',amount: ' 65,000'},
    {name:'Dr. Rajinder Singh Vohra',department:'Commerce',title:'FII inflow into India: its Rational, Determinants, cost and policy Implications for Indian Stock market',fund:'UGC',amount: ' 15,0000'},
    {name:'Dr. Rameshwar Dass',department:'Industrial Chemistry',title:'Study of Heavy Metal in the Water of Western Yamuna Canal',fund:'UGC',amount: ' 42,000'},
    {name:' Dr.P.R.Tyagi',department:'Commerce',title:'FDI in Retailing in India',fund:'UGC',amount: '  100,000'},
    {name:' Dr.Amarjit Singh',department:' Enviornmental Science',title:'Air Quality analysis of –Particular matter',fund:'UGC',amount: ' 1,20,000'}
  ];
  constructor() { }

  getMajorProject(){
    return this.majorProject.slice();
  }
  getMinorProject(){
    return this.minorProject.slice();
  }
}
