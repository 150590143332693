import { NgModule } from "@angular/core";
import { ExtraOptions, Route, RouterModule } from "@angular/router";
import { ApplyFormComponent } from "./view/apply-form/apply-form.component";
import { BestPracticeComponent } from "./view/best/best-practice/best-practice.component";
import { SecondBestPracticeComponent } from "./view/best/second-best-practice/second-best-practice.component";
import { CourseComponent } from "./view/course/course.component";
import { EContentComponent } from "./view/e-content/e-content.component";
import { EnquiryComponent } from "./view/enquiry/enquiry.component";
import { HomeComponent } from "./view/home/home.component";
import { NotFoundComponent } from "./view/not-found/not-found.component";
import { PerformanceComponent } from "./view/performance/performance.component";
import { SecondPerformanceComponent } from "./view/second-performance/second-performance.component";
import { LessonPlanComponent } from "./view/courses/lesson-plan/lesson-plan.component";
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
  };
const routes:Route[]=[
    {path:'',component:HomeComponent},
    // {path:'lesson-plan',component:LessonPlanComponent},
    {path:'about',loadChildren:'./modules/about-us/about-us.module#AboutUsModule'},
    {path:'activities',loadChildren:'./modules/activities/activities.module#ActivitiesModule'},
    {path:'courses',loadChildren:'./modules/course/course.module#CourseModule'},
    {path:'faculty',loadChildren:'./modules/faculty/faculty.module#FacultyModule'},
    {path:'infrastructure',loadChildren:'./modules/infrastructure/infrastructure.module#InfrastructureModule'},
    {path:'iqac',loadChildren:'./modules/iqac/iqac.module#IqacModule'},
    {path:'naac',loadChildren:'./modules/naac/naac.module#NaacModule'},
    {path:'menu-detail',loadChildren:'./modules/menu-detail/menu-detail.module#MenuDetailModule'},
    {path:'research',loadChildren:'./modules/research/research.module#ResearchModule'},
    {path:'calender',loadChildren:'./modules/calender/calender.module#CalenderModule'},
    {path:'student-corner',loadChildren:'./modules/student-corner/student-corner.module#StudentCornerModule'},
    {path:'gallery',loadChildren:'./modules/gallery/gallery.module#GalleryModule'},
    {path:'blog',loadChildren:'./modules/blog/blog.module#BlogModule'},
    {path:'media',loadChildren:'./modules/media/media.module#MediaModule'},
    {path:'landing-page',loadChildren:'./modules/landing-page/landing-page.module#LandingPageModule'},
    {path:'wp-content/uploads/2020',loadChildren:'./modules/wp-content/wp-content.module#WpContentModule'},
    {path:'important-links',loadChildren:'./modules/important/important.module#ImportantModule'},
    {path:'e-contents',loadChildren:'./modules/e-content/e-content.module#EContentModule'},
    {path:'distinct-area-of-performance',component:PerformanceComponent},
    {path:'distinct-area-of-performance17-18',component:SecondPerformanceComponent},
    {path:'best-practices2019-20',component:BestPracticeComponent},
    {path:'best-practices2017-18',component:SecondBestPracticeComponent},
    {path:'course',component:CourseComponent},
    {path:'apply-form',component:ApplyFormComponent},
    {path:'enquiry-form',component:EnquiryComponent},
    {path:'not-found',component:NotFoundComponent},
    {path:'**',redirectTo:'/not-found',pathMatch:'full'}
]
@NgModule({
    imports:[RouterModule.forRoot(routes,routerOptions)],
    exports:[RouterModule]
})
export class AppRoutingModule{}