import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1224-seventeen',
  templateUrl: './c1224-seventeen.component.html',
  styleUrls: ['./c1224-seventeen.component.scss']
})
export class C1224SeventeenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
        title: 'Communication Skills Certificates',
        link: this.c3321Url + '1.2.2.4_Communication Skills Certificates.pdf'
    },
    {
        title: 'English and Soft Skills Certificates',
        link: this.c3321Url + '1.2.2.4_English and Soft Skills Certificates.pdf'
    },
    {
        title: 'Human Value Certificates',
        link: this.c3321Url + '1.2.2.4_Human Value Certificates.pdf'
    },
    {
        title: 'Presentation Skills Certificates',
        link: this.c3321Url + '1.2.2.4_Presentation Skills Certificates.pdf'
    },
    {
        title: 'Yoga Certificates',
        link: this.c3321Url + '1.2.2.4_Yoga Certificates.pdf'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
