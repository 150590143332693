<div id="first">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                   
                    <div class="card-body">
                        
                        <iframe src="https://drive.google.com/file/d/1uI37HCvfC3j16GGU6zt4pa66uFMfs8dz/preview" width="100%" height="1100px" allow="autoplay"></iframe>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>