import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $:any;
@Component({
  selector: 'app-ugc',
  templateUrl: './ugc.component.html',
  styleUrls: ['./ugc.component.scss']
})
export class UgcComponent implements OnInit {

  ugc:{name:string,title:string,creteria:string,detail:string}[];
  constructor(private courseService:CourseService) { }

  ngOnInit(): void {
    this.ugc=this.courseService.getUgc();
  }

  setModal(i:number){
    $('#courseModelTitle').html(this.ugc[i].name);
    $('.title').html(this.ugc[i].title);
    $('.creteria1').html(this.ugc[i].creteria);
    $('.sem1').html(this.ugc[i].detail);
  }
}
