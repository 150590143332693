import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {
  iiqa:{title:string,url:string}[]=[
    {
      title:'2017-18',
      url:'https://drive.google.com/file/d/1XkMdZp_awN7xFhLex879fjf04PNzbhGv/view?usp=sharing'
    },
    {
      title:'2018-19',
      url:'https://drive.google.com/file/d/1ZiKI-8kLaosrnYQq8GK6rjUhZYN0IYAe/view?usp=sharing'
    },
    {
      title:'2019-20',
      url:'https://drive.google.com/file/d/17KnYLtGNyZaZD4jKX24V1pLGbern6YHV/view?usp=sharing'
    },
    {
      title:'2020-21',
      url:'https://drive.google.com/file/d/1R1ibAE0To29HeSXc57Y9o51ef0IrNexn/view?usp=sharing'
    },
    {
      title:'2021-22',
      url:'https://drive.google.com/file/d/1mqLfuaLK02yYy7uKFR-urIQJ2qLXIOMK/view?usp=sharing'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
