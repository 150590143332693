import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowBlogService {
  private showBlog:boolean=true;
  blogChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showBlog=true;
    this.blogChange.next(true);
  }
  hide(){
    this.showBlog=false;
    this.blogChange.next(false);
  }
}
