// import { Component, OnInit } from '@angular/core';
// import { CourseService } from 'src/app/services/course/course.service';
// declare var $:any;
// @Component({
//   selector: 'app-commerce-course',
//   templateUrl: './commerce-course.component.html',
//   styleUrls: ['./commerce-course.component.scss']
// })
// export class CommerceCourseComponent implements OnInit {

//   courseMcom:{name:string,sem1:string,sem2:string,title:string,creteria1:string,creteria2:string}[];
//   courseBsc:{name:string,sem1:string,sub:string[],sem2:string[],title:string,creteria1:string,creteria2:string,comp:string[]}[];
//   BSCpo:string[]=[];
//   constructor(private courseService:CourseService) { }

//   ngOnInit(): void {
//     this.courseMcom=this.courseService.getCourseCreteria();
//     this.courseBsc=this.courseService.getCourseBsc();
//     this.BSCpo=this.courseService.getBSCpo();
//   }

//   setModal(i:number){
    
//     $('.sem2b').hide();
//     $('.comp-detail').hide();
//     $('.po-pso').hide();
//     let modal=document.getElementById('courseModelTitle');
//     modal.innerHTML=this.courseMcom[i].name;
//     let title=document.querySelector('.title');
//     title.innerHTML=this.courseMcom[i].title;
//     let cretera1=document.querySelector('.creteria1');
//     cretera1.innerHTML=this.courseMcom[i].creteria1;
//     let cretera2=document.querySelector('.creteria2');
//     cretera2.innerHTML=this.courseMcom[i].creteria2;
//     let sem1=document.querySelector('.sem1');
//     sem1.innerHTML=this.courseMcom[i].sem1;
//     let sem2=document.querySelector('.sem2');
//     sem2.innerHTML=this.courseMcom[i].sem2;
    
//   }

//   setBModal(i:number){
    
//     $('.sem2b').show();
//     $('.comp-detail').show();
//     $('.po-pso').show();
//     let modal=document.getElementById('courseModelTitle');
//     modal.innerHTML=this.courseBsc[i].name;
//     let title=document.querySelector('.title');
//     title.innerHTML=this.courseBsc[i].title;
//     let cretera1=document.querySelector('.creteria1');
//     cretera1.innerHTML=this.courseBsc[i].creteria1;
    
    
//     let cretera2=document.querySelector('.creteria2');
//     cretera2.innerHTML=this.courseBsc[i].creteria2;
//     let sem1=document.querySelector('.sem1');
//     sem1.innerHTML=this.courseBsc[i].sem1;
//     let sem2=document.querySelector('.sem2');
//     sem2.innerHTML='';
//     let sem2b=document.querySelector('.sem2b');
//     let sem2b1=document.querySelector('.sem2b1');
//     sem2b1.innerHTML=this.courseBsc[i].sem2[0];
//     let sem2b2=document.querySelector('.sem2b2');
//     sem2b2.innerHTML=this.courseBsc[i].sem2[1];
//     let sem2b3=document.querySelector('.sem2b3');
//     sem2b3.innerHTML=this.courseBsc[i].sem2[2];
    
//     let comp=document.querySelector('.comp-heading');
//     comp.innerHTML=this.courseBsc[i].comp[0];
//     let compDetail=document.querySelector('.comp-detail');
//     let detail1=document.querySelector('.comp-detail1');
//     detail1.innerHTML=this.courseBsc[i].comp[1];
//     let detail2=document.querySelector('.comp-detail2');
//     detail2.innerHTML=this.courseBsc[i].comp[2];
//     let po=document.querySelector('.po-pso');
//     po.innerHTML=this.BSCpo[i];
//   }
// }
import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-commerce-course',
  templateUrl: './commerce-course.component.html',
  styleUrls: ['./commerce-course.component.scss']
})
export class CommerceCourseComponent implements OnInit {

  courseMcom: { name: string, sem1: string, sem2: string, title: string, creteria1: string, creteria2: string }[];
  courseBsc: { name: string, sem1: string, sub: string[], sem2: string[], title: string, creteria1: string, creteria2: string, comp: string[] }[];
  BSCpo: string[] = [];
  
  modalTitle: string;
  title: SafeHtml;
  creteria1: SafeHtml;
  creteria2: SafeHtml;
  sem1: SafeHtml;
  sem2: SafeHtml;
  sub: SafeHtml[];
  sem2b: SafeHtml[];
  compHeading: SafeHtml;
  compDetail: SafeHtml[];
  poPso: SafeHtml;

  constructor(private courseService: CourseService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.courseMcom = this.courseService.getCourseCreteria();
    this.courseBsc = this.courseService.getCourseBsc();
    this.BSCpo = this.courseService.getBSCpo();
  }

  setModal(i: number) {
    this.resetModalFields();
    const course = this.courseMcom[i];
    this.modalTitle = course.name || '';
    this.title = this.sanitize(course.title || '');
    this.creteria1 = this.sanitize(course.creteria1 || '');
    this.creteria2 = this.sanitize(course.creteria2 || '');
    this.sem1 = this.sanitize(course.sem1 || '');
    this.sem2 = this.sanitize(course.sem2 || '');
  }

  setBModal(i: number) {
    this.resetModalFields();
    const course = this.courseBsc[i];
    this.modalTitle = course.name || '';
    this.title = this.sanitize(course.title || '');
    this.creteria1 = this.sanitize(course.creteria1 || '');
    this.creteria2 = this.sanitize(course.creteria2 || '');
    this.sem1 = this.sanitize(course.sem1 || '');
    this.sem2 = this.sanitize(''); // Ensuring sem2 is an empty string if not provided
    this.sub = course.sub.length > 0 ? course.sub.map(sub => this.sanitize(sub)) : [];
    this.sem2b = course.sem2.length > 0 ? course.sem2.map(sem => this.sanitize(sem)) : [];
    this.compHeading = course.comp.length > 0 ? this.sanitize(course.comp[0]) : '';
    this.compDetail = course.comp.length > 1 ? course.comp.slice(1).map(comp => this.sanitize(comp)) : [];
    this.poPso = this.sanitize(this.BSCpo[i] || '');
  }

  resetModalFields() {
    this.modalTitle = '';
    this.title = '';
    this.creteria1 = '';
    this.creteria2 = '';
    this.sem1 = '';
    this.sem2 = '';
    this.sub = [];
    this.sem2b = [];
    this.compHeading = '';
    this.compDetail = [];
    this.poPso = '';
  }

  sanitize(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content || '');
  }
}
