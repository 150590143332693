<div id="research">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Research</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Research</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Research</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/research/research-centre" (click)="checkRoute()" (click)="changeRoute('Research Centre')">Research Centre</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/major-research-project" (click)="checkRoute()" (click)="changeRoute('Major Research Projects')">Major Research Projects</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/minor-research-project" (click)="checkRoute()" (click)="changeRoute('Minor Research Projects')">Minor Research Projects</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/student-project" (click)="checkRoute()" (click)="changeRoute('Student Projects')">Student's Projects</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/publication" (click)="checkRoute()" (click)="changeRoute('Publications')">Publications</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/Seminar-conference" (click)="checkRoute()" (click)="changeRoute('Seminar/ Conferences')">Seminar/ Conferences</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/workshops" (click)="checkRoute()"  (click)="changeRoute('Workshops')">Workshops</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/guest-lecture" (click)="checkRoute()" (click)="changeRoute('Guest Lectures')">Guest Lectures</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/fdps" (click)="checkRoute()" (click)="changeRoute('FDPs')">FDPs</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/scholarship" (click)="checkRoute()" (click)="changeRoute('Scholarship')">Scholarship</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/result" (click)="checkRoute()" (click)="changeRoute('Results')">Results</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/pos-cos" (click)="checkRoute()" (click)="changeRoute('POs & COs')">PO's & CO's</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/research/mou" (click)="checkRoute()" (click)="changeRoute('Functional MOUs')">Functional MOU's</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>