import { Component, OnInit } from '@angular/core';
import ScrollOut from "scroll-out";
declare var $:any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    ScrollOut({  
      threshold: .2,
      once: true
    });
  }
 



}
