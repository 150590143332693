import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import ScrollOut from "scroll-out";
@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss']
})
export class WhyComponent implements OnInit,AfterContentInit,OnDestroy {
  so: any;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterContentInit(){
    this.so = ScrollOut({
      onShown: function(el) {
        // remove the class
        el.classList.remove('animated');
    
        // force reflow
        void el.offsetWidth;
    
        // re-add the animated cl
        el.classList.add('animated');
      }
    });
  }
  ngOnDestroy(){
    this.so.teardown();
  }

}
