import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-three-three-two-one',
  templateUrl: './c3-three-three-two-one.component.html',
  styleUrls: ['./c3-three-three-two-one.component.scss']
})
export class C3ThreeThreeTwoOneComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 3/3.3.2.1 Books _Chapter_SSR_Final (Upload and link Required).pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
