<div id="open-access">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Open Access e-Resources</h4>
                    <div class="card-body">
                       
                        <h5 class="title">Open Access Books/Publishing Directories</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Directory of Open Access Books (DOAB) <a href="http://doabooks.org" target="_blank">http://doabooks.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Directory of Open Access Journals (DOAJ) <a href="http://www.doaj.org" target="_blank">http://www.doaj.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Free Medical Journals  <a href="http://www.freemedicaljournals.com" target="_blank">http://www.freemedicaljournals.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Institutional Archives Registry <a href="http://roar.eprints.org" target="_blank">http://roar.eprints.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Access Directory <a href="http://oad.simmons.edu/oadwiki/Main_Page" target="_blank">http://oad.simmons.edu/oadwiki/Main_Page</a></p>
                            </li>
                            <li>
                                <p class="lead ">DOAR <a href="http://www.opendoar.org" target="_blank">http://www.opendoar.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">ROAR <a href="http://roarmap.eprints.org" target="_blank">http://roarmap.eprints.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">ROAD <a href="http://road.issn.org" target="_blank">http://road.issn.org</a></p>
                            </li>
                        </ul>

                        <h5 class="title">Open Access Search Engines</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">BASE: Bielefeld Academic Search Engine <a href="http://www.base-search.net" target="_blank">http://www.base-search.net</a></p>
                            </li>
                            <li>
                                <p class="lead ">CiteSeerX <a href="http://citeseerx.ist.psu.edu" target="_blank">http://citeseerx.ist.psu.edu</a></p>
                            </li>
                            <li>
                                <p class="lead ">Core: Connecting Repositories  <a href="http://core.kmi.open.ac.uk/search" target="_blank">http://core.kmi.open.ac.uk/search</a></p>
                            </li>
                            <li>
                                <p class="lead ">Google Scholar <a href="http://scholar.google.co.in" target="_blank">http://scholar.google.co.in</a></p>
                            </li>
                            <li>
                                <p class="lead ">Microsoft Academic Search <a href="http://academic.research.microsoft.com" target="_blank">http://academic.research.microsoft.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">OAIster (WorldCat) <a href="http://oaister.worldcat.org" target="_blank">http://oaister.worldcat.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">OAJSE <a href="http://www.oajse.com" target="_blank">http://www.oajse.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">JURN <a href="http://www.jurn.org" target="_blank">http://www.jurn.org</a></p>
                            </li>
                        </ul>
                      
                        <h5 class="title">Indian Open Access Journals</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">CSIR-NISCAIR Journals <a href="http://www.niscair.res.in/periodicals/researchjournals" target="_blank">http://www.niscair.res.in/periodicals/researchjournals</a></p>
                            </li>
                            <li>
                                <p class="lead ">Indian Academy of Science <a href="http://www.ias.ac.in/Journals/Overview" target="_blank">http://www.ias.ac.in/Journals/Overview</a></p>
                            </li>
                            <li>
                                <p class="lead ">Medknow Journals  <a href="http://www.medknow.com" target="_blank">http://www.medknow.com</a></p>
                            </li>
                           
                        </ul>
                        <h5 class="title">Open Access Journal Publishers</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Biomed Central <a href="http://www.biomedcentral.com" target="_blank">http://www.biomedcentral.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Computational psychiatry <a href="http://computationalpsychiatry.org" target="_blank">http://computationalpsychiatry.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Intech  <a href="http://www.intechopen.com" target="_blank">http://www.intechopen.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Journal Springer Open <a href="http://journal.chemistrycentral.com" target="_blank">http://journal.chemistrycentral.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Network Neuroscience <a href="http://www.mitpressjournals.org/netn" target="_blank">http://www.mitpressjournals.org/netn</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Mind <a href="http://open-mind.net" target="_blank">http://open-mind.net</a></p>
                            </li>
                            <li>
                                <p class="lead ">PLoS <a href="http://www.plos.org" target="_blank">http://www.plos.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">PeerJ <a href="https://peerj.com" target="_blank">https://peerj.com</a></p>
                            </li>
                        </ul>

                        <h5 class="title">Open Access E-books</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Alex Catalogue of Electronic Texts <a href="http://infomotions.com/alex" target="_blank">http://infomotions.com/alex</a></p>
                            </li>
                            <li>
                                <p class="lead ">Amedeo Challenge <a href="http://www.amedeochallenge.com" target="_blank">http://www.amedeochallenge.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Authorama <a href="http://www.authorama.com" target="_blank">http://www.authorama.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Bartleby <a href="http://www.bartleby.com" target="_blank">http://www.bartleby.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">DOAB: Directory of Open Access Books <a href="http://doabooks.org" target="_blank">http://doabooks.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">eBooks@Adelaide <a href="https://ebooks.adelaide.edu.au" target="_blank">https://ebooks.adelaide.edu.au</a></p>
                            </li>
                            <li>
                                <p class="lead ">OAPEN <a href="http://www.oapen.org/home" target="_blank">http://www.oapen.org/home</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Library <a href="https://openlibrary.org" target="_blank">https://openlibrary.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">InTech open Books <a href="http://www.intechopen.com/books" target="_blank">http://www.intechopen.com/books</a></p>
                            </li>
                            <li>
                                <p class="lead ">Project Gutenberg <a href="http://www.gutenberg.org" target="_blank">http://www.gutenberg.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Read Print  <a href="http://www.readprint.com" target="_blank">http://www.readprint.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Rare Book <a href="http://www.rarebookroom.org" target="_blank">http://www.rarebookroom.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">The American School of Classical Studies at Athens (ASCSA) <a href="http://www.ascsa.edu.gr/index.php/publications/open-access-ebooks" target="_blank">http://www.ascsa.edu.gr/index.php/publications/open-access-ebooks</a></p>
                            </li>
                            <li>
                                <p class="lead ">The Universal Digital Library Million Book Collection <a href="http://ulib.isri.cmu.edu" target="_blank">http://ulib.isri.cmu.edu</a></p>
                            </li>
                        </ul>
                         
                        <h5 class="title">Open Textbook Projects/ Portals</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Openstax <a href="https://openstax.org/" target="_blank">https://openstax.org/</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Textbook Library <a href="https://open.umn.edu/opentextbooks" target="_blank">https://open.umn.edu/opentextbooks</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open SUNY Textbooks <a href="https://milneopentextbooks.org/" target="_blank">https://milneopentextbooks.org/</a></p>
                            </li>
                            <li>
                                <p class="lead ">MIT OpenCourseWare: Online Textbooks <a href="https://ocw.mit.edu/courses/onlinetextbooks/" target="_blank">https://ocw.mit.edu/courses/onlinetextbooks/</a></p>
                            </li>
                            <li>
                                <p class="lead ">Luminos (University of California Press) <a href="https://www.luminosoa.org/" target="_blank">https://www.luminosoa.org/</a></p>
                            </li>
                            <li>
                                <p class="lead ">BCcampus: Open Textbook Collection <a href="https://open.bccampus.ca/browse-ourcollection/find-open-textbooks/" target="_blank">https://open.bccampus.ca/browse-ourcollection/find-open-textbooks/</a></p>
                            </li>
                            <li>
                                <p class="lead ">eCampusOntario Open Library <a href="https://openlibrary.ecampusontario.ca/" target="_blank">https://openlibrary.ecampusontario.ca/</a></p>
                            </li>
                            <li>
                                <p class="lead ">Merlot <a href="https://www.merlot.org/merlot/" target="_blank">https://www.merlot.org/merlot/</a></p>
                            </li>
                            <li>
                                <p class="lead ">UCL Press Open Access Books <a href="https://www.uclpress.co.uk/collections/open-access" target="_blank">https://www.uclpress.co.uk/collections/open-access</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Book Publishers <a href="https://www.openbookpublishers.com/" target="_blank">https://www.openbookpublishers.com/</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Textbook Initiative (Australia) <a href="https://emedia.rmit.edu.au/oer/" target="_blank">https://emedia.rmit.edu.au/oer/</a></p>
                            </li>
                            <li>
                                <p class="lead ">LibreTexts: Free the Textbook <a href="https://libretexts.org/" target="_blank">https://libretexts.org/</a></p>
                            </li>
                            <li>
                                <p class="lead ">200 Free Textbooks: A Meta Collection <a href="https://www.openculture.com/free_textbooks" target="_blank">https://www.openculture.com/free_textbooks</a></p>
                            </li>
                            <li>
                                <p class="lead ">Intechopen <a href="https://www.intechopen.com/" target="_blank">https://www.intechopen.com/</a></p>
                            </li>
                            <li>
                                <p class="lead ">MIT open courseware <a href="https://ocw.mit.edu/index.htm" target="_blank">https://ocw.mit.edu/index.htm</a></p>
                            </li>
                            <li>
                                <p class="lead ">Saylor <a href="https://www.saylor.org/" target="_blank">https://www.saylor.org/</a></p>
                            </li>
                            <li>
                                <p class="lead ">GALILEO Open Learning Materials <a href="https://oer.galileo.usg.edu/all-textbooks/" target="_blank">https://oer.galileo.usg.edu/all-textbooks/</a></p>
                            </li>
                            <li>
                                <p class="lead ">NOBA <a href="https://nobaproject.com/" target="_blank">https://nobaproject.com/</a></p>
                            </li>
                        </ul>

                        <h5 class="title">Open Access Standard Sources</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">ECMA International open standards <a href="http://www.ecma-international.org/publications/standards/Standard.htm" target="_blank">http://www.ecma-international.org/publications/standards/Standard.htm</a></p>
                            </li>
                            <li>
                                <p class="lead ">OASIS Open standards <a href="https://www.oasis-open.org/standards" target="_blank">https://www.oasis-open.org/standards</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Geospatial Consortium  <a href="http://www.opengeospatial.org/standards/is" target="_blank">http://www.opengeospatial.org/standards/is</a></p>
                            </li>
                            <li>
                                <p class="lead ">World Wide Web Consortium Open Standard  <a href="http://open-stand.org" target="_blank">http://open-stand.org</a></p>
                            </li>
                        </ul>
                        <h5 class="title">Open Access Patent Sources</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">AusPat <a href="http://pericles.ipaustralia.gov.au/ols/auspat/quickSearch.do" target="_blank">http://pericles.ipaustralia.gov.au/ols/auspat/quickSearch.do</a></p>
                            </li>
                            <li>
                                <p class="lead ">Esp@cenet <a href="http://ep.espacenet.com" target="_blank">http://ep.espacenet.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Indian Patent Office  <a href="http://ipindiaservices.gov.in/publicsearch" target="_blank">http://ipindiaservices.gov.in/publicsearch</a></p>
                            </li>
                            <li>
                                <p class="lead ">US Patent and Trademark Office (PTO)  <a href="http://patft.uspto.gov" target="_blank">http://patft.uspto.gov</a></p>
                            </li>
                        </ul>
                        <h5 class="title">Open Access ETD Sources</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Australian University Theses & Dissertations <a href="http://trove.nla.gov.au/result?q=+&lformat=Thesis" target="_blank">http://trove.nla.gov.au/result?q=+&lformat=Thesis</a></p>
                            </li>
                            <li>
                                <p class="lead ">DiVA portal <a href="http://www.diva-portal.org/smash/search.jsf" target="_blank">http://www.diva-portal.org/smash/search.jsf</a></p>
                            </li>
                            <li>
                                <p class="lead ">NDTLD  <a href="http://www.ndltd.org" target="_blank">http://www.ndltd.org</a></p>
                            </li>
                        </ul>
                        <h5 class="title">Open Access Theses and Dissertations</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">OATD <a href="http://oatd.org" target="_blank">http://oatd.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">PQDT OPEN: ProQuest Digital Theses <a href="http://pqdtopen.proquest.com" target="_blank">http://pqdtopen.proquest.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">ShodhGanga, India  <a href="http://shodhganga.inflibnet.ac.in" target="_blank">http://shodhganga.inflibnet.ac.in</a></p>
                            </li>
                            <li>
                                <p class="lead ">Theses Canada (Library and Archives Canada)  <a href="http://www.collectionscanada.gc.ca/thesescanada/index-e.html" target="_blank">http://www.collectionscanada.gc.ca/thesescanada/index-e.html</a></p>
                            </li>
                        </ul>

                        <h5 class="title">Archives</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">ArXiv.org <a href="http://arxiv.org" target="_blank">http://arxiv.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">CERN Document Server <a href="http://cds.cern.ch" target="_blank">http://cds.cern.ch</a></p>
                            </li>
                            <li>
                                <p class="lead ">Cogprints  <a href="http://cogprints.org" target="_blank">http://cogprints.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Cryptology ePrint Archive  <a href="https://eprint.iacr.org" target="_blank">https://eprint.iacr.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Eprint Network <a href="http://www.osti.gov/eprints" target="_blank">http://www.osti.gov/eprints</a></p>
                            </li>
                            <li>
                                <p class="lead ">HAL-SHS Archive of scientific literature <a href="https://halshs.archives-ouvertes.fr/search/index" target="_blank">https://halshs.archives-ouvertes.fr/search/index</a></p>
                            </li>
                            <li>
                                <p class="lead ">HAL open archive Scholarly documents  <a href="https://hal.archives-ouvertes.fr/search/index" target="_blank">https://hal.archives-ouvertes.fr/search/index</a></p>
                            </li>
                            <li>
                                <p class="lead ">Institut Nicod Eprints Archive  <a href="http://jeannicod.ccsd.cnrs.fr/search" target="_blank">http://jeannicod.ccsd.cnrs.fr/search</a></p>
                            </li>
                            <li>
                                <p class="lead ">MédiHal open archive of scientific images  <a href="https://medihal.archives-ouvertes.fr" target="_blank">https://medihal.archives-ouvertes.fr</a></p>
                            </li>
                            <li>
                                <p class="lead ">Organic eprints <a href="http://orgprints.org" target="_blank">http://orgprints.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">RePEc (Research Papers in Economics)  <a href="http://repec.org" target="_blank">http://repec.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Solar Physics E-Print Archive  <a href="http://solar.physics.montana.edu/cgibin/eprint/default_page.pl" target="_blank">http://solar.physics.montana.edu/cgibin/eprint/default_page.pl</a></p>
                            </li>
                        </ul>

                        <h5 class="title">Open Education Courseware</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Carnegie Mellon <a href="http://oli.cmu.edu/learn-with-oli/see-our-free-open-courses" target="_blank">http://oli.cmu.edu/learn-with-oli/see-our-free-open-courses</a></p>
                            </li>
                            <li>
                                <p class="lead ">Johns Hopkins OpenCourseWare <a href="http://ocw.jhsph.edu" target="_blank">http://ocw.jhsph.edu</a></p>
                            </li>
                            <li>
                                <p class="lead ">MIT Open Courseware (OCW)  <a href="http://ocw.mit.edu" target="_blank">http://ocw.mit.edu</a></p>
                            </li>
                            <li>
                                <p class="lead ">Notre Dame  <a href="http://ocw.nd.edu" target="_blank">http://ocw.nd.edu</a></p>
                            </li>
                            <li>
                                <p class="lead ">NPTEL <a href="http://nptel.ac.in" target="_blank">http://nptel.ac.in</a></p>
                            </li>
                            <li>
                                <p class="lead ">OpenMichigan <a href="http://open.umich.edu/education" target="_blank">http://open.umich.edu/education</a></p>
                            </li>
                            <li>
                                <p class="lead ">Open Yale Courses  <a href="http://oyc.yale.edu/courses" target="_blank">http://oyc.yale.edu/courses</a></p>
                            </li>
                            <li>
                                <p class="lead ">Oxford Mathematics OpenCourseWare  <a href="https://courses-archive.maths.ox.ac.uk/" target="_blank">https://courses-archive.maths.ox.ac.uk/</a></p>
                            </li>
                           
                        </ul>

                        <h5 class="title">Consortium of Open Courseware (OCW)</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">GUIDE – Global Universities in Distance Education <a href="http://www.guideassociation.org" target="_blank">http://www.guideassociation.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">ISSUU: Open Course Ware Consortium <a href="http://issuu.com/ocwconsortium" target="_blank">http://issuu.com/ocwconsortium</a></p>
                            </li>
                            <li>
                                <p class="lead ">OCW Consortium Europe  <a href="http://www.opencourseware.eu" target="_blank">http://www.opencourseware.eu</a></p>
                            </li>
                            <li>
                                <p class="lead ">Ocw Universia Consortium  <a href="https://www.oeconsortium.org/members/view/215/" target="_blank">https://www.oeconsortium.org/members/view/215/</a></p>
                            </li>
                            <li>
                                <p class="lead ">Social Science Research Network <a href="https://www.ssrn.com/en" target="_blank">https://www.ssrn.com/en</a></p>
                            </li>
                            
                        </ul>
                        <h5 class="title">Open Online Learning Tools</h5>
                        <hr class="pb-1 rounded mt-0">
                        <ul>
                            <li>
                                <p class="lead ">Codecademy <a href="http://www.codecademy.com" target="_blank">http://www.codecademy.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Coursera <a href="https://www.coursera.org" target="_blank">https://www.coursera.org</a></p>
                            </li>
                            <li>
                                <p class="lead ">Duolingo  <a href="https://www.duolingo.com" target="_blank">https://www.duolingo.com</a></p>
                            </li>
                            <li>
                                <p class="lead ">Edx  <a href="https://www.edx.org" target="_blank">https://www.edx.org</a></p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>