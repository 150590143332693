import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c3-three-two-two',
  templateUrl: './c3-three-two-two.component.html',
  styleUrls: ['./c3-three-two-two.component.scss']
})
export class C3ThreeTwoTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 3/3.2.2/13 Workshop on IPR and its role in Research ( 11.5.22).pdf', 
    '/assets/pdf/NAAC/Criterion 3/3.2.2/12 Strategic Gateway to Entrepreneurship Development (07.05.22)1.pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/11 National Webinar on Entrepreneurial Development for Young Students (22.9.21).pdf', 
    '/assets/pdf/NAAC/Criterion 3/3.2.2/10  ENTREPRENEURISHIP- As a Career opportunity ( 18.06.21).pdf', 
    '/assets/pdf/NAAC/Criterion 3/3.2.2/9 Promoting Academic Integrity and Prevention of Plagiarism in HEIs (30.04.21).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/8 One Week State Level FDP on -How to Prepare SSR and AQAR for NAAC  (27 Jan-2 Feb 2021)1.pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/7 Webinar on world earth day ( 22.4.21).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/6 National Workshop on IPR- Industry Academia-Academia Interface ( 04.12.20).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/5 National Web-seminar on Research Methodology (28.05.20).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/4 National Webinar on Entrepreneurial Skills (26.5.20).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/3 National workshop on IPR-Awareness and issues in academic Research (13.2.20).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/2 Industry Academia Interface on Industry Perspectives and Insights (30.07.2019).pdf',
    '/assets/pdf/NAAC/Criterion 3/3.2.2/1 Industry Academia Interface With Eclex (26.07.20).pdf', ];
  src: string;
  constructor() { }

  isLoading = false;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string){
    console.log(id);
    if(id==='1'){
      $('.one').addClass('show');
      $('.six').removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $('.thirteen').removeClass('show');
      this.src = this.pdfSrc[0];
    }
    if(id==='2'){
      $('.two').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.six').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[1];
    }
    if(id==='3'){
      $('.three').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.six').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[2];
    }
    if(id==='4'){
      $('.four').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.six').removeClass('show');
      $('.five').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[3];
    }
    if(id==='5'){
      $('.five').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[4];
    }
    if(id==='6'){
      $('.six').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[5];
    }
    if(id==='7'){
      $('.seven').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[6];
    }
    if(id==='8'){
      $('.eight').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[7];
    }
    if(id==='9'){
      $('.nine').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[8];
    }
    if(id==='10'){
      $('.ten').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[9];
    }
    if(id==='11'){
      $('.eleven').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.twelve').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[10];
    }
    if(id==='12'){
      $('.twelve').addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $(`thirteen`).removeClass('show');
      this.src = this.pdfSrc[11];
    }
    if(id==='13'){
      $(`thirteen`).addClass('show');
      $(`#pdf1`).removeClass('show');
      $('.two').removeClass('show');
      $('.three').removeClass('show');
      $('.four').removeClass('show');
      $('.five').removeClass('show');
      $('.six').removeClass('show');
      $('.seven').removeClass('show');
      $('.eight').removeClass('show');
      $('.nine').removeClass('show');
      $('.ten').removeClass('show');
      $('.eleven').removeClass('show');
      $('.twelve').removeClass('show');
      this.src = this.pdfSrc[12];
    }
    
  }

}
