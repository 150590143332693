import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NaacService } from 'src/app/services/naac/naac.service';
import { ShowNaacService } from 'src/app/services/showNaac/show-naac.service';
declare var $:any;
@Component({
  selector: 'app-naac-item',
  templateUrl: './naac-item.component.html',
  styleUrls: ['./naac-item.component.scss']
})
export class NaacItemComponent implements OnInit,OnDestroy {
  id:string;
  sub:Subscription;
  urlPath='';
  constructor(private showNaacService:ShowNaacService,
    private route:ActivatedRoute,
    private naacService:NaacService,
    private router:Router) { 
    showNaacService.hide();
  }

  ngOnInit(): void {
    this.sub=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        const path=this.naacService.searchNaac(this.id);
        if(path){
          if(path.includes('view?usp=sharing')){
            this.urlPath=path.replace('view?usp=sharing','preview');
            $('#naacItem').attr('src', this.urlPath);
            this.urlPath=path;
          }else{
            this.urlPath=path;
            $('#naacItem').attr('src', path);
          
          }
        }else{
          this.router.navigate(['not-found']);
        }
        
        
      }
    )
  }

  ngOnDestroy(): void {
      this.showNaacService.show();
  }
}
