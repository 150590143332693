import { Component, OnInit } from '@angular/core';
declare var $:any
@Component({
  selector: 'app-ct-six-two-two',
  templateUrl: './ct-six-two-two.component.html',
  styleUrls: ['./ct-six-two-two.component.scss']
})
export class CtSixTwoTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/6.2.2.1_Screen shots of ERP.pdf',
    '/assets/pdf/NAAC/DVV/6.2.2.2_ ERP documents.pdf',
    '/assets/pdf/NAAC/DVV/6.2.2.3_Institutional expenditure.pdf'];
  src: string;

  isLoading = true;
  heading1 = false;
  heading2 = false;
  heading3=false;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  
  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      this.heading3=false;
      this.heading1 = true;
      this.heading2 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      this.heading3=false;
      this.heading1 = false;
      this.heading2 = true;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).addClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3=true;
      this.src = this.pdfSrc[2];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
