import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaborative',
  templateUrl: './collaborative.component.html',
  styleUrls: ['./collaborative.component.scss']
})
export class CollaborativeComponent implements OnInit {
  activity:{title:string,link:string}[]=[
    {
      title:'Biotech 2021-22',
      link:'https://gnkc.ac.in/menu-detail/naac/biotech2021-22'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
