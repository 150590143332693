<div id="team">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>NAAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">NAAC</a></li>
                <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">NAAC Peer
                    Team Report
                </li>
            </ol>
        </nav>
    </div>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>NAAC Peer Team Report</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a>NAAC Peer Team Report</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg py-4">

                                    <h5 class="card-title text-center ">NAAC Peer Team Report</h5>

                                    <div class="card1 mx-auto px-4" *ngIf="!showPdf">
                                        <div class="card-body">
                                            <form id="auth-form" #authForm="ngForm" (ngSubmit)="onSubmit(authForm) " style="display: block;"
                                                class="row g-3">
                                                <div class="mb-3">
                                                    <label for="password" class="form-label">Enter Password</label>
                                                    <input type="password" class="form-control" name="password"
                                                        id="password" ngModel required>
                                                </div>
                                                <div>
                                                    <button type="submit" class="btn btn-primary"
                                                        [disabled]="!authForm.valid">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div *ngIf="showPdf">
                                        <div class="container-fluid d-flex justify-content-center align-items-center h-100"
                                            id="loader" *ngIf="isLoading">
                                            <app-loader></app-loader>
                                        </div>
                                        <div id="detail" class="text-center">
                                            <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                                                id="list-view" (on-progress)="hideLoader()"
                                                (error)="hideLoader()"></pdf-viewer>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>