import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-unique',
  templateUrl: './unique.component.html',
  styleUrls: ['./unique.component.scss']
})
export class UniqueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.carousel').carousel({
      interval: 3000
    })
  }

}
