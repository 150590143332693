import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-five-one-three',
  templateUrl: './ct-five-one-three.component.html',
  styleUrls: ['./ct-five-one-three.component.scss']
})
export class CtFiveOneThreeComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';
  link:{name:string,src:string}[]=[
    {
      name:'5.1.3_Revised data template_1',
      src: this.c241Url + '5.1.3_Revised data template_1.pdf'
    },
    {
      name:'5.1.3_Revised data template with links_2',
      src: this.c241Url + '5.1.3_Revised data template with links_2.pdf'
    },
    {
      name:'5.1.3 Reports of each program with Photographs_3',
      src: this.c241Url + '5.1.3 Reports of each program with Photographs_3.pdf'
    },
    {
      name:'5.1.3_Attendance sheets with signature of students_5Years_6',
      src: this.c241Url + '5.1.3_Attendance sheets with signature of students_5Years_6.pdf'
    },
  ];
  selectedValue:{name:string,src:string};
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[0];

    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[2];
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.selectedValue=this.link[3];
    }
  }


}
