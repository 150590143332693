import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-three-four-two',
  templateUrl: './c3-three-four-two.component.html',
  styleUrls: ['./c3-three-four-two.component.scss']
})
export class C3ThreeFourTwoComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 3/3.4.2 Awards and Recognitions Received from Govt or Govt Recogn Bodies_SSR_final_2023.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }

}
