import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-three-two-two',
  templateUrl: './ct-three-two-two.component.html',
  styleUrls: ['./ct-three-two-two.component.scss']
})
export class CtThreeTwoTwoComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 5){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if((url.length === 6 && (url[5]==='2021-22' || url[5]==='2020-21' || url[5]==='2019-20'))){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
      this.showHeading = false;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
