import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.scss']
})
export class CountComponent implements OnInit,OnDestroy,AfterViewInit {
  id:any;
  constructor() {
   }

  ngOnInit(): void {
    // this.id= setInterval(function(){ 
    //   $('.counter').counterUp(
    //     {
    //       delay: 10,
    //       time: 1000,
    //       offset: 70,
    //       beginAt: 100,
          
    //       }
    //   );
    //  }, 6000);
     
    
   
      
    
  
}
ngAfterViewInit(){
  // setTimeout(()=>{
  //   var a = 0;
  //   $(document).ready(function(){
  //     $(window).scroll(function() {
  //       if(!this.executed){
          
  //         var oTop = $('#counter').offset().top - window.innerHeight;
  //       if (a == 0 && $(window).scrollTop() > oTop) {
  //         $('.counter-value').each(function() {
  //           var $this = $(this),
  //             countTo = $this.attr('data-count');
  //           $({
  //             countNum: $this.text()
  //           }).animate({
  //               countNum: countTo
  //             },
    
  //             {
    
  //               duration: 2000,
  //               easing: 'swing',
  //               step: function() {
  //                 $this.text(Math.floor(this.countNum));
  //               },
  //               complete: function() {
  //                 $this.text(this.countNum);
  //                 //alert('finished');
  //               }
    
  //             });
  //         });
  //         a = 1;
          
          
  //       }
  //       }
        
    
  //     });
  //   });
  // });
}

  
ngOnDestroy(){
  // clearInterval(this.id);
}
}
