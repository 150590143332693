import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c7-seven-one-four',
  templateUrl: './c7-seven-one-four.component.html',
  styleUrls: ['./c7-seven-one-four.component.scss']
})
export class C7SevenOneFourComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-7/7.1.4_Code of Ethics and conduct for staff and students.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/7.1.4/7.1.4_A_Institutional initiatives for inclusive environment.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/7.1.4/7.1.4_B_Sensitization of students and employee to the constitutional obligation.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/7.1.4/7.1.4_C_Human values_Rights_Sensitization values based addon courses.pdf',];
  src: string;

  isLoading = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[0];
      this.showHeading = true;
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[1];
      this.showHeading = false;
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[2];
      this.showHeading = false;
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[3];
      this.showHeading = false;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
