import { NgModule } from "@angular/core";
import { ExtraOptions, Route, RouterModule } from "@angular/router";
import { AboutUsComponent } from "src/app/view/about-us/about-us.component";
import { CommitteeComponent } from "src/app/view/about-us/committee/committee.component";
import { GoverningComponent } from "src/app/view/about-us/governing/governing.component";
import { MessagesComponent } from "src/app/view/about-us/messages/messages.component";
import { OrganogramComponent } from "src/app/view/about-us/organogram/organogram.component";
import { PerspectiveComponent } from "src/app/view/about-us/perspective/perspective.component";
import { ProfileComponent } from "src/app/view/about-us/profile/profile.component";
import { RtiComponent } from "src/app/view/about-us/rti/rti.component";
import { StaffComponent } from "src/app/view/about-us/staff/staff.component";

const route:Route[]=[
    {path:'',component:AboutUsComponent,children:[
        {path:'profile',component:ProfileComponent},
        {path:'governing',component:GoverningComponent},
        {path:'messages',component:MessagesComponent},
        {path:'staff',component:StaffComponent},
        {path:'committee',component:CommitteeComponent},
        {path:'organogram',component:OrganogramComponent},
        {path:'rti',component:RtiComponent},
        {path:'perspective', component:PerspectiveComponent}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class AboutUsRoutingModule{}