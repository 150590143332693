<div id="activities">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Activities</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Activities</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Co-curricular Activities</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/ncc" (click)="checkRoute()" (click)="changeRoute('NCC')">NCC</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/nss" (click)="checkRoute()"
                            (click)="changeRoute('NSS')">NSS</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/sports" (click)="checkRoute()" (click)="changeRoute('Sports')">Sports</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/Women-cell" (click)="checkRoute()" (click)="changeRoute('Women Cell')">Women Cell</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/cultural" (click)="checkRoute()" (click)="changeRoute('Cultural Activities')">Cultural Activities</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/legal-literacy-cell" (click)="checkRoute()" (click)="changeRoute('Legal Literacy Cell')">Legal Literacy Cell</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/youth-red-cross" (click)="checkRoute()"  (click)="changeRoute('Youth Red Cross')">Youth Red Cross</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/eco-club" (click)="checkRoute()"
                            (click)="changeRoute('ECO Club')">ECO Club</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/adventure-club" (click)="checkRoute()" (click)="changeRoute('Adventure Club')">Adventure Club</a>
                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/activities/placement-cell" (click)="checkRoute()" (click)="changeRoute('Placement Cell')">Placement Cell</a>
                        </li> -->
                        <li routerLinkActive="active">
                            <a routerLink="/activities/community-services" (click)="checkRoute()" (click)="changeRoute('Community Services')">Community Services</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/gender" (click)="checkRoute()" (click)="changeRoute('Gender Equality')">Gender Equality</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/VISMAD" (click)="checkRoute()" (click)="changeRoute('VISMAD')">VISMAD</a>
                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/activities/social" (click)="changeRoute('Social Outreach Activities')">Social Outreach Activities</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/cross-cutting" (click)="changeRoute('Cross Cutting')">Cross Cutting</a>
                        </li> -->
                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>