import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grieveance',
  templateUrl: './grieveance.component.html',
  styleUrls: ['./grieveance.component.scss']
})
export class GrieveanceComponent implements OnInit {
  src: string ='/assets/pdf/Grieveance-1.pdf';
    constructor() { }

ngOnInit(): void {
}

}
