<div id="human">
  <div id="showcase">
    <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
  </div>
  <div id="heading">
    <h1>IQAC</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">IQAC</a></li>
        <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Human Values and
          Professional Ethics</li>
      </ol>
    </nav>
  </div>
  <div class="container-fluid ">
    <div class="row">
      <div class="col-md-3">
        <nav id="secondary-navigation">
          <!-- Header -->
          <header class="header">
            <h5>Human Values and Professional Ethics</h5>
          </header>
          <!-- /Header -->
          <ul>
            <li class="first">
              <a (click)="changeRoute('2022-23')">2022-23</a>
            </li>
            <li class="second">
              <a (click)="changeRoute('2021-22')">2021-22</a>
            </li>

          </ul>
        </nav>
      </div>
      <div class="col-md-9" *ngIf="show22">
        <div id="sports">
          <div class="container-fluid pdf">
            <div class="row">
              <div class="col-12">
                <div class="card shadow-lg">

                  <!-- <h5 class="card-title text-center"></h5> -->
                  <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


      <div class="col-md-9" *ngIf="!show22">
        <div id="sports">
          <div class="container-fluid pdf">
            <div class="card shadow-lg colorBlue mx-lg-5">
              <div class="row">
                <div class="col-12 text-center mt-5">
        
                  <h2>Human Values and Professional Ethics</h2>
        
                </div>
                <div class="card-body text-justify">
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">S.NO.</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of human; let i=index;">
                        <th scope="row">{{i+1}}</th>
                        <td class="title">{{data.title}}</td>
                        <td class="link"><a href="{{data.url}}" target="_blank">{{data.url}}</a> </td>
                      </tr>
        
                    </tbody>
                  </table>
                </div>
              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>