<div id="science">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Faculty</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item"><a routerLink="/faculty/teaching-staff">Faculty</a></li>
              <li class="breadcrumb-item"><a >Science</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Science</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/botony" (click)="changeRoute('Department Of Botony')">Department Of Botany</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/mathematics"
                            (click)="changeRoute('Department Of Mathematics')">Department Of Mathematics</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/physics" (click)="changeRoute('Department Of Physics')">Department Of Physics</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/computer-science" (click)="changeRoute('Department Of Computer Science')">Department Of Computer Science</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/zoology" (click)="changeRoute('Department Of Zoology')">Department Of Zoology</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/chemistry" (click)="changeRoute('Department Of Chemistry')">Department Of Chemistry</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/industrial-chemistry"  (click)="changeRoute('Department Of Industrial Chemistry')">Department Of Industrial Chemistry</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/microbiology"
                            (click)="changeRoute('Department Of Microbiology')">Department Of Microbiology</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/science/biotechnology" (click)="changeRoute('Department Of Biotechnology')">Department Of Biotechnology</a>
                        </li>
                       
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>