import { Component, HostListener, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-ct-one-three-two',
  templateUrl: './ct-one-three-two.component.html',
  styleUrls: ['./ct-one-three-two.component.scss']
})
export class CtOneThreeTwoComponent implements OnInit {
  @HostListener("click") onClick() {
    this.checkRoute();
  }
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/1.3.2.1_Certificate from Head of the Institute.pdf',
    '/assets/pdf/NAAC/DVV/1.3.2.4_Report of Field Work.pdf',
    '/assets/pdf/NAAC/DVV/1.3.2.5_Objectives and outcomes of Field work.pdf',];
  src: string;

  showPdf = false;
  isLoading = true;
  showSideMenu = true;
  fullWidth = false;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.showPdf = true;
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      // this.src = this.pdfSrc[0];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`three`).addClass('show');
      $(`four`).removeClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.src = this.pdfSrc[1];
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).addClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.src = this.pdfSrc[2];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

  splitUrl() {
    let url = location.pathname.split('/');
    // console.log(url);
    if (url.length === 5) {
      this.showSideMenu = true;
      this.fullWidth = false;
      // this.showHeading = true;
    }
    else if(url.length === 6 && url[5]==='1-3-2-3'){
      this.showSideMenu=true;
      this.fullWidth=false;
      // this.showHeading = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }
}
