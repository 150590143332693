<div id="ethics">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Corner for Ethics</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Guru Nanak Khalsa College is known for shaping the students personality by giving them opportunity of participation in various activities.
                             Guru Nanak Khalsa College is known for making students empowered by giving them training of skills, like communication skill, leadership skill, writing skill, technical skills etc.
                              It is also known for imbibing Indian culture by giving them opportunities to participate in value oriented training in the classroom, laboratory and at all other places.
                             All the teachers make use of their innovative activities to shape the personality of a student, cultivate various skills and make the student create his own identity.
                        </p>
                        <p class="lead text-justify">
                            Centre for Ethics  at GNKC, was created with an objective of functioning as a Resource Centre for imparting values-based education to students and professionals.
                             The role of  Ethical Corner is to  envisage, creation of awareness in the learners' community that skills and ethical values are essentially complementary.
                             Therefore GNKC always endeavours to make the students aware about the ethical aspects of everything they acquire with the help of enlightened dialogue and the dissemination of knowledge that will lead to informed moral choice. Housed in the Punjabi Department under the direct blessings of the college Gurudwara, it is a centre to promote ethical concepts among the various stakeholders in the college and foster sense of ethical practices in every aspect of life.
                        </p>
                        <p class="lead text-justify">
                            Trained  individuals in the fields of Ethics and other interdisciplinary areas are invited frequently to  empower the students with skills necessary for resolving ethical problems.
                        </p>
                        <p class="lead text-justify">
                            Ethical Corner also provides a platform for interdisciplinary dialogue in ethics and thereby promote the national values of professional and ethical leadership.
                        </p>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>