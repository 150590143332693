<div id="placement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="card-title">Our Recruiters</h3>
                <hr class="pb-1 rounded">
                <div class="row">
                    <img src="/assets/img/hiring/17.jpg" alt="" class="img-fluid mx-auto">
                    <img src="/assets/img/hiring/19.PNG" alt="" class="img-fluid mx-auto">
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-5" *ngFor="let company of companies;let i=index">
                        <div class="card shadow-lg">
                            <img class="card-img-top mx-auto p-2" [src]="company.img" alt="Company">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>