<div id="heading">
    <h1>Staff Committees</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">About Us</a></li>
          <li class="breadcrumb-item active" aria-current="page">Staff Committees</li>
        </ol>
      </nav>
</div>

<div id="committee">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="title">List Of Duties Allocated For The Sessions 2023-24</h2>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        NCC Officer
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Ramneek (Girls’ Wing) </p>
                        <p><i class="bi bi-check2-all"></i> Dr. Parveen Kumar (Boys’ wing)</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        NSS Officer
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Catherine Masih</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Sanjay Vij</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Vinay Chandel </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Time Table Incharge
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Rameshwar Dass</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Anurag</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Women Cell
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Meenu Kapoor </p>
                        <p><i class="bi bi-check2-all"></i> Prof. Santosh Kurra</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Jaswinder Kaur</p>
                        <p><i class="bi bi-check2-all"></i> Prof. Gurvinder Kaur</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Chief Hostel Warden
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Prof. Gurvinder Kaur</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        SC/ST Committee
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Parveen Kumar</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        College Magazine
                    </div>
                    <div class="card-body">
                        <!-- <p><i class="bi bi-check2-all"></i> Dr. Pratima Sharma</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Catherine Masih</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Neena Puri</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Tilak Raj</p> -->
                        <p><i class="bi bi-check2-all"></i> Dr. Tilak Raj (Editor-in-Chief)</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Cultural Affairs
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Tilak Raj                        </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Discipline
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Parveen Kumar</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Purchase Coordinator
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Ranjit Singh</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        University Works
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Anurag</p>
                        <p><i class="bi bi-check2-all"></i>  Mr. Jashan Lal (Clerk)</p>
                        <p><i class="bi bi-check2-all"></i>  Ravneet Singh (Peon)</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Seminar/ Conference/ Research Cell
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Ashok Khurrana</p>
                        <!-- <p><i class="bi bi-check2-all"></i> Dr. P.K. Malik</p> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Sikh Museum
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Ranjit Singh</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Incharge Sports
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i>Dr. Bodh Raj </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Convener Prospectus Committee
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Kamal Preet Kaur</p>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Controller of Exam/ Registrar
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. H.S.Kang</p>
                    </div>
                </div>
            </div> -->

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Spot Evaluation
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Kamal Preet Kaur</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        IQAC & UGC Grants
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Catherine Masih</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Sanjay Arora  </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Bursar
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Sanjay Arora</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Career advising & Placement Cell
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. R. S. Vohra</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Parveen Kumar</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Rameshwar Dass</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Legal Literacy Cell
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Baljit Singh</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Hemant Kumar Mishra</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Pratima Sharma</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Public Relation Officer
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Iqbal Singh</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Grievance Redressal Committee
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Kamalpreet Kaur</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Iqbal Singh</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Jaswinder Kaur</p>
                        <p><i class="bi bi-check2-all"></i> Dr. Amarjit Singh</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card shadow-lg">
                    <div class="card-header">
                        DGHE Works
                    </div>
                    <div class="card-body">
                        <p><i class="bi bi-check2-all"></i> Dr. Neena Puri</p>
                        <p><i class="bi bi-check2-all"></i> Mr. Hazara Singh (Dy. Supdtt.)</p>
                        <p><i class="bi bi-check2-all"></i> Mr. Sohan Lal (Clerk)</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>