<div id="institutional">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>IQAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">IQAC</a></li>
                <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Institutional
                    Development Plan</li>
            </ol>
        </nav>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- <a routerLink="/naac/ssr" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a> -->
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Institutional Development Plan</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="one">
                            <a (click)="changeRoute('1')">2022-23</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>