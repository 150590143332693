<div id="heading">
  <h1>College Profile</h1>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
      <li class="breadcrumb-item"><a href="#">About Us</a></li>
      <li class="breadcrumb-item active" aria-current="page">College Profile</li>
    </ol>
  </nav>
</div>

<div id="profile">
  <div class="container-fluid">
    <div class="card pl-3 pr-3 text-justify ">
      <h4 class="card-title text-center ">College Profile</h4>
      <div class="card-body text-justify">
        <p class="lead">
          With the aim of creating a great centre of ideal education, Guru Nanak Khalsa College was established in 1968
          to commemorate the quincentary birth of Guru Nanak Dev Ji, a great philosopher and reformer.
        </p>
        <p class="lead">The college comprises of five faculties, 23 departments with 100 odd teaching faculty and around
          3000 students.
          The college offers around 17 different programmes at UG level and 7 at PG level and one PG Diploma for a
          varied choice
          extending from regular degree courses to the professional courses as per industry current and futuristic
          requirements.</p>
        <p class="lead">The college was accredited <b>A<sup>+</sup></b> by the NAAC in 2005 and has been reaccredited with <b>'A'</b> grade in 2014 and 2023. The
college was awarded with the status “Centre with Potential for Excellence” by UGC in 2006 and again in 2014-15.</p>
        <h5>Vision</h5>
        <hr class="pb-1" style="width: 65px;">
        <p class="lead">To be known as a Centre of Excellence, which produces globally competitive, socially responsible
          and productive human resource through quality education and training.</p>
        <h5>Mission</h5>
        <hr class="pb-1" style="width: 65px;">
        <p class="lead">To provide career-oriented quality education through innovative delivery methods and aiming at
          the
          holistic development of students which integrates academic excellence, employability skills and socio-cultural
          adaptability.</p>
        <h5>Goals</h5>
        <hr class="pb-1">
        <p class="lead">The main goals of the college are</p>
        <ul>
          <li>
            <p class="lead">to develop life-long learning habits among students</p>
          </li>
          <li>
            <p class="lead">
              to adopt innovative methods of teaching, learning and assessment
            </p>
          </li>
          <li>
            <p class="lead">impart pre-employment and vocational knowledge and related skills</p>
          </li>
          <li>
            <p class="lead">
              to motivate students to integrate more and more with values especially dignity of work and social
              commitment
            </p>
          </li>
          <li>
            <p class="lead">
              to equip students with necessary knowledge, skills and attitudes with which they can serve the community
              and the
              nation meaningfully</p>
          </li>
          <li>
            <p class="lead">to encourage students to imbibe in themselves the best of traditional, ethical and
              scientific attitudes and values</p>
          </li>
        </ul>
      </div>
      <div class="imgLoader"></div>
      <div class="container d-none d-lg-block">
        <div class="book">
          <div class="gap"></div>
          <div class="pages">
            <div class="page"></div>
            <div class="page"></div>
            <div class="page"></div>
            <div class="page"></div>
            <div class="page"></div>
            <div class="page"></div>
          </div>
          <div class="flips">
            <div class="flip flip1">
              <div class="flip flip2">
                <div class="flip flip3">
                  <div class="flip flip4">
                    <div class="flip flip5">
                      <div class="flip flip6">
                        <div class="flip flip7"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
