<div id="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="widget dark">
                    <div class="footer-logo">
                        <img class="mt-3 img-fluid" alt="Logo" src="/assets/img/logo/3.png">
                    </div>
                  
                  <h4 class=" text-center mb-0">GURU NANAK KHALSA COLLEGE</h4>
                  <p class="text-center">City Centre Road, <br> Yamuna Nagar (Haryana) 135001</p>
                  <p class="text-center">NAAC Accredited Grade 'A' with CGPA 3.04 in the Third Cycle.</p>
                  <ul class="list-inline  text-center">
                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-phone mr-2"></i> <a  href="tel:01732-221675">01732-221675</a> </li>
                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-envelope-o  mr-2"></i> <a class="spamspan" href="mailto:khalsawins@gmail.com">khalsawins@gmail.com</a> </li>
                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-globe  mr-2"></i> <a  href="javascript:void(0)">gnkc.ac.in</a> </li>
                  </ul>
                  <ul class="social clearfix mt-10 text-center">
                    <li><a href="https://twitter.com/gnkcynrofficial" target="_blank" aria-label="Twitter"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://instagram.com/gnkcynrofficial" target="_blank" aria-label="Linkedin"><i class="fa fa-linkedin text-white"></i></a></li>
                    <li><a href="https://facebook.com/gnkcynrofficial" target="_blank" aria-label="Facebook"><i class="fa fa-facebook"></i></a></li> 
                    <li><a href="https://youtube.com/c/GuruNanakKhalsaCollegeYamunanagar" target="_blank" aria-label="Youtube"><i class="fa fa-youtube-play"></i></a></li>
                            
                  </ul>
                </div>
              </div>
            <div class="col-md-6 col-lg-3">
                <div class="link">
                    <h4 >Quick Link</h4>
                    <hr>
                    <ul class="pl-0">
                        <li><a routerLink="/faculty/humanities/economics" (click)="setUrl('Department Of Economics')">Departments</a></li>
                        <!-- <li><a routerLink="/not-found">Centres</a></li> -->
                        <li><a routerLink="/courses/arts" (click)="setUrl('Arts')">Courses</a></li>
                        <li><a routerLink="/activities/social" (click)="setUrl('Social Outreach Activities')">Social Initiatives</a></li>
                        <li><a routerLink="/student-corner/anti-ragging" (click)="setUrl('Anti-Ragging Policy')">Anti-Ragging Policy</a></li>
                        <!-- <li><a routerLink="/not-found">Right to Information</a></li> -->
                        <li><a routerLink="/blog" (click)="setUrl('Blogs')">Blogs</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="link borderRight">
                    <h4 >Explore</h4>
                    <hr>
                    <ul class="pl-0">
                        <li><a routerLink="/gallery">Gallery & Campus Life</a></li>
                        <!-- <li><a routerLink="/not-found">Institute Newsletter</a></li> -->
                        <li><a routerLink="/courses/add-on">Add On Courses</a></li>
                        <li><a routerLink="/important-links">Important Links</a></li>
                        <li><a routerLink="/student-corner/prospectus">Prospectus</a></li>
                        <li><a href="https://forms.gnkgei.ac.in/odforms/form/GURUNANAKKHALSACOLLEGE/formperma/yr4n3xJGKyT5zRio0XzJI7lOwLQ0-8jQ1nCj0P6RpZA" target="_blank">Careers</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
               <app-map></app-map>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-right">
                <a href="https://okiedokiepay.com/" class="design" target="_blank">Designed By: Okie Dokie Solutions LLP</a>
            </div>
        </div>
    </div>
</div>