<!-- <div id="bulletin" class="">
    <div class="container">
        <div class="row bullet bg-light">
            <div class="col-3 col-md-2 bullet-1">
                <h5 class="text-center">Bulletin</h5>
                <span class=""></span>
            </div>
            <div class="col-9 col-md-10 bullet-2">
                <marquee behavior="scroll" direction="left" class="blink">Registration For Session 2022-23 Will Open From 20 June 2022</marquee>
            </div>
        </div>
    </div>
</div> -->


<div id="bulletin" class="">
    <div class="container">
        <div class="row bullet bg-light">
            <div class="col-3 col-md-2 bullet-1">
                <h5 class="text-center">Bulletin</h5>
                <span class=""></span>
            </div>
            <div class="col-9 col-md-10 bullet-2">
                <marquee behavior="scroll" direction="left" class="bulletinMarque"></marquee>
            </div>
        </div>
    </div>
</div>
<div id="bulletin1" class=" mt-4">
    <div class="container">
        <div class="row bullet bg-light">
            <div class="col-3 col-md-2 bullet-1">
                <h5 class="text-center">Notices</h5>
                <span class=""></span>
            </div>
            <div class="col-9 col-md-10 bullet-2">
                <marquee behavior="scroll" direction="left" ><a routerLink="/menu-detail/notice"><strong>Click here to view college notices.</strong></a></marquee>
            </div>
        </div>
    </div>
</div> 

<!-- 1.) Registration Open For All Courses For The Session(2021-22). &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://drive.google.com/drive/folders/1lFo30c-BUqFcmgLpFIESDy9Q9ibOyU4T?usp=sharing" target="_blank" download>   2.) Previous Result of ENVIRONMENTAL STUDIES Out Now. <strong>Click Here.</strong></a> -->