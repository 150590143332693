import { Component, OnInit } from '@angular/core';
declare var $ :any;
@Component({
  selector: 'app-c7-seven-one-one',
  templateUrl: './c7-seven-one-one.component.html',
  styleUrls: ['./c7-seven-one-one.component.scss']
})
export class C7SevenOneOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Anti Ragging Policy.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Gender Audit Assessment Report.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Gender Audit committee.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Gender Equity Policy.pdf', 
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Prevention and Sexual Harassment Policy.pdf',
    '/assets/pdf/NAAC/Criterion-7/7.1.1/Student Welfare Policy.pdf' ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('2021');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == 'ragging') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == 'assess') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == 'committee') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == 'policy') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == 'prevent') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.sixth').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == 'student') {
      $('.sixth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[5];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }
}
