import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FifthComponent } from "src/app/view/wp-content/fifth/fifth.component";
import { FirstComponent } from "src/app/view/wp-content/first/first.component";
import { FourthComponent } from "src/app/view/wp-content/fourth/fourth.component";
import { SecondComponent } from "src/app/view/wp-content/second/second.component";
import { ThirdComponent } from "src/app/view/wp-content/third/third.component";
import { WpContentComponent } from "src/app/view/wp-content/wp-content.component";

const route:Routes=[
    {path:'',component:WpContentComponent,children:[
        {path:'02/2018-19-pdf',component:FirstComponent},
        {path:'03/2-7-1-2018-19-converted-pdf',component:SecondComponent},
        {path:'03/2-7-1-2017-18-converted-pdf',component:ThirdComponent},
        {path:'03/Best-Practices-2018-19-pdf',component:FourthComponent},
        {path:'03/Committees-2018-19-pdf',component:FifthComponent}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class WpContentRoutingModule{}