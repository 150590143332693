import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c331-twenty',
  templateUrl: './c331-twenty.component.html',
  styleUrls: ['./c331-twenty.component.scss']
})
export class C331TwentyComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.1.1 Research/';

  result:{title:string,link:string}[]=[
    {
        title: 'Finding the causes of unemployment in rural areas of Haryana: Wirh special reference in Yamuna nagar Distt.',
        link: this.c3321Url + '20_Finding-the-causes-of-unemployment_2020-21.pdf'
    },
    {
        title: 'Investigation of ionization cross sections of tantalum at proton energy ',
        link: this.c3321Url + '21_Investigation-of-ionization_2020-21.pdf'
    },
    {
        title: 'COMPARISION OF PHYSICAL FITNESS VARIABLES ON INTERUNIVERSITY CRICKET PLAYERS ',
        link: this.c3321Url + '22_COMPARISION-OF-PHYSICAL-FITNESS_2020-21.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
