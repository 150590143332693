import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MediaListComponent } from "src/app/view/media-list/media-list.component";

@NgModule({
    imports:[
        RouterModule.forChild([{path:'',component:MediaListComponent}])
    ],
    exports:[
        RouterModule
    ]
})
export class MediaRoutingModule{}