<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria6/6-5"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>6.5.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf6" (click)="setPage('6')">Four filled in feed back forms from different stakholders</a>
                    </li>
                    <li>
                        <a>Analysis and ATR</a>
                    </li>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf5" (click)="setPage('5')">2021-2022</a>
                    </li>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf4" (click)="setPage('4')">2020-2021</a>
                    </li>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf3" (click)="setPage('3')">2019-2020</a>
                    </li>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf2" (click)="setPage('2')">2018-2019</a>
                    </li>
                    <li class="sub-menu">
                        <a data-bs-target="#pdf1" (click)="setPage('1')">2017-2018</a>
                    </li>
                   
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="col-12">
                <div class="collapse " id="pdf6">
                    <h4 class="card-title text-center">Four filled in feed back forms from different stakholders</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc6" [render-text]="true" style="display: block;" id="list-view11"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="collapse " id="pdf5">
                    <h4 class="card-title text-center">Analysis and ATR</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc5" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="collapse " id="pdf4">
                    <h4 class="card-title text-center">Analysis and ATR</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc4" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="collapse " id="pdf3">
                    <h4 class="card-title text-center">Analysis and ATR</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc3" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="collapse " id="pdf2">
                    <h4 class="card-title text-center">Analysis and ATR</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc2" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="collapse " id="pdf1">
                    <h4 class="card-title text-center">Analysis and ATR</h4>
                    <div class="card card-body">
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="pdfSrc1" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>