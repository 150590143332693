import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-lab',
  templateUrl: './language-lab.component.html',
  styleUrls: ['./language-lab.component.scss']
})
export class LanguageLabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
