import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-four-one-three',
  templateUrl: './c1-one-four-one-three.component.html',
  styleUrls: ['./c1-one-four-one-three.component.scss']
})
export class C1OneFourOneThreeComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.3_four filled in feed back forms from different stakholders.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
