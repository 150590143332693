<div id="book">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <div id="book-detail" class="carousel slide text-center" data-ride="carousel">
                    <div class="carousel-inner">
                      
                      <div class="carousel-item ">
                        <img src="/assets/svg/book/grad.svg" alt="Graduation" class="img-fluid">
                        
                      </div>
                      <div class="carousel-item active">
                        <img src="/assets/img/logo/3.png" alt="Graduation" class="img-fluid">
                      </div>
                      <div class="carousel-item  ">
                        <svg width="244px" height="259px" viewBox="0 0 244 259" id="clipboard" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs>
                                <path id="path-1" d="M0,199L199,199L199,0L0,0Z" class="UYaFUNuu_0"></path>
                            </defs>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g>
                                    <g transform="translate(23.000000, 30.000000)">
                                        <g>
                                            <g>
                                                <path d="M144.924888,173.05807 L51.9296668,173.05807 C48.9891871,173.05807 46.5829499,170.65237 46.5829499,167.71189 L46.5829499,29.1950164 C46.5829499,26.2540003 48.9891871,23.8482995 51.9296668,23.8482995 L144.924888,23.8482995 C147.865368,23.8482995 150.271068,26.2540003 150.271068,29.1950164 L150.271068,167.71189 C150.271068,170.65237 147.865368,173.05807 144.924888,173.05807 Z" id="Fill-12" fill="#FFFFFF" class="UYaFUNuu_1"></path>
                                                <path d="M144.924888,173.05807 L51.9296668,173.05807 C48.9891871,173.05807 46.5829499,170.65237 46.5829499,167.71189 L46.5829499,29.1950164 C46.5829499,26.2540003 48.9891871,23.8482995 51.9296668,23.8482995 L144.924888,23.8482995 C147.865368,23.8482995 150.271068,26.2540003 150.271068,29.1950164 L150.271068,167.71189 C150.271068,170.65237 147.865368,173.05807 144.924888,173.05807 Z" id="Stroke-14" stroke="#27446d" stroke-width="2" class="UYaFUNuu_2"></path>
                                                <mask id="mask-2" fill="white">
                                                    <use xlink:href="#path-1"></use>
                                                </mask>
                                                <g id="Clip-17"></g>
                                                <path id="Fill-16" fill="#FFFFFF" mask="url(#mask-2)" d="M58.6277601,157.883704L138.290518,157.883704L138.290518,46.6089111L58.6277601,46.6089111Z" class="UYaFUNuu_3"></path>
                                                <path id="Stroke-18" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" d="M58.6277601,157.883704L138.290518,157.883704L138.290518,46.6089111L58.6277601,46.6089111Z" class="UYaFUNuu_4"></path>
                                                <path d="M66.8149741,66.8409889 L130.039581,66.8409889" id="Stroke-19" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_5"></path>
                                                <path d="M66.8149741,84.7966892 L130.039581,84.7966892" id="Stroke-20" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_6"></path>
                                                <path d="M66.8149741,102.752443 L130.039581,102.752443" id="Stroke-21" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_7"></path>
                                                <path d="M66.8149741,120.708197 L130.039581,120.708197" id="Stroke-22" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_8"></path>
                                                <path d="M66.8149741,138.663951 L130.039581,138.663951" id="Stroke-23" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_9"></path>
                                                <path d="M127.120288,31.9150943 L121.833647,31.9150943 C121.493577,24.9420485 115.670547,19.5781671 108.579496,19.5781671 L86.2496574,19.5781671 C79.1580698,19.5781671 73.3355765,24.9420485 72.9949701,31.9150943 L67.708865,31.9150943 C60.4011129,31.9150943 54.979838,37.4146819 54.979838,44.722434 L54.979838,46.6089111 C54.979838,48.0003019 55.56021,49.615903 56.9510644,49.615903 L75.4913205,49.615903 L119.337833,49.615903 L137.878089,49.615903 C139.268943,49.615903 140.265552,48.0003019 140.265552,46.6089111 L140.265552,44.722434 C140.265552,37.4146819 134.42804,31.9150943 127.120288,31.9150943" id="Fill-24" fill="#FFFFFF" mask="url(#mask-2)" class="UYaFUNuu_10"></path>
                                                <path d="M127.120288,31.9150943 L121.833647,31.9150943 C121.493577,24.9420485 115.670547,19.5781671 108.579496,19.5781671 L86.2496574,19.5781671 C79.1580698,19.5781671 73.3355765,24.9420485 72.9949701,31.9150943 L67.708865,31.9150943 C60.4011129,31.9150943 54.979838,37.4146819 54.979838,44.722434 L54.979838,46.6089111 C54.979838,48.0003019 55.56021,49.615903 56.9510644,49.615903 L75.4913205,49.615903 L119.337833,49.615903 L137.878089,49.615903 C139.268943,49.615903 140.265552,48.0003019 140.265552,46.6089111 L140.265552,44.722434 C140.265552,37.4146819 134.42804,31.9150943 127.120288,31.9150943 Z" id="Stroke-25" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_11"></path>
                                                <path d="M110.439851,20.687096 C110.439851,27.3216809 105.061487,32.6995084 98.4274383,32.6995084 C91.7928534,32.6995084 86.4144895,27.3216809 86.4144895,20.687096 C86.4144895,14.0525111 91.7928534,8.67468356 98.4274383,8.67468356 C105.061487,8.67468356 110.439851,14.0525111 110.439851,20.687096" id="Fill-26" fill="#FFFFFF" mask="url(#mask-2)" class="UYaFUNuu_12"></path>
                                                <path d="M110.439851,20.687096 C110.439851,27.3216809 105.061487,32.6995084 98.4274383,32.6995084 C91.7928534,32.6995084 86.4144895,27.3216809 86.4144895,20.687096 C86.4144895,14.0525111 91.7928534,8.67468356 98.4274383,8.67468356 C105.061487,8.67468356 110.439851,14.0525111 110.439851,20.687096 Z" id="Stroke-27" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_13"></path>
                                                <path d="M105.533026,20.687096 C105.533026,24.6113116 102.351708,27.793166 98.4269555,27.793166 C94.5027399,27.793166 91.3214218,24.6113116 91.3214218,20.687096 C91.3214218,16.7628803 94.5027399,13.5810259 98.4269555,13.5810259 C102.351708,13.5810259 105.533026,16.7628803 105.533026,20.687096" id="Fill-28" fill="#FFFFFF" mask="url(#mask-2)" class="UYaFUNuu_14"></path>
                                                <path d="M105.533026,20.687096 C105.533026,24.6113116 102.351708,27.793166 98.4269555,27.793166 C94.5027399,27.793166 91.3214218,24.6113116 91.3214218,20.687096 C91.3214218,16.7628803 94.5027399,13.5810259 98.4269555,13.5810259 C102.351708,13.5810259 105.533026,16.7628803 105.533026,20.687096 Z" id="Stroke-29" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" class="UYaFUNuu_15"></path>
                                                <g id="Group-32" mask="url(#mask-2)">
                                                    <g transform="translate(81.530997, 92.258760)">
                                                        <path id="Fill-30" stroke="none" fill="#FFFFFF" fill-rule="evenodd" d="M3.73492426,15.3105558L0.193689757,0.186555795L15.179838,3.86564205" class="UYaFUNuu_16"></path>
                                                        <path id="Stroke-31" stroke="#27446d" stroke-width="2" stroke-linejoin="round" fill="none" d="M3.73492426,15.3105558L0.193689757,0.186555795L15.179838,3.86564205" class="UYaFUNuu_17"></path>
                                                    </g>
                                                </g>
                                                <path id="Fill-33" fill="#FFFFFF" mask="url(#mask-2)" d="M159.815076,181.89941L171.112483,170.602003L96.9423404,96.4313235L85.644397,107.72873Z" class="UYaFUNuu_18"></path>
                                                <path id="Stroke-34" stroke="#27446d" stroke-width="2" mask="url(#mask-2)" d="M171.112483,170.602003L96.9423404,96.4313235L85.644397,107.72873L159.815076,181.89941L159.815076,181.89941" class="UYaFUNuu_19"></path>
                                                <g id="Group-37" mask="url(#mask-2)">
                                                    <g transform="translate(91.185984, 101.913747)">
                                                        <path d="M0.352943396,0.412053369 L74.0322911,74.0914011" id="Fill-35" stroke="none" fill="#FFFFFF" fill-rule="evenodd" class="UYaFUNuu_20"></path>
                                                        <path d="M0.352943396,0.412053369 L74.0322911,74.0914011" id="Stroke-36" stroke="#27446d" stroke-width="2" stroke-linejoin="round" fill="none" class="UYaFUNuu_21"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <path d="M182.569411,211.653798 L187.882871,216.967258 C189.288745,218.373132 191.568394,218.373132 192.974268,216.967258 L199.180278,210.761248 C200.586152,209.355374 200.586152,207.075725 199.180278,205.669851 L193.866818,200.356391 L182.569411,211.653798 Z" id="Stroke-39" stroke="#27446d" stroke-width="2" class="UYaFUNuu_22"></path>
                                </g>
                            </g>
                          </svg>

                          <svg width="244px" height="259px" viewBox="0 0 244 259" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="burst">
                            <defs></defs>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-dasharray="3,6" stroke-linejoin="round">
                                <g stroke="#27446d" stroke-width="2">
                                    <path d="M122,128 L84,36" id="Stroke-9" class="GHUcgnpr_0"></path>
                                    <path d="M124,131 L52,59" id="Stroke-7" class="GHUcgnpr_1"></path>
                                    <path d="M121,129 L30,91" id="Stroke-11" class="GHUcgnpr_2"></path>
                                    <path d="M121,130 L22,130" id="Stroke-1" class="GHUcgnpr_3"></path>
                                    <path d="M122,130 L31,168" id="Stroke-8" class="GHUcgnpr_4"></path>
                                    <path d="M124,129 L52,199" id="Stroke-6" class="GHUcgnpr_5"></path>
                                    <path d="M122,133 L83,223" id="Stroke-10" class="GHUcgnpr_6"></path>
                                    <path d="M123,130 L123,232.047357" id="Stroke-3-Copy" class="GHUcgnpr_7"></path>
                                    <path d="M125,133 L163,224" id="Stroke-9" class="GHUcgnpr_8"></path>
                                    <path d="M124,131 L193,200" id="Stroke-7" class="GHUcgnpr_9"></path>
                                    <path d="M125,131 L216,169" id="Stroke-11" class="GHUcgnpr_10"></path>
                                    <path d="M125,130 L224,130" id="Stroke-1-Copy" class="GHUcgnpr_11"></path>
                                    <path d="M124,129 L214,92" id="Stroke-8" class="GHUcgnpr_12"></path>
                                    <path d="M124,129 L192,59" id="Stroke-6" class="GHUcgnpr_13"></path>
                                    <path d="M124,128 L162,37" id="Stroke-10" class="GHUcgnpr_14"></path>
                                    <path d="M123,31 L123,130" id="Stroke-3" transform="translate(123.500000, 80.500000) scale(1, -1) translate(-123.500000, -80.500000) " class="GHUcgnpr_15"></path>
                                </g>
                            </g>
                          </svg>
                      </div>
                    </div>
                  </div>
                  <p>WELCOME TO GURU NANAK KHALSA COLLEGE</p>
            </div>
        </div>
    </div>
</div>