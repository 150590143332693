import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-po-co',
  templateUrl: './po-co.component.html',
  styleUrls: ['./po-co.component.scss']
})
export class PoCoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
