<div id="workshop">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Workshops</h4>
                    <div class="card-body text-justify">
                      <h5>NATIONAL WEBINAR ON ENTREPRENEURIAL DEVELOPMENT FOR YOUNG STUDENTS</h5>
                      <hr class="rounded">
                      <div id="sepCarouselCarousel" class="carousel slide mb-4" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#sepCarouselCarousel" data-slide-to="0" class="active"></li>
                          </ol>
                          <div class="carousel-inner text-center py-5 px-3">
                            <div class="carousel-item active">
                              <img src="/assets/img/Workshop/aug-22/1.jpeg" alt="sepCarousel" class="img-fluid rounded">
                            </div>
                           
                          </div>
                          <a class="carousel-control-prev" href="#sepCarouselCarousel" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#sepCarouselCarousel" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      <h5> IP & It's Role in Research & Innovation </h5>
                      <hr class="rounded">
                      <div id="augustCarouselCarousel" class="carousel slide mb-4" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#augustCarouselCarousel" data-slide-to="0" class="active"></li>
                          </ol>
                          <div class="carousel-inner text-center py-5 px-3">
                            <div class="carousel-item active">
                              <img src="/assets/img/Workshop/aug-22/2.png" alt="AugCarousel" class="img-fluid rounded">
                            </div>
                           
                          </div>
                          <a class="carousel-control-prev" href="#augustCarouselCarousel" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#augustCarouselCarousel" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                        <h5> ENTREPRENEURSHIP- As a Career Opportunity ( June 2021) </h5>
                        <hr class="rounded">
                        <div id="juneCarouselCarousel" class="carousel slide mb-4" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#juneCarouselCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#juneCarouselCarousel" data-slide-to="1"></li>
                              <li data-target="#juneCarouselCarousel" data-slide-to="2"></li>
                              <li data-target="#juneCarouselCarousel" data-slide-to="3"></li>
                              <li data-target="#juneCarouselCarousel" data-slide-to="4"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/Workshop/June-2021/1.png" alt="juneCarousel" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/June-2021/3.png" alt="juneCarousel" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/June-2021/4.png" alt="juneCarousel" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/June-2021/5.jpeg" alt="juneCarousel" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/June-2021/6.jpeg" alt="juneCarousel" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#juneCarouselCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#juneCarouselCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <!-- <div class="row text-center">
                           
                            <div class="col-sm-12 col-md-6 mt-2"> <a href="/assets/img/Workshop/June-2021/1.png" data-lightbox="Workshop"><img src="/assets/img/Workshop/June-2021/1.png" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/June-2021/3.png" data-lightbox="Workshop"><img src="/assets/img/Workshop/June-2021/3.png" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/June-2021/4.png" data-lightbox="Workshop"><img src="/assets/img/Workshop/June-2021/4.png" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/June-2021/5.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/June-2021/5.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/June-2021/6.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/June-2021/6.jpeg" alt="Workshop" class="img-fluid"></a></div>
                        </div> -->

                        <h5> Promoting Academic Integrity & Prevention of Plagiarism in Higher Education Institutions ( April 2021)</h5>
                        <hr class="rounded">
                        <div id="aprilCarousel" class="carousel slide mb-4" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#aprilCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#aprilCarousel" data-slide-to="1"></li>
                              <li data-target="#aprilCarousel" data-slide-to="2"></li>
                              <li data-target="#aprilCarousel" data-slide-to="3"></li>
                              <li data-target="#aprilCarousel" data-slide-to="4"></li>
                              <li data-target="#aprilCarousel" data-slide-to="5"></li>
                              <li data-target="#aprilCarousel" data-slide-to="6"></li>
                              <li data-target="#aprilCarousel" data-slide-to="7"></li>
                              <li data-target="#aprilCarousel" data-slide-to="8"></li>
                              <li data-target="#aprilCarousel" data-slide-to="9"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/Workshop/april-21/1.jpg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/april-21/2.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/april-21/3.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/april-21/4.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/april-21/5.jpg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/april-21/6.jpeg" alt="april" class="img-fluid rounded">
                              </div>

                              <div class="carousel-item active">
                                <img src="/assets/img/Workshop/april-21/7.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/april-21/8.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/april-21/9.jpg" alt="april" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/april-21/10.jpeg" alt="april" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#aprilCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#aprilCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <!-- <div class="row text-center">
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/1.jpg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/1.jpg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/2.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/2.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/3.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/3.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/4.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/4.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/4.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/5.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/6.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/6.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/dec-20/7.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/dec-20/7.jpeg" alt="Workshop" class="img-fluid"></a></div>
                        </div> -->


                        <h5> Intellectual Property Rights: Industry-Academia Interface ( December 2020)</h5>
                        <hr class="rounded">

                        <div id="decCarousel" class="carousel slide mb-4" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#decCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#decCarousel" data-slide-to="1"></li>
                              <li data-target="#decCarousel" data-slide-to="2"></li>
                              <li data-target="#decCarousel" data-slide-to="3"></li>
                              <li data-target="#decCarousel" data-slide-to="4"></li>
                              <li data-target="#decCarousel" data-slide-to="5"></li>
                              <li data-target="#decCarousel" data-slide-to="6"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/Workshop/dec-20/1.jpg" alt="dec" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/dec-20/2.jpeg" alt="dec" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/dec-20/3.jpeg" alt="dec" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/dec-20/4.jpeg" alt="dec" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/dec-20/4.jpeg" alt="dec" class="img-fluid rounded">
                              </div>

                              <div class="carousel-item">
                                <img src="/assets/img/Workshop/dec-20/6.jpeg" alt="dec" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/Workshop/dec-20/7.jpeg" alt="dec" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#decCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#decCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        <!-- <div class="row text-center">
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/1.jpg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/1.jpg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/2.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/2.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/3.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/3.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/4.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/4.jpeg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/5.jpg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/5.jpg" alt="Workshop" class="img-fluid"></a></div>
                            <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/Workshop/april-21/6.jpeg" data-lightbox="Workshop"><img src="/assets/img/Workshop/april-21/6.jpeg" alt="Workshop" class="img-fluid"></a></div>
                           
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>