import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-mathematics',
  templateUrl: './mathematics.component.html',
  styleUrls: ['./mathematics.component.scss']
})
export class MathematicsComponent implements OnInit {
  math:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.math=this.facultyService.getMath();
  }

}
