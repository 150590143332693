import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $:any;

@Component({
  selector: 'app-edu',
  templateUrl: './edu.component.html',
  styleUrls: ['./edu.component.scss']
})
export class EduComponent implements OnInit {
  education:{name:string,creteria:string,title:string,detail1:string[],note:string[],detail2:string[]}[];
  educationpo:string[]=[]
  constructor(private courseService:CourseService) { 
    
  }

  ngOnInit(): void {
    this.education=this.courseService.getEducation();
    this.educationpo=this.courseService.getEducationpo();
  }

  setModal(i:number){
    $('#courseModelTitle').html(this.education[i].name);
    $('.creteria1').html(this.education[i].creteria);
    if(i==0){
      $('.bped').hide();
      $('.detail11').html(this.education[i].detail1[0]);
    }
    if(i==1){
      $('.bped').show();
      $('.title').html(this.education[i].title);
      $('.sem1b1').html(this.education[i].detail1[0]);
      $('.sem1b2').html(this.education[i].detail1[1]);
      $('.sem1b3').html(this.education[i].detail1[2]);
      $('.sem1b4').html(this.education[i].detail1[3]);
      $('.sem1b5').html(this.education[i].detail1[4]);
      $('.sem1b6').html(this.education[i].detail1[4]);
      $('.note1').html(this.education[i].note[0]);
      $('.note2').html(this.education[i].note[1]);
      $('.detail21').html(this.education[i].detail2[0]);

      let po=document.querySelector('.po-pso');
      po.innerHTML=this.educationpo[i];

    }

  }
}
