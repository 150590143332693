import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c33-twenty',
  templateUrl: './c33-twenty.component.html',
  styleUrls: ['./c33-twenty.component.scss']
})
export class C33TwentyComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.2.1 Books/';

  result:{title:string,link:string}[]=[
    {
        title: 'Dr. Alok Goyal',
        link: this.c3321Url + '11_2020_Dr.-Alok Goyal.pdf'
    },
    {
        title: 'Dr. Harinder Singh Kang',
        link: this.c3321Url + '12_2020_Dr.-Harinder-Singh-Kang.pdf'
    },
    {
        title: 'Jaspreet Singh',
        link: this.c3321Url + '13_2020_Jaspreet-Singh.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
