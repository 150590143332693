import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c5-five-four-one',
  templateUrl: './c5-five-four-one.component.html',
  styleUrls: ['./c5-five-four-one.component.scss']
})
export class C5FiveFourOneComponent implements OnInit {
  src='/assets/pdf/NAAC/Criterion-5/5.4.1_additional information.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
