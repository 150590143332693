<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/activities/gender"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>2022-23</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="one">
                            <a (click)="changeRoute('1')">Sports Policy</a>
                        </li>
                        <li class="two">
                            <a (click)="changeRoute('2')">Governance Committee</a>
                        </li>
                        <li class="three">
                            <a (click)="changeRoute('3')">KUK Oveall General Championships Since 1990-91</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">KUK Overall Championship Man Women 2022-23</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">KUK Games Championship Position</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">Medal Winners and Participants (International National University College Level)</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">Tournament Championships Orgainzed</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">Sports Courses</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">List of Sports Faculty</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">Sports Facilities</a>
                        </li>
                        <li class="eleven">
                            <a (click)="changeRoute('11')">Games offered in the College</a>
                        </li>
                        <li class="twelve">
                            <a (click)="changeRoute('12')">Finances on Sports</a>
                        </li>
                        <li class="thirteen">
                            <a (click)="changeRoute('13')">Scholarships</a>
                        </li>
                        <li class="fourteen">
                            <a (click)="changeRoute('14')">Appreciation Letter</a>
                        </li>
                        <li class="fifteen">
                            <a (click)="changeRoute('15')">Social Contribution</a>
                        </li>
                        <li class="sixteen">
                            <a (click)="changeRoute('16')">Departmental Activities</a>
                        </li>
                        <li class="seventeen">
                            <a (click)="changeRoute('17')">Report on Athletic Meet</a>
                        </li>
                        <li class="eighteen">
                            <a (click)="changeRoute('18')">Sports Alumni</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9" *ngIf="show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>