import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BoardsService {

  private boards:{name:string,designation:string}[]=[
    {name:'S.Randeep Singh Jauhar',designation:'President'},
    {name:'Major Rajinder Singh Bhatti',designation:'Vice-President'},
    {name:'S.Balwant Singh Banga',designation:'	General Secretary'},
    {name:'S.Amardeep Singh',designation:'Treasurer'},
    {name:'S. Hardeep Singh Gujral',designation:'Executive Member'},
    {name:'S. Kunwarjit Singh',designation:'Executive Member'},
    {name:'Dr. Bhupinder Singh Gaba',designation:'Member'},
    {name:'S.Tejbans Singh',designation:'Member'},
    {name:'S. Diljit Singh',designation:'Member'},
    {name:'Mrs. Khem Kaur',designation:'Member'},
    {name:'S.Gurmeet Singh',designation:'Member'},
    {name:'Mrs. Kiran Kaur',designation:'Member'},
    {name:'S. Joga Singh',designation:'Member'},
    {name:'S.Pardeep Singh Jauhar',designation:'Member'},
    {name:'Ms. Sara Jauhar',designation:'Member'},
    {name:'S. Harbir Inder Singh Randhawa',designation:'Member'},
  ]
  constructor() { }

  getBoards(){
    return this.boards.slice();
  }
}
