import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-physics',
  templateUrl: './physics.component.html',
  styleUrls: ['./physics.component.scss']
})
export class PhysicsComponent implements OnInit {
  physics:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.physics=this.facultyService.getPhysics();
  }

}
