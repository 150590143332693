import { Component, OnInit } from '@angular/core';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
declare var $:any;
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {

  constructor(private pathNameService:PathNameService) { }

  ngOnInit(): void {
    $('.carousel').carousel({
      interval: 6000
    })
  }
  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }

  admissionForm(id:number){

    if(id===1){
      window.open('https://apply.gnkc.ac.in/odforms/form/GNKCADMISSIONFORM/formperma/OlpTJRqS9vysjtKYJ_GRQ1-WfEv08sC-MQ9Scaws1ho');
    }
    if(id===2){
      window.open('https://apply.gnkc.ac.in/odforms/form/GNKCAdmissionEnquiryForm1/formperma/KrdlDpy3OiXL8wcvG5DR9qTvXj-gtmLlmO34NZvQLj8');
    }
    if(id===3){
      window.open('https://apply.gnkc.ac.in/odforms/form/GURUNANAKKHALSACOLLEGE/formperma/yr4n3xJGKyT5zRio0XzJI7lOwLQ0-8jQ1nCj0P6RpZA');
    }
   
    $("#applyModal1").modal('hide');
  }
  closeModal(){
    $("#applyModal").modal('hide');
  }
}
