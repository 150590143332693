<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria3" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <!-- Header -->
                <header class="header">
                    <h5>3.3.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">3.3.2 No. of books and chapters</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria3/3-3-2/3-3-2-1" (click)="checkRoute()" (click)="changeRoute('2')">3.3.2.1</a>
                    </li>
                    
                   
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'" *ngIf="!showPdf">
            <router-outlet></router-outlet>
        </div>
        <div class="col-md-9" *ngIf="showPdf">
            <div id="three-three-two">
                <div class="container-fluid pdf">
                    <div class="row">
                        <div class="col-12">
                            <div class="card card-body">
                                <h5 class="card-title text-center">No. of books and chapters</h5>
                                <div id="detail" class="text-center">
                                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>