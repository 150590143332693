<div id="heading">
    <h1>Perspective Plan</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Perspective Plan</li>
        </ol>
    </nav>
</div>

<div id="organ">
    <div class="container-fluid">
        <div class="card pl-3 pr-3 text-justify ml-lg-5">
            <h4 class="card-title text-center ">Perspective Plan</h4>
            <div class="card-body text-justify">
                <p class="lead mb-4">
                    <iframe src="https://drive.google.com/file/d/1jg1DYgq2YCWR1_PlHvDDQva0RzrAEYqz/preview" width="100%" height="640px" allow="autoplay"></iframe>
            </div>
        </div>
    </div>
</div>