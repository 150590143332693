import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-g-twotwo-twothree',
  templateUrl: './g-twotwo-twothree.component.html',
  styleUrls: ['./g-twotwo-twothree.component.scss']
})
export class GTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1ktA4r-P8fsSqnUTGaFVZKL5CJF7u_rGD/preview',
    'https://drive.google.com/file/d/1mz_kUNNstxg5WB3XduJhXSVUaTNr8gUl/preview'];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
