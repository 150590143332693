import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1214-eighteen',
  templateUrl: './c1214-eighteen.component.html',
  styleUrls: ['./c1214-eighteen.component.scss']
})
export class C1214EighteenComponent implements OnInit {
  c3321Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result: { title: string, link: string }[] = [
    {
      title: 'Communication Skills Certificates',
      link: this.c3321Url + '1.2.1.4_2018-19_Communication Skills Certificates.pdf'
  },
  {
      title: 'English and Soft Skills Certificates',
      link: this.c3321Url + '1.2.1.4_2018-19_English and Soft Skills Certificates.pdf'
  },
  {
      title: 'Human Values Certificates',
      link: this.c3321Url + '1.2.1.4_2018-19_Human Values Certificates.pdf'
  },
  {
      title: 'Presentation Skills Certificates',
      link: this.c3321Url + '1.2.1.4_2018-19_Presentation Skills Certificates.pdf'
  },
  {
      title: 'Yoga Certificates',
      link: this.c3321Url + '1.2.1.4_2018-19_Yoga Certificates.pdf'
  },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
