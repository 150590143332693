import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit,OnDestroy {
  constructor(
    private showcaseService:ShowcaseService,
    private pathNameService:PathNameService) {
    showcaseService.hideShowcase();
   }

  ngOnInit(): void {
   
   
  }
  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
