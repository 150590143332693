<div id="blog-items">
    <div class="social d-flex justify-content-end mt-2 mr-5">
        <app-button
        type='facebook'
        [shareUrl]='link'>
      </app-button>
      
      <br>
    
      <app-button
        type='twitter'
        [shareUrl]='link'>
      </app-button>
      <app-button
        type='whatsapp'
        [shareUrl]='link'>
      </app-button>
    </div>
   
    <div class="container-fluid description">
        <!-- <div class="row">
            <div class="col-12">
                <h2 class="card-title text-center">
                    Ways to build a resume when you have zero experience.
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5>Ms. Anchal Malhotra, Assistant Professor, Guru Nanak Khalsa Institute of Technology and Management Technical Campus, Yamuna Nagar-Haryana (India).</h5>
                <p class="lead text-justify mt-3">How do you write a resume with no experience? </p>
                <p class="lead text-justify mt-3">I need a job to get experience, but I need experience to get a job. Either way, you need a resume, and what you don't need is to panic. Just because you don't have existing skills that are relevant to the job or experience in a traditional work setting doesn't mean you can't craft a convincing first job resume. </p>
                <p class="lead text-justify mt-3">Here are four simple tactics to get you started on finding valuable experience, connecting with mentors, and getting employed by graduation. </p>
                <p class="lead text-justify mt-3">“How am I supposed to get a job when I don’t have any experience?”</p>
                <p class="lead text-justify mt-3">Experience is like the answer to a frustrating riddle. Every job wants you to already have it—you need experience to get in the door, but since you don’t have any experience, there’s seemingly no way for you to get it in the first place.</p>
                <p class="lead text-justify mt-3">Luckily, there are ways to get job experience when you don’t have any, as well as some workarounds to help you get your foot in the door.</p>
                <p class="lead text-justify mt-3">If you’re a student writing a resume with no experience, start doing these three things NOW. Before you know it, you’ll have some exciting content to include and the connections to actually send it out.</p>
                <ol>
                    <li>
                        <h5>Volunteer</h5>
                        <p class="lead text-justify mt-3">Take a look at the student union at your school or even reach out to your teachers; you never know what type of opportunities you may find, employers actually want to see if you’re committed to do things out of your routine class. If you’re serious about pursuing a career in a given industry, then you should seek out events happening in that industry. Reach out to see if you can help in any way. You’ll get to attend a pricey event for free and also meet people in your industry while gaining something to add to your resume. Employers want to see that you’re committed to things outside of just going to class. It shows dedication, plus great time-management skills.
                        </p>
                    </li>
                    <li>
                        <h5> Decide on a resume format</h5>
                        <p class="lead text-justify mt-3">There are a few dominant resume templates in use today: chronological, functional, and hybrid, which is a combination of the two. A chronological resume format lists a candidate's work experience in reverse-chronological order. A functional resume format focuses on highlighting the candidate's skills and achievements, rather than work experience. While the functional resume format can be an attractive option for job seekers with little experience, most employers prefer a chronological or hybrid resume format. Whatever resume format you decide to use, be sure that your format remains consistent throughout the job resume.
                        </p>
                    </li>
                    <li>
                        <h5>Internships, internships, internships</h5>
                        <p class="lead text-justify mt-3">Paid and unpaid college internships are one of the best weapons you have against "experience required." Not only do they give you some real-world work experience, they also allow you to network and make connections that can put you in a job later. When applying for a job without experience, be sure to list any internships you completed. If you haven't had one, consider applying as a step before an entry-level job.
                        </p>
                    </li>
                    <li>
                        <h5>Start using Social Media for right work</h5>
                        <p class="lead text-justify mt-3">Stop using your social media to post that photo of you and your friends that you look “oh so cute” in and start using it to help get yourself ahead and make connections that can lead to work. First off, take some time to do a social media sweep and make sure there’s nothing on there you wouldn’t want an employer to see.</p>
                        <p class="lead text-justify mt-3">Connect with them and send them a personal message on why you want to connect with them.</p>
                        <p class="lead text-justify mt-3">Next, update your LinkedIn profile to be a true reflection of you, and start utilizing that search bar. Search people in your city and within your industry. Connect with them and send them a personal message on why you want to connect with them. Once you connect, invite them to coffee or an informational interview.</p>
                        <p class="lead text-justify mt-3">Finding influential people in your industry on social media, then following and engaging with them is a much more informal way of building relationships. However, it’s a great way for possible mentors and employers to see just how passionate you are about your industry.</p>
                    </li>
                    <li>
                        <h5> Take stock of your achievements and activities</h5>
                        <p class="lead text-justify mt-3">Make a list of absolutely everything you've done that might be useful on a resume. From this list, you'll then need to narrow down what to actually include on your resume. Different things might be relevant to different jobs you apply for, so keep a full list and pick the most relevant things from it to include on your resume when you send it out.</p>
                        <p class="lead text-justify mt-3">You’re probably thinking, “But isn’t going to college enough?” and the answer is a big no. You can choose to postpone building your resume and network until the day you walk across that stage, but how are you going to compete with all the students that spent those four years doing something more?</p>
                    </li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 class="card-title text-center">
                    Coping Mechanism to deal with Exam Stress
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5>Ms. Anchal Malhotra, Assistant Professor, Guru Nanak Khalsa Institute of Technology and Management Technical Campus, Yamuna Nagar-Haryana (India).</h5>
                <p class="lead text-justify mt-3">Some students are just exam people – they have no trouble getting their heads down to revise and seem to fly through assessment periods without batting an eyelid. But for others exams can be incredibly stressful.</p>
                <p class="lead text-justify mt-3"><strong>That exam stress happens to all of us.</strong> According to The Globe and Mail, 90% of Canadian university students feel stressed and/or overwhelmed during exam periods.</p>
                <p class="lead text-justify mt-3">Post-secondary is stressful. Peak stress times are during midterms and exams. Shocker, right? Many students face multiple exams in a short amount of days. When the workload piles up, it creates enormous amounts of stress. When stress occurs, it can be really hard to escape. That can lead to panic and/or anxiety attacks. Students should feel calm, cool, and collected. Of course, that’s easier said than done, eh?</p>
                <p class="lead text-justify mt-3">So, how can we really cope with exam anxiety?</p>
                <p class="lead text-justify mt-3">There’s no right way to deal with stress. Everyone deals with stress in their own way. Everyone is different and will find mechanisms that work for them over others. By starting early, staying organized, and taking breaks, you will realize that stress CAN be controlled.</p>
                <ol>
                    <li>
                        <h5>Take regular breaks and schedule in fun things to look forward to</h5>
                        <p class="lead text-justify mt-3">Even the most intense exam timetables will allow a little time for a study break. This can include 20-minute breaks during your revision day, and longer activities that you can look forward to. Go out for dinner with friends, go to the cinema, attend a gig, anything that you like doing in your spare time that will take your mind off exams. Spending a little time away from the books will leave you feeling more refreshed and relaxed the next time you revise
                        </p>
                    </li>
                    <li>
                        <h5> Prepare Yourself, DON’T Compare Yourself</h5>
                        <p class="lead text-justify mt-3"><strong>We all do it. Yet, comparing yourself to your peers will stress you out even more.</strong> Psychology Today states that college mental health experts have found a strong correlation between student stress and social comparison. Your friend might know pretty much everything after a day or two of studying while you need more time to absorb the material. Do not stress! If you focus too much on what other people know, you won’t have time to learn the information yourself. That will stress you out even more. Study how you need to study to be successful!
                        </p>
                    </li>
                    <li>
                        <h5>Tune out the Exam Anxiety</h5>
                        <p class="lead text-justify mt-3"><strong>This is a personal favourite. Music has always been a great way for me to de-stress and it can work for you too!</strong> Whether you are taking a break to listen or listening while you work, both are equally beneficial. Psych Central states that listening to music offers a tremendous amount of relaxing effects on our minds and bodies. It slows our heart rate, lowers blood pressure and decreases levels of stress hormones.
                             Classical music is one of the best genres to listen to while studying (lyrics can sometimes be distracting)</p>
                        <p class="lead text-justify mt-3"><strong>Don’t cry your eyes out!</strong> Find ways to deal with stress and conquer your exams to the best of your abilities. Try any of these strategies, or try them all! Something will stick. <strong>Now that you have a sense of how to cope with your stress, start studying earlier, develop a plan, and conquer your goals.</strong></p>
                    </li>
                </ol>
            </div>
        </div> -->

              
    </div>


    <!-- <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="card-title text-center">
                    REASONS FOR RISING PRICES OF FUEL AND ITS IMPACT ON INDIAN ECONOMY(15th June)
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5>Ms. Puneet Jolly, Assistant Professor, Guru Nanak Khalsa Institute of Technology and Management Technical Campus, Yamuna Nagar-Haryana (India)</h5>
                <p class="lead text-justify mt-3">Petrol and diesel prices in the country have been on a skyrocketing journey over the past couple of months – it has crossed or inched closer to Rs 100-per-litre-mark across many cities including Mumbai and Delhi. The prices did go down for a brief period of time, however, commuters again saw an increase in retail prices of petrol and diesel by oil marketing companies for the last five to six weeks.</p>
                <p class="lead text-justify mt-3">Union Petroleum Minister Dharmendra Pradhan said that main reasons behind the fuel price rise are that:</p>
                <ol style="list-style: lower-alpha;">
                    <li>
                        <p class="lead text-justify mt-3"> International market has reduced fuel production</p>
                    </li>
                    <li>
                        <p class="lead text-justify mt-3">Manufacturing countries are producing less fuel to gain more profit. This is making the consumer countries suffer.</p>
                    </li>
                    <li>
                        <p class="lead text-justify mt-3">Country has to save money  to spend on welfare schemes and  over Rs 35,000 crores have been spent on vaccines by central/state government, in a year</p>
                    </li>
                </ol>
                <h5>IMPACT OF PRICE RISE ON INDIAN ECONOMY</h5>
                <ol>
                    <li>
                        <h5>Adverse impact on fiscal deficit:</h5>
                        <p class="lead text-justify mt-3">India imports 1.5 billion barrels of crude oil each year . This comes up to around 86% of its annual crude oil requirement. So, the surge in crude oil prices could increase India’s expenditure, thus adversely affecting India’s fiscal deficit - the difference between the government’s total revenue and total expenditure. Fiscal deficit indicates the amount of money the government has to borrow to meet its expenses. A rise in fiscal deficit could negatively affect the economy as well as markets. The fall in crude oil prices was a major contributing factor in the reduction of India’s fiscal deficit between 2014 and 2016, same is observed during the current years</p>
                    </li>
                    <li>
                        <h5>Impact on the rupee:</h5>
                        <p class="lead text-justify mt-3">The rise in crude oil prices has a clear impact on the Indian rupee. If crude oil prices remain at these high levels, the rupee is further expected to depreciate by the year end. Rupee depreciation has a reverberating effect on the Indian economy and even the stock market. RBI often takes a few steps to control the rupee’s fall.</p>
                    </li>
                    <li>
                        <h5>Impact on Current Account Deficit (CAD):</h5>
                        <p class="lead text-justify mt-3">India’s dependency on crude oil imports has only been increasing over the past few years. The dependency rose from 77.3% in FY2014 to 83.7% in FY2018. The rise in crude oil price has a big impact on the Indian Current Account Deficit (CAD). CAD is a measure of India’s trade where the value of goods and services imported exceeds the value of goods and services exported. CAD essentially indicates how much India owes the world in foreign currency. An SBI report suggests that Indian’s CAD could cross 2.5% of GDP for FY2019 (providing oil price continues at $80 per barrel). CAD has estimated at 1.9% for 2017-18.</p>
                    </li>
                    <li>
                        <h5> Impact on Stock markets</h5>
                        <p class="lead text-justify mt-3">The Indian stock markets have faced a lot of pressure due to the rise in crude oil prices. Between 1 and 24 May, 2018 alone, the Sensex fell by 2.3%.In comparison, the BSE small cap and mid cap indices have had it worse with a drop of nearly 8%. With crude oil prices touching $80 per barrel, there has been a sell-off in small cap and mid cap stocks. Analysts warn that this could continue if the crude oil price continues to rise.</p>
                    </li>
                    <li>
                        <h5>Impact on stocks:</h5>
                        <p class="lead text-justify mt-3">A lot of Indian companies depend on healthy crude oil prices. This includes tyre  , lubricants, footwear, refining and airline companies. The profitability of these companies is adversely affected due to higher input costs. This could negatively impact stock prices in the near term. On the other hand, oil exploration companies in the country could benefit from a rise in oil prices.</p>
                    </li>
                    <li>
                        <h5>Impact on inflation:</h5>
                        <p class="lead text-justify mt-3">Oil is a very important commodity and it is required to meet domestic fuel needs. And in addition to that, it is a necessary raw material used in a number of industries. An increase in the price of crude oil means that would increase the cost of producing goods. This price rise would finally be passed on to consumers resulting in inflation. Experts believe that an increase of $10/barrel in crude oil prices could raise inflation by 10 basis points (0.1%).</p>
                    </li>
                </ol>
                
            </div>
        </div>
    </div> -->
</div>

 