import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c5-five-three-two-one',
  templateUrl: './c5-five-three-two-one.component.html',
  styleUrls: ['./c5-five-three-two-one.component.scss']
})
export class C5FiveThreeTwoOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
