<div id="labs">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Lab</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Institution boasts of having the most advanced instruments in its laboratories like Atomic Absorption Spectrophotometer, Double Beam UV-Visible Spectrophotometers, Flame Photometer, EMG Machine, PCR Machine, Advanced Research Microscope, Analyser, etc.
                             Similarly there are more than 350 computers in various laboratories and offices.
                              The college campus is Wi-Fi connected.
                             There are six smart class rooms, ICT Centre, forty five laboratories and two seminar halls to facilitate the process of teaching and learning.</p>
                             <div id="labCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#labCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#labCarousel" data-slide-to="1"></li>
                                  <li data-target="#labCarousel" data-slide-to="2"></li>
                                  <li data-target="#labCarousel" data-slide-to="3"></li>
                                  <li data-target="#labCarousel" data-slide-to="4"></li>
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/lab/1.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/2.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item ">
                                    <img src="/assets/img/lab/3.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/4.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/5.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/6.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/7.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/8.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/lab/9.jpeg" alt="lab" class="img-fluid rounded">
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#labCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#labCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/1.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/2.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/3.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/4.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/5.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/6.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/7.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/8.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/lab/9.jpeg" alt="lab" class="img-fluid rounded">
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>