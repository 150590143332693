import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  newsChange= new BehaviorSubject<any[]>(null);
  // private news:{name:string,imgUrl:string}[]=[
  //   {name:'Multi faculty Career Workshop',imgUrl:'/assets/img/news/1.jpg'},
  //   {name:'International Online Computer Quiz',imgUrl:'/assets/img/news/2.jpg'},
  //   {name:'National Level Online Quiz Competition on "Covid-19"',imgUrl:'/assets/img/news/3.jpg'},
  //   {name:'Web Talk',imgUrl:'/assets/img/news/4.jpg'},
    
  // ];
  news:any[]=[];
  newsList:AngularFireList<any>;
  constructor(private firebase:AngularFireDatabase,) { }
  getNewsList(){
    this.newsList=this.firebase.list('news');  
    this.newsList.snapshotChanges().subscribe(
      list=>{
      this.news=  list.map(item=>{ return item.payload.val();})
      this.newsChange.next(this.news)
      // this.setCarousel(this.news.reverse());
      }
    )
  }

  getNews(){
    return this.news.slice();
  }

}
