import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competitive',
  templateUrl: './competitive.component.html',
  styleUrls: ['./competitive.component.scss']
})
export class CompetitiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
