import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-two-one',
  templateUrl: './ct-two-one.component.html',
  styleUrls: ['./ct-two-one.component.scss']
})
export class CtTwoOneComponent implements OnInit {
  src: string = '/assets/pdf/NAAC/DVV/2.1_Teachers Appointment Letters.pdf'
  constructor() { }

  ngOnInit(): void {
  }

}
