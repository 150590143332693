import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CalenderComponent } from 'src/app/view/calender/calender.component';
import { TwotwoTwothreeComponent } from 'src/app/view/calender/twotwo-twothree/twotwo-twothree.component';



@NgModule({
  declarations: [
    CalenderComponent,
    TwotwoTwothreeComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    SharedModule,
    RouterModule.forChild([
      {path:'', component:CalenderComponent, children:[
        {path:'2022-23', component:TwotwoTwothreeComponent}
      ]}
    ])
  ]
})
export class CalenderModule { }
