<div id="media-list">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Press & Media</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Press & Media</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="card-title text-center">Press & Media</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngFor="let media of imgList">
                <div class="card shadow-lg mx-auto">
                    <a href={{media.imgUrl}} data-lightbox="media"><img [src]="media.imgUrl" alt="" class="img-fluid "></a></div>
            </div>
        </div>
    </div>
</div>