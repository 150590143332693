<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/research/student-project"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Students Project</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">B.COM. EVS 2ND SEM.</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">B.SC. EVS 6TH SEM.</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">BA EVS 2ND SEM.</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">BCA_EVS 2ND SEM.</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">B.SC. SPORTS EVS 2ND SEM.</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">BBA_EVS 2ND SEM.</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">BBA 5TH SEM.</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">M.SC. PHYSICS 2ND SEM.</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">M.SC. PHYSICS 4TH SEM.</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">MA SOCIAL WORK 4TH SEM.</a>
                        </li>
                        <li class="eleven">
                            <a (click)="changeRoute('11')">BA SOCIAL WORK 2ND SEM.</a>
                        </li>
                        <li class="twelve">
                            <a (click)="changeRoute('12')">MA SOCIAL WORK 2ND SEM.</a>
                        </li>
                        <li class="thirteen">
                            <a (click)="changeRoute('13')">MA PUBLIC ADMN. 4TH SEM.</a>
                        </li>
                        <li class="fourteen">
                            <a (click)="changeRoute('14')">M.SC. BIO TECH 4TH SEM.</a>
                        </li>
                        <li class="fifteen">
                            <a (click)="changeRoute('15')">BA SOCIAL WORK 6TH SEM.</a>
                        </li>
                        <li class="sixteen">
                            <a (click)="changeRoute('16')">BA SOCIAL WORK 4TH SEM.</a>
                        </li>
                        <li class="seventeen">
                            <a (click)="changeRoute('17')">B.SC. IMB 5TH SEM.</a>
                        </li>
                        <li class="eighteen">
                            <a (click)="changeRoute('18')">B.P.ED.</a>
                        </li>
                        <li class="nineteen">
                            <a (click)="changeRoute('19')">B.ED. 2ND YEAR</a>
                        </li>
                        <li class="twenty">
                            <a (click)="changeRoute('20')">B.ED. 1ST YEAR</a>
                        </li>
                        <li class="twentyone">
                            <a (click)="changeRoute('21')">B.COM. 5TH SEM.</a>
                        </li>
                        <li class="twentytwo">
                            <a (click)="changeRoute('22')">B.COM. 3RD SEM.</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>