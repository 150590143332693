import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-one-two-two-three',
  templateUrl: './ct-one-two-two-three.component.html',
  styleUrls: ['./ct-one-two-two-three.component.scss']
})
export class CtOneTwoTwoThreeComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'Models certificates_2021-22',
      link:this.c241Url + '1.2.2.3_Models certificates_2021-22.pdf'
    },
    {
      title:'Models certificates_2020-21',
      link:this.c241Url + '1.2.2.3_Models certificates_2020-21.pdf'
    },
    {
      title:'Models certificates_2019-20',
      link:this.c241Url + '1.2.2.3_Models certificates_2019-20.pdf'
    },
    {
      title:'Models certificates_2018-19',
      link:this.c241Url + '1.2.2.3_Models certificates_2018-19.pdf'
    },
    {
      title:'Models certificates_2017-18',
      link:this.c241Url + '1.2.2.3_Models certificates_2017-18.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
