import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-one-one-nine',
  templateUrl: './c1-one-one-one-nine.component.html',
  styleUrls: ['./c1-one-one-one-nine.component.scss']
})
export class C1OneOneOneNineComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 1/1.1.1_CURRICULUM DELIVERY/1.1.1.9_notices.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }

}
