import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c1-one-four-one-two',
  templateUrl: './c1-one-four-one-two.component.html',
  styleUrls: ['./c1-one-four-one-two.component.scss']
})
export class C1OneFourOneTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.2_Analysis and ATR _5 years/1.4.1_Analysis and ATR 2021-22.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.2_Analysis and ATR _5 years/1.4.1_Analysis and ATR 2020-21.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.2_Analysis and ATR _5 years/1.4.1_Analysis and ATR 2019-20.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.2_Analysis and ATR _5 years/1.4.1_Analysis and ATR 2018-19.pdf', 
    '/assets/pdf/NAAC/Criterion 1/1.4.1_Feedback from different stakeholders/1.4.1.2_Analysis and ATR _5 years/1.4.1_Analysis and ATR 2017-18.pdf', ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    // this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[4];
    }

  }
  hideLoader() {
    this.isLoading = false;
  }
}
