<div id="important">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg my-3 px-md-4">
                <h4 class="card-title text-center ">Important Links</h4>
                <div class="card-body">
                    <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th scope="col">S.NO.</th>
                            <th scope="col">TITLE</th>
                            <th scope="col">URL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td class="title">National Council For Teacher Education (NCTE)</td>
                            <td class="link"><a href="http://www.ncte.gov.in" target="_blank">www.ncte.gov.in</a> </td>
                          </tr>
                         
                          <tr>
                            <th scope="row">2</th>
                            <td class="title">National Apprenticeship Training Scheme (NATS)</td>
                            <td class="link"><a href="http://www.mhrdnats.gov.in" target="_blank">http://www.mhrdnats.gov.in</a> </td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td class="title">Kurukshetra University</td>
                            <td class="link"><a href="http://kuk.ac.in" target="_blank">http://kuk.ac.in</a> </td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td class="title">Results announced by University</td>
                            <td class="link"><a href="https://Results.kuk.ac.in" target="_blank">https://Results.kuk.ac.in</a></td>
                          </tr>
                          <tr>
                            <th scope="row">5</th>
                            <td class="title">All India Council of Technical Education (AICTE) </td>
                            <td class="link"><a href="http://www.aicte-india.org" target="_blank">www.aicte-india.org</a></td>
                          </tr>
                          <tr>
                            <th scope="row">6</th>
                            <td class="title">University Grants Commission</td>
                            <td class="link"><a href="http://www.ugc.ac.in" target="_blank">www.ugc.ac.in</a></td>
                          </tr>
                          <tr>
                            <th scope="row">7</th>
                            <td class="title">Haryana State Counseling Society</td>
                            <td class="link"><a href="http://www.hscs.org" target="_blank">www.hscs.org</a></td>
                          </tr>
                          <tr>
                            <th scope="row">8</th>
                            <td class="title">Guru Nanak Khalsa college Yamunanagar</td>
                            <td class="link"><a href="https://gnkc.ac.in" target="_blank">https://gnkc.ac.in</a></td>
                          </tr>
                          <tr>
                            <th scope="row">9</th>
                            <td class="title">Guru Nanak Khalsa Group of Educational Institutions</td>
                            <td class="link"><a href="https://gnkgei.ac.in" target="_blank">https://gnkgei.ac.in</a></td>
                          </tr>
                         
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>
</div>