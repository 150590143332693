import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onenine-twozero',
  templateUrl: './onenine-twozero.component.html',
  styleUrls: ['./onenine-twozero.component.scss']
})
export class OnenineTwozeroComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1_Extension_Activities_Reports and Attendance_Final_SSR_Folder (2)/3.4.3 2019-20/';
  result:{title:string,link:string}[]=[
    {
      title:'ENVIRONMENT DAY',
      link:this.c343Url + '17_NCC_ENVIRONMENT DAY.pdf'
    },
    {
      title:'UNITY DAY',
      link:this.c343Url + '18_NCC_UNITY DAY.pdf'
    },
    {
      title:'ANTI CORRUPTION AWARENESS',
      link:this.c343Url + '19_NCC_ANTI CORRUPTION AWARENESS.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c343Url + '20_NCC_ROAD SAFETY RALLY.pdf'
    },
    {
      title:'DISTRIBUTION FOOD TO MIGRANTS',
      link:this.c343Url + '21_NCC_DISTRIBUTION FOOD TO MIGRANTS.pdf'
    },
    {
      title:'MAINTAINANCE OF LAW AND DUTY',
      link:this.c343Url + '22_NCC_MAINTAINANCE OF LAW AND DUTY.pdf'
    },
    {
      title:'SOCIAL SERVICE BY NCC CADETS',
      link:this.c343Url + '23_NCC_SOCIAL SERVICE BY NCC CADETS.pdf'
    },
    {
      title:'National Voter Day',
      link:this.c343Url + '24_NCC_National Voter Day.pdf'
    },
    {
      title:'NSS 7 Day Camp at Gdhauli',
      link:this.c343Url + '25_NSS_7 Day Camp at Gdhauli.pdf'
    },
    {
      title:'Tree Plantation at Gdhauli',
      link:this.c343Url + '26_NSS_Tree Plantation at Gdhauli.pdf'
    },
    {
      title:'National Deworming Day',
      link:this.c343Url + '27_NSS_National Deworming Day.pdf'
    },
    {
      title:'ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019',
      link:this.c343Url + '28_YRC_ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019.pdf'
    },
    {
      title:'NUKKAD NATAK ON BLOOD DONATION',
      link:this.c343Url + '29_YRC_NUKKAD NATAK ON BLOOD DONATION.pdf'
    },
    {
      title:'26TH ANNUAL BLOOD DONATION CAMP',
      link:this.c343Url + '30_YRC_26TH ANNUAL BLOOD DONATION CAMP.pdf'
    },
    {
      title:'NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION',
      link:this.c343Url + '31_YRC_NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
