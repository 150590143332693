import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-bed',
  templateUrl: './bed.component.html',
  styleUrls: ['./bed.component.scss']
})
export class BedComponent implements OnInit {

  education1:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.education1=this.facultyService.getEducation1();
  }

}
