import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c331-nineteen',
  templateUrl: './c331-nineteen.component.html',
  styleUrls: ['./c331-nineteen.component.scss']
})
export class C331NineteenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.1.1 Research/';

  result:{title:string,link:string}[]=[
    {
        title: 'Phytase producing LAB Cell factores for enhancing micro nutreient  bioovailablity  of phytate rich foods.',
        link: this.c3321Url + '16_Phytase-producing-LAB-Cell_2019-20.pdf'
    },
    {
        title: 'Performance and Risk Analysis of banking PSU debit Funds Regulars Plans of Debit Mutual Funds',
        link: this.c3321Url + '17_Performance-and-Risk-Analysis_2019-20.pdf'
    },
    {
        title: 'Combinatorial biobleaching of mixedwood pulp with lignolytic  of hemicellulalytic  enzymes for paper maleing.',
        link: this.c3321Url + '18_Combinatorial-biobleaching_2019-20.pdf'
    },
    {
        title: 'Baldev singh Garewal the Noval Parikarma: Ik Adhyan',
        link: this.c3321Url + '19_Baldev-singh-Garewal_2019-20.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
