import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports-science',
  templateUrl: './sports-science.component.html',
  styleUrls: ['./sports-science.component.scss']
})
export class SportsScienceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
