import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnInit, OnDestroy {
  fullWidth = false;
  showSideMenu = true;
  routeName = '';
  sub: Subscription;
  constructor(
    private showcaseService: ShowcaseService,
    private pathNameService: PathNameService
  ) {
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.splitUrl();
    });
    this.sub = this.pathNameService.urlChange.subscribe(res => {
      this.routeName = res;
    });
  }

  splitUrl() {
    let url = location.pathname.split('/');
    this.routeName = url[url.length - 1];
    if (url.length === 3 && (url[2] === 'workshops' || url[2] === 'student-project' || url[2] === 'mou' || url[2] === 'scholarship')) {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
    else if (url.length === 2 || (url.length === 3 && (url[2] === 'research-centre' || url[2] === 'major-research-project' || url[2] === 'minor-research-project' || url[2] === 'publication' || url[2] === 'Seminar-conference' || url[2] === 'guest-lecture' || url[2] === 'fdps' || url[2] === 'result' || url[2] === 'pos-cos'))) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }

  }

  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

  changeRoute(route: string) {
    this.routeName = route;
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }

}
