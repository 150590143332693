import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  social:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.social=this.facultyService.getSocial();
  }

}
