import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowImportantService {

  
  private showImportant:boolean=true;
  importantChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showImportant=true;
    this.importantChange.next(true);
  }
  hide(){
    this.showImportant=false;
    this.importantChange.next(false);
  }
}
