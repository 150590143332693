import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  routeName='';
  sub:Subscription;
 constructor(
   private pathNameService:PathNameService
 ) { }

 ngOnInit(): void {
   setTimeout(()=>{
     this.splitUrl();
   });
   this.sub=this.pathNameService.urlChange.subscribe(res=>{
     this.routeName=res;
   });
 }

 splitUrl(){
   let url=location.pathname.split('/');
   this.routeName=url[url.length-1];
   
 }

 changeRoute(route:string){
   this.routeName=route;
 }
}
