<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/e-contents"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>ARTS</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">DR. ANURAG</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">MS SHALINI BHARDWAJ</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">TILAK RAJ</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <!-- <h4 class="card-title text-center">MoUs,Collaborations for Faculty Exchange</h4> -->
                                <div class="card-body text-justify">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.NO.</th>
                                                <th scope="col">TITLE</th>
                                                <th scope="col">URL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of faculty; let i = index;">
                                                <th scope="row">{{i+1}}</th>
                                                <td class="title">{{item.title}}</td>
                                                <td class="link"><a href="{{item.link}}" target="_blank"><i
                                                            class="fas fa-eye"></i></a> </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</div>