import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-one-three',
  templateUrl: './c-one-three.component.html',
  styleUrls: ['./c-one-three.component.scss']
})
export class COneThreeComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 5 ){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if(url.length === 6 && url[5]==='1-3-1'){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
      this.showHeading = false;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
