import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BulletinService {
  private bulletin:string='';
  changeBulletin=new BehaviorSubject<string>('');
  constructor() { }

  setBulletin(bulletin:string){
    this.bulletin=bulletin[0];
    this.changeBulletin.next(this.bulletin);
  }
}
