import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-two-one-one',
  templateUrl: './two-one-one.component.html',
  styleUrls: ['./two-one-one.component.scss']
})
export class TwoOneOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students Data/2.1.1_Affiliation letters.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students Data/2.1.1_Course wise University Returns_5 years.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students Data/2.1.1_Students Intake.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students Data/2.1.1_Total Admitted students.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students Data/2.1.1_Withdrawn and Migrated.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students University Returns/2.1.1_RETURN_2021-22.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students University Returns/2.1.1_RETURN_2020-21.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students University Returns/2.1.1_RETURN_2019-20.pdf',
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students University Returns/2.1.1_RETURN_2018-19.pdf', 
    '/assets/pdf/NAAC/Criterion 2/2.1.1/2.1.1_Students University Returns/2.1.1_RETURN_2017-18.pdf'];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    // this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.sixth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.sixth').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.sixth').removeClass('active');
      $('.ten').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.sixth').removeClass('active');
      $('.nine').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    

  }
  hideLoader() {
    this.isLoading = false;
  }
}
