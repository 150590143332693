import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { CommerceComponent } from "src/app/view/faculty/commerce/commerce.component";
import { ManagementComponent } from "src/app/view/faculty/commerce/management/management.component";
import { CommunityComponent } from "src/app/view/faculty/community/community.component";
import { EduBedComponent } from "src/app/view/faculty/education/edu-bed/edu-bed.component";
import { EduBpedComponent } from "src/app/view/faculty/education/edu-bped/edu-bped.component";
import { EducationComponent } from "src/app/view/faculty/education/education.component";
import { FacultyComponent } from "src/app/view/faculty/faculty.component";
import { AdministrationComponent } from "src/app/view/faculty/humanities/administration/administration.component";
import { BedComponent } from "src/app/view/faculty/humanities/bed/bed.component";
import { CommunicationComponent } from "src/app/view/faculty/humanities/communication/communication.component";
import { EconomicsComponent } from "src/app/view/faculty/humanities/economics/economics.component";
import { EnglishComponent } from "src/app/view/faculty/humanities/english/english.component";
import { GeographyComponent } from "src/app/view/faculty/humanities/geography/geography.component";
import { HindiComponent } from "src/app/view/faculty/humanities/hindi/hindi.component";
import { HistoryComponent } from "src/app/view/faculty/humanities/history/history.component";
import { HumanitiesComponent } from "src/app/view/faculty/humanities/humanities.component";
import { MusicComponent } from "src/app/view/faculty/humanities/music/music.component";
import { PoliticalScienceComponent } from "src/app/view/faculty/humanities/political-science/political-science.component";
import { PublicAdministrationComponent } from "src/app/view/faculty/humanities/public-administration/public-administration.component";
import { PunjabiComponent } from "src/app/view/faculty/humanities/punjabi/punjabi.component";
import { SanskritComponent } from "src/app/view/faculty/humanities/sanskrit/sanskrit.component";
import { SocialComponent } from "src/app/view/faculty/humanities/social/social.component";
import { BiotechnologyComponent } from "src/app/view/faculty/science/biotechnology/biotechnology.component";
import { BotonyComponent } from "src/app/view/faculty/science/botony/botony.component";
import { ChemistryComponent } from "src/app/view/faculty/science/chemistry/chemistry.component";
import { ComputerScienceComponent } from "src/app/view/faculty/science/computer-science/computer-science.component";
import { IndustrialChemistryComponent } from "src/app/view/faculty/science/industrial-chemistry/industrial-chemistry.component";
import { MathematicsComponent } from "src/app/view/faculty/science/mathematics/mathematics.component";
import { MicrobilogyComponent } from "src/app/view/faculty/science/microbilogy/microbilogy.component";
import { PhysicsComponent } from "src/app/view/faculty/science/physics/physics.component";
import { ScienceComponent } from "src/app/view/faculty/science/science.component";
import { ZoologyComponent } from "src/app/view/faculty/science/zoology/zoology.component";
import { HealthComponent } from "src/app/view/faculty/sports-science/health/health.component";
import { SportsScienceComponent } from "src/app/view/faculty/sports-science/sports-science.component";
import { TeachingStaffComponent } from "src/app/view/faculty/teaching-staff/teaching-staff.component";

const route:Route[]=[
    {path:'faculty',redirectTo:'/faculty/humanities/economics',pathMatch:'full'},
    {path:'',component:FacultyComponent,children:[
        {path:'humanities',component:HumanitiesComponent,children:[
            {path:'economics',component:EconomicsComponent},
            {path:'english',component:EnglishComponent},
            // {path:'education-bed',component:BedComponent},
            {path:'geography',component:GeographyComponent},
            {path:'hindi',component:HindiComponent},
            {path:'history',component:HistoryComponent},
            {path:'political-science',component:PoliticalScienceComponent},
            {path:'public-administration',component:PublicAdministrationComponent},
            // {path:'education',component:AdministrationComponent},
            {path:'punjabi',component:PunjabiComponent},
            {path:'social-work',component:SocialComponent},
            {path:'mass-communication',component:CommunicationComponent},
            {path:'sanskirt',component:SanskritComponent},
            {path:'music',component:MusicComponent}
        ]},
        {path:'science',component:ScienceComponent,children:[
            {path:'botony',component:BotonyComponent},
            {path:'mathematics',component:MathematicsComponent},
            {path:'physics',component:PhysicsComponent},
            {path:'computer-science',component:ComputerScienceComponent},
            {path:'zoology',component:ZoologyComponent},
            {path:'chemistry',component:ChemistryComponent},
            {path:'industrial-chemistry',component:IndustrialChemistryComponent},
            {path:'microbiology',component:MicrobilogyComponent},
            {path:'biotechnology',component:BiotechnologyComponent}
        ]},
        {path:'commerce',component:CommerceComponent,children:[
            {path:'commerce&management',component:ManagementComponent}
        ]},
        {path:'sports-sciences',component:SportsScienceComponent,children:[
            {path:'health-education',component:HealthComponent}
        ]},
        {path:'education',component:EducationComponent, children:[
            {path:'education-bed',component:EduBedComponent},
            {path:'physical-education-bped',component:EduBpedComponent},
        ]},
        {path:'community',component:CommunityComponent},
        {path:'teaching-staff',component:TeachingStaffComponent}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]

})
export class FacultyRoutingModule{}