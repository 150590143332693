<section id="company-hiring" class="section">
    <div class="container d-none d-lg-block">
        <a href="#" class="btn button3 view-all view-all1" routerLink="/menu-detail/placement" (click)="setUrl('Placement Cell')">View all companies...</a>
        <a href="#" class="btn button3 view-all view-all2" routerLink="/courses/arts" (click)="setUrl('Arts')">View all Courses...</a>
        <h2 class="font-weight-bold" style="text-shadow: rgb(0 0 0 / 30%) 0 2px 4px;">Companies Hiring</h2>
        <p class="lead">Top companies are hiring from GNKC</p>
            <div class="stats-bg clearfix">
            <div class="animate">
            <ul class="clearfix">
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/16.png"></li>
                                        <li><img class="" data-src="/assets/img/hiring/1.png" alt="logo" src="/assets/img/hiring/1.png"></li>
                                        <li><img class="" data-src="/assets/img/hiring/2.png" alt="logo" src="/assets/img/hiring/2.png"></li>
                                        <li><img class="" data-src="/assets/img/hiring/3.png" alt="logo" src="/assets/img/hiring/3.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/4.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/5.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/6.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/7.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/8.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/9.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/10.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/11.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/12.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/13.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/14.png"></li>
                                        <li><img class=""  alt="logo" src="/assets/img/hiring/15.png"></li>
                                        
                                    </ul>
            </div>
            <div class="round round1">
                <span class="sm-round"></span>
                <div class="content">
                    <figure>
                        <img class="" data-src="/assets/img/hiring/17.png" alt="icon" src="/assets/img/hiring/17.png">
                    </figure>
                    <h4>80%</h4>
                    <p>Over 80% placements</p>
                </div>
            </div>
            <div class="round round2">
                <!-- <span class="sm-round"></span> -->
                <div class="animate1">
                    <ul class="clearfix">
                                                <li routerLink="/courses/arts">Arts</li>
                                                <li routerLink="/courses/science">Science</li>
                                                <li routerLink="/courses/commerce">Commerce</li>
                                                <li routerLink="/courses/physical">Physical</li>
                                                <li routerLink="/courses/ugc">UGC</li>
                                                <li routerLink="/courses/education">Education</li>
                                                <!-- <li>Add On</li> -->
                                                
                                            </ul>
                    </div>
                <div class="content" style="top: 71px;left:190px;position: absolute;">
                    <!-- <figure>
                        <img class="" data-src="/assets/img/hiring/18.png" alt="icon" src="/assets/img/hiring/18.png">
                    </figure> -->
                    <h4>Top Courses</h4>

                    <!-- <p>Top Courses</p> -->
                </div>
            </div>
            <div class="round round3">
                <span class="sm-round"></span>
                <div class="content">
                    <figure>
                        <img class="" data-src="/assets/svg/hiring/company.svg" alt="icon" src="/assets/svg/hiring/company.svg">
                    </figure>
                    <h4>350+</h4>

                    <p>companies hiring world wide</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid d-block d-lg-none">

        <div class="row">
            <div class="col-12">
                <h2>Companies Hiring</h2>
                <p class="lead">Top companies are hiring from GNKGEI</p>
                <a href="#" class="btn button3 view-all" routerLink="/menu-detail/placement" (click)="setUrl('Placement Cell')">View all companies...</a>
            </div>
        </div>

    </div>
</section>