import { Component, HostListener, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-one-two-one',
  templateUrl: './ct-one-two-one.component.html',
  styleUrls: ['./ct-one-two-one.component.scss']
})
export class CtOneTwoOneComponent implements OnInit {
  @HostListener("click") onClick() {
    this.checkRoute();
  }
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/1.2.1.1_Certificate from head of the HEI.pdf',
    '/assets/pdf/NAAC/DVV/1.2.1.2_Summary report of add-on courses.pdf',];
  src: string;

  showPdf = false;
  showTable=false;
  isLoading = true;
  showSideMenu = true;
  fullWidth = false;
  heading1 = false;
  heading2 = false;
  heading3=false;
  heading4=false;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1:{title:string,link:string}[]=[
    {
      title:'2021-22 Attendance and Signature of students of add-on course',
      link:this.c241Url + '2021-22_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2020-21 Attendance and Signature of students of add-on course',
      link:this.c241Url + '2020-21_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2019-20 Attendance and Signature of students of add-on course',
      link:this.c241Url + '2019-20_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2018-19 Attendance and Signature of students of add-on course',
      link:this.c241Url + '2018-19_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2017-18 Attendance and Signature of students of add-on course',
      link:this.c241Url + '2017-18_Attendance and Signature of students of add-on course.pdf'
    },
    
  ]

  result2:{title:string,link:string}[]=[
    {
      title:'Models certificates_2021-22',
      link:this.c241Url + '1.2.1_Models certificates_2021-22.pdf'
    },
    {
      title:'Models certificates_2020-21',
      link:this.c241Url + '1.2.1_Models certificates_2020-21.pdf'
    },
    {
      title:'Models certificates_2019-20',
      link:this.c241Url + '1.2.1_Models certificates_2019-20.pdf'
    },
    {
      title:'Models certificates_2018-19',
      link:this.c241Url + '1.2.1_Models certificates_2018-19.pdf'
    },
    {
      title:'Models certificates_2017-18',
      link:this.c241Url + '1.2.1_Models certificates_2017-18.pdf'
    }
  ]

  result:{title:string,link:string}[]=[];

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
      this.heading1 = true;
      this.heading2 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
      this.heading1 = false;
      this.heading2 = true;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).addClass('show');
      $(`five`).removeClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=true;
      this.heading3=true;
      this.heading4=false;
      this.result = this.result1;
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
    }
    if (id === '5') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).addClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=true;
      this.heading3=false;
      this.heading4=true;
      this.result = this.result2;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

  splitUrl() {
    let url = location.pathname.split('/');
    // console.log(url);
    if (url.length === 5) {
      this.showSideMenu = true;
      this.fullWidth = false;
      // this.showHeading = true;
    }
    else if(url.length === 6 && url[5]==='1-2-1-4'){
      this.showSideMenu=false;
      this.fullWidth=true;
      // this.showHeading = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }
}
