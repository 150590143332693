import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EContentComponent } from 'src/app/view/e-content/e-content.component';
import { ArtsComponent } from 'src/app/view/e-content/arts/arts.component';
import { BEdComponent } from 'src/app/view/e-content/b-ed/b-ed.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ScienceComponent } from 'src/app/view/e-content/science/science.component';
import { SportsScienceComponent } from 'src/app/view/e-content/sports-science/sports-science.component';
import { CommerceComponent } from 'src/app/view/e-content/commerce/commerce.component';



@NgModule({
  declarations: [
    EContentComponent,
    ArtsComponent,
    BEdComponent,
    CommerceComponent,
    ScienceComponent,
    SportsScienceComponent,
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule,
    RouterModule.forChild([
      {path:'', component:EContentComponent, children:[
        {path:'arts', component:ArtsComponent},
        {path:'b-ed', component:BEdComponent},
        {path:'commerce', component:CommerceComponent},
        {path:'science', component:ScienceComponent},
        {path:'sports-science', component:SportsScienceComponent},
      ]}
  ])
  ]
})
export class EContentModule { }
