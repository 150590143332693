<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/activities/gender"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>2022-23</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">Annual Gender Sensitization Action Plan and Activities</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">Facilities for Women and Safety and Security Measures</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9" *ngIf="show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">

                                    <!-- <h5 class="card-title text-center"></h5> -->
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>