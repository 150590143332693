
<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-9 about-detail">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2 class="font-weight-bold "  data-scroll>About Us</h2>
                        <hr class=" p-1" style="width: 160px;">
                    </div>
                    <div class="col-12 about-detail-name text-center">
                        <h4 class="font-weight-bold text-left" data-scroll >WELCOME TO GURU NANAK KHALSA COLLEGE</h4>
                        <hr class="d-none d-md-block">
                        <p class="lead text-justify" data-scroll>
                            Guru Nanak Khalsa College, Yamuna Nagar, a premier post-graduate institution, affiliated to Kurukshetra University, Kurukshetra, was established in 1968 with a very few departments and a handful of students on the eve of quin-centenary of Guru Nanak Dev Ji's birthday.
                             The objective of the institution was to impart state of the art education to all sections of the society.
                    

                        </p>
                        
                        <p class="lead text-justify d-none d-lg-block" data-scroll>Today this institution boasts of five faculties, 22 departments with 116 teaching faculty and around 3200 students.
                             The college offers around 19 different programmes at UG level and 11 at PG level for a varied choice extending from regular degree courses to the professional courses as per industry current and futuristic requirements.
                        </p>
                             <button class="btn btn-warning " data-scroll routerLink="/about/profile">Read More</button>
                    </div>
                </div>
            </div>
            <div class="col-3 d-none d-sm-block about-img">
                <img src="/assets/img/showcase/16.jpg" alt="Building" class="img-fluid rounded">
            </div>
        </div>
    </div>
</div>

<!-- <app-message-header></app-message-header> -->