<div class="background">
    <div class="container">
      <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="showAlert">
        We received Your Query. Contact You Shortly!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showAlert=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showDanger">
        There is some issue in our end. Please Try Again!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showDanger=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="screen shadow-lg">
        <div class="screen-header">
          <div class="screen-header-left">
            <div class="screen-header-button close" style="margin-top: 10px;"></div>
            <div class="screen-header-button maximize"></div>
            <div class="screen-header-button minimize"></div>
          </div>
          <div class="screen-header-right">
            <div class="screen-header-ellipsis"></div>
            <div class="screen-header-ellipsis"></div>
            <div class="screen-header-ellipsis"></div>
          </div>
        </div>
        <div class="screen-body">
          <div class="screen-body-item left">
            <div class="app-title">
              <span>CONTACT</span>
              <span>US</span>
            </div>
            <div class="app-contact">CONTACT INFO : 01732-221675</div>
          </div>
          <div class="screen-body-item">
            <div class="app-form">
                <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                    <div class="app-form-group">
                        <input class="app-form-control" placeholder="NAME" type="text" name="name"
                        ngModel id="name" required minlength="2">
                      </div>
                      <div class="app-form-group">
                        <input class="app-form-control" placeholder="EMAIL" type="email" name="email"
                        id="email"
                        required 
                        email
                        ngModel>
                      </div>
                      <div class="app-form-group">
                        <input class="app-form-control" placeholder="CONTACT NO" 
                        type="tel"
                        name="phone"
                        id="phone"
                        required
                        minlength="10"
                        maxlength="10"
                        ngModel>
                      </div>
                      <div class="app-form-group message">
                        <input class="app-form-control" placeholder="MESSAGE" type="text" 
                        name="message"
                        id="message"
                        required
                        minlength="3"
                        ngModel>
                      </div>
                      <div class="app-form-group buttons">
                        <button class="app-form-button" type="reset">CANCEL</button>
                        <button class="app-form-button" [disabled]="!contactForm.valid" type="submit">SEND</button>
                      </div>
                </form>
              
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  