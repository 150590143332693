import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-best-practice',
  templateUrl: './best-practice.component.html',
  styleUrls: ['./best-practice.component.scss']
})
export class BestPracticeComponent implements OnInit {
  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
