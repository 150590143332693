import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soft-skill',
  templateUrl: './soft-skill.component.html',
  styleUrls: ['./soft-skill.component.scss']
})
export class SoftSkillComponent implements OnInit {
  pdfSrc: string = 'https://drive.google.com/file/d/1_PcZ6cPaiaIONJ2FcMaheiqsUqDUSl61/preview';
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc;
  }

}
