import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-zoology',
  templateUrl: './zoology.component.html',
  styleUrls: ['./zoology.component.scss']
})
export class ZoologyComponent implements OnInit {
  zoology:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.zoology=this.facultyService.getZoology();
  }

}
