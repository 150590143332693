import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {
  music:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.music=this.facultyService.getMusic();
  }

}
