import { Component, OnInit } from '@angular/core';
import { Staff } from 'src/app/models/staff.model';
import { StaffDetailService } from 'src/app/services/staff/staff-detail.service';
declare var $:any;
@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  adStaff:Staff[];
  girlHostel:Staff[];
  library:Staff[];
  supportingStaff:Staff[];
  groundmen:Staff[];
  security:Staff[];
  supportStaff:Staff[];
  constructor(private staffService:StaffDetailService) { }

  ngOnInit(): void {
    this.getStaffDetail();
  }

  getStaffDetail(){
    this.adStaff=this.staffService.getAdStaff();
    this.girlHostel=this.staffService.getGirlHostel();
    this.library=this.staffService.getLibrary();
    this.supportingStaff=this.staffService.getSupportingStaff();
    this.groundmen=this.staffService.getGroundmen();
    this.security=this.staffService.getSecurity();
    this.supportStaff=this.staffService.getSupportStaff();
  }
    

}
