import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-two-one',
  templateUrl: './two-two-one.component.html',
  styleUrls: ['./two-two-one.component.scss']
})
export class TwoTwoOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
