import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-lp-twothree-twofour-even',
  templateUrl: './lp-twothree-twofour-even.component.html',
  styleUrls: ['./lp-twothree-twofour-even.component.scss']
})
export class LpTwothreeTwofourEvenComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1sLsB3GY9KlcxKuQZbW9pKWc93umc6iKU/preview', 
    'https://drive.google.com/file/d/1A7tKZqW-XyNUjhXMLC8vWzPxrGfy5kia/preview' ,
    'https://drive.google.com/file/d/1nnFMKyzg3rpzNMURUdiW-GzZWE15BIxz/preview', 
    'https://drive.google.com/file/d/1FQLnjxJBh8watEQ1mX__Dm_DDffg53-x/preview',
    'https://drive.google.com/file/d/1zXJA_JUrWWcpNClru-L64QUrvVkyLar_/preview',
    'https://drive.google.com/file/d/1MXFYHymMcr7cegAhJ7BkaxD5HfTb2e3V/preview',
    'https://drive.google.com/file/d/1r6YsxDUYrCdiQpzUr1odGKht499wWJwn/preview',
    'https://drive.google.com/file/d/15rT2PIHX5ZaPNpFsBGuLH55Q1u7n8ZP4/preview',
    'https://drive.google.com/file/d/1x_CV-yJLgTku7FaJm-05NPb5CGSOuGra/preview',
    'https://drive.google.com/file/d/1oZc0XNQ24o0yPEZOXlq-FseCPOHkyEwM/preview',
    'https://drive.google.com/file/d/1U9N2SwsqzTNHi2C4gcon_901zicO68l5/preview',
    'https://drive.google.com/file/d/1zOmKVpY1UWK2HPOx35vgR9yhlbp9bhPf/preview',
    'https://drive.google.com/file/d/1sCiFGraKOxCGR-e-4iaGZHkPO1Q2A7lm/preview',
    'https://drive.google.com/file/d/1zTrry8nEowMvoBol6XW7yeM5DNKycxa3/preview',
    'https://drive.google.com/file/d/1FmxcejTBHjXBhMObLasOMl2LVieOHv-r/preview',
    'https://drive.google.com/file/d/1yRWRG8dMviG-1v8DG6H1Ovk6K7wcjCSw/preview',
    'https://drive.google.com/file/d/1i9tmhkfkKp6tLYKwjIyJOLLv2vir10ec/preview',
    'https://drive.google.com/file/d/1E3gHKnJKJdISkG1hC6w_IjSVhhmcPvA0/preview',
    'https://drive.google.com/file/d/1cJeqTPuyZWZGuArQuA0UizTUchraHbNq/preview',
    'https://drive.google.com/file/d/1zAdd3A9pjNb4JNWYamO_c6jJu8umVExE/preview',
    'https://drive.google.com/file/d/1XpcjXCtakdEa3KLVr2Wjyoljbg4w3Qm2/preview',
    'https://drive.google.com/file/d/1Z4He0iiIcGONVmlhzXWE5ZC95C3hOpY6/preview',
    'https://drive.google.com/file/d/1VaWzA28iAPJX65wnJA1_NxpcRuODULOm/preview',
    'https://drive.google.com/file/d/1uZVAUDCocR1mEKtKn3_YHxfDGP8U9fVe/preview',
    'https://drive.google.com/file/d/1xSoiFDLjatrMo2lZsGpKEwLf_h4yznqF/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    if (year == '23') {
      $('.twentythree').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[22];
    }
    if (year == '24') {
      $('.twentyfour').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfive').removeClass('active');
      this.src = this.pdfSrc[23];
    }
    if (year == '25') {
      $('.twentyfive').removeClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[24];
    }
    
  }
  hideLoader() {
    this.isLoading = false;
  }
}
