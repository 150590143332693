import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-six-five-two',
  templateUrl: './ct-six-five-two.component.html',
  styleUrls: ['./ct-six-five-two.component.scss']
})
export class CtSixFiveTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/6.5.2.2_List of Collaborative Quality Initiatives.pdf',
    '/assets/pdf/NAAC/DVV/6.5.2.3_Action Taken Report.pdf',
    '/assets/pdf/NAAC/DVV/6.5.2.4_Collaborative Quality.pdf',];
  src: string;

  showPdf = false;
  showTable=false;
  isLoading = true;
  heading1 = false;
  heading2 = false;
  heading3=false;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1:{title:string,link:string}[]=[
    {
      title:'IQAC Meetings',
      link:this.c241Url + '6.5.2.5_IQAC Meetings.pdf'
    },
    {
      title:'Sealed and Signed Certificate',
      link:this.c241Url + '6.5.2.5_Sealed and Signed Certificate.pdf'
    },
    
  ]

  result:{title:string,link:string}[]=[];

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      
      this.heading1 = true;
      this.heading2 = false;
      
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      
      this.heading1 = false;
      this.heading2 = true;
      
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).addClass('show');
      $(`four`).removeClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=false;
      this.heading3=true;
      
      this.src = this.pdfSrc[2];
      
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).addClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=true;
      this.heading3=false;
      
      this.result = this.result1;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

  
}
