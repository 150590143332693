import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/services/contact/contact.service';
import {environment} from './../../../../environments/environment';
declare var Email:any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  showAlert=false;
  showDanger=false;
  constructor( private contactService:ContactService) { }

  ngOnInit(): void {
    // this.contactService.fetchContacts();
  }

  onSubmit(form:NgForm){
    if(!form.valid){
      return;
    }
    let name =form.value.name;
    let phone=form.value.phone;
    let email=form.value.email;
    let message=form.value.message;
    let date=new Date();
    let status="new";
    let id=this.idGenrator();
    const contact:Contact=new Contact(id,name,phone,message,email,status,date);
    this.contactService.setContacts(contact);
    form.reset();
    // let data=`Hi, <br>
    // <strong> My Phone Number is : ${phone}. </strong> <br>
    // <strong> Message : </strong> ${message}. <br>
    // Thanks, <br>
    // ${name}`;
    // Email.send({
    //     Host: environment.hostName,
    //     Username: environment.userName,
    //     Password: environment.password,
    //   To : 'khalsawins@gmail.com',
    //   From : environment.userName,
    //   Subject : `Query By ${email}`,
    //   Body : data
    // }).then( message => {
    //  if(message=='OK'){
    //    this.showAlert=true;
    //  }
    // else{
       
    //    this.showDanger=true;
    //  }
    //    form.resetForm(); },
    //     );

  }
  idGenrator() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };
}
