import { Component, OnInit } from '@angular/core';
import { StarService } from 'src/app/services/star/star.service';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {
  star:{name:string,degree:string,img:string}[];
  constructor(private starService:StarService) { }

  ngOnInit(): void {
    this.star=this.starService.getStar();
  }
  

}
