import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  routeName = '';
  sub: Subscription;
  constructor(
    private showcaseService: ShowcaseService,
    private pathNameService: PathNameService
  ) {
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    this.sub = this.pathNameService.urlChange.subscribe(res => {
      this.routeName = res;
    });
  }

  changeRoute(route: string) {
    this.routeName = route;
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }

}
