import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-science',
  templateUrl: './science.component.html',
  styleUrls: ['./science.component.scss']
})
export class ScienceComponent implements OnInit {

  routeName='';
   sub:Subscription;
  constructor(private pathNameService:PathNameService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.routeName=res;
    });
  }
  splitUrl(){
    let url=location.pathname.split('/');
    this.routeName=url[url.length-1];
    
  }

  changeRoute(route:string){
    this.routeName=route;
  }

}
