<div id="infra">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>{{routeName}}</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item"><a >Infrastructure</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Infrastructure</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Girls-Hostel"
                            (click)="changeRoute('Girls Hostel')">Girls Hostel</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Gymnasium-Hall" (click)="changeRoute('Gymnasium Hall')">Gymnasium Hall</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Gurudwara-Sahib" (click)="changeRoute('Gurudwara Sahib')">Gurudwara Sahib</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Labs" (click)="changeRoute('Labs')">Labs</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Language-Lab" (click)="changeRoute('Language Lab')">Language Lab</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/ICT-Lab"  (click)="changeRoute('ICT Lab')">ICT Lab</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Canteen"
                            (click)="changeRoute('Canteen')">Canteen</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Play-Fields" (click)="changeRoute('Play Fields')">Play Fields</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Girls-Common-Room" (click)="changeRoute('Girls Common Room')">Girls Common Room</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Boys-Common-Room" (click)="changeRoute('Boys Common Room')">Boys Common Room</a>
                        </li>

                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/virtual" (click)="changeRoute('Virtual classroom')">Virtual classroom</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/recording" (click)="changeRoute('Lecture Recording Studio')">Lecture Recording Studio</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/audio" (click)="changeRoute('Audio Visual Hall')">Audio Visual Hall</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/health" (click)="changeRoute('Health & Fitness Centre')">Health & Fitness Centre</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/khelo" (click)="changeRoute('Khelo India Center')">Khelo India Center</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/competitive" (click)="changeRoute('Competitive Exam Guidance Corner')">Competitive Exam Guidance Corner</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/digital" (click)="changeRoute('Digital Library')">Digital Library</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/ethics" (click)="changeRoute('Corner for Ethics')">Corner for Ethics</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/seminar-hall" (click)="changeRoute('Seminar Hall')">Seminar Hall</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/library/about" (click)="changeRoute('About Library')">About Library</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/library/subscribed" (click)="changeRoute('Subscribed e-Resources')">Subscribed e-Resources</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/infrastructure/library/open-access" (click)="changeRoute('Open Access e-Resources')">Open Access e-Resources</a>
                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/infrastructure/EDUSAT" (click)="changeRoute('EDUSAT')">EDUSAT</a>
                        </li> -->
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/infrastructure/Photocopying" (click)="changeRoute('Photocopying')">Photocopying</a>
                        </li> -->
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>