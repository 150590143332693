import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticeService } from 'src/app/services/notice/notice.service';
import { ShowNoticeService } from 'src/app/services/showNotice/show-notice.service';
declare var $:any;
@Component({
  selector: 'app-notice-item',
  templateUrl: './notice-item.component.html',
  styleUrls: ['./notice-item.component.scss']
})
export class NoticeItemComponent implements OnInit {
  id:string;
  sub:Subscription;
  urlPath='';
  constructor(private showNoticeService:ShowNoticeService,
    private route:ActivatedRoute,
    private noticeService:NoticeService,
    private router:Router) { 
      showNoticeService.hide();
  }

  ngOnInit(): void {
    this.sub=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        const path=this.noticeService.searchNotice(this.id);
        if(path){
          if(path.includes('view?usp=sharing')){
            this.urlPath=path.replace('view?usp=sharing','preview');
            $('#noticeItem').attr('src', this.urlPath);
            this.urlPath=path;
          }else{
            this.urlPath=path;
            $('#noticeItem').attr('src', path);
          
          }
        }else{
          this.router.navigate(['not-found']);
        }
        
        
      }
    )
  }

  ngOnDestroy(): void {
      this.showNoticeService.show();
  }
}
