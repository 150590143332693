<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <!-- Header -->
                <header class="header">
                    <h5>CRITERIA 5</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria5/5-1" (click)="checkRoute()">5.1</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria5/5-2" (click)="checkRoute()">5.2</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria5/5-3" (click)="checkRoute()">5.3</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria5/5-4" (click)="checkRoute()">5.4</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9" *ngIf="showHeading">
            <h4 class="text-center"> Student Support & Progression</h4>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>