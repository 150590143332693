<div id="placement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Placement Cell</h4>
                    <div class="card-body">
                        <div class="row text-center">
                            <div class="col-12">
                                <img src="assets\img\placement\1.jpeg" alt="" class="img-fluid rounded">
                            </div>
                        </div>
                        
                        <!-- <iframe src="https://drive.google.com/file/d/1gtSt8kDFAUgYqYG4CZ6KHDr3hvjq5J0s/preview" width="100%" height="980" allow="autoplay"></iframe> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>