import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-two-four-one',
  templateUrl: './ct-two-four-one.component.html',
  styleUrls: ['./ct-two-four-one.component.scss']
})
export class CtTwoFourOneComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'2.4.1_Teacher without repeat count 3B (1)',
      link:this.c241Url + '2.4.1_Teacher-without-repeat-count-3B(1).pdf'
    },
    {
      title:'2.4.1_Teachers during 5 years (3 B)',
      link:this.c241Url + '2.4.1_Teachers during 5 years (3 B).pdf'
    },
    {
      title:'2.4.1_Teachers during 2021-22 (3 A)',
      link:this.c241Url + '2.4.1_Teachers during 2021-22 (3 A).pdf'
    },
    {
      title:'2.4.1 (1) JUSTIFICATION FOR PHYSICAL EDUCATION',
      link:this.c241Url + '2.4.1 (1) JUSTIFICATION FOR PHYSICAL EDUCATION.pdf'
    },
    {
      title:'2.4.1 (2) Sanction letter',
      link:this.c241Url + '2.4.1 (2) Sanction letter.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }
}
