<div id="canteen">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Canteen</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The newly built College Canteen is housed in Baba Banda Bahadar Block and is equipped with ultra modern facilities.
                             It has a seating capacity of 150 students where they can enjoy North Indian and South Indian delicacies.</p>
                             <div id="canteenCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#canteenCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#canteenCarousel" data-slide-to="1"></li>
                                
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/canteen/1.jpeg" alt="canteen" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/canteen/2.jpeg" alt="canteen" class="img-fluid rounded">
                                  </div>
                                 
                                </div>
                                <a class="carousel-control-prev" href="#canteenCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#canteenCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/canteen/1.jpeg" alt="canteen" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/canteen/2.jpeg" alt="canteen" class="img-fluid rounded">
                            </div>
                           
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>