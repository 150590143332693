import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-economics',
  templateUrl: './economics.component.html',
  styleUrls: ['./economics.component.scss']
})
export class EconomicsComponent implements OnInit {

  economics:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.economics=this.facultyService.getEconomics();
  }

}
