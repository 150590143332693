import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlumniComponent } from 'src/app/view/menu-detail/alumni/alumni.component';
import { MenuDetailComponent } from 'src/app/view/menu-detail/menu-detail.component';
import { PlacementComponent } from 'src/app/view/menu-detail/placement/placement.component';
import { StarsComponent } from 'src/app/view/menu-detail/stars/stars.component';
import { TestimonialComponent } from 'src/app/view/menu-detail/testimonial/testimonial.component';
import { RouterModule } from '@angular/router';
import { MenuDetailRoutingModule } from './menu-detail-routing.module';
import { ContactUsComponent } from 'src/app/view/menu-detail/contact-us/contact-us.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundModule } from '../not-found/not-found/not-found.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from 'src/environments/environment';
import { NaacComponent } from 'src/app/view/menu-detail/naac/naac.component';
import { NaacItemComponent } from 'src/app/view/menu-detail/naac/naac-item/naac-item.component';
import { NcteItemComponent } from 'src/app/view/menu-detail/ncte/ncte-item/ncte-item.component';
import { NcteComponent } from 'src/app/view/menu-detail/ncte/ncte.component';
import { NoticeComponent } from 'src/app/view/menu-detail/notice/notice.component';
import { NoticeItemComponent } from 'src/app/view/menu-detail/notice/notice-item/notice-item.component';
import { CareerComponent } from 'src/app/view/menu-detail/career/career.component';
import { CareerItemComponent } from 'src/app/view/menu-detail/career/career-item/career-item.component';



@NgModule({
  declarations: [
    MenuDetailComponent,
    StarsComponent,
    PlacementComponent,
    AlumniComponent,
    TestimonialComponent,
    ContactUsComponent,
    NaacComponent,
    NaacItemComponent,
    NcteComponent,
    NcteItemComponent,
    NoticeComponent,
    NoticeItemComponent,
    CareerComponent,
    CareerItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenuDetailRoutingModule,
    FormsModule,
    HttpClientModule,
    NotFoundModule,
    PdfViewerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule
  ]
})
export class MenuDetailModule { }
