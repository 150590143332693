<div id="ncc">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">NCC</h4>
                    <div class="card-body">
                        <h5>NCC (National Cadet Corps)</h5>
                        <hr class="rounded">
                        <p class="lead text-justify">N.C.C. is a very popular and efficiently-run organization in the college.
                             The cadets are governed by N.C.C. rules laid down by the university from time to time.
                              Besides whole time N.C.C. Officers, Dr. (Major) Harinder Singh Kang and Dr. Ramneek of this college conduct the training and administration of N.C.C. in the college.
                               The college has three platoons of N.C.C. for boys and one for girls.
                             Students on N.C.C rolls are required to complete 75% of the parades during the year. 
                        </p>
                        <div class="row my-3">
                            <div class="col-lg-6 mt-2">
                                <img src="/assets/img/ncc/5.jpeg" alt="NCC" class="img-fluid rounded ">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <img src="/assets/img/ncc/3.jpeg" alt="NCC" class="img-fluid rounded ">
                            </div>
                        </div>
                        <p class="lead text-justify">
                            Qualifications in N.C.C. prove advantageous to the students in their future career.
                             32 seats are reserved for ‘C’ certificate holders in I.M.A. per course on the basis of merit.
                             Various courses like Para-training, Mountaineering with Rock Climbing, Basic Leadership and Advanced Leadership courses are open to N.C.C. Cadets, N.C.C. Cadets with 50% marks in graduation, below 25 years and ‘B’ Grade in ‘C’ Certificate are eligible for direct SSB interview for Officer Training Academy, Chennai (Exempted from Written test.) 5 marks weightage is awarded to ‘C’ Certificate holders in various colleges/universities and job sectors. 
                        </p>
                        <p class="lead text-justify">The cadet/cadets who have represented Directorate in the Republic Day Parade, New Delhi will be granted additional seats in PG courses besides he/she fulfills the basic eligibility conditions. </p>
                        <p class="lead text-justify font-weight-bold text-dark">Commissioned Officer In INDIAN ARMY</p>
                        <div class="rank mb-5">
                            <table class="table table-bordered table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Course</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>Vijay Bakshi</td>
                                    <td>OTA</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                      <td>Harshvir Singh</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">3</th>
                                      <td>Sunil Bhardwaj</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">4</th>
                                      <td>Avinash Nair</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">5</th>
                                      <td>Mayank Goel</td>
                                      <td>IMA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">6</th>
                                      <td>Ashish Bajpai</td>
                                      <td>IMA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">7</th>
                                      <td>Kanwaljeet Singh</td>
                                      <td>IMA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">8</th>
                                      <td>Aditya Wali</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">9</th>
                                      <td>Tarun Sawhney</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">10</th>
                                      <td>Dushyant</td>
                                      <td>NDA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">11</th>
                                      <td>Trivender Singh</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">12</th>
                                      <td>Narinder S.N. Singh</td>
                                      <td>INA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">13</th>
                                      <td>Saurabh Bali</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">14</th>
                                      <td>Vivek Kler</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">15</th>
                                      <td>Sonia Soni</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">16</th>
                                      <td>Himani Luthra</td>
                                      <td>OTA</td>
                                  </tr>
                                  <tr>
                                      <th scope="row">17</th>
                                      <td>Mohit Jatt</td>
                                      <td>IMA</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/1.jpeg" alt="NCC" class="img-fluid rounded h250 ">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/2.JPG" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/4.JPG" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <!-- <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div> -->
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/7.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/8.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/9.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <!-- <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/11.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div> -->
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/12.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/13.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/14.jpeg" alt="NCC" class="img-fluid rounded h250" style="object-fit: cover;">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/ncc/15.jpeg" alt="NCC" class="img-fluid rounded h250">
                            </div>
                        </div>

                        <button class="btn btn-info mt-5 text-light"  data-toggle="collapse" href="#ncc-form" >NCC Enrolment Form</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="collapse row mt-5"  id="ncc-form">
            <div class="col-12 nccForm">
                <div class="card shadow-lg" >
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showAlert">
                        Something went Wrong. Please try again!
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showAlert=false">
                         <span aria-hidden="true">&times;</span>
                       </button>
                       </div>
                       <div class="alert alert-success alert-dismissible" role="alert" *ngIf="showSuccess">
                        Form Is successfully Submitted.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showSuccess=false">
                         <span aria-hidden="true">&times;</span>
                       </button>
                       </div>
                    <h4 class="card-title">NCC Enrolment Form</h4>
                    <p class="lead">Fields marked with an <strong style="color: var(--red);"> *</strong>  are required</p>

                    <form  #nccform="ngForm" (ngSubmit)="onSubmit(nccform)">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Student Name <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="name" 
                                        name="name" 
                                        aria-describedby="Student Name" 
                                        placeholder="Enter Student Name"
                                        ngModel
                                        required
                                        minlength="4">
                                        <small id="name" class="form-text text-muted">Full name should be given.</small>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Father Name <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="fatherName" 
                                        name="FatherName" 
                                        aria-describedby="Father Name" 
                                        placeholder="Enter Father Name"
                                        ngModel
                                        required
                                        minlength="4">
                                        <small id="name" class="form-text text-muted">Full name should be given.</small>
                                      </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Class <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="class" 
                                        name="class" 
                                        aria-describedby="Class" 
                                        placeholder=" Enter Class"
                                        ngModel
                                        required
                                        minlength="2">
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Roll No. <strong>*</strong></label>
                                        <input type="tel" 
                                        class="form-control" 
                                        id="roll" 
                                        name="roll" 
                                        aria-describedby="Roll No." 
                                        placeholder=" Enter Roll No."
                                        ngModel
                                        required
                                        minlength="1"
                                        maxlength="6">
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="gender">Gender <strong>*</strong></label>
                                        <select class="form-control" id="gender" name="gender" required ngModel>
                                          <option selected>Male</option>
                                          <option>Female</option>
                                          <option>Others</option>
                                        </select>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="category">Category <strong>*</strong></label>
                                        <select class="form-control" id="category" name="category" required ngModel>
                                          <option selected>Gen</option>
                                          <option>SC</option>
                                          <option>ST</option>
                                          <option>BC</option>
                                          <option>Minority</option>
                                          <option>Handicap</option>
                                          <option>Fatherless</option>
                                        </select>
                                      </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="email">Email <strong>*</strong></label>
                                        <input type="email" 
                                        class="form-control" 
                                        id="email" 
                                        name="email" 
                                        aria-describedby="Email" 
                                        placeholder="Enter Email"
                                        ngModel
                                        required
                                        email>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="mobile">Contact No. <strong>*</strong></label>
                                        <input type="tel" 
                                        class="form-control" 
                                        id="mobile" 
                                        name="mobile" 
                                        aria-describedby="Contact No." 
                                        placeholder="Enter Contact No."
                                        ngModel
                                        required
                                        minlength="10"
                                        maxlength="10">
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="message">Participation In Other Activities <strong>*</strong></label>
                                        <textarea class="form-control" id="message"
                                        name="message" rows="3" required ngModel minlength="2"></textarea>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-primary mr-3" type="submit" [disabled]="!nccform.valid">Submit</button>
                            <button class="btn btn-primary" type="button" data-toggle="collapse" href="#ncc-form">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>