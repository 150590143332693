<div id="aqar-link">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>AQAR Links</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <!-- <li class="breadcrumb-item"><a href="#">AQAR Links</a></li> -->
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">AQAR Links</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>AQAR Links</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/wp-content/uploads/2020/02/2018-19-pdf" >2018-19.pdf</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/wp-content/uploads/2020/03/2-7-1-2018-19-converted-pdf"
                            >2.7.1_2018_19-converted.pdf</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/wp-content/uploads/2020/03/2-7-1-2017-18-converted-pdf"
                            >2.7.1_2017_18-converted.pdf</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/wp-content/uploads/2020/03/Best-Practices-2018-19-pdf"
                            >Best-Practices-2018_19.pdf</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/wp-content/uploads/2020/03/Committees-2018-19-pdf"
                            >Committees-2018_19.pdf</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>