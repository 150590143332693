<div id="career-list" *ngIf="showCareer">
    <div class="container-fluid mt-5 px-lg-5">
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="card-title">ADVERTISEMENTS</h3>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col" class="w-45">Title</th>
                <th scope="col" class="w-45">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let career of careers; let i=index">
                <th scope="row">{{career.date}}</th>
                <td><a href="{{career.path}}" target="_blank">{{career.title}}</a></td>
                <td>{{career.desc}}</td>
                <!-- <td (click)="hideCareer()"><a [routerLink]="['/menu-detail/career',career.path]">{{career.title}}</a></td> -->
              </tr>
             
            </tbody>
          </table>
    </div>
  </div>
  <div *ngIf="!showCareer">
  <router-outlet></router-outlet>
  </div>
  
  <!-- <iframe src="https://drive.google.com/file/d/1R2eIb9wFkdAMJSAv25wotU4JjONN2ZRc/preview"></iframe> -->