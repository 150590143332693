import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReversePipe } from 'src/app/pipes/reverse/reverse.pipe';
import { ButtonComponent } from 'src/app/view/shared/button/button.component';
import { SafePipe } from 'src/app/pipe/safe/safe.pipe';



@NgModule({
  declarations: [
    ReversePipe,
    ButtonComponent,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ReversePipe,
    ButtonComponent,
    SafePipe
  ]
})
export class SharedModule { }
