<div id="second">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                   
                    <div class="card-body">
                        
                        <iframe src="https://drive.google.com/file/d/1eI20ev9wVJIjML9ivyYpOopauYr6fijW/preview" width="100%" height="1100px" allow="autoplay"></iframe>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>


<!-- <div id="second">
    <div class="container-fluid text-center mt-3">
        <div class="btn-group btn-group-toggle " data-toggle="buttons" role="group" aria-label="Message Button Group">
            <button type="button" 
            class="btn mr-1 first" 
            id="first-pdf" 
            data-toggle="collapse" 
            data-target="#pdf1" 
            aria-expanded="true" 
            aria-controls="pdf1"
            (click)="showCard(1)">Best-Practices-2018_19.pdf </button>

            <button type="button" 
            class="btn mr-1" 
            id="second-pdf" 
            data-toggle="collapse" 
            data-target="#pdf2" 
            aria-expanded="true" 
            aria-controls="pdf2"
            (click)="showCard(2)">2.7.1_2018_19-converted.pdf</button>

            <button type="button" 
            class="btn mr-1" 
            id="third-pdf" 
            data-toggle="collapse" 
            data-target="#pdf3" 
            aria-expanded="true" 
            aria-controls="pdf3"
            (click)="showCard(3)">2.7.1_2017_18-converted.pdf</button>
            
            <button type="button" 
            class="btn mr-1" 
            id="fourth-pdf" 
            data-toggle="collapse" 
            data-target="#pdf4" 
            aria-expanded="true" 
            aria-controls="pdf4"
            (click)="showCard(4)">Committees-2018_19.pdf</button>

           
        </div>

        
        <div class="row collapse show multi-collapse" id="pdf1">
            <iframe src="https://drive.google.com/file/d/1mcnJCTYrBmAPCibrJOCnycaJx4OLdsi4/preview" width="100%" height="1100px" allow="autoplay"></iframe>
        </div>

     
        <div class="row collapse  multi-collapse" id="pdf2">
            <iframe src="https://drive.google.com/file/d/1eI20ev9wVJIjML9ivyYpOopauYr6fijW/preview" width="100%" height="1100px" allow="autoplay"></iframe>
        </div>

  
        <div class="row collapse multi-collapse" id="pdf3">
            <iframe src="https://drive.google.com/file/d/1Tow-VcTAcrPP55ZPRF828N9325l1EMME/preview" width="100%" height="1100px" allow="autoplay"></iframe>
        </div>

         <div class="row collapse multi-collapse" id="pdf4">
            <iframe src="https://drive.google.com/file/d/16CT4sR6MleHW3meyHzbJGxehBmR3fhZm/preview" width="640" height="480" allow="autoplay"></iframe>
        </div>
       
    </div>
</div> -->