import { Injectable } from '@angular/core';
import { Staff } from 'src/app/models/staff.model';

@Injectable({
  providedIn: 'root'
})
export class StaffDetailService {
  private adStaff:Staff[]=[
    {name:'Mr. Hazara Singh',img:'/assets/img/staff/Hazara_Singh.JPG',position:'Dy. Superintendent'},
    // {name:'Ms. Jaswinder Kaur',img:'/assets/img/staff/Jaswinder_Kaur.jpeg',position:'Steno'},
    {name:'Mr. Krishan Chand Sharma',img:'/assets/img/staff/Krishan_Chand_Sharma.JPG',position:'Clerk'},
    {name:'Mr. Sohan Lal',img:'/assets/img/staff/Sohan_Lal.JPG',position:'Clerk'},
    // {name:'Mr. Amarjit Singh Bhatia',img:'/assets/img/staff/5.jpeg',position:'Clerk'},
    {name:'Mr. Yash Pal Saini',img:'/assets/img/staff/Yash_Pal_Saini.jpeg',position:'Fee-Clerk'},
    {name:'Mr. Jashan Lal',img:'/assets/img/staff/Jashan_Lal.JPG',position:'Clerk'},
    {name:'Mr. Ravneet Singh',img:'/assets/img/staff/Ravneet_Singh.JPG',position:'Peon'},
    {name:'Mr. Harpreet Singh',img:'/assets/img/staff/Harpreet_Singh.JPG',position:'Peon'},
    {name:'Mr. Jagdeep Singh',img:'/assets/img/staff/Jagdeep_Singh.jpg',position:'Peon'},
    {name:'Mr. Sunil Kumar',img:'/assets/img/staff/Sunil_Kumar.JPG',position:'Clerk'},
    {name:'Mr. Ram Avtar',img:'/assets/img/staff/Ram_Avtar.JPG',position:'Data Entry Operator'},
    {name:'Mr. Bipin Kumar',img:'/assets/img/staff/Bipin_Kumar.JPG',position:'Asst Store Keeper'},
    {name:'Mr. Parmod Kamboj',img:'/assets/img/staff/Parmod_Kamboj1.jpeg',position:'Computer Technician'},
    {name:'Mr. Sahil Khan',img:'/assets/img/staff/Sahil_Khan.JPG',position:'Data Entry Operator'},
    {name:'Mr. Avneet Singh',img:'/assets/img/staff/avneet.png',position:'Steno Cum Computer Operator'},
    {name:'Ms. Nisha',img:'/assets/img/staff/Ms Nisha.jpg',position:'Account Clerk'},
    {name:'Mr. Lalit Kumar',img:'/assets/img/staff/Mr. Lalit .jpg',position:'DEO'},
    {name:'Mr. Arun',img:'/assets/img/staff/Mr Arun.jpg',position:'DEO'},
    {name:'Mr. Vipin',img:'/assets/img/staff/Mr. Vipin.jpg',position:'DEO'},
    {name:'Ms. Shivani',img:'/assets/img/staff/Ms Shivani.jpg',position:'DEO'},
    {name:'Ms. Navjot Kaur',img:'/assets/img/staff/Navjot_Kaur.JPG',position:'Receptionist'},
  ];

  private girlHostel:Staff[]=[
    {name:'Ms. Asha Chhabra',img:'/assets/img/faculty/math/2.jpg',position:'Warden'}
  ];

  private library:Staff[]=[
    {name:'Dr. Ramneek Ahlawat',img:'/assets/img/staff/Ramneek_Ahlawat.JPG',position:'Librarian'},
    // {name:'Ms. Ravita Saini',img:'/assets/svg/staff/2.svg',position:'Librarian (B.Ed.)'},
    {name:'Ms. Inderjit Kaur',img:'/assets/img/staff/Inderjit_Kaur.jpeg',position:'Library Attendant'}
  ];

  private supportingStaff:Staff[]=[
    {name:'Mrs. Poonam Bhasin',img:'/assets/img/staff/Poonam_Bhasin.jpeg',position:'Tech. Asstt.'}
  ];
  private groundmen:Staff[]=[
    {name:'Mr. Mahi Pal',img:'/assets/img/staff/Mahi_Pal.jpeg',position:'Physical Education'},
    {name:'Mr. Vijay Singh',img:'/assets/img/staff/Vijay_Singh.jpeg',position:'Physical Education'},
    {name:'Mr. Manjit Singh',img:'/assets/img/staff/Manjit_Singh.jpeg',position:'Physical Education'},
    
    {name:'Mr. Phool Chand',img:'/assets/svg/staff/2.svg',position:'Microbiology and Botany'}
  ];

  private security:Staff[]=[
    
    {name:'Mr. Gurvinder Singh',img:'/assets/svg/staff/2.svg',position:'Security Guard'},
    {name:'Mr. Gurinder Singh',img:'/assets/svg/staff/2.svg',position:'Cycle Stand Guard'}
  ];

  private supportStaff:Staff[]=[
    // {name:'Mr. Asha Ram',img:'/assets/img/staff/Asha_Ram.jpeg',position:'Mali'},
    {name:'Mr. Ravinder Kumar',img:'/assets/img/staff/Ravinder_Kumar.jpeg',position:'Sweeper'}
  ];
  constructor() { }

  getAdStaff(){
    return this.adStaff.slice();
  }

  getGirlHostel(){
    return this.girlHostel.slice();
  }

  getLibrary(){
    return this.library.slice();
  }

  getSupportingStaff(){
    return this.supportingStaff.slice();
  }

  getGroundmen(){
    return this.groundmen.slice();
  }

  getSecurity(){
    return this.security.slice();
  }

  getSupportStaff(){
     return this.supportStaff.slice();
  }
}
