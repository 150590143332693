import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-six',
  templateUrl: './two-six.component.html',
  styleUrls: ['./two-six.component.scss']
})
export class TwoSixComponent implements OnInit {
  fullWidth=false;
  showSideMenu = false;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 5){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if(url.length === 6 && (url[5]=== '2-6-1' || url[5]=== '2-6-2' || url[5]=== '2-6-3')){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
