import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Form, NgForm } from '@angular/forms';
import { interval, Observable, Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
import {FormGroup} from '@angular/forms';
import { OtpApiService } from 'src/app/services/otpApi/otp-api.service';
import { timer } from 'rxjs';
declare var bodymovin:any, $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit ,OnDestroy{
  showHeader=true;
  showShowcase=true;
  sub:Subscription[]=[];
  isLoading:boolean[]=[];


  // OtpModal Variable
  private timer:Observable<any>;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  hasPhone=false;
  count=0;
  resendButton=false;
  status='';
  showResend=false;
  verifyOtp=false;
  newOtp=false;
  phone:number;
  constructor(private showcaseService:ShowcaseService,
    private pathNameService:PathNameService,
    private otpService:OtpApiService) { }

  ngAfterViewInit(){
    
  }
  ngOnInit(): void {
    setTimeout(()=>{
      this.sub[1]=this.showcaseService.changedShowcase.subscribe(val=>{
        this.showShowcase=val;
        if(this.showShowcase){
          $('#showcase').attr('style','display:block');
        }
        if(!this.showShowcase){
          $('#showcase').attr('style','display:none');
        }
      });
      this.sub[2]=this.showcaseService.changedHeader.subscribe(val=>{
        if(val){
          $('#header').attr('style','display:block');
        }
        if(!val){
          $('#header').attr('style','display:none');
        }
      })
    });
    
   this.setLoader();
    $('.carousel').carousel({
      pause:false
    })
    this.setHeader();
    this.setCounter();
    this.scrollHeader();
    // otp Modal 
    // if($(window).innerWidth()<992){
    //   document.getElementById('types').className='d-none';
    //     document.getElementById('typeshover').className='d-none';
    // }
    
  }
  scrollHeader(){
    $(document).ready(function() {
      $(window).scroll(function () {
        //if you hard code, then use console
        //.log to determine when you want the 
        //nav bar to stick.  
        if($(window).innerWidth()>=568){
          if ($(window).scrollTop() >= 110) {
            $('#menu-bar').attr('style','display:none');
            $('#menu-header1').attr('style','display:block');
            
          }
          if ($(window).scrollTop() == 0 || $(window).scrollTop() <= 109  ) {
           
            $('#menu-header1').attr('style','display:none');
            $('#menu-bar').attr('style','display:block');
          }
        }
        if($(window).innerWidth()<568){
          $('#menu-header1').attr('style','display:none');
          $('#menu-bar').attr('style','display:block');
        }
      
     
    });
    });
    
  }
  
  setHeader(){
    const source = interval(5000);
    this.sub[0]=source.subscribe(val=>{
     this.showHeader=!this.showHeader;
     if(!this.showHeader){
      //  document.getElementById('group-name').className='d-none animate__animated animate__slideInDown';
      //  document.getElementById('group-welcome').className='d-block animate__animated animate__slideInDown';
       if($(window).innerWidth()>991 && this.showShowcase==true){
        document.getElementById('types').className='d-none animate__animated animate__fadeIn';
        document.getElementById('typeshover').className='d-none d-lg-block animate__animated animate__fadeIn';
      }
     }
     else{
      // document.getElementById('group-welcome').className='d-none animate__animated animate__slideInDown';
      // document.getElementById('group-name').className='d-block animate__animated animate__slideInDown';
      if($(window).innerWidth()>991 && this.showShowcase==true){
        document.getElementById('typeshover').className='d-none animate__animated animate__fadeIn';
        document.getElementById('types').className='d-none d-lg-block animate__animated animate__fadeIn';
      }
     }
    });
  }
  
  setCounter(){
    $('.counter').counterUp();
  }

  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }

  onSubmit(form:NgForm){
    if(form.invalid){
      form.reset();
      return;
    }
    form.reset();
  }
  setLoader(){
    for(let i=0;i<4;i++){
      this.isLoading.push(true);
    }
  }
  hideLoader(i:number){
    this.isLoading[i]=false;
  }

  onSubmitPhone(form:NgForm){
    if(form.invalid){
      return;
    }
    this.setTimer();
    let phone=form.value.phone;
    this.phone=phone;
    this.otpService.sendOtp(phone).subscribe(res=>{
      if(res.type=='success'){
        this.otpService.setPhone(phone);
        
      }
    });
    this.showResend=true;
    
    

  }
  onSubmitotp(form:NgForm){
    this.verifyOtp=false;
    if(form.invalid){
      return;
    }
    let otp=form.value.otp;
    this.otpService.verifyOtp(otp,this.phone).subscribe(res=>{
      if(res.type=='success'){
        this.showResend=false;

        window.open('https://data.odpay.in/odforms/form/GNKCADMISSIONFORM/formperma/OlpTJRqS9vysjtKYJ_GRQ1-WfEv08sC-MQ9Scaws1ho?mobile='+this.phone);
        $("#otpModal").modal('hide');
        form.reset();
      }
      else{
        this.verifyOtp=true;
        form.reset();
        
      }
    });
    
    

  }
  resendOtp(){
    this.newOtp=false;
    this.otpService.resendOtp(this.phone).subscribe(res=>{
      if(res.type=='success'){
        this.newOtp=true;
      }
    });
    this.setTimer();
  }
  setTimer(){
    this.resendButton=true;
    if(this.count<3){
      this.timer=timer(6000);
      this.sub[2]=this.timer.subscribe(()=>{
        this.count++;
        this.resendButton=false;
        
      });
    }
   
  }

  scrollTo(id:string){
    document.getElementById(id).scrollIntoView();

  }
  admissionForm(id:number){

    if(id===1){
      window.open('https://apply.gnkc.ac.in/odforms/form/GNKCADMISSIONFORM/formperma/OlpTJRqS9vysjtKYJ_GRQ1-WfEv08sC-MQ9Scaws1ho');
    }
    if(id===2){
      window.open('https://apply.gnkc.ac.in/odforms/form/GNKCAdmissionEnquiryForm1/formperma/KrdlDpy3OiXL8wcvG5DR9qTvXj-gtmLlmO34NZvQLj8');
    }
    if(id===3){
      window.open('https://forms.gnkgei.ac.in/odforms/form/GURUNANAKKHALSACOLLEGE/formperma/yr4n3xJGKyT5zRio0XzJI7lOwLQ0-8jQ1nCj0P6RpZA');
    }
   
    $("#applyModal").modal('hide');
  }
  paymentUrl(id:string){
      window.open(id);
  }
  ngOnDestroy(){
    this.sub.forEach(s=>{
      s.unsubscribe();
    });
  }

  LinkUrl(id:string){
    window.open(id);
  }
  closeModal(){
    $("#applyModal").modal('hide');
  }
  
}
