import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-team-report',
  templateUrl: './team-report.component.html',
  styleUrls: ['./team-report.component.scss']
})
export class TeamReportComponent implements OnInit {
  pdfSrc:string= '/assets/pdf/NAAC/Team Report.pdf';
  password:string = 'gnkc2023';
  src:string;
  showPdf=false;

  isLoading=true;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc;
  }

  hideLoader(){
    this.isLoading=false;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    if(form.value.password == this.password){
      this.showPdf = true;
      this.isLoading=false;
    }else{
      alert("Incorrect Password!")
    }
  }
}
