import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-six-three-three',
  templateUrl: './ct-six-three-three.component.html',
  styleUrls: ['./ct-six-three-three.component.scss']
})
export class CtSixThreeThreeComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/6.3.3.1_Faculty Development Program Data Template.pdf',
    '/assets/pdf/NAAC/DVV/6.3.3.2_Number of teachers (FDP).pdf',
    '/assets/pdf/NAAC/DVV/6.3.3.4 Non teaching staff (FDP).pdf',
    '/assets/pdf/NAAC/DVV/6.3.3.5_Year wise list.pdf',
    '/assets/pdf/NAAC/DVV/6.3.3.6_ Annual Reports.pdf',];
  src: string;

  showPdf = false;
  showTable=false;
  isLoading = true;
  heading1 = false;
  heading2 = false;
  heading3=false;
  heading4=false;
  heading5=false;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1:{title:string,link:string}[]=[
    {
      title:'FDP certificates 2021-22',
      link:this.c241Url + '1. FDP certificates 2021-22.pdf'
    },
    {
      title:'FDP certificates 2020-21',
      link:this.c241Url + '2. FDP certificates 2020-21.pdf'
    },
    {
      title:'FDP certificates 2019-20',
      link:this.c241Url + '3. FDP certificates 2019-20.pdf'
    },
    {
      title:'FDP certificates 2017-18',
      link:this.c241Url + '4. FDP certificates 2017-18.pdf'
    },
    {
      title:'Program as per UGC_AICTE',
      link:this.c241Url + '5. Program as per UGC_AICTE.pdf'
    },
    
  ]

  result:{title:string,link:string}[]=[];

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
      this.heading1 = true;
      this.heading2 = false;
      this.heading5=false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      this.showPdf = true;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
      this.heading1 = false;
      this.heading2 = true;
      this.heading5=false;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).addClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      this.showPdf = false;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=true;
      this.heading3=false;
      this.heading4=false;
      this.heading5=false;
      this.result = this.result1;
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).addClass('show');
      $(`five`).removeClass('show');
      $(`six`).removeClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=false;
      this.heading3=true;
      this.heading4=false;
      this.heading5=false;
      this.src = this.pdfSrc[2];
    }
    if (id === '5') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).addClass('show');
      $(`six`).removeClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=false;
      this.heading3=false;
      this.heading4=true;
      this.heading5=false;
      this.src = this.pdfSrc[3];
    }
    if (id === '6') {
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      $(`six`).addClass('show');
      this.showPdf = true;
      this.heading1 = false;
      this.heading2 = false;
      this.showTable=false;
      this.heading3=false;
      this.heading4=false;
      this.heading5=true;
      this.src = this.pdfSrc[4];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

  
}
