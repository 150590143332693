import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-ct-five-three-one',
  templateUrl: './ct-five-three-one.component.html',
  styleUrls: ['./ct-five-three-one.component.scss']
})
export class CtFiveThreeOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/5.3.1_Revised data in the data template of awards_medals in sports and cultural activities_1.pdf',
    '/assets/pdf/NAAC/DVV/5.3.1_Revised data of Awards_medals in sports and cultural activities event wise_2.pdf'];
  src: string;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  showTable = false;
  c241Url = 'https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1: { title: string, link: string }[] = [
    {
      title: '2021-22_E-copies of awards letters of sports and cultural',
      link: this.c241Url + '2021-22_E-copies of awards letters of sports and cultural.pdf'
    },
    {
      title: '2020-21_E-copies of awards letters of sports and cultural',
      link: this.c241Url + '2020-21_E-copies of awards letters of sports and cultural.pdf'
    },
    {
      title: '2019-20_E-copies of awards letters of sports and cultural',
      link: this.c241Url + '2019-20_E-copies of awards letters of sports and cultural.pdf'
    },
    {
      title: '2018-19_E-copies of awards letters of sports and cultural',
      link: this.c241Url + '2018-19_E-copies of awards letters of sports and cultural.pdf'
    },
    {
      title: '2017-18_E-copies of awards letters of sports and cultural',
      link: this.c241Url + '2017-18_E-copies of awards letters of sports and cultural.pdf'
    }
  ]

  result: { title: string, link: string }[] = [];

  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '3') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      this.result = this.result1;
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.showTable = true;
    }
    if (id === '1') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.showTable = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.showTable = false;
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
