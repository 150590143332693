<div id="seminar">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">National Seminar</h4>
                    <div class="card-body text-justify">
                        <h5>Dr. Rajinder Singh Ahluwalia, Associate Prof. of English</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Act as Resource Person at <strong> SKIT</strong> Jaipur and gave a workshop on <strong>Online Language Learning</strong> in March, 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>What do religions say about origin of world?</strong>  at a National Seminar, Guru Nanak Girls College, Yamuna Nagar on 31 March 2016. Also acted as the Moderator for Panel Discussion in this seminar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Organized a NAAC sponsored National Workshop on <strong>Ensuring Quality through Multi-media assisted Teaching and Learning</strong>  at Guru Nanak Khalsa College, Yamuna Nagar on 15 February, 2016. Also acted as a Resource Person and conducted a session on <strong>`Creating Online Classrooms’</strong> </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on topic <strong>Using Multimedia for Effective Teaching in Large Classes at a National Seminar,</strong>  GMN College, Ambala Cantt on 4-5 November 2015.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Teacher Training: the weakest link</strong>  at a National Conference organized by All India Federation of University and College Teachers (AIFUCTO) at Chandigarh on 14-16 November 2014.</p>
                            </li>
                        </ul>

                        <h5>Dr. Sanjay Arora, Associate Prof. of Commerce</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Price Mechanism in Handloom Industry - A Case Study of District Panipat”</strong>  in International Conference on the theme <strong>Building Sustainable Business Excellence: th Remagining the Future</strong>  on Jan 25-27 , 2018 organised by CMAI at Pegasus International College, Vietnam.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Co-chaired a session and presented paper on the topic <strong>“Contemporary Issues in Commerce & Management”</strong> in National Seminar on the theme <strong>Managing Change in Contemporary Business Scenario</strong> on 27th February 2018, Dayal Singh College, Karnal.</p>
                            </li>
                            
                        </ul>
                        

                        <h5>Dr. Rajinder Singh Vohra, Assistant Prof. of Commerce</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong> “Indian Retail Industry – Times to Move Ahead”</strong> in National Seminar on the theme <strong>Retail Management: Emerging Issues</strong> on September 22 , 2016, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Participated in National Workhsop on the theme <strong>Financial Planning</strong> the on September 09 , 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Acted as Workshop Secretary in National Workshop on the theme <strong>Research Methodology</strong> on September 15th 2917.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Participated in National Workhsop on the theme <strong>Hybrid Cloud Computing & IoT</strong> on September 16 , 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Startup Initiative (A Boost to Indian Economy)”</strong> in National Seminar on the theme <strong>Digital Economy: Challenges and Opportunities</strong> on February 28 , 2018, D.A.V. (P.G.) College, Karnal.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Ease of doing Business (An Appraisal of India’s Improved Ranking)”</strong> in Inter-Disciplinary National Seminar on the theme <strong>Unravelling New India: Issues, Opportunities & Challenges</strong> on March 10, 2018, Maharaja Agrasen Mahavidyalaya, Jagadhri.</p>
                            </li>
                        </ul>

                        <h5>Dr. Tilak Raj, Assistant Prof. of Punjabi</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Baba Banda Bahadar Di Shakhsiat: Fateh Sarhind”</strong> in National Seminar on the theme Human Freedom and Baba Banda Singh Bahadur on Oct 04, 2016, S.D. College (Lahore), Ambala Cantt.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Gurdial Singh Da Sahit Jagat: Ek Survey”</strong> in National Seminar on the theme <strong>Gurdial Singh Da Sahit Jagat</strong> on Nov 07, 2016, Kurukshetra Univeristy, Kurukshetra.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Samajik Kranti De Prerak: Guru Gobind Singh Ji”</strong> in National Seminar on the theme Yug Parevartak: Guru Gobind Singh Ji on Feb 28, 2017, Guru Nanak Khalsa College, Karnal.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Haryana Di Punjabi Kavita :"Pachhan Chinh”</strong> in National Seminar on the theme Haryana Vich Rachit Punjabi Kavita : Samvedna Te Srokar on March 22, 2017, Mukand Lal National College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Acted as Resource Person and presented a paper on the topic <strong>“Dharam Yudh Di Nirol Shastar Gatha : Guru Gobind Singh”</strong> in National Seminar on the theme Sri Guru Gobind Singh Ji : Shastra to Shastr Tak Di Yatra on March 28-29, 2017, Guru Nanak Girls College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Ramesh Kumar Di Kavita VichNari Samvedna”</strong> in National Seminar on the theme <strong>Ikkasvi Sadi Da Punjabi Sahit: Nari Chetna De Vibhin Pasar</strong> on Feb 09, 2018, D.A.V. College, Naniola (Ambala).</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Nishkam Sewa Di Aduti Misal – Bhai Ghanya Ji”</strong> in National Seminar on the theme Sewa Panthi Samprda De Modhi Ate Manvta De Punj Bhai Ghanya Ji on April 16, 2018, Guru Nanka Girls College, Yamuna Nagar.</p>
                            </li>
                        </ul>

                        <h5>Dr. Anurag, Associate Prof. of History</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Satnami Vidroh (1672AD) Ka Sawroop</strong> in Haryana Swarna Jayanti National Conference on the theme <strong>Haryana through the Ages: Reflections on History Society Culture and Economy</strong> on February 7-8, 2017 at Haryana Academy of History & Culture, Kurukshetra.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Pandit Jawahar Lal Nehru aur Rashtra Nirman Ki Prakirya: Arthik Nition Ke Vishesh Sandarbh mein</strong> in National Seminar on the theme <strong>Pt. Jawahar Lal Nehru and Indian Nationalism</strong> on October 22, 2016 at M.D.S.D. Girls College, Ambala City.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Bharat Pakistan Sambandh(1947- 1971): Ek Alochnatmak Adhyan</strong> in National Conference on the theme <strong>Indo-Pak Relations after 1947 A.D.</strong> on November 11-12, 2016 at D.A.V. College, Cheeka (Haryana).</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Shershah Suri Ke Prashasan Mein Pardarshita: Ek Alochnatmak Adhyan</strong> in National Seminar on the theme <strong>Transparency in Public Administration: Myth or Reality</strong> on March 21, 2017 at Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Ek Etihasik Satrot Ke Roop Mein Gulbadan Begum Krit Humanyunnama</strong> in National Seminar on the theme <strong>Status of Women: Past and Present</strong> on March 4, 2017 at D.A.V. College, Sadhaura.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Swachh Bharat Abhiyan</strong>: Pramukh Mudde aur Chunotian in National Seminar on the theme <strong>Swachh Bharat Abhiyan</strong> on January 30, 2018 at Government College, Chhachhrauli.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Madhyakalin Haryana Mein Chishti Sufi Silsile ka Vikas: Ek Adhyan</strong> in Haryana Swarna Jyanti National Seminar on the theme <strong>Haryana through the Ages: Continuity and Change</strong> on March 11, 2018 at Pt. Chiranji Lal Sharma Govt. College, Karnal.</p>
                            </li>
                        </ul>

                        <h5>Dr. Gian Bhushan, Assistant Professor of Zoology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper in 6th National Conference on the theme <strong>“Chemical & Environmental Sciences : Emerging Dimensions & Challenges Ahead”</strong> on April 01, 2017, Arya PG College, Panipat.                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented & submitted a poster on the topic <strong>“Avian Biodiversity in and around Chandigarh”</strong> in National Seminar on the theme <strong>Challenges in Sustainable Management of Natural Resources</strong> on March 24-25, 2017, Department of Zoology, Kurukshetra University, Kurukshetra.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“RTI Act 2005 with Reference to MOEFCC”</strong> in National Seminar on the theme <strong>Transparency in Public Administration : Myth or Reality,</strong> March 21, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Seminar on the theme <strong>Advances in Zoology for Sustainable Development</strong> on February 15-17, 2018, Department of Zoology, Kurukshetra University, Kurukshetra.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Hybrid Cloud Computing & IOT,</strong> Sept.16, 2017, Guru Nanak Khalsa College, Yamuna Nagar.                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Applications of Remote Sensing in Applied Sciences,</strong> Sept. 14, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Physics Horizon : From Concept to Reality</strong> on Sept.13, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Applications of Remote Sensing in Applied Sciences,</strong> Sept. 14, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Biodiversity</strong> on September11, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                        </ul>

                        <h5>Dr. Neena Puri, Assistant Prof. of Industrial Microbiology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on <strong>“Cooperative Learning for Producing High Quality Dairy Professionals”</strong> on March 17, 2017, College of Dairy Science & Technology, GADVASU, Ludhiana.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Oral presentation of research paper on the topic <strong>“Industrial Microbiology : A Skill and Job Oriented Course”</strong> in National Seminar on the theme <strong>Skill Development : Challenges and Implementation</strong> on March 18, 2017, Guru Nanak Girls College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Acted as Stage Secretary and presented paper on the topic <strong>“Microbiological and General Safety of Foods : Right to Information”</strong> in National Seminar on the theme <strong>Transparency in Public Administration : Myth or Reality</strong> on March 21, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Acted as Judge for Poster Making Competition at National Conference on the theme <strong>Academic Industrial Interface</strong> on Sept. 1- 2, 2017, organized by CGC Landran in association with Association of Microbiologists of India, Chandigarh Unit.</p>
                            </li>
                        </ul>

                        <h5>Dr. Neelam Behal, Assistant Professor of Zoology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Acted as Organising Secretary in National Workshop on the theme <strong>Biodiversity</strong> on September 11, 2017, Internal Quality Assurance Cell, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended one day National Workshop on the theme <strong>Physics Horizons : From Concept to Reality</strong> on September 13, 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Hybrid Cloud Computing,</strong> Sept. 16, 2017, organized by IQAC, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Cleanliness- Need of Time”</strong> in multi-disciplinary National Seminar on the theme Swachh Bharat Abhiyan on Jan. 30, 2016, Government College, Chhachhrauli.</p>
                            </li>
                        </ul>

                        <h5>Mr. Kiranpal Singh Virk, Assistant Prof. of Computer Science</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Software Component Quality Models: A Survey”</strong> in 2nd International National Conference on the theme Intelligent Communication, Control and Devices on April 15-16, 2017, University of Petroleum & Energy Studies, Dehradoon,Uttrakhand.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Transparency in Public Administration: Role of ICT in e-Governance”</strong> in National Seminar on the theme <strong>Transparency in Public Administration: Myth or Reality</strong> on March 21 , 2017, Guru Nanak Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Issues and Challenges in Swachh Bharat Abbhiyan: A Survey”</strong> in National Seminar on the theme <strong>Swachh Bharat Abhiyan</strong> on January 30, 2018 at Government College, Chhachhrauli.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Genetic Algorithm and Its Role in Selection of Secure Components for IoT Applications”</strong> in National Conference on the theme <strong>Recent Trends in Network Security</strong> on March 10-11, 2018, S.D. (P.G.) College, Panipat.
                                </p>
                            </li>
                        </ul>

                        <h5>Mr. Kiranpal Singh Virk, Assistant Prof. of Computer Science</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Haryana ka Lok Kavya: Ek Avlokan”</strong> in National Seminar on the theme <strong>Haryana Ka Kavya evam Katha Sahitya</strong> on July 24, 2016, University College, Kuruksehtra.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Mauritius mein Bhartiya Sanskriti: Sahitya Darpan mein”</strong> in International Seminar on the theme <strong>Bhartiya Sanskriti Ka Vaishvik Prasar</strong> on Sept. 11, 2016, Department of Hindi, Kurukshetra University, Kurukshera.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Vaishvik Priprekshya mein Geeta Ka Karya Karan Bhav”</strong> in International Seminar on the theme <strong>Vaishvik Preprekshya mein Shrimadbhavad Geeta ke Jeevan-Mulya</strong> on Dec. 04, 2016, Matribhumi Seva Mishan, Kurukshetra.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Pravasi Sahityakaron ka Hindi Bhasha Ke Vikas mein Yogdan”</strong> in International Seminar on the theme Vishav Patal par Hindi: Vistar evam Sambhavnayein on January 10, 2017, Dayal Singh College, Karnal.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Haryanvi Lok Jeevan mein Vyapt Haryanvi Kahavtein”</strong> in International Seminar on the theme <strong>Haryanvi Lok Sahitya: Samaj, Sanskriti evam Sambhavnayein</strong> on March 07, 2017, Guru Nanka Khalsa College, Karnal.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Participated in National Seminar on the theme <strong>Transparency in Public Administration: Myth or Reality</strong> on March 21, 2017, Guru Nanka Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Changing Trends in Physical Education and Sports</strong> on September 07, 2017, Guru Nanka Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Hybrid Cloud Computing & IoT</strong> on September 16, 2017, Guru Nanka Khalsa College, Yamuna Nagar.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended International Conference on the theme <strong>Vishav mein Hindi Shikshan: Naye Ayam, Nai Drishti</strong> on September 17, 2017, Patanjli University, Haridwar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Attended National Workshop on the theme <strong>Bharat Ki Sanskritik Virasat – Mudde aur Chunotian</strong> on September 20, 2017, Guru Nanka Khalsa College, Yamuna Nagar.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>“Swachhta Abbhiyan: Ek Alochnatmak Drishtikon”</strong> in National Seminar on the theme <strong>Swachh Bharat Abhiyan</strong> on January 30, 2018 at Government College, Chhachhrauli.</p>
                            </li>
                            
                        </ul>
                        <h4 class="card-title text-center">Research Papers In Seminars/Conference Proceedings</h4>
                        <br>
                        <h5>Dr. Rajinder Singh Ahluwalia, Associate Prof. of English</h5>
                        <hr>
                        <h5>International Seminar and workshops</h5>
                        <ul>
                            <li>
                                <p class="lead text-justify">Gave a workshop on <strong>Scenario based ELT</strong> at an International Conference organized by ELTAI at St. Teresa College, Kochi, Kerala in 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Moderated a panel discussion on <strong>Eastern and Western Perspectives on Language Teaching</strong> at an International Conference organized by ELTAI at St. Teresa College, Kochi, Kerala in 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Chaired a session and presented a paper on the topic <strong>Teaching Literature Today: What to Teach and How to Teach it</strong> at an International Seminar held at University College, Kurukshetra University on 23 March 2016.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper on the topic <strong>Using Interactive PPTs and Webquests to motivate ESL Learners</strong> at an International Conference organized at <strong>Amity University, Lucknow</strong> on 4-6 March, 2016. This paper was selected as <strong>the Best Paper</strong> of the conference.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">I also chaired a Plenary session and a Round Table Discussion session on CALL SIG in this conference.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Presented a paper titled <strong>Designing an Online Course for In-service Training of English Teachers</strong> at Teacher Educators’ Conference (TEC 15) organized jointly by the British Council and EFL University at Hyderabad on 27 February to 1 March, 2015.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Conducted a workshop on <strong>Using Google Sites for Effective English Language Teaching</strong> at the 2nd International Conference organized at <strong>Amity University, Lucknow</strong> on 24-25 May, 2014. Also chaired a round table discussion session on CALL SIG.</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>