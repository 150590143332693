import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CAREER } from 'src/app/models/career.model';
import { CareerService } from 'src/app/services/career/career.service';
import { ShowCareerService } from 'src/app/services/showCareer/show-career.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  // careers:CAREER[]=[];
  careers:{title:string,desc:string, date:string, path:string}[]=[
    {
      "title":'Recruitment Notice',
      "desc":'Recruitment Notice',
      "date":"05/06/2024",
      "path":'https://gnkc.in/assets/img/career/1.jpeg'
    },
    {
      "title":'Recruitment Notice',
      "desc":'Recruitment Notice',
      "date":"26/10/2023",
      "path":'https://gnkc.in/assets/img/popup/popup1.jpeg'
    },
  ];
  showCareer=true;
  sub:Subscription[]=[];
  constructor(private careerService:CareerService,
    private showCareerService:ShowCareerService,
    private router:Router) { }

  ngOnInit(): void {
    // this.careerService.fetchCareer();
    
    // this.sub[0] = this.careerService.careerChange.subscribe(career=>{
    //   this.careers=career;
    
    // });
    // this.sub[1]= this.showCareerService.careerChange.subscribe(value=>{
    //   this.showCareer = value;
    // });
  }
  // hideCareer(){
  //   this.showCareerService.hide();
  // }
  // splitUrl(){
  //   let url=location.pathname.split('/');
  //   if(url.length===4){
  //     this.hideCareer();
  //     this.router.navigate(['/menu-detail/career/'+url[3]]);

  //   }
    
  // }
  // ngOnDestroy(): void {
  //     this.sub.forEach(s=>{
  //       s.unsubscribe();
  //     })
  // }
}
