<div id="alumni">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="card-title">Our Pride</h3>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <div class="row">
          <img src="/assets/img/alumni/31.PNG" alt="" class="img-fluid mx-auto">
          <iframe src="https://drive.google.com/file/d/1an5iZa5U97cmmuEixEKdux1HP6EVKkt8/preview" width="100%" height="700" allow="autoplay"></iframe>
            <!-- <div class="col-sm-6 col-md-4 col-xl-3 mt-5" *ngFor="let person of alumni;let i=index">
                <div class="property-card shadow-lg" >
                    <a href="#">
                      <div class="property-image" [style.background-image]="'url('+person.img+')'">
                        <div class="property-image-title">
                          Optional <h5>Card Title</h5> If you want it, turn on the CSS also.
                        </div>
                      </div></a>
                    <div class="property-description">
                      <h5> {{person.name}} </h5>
                      <p>{{person.degree}}</p>
                    </div>
                    <a >
                      <div class="property-social-icons">
                        I would usually put multipe divs inside here set to flex. Some people might use Ul li. Multiple Solutions
                      </div>
                    </a>
                  </div>
            </div>
            -->
        </div>
    </div>
</div>