import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IqacComponent } from 'src/app/view/iqac/iqac.component';
import { NirfComponent } from 'src/app/view/iqac/nirf/nirf.component';
import { RouterModule } from '@angular/router';
import { IQACRoutingModule } from './iqac-routing.module';
import {  PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { StudentFeedbackComponent } from 'src/app/view/iqac/student-feedback/student-feedback.component';
import { CalenderComponent } from 'src/app/view/iqac/calender/calender.component';
import { SecondCalenderComponent } from 'src/app/view/iqac/second-calender/second-calender.component';
import { SecondStudentFeedbackComponent } from 'src/app/view/iqac/second-student-feedback/second-student-feedback.component';
import { BestPracticesComponent } from 'src/app/view/iqac/best-practices/best-practices.component';
import { StudentSurveyComponent } from 'src/app/view/iqac/student-survey/student-survey.component';
import { CurriculumComponent } from 'src/app/view/iqac/curriculum/curriculum.component';
import { MeetingsComponent } from 'src/app/view/iqac/meetings/meetings.component';
import { HumanComponent } from 'src/app/view/iqac/human/human.component';
import { SharedModule } from '../shared/shared.module';
import { AqarComponent } from 'src/app/view/iqac/aqar/aqar.component';
import { BpTwooneTwotwoComponent } from 'src/app/view/iqac/best-practices/bp-twoone-twotwo/bp-twoone-twotwo.component';
import { BpTwotwoTwothreeComponent } from 'src/app/view/iqac/best-practices/bp-twotwo-twothree/bp-twotwo-twothree.component';
import { InstitutionalComponent } from 'src/app/view/iqac/institutional/institutional.component';
import { ItTwotwoTwothreeComponent } from 'src/app/view/iqac/institutional/it-twotwo-twothree/it-twotwo-twothree.component';
import { DevelopmentComponent } from 'src/app/view/iqac/development/development.component';



@NgModule({
  declarations: [
    BpTwotwoTwothreeComponent,
    BpTwooneTwotwoComponent,
    IqacComponent,
    NirfComponent,
    StudentFeedbackComponent,
    CalenderComponent,
    SecondCalenderComponent,
    SecondStudentFeedbackComponent,
    BestPracticesComponent,
    StudentSurveyComponent,
    CurriculumComponent,
    MeetingsComponent,
    HumanComponent,
    AqarComponent,
    InstitutionalComponent,
    ItTwotwoTwothreeComponent,
    DevelopmentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IQACRoutingModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule
  ]
})
export class IqacModule { }
