import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-c5-five-three-two',
  templateUrl: './c5-five-three-two.component.html',
  styleUrls: ['./c5-five-three-two.component.scss']
})
export class C5FiveThreeTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-5/5.3.2_SPORTS AND CULTUREAL ACTIVITIES PARTICIPATION/5.3.2_ 2021-22_SPORTTS AND CULTURAL ACTIVITIES PARTICIPATION.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.3.2_SPORTS AND CULTUREAL ACTIVITIES PARTICIPATION/5.3.2_2020-21_SPORTTS AND CULTURAL ACTIVITIES PARTICIPATION.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.3.2_SPORTS AND CULTUREAL ACTIVITIES PARTICIPATION/5.3.2_2019-20_SPORTTS AND CULTURAL ACTIVITIES PARTICIPATION.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.3.2_SPORTS AND CULTUREAL ACTIVITIES PARTICIPATION/5.3.2_2018-19_SPORTTS AND CULTURAL ACTIVITIES PARTICIPATION.pdf', 
    '/assets/pdf/NAAC/Criterion-5/5.3.2_SPORTS AND CULTUREAL ACTIVITIES PARTICIPATION/5.3.2_2017-18_SPORTTS AND CULTURAL ACTIVITIES PARTICIPATION.pdf', ];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    
    

  }
  hideLoader() {
    this.isLoading = false;
  }
}
