import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-biotechnology',
  templateUrl: './biotechnology.component.html',
  styleUrls: ['./biotechnology.component.scss']
})
export class BiotechnologyComponent implements OnInit {
  bio:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.bio=this.facultyService.getBiotechnology();
  }

}
