<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" *ngIf="showSideMenu">
            <a routerLink="/naac/dvv/criteria1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>1.2.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-2" (click)="checkRoute()">1.2.2.2 Attendance sheet of students of add-on courses</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-3" (click)="checkRoute()">1.2.2.3 Models Certificates</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria1/1-2-2/1-2-2-4" (click)="checkRoute()">1.2.2.4 Certificates of Adon Courses</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'" >
            <router-outlet></router-outlet>
        </div>
    </div>
</div>