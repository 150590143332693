import { Component, HostListener, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-three-three-two',
  templateUrl: './ct-three-three-two.component.html',
  styleUrls: ['./ct-three-three-two.component.scss']
})
export class CtThreeThreeTwoComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/3.3.2_No.-of-books-and-chapters_DVV.pdf',];
  src: string;

  showPdf = false;
  isLoading = true;
  showSideMenu = true;
  fullWidth = false;
  ngOnInit(): void {
    // this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      this.showPdf= true;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      this.showPdf= false;
      // this.src = this.pdfSrc[0];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 5){
      this.showSideMenu=true;
      this.fullWidth=false;
      // this.showHeading = true;
    }
    // else if(url.length === 5){
    //   this.showSideMenu=true;
    //   this.fullWidth=true;
    //   // this.showHeading = false;
    // }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

}
