import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twenty-twentyone',
  templateUrl: './twenty-twentyone.component.html',
  styleUrls: ['./twenty-twentyone.component.scss']
})
export class TwentyTwentyoneComponent implements OnInit {
  c3431Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1 Number of Extension and Outreach Programs_Photographs_5yr_SSR_Final/2020-21/';

  result:{title:string,link:string}[]=[
    {
      title:'VOLUNTARY SERVICE DURING COVID -19',
      link:this.c3431Url + '32_ 2020 -21_NCC_VOLUNTARY SERVICE DURING COVID -19_photos_.pdf'
    },
    {
      title:'ARMED FORCED FLAG DAY',
      link:this.c3431Url + '33_ 2020 -21_NCCARMED FORCED FLAG DAY__photos.pdf'
    },
    {
      title:'SWATCHHTA PAKHWARA',
      link:this.c3431Url + '34_ 2020 -21_NCC_SWATCHHTA PAKHWARA_photos.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c3431Url + '35_ 2020 -21_NCC_CLEANLINESS DRIVE_photos.pdf'
    },
    {
      title:'NATIONAL GIRLS HEALTH SERVICE WEEK',
      link:this.c3431Url + '36_ 2020 -21_NCC_NATIONAL GIRLS HEALTH SERVICE WEEK_photos.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c3431Url + '37_ 2020 -21_NCC__ROAD SAFETY RALLY_photos.pdf'
    },
    {
      title:'DONATION TO SAHEED NISHANT SHARMA',
      link:this.c3431Url + '38_ 2020 -21_NCC_DONATION TO SAHEED NISHANT SHARMA_photos_.pdf'
    },
    {
      title:'NO PLASTIC USE ABHIYAN',
      link:this.c3431Url + '39_ 2020 -21_NCC_NO PLASTIC USE ABHIYAN_photos.pdf'
    },
    {
      title:'NASHA MUKTI DIWAS',
      link:this.c3431Url + '40_ 2020 -21_NCC_NASHA MUKTI DIWAS_photos.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
