<div id="about-us">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Registration Form</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Registration Form</li>
            </ol>
          </nav>
    </div>
    <!-- <div class="alert alert-info mx-5 my-2 my-lg-5" role="alert">
        You have missed the deadline..!!
      </div> -->
    <div>
        <iframe frameborder="0" style="height:2900px;width:99%;border:none;" src='https://data.odpay.in/odforms/form/GNKCADMISSIONFORM/formperma/OlpTJRqS9vysjtKYJ_GRQ1-WfEv08sC-MQ9Scaws1ho'></iframe>
    </div>
</div>