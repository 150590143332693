import { Component, HostListener, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})
export class DevelopmentComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1aCq2b7ngjzbN6w1bM3HHnUN0h6fGonmU/preview',
  ];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.one').addClass('active');
      this.src = this.pdfSrc[0];
    }
  }

  
  hideLoader() {
    this.isLoading = false;
  }
}
