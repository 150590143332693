import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-three-three-one',
  templateUrl: './c3-three-three-one.component.html',
  styleUrls: ['./c3-three-three-one.component.scss']
})
export class C3ThreeThreeOneComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 3/3.3.1_Research_Papers_Combined_SSR.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
