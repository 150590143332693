<div id="awards" data-scroll>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="font-weight-bold" > Honours & Awards</h2>
                <hr class="pb-1 rounded">
            </div>
            
        </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <img src="/assets/img/awards/awards.jpg" alt="" class="img-fluid w-100">
        </div>
      </div>
    </div>
    
</div>

<!-- <div class="timeline mb-5" data-scroll>
    <div class="container left">
        <i class="icon bi bi-trophy"></i>
      <div class="date">2005</div>
      <div class="content">
        <h2>A+</h2>
        <p>
            
            Accredited Institution in 2005. The college has been reaccredited with A grade in 2014 which is the highest grade given to any institution.
        </p>
      </div>
    </div>
    <div class="container right">
        <i class="icon fas fa-award"></i>
      <div class="date">2004</div>
      <div class="content">
        <h2>Awarded</h2>
        <p>
            “Best College"
            by Kurukshetra University, Kurukshetra in 2003-04.
        </p>
      </div>
    </div>
    <div class="container left">
        <i class="icon fas fa-medal"></i>
      <div class="date"><i class="fas fa-infinity"></i></div>
      <div class="content">
        <h2>Governor's Award</h2>
        <p>
            The college
            has received Governor's Award several times for its significant contribution to sports and community services.
        </p>
      </div>
    </div>
    <div class="container right">
      <i class="icon fa fa-running"></i>
      <div class="date">28 Yrs.</div>
      <div class="content">
        <h2>Sports Champion</h2>
        <p>
            College remains Overall Kurukshetra University Sports Champion for last 28 years in the Women section and 21 years in the Men section.
        </p>
      </div>
    </div>
    <div class="container left">
        <i class="icon fa fa-certificate"></i>
      <div class="date">2015</div>
      <div class="content">
        <h2>Potential for Excellence</h2>
        <p>
            UGC awarded the status "College with Potential for Excellence" in 2006. The college has been reawarded this excellent status once again in 2014-15.
        </p>
      </div>
    </div>
    <div class="container right">
        <i class="icon fas fa-atom"></i>
      <div class="date">15 </div>
      <div class="content">
        <h2>Projects</h2>
        <p>
            Faculty members of the college have completed six Major Research Projects, nine Minor Research Projects and two Post Doctoral Projects sponsored by UGC.            
        </p>
      </div>
    </div>
  </div> -->