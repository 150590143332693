import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import ScrollOut from "scroll-out";
declare var $:any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit ,OnDestroy{
  so:any;
  
  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideHeader();
  }

  ngOnInit(): void {
    $('#axis-btn').addClass('active');
    $('#work-btn').addClass('active');
    this.so = ScrollOut({
      onShown: function(el) {
        // remove the class
        el.classList.remove('animated');
    
        // force reflow
        void el.offsetWidth;
    
        // re-add the animated cl
        el.classList.add('animated');
      }
    });
  }
  showCard(id:number){
    if(id===1){
      $('#axis').collapse('show');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').addClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===2){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('show');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').addClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    
    }
    if(id===3){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('show');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').addClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===4){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('show');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').addClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===5){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('show');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').addClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===6){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('show');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').addClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===7){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('show');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').addClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===8){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('show');
      $('#utkarsh').collapse('hide');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').addClass('active');
      $('#utkarsh-btn').removeClass('active');
    }
    if(id===9){
      $('#axis').collapse('hide');
      $('#bajaj').collapse('hide');
      $('#bulk').collapse('hide');
      $('#it').collapse('hide');
      $('#mediyork').collapse('hide');
      $('#sangwan').collapse('hide');
      $('#teches').collapse('hide');
      $('#tele').collapse('hide');
      $('#utkarsh').collapse('show');

      $('#axis-btn').removeClass('active');
      $('#bajaj-btn').removeClass('active');
      $('#bulk-btn').removeClass('active');
      $('#it-btn').removeClass('active');
      $('#mediyork-btn').removeClass('active');
      $('#sangwan-btn').removeClass('active');
      $('#tech-btn').removeClass('active');
      $('#tele-btn').removeClass('active');
      $('#utkarsh-btn').addClass('active');
    }
    if(id===10){
      $('#working').collapse('show');
      $('#selection').collapse('hide');
      

      $('#work-btn').addClass('active');
      $('#selection-btn').removeClass('active');
    }
    if(id===11){
      $('#working').collapse('hide');
      $('#selection').collapse('show');
      

      $('#work-btn').removeClass('active');
      $('#selection-btn').addClass('active');
    }
  }
  ngOnDestroy(){
    this.so.teardown();
    this.showcaseService.showingHeader();
  }
}
