<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria6"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>6.3.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">6.3.2.1 Revised data for Number of teachers</a>
                    </li>
                    <li class="two">
                        <a (click)="changeRoute('2')">6.3.2.2 Cerificates in English version</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">6.3.2.3 E-copy of financial support to teachers</a>
                    </li>
                </ul>
            </nav>
        </div>
        
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading1">Revised data for Number of teachers</h5>
                            <h5 class="card-title text-center" *ngIf="heading2">Cerificates in English version</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">E-copy of financial support to teachers</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                                    id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>