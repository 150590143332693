import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boys-room',
  templateUrl: './boys-room.component.html',
  styleUrls: ['./boys-room.component.scss']
})
export class BoysRoomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
