import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';

@Component({
  selector: 'app-iqbal',
  templateUrl: './iqbal.component.html',
  styleUrls: ['./iqbal.component.scss']
})
export class IqbalComponent implements OnInit {
  result:{name:string ,title:string, link:string} [] = [];
  constructor(private ssrService : SsrService) { }

  ngOnInit(): void {
    this.result = this.ssrService.getIqbal();
  }


}
