import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-institutional',
  templateUrl: './institutional.component.html',
  styleUrls: ['./institutional.component.scss']
})
export class InstitutionalComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 3){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else if(url.length === 4 && (url[3]=== '2022-23') ){
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
