import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowNaacService {

  private showNaac:boolean=true;
  naacChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showNaac=true;
    this.naacChange.next(true);
  }
  hide(){
    this.showNaac=false;
    this.naacChange.next(false);
  }
}
