import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  mediaList:AngularFireList<any>;
  constructor(private firebase:AngularFireDatabase) { }
  getMediaList(){
    this.mediaList=this.firebase.list('media');  
  }
}
