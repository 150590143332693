import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlogService } from 'src/app/services/blog/blog.service';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
import { ShowBlogService } from 'src/app/services/showBlog/show-blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit,OnDestroy, AfterViewChecked {
  showBlog:boolean=true;
  sub:Subscription[]=[];
  blogs:any[];
  menuName='Blog';
  constructor(private showBlogService:ShowBlogService,
    private blogService:BlogService,
    private router:Router,
    private showcaseService:ShowcaseService,
    private pathNameService:PathNameService,
    private cdRef:ChangeDetectorRef) { 
      showcaseService.hideShowcase();
    }

  ngOnInit(): void {
    this.blogService.getBlogList();
    this.sub[0]= this.blogService.blogList.snapshotChanges().subscribe(
      list=>{
      this.blogs=  list.map(item=>{ return {
        $key:this.convertingUrl(item.payload.val().title),
        ... item.payload.val()
      }})
      this.blogService.setBlog(this.blogs);
      this.splitUrl();
      }
      );
    this.sub[1]=this.showBlogService.blogChange.subscribe(val=>{
      this.showBlog=val;
    });
    this.sub[2]=this.pathNameService.urlChange.subscribe(res=>{
      if(!res){
        return this.menuName='Blog';
      }
      this.menuName=res;
    });
  }
  splitUrl(){
    console.log(location.pathname);
    let url=location.pathname.split('/');
    if(url.length===3){
      this.hideBlog();
      this.router.navigate(['/blog/'+url[2]]);

    }
    
  }
  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }
  convertingUrl(id:string){
    for(let i=0;i<id.length;i++){
      id=id.replace(/[“’”'":;.,/!@#$%`]/g,'-');
    }
    if(id.includes('(')){
      for(let i=0;i<id.length;i++){
        id=id.replace('(','-');
      }
    }
    if(id.includes(')')){
      for(let i=0;i<id.length;i++){
        id=id.replace(')','-');
      }
    }
    if(id.includes(' ')){
      for(let i=0;i<id.length;i++){
        id=id.replace(' ','-');
      }
    }
    if(id.includes('.')){
      for(let i=0;i<id.length;i++){
        id=id.replace('.','-');
      }
    }
    if(id.includes('_')){
      for(let i=0;i<id.length;i++){
        id=id.replace('_','-');
      }
    }
    return id;
    
  }
  hideBlog(){
    this.showBlogService.hide();
  }
  ngOnDestroy(): void {
    this.showcaseService.showingShowcase();
    this.sub.forEach(s=>s.unsubscribe())
  }
}
