import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $:any;
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  education:{name:string,creteria:string,title:string,detail1:string[],note:string[],detail2:string[]}[];
  educationpo:string[]=[]
  src: string;
  isLoading = true;
  showPdf = false;
  message = false;
  showCard = false;

  pdfSrc: string[] = [
    '/assets/pdf/Education/Building Use & Completion Certificate.pdf',
    '/assets/pdf/Education/NCTE Recognition.pdf',
    '/assets/pdf/Education/KUK Approval (2021-22).pdf',
    '/assets/pdf/Education/Books & Journal Details.pdf',
    '/assets/pdf/Education/Land Documents with Building Plan.pdf',
    '/assets/pdf/Education/Sanctioned Program & Intake.pdf',
    '/assets/pdf/Education/Pancard of the Society (GNKC).pdf',
    '/assets/pdf/Education/Available Infrastructural Facilities.pdf',
    '/assets/pdf/Education/Faculty List B.Ed. 2023-24.pdf',
    '/assets/pdf/Education/List of B.Ed. Part-2 2023-24.pdf',
    '/assets/pdf/Education/List of B.Ed. Part-2.pdf',
    '/assets/pdf/Education/FDR (Endowed & Reserve).pdf',
    '/assets/pdf/Education/FDR (Endowed & Reserve).pdf',
    '/assets/pdf/Education/Copy of Affidavit.pdf',
    '/assets/pdf/Education/Minority Certificate.pdf',
    '/assets/pdf/Education/Fee Charged from Students.pdf',
    '/assets/pdf/Education/Balance Sheet 2022-23.pdf'
  ];
  constructor(private courseService:CourseService) { 
    
  }

  ngOnInit(): void {
    this.education=this.courseService.getEducation();
    this.educationpo=this.courseService.getEducationpo();
    this.changeRoute('1');
  }

  setModal(i:number){
    $('#courseModelTitle').html(this.education[i].name);
    $('.creteria1').html(this.education[i].creteria);
    if(i==0){
      $('.bped').hide();
      $('.detail11').html(this.education[i].detail1[0]);
      $('.detail12').html(this.education[i].detail1[1]);
    }
    if(i==1){
      $('.bped').show();
      $('.title').html(this.education[i].title);
      $('.sem1b1').html(this.education[i].detail1[0]);
      $('.sem1b2').html(this.education[i].detail1[1]);
      $('.sem1b3').html(this.education[i].detail1[2]);
      $('.sem1b4').html(this.education[i].detail1[3]);
      $('.sem1b5').html(this.education[i].detail1[4]);
      $('.sem1b6').html(this.education[i].detail1[4]);
      $('.note1').html(this.education[i].note[0]);
      $('.note2').html(this.education[i].note[1]);
      $('.detail21').html(this.education[i].detail2[0]);

      // let po=document.querySelector('.po-pso');
      // po.innerHTML=this.educationpo[i];

    }

  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      this.showPdf= false;
      this.message= false;
      this.showCard=true;
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      // this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '3') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '4') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '5') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '6') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '7') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '8') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '9') {
      this.showCard=false;
      this.showPdf= true;
      this.message= false;
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
     this.src = this.pdfSrc[7];
    }
    if (year == '10') {
      this.message= true;
      this.showCard=false;
      this.showPdf=false;
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      
    }
    if (year == '11') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '12') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '13') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '14') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '15') {
      this.showCard=false;
      this.showPdf= true;
      this.message= false;
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '16') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '17') {
      this.showPdf= true;
      this.message= false;
      this.showCard=false;
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '18') {
      this.showPdf= true;
      this.showCard=false;
      this.message= false;
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '19') {
      this.showCard=false;
      this.showPdf= true;
      this.message= false;
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      this.src = this.pdfSrc[16];
    }
  }
}
