<div id="hostel">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Girls Hostel</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">The college has one magnificent Girls’ hostel on its premises. The hostel provides accommodation for 150 students coming from far flung areas of the country. Admission to hostel is strictly on merit.</p>
                        <p class="lead text-justify font-weight-bold text-dark">Rules & Regulations for Hostel :
                        </p>
                        <ul>
                            <li>
                                <p class="lead">Those wishing to join the hostel will have to present themselves for the interview before the admission committee on the notified dates for admission in the college.
                                     However, admission to the hostel will be granted on merit after being inter-viewed by the Hostel Committee.</p>
                            </li>
                            <li>
                                <p class="lead">Hostel seat is allotted only for one academic year.
                                     All students, old and new, on being granted admission to the Hostel, are required to fill in the Hostel form.</p>
                            </li>
                            <li>
                                <p class="lead">Fresh applicants to the hostel must furnish a certificate of good behaviour from the Principal of the institution last attended.</p>
                            </li>
                            <li>
                                <p class="lead">Admission to the Hostel will only be granted, if the candidate is accompanied by her parent/parents at the time of interview (This will apply to old students as well)</p>
                            </li>
                            <li>
                                <p class="lead">The Principal is the final deciding authority for the allotment of a seat in the Hostel and can refuse admission to any student without giving any reason in the interest of the institution.
                                     No hostel admission can be claimed as matter of right on any account.</p>
                            </li>
                            <li>
                                <p class="lead">Application Form for admission to hostel containing all the rules and regulations of the Hostel is available separately in the college office.</p>
                            </li>
                            <li>
                                <p class="lead">Hostel rent for full year is Rs. 20,000 for non AC rooms and Rs. 40,000 for AC rooms along with additional Rs. 3,000 of security cahrges.</p>
                            </li>
                            <li>
                                <p class="lead">Any information related to hostel rules and admission can be obtained from hostel warden.</p>
                            </li>
                        </ul>
                        <div id="hostelCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#hostelCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#hostelCarousel" data-slide-to="1"></li>
                            
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/hostel/3.JPG" alt="hostel" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/hostel/4.JPG" alt="hostel" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#hostelCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#hostelCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                         <!-- <div class="row">
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/hostel/1.jpeg" alt="hostel" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/hostel/2.jpeg" alt="hostel" class="img-fluid rounded">
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>