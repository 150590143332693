import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c33-seventeen',
  templateUrl: './c33-seventeen.component.html',
  styleUrls: ['./c33-seventeen.component.scss']
})
export class C33SeventeenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.2.1 Books/';

  result:{title:string,link:string}[]=[
    {
        title: 'Dr. Alok Goyal',
        link: this.c3321Url + '1_2017_Dr.-Alok-Goyal.pdf'
    },
    {
        title: 'Dr Sanjay Arora',
        link: this.c3321Url + '2_2017_Dr-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr Sanjay Arora',
        link: this.c3321Url + '3_2017_Dr-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr Sanjay Arora',
        link: this.c3321Url + '4_2017_Dr-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr Sanjay Arora',
        link: this.c3321Url + '5_2017_Dr-Sanjay-Arora.pdf'
    },
    {
        title: 'Dr Sanjay Arora',
        link: this.c3321Url + '6_2017_Dr-Sanjay-Arora.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
