import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireList } from 'angularfire2/database';
import { CAREER } from 'src/app/models/career.model';
import { BehaviorSubject } from 'rxjs';
import { ShowCareerService } from '../showCareer/show-career.service';

@Injectable({
  providedIn: 'root'
})
export class CareerService {
  careerList:AngularFireList<any>;
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  private careers:CAREER[]=[];
  newCareer:CAREER[]=[];
  careerChange=new BehaviorSubject<CAREER[]>([]);
  constructor(private http:HttpClient,
    private router:Router,
    private showService:ShowCareerService) { }

  fetchCareer(){
    this.http.get<CAREER[]>(this.url+'/career.json').subscribe(res=>{
      this.setCareer(res);
    },error=>{
      console.log(error);
    });
  }

  setCareer(career:CAREER[]){
    this.careers=career;
    this.newCareer=Object.keys(this.careers).map(key=>({$key:key,...this.careers[key]}));
    this.careerChange.next(this.newCareer.reverse());
    this.splitUrl();
    // console.log(this.newContacts);
  }

  searchCareer(id:string){
    for(let careerItem of this.newCareer){
      if(careerItem.path===id){
        return careerItem.link;
      }
    }
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.showService.hide();
      this.router.navigate(['/menu-detail/career/'+url[3]]);

    }
    
  }
}
