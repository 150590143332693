import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-punjabi',
  templateUrl: './punjabi.component.html',
  styleUrls: ['./punjabi.component.scss']
})
export class PunjabiComponent implements OnInit {
  punjabi:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.punjabi=this.facultyService.getPunjabi();
  }

}
