<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <img src="/assets/img/not-found/1.jpg" alt="Page Not Found" class="img-fluid w-100">
        </div>
        <div class="col-12 detail d-flex flex-column justify-content-end align-items-center">
            <h4>Page is Under Maintenance !</h4>
        </div>
    </div>
    
</div>