import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Gallery } from 'src/app/models/gallery.model';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { ImageService } from 'src/app/services/image.service';
import { ShowGalleryService } from 'src/app/services/showGallery/show-gallery.service';
import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
declare var $:any;
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit,OnDestroy {

  showList:boolean=true;
  images:any[];
  filterImage:Gallery[]=[];
  sub:Subscription[]=[];
  constructor(private showcaseService:ShowcaseService,private showService:ShowGalleryService,
    private imgService:ImageService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    this.imgService.getImgDetailList();
    this.sub[0]= this.imgService.imgDetailList.snapshotChanges().subscribe(
      list=>{
      this.images=  list.map(item=>{  return {
        $key:item.key,
        ... item.payload.val()
      }});
      this.imgService.setCategory(this.images);
      this.filterImage=this.imgService.setFilter();
      }
      );
      this.sub[1]=this.showService.galleryChange.subscribe(val=>{
        this.showList=val;
      });
      
  }
  hideList(){
    this.showService.hide();
  }
  openGalleryList(id:string,caption:string){

    let image=this.imgService.getSingleTypeImages(caption);
    let newImage:{src:string,thumb:string,subHtml:string}[]=[];

    for(let i of image){
      newImage.push({src:i.imgUrl,thumb:i.imgUrl,subHtml:i.caption});
    }

    const $dynamicGallery = document.getElementById(id);
    const dynamicGallery = lightGallery($dynamicGallery, {
      dynamic: true,
      plugins: [lgZoom,lgShare,lgThumbnail],
      dynamicEl: newImage,
  });

  
      $('.lg-backdrop').css('z-index', 99999);
      $('.lg-outer').css('z-index', 99999);
      // Starts with third item.(Optional).
      // This is useful if you want use dynamic mode with
      // custom thumbnails (thumbnails outside gallery),
      dynamicGallery.openGallery(0);
  
  
  
  

  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
    this.sub.forEach(s=>{
      s.unsubscribe();
    })
  }
  
}
