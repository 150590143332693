<div id="header" data-scroll>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-9 text-center">
                <h2 class="font-weight-bold " style="text-shadow: rgb(0 0 0 / 30%) 0 2px 4px;">Visionary Management</h2>
                <hr class=" p-1" style="width: 160px;">
            </div>
        </div>
    </div>
    <div class="container-fluid text-center mt-3">
        <div class="btn-group btn-group-toggle " data-toggle="buttons" role="group" aria-label="Message Button Group">
            <button type="button" 
            class="btn mr-1 first" 
            id="patron-message" 
            data-toggle="collapse" 
            data-target="#message1" 
            aria-expanded="true" 
            aria-controls="Patron Message"
            (click)="showCard(1)">Patron Message</button>

            <button type="button" 
            class="btn mr-1" 
            id="President-message" 
            data-toggle="collapse" 
            data-target="#message2" 
            aria-expanded="true" 
            aria-controls="President Message"
            (click)="showCard(2)">President Message</button>

            <button type="button" 
            class="btn mr-1" 
            id="principal-message" 
            data-toggle="collapse" 
            data-target="#message3" 
            aria-expanded="true" 
            aria-controls="Principal Message"
            (click)="showCard(3)">Principal Message</button>

           
        </div>

        <!-- Message 1 -->
        <div class="row collapse show multi-collapse" id="message1">
            <div class="col-12 col-lg-9 py-5 detail-box">
                <div class="message-detail">
                    <h5 class="text-left">Patron's Message</h5>
                    <p class="text-justify">Guru Nanak Khalsa College was founded in 1968 when the world was celebrating 500th Birth Anniversary of Sri Guru Nanak Dev Ji, a great saint and philosopher from India.
                         Right from its inception the college committed itself to follow the philosophy and teachings of this great Guru ji who believed in the oneness of humanity and envisioned a casteless society where every body had equal opportunity to grow and succeed.
                         </p>
                    
                      <button class="btn btn-warning btn-sm mx-auto" [routerLink]="'/about/messages'" [fragment]="'patron'">Read More</button>
                  </div>
            </div>
            <div class="col-12 col-lg-3 d-none d-md-block image-box">
                <div class="badge1 badge">
                    <div class="text">
                        Patron
                    </div>
                </div>
            </div>
            <div class="col-12 d-block d-md-none image-box">
                <img src="/assets/img/message/1.jpeg" alt="Chairman" class="img-fluid rounded-circle" loading='eager'>
            </div>
        </div>

        <!-- Message 2 -->
        <div class="row collapse  multi-collapse" id="message2">
            <div class="col-12 col-lg-9 py-5 detail-box">
                <div class="message-detail">
                    <h5 class="text-left">President's Message</h5>
                    <p class="text-justify">I welcome the new and continuing students to the new Golden Jubilee Academic Session.
                         The Khalsa Institutions are a centre of knowledge and provide most advanced education for all sections.
                         Founded on the ideals of Guru Nanak Dev Ji, the students are taught the core values of Naam Jap, Vand Chhak and Kirat Kar as in today’s fast changing world the young generation should not only have appropriate skills and knowledge but they should also be morally and ethically sound to being new changes in our country.</p>
                    
                      <button class="btn btn-warning btn-sm mx-auto" [routerLink]="'/about/messages'" [fragment]="'president'">Read More</button>
                  </div>
            </div>
            <div class="col-12 col-lg-3 d-none d-md-block image-box">
                <div class="badge badge2">
                    <div class="text">
                        President
                    </div>
                </div>
            </div>
            <div class="col-12 d-block d-md-none image-box">
                <img src="/assets/img/message/2.png" alt="Manager" class="img-fluid rounded-circle">
            </div>
        </div>

        <!-- Message 3 -->
        <div class="row collapse multi-collapse" id="message3">
            <div class="col-12 col-lg-9 py-5 detail-box">
                <div class="message-detail">
                    <h5 class="text-left">Principal's Message</h5>
                    <p class="text-justify">I feel very privileged and honoured to be part of the Golden Jubilee year of our esteemed Institution.
                         Today, Guru Nanak Khalsa College is one of the leading institutions of Haryana where students from every nook and corner of the country receive education.
                          We always try to cultivate students who not only have sound academic knowledge and skills but also have a unique ethical attitude to serve the community and nation.
                         </p>
                     <button class="btn btn-warning btn-sm mx-auto" [routerLink]="'/about/messages'" [fragment]="'principal'">Read More</button>
                  </div>
            </div>
            <div class="col-12 col-lg-3 d-none d-md-block image-box">
                <div class="badge badge3">
                    <div class="text">
                        Principal
                    </div>
                </div>
            </div>
            <div class="col-12 d-block d-md-none image-box">
                <img src="/assets/img/message/3.jpeg" alt="Principal" class="img-fluid rounded-circle">
            </div>
        </div>
        
       
    </div>
</div>