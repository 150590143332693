import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StarService {
  private star:{name:string,degree:string,img:string}[]=[
    {name:'Jaspreet Singh',degree:'M.Com., NET',img:'/assets/img/star/1.jpeg'},
    {name:'Ramanjot Kaur',degree:'M.Com., NET',img:'/assets/img/star/2.png'},
    {name:'Vandana',degree:'M.Com., NET',img:'/assets/img/star/3.png'},
    {name:'Saloni Gupta',degree:'M.Sc. Biotech (Ist Sem.) 74.75% (Ist Univ. Position)',img:'/assets/img/star/4.png'},
    {name:'Garima Sukhija',degree:'M.Sc. Biotech (Ist Sem.) 69% (2nd Univ. Position)',img:'/assets/img/star/5.png'},
    {name:'Himakshi',degree:'M.Sc. Biotech (Ist Sem.) 63.7% (3rd Univ. Position)',img:'/assets/img/star/6.png'},
    {name:'Sonia',degree:'M.Sc. Biotech (2nd Sem. - 79.14%) (1st Sem-73%, Class Topper)',img:'/assets/img/star/7.png'},
    {name:'Priyanka',degree:'Priyanka M.Sc. Physics 83%',img:'/assets/img/star/8.png'},
    {name:'Rashi Saini',degree:'M.Sc. Physics 81.7%',img:'/assets/img/star/9.png'},
    {name:'Ritika',degree:'M.Sc. Physics 79.4%',img:'/assets/img/star/10.png'},
    {name:'Harneet',degree:'M.Sc. Physics 75.7%',img:'/assets/img/star/11.png'},
    {name:'Paras',degree:'M.Sc. Physics 75.15%',img:'/assets/img/star/12.png'},
    {name:'Kasish',degree:'M.Sc. Physics 75.1%',img:'/assets/img/star/13.png'},
    {name:'Kanika Devi',degree:'M.Sc. Math 79.2%',img:'/assets/img/star/14.png'},
    {name:'Jasbeer Kaur',degree:'M.Sc. Math 78.6%',img:'/assets/img/star/15.png'},
    {name:'Kanika Singla',degree:'M.Sc. Math 75.8%',img:'/assets/img/star/16.png'},
    {name:'Kriti Rani',degree:'M.Sc. Math 75%',img:'/assets/img/star/17.png'},
    {name:'Randeep Singh',degree:'M.Sc. Math 74%',img:'/assets/img/star/18.png'},
    {name:'Amanpreet Kaur',degree:'M.Sc. Math 73%',img:'/assets/img/star/19.png'},
    {name:'Shakshi',degree:'M.Sc. Math 72.16%',img:'/assets/img/star/20.png'},
    {name:'Abhishek',degree:'M A Public Admn sem I',img:'/assets/img/star/21.png'},
    {name:'Kavita',degree:'364/500 MA Public admn Sem. III Dec. 2019',img:'/assets/img/star/22.png'},
    {name:'Lalita',degree:'360/500 MA Public Admn Sem III Dec 2019',img:'/assets/img/star/23.png'},
    {name:'Manisha bihana',degree:'386/500 MA Public Admn Sem III Dec 2019',img:'/assets/img/star/24.png'},
    {name:'Shashi Kumar',degree:'M.A. Public Admn Sem I',img:'/assets/img/star/25.png'},
    {name:'Sahil',degree:'B.Sc. (NM) IV Sem 79.3%',img:'/assets/img/star/26.png'},
    {name:'Shubham',degree:'B.Sc. II Sem 79%',img:'/assets/img/star/27.png'},
    {name:'Jaspreet Singh',degree:'B.Sc. II Sem 77%',img:'/assets/img/star/28.png'},
    {name:'Deepika',degree:'B.Sc. II Sem 72%',img:'/assets/img/star/29.png'},
    {name:'Ritik Gupta',degree:'B.Sc. (IC) II Sem 71.83%',img:'/assets/img/star/30.png'},
    {name:'Sukhpreet Singh',degree:'BSC II 70%',img:'/assets/img/star/31.png'},
    {name:'Kanwaljeet',degree:'B.Sc. IV Sem 84.7%',img:'/assets/img/star/32.png'},
    {name:'Vinita',degree:'B.Sc. IV Sem 80%',img:'/assets/img/star/33.png'},
    {name:'Shubham Kumar',degree:'B.Sc. (IC) IV Sem 78%',img:'/assets/img/star/34.png'},
    {name:'Abhishek',degree:'B.Sc. (IC) IV Sem 77.33%',img:'/assets/img/star/35.png'},
    {name:'Nishima',degree:'BSc (IC) IV Sem 75.8%',img:'/assets/img/star/36.png'},
    {name:'Suraj',degree:'B.Sc. (NM) IV Sem 75.7%',img:'/assets/img/star/37.png'},
    {name:'Shivam Kumar',degree:'B.Sc. (IC) IV Sem 74.5%',img:'/assets/img/star/38.png'},
    {name:'Deepanshu Kamboj',degree:'B.Sc. (NM) IV Sem 74.4%',img:'/assets/img/star/39.png'},
    {name:'Arpit',degree:'B.Sc. (NM) IV Sem 74.3%',img:'/assets/img/star/40.png'},
    {name:'Sumit',degree:'B.Sc. (IC) IV Sem 70%',img:'/assets/img/star/41.png'},
    {name:'Udit Dhiman',degree:'B.Sc. (CS) IV Sem 72.3%',img:'/assets/img/star/42.png'},
    {name:'Varun',degree:'B.Sc. IC IV Sem 76.5%',img:'/assets/img/star/43.png'},
    {name:'Vishal',degree:'B.Sc. (CS) IV Sem 69.16%',img:'/assets/img/star/44.png'},
    {name:'Komal',degree:'BA III 82% in Public Admn.',img:'/assets/img/star/45.png'},
    {name:'Anil Yadav',degree:'BAIII 79%',img:'/assets/img/star/46.png'},
    {name:'Ankita',degree:'BA 3, Public Adm 71%',img:'/assets/img/star/47.png'},
    {name:'Sahil',degree:'BA. III',img:'/assets/img/star/48.png'},
    {name:'Palak Kaur',degree:'BA. III',img:'/assets/img/star/49.png'},
    {name:'Sumit Gujjar',degree:'BA. III',img:'/assets/img/star/50.png'}
    
  ];

  private alumni:{name:string,degree:string,img:string}[]=[
    {name:'Sh. Gurdyal Singh Nimer',degree:'International Punjabi Poet',img:'/assets/img/alumni/1.jpg'},
    {name:'Mr. Sukhchain Singh',degree:'Technical Judge, International Weight Lifting Federation',img:'/assets/img/alumni/2.jpg'},
    {name:'Dr. Vijay Dahiya',degree:' Medical Superientendent Yamuna Nagar',img:'/assets/img/alumni/3.jpg'},
    {name:'Mr. Ankur Sharma ',degree:'Body Builder Mr.India & Mr. Universe (4th position)',img:'/assets/img/alumni/4.png'},
    {name:'Mr. Dhiraj Bhatia',degree:'Prop., Kips India Publishers Noida',img:'/assets/img/alumni/5.png'},
    {name:'Col. Kanwaljit Singh ',degree:'Commanding Officer, 12, Kumaon at Umroi, Shillong (Meghalya)',img:'/assets/img/alumni/6.jpg'},
    {name:'Lt. Col Vijay Bakshi',degree:'Second in command of 301 special operations Squadron',img:'/assets/img/alumni/7.jpg'},
    {name:'Kanwar Sahib Ansal',degree:'Second-In-Comand. 52 Bn BSF Sadqui Road Fazilka Punjab.',img:'/assets/img/alumni/8.jpg'},
    {name:'Lt. Col. Avinash Nair',degree:'',img:'/assets/img/alumni/9.png'},
    {name:'Sh. Narender Singh',degree:'S.H.O. Haryana Police Posted at Shahzadpur',img:'/assets/img/alumni/10.png'},
    {name:'Mr. Dinesh Tyagi',degree:'Sessions Judge, POCSO Court No.2, Udaipur',img:'/assets/img/alumni/11.png'},
    {name:'Mohd. Miraj',degree:'Branch Manager, HDFC Bank Kotdwar',img:'/assets/img/alumni/12.png'},
    {name:'Mr. Hitesh Bhalla',degree:'Branch Manager, Axis Bank Hissar',img:'/assets/img/alumni/13.png'},
    {name:'Ms. Deepika Jain',degree:'Labour Welfare Officer,Arisht Spinning Mills (Wardhman Group) Baddies, Solan (H.P.)',img:'/assets/img/alumni/14.jpg'},
    {name:'Pranav Mishra',degree:'Assistant Executive Engineer(DFS)/ Drilling Fluid Engineer A&AA Basin, Assam, Oil and Natural Gas Corporation (ONGC)',img:'/assets/img/alumni/15.jpg'},
    {name:'Vinit Pundir',degree:'Assistant Vice President, IT, Indorama Group, Bangkok    ',img:'/assets/img/alumni/16.png'},
    {name:'Dr. Harish Suri',degree:'Senior Research Lead, Evalueserve, Gurugram',img:'/assets/img/alumni/17.png'},
    {name:'Mr. Supreet Bhalla',degree:'Associate Director, Pre Sales, AT & T',img:'/assets/img/alumni/18.png'},
    {name:'Mr. Gurbaj Singh',degree:'International Hockey Umpire',img:'/assets/img/alumni/19.jpg'},
    {name:'Bikramjit Singh',degree:'President and Founder, Warriors Hockey Club, Finland, Member of Finland Hockey Association, National Hockey Umpire in Finland',img:'/assets/img/alumni/20.jpg'},
    {name:'SI Ram Lal',degree:'Scaled Mt. Everest on 21 May 2013 and created World Record by cycling on seabed in 2016',img:'/assets/img/alumni/21.png'},
    {name:'Ms. Seema Goswami',degree:'Scaled Mount Everest summit date 20/05/2016    ',img:'/assets/img/alumni/22.png'},
    {name:'Ms. Sunita Singh Choken',degree:'Mountaineer Scaled Mount Everest in 2011',img:'/assets/img/alumni/23.jpg'},
    {name:'Dr. Amit Joshi',degree:'Director, GNKITM Technical Campus Yamuna Nagar',img:'/assets/img/alumni/24.png'},
    {name:'Mr.Atul Ahuja',degree:'Asstt. Professor, Physics dept., IB(PG), College, Panipat.',img:'/assets/img/alumni/25.png'},
    {name:' Mr. Rajiv Goldy (Comedian)',degree:'Stand Up Comedy Artist. Participated in " The Great Indian Laughter Challenge" with Akashay Kumar on Star Plus. Coming Soon as an Anchor on DD National\'s Reality Show "Shining Star".',img:'/assets/img/alumni/26.jpg'},
    {name:'Mr. Maninder Singh',degree:'Country Manager II, Accord Healthcare Ltd., Keyna',img:'/assets/img/alumni/27.png'},
    {name:'Mr. Malkiat Singh',degree:'Inspector posted at Kurukshetra',img:'/assets/img/alumni/28.jpg'},
    {name:'Dr. Bhupinder Singh',degree:'Assistant Professor, Faculty of Water Supply and Environmental Engineering Arbaminch University, Ethiopia',img:'/assets/img/alumni/29.png'},
    {name:'Mr. Ankur Kansal',degree:'Environment Engineer, Uttarakhand Pollution Control Board, Dehradun',img:'/assets/img/alumni/30.png'},
  ]
  constructor() { }
  getStar(){
    return this.star.slice();
  }

  getAlumni(){
    return this.alumni.slice();
  }
}
