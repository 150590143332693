import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NCTE } from 'src/app/models/ncte.model';
import { NcteService } from 'src/app/services/ncte/ncte.service';
import { ShowNcteService } from 'src/app/services/showNcte/show-ncte.service';

@Component({
  selector: 'app-ncte',
  templateUrl: './ncte.component.html',
  styleUrls: ['./ncte.component.scss']
})
export class NcteComponent implements OnInit,OnDestroy {
  nctes:NCTE[]=[];
  showNcte=true;
  sub:Subscription[]=[];
  constructor(private ncteService:NcteService,
    private showNcteService:ShowNcteService,
    private router:Router) { }

  ngOnInit(): void {
    this.ncteService.fetchNcte();
    
    this.sub[0] = this.ncteService.ncteChange.subscribe(ncte=>{
      this.nctes=ncte;
    
    });
    this.sub[1]= this.showNcteService.ncteChange.subscribe(value=>{
      this.showNcte = value;
    });
  }
  hideNcte(){
    this.showNcteService.hide();
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.hideNcte();
      this.router.navigate(['/menu-detail/ncte/'+url[3]]);

    }
    
  }
  ngOnDestroy(): void {
      this.sub.forEach(s=>{
        s.unsubscribe();
      })
  }

}
