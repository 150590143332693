<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>1.3.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">1.3.2.1 Certificate from Head of the Institute</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria1/1-3-2/1-3-2-3" (click)="checkRoute()" (click)="changeRoute('2')" >1.3.2.3 Project Work Completion Certificates</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">1.3.2.4 Report of Field Work</a>
                    </li>
                    <li class="four">
                        <a (click)="changeRoute('4')">1.3.2.5 Objectives and outcomes of Field work</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'" *ngIf="!showPdf">
            <router-outlet></router-outlet>
        </div>
        <div class="col-md-9" *ngIf="showPdf">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading1">Certificate from Head of the Institute</h5>
                            <h5 class="card-title text-center" *ngIf="heading2">Report of Field Work</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">Objectives and outcomes of Field work</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>