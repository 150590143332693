import { Component, OnInit } from '@angular/core';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private pathNameService:PathNameService) { }

  ngOnInit(): void {
  }
  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }

}
