import { Component, OnInit } from '@angular/core';
import { ResearchProject } from 'src/app/models/research-project.model';
import { ProjectService } from 'src/app/services/research/project.service';

@Component({
  selector: 'app-minor-project',
  templateUrl: './minor-project.component.html',
  styleUrls: ['./minor-project.component.scss']
})
export class MinorProjectComponent implements OnInit {
  minorProject:ResearchProject[];
  constructor(private projectService:ProjectService) { }

  ngOnInit(): void {
    this.minorProject=this.projectService.getMinorProject();
  }

}
