import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireList } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';
import { NAAC } from 'src/app/models/naac.model';
import { ShowNaacService } from '../showNaac/show-naac.service';

@Injectable({
  providedIn: 'root'
})
export class NaacService {
  naacList:AngularFireList<any>;
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  private naacs:NAAC[]=[];
  newNaac:NAAC[]=[];
  naacChange=new BehaviorSubject<NAAC[]>([]);
  constructor(private http:HttpClient,
    private router:Router,
    private showService:ShowNaacService) { }

  fetchNaac(){
    this.http.get<NAAC[]>(this.url+'/naac.json').subscribe(res=>{
      this.setNaac(res);
    },error=>{
      console.log(error);
    });
  }

  setNaac(naac:NAAC[]){
    this.naacs=naac;
    this.newNaac=Object.keys(this.naacs).map(key=>({$key:key,...this.naacs[key]}));
    this.naacChange.next(this.newNaac.reverse());
    this.splitUrl();
    // console.log(this.newContacts);
  }

  searchNaac(id:string){
    for(let naacItem of this.newNaac){
      if(naacItem.path===id){
        return naacItem.link;
      }
    }
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.showService.hide();
      this.router.navigate(['/menu-detail/naac/'+url[3]]);

    }
    
  }
}
