<div id="why" data-scroll>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class=" font-weight-bold heading"> Why GNKC?</h2>
                <hr class=" p-1" style="width: 300px;">
                <p class="font-weight-bold">Come discover our exciting and vibrant campus and find out why #Life is invigorating and fulfilling.</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card1">
                    <div class="card-body text-center">
                        <img src="/assets/svg/courses/48.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>Global Collaboration</h4>
                        <p>The college honour keeps the students encouraged and motivated and gives them opportunity , guidance and support to shine at national and international stages.                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card2">
                    <div class="card-body text-center">
                        <img src="/assets/svg/why/1.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>All Round Development</h4>
                        <p>The college provides conducive environment to enrich students , physically and mentally. Acclaimed in sports at the University, State and National level. Amicable staff to promote wellbeing of students.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card3">
                    <div class="card-body text-center">
                        <img src="/assets/svg/why/2.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>Transforms Lives</h4>
                        <p>The serene and positive environs of the campus, promote skills and innate abilities to transform students into reliable and responsible individuals.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card4">
                    <div class="card-body text-center">
                        <img src="/assets/svg/why/3.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>Career Boost</h4>
                        <p>From Undergrads to Internships, the college promotes and fosters abilities in students to acquire and articulate their skills effectively for future employment level in the right way.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card5">
                    <div class="card-body text-center">
                        <img src="/assets/svg/why/4.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>Techno Advanced Education</h4>
                        <p>High Wi-fi speed campus, nurturing teaching by blended mode and LMS savvy staff . Latest online as well as Virtual modes to make  knowledge  accessible to students.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
                <div class="card card6">
                    <div class="card-body text-center">
                        <img src="/assets/svg/why/5.svg" alt="Globally" class="img-fluid mx-auto" >
                        <h4>Clean and Green Environment</h4>
                        <p>The college promotes the physical, psychological and educational environment and ecologically friendly, which develops and boosts confidence to develop physically, emotionally and mentally towards becoming better human beings.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>