import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-lp-twothree-twofour',
  templateUrl: './lp-twothree-twofour.component.html',
  styleUrls: ['./lp-twothree-twofour.component.scss']
})
export class LpTwothreeTwofourComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1ulh6747ht_Xa_5HHBpt6BaGYnmElDYpp/preview', 
    'https://drive.google.com/file/d/1kiYCIFn1WDENuQ4oVED8VeZLIrIAEnbw/preview' ,
    'https://drive.google.com/file/d/1HpgncNop9wq-pKx_z8gY1wMMzSsZRDkH/preview', 
    'https://drive.google.com/file/d/1Tbz3X_4fUekjFBZe-zyZ61Y6zubvbKnn/preview',
    'https://drive.google.com/file/d/15OwGtx5y_FS6DsGsMV35Jw5Ie3nkGd_A/preview',
    'https://drive.google.com/file/d/1zFWEE0Q4FOVqVhfK_p8hJx6s5H-nyfKi/preview',
    'https://drive.google.com/file/d/1U97nBrUDZOW5lcZsqEijDcPl05vcxRMn/preview',
    'https://drive.google.com/file/d/1Tq5hLGo7DNB0hc-r7nJGPuli1mVQPPhw/preview',
    'https://drive.google.com/file/d/1IOaPmWFxGHOs-R20Mwxvjvlukve6aQKE/preview',
    'https://drive.google.com/file/d/1g6vwn1Eel1icT-5xfSayASwYl94e-aOg/preview',
    'https://drive.google.com/file/d/1xmwz6BlYXY1pIRMqyzPe41OgoYvbMNU3/preview',
    'https://drive.google.com/file/d/1dhpWJNDDoU6rCiOxmZMjGs8pEtJ7LTL7/preview',
    'https://drive.google.com/file/d/1HfH_yc5UXkgNQXzWFKrWefNwJ89pCB6M/preview',
    'https://drive.google.com/file/d/108S4W6jpa1TIR8dZ4o9jcvJXNc0sKd-i/preview',
    'https://drive.google.com/file/d/1VQTqZUwW8vU-iCy76TJb0AqKTwcNm_iJ/preview',
    'https://drive.google.com/file/d/1pKQ6FtEz8SyIRzzxMR7qYH7_rNj9Yp-C/preview',
    'https://drive.google.com/file/d/1MOJteXu9shZL_PM1hgC1Kmt2max9ssxd/preview',
    'https://drive.google.com/file/d/1z0PZ7cg0iS8E2BIZklXWWUbhkdkIugoB/preview',
    'https://drive.google.com/file/d/15kwLc9Y23XTj4Eua8sLmme0KhUiDKpvk/preview',
    'https://drive.google.com/file/d/17GvkOXr0qVFKB8hirRUX-Bze1m-0ckot/preview',
    'https://drive.google.com/file/d/157Nkxm4qPOHRiAwlCN0dDXvPrPlj1sYs/preview',
    'https://drive.google.com/file/d/1J8s77BwwAFW0wZsrfL8fDjpA6Ci-9GiI/preview',
    'https://drive.google.com/file/d/1mwyh94iB4bSW7IMnzmRQV8wqF5u21Sn3/preview',
    'https://drive.google.com/file/d/15pM4VFxJNK1SPqnoCnjYP8jC9gsA_-vJ/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    if (year == '23') {
      $('.twentythree').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[22];
    }
    if (year == '24') {
      $('.twentyfour').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twenty').removeClass('active');
      this.src = this.pdfSrc[23];
    }
    
  }
  hideLoader() {
    this.isLoading = false;
  }
}
