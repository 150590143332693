<div id="virtual">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Virtual Classroom</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Virtual classrooms provide an opportunity to learn and practice a language without being physically present at a local center. This option, which works like a face-to-face webinar  enables students to interact with their trainer and other students in a dynamic environment. The virtual classroom being the heart of online programs is a convenient, central place where college courses unfold.
                             Although there are many fundamental similarities between a traditional campus-based education and the online college experience, learning through a virtual classroom offers many benefits over traditional college degree programs.
                        </p>
                        <p class="lead text-justify">
                            Guru Nanak Khalsa College has a virtual classroom on its campus and is proud to be the first college of North India to introduce this facility. This facility is used to deliver and live-stream lectures, conduct Virtual Classrooms and seminars through A-VIEW, which serve as a complete virtual learning tool.
                        </p>
                        <!-- <div class="row text-center">
                            <div class="row text-center">
                                <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/virtual/1.jpg" data-lightbox="Virtual Classroom"><img src="/assets/img/virtual/1.jpg" alt="Virtual Classroom" class="img-fluid"></a></div>
                                <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/virtual/2.jpeg" data-lightbox="Virtual Classroom"><img src="/assets/img/virtual/2.jpeg" alt="Virtual Classroom" class="img-fluid"></a></div>
                                <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/virtual/3.jpeg" data-lightbox="Virtual Classroom"><img src="/assets/img/virtual/3.jpeg" alt="Virtual Classroom" class="img-fluid"></a></div>
                                <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/virtual/4.jpeg" data-lightbox="Virtual Classroom"><img src="/assets/img/virtual/4.jpeg" alt="Virtual Classroom" class="img-fluid"></a></div>
                                <div class="col-sm-12 col-md-6 mt-2"><a href="/assets/img/virtual/5.jpeg" data-lightbox="Virtual Classroom"><img src="/assets/img/virtual/5.jpeg" alt="Virtual Classroom" class="img-fluid"></a></div>
                                
                            </div>
                           
                           
                        </div> -->
                        <div id="virtualCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#virtualCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#virtualCarousel" data-slide-to="1"></li>
                              <li data-target="#virtualCarousel" data-slide-to="2"></li>
                              <li data-target="#virtualCarousel" data-slide-to="3"></li>
                              <li data-target="#virtualCarousel" data-slide-to="4"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/virtual/1.jpg" alt="Virtual Classroom" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/virtual/2.jpeg" alt="Virtual Classroom" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/virtual/3.jpeg" alt="Virtual Classroom" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/virtual/4.jpeg" alt="Virtual Classroom" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/virtual/5.jpeg" alt="Virtual Classroom" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#virtualCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#virtualCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>