<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr/criteria3/3-2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>3.2.2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li>
                        <a>2021-22</a>
                    </li>
                    <li class="sub-menu one">
                        <a (click)="changeRoute('1')">Workshop on IPR and its role in Research</a>
                    </li>
                    <li class="sub-menu two">
                        <a (click)="changeRoute('2')">Strategic Gateway to Entrepreneurship
                            Development</a>
                    </li>
                    <li class="sub-menu three">
                        <a (click)="changeRoute('3')">National Webinar on Entrepreneurial
                            Development for Young Students</a>
                    </li>
                    <li>
                        <a>2020-21</a>
                    </li>
                    <li class="sub-menu four">
                        <a (click)="changeRoute('4')">ENTREPRENEURISHIP- As a Career
                            opportunity</a>
                    </li>
                    <li class="sub-menu five">
                        <a (click)="changeRoute('5')">Promoting Academic Integrity and Prevention of
                            Plagiarism in HEIs</a>
                    </li>
                    <li class="sub-menu six">
                        <a (click)="changeRoute('6')">One Week State Level FDP on -How to Prepare SSR
                            and AQAR for NAAC</a>
                    </li>
                    <li class="sub-menu seven">
                        <a (click)="changeRoute('7')">Webinar on world earth day</a>
                    </li>
                    <li class="sub-menu eight">
                        <a (click)="changeRoute('8')">National Workshop on IPR- Industry
                            Academia-Academia Interface</a>
                    </li>
                    <li>
                        <a>2019-20</a>
                    </li>
                    <li class="sub-menu nine">
                        <a (click)="changeRoute('9')">National Web-seminar on Research Methodology</a>
                    </li>
                    <li class="sub-menu ten">
                        <a (click)="changeRoute('10')">National Webinar on Entrepreneurial Skills</a>
                    </li>
                    <li class="sub-menu eleven">
                        <a (click)="changeRoute('11')">National workshop on IPR-Awareness and issues in academic Research</a>
                    </li>
                    <li class="sub-menu twelve">
                        <a (click)="changeRoute('12')">Industry Academia Interface on Industry Perspectives and Insights</a>
                    </li>
                    <li class="sub-menu thirteen">
                        <a (click)="changeRoute('13')">Industry Academia Interface With Eclex</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Workshops/Seminars/Conferences on Research Methodology ,IPR & Entreprenuership</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>