<div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>PG Diploma in Yoga Therapy (One Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/36.svg">
                    <h3>PG Diploma</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>P.G. Diploma in Yoga Therapy</p>
                    <p>(30 seats)</p>
                        <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal"  data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    
    
   
    
  </div>
  <div class="container course-detail mt-5">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.Sc. (Three Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/7.svg">
                    <h3>B.Sc.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Sc. (Physical Education, Health Education and Sports)</p>
                    <!-- <p>GIA - 50 Seats <br>
                      SF - 50 Seats</p> -->
                        <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
       
        
      </div>
    </div>
    
   
   
    
  </div>
  
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="creteria1"></h3>
          <p class="sem1"></p>
          <ol class="sem1b">
            <li class="sem1b1"></li>
            <li class="sem1b2"></li>
            <li class="sem1b3">
              
            </li>
          </ol>
          <ul class="se1b-detail">
            <li class="se1b-detail1"></li>
            <li class="se1b-detail2"></li>
            <li class="se1b-detail3"></li>
          </ul>
          <!-- <h3 class="creteria2"></h3>
          <ol class="sem2b">
            <li class="sem2b1"></li>
            <li class="sem2b2"></li>
            <li class="sem2b3"></li>
            <li class="sem2b4"></li>
          </ol>
          <div class="po-pso">
          
          </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>