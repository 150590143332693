<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria2/2-3/2-3-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>ICT</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('1')">Educational Trip</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('2')">Essay Writing</a>
                    </li>
                    <li class="third">
                        <a (click)="changeRoute('3')">Expert Talk</a>
                    </li>
                    <li class="fourth">
                        <a (click)="changeRoute('4')">Extension Lecture</a>
                    </li>
                    <li class="fifth">
                        <a (click)="changeRoute('5')">Field Trips</a>
                    </li>
                    <li class="fifth">
                        <a (click)="changeRoute('5')">Field Trips</a>
                    </li>
                    <li class="six">
                        <a (click)="changeRoute('6')">Flipped Classrooms</a>
                    </li>
                    <li class="seven">
                        <a (click)="changeRoute('7')"> Group assignment</a>
                    </li>
                    <li class="eight">
                        <a (click)="changeRoute('8')"> GROUP PROJECTS</a>
                    </li>
                    <li class="nine">
                        <a (click)="changeRoute('9')"> ICT TOOLS</a>
                    </li>
                    <li class="ten">
                        <a (click)="changeRoute('10')"> Mentor</a>
                    </li>
                    <li class="eleven">
                        <a (click)="changeRoute('11')"> Peer tutoring</a>
                    </li>
                    <li class="twelve">
                        <a (click)="changeRoute('12')"> PPT Contest</a>
                    </li>
                    <li class="thirteen">
                        <a (click)="changeRoute('13')"> PRACTICAL</a>
                    </li>
                    <li class="fourteen">
                        <a (click)="changeRoute('14')"> Quiz</a>
                    </li>
                    <li class="fifteen">
                        <a (click)="changeRoute('15')"> Seminar</a>
                    </li>
                    <li class="sixteen">
                        <a (click)="changeRoute('16')"> Training Program</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">STUDENT CENTRIC AND ICT TOOLS</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>