<div id="program">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h2 style="font-size: 32px;" class=" font-weight-bold"> Programs Offered</h2>
                <hr class=" p-1" style="width: 300px;">
            </div>
        </div>
        <div id="programCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#programCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#programCarousel" data-slide-to="1"></li>
                <li data-target="#programCarousel" data-slide-to="2"></li>
                <li data-target="#programCarousel" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card  ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/8.svg" alt="Arts" class="img-fluid">
                                    </div>
                                    <div class="col-10">
                                        <h3 class="font-weight-bold">ARTS</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 divider">
                                        <span><i class="bi bi-check-all"></i>M.A. (Social Work)</span><br>
                                        <span><i class="bi bi-check-all"></i>M.A. (Punjabi)</span><br>
                                        <span><i class="bi bi-check-all"></i>M.A. (PUblic Administration)</span><br><br>
                                        <a routerLink="/courses/arts">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <span><i class="bi bi-check-all"></i>B.A. (Economics)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.A. (Political Science)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.A. (History)</span><br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/55.svg" alt="Science" class="img-fluid">
                                    </div>
                                    <div class="col-10">

                                        <h3 class="font-weight-bold">SCIENCE</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 divider">
                                        <span><i class="bi bi-check-all"></i>M.Sc. (Biotechnology)</span><br>
                                        <span><i class="bi bi-check-all"></i>M.Sc. (Mathematics)</span><br>
                                        <span><i class="bi bi-check-all"></i>M.Sc. (Physics)</span><br><br>
                                        <a routerLink="/courses/science">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <span><i class="bi bi-check-all"></i>B.Sc.(Medical)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.Sc.(Non- Medical)</span><br><br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/29.svg" alt="Computer Science" class="img-fluid">
                                    </div>
                                    <div class="col-10">

                                        <h3 class="font-weight-bold">COMPUTER SCIENCE</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 ">
                                        <span><i class="bi bi-check-all"></i>B.Sc. (Computer Science)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.Sc. (Computer Application)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.C.A. (Computer
                                            Application)</span><br><br>
                                        <a routerLink="/courses/science">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <!-- <span><i class="bi bi-check-all"></i>B.Sc. (Industry Chemistry)</span>  -->
                                        <br>
                                        <!-- <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Chemistry)</span>  -->
                                        <br>
                                        <!-- <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Microbiology)</span>  -->
                                        <br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/34.svg" alt="Commerce" class="img-fluid">
                                    </div>
                                    <div class="col-10">
                                        <!-- <h5 class="font-weight-bold">SCHOOL OF</h5> -->
                                        <h3 class="font-weight-bold">COMMERCE</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 divider">
                                        <span><i class="bi bi-check-all"></i>M.Com. (50 Seats)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.B.A. (70 Seats)</span><br><br><br>
                                        <a routerLink="/courses/commerce">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <span><i class="bi bi-check-all"></i>B.Com. (General)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.Com. (Computer Application)</span><br>
                                        <span><i class="bi bi-check-all"></i>B.Com. (Hons.)</span><br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/36.svg" alt="Commerce" class="img-fluid">
                                    </div>
                                    <div class="col-10">
                                        <!-- <h5 class="font-weight-bold">SCHOOL OF</h5> -->
                                        <h3 class="font-weight-bold">PHYSICAL EDUCATION</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 ">
                                        <span><i class="bi bi-check-all"></i>PG Diploma in Yoga Therapy</span><br>
                                        <span><i class="bi bi-check-all"></i>B.Sc.</span><br><br><br>
                                        <a routerLink="/courses/physical">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <br>
                                        <br>
                                        <br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/54.svg" alt="UGC & Education" class="img-fluid">
                                    </div>
                                    <div class="col-10">
                                        <!-- <h5 class="font-weight-bold">SCHOOL OF</h5> -->
                                        <h3 class="font-weight-bold">Community College - Diploma</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <span><i class="bi bi-check-all"></i>Diploma in Retail Mgmt.</span><br>
                                        <span><i class="bi bi-check-all"></i>Diploma in Banking Mgmt.</span><br><br>
                                        <a routerLink="/courses/ugc">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <br>
                                        <br>
                                        <br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="carousel-item">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 mt-5">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-2">
                                        <img src="/assets/svg/courses/54.svg" alt="UGC & Education" class="img-fluid">
                                    </div>
                                    <div class="col-10">
                                        <!-- <h5 class="font-weight-bold">SCHOOL OF</h5> -->
                                        <h3 class="font-weight-bold">EDUCATION</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <span><i class="bi bi-check-all"></i>B.Ed. </span><br>
                                        <span><i class="bi bi-check-all"></i>B.P.Ed. </span><br>
                                        <a routerLink="/courses/education">KNOW MORE</a>
                                    </div>
                                    <div class="col-6 pl-3">
                                        <br>
                                        <br>
                                        <br><br>
                                        <button class="btn" style="cursor: pointer;" data-toggle="modal"
                                            data-target="#applyModal1">Apply Now</button>
                                    </div>
                                </div>
                                <div class="main-bg"></div>
                                <div class="bubble-1"></div>
                                <div class="bubble-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#programCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fas fa-chevron-left"></i></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#programCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"><i class="fas fa-chevron-right"></i></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!-- <div class="row">
            <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg " data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/8.svg" alt="Arts" class="img-fluid">
                        </div>
                        <div class="col-10">
                            <h3 class="font-weight-bold">ARTS</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span><i class="bi bi-check-all"></i>M.A. (Social Work)</span><br>
                            <span><i class="bi bi-check-all"></i>M.A. (Punjabi)</span><br>
                            <span><i class="bi bi-check-all"></i>M.A. (PUblic Administration)</span><br><br>
                            <a routerLink="/courses/arts">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span><i class="bi bi-check-all"></i>B.A. (Economics)</span><br>
                            <span><i class="bi bi-check-all"></i>B.A. (Political Science)</span><br>
                            <span><i class="bi bi-check-all"></i>B.A. (History)</span><br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/55.svg" alt="Science" class="img-fluid">
                        </div>
                        <div class="col-10">
                            
                            <h3 class="font-weight-bold">SCIENCE</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span><i class="bi bi-check-all"></i>M.Sc. (Biotechnology)</span><br>
                            <span><i class="bi bi-check-all"></i>M.Sc. (Mathematics)</span><br>
                            <span><i class="bi bi-check-all"></i>M.Sc. (Physics)</span><br><br>
                            <a routerLink="/courses/science">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span><i class="bi bi-check-all"></i>B.Sc.(Medical)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc.(Non- Medical)</span><br><br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div> -->
        <!-- <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/27.svg" alt="Industry" class="img-fluid">
                        </div>
                        <div class="col-10">
                           
                            <h3 class="font-weight-bold">INDUSTRY</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industry Chemistry)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Chemistry)</span><br><br><br>
                            <a routerLink="/courses/science">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Microbiology)</span><br>
                            <br>
                            <br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div> -->

        <!-- <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/29.svg" alt="Computer Science" class="img-fluid">
                        </div>
                        <div class="col-10">
                            
                            <h3 class="font-weight-bold">COMPUTER SCIENCE</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span><i class="bi bi-check-all"></i>B.Sc. (Computer Science)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc. (Computer Application)</span><br>
                            <span><i class="bi bi-check-all"></i>B.C.A. (Computer Application)</span><br><br>
                            <a routerLink="/courses/science">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industry Chemistry)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Chemistry)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc. (Industrial Microbiology)</span><br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/34.svg" alt="Commerce" class="img-fluid">
                        </div>
                        <div class="col-10">
                            
                            <h3 class="font-weight-bold">COMMERCE</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span><i class="bi bi-check-all"></i>M.Com. (50 Seats)</span><br>
                            <span><i class="bi bi-check-all"></i>B.B.A. (70 Seats)</span><br><br><br>
                            <a routerLink="/courses/commerce">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span><i class="bi bi-check-all"></i>B.Com. (General)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Com. (Computer Application)</span><br>
                            <span><i class="bi bi-check-all"></i>B.Com. (Hons.)</span><br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/36.svg" alt="Commerce" class="img-fluid">
                        </div>
                        <div class="col-10">
                            
                            <h3 class="font-weight-bold">PHYSICAL EDUCATION</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 ">
                            <span><i class="bi bi-check-all"></i>PG Diploma in Yoga Therapy</span><br>
                            <span><i class="bi bi-check-all"></i>B.Sc.</span><br><br><br>
                            <a routerLink="/courses/physical">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                          <br>
                           <br>
                            <br><br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div>
            
            <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/54.svg" alt="UGC & Education" class="img-fluid">
                        </div>
                        <div class="col-10">
                            
                            <h3 class="font-weight-bold">UGC & EDUCATION</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span class="font-weight-bold">UGC</span><br>
                            <span><i class="bi bi-check-all"></i>Diploma in Retail Mgmt.</span><br>
                            <span><i class="bi bi-check-all"></i>Diploma in Banking Mgmt.</span><br><br>
                            <a routerLink="/courses/ugc">KNOW MORE</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span class="font-weight-bold">EDUCATION</span><br>
                            <span><i class="bi bi-check-all"></i>B.Ed. </span><br>
                            <span><i class="bi bi-check-all"></i>B.P.Ed. </span><br>
                            <br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div> -->

        <!-- <div class="col-sm-12 col-md-6 mt-5">
                <div class="card shadow-lg" data-scroll>
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/svg/courses/48.svg" alt="UGC & Education" class="img-fluid">
                        </div>
                        <div class="col-10">
                            <h5 class="font-weight-bold">ADD ON</h5>
                            <h3 class="font-weight-bold">COURSES</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 divider">
                            <span ><i class="bi bi-check-all"></i>Financial Services</span><br>
                            <span><i class="bi bi-check-all"></i>Digital Marketing</span><br>
                            <span><i class="bi bi-check-all"></i>Networking</span><br><br>
                            <a routerLink="/courses/add-on" (click)="setUrl('Add On')" >VIEW ALL</a>
                        </div>
                        <div class="col-6 pl-3">
                            <span ><i class="bi bi-check-all"></i>Hygiene and Fitness</span><br>
                            <span><i class="bi bi-check-all"></i>Yoga </span><br>
                            <span><i class="bi bi-check-all"></i>Medical Lab Technician</span><br>
                            <br>
                            <button class="btn">Apply Now</button>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </div> -->
        <!-- </div> -->
    </div>
</div>


<div class="modal" id="applyModal1" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Apply for Admission now</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <button class="btn btn-primary mr-3" routerLink="enquiry-form">Enquiry</button>
                <button class="btn btn-primary mr-3" routerLink="apply-form">Registration Form</button>
                <button class="btn btn-primary" (click)="admissionForm(3)">Job Application Form</button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>