import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c7-seven-one-two',
  templateUrl: './c7-seven-one-two.component.html',
  styleUrls: ['./c7-seven-one-two.component.scss']
})
export class C7SevenOneTwoComponent implements OnInit {
  src='/assets/pdf/NAAC/Criterion-7/7.1.2_Policy document for disabled friendly environment and new solar panel process.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
