import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-one-one-seven',
  templateUrl: './c1-one-one-one-seven.component.html',
  styleUrls: ['./c1-one-one-one-seven.component.scss']
})
export class C1OneOneOneSevenComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 1/1.1.1_CURRICULUM DELIVERY/1.1.1.7_INTERNAL ASSESSMENT.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }

}
