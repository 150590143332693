<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" *ngIf="showSideMenu">
            <a routerLink="/naac/ssr/criteria3/3-4"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>3.4.3</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="activated">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/3-4-3-1" (click)="checkRoute()">3.4.3.1</a>
                    </li>
                    <li>
                        <a>Extension Activities List Reports</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/2021-22" (click)="checkRoute()">2021-22</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/2020-21" (click)="checkRoute()">2020-21</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/2019-20" (click)="checkRoute()">2019-20</a>
                    </li>
                    <li routerLinkActive="active" class="sub-menu">
                        <a  routerLink="/naac/ssr/criteria3/3-4/3-4-3/2018-19" (click)="checkRoute()">2018-19</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9" *ngIf="showHeading">
            <h4 class="text-center">Extension & Outreach Activities</h4>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>