<div id="course">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Courses</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Courses</a></li>
             
            </ol>
          </nav>
    </div>
    <div>
        <div class="container-fluid">
            <div class="course-list shadow-lg course-detail">
                <div class="box-default3 text-center">
                  <h2 >Courses</h2>
                  <hr class="pb-1 rounded ml-auto">
                  <div class="row">
                    <div class="col-md-12 col-xl-6 mb-3">
                      <div class="card">
                        <div class="face face1 text-center mx-auto">
                            <div class="content">
                                <img src="/assets/svg/courses/8.svg">
                                <h3>Humanities</h3>
                            </div>
                        </div>
                        <div class="face face2 mx-auto">
                            <div class="content">
                                <p>Humanities</p>
                                    <a class="btn btn-block" routerLink="/courses/arts" (click)="setUrl('Humanities')">Check Courses</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-12 col-xl-6 mb-3">
                      <div class="card">
                        <div class="face face1 text-center mx-auto">
                            <div class="content">
                                <img src="/assets/svg/courses/2.svg">
                                <h3>Science</h3>
                            </div>
                        </div>
                        <div class="face face2 mx-auto">
                            <div class="content">
                                <p>Science</p>
                                    <a class="btn btn-block" routerLink="/courses/science" (click)="setUrl('Science')" >Check Courses</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-12 col-xl-6 mb-3">
                      <div class="card">
                        <div class="face face1 text-center mx-auto">
                            <div class="content">
                                <img src="/assets/svg/courses/9.svg">
                                <h3>Commerce</h3>
                            </div>
                        </div>
                        <div class="face face2 mx-auto">
                            <div class="content">
                                <p>Commerce</p>
                                    <a class="btn btn-block" routerLink="/courses/commerce" (click)="setUrl('Commerce')" >Check Courses</a>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-md-12 col-xl-6 mb-3">
                        <div class="card">
                          <div class="face face1 text-center mx-auto">
                              <div class="content">
                                  <img src="/assets/svg/courses/36.svg">
                                  <h3>Physical Education, Health Education and Sports</h3>
                              </div>
                          </div>
                          <div class="face face2 mx-auto">
                              <div class="content">
                                  <p>Physical Education, Health Education and Sports</p>
                                      <a class="btn btn-block" routerLink="/courses/physical" (click)="setUrl('Physical Education, Health Education and Sports')" >Check Courses</a>
                              </div>
                          </div>
                      </div>
                      </div>

                      <div class="col-md-12 col-xl-6 mb-3">
                        <div class="card">
                          <div class="face face1 text-center mx-auto">
                              <div class="content">
                                  <img src="/assets/svg/courses/37.svg">
                                  <h3>UGC Skill Based Course</h3>
                              </div>
                          </div>
                          <div class="face face2 mx-auto">
                              <div class="content">
                                  <p>UGC Skill Based Course</p>
                                      <a class="btn btn-block" routerLink="/courses/ugc" (click)="setUrl('UGC Skill Based Course')" >Check Courses</a>
                              </div>
                          </div>
                      </div>
                      </div>

                      <div class="col-md-12 col-xl-6 mb-3">
                        <div class="card">
                          <div class="face face1 text-center mx-auto">
                              <div class="content">
                                  <img src="/assets/svg/courses/38.svg">
                                  <h3>Education</h3>
                              </div>
                          </div>
                          <div class="face face2 mx-auto">
                              <div class="content">
                                  <p>Education</p>
                                      <a class="btn btn-block" routerLink="/courses/education" (click)="setUrl('Education')" >Check Courses</a>
                              </div>
                          </div>
                      </div>
                      </div>
                      <div class="col-md-12 col-xl-6 mb-3">
                        <div class="card">
                          <div class="face face1 text-center mx-auto">
                              <div class="content">
                                  <img src="/assets/svg/courses/40.svg">
                                  <h3>Add On</h3>
                              </div>
                          </div>
                          <div class="face face2 mx-auto">
                              <div class="content">
                                  <p>Add On</p>
                                      <a class="btn btn-block" routerLink="/courses/add-on" (click)="setUrl('Add On')" >Check Courses</a>
                              </div>
                          </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
        </div>
       
    </div>
</div>
