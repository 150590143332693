import { Component, OnInit } from '@angular/core';
import { EContentService } from 'src/app/services/e-content/e-content.service';
declare var $: any;
@Component({
  selector: 'app-arts',
  templateUrl: './arts.component.html',
  styleUrls: ['./arts.component.scss']
})
export class ArtsComponent implements OnInit {
  isLoading = true;
  faculty:{name:string ,title:string, link:string}[] =[];
  constructor(private contentService: EContentService) {}
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      this.faculty = this.contentService.getAnurag();
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      this.faculty = this.contentService.getShalini();
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      this.faculty = this.contentService.getTilak();
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
