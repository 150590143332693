<div id="student">
  <div id="showcase">
    <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
  </div>
  <div id="heading">
    <h1>IQAC</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">IQAC</a></li>
        <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Student Satisfaction
          Survey</li>
      </ol>
    </nav>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <nav id="secondary-navigation">
          <!-- Header -->
          <header class="header">
            <h5>Student Satisfaction Survey</h5>
          </header>
          <!-- /Header -->
          <ul>
            <li class="first">
              <a (click)="changeRoute('2022-23')">2022-23</a>
            </li>
            <li class="second">
              <a (click)="changeRoute('2021-22')">2021-22</a>
            </li>

          </ul>
        </nav>
      </div>

      <div class="col-md-9" *ngIf="show22">
        <div id="sports">
          <div class="container-fluid pdf">
            <div class="row">
              <div class="col-12">
                <div class="card shadow-lg">

                  <!-- <h5 class="card-title text-center"></h5> -->
                  <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9" *ngIf="!show22">
        <div id="sports">
          <div class="container">
            <div class="card shadow-lg">
              <h4 class="card-title text-center">ANALYSIS OF STUDENT FEEDBACK 2021-22</h4>
              <div class="card-body">
                <div class="ranked">
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">COMPONENT</th>
                        <th scope="col">STUDENTS RESPONSE (%Agree)</th>
                        <th scope="col">STUDENTS RESPONSE (%Disagree)</th>
                        <th scope="col">STUDENTS RESPONSE (%Agree to some extend)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of data; let i=index">
                        <th scope="row">{{item.Serial}}</th>
                        <td>{{item.component}}</td>
                        <td>{{item.agree}}</td>
                        <td>{{item.disagree}}</td>
                        <td>{{item.extent}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 class="card-title text-center">STUDENT FEEDBACK 2021-22</h4>
                <p class="lead text-justify">During the academic session 2021-22, majority (92%) of the students agree that
                  there are various subjects and subject combinations available in the college.
                  Majority (87%) of the students do agree that teachers help them in choosing the various subjects and around ¾
                  of the students think that these subjects help them in the employability.
                  Majority of the students confirmed that all classes are regularly held in the college.
                  Online survey shows that teachers use PPT method and white board method in their teaching.
                  A large number of students feel that all text books are available and issued by library staff well in time.
                  A large number of the students (94%) take part in NSS, NCC and cultural Activities. Around 73% of the students
                  were satisfied with training and placement camps that are arranged in the college.
                  A large number of students feel secured in the college and the discipline is well maintained by various
                  teachers from time to time.
                  Majority of the students (86%) feel that various cultural and other programs are held. Students (82%) are
                  satisfied with the sports facilities available in the college campus.
                  Fee concession is given to about 78% of the students. Majority of the students agree that college teachers are
                  well equipped with the teaching skills. 86% of the students do agree that the grievances are resolved amicably
                  by the Staff and the Principal.
                  Students are of the opinion that ample seating arrangement is available in the campus. Teaching staff is
                  available most of the time in the college.
                  Majority of the Students do agree that very good attendance system and examination pattern exists in the
                  college.
                  84 % of the students are of the view that Moral Education lectures & Community Functions are held regularly in
                  the college.
                  A large percentage (77%) of the students told about the computer awareness and Environmental Studies subjects
                  available in the college.
                  The students do mentioned about the employment information is provided to them for their benefits.</p>
              </div>
        
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>