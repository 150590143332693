import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twoone-twotwo',
  templateUrl: './twoone-twotwo.component.html',
  styleUrls: ['./twoone-twotwo.component.scss']
})
export class TwooneTwotwoComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1_Extension_Activities_Reports and Attendance_Final_SSR_Folder (2)/3.4.3 2021-22/';

  result:{title:string,link:string}[]=[
    {
      title:'HARYANA DAY',
      link:this.c343Url + '41_NCC_HARYANA DAY.pdf'
    },
    {
      title:'ARMED FORCES FLAG DAY',
      link:this.c343Url + '42_NCC_ARMED FORCES FLAG DAY.pdf'
    },
    {
      title:'NADI UTSAV',
      link:this.c343Url + '43_NCC_NADI UTSAV.pdf'
    },
    {
      title:'NISHANT SHARMA SAHEEDI DIWAS',
      link:this.c343Url + '44_NCC_NISHANT SHARMA SAHEEDI DIWAS.pdf'
    },
    {
      title:'OPPORTUNITY FOR GIRLS IN ARMY',
      link:this.c343Url + '45_NCC_OPPORTUNITY FOR GIRLS IN ARMY.pdf'
    },
    {
      title:'WORLD DRUG DAY RALLY',
      link:this.c343Url + '46_NCC_WORLD DRUG DAY RALLY.pdf'
    },
    {
      title:'ONE DAY VACCINATION CAMP',
      link:this.c343Url + '47_NSS_ONE DAY VACCINATION CAMP.pdf'
    },
    {
      title:'CLEANINESS DRIVE',
      link:this.c343Url + '48_NSS_CLEANINESS DRIVE.pdf'
    },
    {
      title:'AATAMNIRBHAR PROGRAM',
      link:this.c343Url + '49_NSS_AATAMNIRBHAR PROGRAM.pdf'
    },
    {
      title:'MEGHA COVID VACCINATION CAMP',
      link:this.c343Url + '50_NSS_MEGHA COVID VACCINATION CAMP.pdf'
    },



    {
      title:'NSS DAY CELEBRATION',
      link:this.c343Url + '51_NSS_NSS DAY CELEBRATION.pdf'
    },
    {
      title:'PULSE POLIO CAMPAIGN',
      link:this.c343Url + '52_NSS_PULSE POLIO CAMPAIGN.pdf'
    },
    {
      title:'POSHANMAH CAMP',
      link:this.c343Url + '53_NSS_POSHANMAH CAMP.pdf'
    },
    {
      title:'UNIVERSITY LEVEL PRE-RD TRIAL CAMP',
      link:this.c343Url + '54_NSS_UNIVERSITY LEVEL PRE-RD TRIAL CAMP.pdf'
    },
    {
      title:'NORHT ZONE PRE-RD CAMP, BIYANI  UNIVERSITY, JAIPUR',
      link:this.c343Url + '55_NSS_NORHT ZONE PRE-RD CAMP, BIYANI  UNIVERSITY, JAIPUR.pdf'
    },
    {
      title:'STATE LEVEL NSS CAMP',
      link:this.c343Url + '56_NSS_STATE LEVEL NSS CAMP.pdf'
    },
    {
      title:'NATIONAL SEMINAR AND CLEANINESS DRIVE AT GVT COLLEGE AHARWALA',
      link:this.c343Url + '57_NSS_NATIONAL SEMINAR AND CLEANINESS DRIVE AT GVT COLLEGE AHARWALA.pdf'
    },
    {
      title:'UNIVERSITY LEVEL NSS CAMP, Mullana',
      link:this.c343Url + '58_NSS_UNIVERSITY LEVEL NSS CAMP, Mullana.pdf'
    },
    {
      title:'NATIONAL INTERGRATION CAMP AT KUK',
      link:this.c343Url + '59_NSS_NATIONAL INTERGRATION CAMP AT KUK.pdf'
    },
    {
      title:'ARMED FORCED FLAG DAY DONATION COLLECTED CAMP',
      link:this.c343Url + '60_NSS_ARMED FORCED FLAG DAY DONATION COLLECTED CAMP.pdf'
    },



    {
      title:'SEMINAR ON GURUPURAB',
      link:this.c343Url + '61_NSS_SEMINAR ON GURUPURAB.pdf'
    },
    {
      title:'COLLEGE FOUNDATION DAY CELEBRATION',
      link:this.c343Url + '62_NSS_COLLEGE FOUNDATION DAY CELEBRATION.pdf'
    },
    {
      title:'GURU RAVIDAS JAYANTI',
      link:this.c343Url + '63_NSS_GURU RAVIDAS JAYANTI.pdf'
    },
    {
      title:'ANPURNA WEEK',
      link:this.c343Url + '64_ROTRACT_ANPURNA WEEK.pdf'
    },
    {
      title:'MANGO DONATION ',
      link:this.c343Url + '65_ROTRACT_MANGO DONATION.pdf'
    },
    {
      title:'SHAHADAT',
      link:this.c343Url + '66_ROTRACT_SHAHADAT.pdf'
    },
    {
      title:'AZADI DIVAS',
      link:this.c343Url + '67_ROTRACT_AZADI DIVAS.pdf'
    },
    {
      title:'RAKHI WITH MEN IN KHAKHI',
      link:this.c343Url + '68_ROTRACT_RAKHI WITH MEN IN KHAKHI.pdf'
    },
    {
      title:'SIKSHA ',
      link:this.c343Url + '69_ROTRACT_SIKSHA.pdf'
    },
    {
      title:'HEALTH CHECKUP CAMP',
      link:this.c343Url + '70_ROTRACT_HEALTH CHECKUP CAMP.pdf'
    },



    {
      title:'KHUSIYO KI DIWALI',
      link:this.c343Url + '71_ROTRACT_KHUSIYO KI DIWALI.pdf'
    },
    {
      title:'MASK DISTRIBUTION DRIVE',
      link:this.c343Url + '72_ROTRACT_MASK DISTRIBUTION DRIVE.pdf'
    },
    {
      title:'CHRISTMAS DAY',
      link:this.c343Url + '73_ROTRACT_CHRISTMAS DAY.pdf'
    },
    {
      title:'SMILE DAY',
      link:this.c343Url + '74_ROTRACT_SMILE DAY.pdf'
    },
    {
      title:'NEW YEAR CELEBRATION',
      link:this.c343Url + '75_ROTRACT_NEW YEAR CELEBRATION.pdf'
    },
    {
      title:'CLOTHES DONATION DRIVE',
      link:this.c343Url + '76_ROTRACT_CLOTHES DONATION DRIVE.pdf'
    },
    {
      title:'BONFIRE',
      link:this.c343Url + '77_ROTRACT_BONFIRE.pdf'
    },
    {
      title:'MAHADAN 7.0 FIRST CAMP',
      link:this.c343Url + '78_ROTRACT_MAHADAN 7.0 FIRST CAMP.pdf'
    },
    {
      title:'MAHADAN 7.0 SECOND CAMP',
      link:this.c343Url + '79_ROTRACT_MAHADAN 7.0 SECOND CAMP.pdf'
    },
    {
      title:'MASS MARRIAGE',
      link:this.c343Url + '80_ROTRACT_MASS MARRIAGE.pdf'
    },



    {
      title:'ROTTI DAY',
      link:this.c343Url + '81_ROTRACT_ROTTI DAY.pdf'
    },
    {
      title:'CHABEEL ',
      link:this.c343Url + '82_ROTRACT_CHABEEL.pdf'
    },
    {
      title:'COVID-19 VACCINATION CAMP ',
      link:this.c343Url + '83_YRC_COVID-19 VACCINATION CAMP.pdf'
    },
    {
      title:'COVID-19 VACCINATION CAMP ',
      link:this.c343Url + '84_YRC_COVID-19 VACCINATION CAMP.pdf'
    },
    {
      title:'POLIO DROP DUTY',
      link:this.c343Url + '85_YRC_POLIO DROP DUTY.pdf'
    },
    {
      title:'BLOOD,SUGAR,EYE,&HEALTH CHECKUP CAMP',
      link:this.c343Url + '86_YRC_BLOOD,SUGAR,EYE,_HEALTH CHECKUP CAMP.pdf'
    },
    {
      title:'GURUPARV CELEBRATION',
      link:this.c343Url + '87_YRC_GURUPARV CELEBRATION.pdf'
    },
    {
      title:'POSTER MAKING &SLOGAN WRITING COMPITITION ON THEME OF BLOOD DONATION',
      link:this.c343Url + '88_YRC_POSTER MAKING _SLOGAN WRITING COMPITITION ON THEME OF BLOOD DONATION.pdf'
    },
    {
      title:'BLOOD DONATION CAMP',
      link:this.c343Url + '89_YRC_BLOOD DONATION CAMP.pdf'
    },
    {
      title:'"ME AND MY BUDDY"(TO HELP OTHER)DURING LOCKDOWN ',
      link:this.c343Url + '90_YRC_ME AND MY BUDDY TO HELP OTHER DURING LOCKDOWN.pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
