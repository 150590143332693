<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria7/7-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>7.1.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('ragging')">Anti Ragging Policy</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('assess')">Gender Audit Assessment Report</a>
                    </li>
                    <li class="third">
                        <a (click)="changeRoute('committee')">Gender Audit committee</a>
                    </li>
                    <li class="fourth">
                        <a (click)="changeRoute('policy')">Gender Equity Policy</a>
                    </li>
                    <li class="fifth">
                        <a (click)="changeRoute('prevent')">Prevention and Sexual Harassment Policy</a>
                    </li>
                    <li class="sixth">
                        <a (click)="changeRoute('student')">Student Welfare Policy</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Gender Equity Sensitization Policies and Gender Equity audit report</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>