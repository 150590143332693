<div id="nss">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">NSS</h4>
                    <div class="card-body">
                        <h5>National Service Scheme (N.S.S.)</h5>
                        <hr class="rounded">
                        <p class="lead text-justify">TThe college started National Service Scheme in 1979.
                             The aim of the scheme is to develop all-round personality of the students to make them responsible and patriotic citizens.
                              It also inculcates the sense of dignity of manual labour. N.S.S. provides a forum to the young students for social service in their spare time.
                             Under this scheme two types of programmes are organized : regular activities and camping programme. 
                        </p>
                        <p class="lead text-justify text-dark">The college has three units of N.S.S. volunteers under the devoted programme officers</p>
                        <ol>
                            <li>
                                <p class="lead text-justify text-dark">Dr. Catherine Masih</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Dr. Sanjay Vij</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Dr. Vinay Chandel</p>
                            </li>
                        </ol>
                        <p class="lead text-justify">The outstanding volunteers of NSS get University Merit Certificate with a special weightage in the higher education admissions and jobs. 
                        </p>
                        <p class="lead text-justify"><i><strong>The volunteers who have represented college in the Republic Day Parade, New Delhi will be granted additional seats in PG courses if he/she fullfills the basic eligibility conditions.</strong></i></p>
                        <ul>
                            <li>
                                <p class="lead text-justify text-dark">Camps</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Tree Plantation</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Adoption of Village Gadholi (Yamunanagar)                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Cleanliness drive (Swachh Bharat )
                                </p>
                            </li>
                        </ul>
                        <div class="row">
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/nss/1.jpeg" alt="Nss" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/nss/2.jpeg" alt="Nss" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/nss/3.jpeg" alt="Nss" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3">
                                <img src="/assets/img/nss/4.jpeg" alt="Nss" class="img-fluid rounded">
                            </div>
                        </div>

                        <button class="btn btn-info mt-5 text-light"  data-toggle="collapse" href="#nss-form" >NSS Enrolment Form</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="collapse row mt-5"  id="nss-form">
            <div class="col-12 nssForm">
                <div class="card shadow-lg" >
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showAlert">
                        Something went Wrong. Please try again!
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showAlert=false">
                         <span aria-hidden="true">&times;</span>
                       </button>
                       </div>
                       <div class="alert alert-success alert-dismissible" role="alert" *ngIf="showSuccess">
                        Form Is successfully Submitted.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showSuccess=false">
                         <span aria-hidden="true">&times;</span>
                       </button>
                       </div>
                    <h4 class="card-title">NSS Enrolment Form</h4>
                    <p class="lead">Fields marked with an <strong style="color: var(--red);"> *</strong>  are required</p>

                    <form  #nssform="ngForm" (ngSubmit)="onSubmit(nssform)">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Student Name <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="name" 
                                        name="name" 
                                        aria-describedby="Student Name" 
                                        placeholder="Enter Student Name"
                                        ngModel
                                        required
                                        minlength="4">
                                        <small id="name" class="form-text text-muted">Full name should be given.</small>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Father Name <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="fatherName" 
                                        name="FatherName" 
                                        aria-describedby="Father Name" 
                                        placeholder="Enter Father Name"
                                        ngModel
                                        required
                                        minlength="4">
                                        <small id="name" class="form-text text-muted">Full name should be given.</small>
                                      </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Class <strong>*</strong></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="class" 
                                        name="class" 
                                        aria-describedby="Class" 
                                        placeholder=" Enter Class"
                                        ngModel
                                        required
                                        minlength="2">
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Roll No. <strong>*</strong></label>
                                        <input type="tel" 
                                        class="form-control" 
                                        id="roll" 
                                        name="roll" 
                                        aria-describedby="Roll No." 
                                        placeholder=" Enter Roll No."
                                        ngModel
                                        required
                                        minlength="1"
                                        maxlength="6">
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="gender">Gender <strong>*</strong></label>
                                        <select class="form-control" id="gender" name="gender" required ngModel>
                                          <option selected>Male</option>
                                          <option>Female</option>
                                          <option>Others</option>
                                        </select>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="category">Category <strong>*</strong></label>
                                        <select class="form-control" id="category" name="category" required ngModel>
                                          <option selected>Gen</option>
                                          <option>SC</option>
                                          <option>ST</option>
                                          <option>BC</option>
                                          <option>Minority</option>
                                          <option>Handicap</option>
                                          <option>Fatherless</option>
                                        </select>
                                      </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="email">Email <strong>*</strong></label>
                                        <input type="email" 
                                        class="form-control" 
                                        id="email" 
                                        name="email" 
                                        aria-describedby="Email" 
                                        placeholder="Enter Email"
                                        ngModel
                                        required
                                        email>
                                      </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="mobile">Contact No. <strong>*</strong></label>
                                        <input type="tel" 
                                        class="form-control" 
                                        id="mobile" 
                                        name="mobile" 
                                        aria-describedby="Contact No." 
                                        placeholder="Enter Contact No."
                                        ngModel
                                        required
                                        minlength="10"
                                        maxlength="10">
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="message">Participation In Other Activities <strong>*</strong></label>
                                        <textarea class="form-control" id="message"
                                        name="message" rows="3" required ngModel minlength="2"></textarea>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-primary mr-3" type="submit" [disabled]="!nssform.valid">Submit</button>
                            <button class="btn btn-primary" type="button" data-toggle="collapse" href="#nss-form">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>