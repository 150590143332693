<div id="subscribed">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Subscribed e-Resources</h4>
                    <div class="card-body">
                       <div class="text-center mb-3">
                        <img src="/assets/img/library/6.png" alt="Subscribed e-Resources" class="img-fluid">
                       </div>
                        <p class="lead text-justify font-weight-bold text-dark">TThe Project entitled “National Library and Information Services Infrastructure for Scholarly Content (N-LIST)”, provides access to e-resources to students, researchers and faculty from colleges and other beneficiary institutions through the server(s) installed at the INFLIBNET Centre. The authorized users from colleges can now access e-resources and download articles required by them directly from the publisher’s website once they are duly authenticated as authorized users through servers deployed at the INFLIBNET Centre.</p>
                       
                        <h5 class="title">E-RESOURCES@N-LIST</h5>
                        <hr class="pb-1 rounded mt-0">
                        <p class="lead text-justify">The Consortium subscribes to the following resources for the colleges. All electronic resources subscribed under N-LIST Programme are available from the publisher’s Web site.</p>
                        <p class="lead text-justify">For Remote Access the following Electronic Resources through the N-LIST program subscribed by the Library visit the Login Page by Clicking here⇒ <a href="https://iproxy.inflibnet.ac.in:2443/login" target="_blank">Member Login</a></p>
                        <p class="lead text-justify"><strong>NOTE: Login Details were provided to all the Faculty Members and Students they must check their official E-mail ID. Moreover, if any member forgot his/her password may click on the forget password option at the member login page, enter his/her registered email ID, they will get a new password immediately from INFLIBNET Centre itself.</strong></p>
                     
                         
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>