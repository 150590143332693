<div id="legal">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Legal Literacy Cell</h4>
                    <div class="card-body">
                        <p class="lead text-justify">Under the Legal Literacy Cell, the College organizes various Awareness Programs, workshops, talks, discussions (organized by DLSA) on legal issues related to : -  
                        </p>
                        <ul>
                            <li>
                                <p class="lead text-justify text-dark">Women rights</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Consumer Protection</p>
                            </li>
                            <li>
                                <p class="lead text-justify text-dark">Issues of rights & responsibilities of the citizens
                                </p>
                            </li>
                        </ul>
                        <div class="row text-center">
                            <div class="col-12">
                                <img src="/assets/img/litracy_cell/1.JPG" alt="Cell" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>