import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { ImportantService } from 'src/app/services/important/important.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
import { ShowImportantService } from 'src/app/services/showImportant/show-important.service';

@Component({
  selector: 'app-important',
  templateUrl: './important.component.html',
  styleUrls: ['./important.component.scss']
})
export class ImportantComponent implements OnInit,AfterViewChecked,OnDestroy {
  showImportant:boolean=true;
  sub:Subscription[]=[];
  importants:any[];
  routeName='';
  constructor(private showImportantService:ShowImportantService,
    private importantService:ImportantService,private router:Router,
    private showcaseService:ShowcaseService,
    private pathNameService:PathNameService,
    private cdRef:ChangeDetectorRef) { 
      showcaseService.hideShowcase();
    }

  ngOnInit(): void {
    this.importantService.getImportantList();
    this.sub[0]= this.importantService.importantList.snapshotChanges().subscribe(
      list=>{
      this.importants=  list.map(item=>{ return {
        $key:this.convertingUrl(item.payload.val().title),
        ... item.payload.val()
      }})
      this.importantService.setImportant(this.importants);
      this.splitUrl();
      }
      );
    this.sub[1]=this.showImportantService.importantChange.subscribe(val=>{
      this.showImportant=val;
    });
    this.sub[2]=this.pathNameService.urlChange.subscribe(res=>{
      if(!res){
        return this.routeName= 'Important Link'
      }
      this.routeName=res;
    });
  }
  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===3){
      this.hideImportant();
      this.router.navigate(['/important-links/'+url[2]]);

    }
    
  }
  hideImportant(){
    this.showImportantService.hide();
  }
  convertingUrl(id:string){
    for(let i=0;i<id.length;i++){
      id=id.replace(/[“’”'":;.,/!@#$%`]/g,'-');
    }
    if(id.includes('(')){
      for(let i=0;i<id.length;i++){
        id=id.replace('(','-');
      }
    }
    if(id.includes(')')){
      for(let i=0;i<id.length;i++){
        id=id.replace(')','-');
      }
    }
    if(id.includes(' ')){
      for(let i=0;i<id.length;i++){
        id=id.replace(' ','-');
      }
    }
    if(id.includes('.')){
      for(let i=0;i<id.length;i++){
        id=id.replace('.','-');
      }
    }
    if(id.includes('_')){
      for(let i=0;i<id.length;i++){
        id=id.replace('_','-');
      }
    }
    return id;
    
  }
  ngOnDestroy(): void {
    this.showcaseService.showingShowcase();
      this.sub.forEach(s=>{
        s.unsubscribe();
      })
  }
}
