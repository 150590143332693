<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr/criteria2/2-3/2-3-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>PPT</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-AMANDEEP-KAUR" (click)="checkRoute()">DR AMANDEEP KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-ANURADHA" (click)="checkRoute()">PROF ANURADHA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-ANURAG" (click)="checkRoute()">DR ANURAG</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-ASHOK-KHURANA" (click)="checkRoute()">DR ASHOK KHURANA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-BHAWNA-SHARMA" (click)="checkRoute()">PROF BHAWNA SHARMA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-BODHRAJ " (click)="checkRoute()">DR BODHRAJ </a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-DHEERAJ" (click)="checkRoute()">PROF DHEERAJ</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-AMRITA-PRITAM" (click)="checkRoute()">DR AMRITA PRITAM</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-BALJEET-SINGH" (click)="checkRoute()">DR BALJEET SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-GURVINDER-KAUR" (click)="checkRoute()">DR GURVINDER KAUR</a>
                    </li>



                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-IQBAL-SINGH" (click)="checkRoute()">DR IQBAL SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-JASWINDER-KAUR" (click)="checkRoute()">DR JASWINDER KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-JOSHPREET-SANDHU" (click)="checkRoute()">DR JOSHPREET SANDHU</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-KAMALPREET-KAUR" (click)="checkRoute()">DR KAMALPREET KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-MANPUNEET-KAUR" (click)="checkRoute()">DR MANPUNEET KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-MEENU-KAPUR" (click)="checkRoute()">DR MEENU KAPUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-M-P-AGGARWAL " (click)="checkRoute()">DR M P AGGARWAL </a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-RANJIT-SINGH" (click)="checkRoute()">DR RANJIT SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-RAVINDER-KAUR" (click)="checkRoute()">DR RAVINDER KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-SANGEETA " (click)="checkRoute()">DR SANGEETA </a>
                    </li>



                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-VARSHA-NIGAM" (click)="checkRoute()">DR VARSHA NIGAM</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-AMARJEET-SINGH" (click)="checkRoute()">DR AMARJEET SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-A-P-MISHRA" (click)="checkRoute()">DR A P MISHRA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-HARI-KIRAN-KAUR" (click)="checkRoute()">DR HARI KIRAN KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-SAHIB-SINGH" (click)="checkRoute()">DR SAHIB SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-ETENDER" (click)="checkRoute()">PROF ETENDER</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-JAGAT " (click)="checkRoute()">DR JAGAT </a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-JASMEEN-KAUR" (click)="checkRoute()">PROF JASMEEN KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-JASPREET-SINGH" (click)="checkRoute()">PROF JASPREET SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-KANIKA" (click)="checkRoute()">PROF KANIKA</a>
                    </li>



                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-KAVITA" (click)="checkRoute()">PROF KAVITA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-KIRANPAL-SINGH-VIRK" (click)="checkRoute()">DR KIRANPAL SINGH VIRK</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-MANDEEP-KAUR" (click)="checkRoute()">DR MANDEEP KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-N-P-SINGH" (click)="checkRoute()">DR N P SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-NEELAM-SINGH" (click)="checkRoute()">PROF NEELAM SINGH</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-NEENA-PURI" (click)="checkRoute()">DR NEENA PURI</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-NIDHI" (click)="checkRoute()">DR NIDHI</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-PARDEEP" (click)="checkRoute()">PROF PARDEEP</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-PARVEEN-KUMAR" (click)="checkRoute()">DR PARVEEN KUMAR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-POOJA-RANI" (click)="checkRoute()">PROF POOJA RANI</a>
                    </li>



                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-PRATIBHA-SHARMA" (click)="checkRoute()">DR PRATIBHA SHARMA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-R-S-VOHRA" (click)="checkRoute()">PROF R S VOHRA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-RAJU" (click)="checkRoute()">PROF RAJU</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-RAVNEET-KAUR" (click)="checkRoute()">PROF RAVNEET KAUR</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-RENU" (click)="checkRoute()">PROF RENU</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-SANTOSH-KURRA" (click)="checkRoute()">DR SANTOSH KURRA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-SEEMA" (click)="checkRoute()">PROF SEEMA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-SHALINI-BHARDWAJ" (click)="checkRoute()">PROF SHALINI BHARDWAJ</a>
                    </li>
                    <!-- <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-SHAWETA-SACHDEVA" (click)="checkRoute()">DR SHAWETA SACHDEVA</a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-SHILPI" (click)="checkRoute()">PROF SHILPI</a>
                    </li>



                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-SHIPRA" (click)="checkRoute()">PROF SHIPRA</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/PROF-SMRITI" (click)="checkRoute()">PROF SMRITI</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-TILAK-RAJ" (click)="checkRoute()">DR TILAK RAJ</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-3/2-3-1/ppt/DR-VINEY-CHANDEL" (click)="checkRoute()">DR VINEY CHANDEL</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>