import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-four-two-one',
  templateUrl: './two-four-two-one.component.html',
  styleUrls: ['./two-four-two-one.component.scss']
})
export class TwoFourTwoOneComponent implements OnInit {
  src='/assets/pdf/NAAC/Criterion 2/2.4/2.4.2/2.4.2.1/2.4.2.1_Ph.D degrees.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
