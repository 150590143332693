import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AboutUsComponent } from 'src/app/view/about-us/about-us.component';
import { CommitteeComponent } from 'src/app/view/about-us/committee/committee.component';
import { GoverningComponent } from 'src/app/view/about-us/governing/governing.component';
import { MessagesComponent } from 'src/app/view/about-us/messages/messages.component';
import { ProfileComponent } from 'src/app/view/about-us/profile/profile.component';
import { StaffComponent } from 'src/app/view/about-us/staff/staff.component';
import { AboutUsRoutingModule } from './about-us-routing.module';
import { OrganogramComponent } from 'src/app/view/about-us/organogram/organogram.component';
import { RtiComponent } from 'src/app/view/about-us/rti/rti.component';
import { PerspectiveComponent } from 'src/app/view/about-us/perspective/perspective.component';



@NgModule({
  declarations: [
    AboutUsComponent,
    ProfileComponent,
    GoverningComponent,
    MessagesComponent,
    StaffComponent,
    CommitteeComponent,
    OrganogramComponent,
    RtiComponent,
    PerspectiveComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AboutUsRoutingModule
  ]
})
export class AboutUsModule { }
