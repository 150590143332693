import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {

  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
