import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-extended-rpofile',
  templateUrl: './extended-rpofile.component.html',
  styleUrls: ['./extended-rpofile.component.scss']
})
export class ExtendedRpofileComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth = false;
  showSideMenu = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl() {
    let url = location.pathname.split('/');
    // console.log(url);
    if (url.length === 4) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }

  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}