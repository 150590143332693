<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria6/6-2"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>6.2.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('1')">Code of counduct</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('2')">Divyangjan Policy</a>
                    </li>
                    <li class="third">
                        <a (click)="changeRoute('3')">E-goverance Policy</a>
                    </li>
                    <li class="fourth">
                        <a (click)="changeRoute('4')">E-waste Management Policy</a>
                    </li>
                    <li class="fifth">
                        <a (click)="changeRoute('5')">Examination and Internal Assessment Policy</a>
                    </li>
                    <li class="six">
                        <a (click)="changeRoute('6')">Financial Suppot for teachers</a>
                    </li>
                    <li class="seven">
                        <a (click)="changeRoute('7')">Gender Equity Policy</a>
                    </li>
                    <li class="eight">
                        <a (click)="changeRoute('8')">Green Policy</a>
                    </li>
                    <li class="nine">
                        <a (click)="changeRoute('9')">Grievance redressal policy</a>
                    </li>
                    <li class="ten">
                        <a (click)="changeRoute('10')">Library Policy</a>
                    </li>
                    <li class="eleven">
                        <a (click)="changeRoute('11')">Research and Development Policy</a>
                    </li>
                    <li class="twelve">
                        <a (click)="changeRoute('12')">Scholarship and freeship Policy</a>
                    </li>
                    <li class="thirteen">
                        <a (click)="changeRoute('13')">Student Welfare Policy</a>
                    </li>
                    <li class="fourteen">
                        <a (click)="changeRoute('14')">Teaching Learning Policy</a>
                    </li>
                    <li class="fifteen">
                        <a (click)="changeRoute('15')">Waste Management Policy</a>
                    </li>
                    <li class="sixteen">
                        <a (click)="changeRoute('16')">Purchase Policy</a>
                    </li>
                    <li class="seventeen">
                        <a (click)="changeRoute('17')">Admission Policy</a>
                    </li>
                    <li class="eighteen">
                        <a (click)="changeRoute('18')">Prevention and Sexual Harassment Policy</a>
                    </li>
                    <li class="nineteen">
                        <a (click)="changeRoute('19')"> Anti Ragging Policy</a>
                    </li>
                    <li class="twenty">
                        <a (click)="changeRoute('20')">Placement Carrier Counseling Policy</a>
                    </li>
                    <li class="twentyone">
                        <a (click)="changeRoute('21')">Emargency First Aid Policy</a>
                    </li>
                    <li class="twentytwo">
                        <a (click)="changeRoute('22')">Sports Policy</a>
                    </li>
                    <li class="twentythree">
                        <a (click)="changeRoute('23')">Teacher welfare Policy</a>
                    </li>
                    <li class="twentyfour">
                        <a (click)="changeRoute('24')">Hostel Policy</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Policy Documents</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>