<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria6/6-3"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>6.3.3</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li >
                        <a   >6.3.3.1</a>
                        <!-- routerLink="/naac/ssr/criteria6/6-3/6-3-3/6-3-3-1" -->
                    </li>
                    <li >
                        <a  routerLink="/naac/ssr/criteria6/6-3/6-3-3/6-3-3-2" >6.3.3.2</a>
                    </li>
                    <li>
                        <a>Teaching Staff</a>
                    </li>
                    <li class="sub-menu first">
                        <a (click)="changeRoute('1')">2021-2022</a>
                    </li>
                    <li class="sub-menu second">
                        <a (click)="changeRoute('2')">2020-2021</a>
                    </li>
                    <li class="sub-menu third">
                        <a (click)="changeRoute('3')">2019-2020</a>
                    </li>
                    <li class="sub-menu fourth">
                        <a (click)="changeRoute('4')">2018-2019</a>
                    </li>
                    <li class="sub-menu fifth">
                        <a (click)="changeRoute('5')">2017-2018</a>
                    </li>
                    <li>
                        <a>Non Teaching</a>
                    </li>
                    <li class="sub-menu sixth">
                        <a  (click)="changeRoute('6')">2021-2022</a>
                    </li>
                    <li class="sub-menu seven">
                        <a (click)="changeRoute('7')">2020-2021</a>
                    </li>
                    <li class="sub-menu eight">
                        <a (click)="changeRoute('8')">2019-2020</a>
                    </li>
                    <li class="sub-menu nine">
                        <a (click)="changeRoute('9')">2018-2019</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Teaching & Non-Teaching Participation In FDP/MDP/STC</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>