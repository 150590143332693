<div id="social">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Social Outreach Activities 2021-2022</h4>
                    <div class="card-body">
                        <iframe src="https://drive.google.com/file/d/1n22a9ymTdOriAgHFnVQroz3Jwkbu7RfN/preview" width="100%" height="1100" allow="autoplay"></iframe>
                        <h4 class="card-title text-center mt-4">Social Outreach Activities 2019-2020</h4>
                       <ol>
                           <li>
                               <h5>10.03.2020 – Right to Equality Awareness Lecture</h5>
                               <hr>
                               <p class="lead text-justify"><strong>Place:</strong> Govt. School, Village Gadholi, Yamuna Nagar</p>
                               <p class="lead text-justify"><strong>Lecture by:</strong> Dr. Hemant Kumar Mishra</p>
                               <p class="lead text-justify"><strong>No. of participants:</strong> 60</p>
                           </li>
                           <li>
                            <h5>12.03.2020 – Gender Equality Awareness Programme</h5>
                            <hr>
                            <p class="lead text-justify"><strong>Place:</strong> Govt. School, Village Gadholi, Yamuna Nagar</p>
                            <p class="lead text-justify"><strong>Lecture by:</strong> Dr. Hemant Kumar Mishra</p>
                            <p class="lead text-justify"><strong>No. of participants:</strong> 50</p>
                           </li>
                           <li>
                            <h5>15.03.2020 – Tree Plantation Drive</h5>
                            <hr>
                            <p class="lead text-justify"><strong>Place:</strong> Village Gadholi, Yamuna Nagar</p>
                            <ul>
                                <li>
                                    <p class="lead text-justify">50 Tree plantations</p>
                                </li>
                                <li>
                                    <p class="lead text-justify">200 Saplings distribution</p>
                                </li>
                            </ul>
                           </li>
                           <li>
                            <h5>17.03.2020 – Corona Virus Awareness Lecture</h5>
                            <hr>
                            <p class="lead text-justify"><strong>Place:</strong> Govt. School, Village Gadholi, Yamuna Nagar</p>
                            <p class="lead text-justify"><strong>Lecture by:</strong> Dr. Amarjeet Singh</p>
                            <p class="lead text-justify"><strong>No. of participants:</strong> 100 Students</p>
                           </li>
                           <li>
                            <h5>19.03.2020 – Women Health and Hygiene Awareness Campaign</h5>
                            <hr>
                            <p class="lead text-justify"><strong>Place:</strong> Panchayat Hall, Village Gadholi, Yamuna Nagar</p>
                            <p class="lead text-justify"><strong>Lecture by:</strong> Dr. Anuradha</p>
                            <p class="lead text-justify"><strong>No. of participants:</strong> 55</p>
                           </li>
                           <li>
                            <h5>12.03. 2021 – National Youth Day</h5>
                            <hr>
                            <p class="lead text-justify"><strong>Place:</strong> FPAI, Yamuna Nagar Branch</p>
                            <p class="lead text-justify"><strong>Lecture by:</strong> </p>
                            <ul>
                                <li>
                                    <p class="lead text-justify">Dr. Anjana Taluja, Branch Manager</p>
                                </li>
                                <li>
                                    <p class="lead text-justify">Dr. Hemant Mishra, Associate Professor</p>
                                </li>
                            </ul>
                            <p class="lead text-justify"><strong>No. of participants:</strong> 15 Students</p>
                            <p class="lead text-justify"><strong>Beneficiaries:</strong> 50 Slum Dwellers</p>
                            <p class="lead text-justify"><strong>Services:</strong></p>
                            <ul>
                                <li>
                                    <p class="lead text-justify"> Health Check-up Camp</p>
                                </li>
                                <li>
                                    <p class="lead text-justify">Distribution of Winter Clothes to slum Dwellers</p>
                                </li>
                            </ul>
                           </li>
                       </ol>
                       <a href="https://drive.google.com/file/d/1cbvBP5biXbcVqe-94KokW0FPVBayglPY/view?usp=sharing" target="_blank" download>Click Here For View More!</a>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>