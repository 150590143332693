import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-it-twotwo-twothree',
  templateUrl: './it-twotwo-twothree.component.html',
  styleUrls: ['./it-twotwo-twothree.component.scss']
})
export class ItTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1WkyFs_7s7MSlEgkjfAfsekvZjbfKLuus/preview',
    'https://drive.google.com/file/d/1kH-ADM_hchs4iuBb_yoqnEXmXIJG1eCd/preview',
    "https://drive.google.com/file/d/1MhdLuPd35VngNaIO3YchlmWMKUbTZZ0z/preview",
    "https://drive.google.com/file/d/1N9i8wFrwDrnqXLzjbZd4kQws_Vxyto8m/preview",
    "https://drive.google.com/file/d/1bVmMY3YN6krIN9AxHYqpVHIKkSYzpFAv/preview",
    "https://drive.google.com/file/d/1e7dnHlMQqB46fuT65tni9dA9PzmLeLiT/preview",
    "https://drive.google.com/file/d/1eCFejFAyuTXA2nJsU0xInAFtJmzgiF4l/preview",
    "https://drive.google.com/file/d/17I91I5RkQkcXSszzP6F1WeEm9BU1M5xW/preview",
    "https://drive.google.com/file/d/1D_XPcJ-ijoLuG0vwxgzLK7ZlHoQHvDLd/preview",
    "https://drive.google.com/file/d/1V2blTVpwx4-jUl9JmKc5rXog5KuXnqK9/preview",
    "https://drive.google.com/file/d/1gdgW7_5nEURPgvmtfRHI3rjjm17R_Rb-/preview",
    "https://drive.google.com/file/d/1SFuOh987YP-Tn_PUHVDE__7K0cwnfz5Q/preview",
    "https://drive.google.com/file/d/1i5teVMz0PLtxDoxlc5KapujsLdLgHvyJ/preview",
    "https://drive.google.com/file/d/1Lb1WNfJqUH_Z28ULWyRVgqlhyc0vLQ4l/preview",
    "https://drive.google.com/file/d/1iVMz0Euf-rcs68aO8ftrdK2EYQLc5XeH/preview",
    "https://drive.google.com/file/d/1NOpWLANaIXAFTXJ0l5rWHfbF0Cyi6-s_/preview",
    "https://drive.google.com/file/d/1wlYnul6T5eb_tIsqCIlLwmn7stKtVbtW/preview",
    "https://drive.google.com/file/d/128abfT2Ah2erX5WGwZ4l15hVOmCNPE6S/preview"
  ];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.one').addClass('active');
      $('.two').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.two').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.three').addClass('active');
      $('.one').removeClass('active');
      $('.two').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.two').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.two').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.six').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.six').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.six').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.ten').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.ten').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.eighteen').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eightteen').addClass('active');
      $('.one').removeClass('active');
      $('.three').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.two').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.six').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.sixteen').removeClass('active');
      this.src = this.pdfSrc[17];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
