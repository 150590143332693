import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c331-seventeen',
  templateUrl: './c331-seventeen.component.html',
  styleUrls: ['./c331-seventeen.component.scss']
})
export class C331SeventeenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.1.1 Research/';

  result:{title:string,link:string}[]=[
    {
        title: 'History meaning and scope : Development of Historical Writings and Recent Trends',
        link: this.c3321Url + '1_History-meaning-and-scope_2017_18.pdf'
    },
    {
        title: 'Bharat Me Islam Ka Agman aur Bhartiya Samaj par Iske Parbhav : Ek Alochnatmak Adhyan',
        link: this.c3321Url + '2_Bharat-Me-Islam _2017_18.pdf'
    },
    {
        title: 'Shaikh Abul Fazal ka Boudhik - Sahtiyatik Yogdan',
        link: this.c3321Url + '3_Shaikh-Abul-Fazal-ka-Boudhik_2017_18.pdf'
    },
    {
        title: 'Cloning caharacteristics and its potential in pulp bio bleaching',
        link: this.c3321Url + '4_Cloning-caharacteristics_2017_18.pdf'
    },
    {
        title: 'Statistical optimization ofphysical and nutritional factor for laccase production by environmental isolate NS 1 ',
        link: this.c3321Url + '5_Statistical-optimization__2017_18.pdf'
    },
    {
        title: 'A Statistical approach for a chitinase production by bacillus sp and its antifungal protection',
        link: this.c3321Url + '6_A-Statistical-approach__2017_18.pdf'
    },
    {
        title: 'Yamuna Nagar Zile me Football aur Maidani khelkoodon ke khiladiyon ki gati aur sahansheelta ka ek tulnatamark adhyann.',
        link: this.c3321Url + '7_Yamuna-Nagar-Zile-me__2017_18.pdf'
    },
    {
        title: 'Coproduction of Protease and mannanase from B.nealsonii PN-11,in SSF and their combined application as Detergent additive',
        link: this.c3321Url + '8_Coproduction-of-Protease_2017_18.pdf'
    },
    {
        title: 'Demonetisation and Its effect on Indian Economy',
        link: this.c3321Url + '9_Demonetisation_2017_18.pdf'
    },
    {
        title: 'GST & Its impacts on Indian Economy',
        link: this.c3321Url + '10_GST_ Its-impacts_2017_18.pdf'
    },
    {
        title: 'Software Component Quality Models : A Survey',
        link: this.c3321Url + '11_Software-Component-Quality-Models_2017_18.pdf'
    },
    {
        title: 'Guru Nanak Bani- Vishesh Ank',
        link: this.c3321Url + '12_Guru-Nanak-Bani-Vishesh-Ank_2017_18.pdf'
    },
    {
        title: 'A reviw on bioactive constituents and pharmcological consequences of some medicinal plants',
        link: this.c3321Url + '13_A reviw-on-bioactive-constituents_2017_18.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
