<div id="community">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Faculty</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item"><a routerLink="/faculty/teaching-staff">Faculty</a></li>
              <li class="breadcrumb-item"><a >Community College</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Department of Community College</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div id="community-detail">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <h4 class="card-title text-center">Department of Community College</h4>
                                    <div class="card-body text-justify">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-4 mt-5" *ngFor="let staff of  community;let i=index">
                                                    <div class="our-team">
                                                        <div class="picture">
                                                          <img class="img-fluid" [src]="staff.img" [alt]="staff.name">
                                                        </div>
                                                        <div class="team-content">
                                                          <h3 class="name">{{staff.name}}</h3>
                                                          <h4 class="title">{{staff.degree}}</h4>
                                                        </div>
                                                        <h5>{{staff.position}}</h5>
                                                        <ul class="social">
                                                          <li><a href="#"  >{{staff.position}}</a></li>
                                                          
                                                        </ul>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>