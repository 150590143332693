import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c331-eighteen',
  templateUrl: './c331-eighteen.component.html',
  styleUrls: ['./c331-eighteen.component.scss']
})
export class C331EighteenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.1.1 Research/';

  result:{title:string,link:string}[]=[
    {
        title: 'Multipal ionization effect on La X-Ray production cross sections of Dy. Ho and Er at 320 KeV Proton energy',
        link: this.c3321Url + '14_Multipal-ionization-effect-on-La-X-Ray_2018-19.pdf'
    },
    {
        title: 'Decolourizing of Distillery spentwash using indegenously prepared cation exchanger from the agricultural waste(wheat straw)',
        link: this.c3321Url + '15_Decolourizing-of-Distillery-spentwash_2018-19.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
