<div id="guest">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Guest Lectures</h4>
                    <div class="card-body text-justify">
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/guest/1.jpg" alt="guest" class="img-fluid"></div>
                            <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/guest/2.jpg" alt="guest" class="img-fluid"></div>
                            <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/guest/3.jpg" alt="guest" class="img-fluid"></div>
                            <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/guest/4.jpg" alt="guest" class="img-fluid"></div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>