import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-second',
  templateUrl: './second.component.html',
  styleUrls: ['./second.component.scss']
})
export class SecondComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  showCard(id:number){
    if(id===1){
      $('#pdf1').collapse('show');
      $('#first-pdf').removeClass('first');
      $('#pdf2').collapse('hide');
      $('#pdf3').collapse('hide');
      $('#pdf4').collapse('hide');
     
    }
    if(id===2){
      $('#pdf1').collapse('hide');
      $('#first-pdf').removeClass('first');
      $('#pdf2').collapse('show');
      $('#pdf3').collapse('hide');
      $('#pdf4').collapse('hide');
    }
    if(id===3){
      $('#pdf1').collapse('hide');
      $('#first-pdf').removeClass('first');
      $('#pdf2').collapse('hide');
      $('#pdf3').collapse('show');
      $('#pdf4').collapse('hide');
    }
    if(id===4){
      $('#pdf4').collapse('hide');
      $('#first-pdf').removeClass('first');
      $('#pdf1').collapse('hide');
      $('#pdf2').collapse('hide');
      $('#pdf3').collapse('hide');
     
    }
  
  }

}
