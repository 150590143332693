import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
declare var $: any;
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  routeName = '';
  sub: Subscription; 
  fullWidth = false;
  showSideMenu = true;
  constructor(
    private showcaseService: ShowcaseService,
    private pathNameService: PathNameService
  ) {
    showcaseService.hideShowcase();

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.splitUrl();
    });
    this.sub = this.pathNameService.urlChange.subscribe(res => {
      this.routeName = res;
    });
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

  splitUrl() {
    let url = location.pathname.split('/');
    this.routeName = url[url.length - 1];
    if(url.length === 2 || (url.length === 3 && (url[2]=== 'ncc' || url[2]=== 'nss' || url[2]=== 'Women-cell' || url[2]=== 'cultural' || url[2]=== 'legal-literacy-cell' || url[2]=== 'youth-red-cross' || url[2]=== 'eco-club' || url[2]=== 'adventure-club' || url[2]=== 'placement-cell' || url[2]=== 'community-services'))){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else if(url.length === 3 && (url[2]=== 'sports' || url[2]=== 'gender' || url[2]=== 'VISMAD') ){
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }

  changeRoute(route: string) {
    this.routeName = route;
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
    this.sub.unsubscribe();
  }
}
