import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c33-nineteen',
  templateUrl: './c33-nineteen.component.html',
  styleUrls: ['./c33-nineteen.component.scss']
})
export class C33NineteenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.3.2.1 Books/';

  result:{title:string,link:string}[]=[
    {
        title: 'Dr.Harinder Singh Kang, Dr.Amrita Pritam',
        link: this.c3321Url + '7_2019_Dr.Harinder-Singh-Kang.pdf'
    },
    {
        title: 'Dr. Alok Goel',
        link: this.c3321Url + '8_2019_Dr.-Alok-Goel.pdf'
    },
    {
        title: 'Dr. Ashok Khurana',
        link: this.c3321Url + '9_2019_Dr.-Ashok-Khurana.pdf'
    },
    {
        title: 'Dr. Ashok Khurana',
        link: this.c3321Url + '10_2019_Dr.-Ashok-Khurana.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
