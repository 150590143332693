import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-one-one',
  templateUrl: './c1-one-one-one.component.html',
  styleUrls: ['./c1-one-one-one.component.scss']
})
export class C1OneOneOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
}
