import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-girls-room',
  templateUrl: './girls-room.component.html',
  styleUrls: ['./girls-room.component.scss']
})
export class GirlsRoomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
