<div id="english">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h4 class="card-title text-center">Department of English</h4>
                    <div class="card-body mx-auto text-justify">
                        <!-- <div class="row ">
                            <div class="content mt-5 col-12 col-xl-6" *ngFor="let staff of english;let i=index">
                                <div class="card person mx-auto">
                                    <div class="firstinfo">
                                        <img [src]="staff.img" [alt]="staff.name">
                                        <div class="profileinfo">
                                            <h1 >{{staff.name}}</h1>
                                            <h3>{{staff.position}}</h3>
                                            <p class="bio">{{staff.degree}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         -->

                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12 col-lg-6 mt-5" *ngFor="let staff of english;let i=index">
                                    <div class="our-team">
                                        <div class="picture">
                                          <img class="img-fluid" [src]="staff.img" [alt]="staff.name">
                                        </div>
                                        <div class="team-content">
                                          <h3 class="name">{{staff.name}}</h3>
                                          <h4 class="title">{{staff.degree}}</h4>
                                        </div>
                                        <h5>{{staff.position}}</h5>
                                        <ul class="social">
                                          <li><a href="#"  >{{staff.position}}</a></li>
                                          
                                        </ul>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row details" >
                            <div class="col-sm-6 col-md-6 mt-3" *ngFor="let staff of english;let i=index">
                                <div class="card ">
                                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                                    <div class="card-body">
                                        <h5 class="text-center">{{staff.name}}</h5>
                                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                                        <p class="text-center"><img src="/assets/svg/book/grad.svg" alt="profile" class="img-fluid rounded-circle">{{staff.degree}}</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>