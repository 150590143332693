


<div class="container-fluid pdf">
    <div class="row">
        <div class="col-12">
            <div class="card card-body">
                <h5 class="card-title text-center">ACADEMIC CALENDER KUK</h5>
                <div id="detail" class="text-center">
                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</div>