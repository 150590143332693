<div class="course-list shadow-lg course-detail">
  <div class="box-default3">
    <h4>M.A. (Two Year Duration)</h4>
    <hr class="pb-1 rounded">
    <div class="row">
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/8.svg">
                  <h3>Social Work</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.A. Social Work</p>
                      <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/49.svg">
                  <h3>Punjabi</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.A. Punjabi</p>
                      <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/50.svg">
                  <h3>Public Administration</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>M.A. Public Administration</p>
                      <a class="btn btn-block" (click)="setModal(2)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>

<div class="course-list course-detail mt-5 shadow-lg">
  <div class="box-default3 w-100">
    <h4>B.A. (Three Year Duration)</h4>
    <hr class="pb-1 rounded">
    <div class="row">
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/51.svg">
                  <h3>B.A.</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p style="font-size: 14px;">Bachelor of Arts</p>
                      <a class="btn btn-block mb-4"   data-toggle="modal" data-target="#BAModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <!-- <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/52.svg">
                  <h3>Political Science</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.A. Political Science</p>
                      <a class="btn btn-block" (click)="setModal('B.A. Political Science')" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-12 col-xl-6 mb-3">
        <div class="card">
          <div class="face face1 text-center mx-auto">
              <div class="content">
                  <img src="/assets/svg/courses/53.svg">
                  <h3>History</h3>
              </div>
          </div>
          <div class="face face2 mx-auto">
              <div class="content">
                  <p>B.A. History</p>
                      <a class="btn btn-block" (click)="setModal('B.A. History')" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
              </div>
          </div>
      </div>
      </div> -->
    </div>
  </div>
</div>


<div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="courseModelTitle"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="title"></h5>
        <p class="creteria"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="BAModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="courseModelTitle">B.A. (Three Year Duration)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p><b>Major Subjects: </b> English, Hindi, Punjabi, Sanskrit, Political Science, Public Administration, History,
          Geography, Social Work, Physical Education, Music (V), Economics, Statistics
          </p>  
        <p><b>Minor Subjects: </b> Economics, Geography, History, Political Science, Public Administration, Social
          Work.</p>  
        <h5>10+2 in any stream with five subjects including English shall be eligible to join B.A. course. </h5>
          <p>The student is required to select 3 major courses one minor course and one
            Multidisciplinary courses from the list given below
            This can be from any other programmes offered by the Institute than the
            For one multidisciplinary course choose any one course other than the opted for the major, minor
            and the courses studied at 10+1, 10+2 level.
            </p>
          <!-- <ol style="list-style: lower-alpha;">
            <li>
              <p class="font-weight-bold">Compulsory Subjects :Every student will have to study the following subjects:-</p>
              <ol>
                <li>English</li>
                <li>Hindi/Punjabi/Sanskrit (any one).</li>
                <li>Environmental Studies (Qualifying paper)</li>
                <li>Computer Awareness (Qualifying Paper for non computer subject students)</li>
              </ol>
            </li>
            <li>
              <p class="font-weight-bold">Optional Subjects : A candidate may se lect any two optional subjects from the following groups selecting only one subject from a given group</p>
              <ul style="list-style: none;">
                <li><strong>Group-I (Elective) :</strong>Hindi, Sanskrit, Punjabi, Geography</li>
                <li><strong>Group-II :</strong>Mathematics, Social Work, Music (Vocal), Health and Physical Education,</li>
                <li><strong>Group-III :</strong>Political Science, Pub. Admn.</li>
                <li><strong>Group-IV :</strong>Economics</li>
                <li><strong>Group-V :</strong>History</li>
              </ul>
            </li>
          </ol>
          <h3>Notes</h3>
          <ol>
            <li>Language offered as compulsory subject as elective subject. shall not be offered</li>
            <li>The foreign and non-Hindi speaking students will offer Additional English in lieu of Hindi/Sanskrit/Punjabi (Compulsory)</li>
            <li>Mathematics will be offered with only Economics.</li>
            <li>Political Science will not be offered with Public Admn</li>
          </ol>

          <h6>Programme and course outcomes for all programme offered by the institution are stated and displayed on website and communicated to teachers and students.</h6>
      <h6>Our institute offers Science, Commerce, Arts and Sport Science Streams which further include various interactive courses like Business Administration, Computer Science, Computer Application, Physics, Chemistry, Botany, Zoology, Biotechnology, Microbiology, Mathematics, History, English, Hindi, Sanskrit, Punjabi, Social Work, Political Science, Public administration, Economics, and Physical Education etc.</h6>
      <h6>Here is a brief discussion about the programme, programme specific and course outcomes of the various streams offered by our institution.</h6>


      <h2>HISTORY </h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Understanding grounds of our religion, custom institutions, administration and so on.</p></li>
        <li><p>Understand the present exiling social political, religious and economic conditions of people.</p></li>
        <li><p>Study & Understanding of historical events.</p></li>
        <li><p>Rise of Modern world and Ancient India.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Students will demonstrate knowledge of the chronology, narrative, major events, personalities and turning points of ancient, medieval and modern history.</p></li>
        <li><p>Students will be able to understand major historical developments based on contextualized analysis of interrelated political, social, economic, cultural and intellectual processes.</p></li>
        <li><p>Students will be able to accurately extract evidence from secondary sources by analyzing and evaluating them in relation to their cultural and historical context and use that evidence to build and support an argument.</p></li>
        <li><p>Students will be able to evaluate historical sources by analyzing them in relation to the evidence that supports them, their theoretical frameworks and other secondary historical literature.</p></li>
        <li><p>Students will learn to understand the world contextually, that is, to interpret human experiences and the meanings people have given them in relationship to the place and time in which they occurred.</p></li>
        <li><p>Students will learn to explain how and why important events happen and change occurs over time.</p></li>
        <li><p>Students will learn the general course of human history in multiple areas of the world.</p></li>
      </ul>

      <h2>POLITICAL SCIENCE</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Clear knowledge about the issues of international and domestic politics and public policy.</p></li>
        <li><p>Analyze political and policy problems.</p></li>
        <li><p>Analyze the working of Indian Politics which include union & states and working of union executives.</p></li>
        <li><p>Social politics by Great Indian Leader.</p></li>
        <li><p>Study of International relations.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>The students would be able to understand fundamental concepts in the discipline of Political Science.</p></li>
        <li><p>Understanding of how political institutions, processes, laws, and ideas combine to influence policy and political outcomes.</p></li>
        <li><p>Understand and explain political theories and political systems in different parts of world.</p></li>
        <li><p>The students would be able to understand the political system of India including the structure and relationship between different branches of government.</p></li>
        <li><p>The students would be able to Understand National and International political matters.</p></li>
      </ul>

      <h2>PUBLIC ADMINISTRATIVE</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Demonstrative critical thinking, research and communication skills as applied to the public and private sectors.</p></li>
        <li><p>Explain the cross-cultural context of public and private institutions operating in a global environment.</p></li>
        <li><p>Manage diversity issues unities and organizational framework.</p></li>
        <li><p>Detailed study about rural-urban administration, issues in public administration and basic element of public administration.</p></li>
        <li><p>Student also comes to know about the central, state and district administration.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Helps to train undergraduate with the necessary social, cultural historical, economical knowledge of the working of society.</p></li>
        <li><p>Helps the students to rule good knowledge and clarity of concepts related to the subjects.</p></li>
        <li><p>Students have a wider mental horizon; acquires self-confidence in vocabulary and writing skills appropriate to the subjects.</p></li>
        <li><p>Develop the ability to apply the knowledge to solve problems in the relevant field and also develop the critical and analytical sense.</p></li>
      </ul>

      

      <h2>PUNJABI</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Students will understand the history of Punjab from the foundation of first Sikh empire to the formation of new Punjab.</p></li>
        <li><p>Helps to understand the official technology letters and grammar to understand the language in better way.</p></li>
        <li><p>Come to know about various novels and poetry books.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Helps to train undergraduate with the necessary Social Punjabi Culture, and historical knowledge of the working of society.</p></li>
        <li><p>It helps the students to rule good knowledge and clarity of concepts related to the subjects.</p></li>
        <li><p>Develop the ability to apply the knowledge to solve problems in the relevant field and also develop the critical and analytical sense.</p></li>
      </ul>

      <h2>ECONOMICS</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Provide students with the opportunity to focus on applied and policy issues in economics.</p></li>
        <li><p>Understand the theories and strategies of growth and development of the nation.</p></li>
        <li><p>Detailed study of sectorial aspects of Indian economy which include agriculture, Industry and Trade.</p></li>
        <li><p>Knowledge about Demographic aspects and development and planning with reference to India.</p></li>
        <li><p>Learning about important subject opted for different competitive exams i.e. Indian Economic Services (IES), Indian Administrative Services (IAS), Banking, National Sample Survey, and Ministry of Foreign Affairs etc.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Economics leads to careers in business, law, education, banking, insurance, journalism, public policy, environmental science, international affairs and in research and consultancy.</p></li>
        <li><p>Students will develop the ability to apply theoretical knowledge of Micro and Macro Economics to explain the behavior of individuals, businesses and industries in market-based systems and analyze the challenges of developing economies.</p></li>
        <li><p>The study will help in enhancing numerical and computing ability.</p></li>
        <li><p>It will also enhance the ability to analyze economic behavior in practice.</p></li>
        <li><p>Students will be able to effectively communicate economic ideas to solve the various economic problems.</p></li>
        <li><p>Students will acquire problem solving skills and develop a logical way of dealing with various economic issues.</p></li>
        <li><p>Students will be able to explain the role of the government in the economy, including taxation, expenditure and production.</p></li>
        <li><p>Students will also be able to analyze the impact of fiscal and monetary policy in the economy.</p></li>
      </ul>

      <h2>ENGLISH</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Students learn to write with clearly at all levels.</p></li>
        <li><p>Students will be able to understand, evaluate and synthesize information from a variety of written and electronic sources.</p></li>
        <li><p>Students can identify key ideas and learns in a spoken or written test.</p></li>
        <li><p>Knowledge about vocabulary grammar and writing skills.</p></li>
        <li><p>Learn about the various novels and poetry looks.</p></li>
        <li><p>Students will be able to understand the process of communicating and interpreting human experiences through literary representation using historical contexts and disciplinary methodologies.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Students will gain awareness about diversified and best literary traditions of the world. All the great texts that a learner studies will expand his/her range of experience and in turn will broaden their perspective on life.</p></li>
        <li><p>Acquisition of values is needed for self-development and social transformation which the study of Literature will instill in students and help them emerge as better human beings.</p></li>
        <li><p>The study of Literature will enhance their reading, writing, speaking, listening- skills, ability to instruct and convey information accurately in a variety of formats.</p></li>
        <li><p>Students will become potentially better with their knowledge on writing skills and finesse; there will be possibilities of them emerging as perspective writers, editors, content developers and teachers.</p></li>
        <li><p>Students need to be familiar with representative literary and cultural texts within a significant number of historical, geographical, and cultural contexts.</p></li>
        <li><p>Students will be able to apply critical and theoretical approaches to the reading and analysis of literary and cultural texts in multiple genres.</p></li>
        <li><p>Students will be able to identify, analyze, interpret and describe the critical ideas, values, and themes that appear in literary and cultural texts and understand the way these ideas, values, and themes inform and shape culture and society, both now and in the past.</p></li>
        <li><p>Students will gain the talent to write analytically in a variety of formats, including essays, research papers, reflective writing, and critical reviews of secondary sources.</p></li>
      </ul>

      <h2>HINDI</h2>
      <h3>Programme Outcome</h3>
      <ul>
        <li><p>Able to evaluate the concept of Hinduism from past to present and making the society closer through literature.</p></li>
        <li><p>Elaborating methods of Hindi Literature.</p></li>
        <li><p>Develop reading, writing, speaking and listening skills of Hindi.</p></li>
        <li><p>Understand the various aspects of Hindi literature vocabulary.</p></li>
      </ul>
      <h3>Course Outcome (CO)</h3>
      <ul>
        <li><p>Understand the interrelation between literature and society and analyze the role played by Hindi Literature.</p></li>
        <li><p>Understand and explain the nature of Hindi language and literature.</p></li>
        <li><p>Understand the philosophy of life and get inspired by the vision of eminent writers.</p></li>
        <li><p>Enhance critical skills of literary writing and develop literary research attitude.</p></li>
        <li><p>Enhance skills of literary criticism.</p></li>
        <li><p>Develop the art of illustrating literary forms like one-act-play and short story.</p></li>
        <li><p>Understand the local and regional culture through the study of various dialects.</p></li>
      </ul> -->
      </div>
      
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>