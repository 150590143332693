<div id="seminar-hall">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Seminar Hall</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The college has a well furnished adequately spacious and magnificent Seminar Hall with a sitting capacity of 100 students at a time.
                             The Hall is well equipped with all facilities like a projector, audio-visual, aids and amplifiers etc.
                              The college keeps on inviting renowned academicians and intellectuals from different spheres to widen the horizon of the knowledge of the students and the staff as well.
                             Competitive programmes in difference academic spheres are also organized to give an appropriate exposure to the talents and potentialities of the students for their overall development.
                        </p>
                        <div id="seminar-hallCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#seminar-hallCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="1"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="2"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="3"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="4"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="5"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="6"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="7"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="8"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="9"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="10"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="11"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="12"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="13"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="14"></li>
                              <li data-target="#seminar-hallCarousel" data-slide-to="15"></li>
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/seminar-hall/1.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/2.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item ">
                                <img src="/assets/img/seminar-hall/3.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/4.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/5.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/6.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/7.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/8.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/9.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/10.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/11.jpg" alt="seminar-hall" class="img-fluid rounded">
                              </div>

                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/12.jpeg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/13.jpeg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/14.jpeg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/15.jpeg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/seminar-hall/16.jpeg" alt="seminar-hall" class="img-fluid rounded">
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#seminar-hallCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#seminar-hallCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                         <!-- <div class="row text-center">
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/1.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/2.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/3.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/4.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/5.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/6.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/7.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/8.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/9.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/10.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 mt-3 ">
                                <img src="/assets/img/seminar-hall/11.jpg" alt="Seminar Hall" class="img-fluid rounded">
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>