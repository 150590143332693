import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-student-feedback',
  templateUrl: './second-student-feedback.component.html',
  styleUrls: ['./second-student-feedback.component.scss']
})
export class SecondStudentFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
