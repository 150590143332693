import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogItemComponent } from 'src/app/view/menu-detail/blog/blog-item/blog-item.component';
import { BlogComponent } from 'src/app/view/menu-detail/blog/blog.component';
import { RouterModule } from '@angular/router';
import { BlogRoutingModule } from './blog-routing.module';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    BlogComponent,
    BlogItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BlogRoutingModule,
    SharedModule
  ]
})
export class BlogModule { }
