import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-one-two-two',
  templateUrl: './ct-one-two-two.component.html',
  styleUrls: ['./ct-one-two-two.component.scss']
})
export class CtOneTwoTwoComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  // showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 5 || (url.length===6 &&(url[5]==='1-2-2-3' || url[5]==='1-2-2-2'))){
      this.showSideMenu=true;
      this.fullWidth=false;
      // this.showHeading = true;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
