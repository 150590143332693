<div id="recording">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Lecture Recording Studio</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Endeavouring to provide ‘the best’ to the students, the college has initiated video lecturing on various topics.
                             The students can watch, video lectures by renounced academician at their own convenience.
                              As video in particular is often more attractive as a means to capture lecture content and present direct instruction it is often the most visible and the most resource intensive.
                             It is easy then to assume that it will be the most impactful.
                        </p>
                        <p class="lead text-justify">
                            The college provides for a focused discussion guided by the instructor which acts as  an archived resource that students can access anywhere and anytime from first exposure to review and remediation.
                        </p>
                         
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-12 mt-3">
                                <img src="/assets/img/recording/1.jpeg" alt="recording" class="img-fluid rounded">
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>