<div id="heading">
    <h1>Administrative Staff</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">About Us</a></li>
          <li class="breadcrumb-item active" aria-current="page">Administrative Staff</li>
        </ol>
      </nav>
</div>

<div id="staff">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Administrative Staff</h2>
                <hr>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of adStaff;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " src="/assets/img/staff/2.jpeg" alt="Jaswinder Kau">
                    <div class="card-body">
                        <h5 class="text-center">Ms. Jaswinder Kaur</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">Steno</p>
                    </div>
                </div>
            </div> -->
            
        </div>
        
        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Girls' Hostel</h2>
                <hr style="width: 215px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of girlHostel;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">College Library</h2>
                <hr style="width: 230px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of library;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Techo-Academic Supporting Staff</h2>
                <hr style="width: 215px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of supportingStaff;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Laboratory Attendents/Groundmen</h2>
                <hr style="width: 215px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of groundmen;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Security Staff</h2>
                <hr style="width: 215px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of security;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h2 class="font-weight-bold" style="font-size: 32px;">Supporting Staff</h2>
                <hr style="width: 215px;">
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-6 col-md-4 col-lg-3 mt-3" *ngFor="let staff of supportStaff;let i=index">
                <div class="card ">
                    <img class="card-img-top rounded-circle w-50 h-50 " [src]="staff.img" [alt]="staff.name">
                    <div class="card-body">
                        <h5 class="text-center">{{staff.name}}</h5>
                        <p class="text-center"><img src="/assets/svg/staff/1.svg" alt="profile" class="img-fluid rounded-circle">{{staff.position}}</p>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>