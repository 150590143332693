import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstComponent } from 'src/app/view/wp-content/first/first.component';
import { SecondComponent } from 'src/app/view/wp-content/second/second.component';
import { WpContentComponent } from 'src/app/view/wp-content/wp-content.component';
import { RouterModule } from '@angular/router';
import { WpContentRoutingModule } from './wp-content-routing.module';
import { ThirdComponent } from 'src/app/view/wp-content/third/third.component';
import { FourthComponent } from 'src/app/view/wp-content/fourth/fourth.component';
import { FifthComponent } from 'src/app/view/wp-content/fifth/fifth.component';



@NgModule({
  declarations: [
    WpContentComponent,
    FirstComponent,
    SecondComponent,
    ThirdComponent,
    FourthComponent,
    FifthComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WpContentRoutingModule
  ]
})
export class WpContentModule { }
