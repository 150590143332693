<div id="scholarship" data-scroll>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <h2 class="font-weight-bold" data-scroll>
                    Scholarships
                </h2>
                <p data-scroll> <span>6</span>5 Lacs Awarded to Students by Jamna Auto Industries, Govt. of  Haryana and Kurukshetra University to Enhance the Talents of Meritorious Students, Outstanding Sportspersons, Economically Weaker Students and Minorities Students.</p>
            </div>
            <div class="col-12 col-md-6 col-lg-8">
                <div class="row pt-0 pl-lg-5" data-scroll>
                    <div class="col-12 col-md-6 text-center py-3 d-flex flex-column justify-content-center border-bt border-rt">
                        <h1>47+</h1>
                        <h6>Ph.D. Holder Faculties</h6>
                    </div>
                    <div class="col-12 col-md-6 text-center py-3 d-flex flex-column justify-content-center border-bt ">
                        <h1>16+ </h1>
                        <h6>Cadets Serving The Nation Through NCC</h6>
                    </div>
                    <div class="col-12 col-md-6 text-center py-3 d-flex flex-column justify-content-center  border-rt">
                        <h1 >20+</h1>
                        <h6>Celebrity/VIP Visit</h6>
                    </div>
                    <div class="col-12 col-md-6 text-center py-3 d-flex flex-column justify-content-center">
                        <h1>10</h1>
                        <h6>Add-On Courses</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="part-2">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3 border-2">
                <p class="px-1" data-scroll>Wi-Fi Enabled Campus with facility of LMS for Blended Mode of Learning for latest requirements.</p>
                <hr>
            </div>
            <div class="col-12 col-md-6 col-lg-3 border-3">
                <p class="px-1" data-scroll>Multipurpose Gymnasium Hall for Overall Development of Students.</p>
                <hr>
            </div>
            <div class="col-12 col-md-6 col-lg-3 border-2">
                <p class="px-1" data-scroll>Cultural and Talent Fests to Promote Extracurricular Activities.</p>
                <hr>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <p class="px-1" data-scroll>Well Placed Alumini Motivating Juniors to Shine in Their Fields.</p>
                <hr>
                </div>
        </div>
    </div>
</div>