import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';

@Component({
  selector: 'app-baljeet',
  templateUrl: './baljeet.component.html',
  styleUrls: ['./baljeet.component.scss']
})
export class BaljeetComponent implements OnInit {
  result:{name:string ,title:string, link:string} [] = [];
  constructor(private ssrService : SsrService) { }

  ngOnInit(): void {
    this.result = this.ssrService.getBaljeet();
  }
}
