import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-one-two-two-four',
  templateUrl: './ct-one-two-two-four.component.html',
  styleUrls: ['./ct-one-two-two-four.component.scss']
})
export class CtOneTwoTwoFourComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 6){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = true;
    }
    else if((url.length === 7 && (url[6]==='2021-22' || url[6]==='2020-21' || url[6]==='2019-20' || url[6]==='2018-19' || url[6]==='2017-18' ))){
      this.showSideMenu=true;
      this.fullWidth=false;
      this.showHeading = false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
      this.showHeading = false;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
