import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-iqac',
  templateUrl: './iqac.component.html',
  styleUrls: ['./iqac.component.scss']
})
export class IqacComponent implements OnInit,OnDestroy {

  constructor(private showcaseService:ShowcaseService) {
    showcaseService.hideShowcase();
   }

  ngOnInit(): void {
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
