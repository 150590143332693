import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-one-three-two-three',
  templateUrl: './ct-one-three-two-three.component.html',
  styleUrls: ['./ct-one-three-two-three.component.scss']
})
export class CtOneThreeTwoThreeComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'1.3.2_Certificates of MSW',
      link:this.c241Url + '1.3.2_Certificates of MSW.pdf'
    },
    {
      title:'1.3.2_Field Work Certificate_B.A. Social Work',
      link:this.c241Url + '1.3.2_Field Work Certificate_B.A. Social Work.pdf'
    },
    {
      title:'1.3.2_Project completion Certific_B.Sc. Sports Computer Awarness',
      link:this.c241Url + '1.3.2_Project completion Certific_B.Sc. Sports Computer Awarness.pdf'
    },
    {
      title:'1.3.2_Project completion Certificate EVS',
      link:this.c241Url + '1.3.2_Project completion Certificate EVS.pdf'
    },
    {
      title:'1.3.2_Project completion Certificate_M.SC._B.SC._BBA_B.COM.',
      link:this.c241Url + '1.3.2_Project completion Certificate_M.SC._B.SC._BBA_B.COM..pdf'
    },
    {
      title:'1.3.2_Project completion Certificate_M.Sc. Physics',
      link:this.c241Url + '1.3.2_Project completion Certificate_M.Sc. Physics.pdf'
    },
    {
      title:'1.3.2_SIP Certificates_B.Ed. _B.P.Ed.',
      link:this.c241Url + '1.3.2_SIP Certificates_B.Ed. _B.P.Ed..pdf'
    },
    {
      title:'1.3.2_Project completion Certificate MA public admn.',
      link:this.c241Url + '1.3.2_Project completion Certificate MA public admn..pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
