import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-lp-twoone-twotwo',
  templateUrl: './lp-twoone-twotwo.component.html',
  styleUrls: ['./lp-twoone-twotwo.component.scss']
})
export class LpTwooneTwotwoComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1PPuZeh5aN1aYvYE8x5t04S0DZCC_bwFQ/preview', 
    'https://drive.google.com/file/d/13i6kSaGD5Nfv-IQfDWLb3VI5_GYEnlu-/preview' ,
    'https://drive.google.com/file/d/1EJaKXWN7V8Zuer-LQi3sfOvz6iu6mNFj/preview', 
    'https://drive.google.com/file/d/1dj_XUv34LlQM28utu7T_2BOGbPFb7PRP/preview',
    'https://drive.google.com/file/d/1DRfIi85ZkArLtiNU_iRvqA-zkQn5g712/preview',
    'https://drive.google.com/file/d/1VVajvUt7C7wo79JtZbbXvbv5uxUxtMoP/preview',
    'https://drive.google.com/file/d/1ZPXUhN7VjhiIpZRo5bZRwJRuk_vulKRR/preview',
    'https://drive.google.com/file/d/17bq1U2jG9C4Yx3QwnJj-0P4CtgHBEzQq/preview',
    'https://drive.google.com/file/d/1uxJlTUsnj3CalDgBwLrPEkuSA2Yy0ZvA/preview',
    'https://drive.google.com/file/d/1lj7C6tCi74sqnbekg5PrzSLhLmz5FS65/preview',
    'https://drive.google.com/file/d/1jNQL3_p6u97px-CeAXdzM_Y2URq7Chsp/preview',
    'https://drive.google.com/file/d/1aj9x1nYYcEaydv40gSoHdwv4uyCS9eV6/preview',
    'https://drive.google.com/file/d/1wbKk3O_TxK3YHyHIOsoVAgwy-wlwP2sm/preview',
    'https://drive.google.com/file/d/1ZsfU61rhiOVkawwbyu6OE1XfUMynLtXz/preview',
    'https://drive.google.com/file/d/1oxyyb_9I8b9okwQOB2OgsYVF82MQnyeI/preview',
    'https://drive.google.com/file/d/1mKz9Fe3y_tte9lG6iv4xGZwXMgrVVCv4/preview',
    'https://drive.google.com/file/d/1y-jfvIG4idW8WaPaKMGqUKwKQ1BeLFIm/preview',
    'https://drive.google.com/file/d/1whpOKNWYibX7UVgt3Mrk8uLLiI0nEDTV/preview',
    'https://drive.google.com/file/d/1TeUqZYQZmbKHYRlJ4nbiYD6kU2bDqLDo/preview',
    'https://drive.google.com/file/d/1f1jzbvTXMSQzqzd7qpfiAA3STkPIe1Y0/preview',
    'https://drive.google.com/file/d/1Rxrrmpf29c7bmM3qpdWC3v6aFjTxK1Vc/preview',
    'https://drive.google.com/file/d/1rplHT5UK_SjVLWUAyoxntkvjurHtN5vM/preview',
    'https://drive.google.com/file/d/1S1sfBE8hEOoBhq4SoO-gLBFwkyNYrtiN/preview',
    'https://drive.google.com/file/d/16k2G4XZG9vGS9XGCjZQK9PkcvaOTYXXQ/preview',
    'https://drive.google.com/file/d/1f7WiAydTOKKir9awwy3uFThFtbDtoJzJ/preview',
    'https://drive.google.com/file/d/1F1CCj_emX1Ju711SsuH32_KGmFDQFFMI/preview',
    'https://drive.google.com/file/d/1TtinmRayw7FRSEbgrt2wWQdTZB_zrjM9/preview',
    'https://drive.google.com/file/d/1jLp2TFkQPHIwu-D9acCmOVFhllOBxUg0/preview',
    'https://drive.google.com/file/d/1kSsH4o-pB597EBHQ6HnbSYv_yNX7eFL6/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
     $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    if (year == '23') {
      $('.twentythree').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[22];
    }
    if (year == '24') {
      $('.twentyfour').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[23];
    }
    if (year == '25') {
      $('.twentyfive').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[24];
    }
    if (year == '26') {
      $('.twentysix').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[25];
    }
    if (year == '27') {
      $('.twentyseven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyeight').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[26];
    }
    if (year == '28') {
      $('.twentyeight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentynine').removeClass('active');
      this.src = this.pdfSrc[27];
    }

    if (year == '29') {
      $('.twentynine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      $('.twentyfive').removeClass('active');
      $('.twentysix').removeClass('active');
      $('.twentyseven').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyeight').removeClass('active');
      this.src = this.pdfSrc[28];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
