import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookComponent } from './view/book/book.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { MapComponent } from './view/footer/map/map.component';
import { HomeComponent } from './view/home/home.component';
import { AboutComponent } from './view/home/about/about.component';
import { MessageHeaderComponent } from './view/home/about/message-header/message-header.component';
import { HiringComponent } from './view/home/hiring/hiring.component';
import { AppRoutingModule } from './app-routing.module';
import {MatIconModule} from '@angular/material/icon';
import { AboutUsModule } from './modules/about-us/about-us.module';
import { MenuComponent } from './view/home/menu/menu.component';
import { CourseModule } from './modules/course/course.module';
import { ActivitiesModule } from './modules/activities/activities.module';
import { InfrastructureModule } from './modules/infrastructure/infrastructure.module';
import { FacultyModule } from './modules/faculty/faculty.module';
import { ResearchModule } from './modules/research/research.module';
import { IqacModule } from './modules/iqac/iqac.module';
import { MenuDetailModule } from './modules/menu-detail/menu-detail.module';
import { StudentCornerModule } from './modules/student-corner/student-corner.module';
import { LoaderModule } from './modules/loader/loader.module';
import { ProgramComponent } from './view/home/program/program.component';
import { AwardsComponent } from './view/home/awards/awards.component';
import { SmallMenuComponent } from './view/home/small-menu/small-menu.component';
import { CountComponent } from './view/home/count/count.component';
import { WhyComponent } from './view/home/why/why.component';
import { NotFoundModule } from './modules/not-found/not-found/not-found.module';
import {MatStepperModule} from '@angular/material/stepper';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TestimonialComponent } from './view/home/testimonial/testimonial.component';
import { UniqueComponent } from './view/home/unique/unique.component';
import { ScholarshipComponent } from './view/home/scholarship/scholarship.component';
import { BulletinComponent } from './view/home/bulletin/bulletin.component';
import { GalleryModule } from './modules/gallery/gallery.module';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { MediaComponent } from './view/home/media/media.component';
import { MediaModule } from './modules/media/media.module';
import { NewMenuComponent } from './view/home/new-menu/new-menu.component';
import { NewsComponent } from './view/home/news/news.component';
import { WpContentModule } from './modules/wp-content/wp-content.module';
import { PerformanceComponent } from './view/performance/performance.component';
import { BestPracticeComponent } from './view/best/best-practice/best-practice.component';
import { SecondBestPracticeComponent } from './view/best/second-best-practice/second-best-practice.component';
import { SecondPerformanceComponent } from './view/second-performance/second-performance.component';
import { CourseComponent } from './view/course/course.component';
import { BlogModule } from './modules/blog/blog.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LandingPageModule } from './modules/landing-page/landing-page.module';
import { SharedModule } from './modules/shared/shared.module';
import { ImportantModule } from './modules/important/important.module';
import { ApplyFormComponent } from './view/apply-form/apply-form.component';
import { EnquiryComponent } from './view/enquiry/enquiry.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NaacModule } from './modules/naac/naac.module';
import { RouterModule } from '@angular/router';
import { EduComponent } from './view/courses/education/edu/edu.component';
import { EContentModule } from './modules/e-content/e-content.module';
import { CalenderModule } from './modules/calender/calender.module';
import { DevTwotwoTwothreeComponent } from './view/iqac/development/dev-twotwo-twothree/dev-twotwo-twothree.component';

@NgModule({
  declarations: [
    AppComponent,
    BookComponent,
    HeaderComponent,
    FooterComponent,
    MapComponent,
    HomeComponent,
    AboutComponent,
    MessageHeaderComponent,
    HiringComponent,
    MenuComponent,
    ProgramComponent,
    AwardsComponent,
    SmallMenuComponent,
    CountComponent,
    WhyComponent,
    TestimonialComponent,
    UniqueComponent,
    ScholarshipComponent,
    BulletinComponent,
    MediaComponent,
    NewMenuComponent,
    NewsComponent,
    PerformanceComponent,
    BestPracticeComponent,
    SecondBestPracticeComponent,
    SecondPerformanceComponent,
    CourseComponent,
    ApplyFormComponent,
    EnquiryComponent,
    EduComponent,
    DevTwotwoTwothreeComponent,
    
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    AboutUsModule,
    CourseModule,
    ActivitiesModule,
    InfrastructureModule,
    FacultyModule,
    ResearchModule,
    IqacModule,
    MenuDetailModule,
    StudentCornerModule,
    LoaderModule,
    FormsModule,
    NotFoundModule,
    MatStepperModule,
    HttpClientModule,
    GalleryModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    MediaModule,
    WpContentModule,
    BlogModule,
    CarouselModule,
    LandingPageModule,
    SharedModule,
    ImportantModule,
    PdfViewerModule,
    NaacModule,
    EContentModule,
    CalenderModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports:[
  ]
})
export class AppModule { }
