<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <a routerLink="/naac/dvv/criteria5"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
      <nav id="secondary-navigation">
        <!-- Header -->
        <header class="header">
          <h5>5.1.1</h5>
        </header>
        <!-- /Header -->
        <ul>
          <li class="one">
            <a (click)="changeRoute('1')">Sanction letters of Scholarships</a>
          </li>
          <li class="two">
            <a (click)="changeRoute('2')">Scholarship Goverment Scheme</a>
          </li>
          <li class="three">
            <a (click)="changeRoute('3')">Scholarship Non_Government Scheme</a>
          </li>
          <li class="four">
            <a (click)="changeRoute('4')">Data template link_2</a>
          </li>
          <li class="five">
            <a (click)="changeRoute('5')">Policy Documents non Govt. scholarship JAI CSR and HEI_4</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-9">
      <div id="five-one-one">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card shadow-lg">
                <h4 class="card-title text-center" *ngIf="heading1">Sanction letters of Scholarships</h4>
                <h4 class="card-title text-center" *ngIf="heading2">Scholarship Goverment Scheme</h4>
                <h4 class="card-title text-center" *ngIf="heading3">Scholarship Non_Government Scheme</h4>
                <h4 class="card-title text-center" *ngIf="heading4">Data template link_2</h4>
                <h4 class="card-title text-center" *ngIf="heading5">Policy Documents non Govt. scholarship JAI CSR and HEI_4</h4>
                <div class="card-body text-justify">
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <!-- <th scope="col">S.NO.</th> -->
                        <th scope="col">TITLE</th>
                        <th scope="col">URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of result; let i = index;">
                        <!-- <th scope="row">{{i+41}}</th> -->
                        <td class="title">{{item.title}}</td>
                        <td class="link"><a href="{{item.link}}" target="_blank"><i class="fas fa-eye"></i></a> </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>