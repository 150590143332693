import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-five-one-four',
  templateUrl: './ct-five-one-four.component.html',
  styleUrls: ['./ct-five-one-four.component.scss']
})
export class CtFiveOneFourComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';
  link:{name:string,src:string}[]=[
    {
      name:'5.1.4_Revised attached documents for redressal of students  Grievances_1',
      src: '/assets/pdf/NAAC/DVV/5.1.4_Revised attached documents for redressal of students  Grievances_1.pdf'
    },
    {
      name:'5.1.4_Proofs of constitution of Internal_Grievances committee as per UGC _3',
      src: '/assets/pdf/NAAC/DVV/5.1.4_Proofs of constitution of Internal_Grievances committee as per UGC _3.pdf'
    },
    {
      name:'5.1.4_Committee reports for redressal of students grievances_4',
      src: '/assets/pdf/NAAC/DVV/5.1.4_Committee reports for redressal of students grievances_4.pdf'
    },
    {
      name:'5.1.4_Minutes of the meetings of students grievance committee_5',
      src: '/assets/pdf/NAAC/DVV/5.1.4_Minutes of the meetings of students grievance committee_5.pdf'
    }
  ];
  selectedValue:{name:string,src:string};
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.selectedValue=this.link[0];

    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      this.selectedValue=this.link[1];
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      this.selectedValue=this.link[2];
    }
    if (id === '3') {
      $(`four`).addClass('show');
      $(`three`).removeClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      this.selectedValue=this.link[3];
    }
  }
}
