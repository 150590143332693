<div id="competitive">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Competitive Exam Guidance Corner</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            To make the students contentious for various prestigious competitions, the college has a well stocked library with latest journals, magazines and E content where students prepare for Civil Service Exams, NET, GATE, SSC.
                        </p>
                        <p class="lead text-justify">
                            The reading room is  equipped with large number of competitive examination books such as current affairs, General knowledge, periodicals, Newspaper etc.
                             It has been setup of competitive exam books like UPSC, MPSC, and Banking examination.
                              Students who would like to prepare for the MPSC, UPSC, Banking and Staff Selection Commission and other examinations can register their name in the centre.
                             Students are always encouraged to use this facility.
                        </p>
                        <p class="lead text-justify">
                            Lecture series is arranged on various subjects and topics included in syllabi of the UPSC, MPSC, Banking and other competitive examination.
                             *Special Classes and physical training sessions are arranged for recruitment and placements.
                             Various subject experts from college also  get involved in the lecture sessions.
                        </p>
                        <p class="lead text-justify">
                            Competitive Examination Centre.
                             The aim of the setting up a Competitive Corner is to provide guidance to the students who come from rural background for competitive exams develop  good administrators, to create awareness among the students for various competitive exams and to develop among them a sense of social and civic responsibility.
                        </p>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>