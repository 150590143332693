import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sp-twoone-twotwo',
  templateUrl: './sp-twoone-twotwo.component.html',
  styleUrls: ['./sp-twoone-twotwo.component.scss']
})
export class SpTwooneTwotwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
