<!-- 
<div id="lesson">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <header class="header">
                        <h5>SPORTS</h5>
                    </header>
                    <ul>
                        <li [class.active]="activeRoute === '2023-24' || activeRoute === 'sports-events' || activeRoute === 'athletic-meet'" class="first">
                            <a (click)="toggleSubmenu('2023-24')">2023-24</a>
                            <ul *ngIf="showSubmenu2023">
                                <li [class.active]="activeRoute === 'sports-events'">
                                    <a (click)="changeRoute('2023-24', 'sports-events')">Sports events</a>
                                </li>
                                <li [class.active]="activeRoute === 'athletic-meet'">
                                    <a (click)="changeRoute('2023-24', 'athletic-meet')">Athletic meet</a>
                                </li>
                            </ul>
                        </li>
                        <li [class.active]="activeRoute === '2022-23'" class="second">
                            <a (click)="changeRoute('2022-23')">2022-23</a>
                        </li>
                        <li [class.active]="activeRoute === '2021-22'" class="third">
                            <a (click)="changeRoute('2021-22')">2021-22</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9" *ngIf="show23 && !show22 && !showSubmenu2023">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="!show23 && show22 && !showSubmenu2023">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="card shadow-lg">
                            <h4 class="card-title text-center">Sports</h4>
                            <div class="card-body">
                                <iframe src="https://drive.google.com/file/d/1MZ1z9UpqJNjCiiIQAl5YA03FSM-g7Btj/preview" width="100%" height="960" allow="autoplay"></iframe>
                                <h5 class="mt-4">Physical Education And Sports Sciences</h5>
                                <hr class="rounded">
                                <p class="lead text-justify">College is known for its might in sports. This is all due to excellent sporting environment and talented students who have bagged a large number of international, national, and state titles in India and abroad. The college possesses a magnificent Gymnasium Hall for all Indoor games of National Level. The students from all the classes take active part in games of their choice.</p>
                                <p class="lead text-justify">Our students of sports faculty have shown their talent at national and international level and they are equally matched by the students from other faculties. On the whole it can be said that Physical Training is compulsory for all able-bodied students. They are expected to participate in one or more of the following games and activities like Football, Hockey, Table Tennis, Cricket, Volleyball, Kabaddi, Athletics, Gymnastics, Archery, Shooting, Badminton and Yoga etc.</p>
                                <h5 class="mt-5">Glorious Achievements In Sports And Physical Education</h5>
                                <hr class="rounded">
                                <p class="lead text-justify">Guru Nanak Khalsa College has dominated sports activities in State/National/International since its inception.</p>
                                <p class="lead text-justify text-dark">We are Kurukshetra University overall sports champions consecutively 28 times in women section and 21 times in men section.</p>
                                <p class="lead text-justify">A large number of our sportspersons captain or represent Kurukshetra University in Inter-varsity championships and figure among the first three positions.</p>
                                <p class="lead text-justify"><strong class="text-dark">Khelo India Center</strong> in Weight Lifting allotted by Sports Authority of India for 30 Students(15 Male + 15 Females)</p>
                                <p class="lead text-justify">Nursery for nurturing budding talent in Yoga & Table Tennis</p>
                                <p class="lead font-weight-bold text-dark">Courses of study in Physical Education and Sports Sciences</p>
                                <ul>
                                    <li><p class="lead">B.Sc. in Physical Education, Health Education and Sports Sciences (Three years)</p></li>
                                    <li><p class="lead">B. P. Ed (Two Years)</p></li>
                                    <li><p class="lead">PG Diploma in Yoga Therapy (One Year)</p></li>
                                </ul>
                                <div class="row">
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/5.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/6.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/7.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/8.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/9.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/10.jpeg" alt="sports" class="img-fluid rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="showSubmenu2023 && show23 && !show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <h5 class="mt-4">Sport Events</h5>
                                    <div class="row">
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/1.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/2.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/3.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/4.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/5.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/6.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/7.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/8.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/9.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/10.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/11.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/15.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/16.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/17.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/18.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/19.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/12.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/13.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/14.jpg" alt="sports" class="img-fluid rounded"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="showSubmenu2023 && show23 && show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <h5 class="mt-4">Athletic meet</h5>
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="lesson">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <header class="header">
                        <h5>SPORTS</h5>
                    </header>
                    <ul>
                        <li [class.active]="activeYear === '2023-24'" class="first">
                            <a (click)="toggleSubmenu('2023-24')">2023-24</a>
                            <ul *ngIf="showSubmenu2023">
                                <li [class.active]="activeRoute === 'sports-events'">
                                    <a (click)="changeRoute('2023-24', 'sports-events')">Sports events</a>
                                </li>
                                <li [class.active]="activeRoute === 'athletic-meet'">
                                    <a (click)="changeRoute('2023-24', 'athletic-meet')">Athletic meet</a>
                                </li>
                                <li>
                                    <a (click)="goBack()">Go Back</a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="!showSubmenu2023" [class.active]="activeYear === '2022-23'" class="second">
                            <a (click)="changeRoute('2022-23')">2022-23</a>
                        </li>
                        <li *ngIf="!showSubmenu2023" [class.active]="activeYear === '2021-22'" class="third">
                            <a (click)="changeRoute('2021-22')">2021-22</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9" *ngIf="show23 && !show22 && !showSubmenu2023">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="!show23 && show22 && !showSubmenu2023">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="card shadow-lg">
                            <h4 class="card-title text-center">Sports</h4>
                            <div class="card-body">
                                <iframe src="https://drive.google.com/file/d/1MZ1z9UpqJNjCiiIQAl5YA03FSM-g7Btj/preview" width="100%" height="960" allow="autoplay"></iframe>
                                <h5 class="mt-4">Physical Education And Sports Sciences</h5>
                                <hr class="rounded">
                                <p class="lead text-justify">College is known for its might in sports. This is all due to excellent sporting environment and talented students who have bagged a large number of international, national, and state titles in India and abroad. The college possesses a magnificent Gymnasium Hall for all Indoor games of National Level. The students from all the classes take active part in games of their choice.</p>
                                <p class="lead text-justify">Our students of sports faculty have shown their talent at national and international level and they are equally matched by the students from other faculties. On the whole it can be said that Physical Training is compulsory for all able-bodied students. They are expected to participate in one or more of the following games and activities like Football, Hockey, Table Tennis, Cricket, Volleyball, Kabaddi, Athletics, Gymnastics, Archery, Shooting, Badminton and Yoga etc.</p>
                                <h5 class="mt-5">Glorious Achievements In Sports And Physical Education</h5>
                                <hr class="rounded">
                                <p class="lead text-justify">Guru Nanak Khalsa College has dominated sports activities in State/National/International since its inception.</p>
                                <p class="lead text-justify text-dark">We are Kurukshetra University overall sports champions consecutively 28 times in women section and 21 times in men section.</p>
                                <p class="lead text-justify">A large number of our sportspersons captain or represent Kurukshetra University in Inter-varsity championships and figure among the first three positions.</p>
                                <p class="lead text-justify"><strong class="text-dark">Khelo India Center</strong> in Weight Lifting allotted by Sports Authority of India for 30 Students(15 Male + 15 Females)</p>
                                <p class="lead text-justify">Nursery for nurturing budding talent in Yoga & Table Tennis</p>
                                <p class="lead font-weight-bold text-dark">Courses of study in Physical Education and Sports Sciences</p>
                                <ul>
                                    <li><p class="lead">B.Sc. in Physical Education, Health Education and Sports Sciences (Three years)</p></li>
                                    <li><p class="lead">B. P. Ed (Two Years)</p></li>
                                    <li><p class="lead">PG Diploma in Yoga Therapy (One Year)</p></li>
                                </ul>
                                <div class="row">
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/5.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/6.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/7.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/8.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/9.jpeg" alt="sports" class="img-fluid rounded"></div>
                                    <div class="col-sm-6 mt-3"><img src="/assets/img/sports/10.jpeg" alt="sports" class="img-fluid rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="showSubmenu2023 && show23 && !show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <h5 class="mt-4">Sport Events</h5>
                                    <div class="row">
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/1.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/2.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/3.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/4.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/5.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/6.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/7.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/8.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/9.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/10.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/11.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/15.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/16.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/17.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/18.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/19.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/12.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/13.jpg" alt="sports" class="img-fluid rounded"></div>
                                        <div class="col-sm-6 mt-3"><img src="/assets/img/sportactivity/14.jpg" alt="sports" class="img-fluid rounded"></div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="showSubmenu2023 && show23 && show22">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    <h5 class="mt-4">Athletic meet</h5>
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
