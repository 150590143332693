import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  commerce:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.commerce=this.facultyService.getCommerce();
  }

}
