import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';
declare var $:any;
@Component({
  selector: 'app-c3-three-five-one',
  templateUrl: './c3-three-five-one.component.html',
  styleUrls: ['./c3-three-five-one.component.scss']
})
export class C3ThreeFiveOneComponent implements OnInit {
  faculty:{title:string,link:string}[]=[];

  showBrief=true;
  isLoading = true;
  constructor( private ssrService: SsrService) { }

  ngOnInit(): void {
    this.changeRoute('1');
    this.faculty= this.ssrService.getC351research();
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      this.showBrief = true;
    }
  }

  hideLoader() {
    this.isLoading = false;
  }
}
