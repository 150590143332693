<div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.Ed. (Two Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/38.svg">
                    <h3>B.Ed.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Ed. </p>
                    <p>NCTE Approved</p>
                        <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    
    
   
    
  </div>
  <div class="container course-detail mt-5">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.P.Ed. (Two Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/39.svg">
                    <h3>B.P.Ed.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.P.Ed.</p>
                    <p>NCTE Approved</p>
                        <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
       
        
      </div>
    </div>
    
   
   
    
  </div>
  
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="creteria1"></h3>
          <p class="title"></p>
          <p class="font-weight-font detail1">Educational Qualification:</p>
          <p class="detail11"></p>
          <div class="bped">
            <ol class="sem1b">
              <li class="sem1b1"></li>
              <li  class="sem1b2"></li>
              <li class="sem1b3"></li>
              <li class="sem1b4"></li>
              <li class="sem1b5"></li>
              <li class="sem1b6"></li>
            </ol>
            <p class="font-weight-font detail1">Note :</p>
            <ol class="note">
              <li class="note1"></li>
              <li class="note1"></li>
            </ol>
            <ul class="detail2">
              <p class="font-weight-bold">Physical Efficiency Test:</p>
              <li class="detail21"></li>
            </ul>
            <div class="po-pso">
          
            </div>
          </div>
         
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>