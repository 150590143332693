<div id="nirf">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>IQAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">IQAC</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">NIRF</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>NIRF</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a  (click)="changeRoute('2018')">2018-2019</a>
                        </li>
                        <li class="second">
                            <a  (click)="changeRoute('2019')">2019-2020</a>
                        </li>
                        <li class="third">
                            <a  (click)="changeRoute('2020')">2020-2021</a>
                        </li>
                        <li class="four">
                            <a  (click)="changeRoute('2021')">2021-2022</a>
                        </li>
                        <li class="five">
                            <a  (click)="changeRoute('2022')">2022-2023</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    
                                    <h5 class="card-title text-center">NIRF</h5>
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                                    <!-- <div class="container-fluid d-flex justify-content-center align-items-center h-100" id="loader" *ngIf="isLoading">
                                        <app-loader></app-loader>
                                    </div>
                                    <div id="detail" class="text-center">
                                        <pdf-viewer [src]="src" 
                                        [render-text]="true"
                                        style="display: block;"
                                        id="list-view"
                                        
                                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>

                                </div> -->
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container mt-5" >
        <div class="card shadow-lg colorBlue">
            <div class="row">
                <div class="col-12 text-center mt-5">

                    <h2>NIRF</h2>
                </div>
            </div>
            <div class="container-fluid d-flex justify-content-center align-items-center h-100" id="loader" *ngIf="isLoading">
                <app-loader></app-loader>
            </div>
            <div id="detail" class="text-center">
                <pdf-viewer [src]="pdfSrc" 
                [render-text]="true"
                style="display: block;"
                id="list-view"
                
                (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
            </div>
        </div>
    </div> -->
</div>