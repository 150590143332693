<div id="scholarship">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Scholarship</h4>
                    <div class="card-body text-justify">
                       <div class="ranked">
                        <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Title</th>
                                <th scope="col">URL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td>SC Scholarship 2021-22</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/sc-scholarship-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/sc-scholarship-2021-22</a></td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>POST MATERIC BC.OBC.FATHERLESS.BPL SCHOLARSHIP</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/postmateric-bc-obc-fatherless-bpl-scholarship" target="_blank">https://gnkc.ac.in/menu-detail/naac/postmateric-bc-obc-fatherless-bpl-scholarship</a></td>
                              </tr>

                              <tr>
                                <th scope="row">3</th>
                                <td>Minority SCHOLARSHIP</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/minority-scholarship" target="_blank">https://gnkc.ac.in/menu-detail/naac/minority-scholarship</a></td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>JAI Scholarship (Players)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/jai-scholarship-players" target="_blank">https://gnkc.ac.in/menu-detail/naac/jai-scholarship-players</a></td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>JAI Scholarship (Academics)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/jai-scholarship-academics" target="_blank">https://gnkc.ac.in/menu-detail/naac/jai-scholarship-academics</a></td>
                              </tr>
                            </tbody>
                          </table>
                       </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>