<div id="cultural">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Cultural</h4>
                    <div class="card-body">
                        <p class="lead text-justify">As regards to the cultural activities, the college is a leading institution.
                             College provides platform to the students to show their talent by holding <strong class="text-dark"> Talent Hunt Program</strong> for various cultural activities like skit, mono-acting, play, mimicry, orchestra, bhangra, singing and dancing in folk and western styles in solo and group categories, debate, declamation, poetical recitation, quiz etc.
                        </p>
                        <p class="lead text-justify">Students participate in the youth festivals at zonal, inter zonal, intra-university and inter-university besides participating in various functions organized by the district administration, CSR Group of Jamna Auto Industries, other community welfare activities, Independence Day, Republic Day and the state level Gita Jayanti and Ratnawali festivals. 
                        </p>
                        <p class="lead text-justify">Our Students have constantly brought laurels to the college in these competitions.
                        </p>
                         <div class="row">
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/1.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/2.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/3.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/4.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/6.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/7.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/8.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/9.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/10.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/11.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/12.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/13.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/14.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/15.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/16.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3">
                                <img src="/assets/img/cultural/5.jpeg" alt="cultural" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>