import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ImportantItemComponent } from 'src/app/view/important/important-item/important-item.component';
import { ImportantComponent } from 'src/app/view/important/important.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    ImportantComponent,
    ImportantItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path:'',component:ImportantComponent,children:[
        {path:':id',component:ImportantItemComponent}
    ]}
    ]),
    SharedModule
  ]
})
export class ImportantModule { }
