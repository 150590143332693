<div id="news" class="my-5">
    <div class="container-fluid px-2 px-lg-5">
        <div class="row">
            <div class="col-12" data-scroll>
                <h2 class="font-weight-bold text-center">Events</h2>
                <hr class=" p-1 " style="width: 160px;">
                
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!isLoading">
            <owl-carousel-o [options]="customOptions">

                <ng-container *ngFor="let event of news; let i=index">
                  <ng-template carouselSlide >
                        <div class="documentation">
                            <div class="card shadow-lg mx-auto" >
                                <a href="{{event.imgUrl}}" data-lightbox="news">
                                 <img appLazyload class="card-img-top h-100" [src]="event.imgUrl" alt="Card image cap">
                                 </a>
                                 <div class="card-body">
                                   <a href="{{event.subCategory}}" target="_blank">{{event.caption}}  <i class="fas fa-arrow-right"></i></a>
                                   <!-- <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, totam perspiciatis inventore laborum cupiditate ratione nam atque repellendus ipsam soluta, explicabo hic eos ea numquam temporibus enim eligendi in ex!</p> -->
                                   <!-- <a  class="btn btn-warning w-100">Know More</a> -->
                                 </div>
                                 
                               </div>
                        </div>
                        
                       
                        
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
           
        </div>
        <div class="newsCarousel" >

        </div>
       
        
       
    </div>
</div>








