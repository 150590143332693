import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BulletinService } from '../bulletin/bulletin.service';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  constructor(private http:HttpClient,
    private bulletinService:BulletinService) { }

  fetchBulletin(){
    this.http.get<string>(this.url+'bulletin.json').subscribe(
      res=>{
        this.bulletinService.setBulletin(res);
      },
      error=>{
        console.log(error);
      }
    );
  }
}
