<div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria7/7-1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>7.1.4</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('1')">Code of Ethics and conduct for staff and students</a>
                    </li>
                    <li>
                        <a>Inculsive environment initiatives and sensitization  for constitution al obligations</a>
                    </li>
                    <li class="sub-menu second">
                        <a (click)="changeRoute('2')">Institutional initiatives for inclusive environment</a>
                    </li>
                    <li class="sub-menu third">
                        <a (click)="changeRoute('3')">Sensitization of students and employee to the constitutional obligation</a>
                    </li>
                    <li class="sub-menu fourth">
                        <a (click)="changeRoute('4')">Human values Rights Sensitization values based addon courses</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="showHeading">Code of Ethics and conduct for staff and students</h5>
                            <h5 class="card-title text-center" *ngIf="!showHeading">Inculsive environment initiatives and sensitization  for constitution al obligations</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>