import { Component, HostListener, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-two-one-one',
  templateUrl: './ct-two-one-one.component.html',
  styleUrls: ['./ct-two-one-one.component.scss']
})
export class CtTwoOneOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/2.1.1_Year wise Intake Sanction from affiliating University.pdf',
    '/assets/pdf/NAAC/DVV/2.1.1_Minority Certificate, Guidelines and list of UGC_1.pdf',
    '/assets/pdf/NAAC/DVV/2.1.1_Complete data template_2.pdf',
    '/assets/pdf/NAAC/DVV/2.1.1_Sealed and signed approved admission list from affiliating university_5.pdf',
    '/assets/pdf/NAAC/DVV/2.1.1_Sanctioned admission strength vs. students_ enrollment_6.pdf'];
  src: string;

  isLoading = true;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  heading4 = false;
  heading5 = false;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`three`).addClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`four`).addClass('show');
      $(`three`).removeClass('show');
      $(`two`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = true;
      this.heading5 = false;
      this.src = this.pdfSrc[3];
    }
    if (id === '5') {
      $(`one`).removeClass('show');
      $(`five`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`two`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = true;
      this.src = this.pdfSrc[4];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

 
}
