import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-sp-twotwo-twothree',
  templateUrl: './sp-twotwo-twothree.component.html',
  styleUrls: ['./sp-twotwo-twothree.component.scss']
})
export class SpTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1d2ALME7PXlIdyzF4e9fHir7rrskDYCGt/preview', 
    'https://drive.google.com/file/d/1E5N2xNasOmQaPjal6qYF2SfzX5rdQhYG/preview' ,
    'https://drive.google.com/file/d/1EGyy5y6ARTdi14qg5H1Znf86HyHvDCYo/preview', 
    'https://drive.google.com/file/d/1soLzsErC9ugIeqHLNAOCl8GbGWAric6B/preview',
    'https://drive.google.com/file/d/1MICeb2mYCBAJmG8X7ZrRHliaoT86HKfG/preview',
    'https://drive.google.com/file/d/1qp6fIKKwmgl3HIqKvcbeEVSu_1tXUAZQ/preview',
    'https://drive.google.com/file/d/1D9oBmp0kjs8HBmnUAp3ZVlmYw7zt5SZe/preview',
    'https://drive.google.com/file/d/1kYW3vJEyvvAZZ3pBlHC0lJhPn9Q6ccQD/preview',
    'https://drive.google.com/file/d/14W8Ndl19M80yaibCE9AlWa5-XcZ93N7e/preview',
    'https://drive.google.com/file/d/1cRVvBPF1k3g9A5OkJVPCuVrKy98Jzhtf/preview',
    'https://drive.google.com/file/d/1EZ32iYibkkecMyH8cb2c4x08h_QEMUR3/preview',
    'https://drive.google.com/file/d/1PQgyO5XbIXMCqyCHPCAbQ4MHglnbPEJ6/preview',
    'https://drive.google.com/file/d/1lkCg48st9SngMpEZ1xRqrbs95JoW9yvy/preview',
    'https://drive.google.com/file/d/1aVil-sIuRQ_UQN6u52Hgw1k74YcC4H5r/preview',
    'https://drive.google.com/file/d/1X7J_2e6Q0Ol7aI4WrMnHBqsXm85Mn2tu/preview',
    'https://drive.google.com/file/d/1Xxfrjq3eNPKzyMpHgOWEz64ODCozyjy8/preview',
    'https://drive.google.com/file/d/1FrZ3MIdjWknXaZHHemo8ptXeeBI7OZQo/preview',
    'https://drive.google.com/file/d/1Wy3QuhpYfKSWnx9HQdpmKSnfMLFz33Iv/preview',
    'https://drive.google.com/file/d/1e0yd2DORalidqDisGITff1A_oOM3e__e/preview',
    'https://drive.google.com/file/d/1wdSvGoiF9f2V0tz33Br8pCPprgoRzr8B/preview',
    'https://drive.google.com/file/d/1v_WFHUlyJQw3F6BtNX4zZuJIyIEAuXtL/preview',
    'https://drive.google.com/file/d/1DRdCtrfszSA29gVHMQ0J-8s8WTIgEP2A/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.four').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twenty').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    
  }
  hideLoader() {
    this.isLoading = false;
  }
}
