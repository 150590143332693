<div id="corner">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Student Corner</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a >Student Corner</a></li>
              <li class="breadcrumb-item active" aria-current="page" style="text-transform: capitalize;">{{courseName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</div>

