<div class="course-list">
    <div class="box-default3">
      <h4>Post Graduate Programme</h4>
      <div class="row">
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/1.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Engineering</a><a></a></h6><a>
              <p>School of Engineering and Technology</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">25 Courses </span>
                </div>
            
            <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="2" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/13.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Allied Health Sciences</a><a></a></h6><a>
              <p>School of Allied Health Sciences</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">8 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="10" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/2.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Dental</a><a></a></h6><a>
              <p>School of Dental Sciences</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">1 Course</span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="12" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/4.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Nursing</a><a></a></h6><a>
              <p>School of Nursing Sciences &amp; Research</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">2 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="13" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/3.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Medical</a><a></a></h6><a>
              <p>School of Medical Sciences &amp; Research</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">1 Course</span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="14" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/5.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Agriculture</a><a></a></h6><a>
              <p>School of Agricultural Sciences</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">1 Course</span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="15" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/6.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Management</a><a></a></h6><a>
              <p>School of Business Studies</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">14 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="4" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/7.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Basic Sciences</a><a></a></h6><a>
              <p>School of Basic Sciences &amp; Research</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">9 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="3" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/8.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Humanities &amp; Social Sciences</a><a></a></h6><a>
              <p>School of Humanities &amp; Social Sciences</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">7 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="5" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/9.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Mass Communication</a><a></a></h6><a>
              <p>School of Media, Film and Entertainment</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">5 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="6" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/10.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Architecture &amp; Design</a><a></a></h6><a>
              <p>School of Architecture &amp; Planning</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">7 Courses </span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="7" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/11.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Pharmacy</a><a></a></h6><a>
              <p>School of Pharmacy</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">1 Course</span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="8" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    
        <div class="col-md-4 col-sm-6">
          <!-- Course Box -->
          <div class="course-box">
            <!-- Content -->
            <div class="content clearfix">
              <i class="icon"><a href="#" style="color:#000;"><img src="/assets/svg/courses/12.svg" alt="icon"></a></i>
              <div class="details">
              <h6><a href="#">Education</a><a></a></h6><a>
              <p>School of Education</p>
              </a></div><a>
            </a></div><a>
            <!-- /Content -->
            <!-- Footer -->
            </a><div class="footer"><a>
              </a><div class="row">
                <div class="col-sm-6">
                  <span class="count">1 Course</span>
                </div>
                <div class="col-sm-6 text-right"><a>
                  </a><a class="more-info modalButtonViewCourse" data-toggle="modal" data-target="#course-divition-modal" href="javascript:void(0)" data-src="9" data-value="2">More Info <i class="fas fa-arrow-circle-right icons"></i></a>
                          </div>
    </div>
  </div>
  <!-- /Footer -->
  </div>
  <!-- /Course Box -->
  </div>
        
      
                    </div>
    </div>
  </div>