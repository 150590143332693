import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit,OnDestroy {
  imgList:any[];
  constructor(private showcaseService:ShowcaseService,
    private service:MediaService ) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
    this.service.getMediaList();
    this.service.mediaList.snapshotChanges().subscribe(
      list=>{
      this.imgList=  list.map(item=>{ return item.payload.val();})
      this.imgList=this.imgList.reverse();
      }
    );
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
