import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireList } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';
import { NAAC } from 'src/app/models/naac.model';
import { NOTICE } from 'src/app/models/notice.model';
import { ShowNoticeService } from '../showNotice/show-notice.service';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  noticeList:AngularFireList<any>;
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  private notices:NOTICE[]=[];
  newNotice:NOTICE[]=[];
  noticeChange=new BehaviorSubject<NOTICE[]>([]);
  constructor(private http:HttpClient,
    private router:Router,
    private showService:ShowNoticeService) { }

  fetchNotice(){
    this.http.get<NOTICE[]>(this.url+'/notice.json').subscribe(res=>{
      this.setNotice(res);
    },error=>{
      console.log(error);
    });
  }

  setNotice(notice:NOTICE[]){
    this.notices=notice;
    this.newNotice=Object.keys(this.notices).map(key=>({$key:key,...this.notices[key]}));
    this.noticeChange.next(this.newNotice.reverse());
    this.splitUrl();
    // console.log(this.newContacts);
  }

  searchNotice(id:string){
    for(let noticeItem of this.newNotice){
      if(noticeItem.path===id){
        return noticeItem.link;
      }
    }
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.showService.hide();
      this.router.navigate(['/menu-detail/notice/'+url[3]]);

    }
    
  }
}
