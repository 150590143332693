import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nfound',
  templateUrl: './nfound.component.html',
  styleUrls: ['./nfound.component.scss']
})
export class NfoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
