import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ict-lab',
  templateUrl: './ict-lab.component.html',
  styleUrls: ['./ict-lab.component.scss']
})
export class IctLabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
