import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Blog } from 'src/app/models/blog.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blogList:AngularFireList<any>;
  blog:Blog[]=[];

  constructor(private firebase:AngularFireDatabase) { }
  getBlogList(){
    this.blogList= this.firebase.list('blogDetails');  

  }
  setBlog(blogs:any){
    this.blog=blogs;
  }
  getTitle(id:string){
    const blogItem = this.blog.find(b=>b.$key===id);
    return blogItem.title;
  }
  searchBlog(id:string){
   
    for(let blogItem of this.blog){
      if(blogItem.$key===id){
        
        return blogItem.description;
      }
    }
  }
}
