import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AqarComponent } from "src/app/view/iqac/aqar/aqar.component";
// import { AqarComponent } from "src/app/view/iqac/aqar/aqar.component";
import { BestPracticesComponent } from "src/app/view/iqac/best-practices/best-practices.component";
import { BpTwooneTwotwoComponent } from "src/app/view/iqac/best-practices/bp-twoone-twotwo/bp-twoone-twotwo.component";
import { BpTwotwoTwothreeComponent } from "src/app/view/iqac/best-practices/bp-twotwo-twothree/bp-twotwo-twothree.component";
import { CalenderComponent } from "src/app/view/iqac/calender/calender.component";
import { CurriculumComponent } from "src/app/view/iqac/curriculum/curriculum.component";
import { DevelopmentComponent } from "src/app/view/iqac/development/development.component";
import { HumanComponent } from "src/app/view/iqac/human/human.component";
import { InstitutionalComponent } from "src/app/view/iqac/institutional/institutional.component";
import { ItTwotwoTwothreeComponent } from "src/app/view/iqac/institutional/it-twotwo-twothree/it-twotwo-twothree.component";
import { IqacComponent } from "src/app/view/iqac/iqac.component";
import { MeetingsComponent } from "src/app/view/iqac/meetings/meetings.component";
import { NirfComponent } from "src/app/view/iqac/nirf/nirf.component";
import { SecondCalenderComponent } from "src/app/view/iqac/second-calender/second-calender.component";
import { SecondStudentFeedbackComponent } from "src/app/view/iqac/second-student-feedback/second-student-feedback.component";
import { StudentFeedbackComponent } from "src/app/view/iqac/student-feedback/student-feedback.component";
import { StudentSurveyComponent } from "src/app/view/iqac/student-survey/student-survey.component";

const route:Route[]=[
    {path:'',component:IqacComponent,children:[
        {path:'nirf',component:NirfComponent},
        // {path:'aqar',component:AqarComponent},
        {path:'student-feedback2019-20',component:StudentFeedbackComponent},
        {path:'student-feedback2017-18',component:SecondStudentFeedbackComponent},
        {path:'academic-calender2019-20',component:CalenderComponent},
        {path:'academic-calender2017-18',component:SecondCalenderComponent},
        {path:'best-practices',component:BestPracticesComponent, children:[
            {path:'2022-23', component:BpTwotwoTwothreeComponent},
            {path:'2021-22', component:BpTwooneTwotwoComponent},
        ]},
        {path:'institutional',component:InstitutionalComponent, children:[
            {path:'2022-23', component:ItTwotwoTwothreeComponent},
        ]},
        {path:'development',component:DevelopmentComponent},
        {path:'student-satisfaction-survey',component:StudentSurveyComponent},
        {path:'curriculum',component:CurriculumComponent},
        {path:'meetings',component:MeetingsComponent},
        {path:'human-value',component:HumanComponent},
        {path:'aqar',component:AqarComponent},
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class IQACRoutingModule{}