<div id="adventure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Adventure Club</h4>
                    <div class="card-body">
                        <p class="lead text-justify">Two students of our adventure club of the college scaled Mount Everest in May 2012 and May 2013.
                            <strong class="text-dark">They are Sunita Choken and Mr. Ram Kumar.</strong> 
                              Nine girls and six boys of this club under took tracking camp at Manali, Narkanda, Himachal Pradesh.
                        </p>
                         <div class="row">
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/adventure/5.png" alt="adventure" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/adventure/6.png" alt="adventure" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/adventure/7.png" alt="adventure" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/adventure/8.png" alt="adventure" class="img-fluid rounded">
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>