<div id="count">
    <div class="container">
        <div class="row count-row text-center">
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter"  >3000</span>+</span>
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Students</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >50,000</span>+</span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Alumni</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >50</span>+</span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Years of Legacy</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >50</span>+</span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Class Rooms</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >16</span></span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Smart Class Rooms</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >19</span></span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>UG Programmes</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >11</span></span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>PG Programmes</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3" id="counter">
                <span id="original"><span class="counter" >100</span>+</span>
                
                <div class="horizontal">
                    <hr class="pb-1 rounded mt-0">
                </div>
                <p>Faculty</p>
            </div>
        </div>
    </div>
</div>