<div id="club">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">ECO Club</h4>
                    <div class="card-body">
                        <div class="container-fluid d-flex justify-content-center align-items-center h-100" id="loader"
                            *ngIf="isLoading">
                            <app-loader></app-loader>
                        </div>
                        <div id="detail" class="text-center">
                            <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"
                                (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>

                        </div>
                        <!-- <p class="lead text-justify">ECO Club of the college plays an important role in organizing plantation activities.
                             The college celebrates ‘Van Mahotsav’ and World Environment Day every year and tree plantation is carried out in and around the college.
                              New saplings are planted and the damaged/ rotten plants are replaced with fresh healthy ones to keep the campus and surroundings green.
                             Moreover the greenery of the campus is maintained by the Gardeners with seasonal and eco-friendly plants.
                        </p>
                         <div class="row text-center"> -->
                        <!-- <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/club/1.jpeg" alt="club" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/club/2.jpeg" alt="club" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/club/3.jpeg" alt="club" class="img-fluid rounded">
                            </div> -->
                        <!-- <div class="col-sm-6 col-md-6 mt-3">
                                <img src="/assets/img/club/4.jpeg" alt="club" class="img-fluid rounded">
                            </div> -->

                        <!-- </div> -->
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>