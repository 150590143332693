import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
  meetings:{title:string,url:string}[]=[
    // {
    //   title:'Meeting 2020-21',
    //   url:'https://gnkc.ac.in/menu-detail/naac/meeting202021'
    // },
    // {
    //   title:' Meeting 2021-2022',
    //   url:'https://gnkc.ac.in/menu-detail/naac/meeting-2021-22'
    // },
    {
      title:'Composition and activities ',
      url:'https://gnkc.ac.in/menu-detail/naac/composition-and-activities'
    },
    {
      title:'IQAC MEETINGS-2017-18',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac1718'
    },
    {
      title:'IQAC MEETINGS-2018-19',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac1819'
    },
    {
      title:'IQAC MEETINGS-2019-20',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac1920'
    },
    {
      title:'IQAC MEETINGS-2020-21',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac2021'
    },
    {
      title:'IQAC MEETINGS-2021-22',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac2122'
    },
    {
      title:'IQAC MEETINGS-2022-23',
      url:'https://gnkc.ac.in/menu-detail/naac/iqac2022-23'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
