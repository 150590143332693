import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit,OnDestroy{

  
  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
