<div id="student">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Students Project</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            Various departments of the college ensure the students participation through students project work.
                             Students of the PG programmes are assigned with projects in frontline areas of research in departments like, Industrial Chemistry, Microbiology, Biotechnology, Environmental Sciences, Management, Social Work and Computer Science.
                              The major facilities available in the college for students are:
                        </p>
                        <ul>
                            <li>
                                <p class="lead text-justify">Internet connectivity in the college campus through well equipped ICT lab.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">The college has promoted and encouraged research activities by providing facilities like computer systems, journals, internet connectivity and INFLIBNET access.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">The library has separate reference / periodical section with back volumes and current journals/dissertations/thesis for ready reference. All the books and journals are catalogued using computer programming. The library has Xeroxing, computer printing and other reprographic facilities
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Subscription to journals and easy access.
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Modular laboratories
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Other well-equipped laboratories
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Seminar/conference hall
                                </p>
                            </li>
                            <li>
                                <p class="lead text-justify">Computer laboratories</p>
                            </li>
                        </ul>

                        <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th scope="col">S.NO.</th>
                                <th scope="col">TITLE</th>
                                <th scope="col">URL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td class="title">B.P.Ed sem 3</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bped-sem3" target="_blank">https://gnkc.ac.in/menu-detail/naac/bped-sem3</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td class="title">MSW Previous Year</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/mswpreviousyear" target="_blank">https://gnkc.ac.in/menu-detail/naac/mswpreviousyear</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td class="title">MSW Final Year</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/mswfinalyear" target="_blank">https://gnkc.ac.in/menu-detail/naac/mswfinalyear</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td class="title">BEd 1st 2021-22</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bed1styear" target="_blank">https://gnkc.ac.in/menu-detail/naac/bed1styear</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td class="title">BEd 2nd 2021-22</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bed2ndyear" target="_blank">https://gnkc.ac.in/menu-detail/naac/bed2ndyear</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">6</th>
                                <td class="title">Organic Farming Project</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/organicfarmingproject" target="_blank">https://gnkc.ac.in/menu-detail/naac/organicfarmingproject</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">7</th>
                                <td class="title">Parali Management</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/paralimanagement" target="_blank">https://gnkc.ac.in/menu-detail/naac/paralimanagement</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">8</th>
                                <td class="title">Retail Management Project</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/retailmanagementproject" target="_blank">https://gnkc.ac.in/menu-detail/naac/retailmanagementproject</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">9</th>
                                <td class="title">Computer Awareness Project</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/computer-awareness-project" target="_blank">https://gnkc.ac.in/menu-detail/naac/computer-awareness-project</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">10</th>
                                <td class="title">Summer Internships Training</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bba-summer-internships-training" target="_blank">https://gnkc.ac.in/menu-detail/naac/bba-summer-internships-training</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">11</th>
                                <td class="title">Public Administration (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/public-administration-certificates-final-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/public-administration-certificates-final-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">12</th>
                                <td class="title">EVS (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/evs-projects-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/evs-projects-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">13</th>
                                <td class="title">Bio Tech (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/biotech-certificate-final-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/biotech-certificate-final-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">14</th>
                                <td class="title">B.Sc IMB (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/b-sc-imb-certitficate-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/b-sc-imb-certitficate-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">15</th>
                                <td class="title">B.Com 3rd Sem (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/b-com-3rd-sem-certificate-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/b-com-3rd-sem-certificate-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">16</th>
                                <td class="title">
                                  B.Com 5th Sem (2021-22)</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/b-com-5th-sem-certificate-2021-22" target="_blank">https://gnkc.ac.in/menu-detail/naac/b-com-5th-sem-certificate-2021-22</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">17</th>
                                <td class="title">Bsc Evs</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bsc-evs" target="_blank">https://gnkc.ac.in/menu-detail/naac/bsc-evs</a> </td>
                              </tr>
                             
                              <tr>
                                <th scope="row">18</th>
                                <td class="title">Msc Ic</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/msc-ic" target="_blank">https://gnkc.ac.in/menu-detail/naac/msc-ic</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">19</th>
                                <td class="title">Bca Evs</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bca-evs" target="_blank">https://gnkc.ac.in/menu-detail/naac/bca-evs</a> </td>
                              </tr>
                              <tr>
                                <th scope="row">20</th>
                                <td class="title">Bba 5Th Sem Session 2020-21</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bba-5th-sem-session2020-21" target="_blank">https://gnkc.ac.in/menu-detail/naac/bba-5th-sem-session2020-21</a></td>
                              </tr>
                              <tr>
                                <th scope="row">21</th>
                                <td class="title">Bsc Imb</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bsc-imb" target="_blank">https://gnkc.ac.in/menu-detail/naac/bsc-imb</a></td>
                              </tr>
                              <tr>
                                <th scope="row">22</th>
                                <td class="title">Msc Bio Tech</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/msc-bio-tech" target="_blank">https://gnkc.ac.in/menu-detail/naac/msc-bio-tech</a></td>
                              </tr>
                              <tr>
                                <th scope="row">23</th>
                                <td class="title">Bcom 3Rd Sem</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bcom-3rd-sem" target="_blank">https://gnkc.ac.in/menu-detail/naac/bcom-3rd-sem</a></td>
                              </tr>
                              <tr>
                                <th scope="row">24</th>
                                <td class="title">Bcom 5Rd Sem</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/bcom-5rd-sem" target="_blank">https://gnkc.ac.in/menu-detail/naac/bcom-5rd-sem</a></td>
                              </tr>
                              <tr>
                                <th scope="row">25</th>
                                <td class="title">Ma Public Admin</td>
                                <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/ma-public-admin" target="_blank">https://gnkc.ac.in/menu-detail/naac/ma-public-admin</a></td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>