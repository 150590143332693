import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import ScrollOut from "scroll-out";
import { PathNameService } from 'src/app/services/pathName/path-name.service';
@Component({
  selector: 'app-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit,AfterContentInit,OnDestroy {
  so: any;
  constructor( private pathNameService:PathNameService) { }

  ngOnInit(): void {
  }
  ngAfterContentInit(){
    this.so = ScrollOut({
      onShown: function(el) {
        // remove the class
        el.classList.remove('animated');
    
        // force reflow
        void el.offsetWidth;
    
        // re-add the animated cl
        el.classList.add('animated');
      }
    });
  }
  setUrl(route:string){
    this.pathNameService.setUrl(route);
  }
  ngOnDestroy(){
    this.so.teardown();
  }

}
