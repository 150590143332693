<div id="meetings">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>IQAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">IQAC</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Meetings</li>
            </ol>
          </nav>
    </div>
    <div class="container mt-5" >
        <div class="card shadow-lg colorBlue mx-lg-5">
            <div class="row">
                <div class="col-12 text-center mt-5">

                    <h2>Meetings</h2>
                   
                </div>
                <div class="card-body text-justify">
                    <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th scope="col">S.NO.</th>
                            <th scope="col">TITLE</th>
                            <th scope="col">URL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of meetings; let i=index;">
                            <th scope="row">{{i+1}}</th>
                            <td class="title">{{data.title}}</td>
                            <td class="link"><a href="{{data.url}}" target="_blank">{{data.url}}</a> </td>
                          </tr>
                         
                        </tbody>
                      </table>
                </div>
            </div>
            
        </div>
    </div>
</div>