import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-one-one-one-two',
  templateUrl: './c1-one-one-one-two.component.html',
  styleUrls: ['./c1-one-one-one-two.component.scss']
})
export class C1OneOneOneTwoComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion 1/1.1.1_CURRICULUM DELIVERY/1.1.1.2_ COLLEGE ACTIVITY CALENDER.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
