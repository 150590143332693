import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c6-six-three-two',
  templateUrl: './c6-six-three-two.component.html',
  styleUrls: ['./c6-six-three-two.component.scss']
})
export class C6SixThreeTwoComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/NAAC/Criterion-6/Financial support to teachers certificate.pdf'];
  src: string;
  constructor() { }

  ngOnInit(): void {
    this.src = this.pdfSrc[0];
  }
}
