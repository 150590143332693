import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-nineteen-twenty',
  templateUrl: './c3-nineteen-twenty.component.html',
  styleUrls: ['./c3-nineteen-twenty.component.scss']
})
export class C3NineteenTwentyComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/3.4.3.1 Reports/';
  result:{title:string,link:string}[]=[
    {
      title:'ENVIRONMENT DAY',
      link:this.c343Url + '17_ 2019-20-NCC_ENVIRONMENT-DAY_report.pdf'
    },
    {
      title:'UNITY DAY',
      link:this.c343Url + '18_ 2019-20-NCC_UNITY-DAY_report.pdf'
    },
    {
      title:'ANTI CORRUPTION AWARENESS',
      link:this.c343Url + '19_ 2019-20-NCC_ANTI-CORRUPTION-AWARENESS_report.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c343Url + '20_ 2019-20-NCC_ROAD-SAFETY-RALLY_report.pdf'
    },
    {
      title:'DISTRIBUTION FOOD TO MIGRANTS',
      link:this.c343Url + '21_ 2019-20-NCC_DISTRIBUTION-FOOD-TO-MIGRANTS_report.pdf'
    },
    {
      title:'MAINTAINANCE OF LAW AND DUTY',
      link:this.c343Url + '22_ 2019-20-NCC_MAINTAINANCE-OF-LAW-AND-DUTY_report.pdf'
    },
    {
      title:'SOCIAL SERVICE BY NCC CADETS',
      link:this.c343Url + '23_ 2019-20-NCC_SOCIAL-SERVICE-BY-NCC-CADETS_report.pdf'
    },
    {
      title:'National Voter Day',
      link:this.c343Url + '24_ 2019-2020_NSS-National-Voter-Day_report.pdf'
    },
    {
      title:'NSS 7 Day Camp at Gdhauli',
      link:this.c343Url + '25_ 2019-2020_NSS-NSS-7-Day-Camp-at-Gdhauli_report.pdf'
    },
    {
      title:'Tree Plantation at Gdhauli',
      link:this.c343Url + '26_ 2019-2020_NSS-Tree-Plantation-at-Gdhauli_report.pdf'
    },
    {
      title:'National Deworming Day',
      link:this.c343Url + '27_ 2019-2020_NSS-National-Deworming-Day_report.pdf'
    },
    {
      title:'ROAD SAFETY AWARENESS PROGRAM,MOTOR VEHCHCLE ACT-2019',
      link:this.c343Url + '28_ 2019-20-YRC_ROAD-SAFETY-AWARENESS _report.pdf'
    },
    {
      title:'NUKKAD NATAK ON BLOOD DONATION',
      link:this.c343Url + '29_ 2019-20-YRC_NUKKAD-NATAK-ON-BLOOD-DONATION_report.pdf'
    },
    {
      title:'26TH ANNUAL BLOOD DONATION CAMP',
      link:this.c343Url + '30_ 2019-20-YRC_BLOOD-DONATION-CAMP_report.pdf'
    },
    {
      title:'NATIONAL LEVEL ONLINE COLLAGE MAKING COMPETITION',
      link:this.c343Url + '31_ 2019-20-YRC_COLLAGE-MAKING-COMPETITION_report.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
