import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';
declare var $: any;
@Component({
  selector: 'app-c7-seven-three-one',
  templateUrl: './c7-seven-three-one.component.html',
  styleUrls: ['./c7-seven-three-one.component.scss']
})
export class C7SevenThreeOneComponent implements OnInit {
  institutional:{title:string,link:string}[]=[];

  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-7/7.3.1 Brief Report on Sports of Guru Nanak Khalsa College _Yamuna nagar(Institutional Distinctiveness).pdf',];
  src: string;
  showBrief=true;
  isLoading = true;
  constructor( private ssrService: SsrService) { }

  ngOnInit(): void {
    this.changeRoute('1');
    this.institutional= this.ssrService.getC7Institutional();
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
      this.showBrief = true;
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      // this.src = this.pdfSrc[1];
      this.showBrief=false;
    }
  }

  hideLoader() {
    this.isLoading = false;
  }

}
