import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-human',
  templateUrl: './human.component.html',
  styleUrls: ['./human.component.scss']
})
export class HumanComponent implements OnInit {
  human:{title:string,url:string}[]=[
    {
      title:'News',
      url:'https://gnkc.ac.in/menu-detail/naac/newscutout'
    },
    {
      title:'Celebration of National and Internal Days',
      url:'https://gnkc.ac.in/menu-detail/naac/Celebrationnationalinternaldays'
    },
    {
      title:'Code Ethics',
      url:'https://gnkc.ac.in/menu-detail/naac/codeethics'
    },
  ]
  
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1wb3pYPJD_qGjqj3g0FWpfEEvUbeDAfIM/preview',];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('2022-23');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '2022-23') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
      this.show22 = true;
    }
    if (year == '2021-22') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.show22 = false;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
