<div id="gender">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card shadow-lg">
            <h4 class="card-title text-center">Gender Equality</h4>
            <div class="card-body text-justify">
                <div class="container-fluid d-flex justify-content-center align-items-center h-100"
                id="loader" *ngIf="isLoading">
                <app-loader></app-loader>
            </div>
            <div id="detail" class="text-center">
                <pdf-viewer [src]="src1" [render-text]="true" style="display: block;"
                    id="list-view" (on-progress)="hideLoader()"
                    (error)="hideLoader()"></pdf-viewer>
                <pdf-viewer [src]="src" [render-text]="true" style="display: block;"
                    id="list-view" (on-progress)="hideLoader()"
                    (error)="hideLoader()"></pdf-viewer>
            </div>
            </div>
          </div>
  
        </div>
      </div>
  
  
    </div>
  </div>