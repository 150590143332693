<div id="testimonial" >
    <div class="container-fluid mt-5 px-3 px-lg-5" data-scroll>
      <div class="row">
        <div class="col-12">
          <h2 class="font-weight-bold text-center " style="font-size: 32px;">Testimonials</h2>
          <hr class="pb-1 ">
        </div>
       
      </div>
      <div class="row">
        <div class="col-12">
          <div class="owl-carousel owl-theme">
            <div class="item"> 
              <figure class="snip1157">
                  <blockquote>Will forever be grateful for this fun-filled, enriching experience and value my education at Guru Nanak Khalsa College. GNKC has an incredibly unique education system that meets every need! <br>The after-college programs are immensely useful and provide opportunities to do other activities along with core studies.
                    <div class="arrow"></div>
                  </blockquote>
                  <img src="/assets/svg/staff/2.svg" alt="sq-sample3" />
                  <div class="author">
                    <h5>Chaitanya Arora, <br><span> Director</span><br> <span>BSc Computer Science (1991-1993)</span></h5>
                  </div>
                </figure>
            </div>
            <div class="item"> 
              <figure class="snip1157">
                  <blockquote>GNKC has the right combination of administration, dedicated enthusiastic teachers and support staff who are determined to help an individual find their full potential. College has a lot to offer for a head start on a successful career and has a safe learning environment with an equal opportunity to make friends for life!
                    <div class="arrow"></div>
                  </blockquote>
                  <img src="/assets/img/testimonial/3.jpeg" alt="sq-sample3" />
                  <div class="author">
                    <h5>Gaurav Sharma <br><span> Working in District Child</span><br> <span>Protection Unit Yamunanagar,</span><br><span>Women and Child </span><br><span>Development Department</span></h5>
                  </div>
                </figure>
            </div>

            <div class="item"> 
              <figure class="snip1157">
                  <blockquote>Progress of an Academic institution takes wings when education is imparted with a personal touch by teachers. That's what makes gnkc stand apart. Care, concern and motivation have been the hallmark of teachers here and this crowning glory shall bring the institution continued success.
                    <div class="arrow"></div>
                  </blockquote>
                  <img src="/assets/img/testimonial/2.jpeg" alt="sq-sample3" />
                  <div class="author">
                    <h5>SATYA PAL POONIA <br><span> Post Held - Master (ENGLISH),</span><br> <span>ORGANIZATION - SAINIK SCHOOL KUNJPURA</span></h5>
                  </div>
                </figure>
            </div>
            <div class="item"> 
                <figure class="snip1157">
                    <blockquote>My experience in gnkc was really wonderful. It opened great opportunities for me. As a student of science , I learnt a lot about the practical programs. I shall always relish my student days in gnkc throughout my life.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/13.png" alt="sq-sample3" />
                    <div class="author">
                      <h5>Mr. Hitesh Bhalla <br><span> Assistant Vice –President</span><br> <span> Axis Bank</span></h5>
                    </div>
                  </figure>
              </div>
            <div class="item">
                <figure class="snip1157">
                    <blockquote>My teachers at gnkc made me what I am today. The faculty of commerce department provided me the right atmosphere to accomplish my dreams.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/17.png" alt="sq-sample3" />
                    <div class="author">
                      <h5>Dr. Harish Suri <br><span> Senior Research Lead,</span> <br><span> Evalueserve, Gurugram</span></h5>
                    </div>
                  </figure>
            </div>
            <div class="item">
                <figure class="snip1157">
                    <blockquote>The amicable staff and environs at gnkc  created confidence in me to achieve in real life. The conducive way in which the teachers helped the students , will always be a souce of inspiration to all of us.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/19.jpg" alt="sq-sample3" />
                    <div class="author">
                      <h5>Mr. Gurbaj Singh <br> <span> International Hockey Umpire</span></h5>
                    </div>
                  </figure>
            </div>
            <div class="item">
                <figure class="snip1157">
                    <blockquote>The innovative ways in which we were imparted education in the college has made a remarkable change in my life. The fellow feeling and community development which I learnt at gnkc helped me to serve the society after being a student of  social work at the college. I wish that the college goes long way in imparting quality education in future also.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/7.jpg" alt="sq-sample3" />
                    <div class="author">
                      <h5>Lt. Col Vijay Bakshi <br><span> 2IC in AAD</span></h5>
                    </div>
                  </figure>
            </div>
            <div class="item">
                <figure class="snip1157">
                    <blockquote>My days at gnkc provided me a platform for realising my future dreams. My stay at gnkc especially the training by NCC disciplined me and inspired me to serve the country through forces.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/22.png" alt="sq-sample3" />
                    <div class="author">
                      <h5>Ms. Seema Goswami <br><span> Scaled Mount Everest</span></h5>
                    </div>
                  </figure>
            </div>
            <div class="item">
                <figure class="snip1157">
                    <blockquote>I got admission in gnkc in Bsc sports and my success road ensued from there. The faculty members at gnkcenhanced my educational and interpersonal skills.. which helped me in achieving in my life.
                      <div class="arrow"></div>
                    </blockquote>
                    <img src="/assets/img/alumni/12.png" alt="sq-sample3" />
                    <div class="author">
                      <h5>Mohd. Miraj <br><span> Branch Manager</span><br><span> HDFC</span></h5>
                    </div>
                  </figure>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  