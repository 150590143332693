import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';

@Component({
  selector: 'app-neena',
  templateUrl: './neena.component.html',
  styleUrls: ['./neena.component.scss']
})
export class NeenaComponent implements OnInit {
  result:{name:string ,title:string, link:string} [] = [];
  constructor(private ssrService : SsrService) { }

  ngOnInit(): void {
    this.result = this.ssrService.getNeena();
  }

}
