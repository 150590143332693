import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3-three-two-one',
  templateUrl: './c3-three-two-one.component.html',
  styleUrls: ['./c3-three-two-one.component.scss']
})
export class C3ThreeTwoOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
