import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $:any;
@Component({
  selector: 'app-graduate',
  templateUrl: './graduate.component.html',
  styleUrls: ['./graduate.component.scss']
})
export class GraduateComponent implements OnInit {
  courseArt:{name:string,title:string,creteria:string}[]=[];
  constructor(private courseService:CourseService) { }

  ngOnInit(): void {
    this.courseArt=this.courseService.getCourseArt();
  }

  setModal(i:number){
    document.getElementById('courseModelTitle').innerHTML=this.courseArt[i].name;
    document.querySelector('.title').innerHTML=this.courseArt[i].title;
    document.querySelector('.creteria').innerHTML=this.courseArt[i].creteria;
  }
}
