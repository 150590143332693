import { Component, OnInit } from '@angular/core';
import { SsrService } from 'src/app/services/ssr/ssr.service';
declare var $:any;
@Component({
  selector: 'app-c1-one-three-two',
  templateUrl: './c1-one-three-two.component.html',
  styleUrls: ['./c1-one-three-two.component.scss']
})
export class C1OneThreeTwoComponent implements OnInit {
  session:{title:string,link:string}[]=[];

  showBrief=true;
  isLoading = true;
  constructor( private ssrService: SsrService) { }

  ngOnInit(): void {
    this.changeRoute('1');
    this.session= this.ssrService.getSession();
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      // this.src = this.pdfSrc[1];
      this.showBrief=false;
    }
  }

  hideLoader() {
    this.isLoading = false;
  }

}
