import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c32-nineteen',
  templateUrl: './c32-nineteen.component.html',
  styleUrls: ['./c32-nineteen.component.scss']
})
export class C32NineteenComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
        title: 'One Day Inter-Disciplinary National Workshop on Intellectual Property Rights-Awareness & Issues in Academic Research',
        link: this.c3321Url + '1_13.02.2020-IPR-Awareness _ Issues-in-Academic-Research-Final.pdf'
    },
    {
        title: 'One Day National Webinar on Entrepreneurial Skills',
        link: this.c3321Url + '2_26.05.20-ENTREPRENEURIAL-SKILLS-Final_.pdf'
    },
    {
        title: 'National Web-Seminar on Research Methodology',
        link: this.c3321Url + '3_28.5.20-WEB-SEMINAR-ON-RESEARCH-METHODOLOGY-Final.pdf'
    },
]
  constructor() { }

  ngOnInit(): void {
  }

}
