<div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>M.Com. (Two Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/9.svg">
                    <h3>M.Com.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>M.Com.</p>
                      <p>(50 seats - GIA) (40 seats - SF)</p>
                      <a class="btn btn-block" (click)="setModal(4)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                    </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    
    
   
    
  </div>
  <div class="container course-detail mt-5">
    <div class="box-default3 shadow-lg">
      <h4>B.Com. (Three Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/33.svg">
                    <h3>General</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Com. General </p>
                        <a class="btn btn-block" (click)="setBModal(8)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/34.svg">
                    <h3>Computer Applications</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Com. (C.A.)</p>
                        <a  class="btn btn-block" (click)="setBModal(9)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
        <!-- <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/12.svg">
                    <h3>B.Com (Hons.)</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Com. (Hons.)</p>
                    <p>(50 Seats - SF)</p>
                        <a class="btn btn-block" (click)="setBModal(10)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div> -->
        
      </div>
    </div>
    
   
   
    
  </div>
  <div class="container course-detail  mt-5">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.B.A (Three Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
              <div class="content">
                <img src="/assets/svg/courses/35.svg">
                <h3>B.B.A.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
              <div class="content">
                <p>B.B.A.</p>
                <p>AICTE Approved</p>
                  <a class="btn btn-block" (click)="setBModal(11)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
              </div>
            </div>
          </div>
          
      </div>
    </div>
    
    
   
    
  </div>
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- <div class="modal-body">
          <h5 class="title"></h5>
          <h3 class="creteria1"></h3>
          <p class="sem1"></p>
          
          <h3 class="creteria2"></h3>
          <p class="sem2"></p>
          <ol class="sem2b">
            <li class="sem2b1"></li>
            <li class="sem2b2"></li>
            <li class="sem2b3"></li>
          </ol>
          <h5 class="comp-heading"></h5>
          <ol class="comp-detail">
            <li class="comp-detail1"></li>
            <li class="comp-detail2"></li>
            
          </ol>
          <div class="po-pso">
          
          </div>
        </div> -->
        <div class="modal-body">
          <h5 *ngIf="title" class="title" [innerHTML]="title"></h5>
          <h3 *ngIf="creteria1" class="creteria1" [innerHTML]="creteria1"></h3>
          <p *ngIf="sem1" class="sem1" [innerHTML]="sem1"></p>
          <h3 *ngIf="creteria2" class="creteria2" [innerHTML]="creteria2"></h3>
          <p *ngIf="sem2" class="sem2" [innerHTML]="sem2"></p>
          <ol *ngIf="sem2b && sem2b.length > 0" class="sem2b">
            <li *ngIf="sem2b[0]" class="sem2b1" [innerHTML]="sem2b[0]"></li>
            <li *ngIf="sem2b[1]" class="sem2b2" [innerHTML]="sem2b[1]"></li>
            <li *ngIf="sem2b[2]" class="sem2b3" [innerHTML]="sem2b[2]"></li>
          </ol>
          <h5 *ngIf="compHeading" class="comp-heading" [innerHTML]="compHeading"></h5>
          <ol *ngIf="compDetail && compDetail.length > 0" class="comp-detail">
            <li *ngIf="compDetail[0]" class="comp-detail1" [innerHTML]="compDetail[0]"></li>
            <li *ngIf="compDetail[1]" class="comp-detail2" [innerHTML]="compDetail[1]"></li>
            <li *ngIf="compDetail[2]" class="comp-detail3" [innerHTML]="compDetail[2]"></li>
          </ol>
          <div *ngIf="poPso" class="po-pso" [innerHTML]="poPso"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  



  <!-- <h2>B.SC. WITH BOTANY</h2>
  <h3>Programme Outcome</h3>
  <ul>
    <li><p>Knowledge and understanding of plants structure.</p></li>
    <li><p>Students learn to carry out practical work.</p></li>
    <li><p>Communication of scientific idea.</p></li>
    <li><p>Student will be able to apply the scientific method to question in botany by formulating testable hypotheses.</p></li>
    <li><p>Explain how plants function at the level of genes, cells, tissues.</p></li>
  </ul>
  <h3>Program Specific Outcomes (PSO)</h3>
  <ul>
    <li><p>Students will get additional skill of applying digital literacy to varied areas of biological studies by learning latest techniques involving computers like –Analyze and interpret the significance of data by using statistical software.</p></li>
    <li><p>Handling instruments required for photography of plants.</p></li>
    <li><p>Evaluate information about plants.</p></li>
  </ul>
  <h3>Course Outcome (CO)</h3>
  <ul>
    <li><p>To follow the 3 R’s – Reduce, Recycle and Reuse and promote the principles of Sustainable development of Environment.</p></li>
    <li><p>To sensitize the use of biodegradable, cost efficient, environment friendly and green chemicals and procedures.</p></li>
    <li><p>To minimize the use of chemicals and reduce the environmental pollution after knowing the concepts of Botany.</p></li>
  </ul>

  <h2>B.SC. WITH ZOOLOGY</h2>
  <h3>Programme Outcome</h3>
  <ul>
    <li><p>Understanding the morphology and functional characteristics of animals at cellular and tissue level.</p></li>
    <li><p>Enhancing technical skills of students.</p></li>
    <li><p>Theoretical and practical knowledge about the Environmental Science, aqua culture and pest management etc.</p></li>
  </ul>
  <h3>Program Specific Outcomes (PSO)</h3>
  <ul>
    <li><p>In-depth theoretical and practical knowledge of Zoology.</p></li>
    <li><p>Understanding the technical aspects of various technologies used in the field.</p></li>
    <li><p>Helps in addressing the biological challenges faced by human beings.</p></li>
  </ul>
  <h3>Course Outcome (CO)</h3>
  <ul>
    <li><p>After studyingthe course,  the students get well versed with diversified topics like immunology, recombinant DNA technology, plant tissue culture, biochemistry, Molecular Biology, zoological nomenclature, classification, developmental stages, economical importance of lower and higher invertebrates, hematology, histology of mammals, aquaculture and pest management.</p></li>
    <li><p>The student can pursue higher studies like M.Sc. and Ph. D. in Zoology, Entomology, Fish and Fisheries, Wildlife, Marine Biology etc.</p></li>
    <li><p>They can also become Medical Representative of various Pharma companies.</p></li>
  </ul>

  <h2>B.SC. WITH CHEMISTRY</h2>
  <h3>Programme Outcome</h3>
  <ul>
    <li><p>Knowledge about fundamentals of chemical and scientific theories.</p></li>
    <li><p>Assess the properties of all elements.</p></li>
    <li><p>Quality and Quality analysis of chemicals in laboratories.</p></li>
    <li><p>Discuss the position of elements and their properties, bounding, hybridization, and structure in different compound using theories.</p></li>
  </ul>
  <h3>Program Specific Outcomes (PSO)</h3>
  <ul>
    <li><p>In-depth theoretical and practical knowledge of Chemistry.</p></li>
    <li><p>To apply different concepts of organic, inorganic, physical, analytical and spectroscopic techniques of chemistry in various fields.</p></li>
    <li><p>Understanding the technical aspects of various technologies used in the field of chemistry.</p></li>
    <li><p>Helps in addressing the biochemical challenges faced by human beings.</p></li>
  </ul>
  <h3>Course Outcome (CO)</h3>
  <ul>
    <li><p>Disciplinary knowledge and understanding of fundamentals and principles of Chemistry.</p></li>
    <li><p>Learn about the different concepts, laws, theories, application and reactions.</p></li>
    <li><p>To equip the students with theoretical and practical aspects of some industrially important chemical compounds and conceptual understanding of phase equilibrium, electrochemistry, thermodynamics and their applications in allied field.</p></li>
  </ul>

  <h2>B. SC. WITH PHYSICS</h2>
  <h3>Programme Outcome</h3>
  <ul>
    <li><p>Able to explain physics applies to phenomena in the world around than.</p></li>
    <li><p>Recognize physics methods and principles and address problems related to them.</p></li>
    <li><p>Basics knowledge about the transistor semiconductors, amplifier, electromagnetism.</p></li>
    <li><p>Problem solving ability related to nuclear physics, solid states, noon physics and atomic and macules structure.</p></li>
  </ul>
  <h3>Program Specific Outcomes (PSO)</h3>
  <ul>
    <li><p>Ability to develop experimental tools.</p></li>
    <li><p>Analyze problems and develop correct solutions using laws.</p></li>
    <li><p>Acquire knowledge about classical mechanics, quantum mechanics, electromagnetism, and optics.</p></li>
    <li><p>Learn how to design and conduct experiments.</p></li>
    <li><p>Learn about minimize contributing variables.</p></li>
    <li><p>Apply conceptual understanding of Physics.</p></li>
  </ul>
  <h3>Course Outcome (CO)</h3>
  <ul>
    <li><p>Learn about concepts of Modern Physics.</p></li>
    <li><p>Relationship between observation and theory as well as Practical.</p></li>
    <li><p>Concept developed and changed over time.</p></li>
    <li><p>Acquire knowledge in the area of nuclear and practical physics.</p></li>
    <li><p>Relationship between electrical charge, field potential and magnetism.</p></li>
    <li><p>Skills to solve numerical problems.</p></li>
    <li><p>Understanding principles of optics.</p></li>
    <li><p>Thermal and statistical principal.</p></li>
    <li><p>Mathematical and Computer Techniques.</p></li>
  </ul>

  <h2>B. SC. WITH MATHEMATICS</h2>
  <h3>Programme Outcome</h3>
  <ul>
    <li><p>Demonstrate the lignifying structure mathematics and relationships among them.</p></li>
    <li><p>Investigable and apply mathematical problems and solutions in a variety of contents.</p></li>
    <li><p>Develop basic manipulative skills in algebra, geometry, trigonometry and calculus.</p></li>
    <li><p>Know about sets, relations, functions and logical structures.</p></li>
  </ul>
  <h3>Program Specific Outcomes (PSO)</h3>
  <ul>
    <li><p>The students are prepared for employability.</p></li>
    <li><p>In various technical positions like scientific assistants, quality control assistants, etc.</p></li>
    <li><p>As web based and application based designer and developer, tester, Data Entry Operator (DEO), etc.</p></li>
    <li><p>Through competitive exams like IT officers/bank employees in various public and private sectors.</p></li>
    <li><p>As entrepreneur to build, upgrade, test and maintain a computer system.</p></li>
  </ul>
  <h3>Course Outcome (CO)</h3>
  <ul>
    <li><p>Learn about concepts of Mathematics.</p></li>
    <li><p>Leaners will acquire the skills like decision making, problem solving in day to day business affaires after studying the subject of mathematics.</p></li>
    <li><p>Skills to solve numerical problems.</p></li>
    <li><p>Mathematical and Computer Techniques.</p></li>
    <li><p>Learners will be able to do higher education and advance research in the field of mathematics.</p></li>
  </ul> -->