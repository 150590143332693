import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eighteen-nineteen',
  templateUrl: './eighteen-nineteen.component.html',
  styleUrls: ['./eighteen-nineteen.component.scss']
})
export class EighteenNineteenComponent implements OnInit {
  c3431Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1 Number of Extension and Outreach Programs_Photographs_5yr_SSR_Final/2018-19/';
  result:{title:string,link:string}[]=[
    {
      title:'SWACHTTA PAKHWARA',
      link:this.c3431Url + '1_2018 -19 NCC_SWACHTTA PAKHWARA_photos.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c3431Url + '2._2018 -19 NCC_CLEANLINESS DRIVE_photos.pdf'
    },
    {
      title:'SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN',
      link:this.c3431Url + '3_2018 -19 NCC_SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN_photos.pdf'
    },
    {
      title:'DRUG CONTROL RALLY',
      link:this.c3431Url + '4_2018 -19 NCC_DRUG CONTROL RALLY_photos.pdf'
    },
    {
      title:'SADAK SURAKSHA PRIKSHA',
      link:this.c3431Url + '5_2018 -19 NCC_SADAK SURAKSHA PRIKSHA_photos_.pdf'
    },
    {
      title:'BLOOD DONATION RALLY',
      link:this.c3431Url + '6_2018 -19 NCC__BLOOD DONATION RALLY_ photos.pdf'
    },
    {
      title:'BLOOD DONATION CAMP',
      link:this.c3431Url + '7_2018 -19 NCC_BLOOD DONATION CAMP_photos.pdf'
    },
    {
      title:'FLAG DAY',
      link:this.c3431Url + '8_2018 -19 NCC_ FLAG DAY _photos.pdf'
    },
    {
      title:'TRAFFIC AWARNESS RALLY',
      link:this.c3431Url + '9_2018 -19 NCC__TRAFFIC AWARNESS RALLY_ photos.pdf'
    },
    {
      title:'WORLD WATER DAY',
      link:this.c3431Url + '10_2018 -19 NCC___WORLD WATER DAY_photos.pdf'
    },
    {
      title:'BETI BACHAO BETI PADHAO RALLY',
      link:this.c3431Url + '11_2018 -19 NCC __BETI BACHAO BETI PADHAO RALLY_photos.pdf'
    },
    {
      title:'ATC CAMP',
      link:this.c3431Url + '12_2018 -19 NCC_ATC CAMP_photos.pdf'
    },
    {
      title:'Helping the Poor & Needy person',
      link:this.c3431Url + '13_2018 -19 NSS_Helping the Poor _ Needy person_photos.pdf'
    },
    {
      title:'National level Road Safety Week',
      link:this.c3431Url + '14_2018 -19 NSS_National level Road Safety Week__photos.pdf'
    },
    {
      title:'State level Road Safety Seminar',
      link:this.c3431Url + '15_2018 -19 NSS_State level Road Safety _photos.pdf'
    },
    {
      title:'Health and Blood Checkup Camp',
      link:this.c3431Url + '16_2018 -19 NSS_Health and Blood Checkup Camp_photo.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
