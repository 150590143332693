

export class Contact{
    constructor(
        public id:string,
        public name:string,
        public phone:string,
        public message:string,
        public email:string,
        public status:string,
        public date:Date
    ){}
}