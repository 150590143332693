<div id="heading">
    <h1>Organogram</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Organogram</li>
        </ol>
    </nav>
</div>

<div id="organ">
    <div class="container-fluid">
        <div class="card pl-3 pr-3 text-justify ml-lg-5">
            <h4 class="card-title text-center ">Organogram of the Institution</h4>
            <div class="card-body text-justify">
                <!-- <img class="img-fluid" src="assets\img\about-us\Organogram Final.jpg" alt="Organogram"> -->
                <iframe src="https://drive.google.com/file/d/1GBAy2blpFrvYM8j0PpyUuFa563ZjgiSe/preview" width="100%" height="640px" allow="autoplay"></iframe>
                <!-- <p class="lead mb-4">
                    Managing Committee consists of 4 office bearers, 12 Governing Body members and Members of the General body. All important decisions are taken by the governing body with the principal of the college as ex- officio member. For day to day administration of the college the principal is assisted by 4 Deans, 16 HODs and 106 members of staff who worked in individual or as team members of various committees to run the academic and extra-curricular activities smoothly. The clerical and support staff provide all necessary backup and support.</p>

                    <iframe src="https://drive.google.com/file/d/1GBAy2blpFrvYM8j0PpyUuFa563ZjgiSe/preview" width="100%" height="640px" allow="autoplay"></iframe> -->
            </div>
        </div>
    </div>
</div>