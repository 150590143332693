import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AddOnComponent } from "src/app/view/courses/add-on/add-on.component";
import { CommerceCourseComponent } from "src/app/view/courses/commerce-course/commerce-course.component";
import { CoursesComponent } from "src/app/view/courses/courses.component";
import { EducationComponent } from "src/app/view/courses/education/education.component";
import { ElectiveCoursesComponent } from "src/app/view/courses/elective-courses/elective-courses.component";
import { GraduateComponent } from "src/app/view/courses/graduate/graduate.component";
import { LessonPlanComponent } from "src/app/view/courses/lesson-plan/lesson-plan.component";
import { LpTwooneTwotwoComponent } from "src/app/view/courses/lesson-plan/lp-twoone-twotwo/lp-twoone-twotwo.component";
import { LpTwothreeTwofourComponent } from "src/app/view/courses/lesson-plan/lp-twothree-twofour/lp-twothree-twofour.component";
import { LpTwotwoTwothreeComponent } from "src/app/view/courses/lesson-plan/lp-twotwo-twothree/lp-twotwo-twothree.component";
import { PhysicalCourseComponent } from "src/app/view/courses/physical-course/physical-course.component";
import { PoCoComponent } from "src/app/view/courses/po-co/po-co.component";
import { PostGraduateComponent } from "src/app/view/courses/post-graduate/post-graduate.component";
import { SoftSkillComponent } from "src/app/view/courses/soft-skill/soft-skill.component";
import { UgcComponent } from "src/app/view/courses/ugc/ugc.component";
import { SecComponent } from "src/app/view/courses/sec/sec.component"
import { VacComponent } from "src/app/view/courses/vac/vac.component"
import { AecComponent } from "src/app/view/courses/aec/aec.component"
import { MdcComponent } from "src/app/view/courses/mdc/mdc.component"
import { LpTwothreeTwofourEvenComponent } from "src/app/view/courses/lesson-plan/lp-twothree-twofour-even/lp-twothree-twofour-even.component";

const route:Route[]=[
    {path:'',component:CoursesComponent,children:[
        {path:'arts',component:GraduateComponent},
        {path:'science',component:PostGraduateComponent},
        {path:'commerce',component:CommerceCourseComponent},
        {path:'physical',component:PhysicalCourseComponent},
        {path:'ugc',component:UgcComponent},
        {path:'education',component:EducationComponent},
        {path:'sec',component:SecComponent},
        {path:'vac',component:VacComponent},
        {path:'aec',component:AecComponent},
        {path:'mdc',component:MdcComponent},
        // {path:'add-on',component:AddOnComponent},
        {path:'PO-CO',component:PoCoComponent},
        {path:'elective-courses',component:ElectiveCoursesComponent},
        {path:'lesson-plan', component: LessonPlanComponent, children:[
            {path:'2021-2022', component:LpTwooneTwotwoComponent},
            {path:'2022-2023', component:LpTwotwoTwothreeComponent},
            {path:'2023-2024', component:LpTwothreeTwofourComponent},
            {path:'2023-2024-even', component:LpTwothreeTwofourEvenComponent},
        ]},
        {path:'soft-skill', component:SoftSkillComponent}
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class CoursesRoutingModule{}