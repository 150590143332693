import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-three',
  templateUrl: './one-three.component.html',
  styleUrls: ['./one-three.component.scss']
})
export class OneThreeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
