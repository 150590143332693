import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twentyone-twentytwo',
  templateUrl: './twentyone-twentytwo.component.html',
  styleUrls: ['./twentyone-twentytwo.component.scss']
})
export class TwentyoneTwentytwoComponent implements OnInit {
  c3431Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1 Number of Extension and Outreach Programs_Photographs_5yr_SSR_Final/2021-22/';

  result:{title:string,link:string}[]=[
    {
      title:'HARYANA DAY',
      link:this.c3431Url + '41_2021-22_NCC_HARYANA DAY_photos.pdf'
    },
    {
      title:'ARMED FORCES FLAG DAY',
      link:this.c3431Url + '42_2021-22_NCC_ARMED FORCES FLAG DAY_photos.pdf'
    },
    {
      title:'NADI UTSAV',
      link:this.c3431Url + '43_2021-22_NCC_NADI UTSAV_photos.pdf'
    },
    {
      title:'NISHANT SHARMA SAHEEDI DIWAS',
      link:this.c3431Url + '44_2021-22_NCC_NISHANT SHARMA SAHEEDI DIWAS_photos.pdf'
    },
    {
      title:'OPPORTUNITY FOR GIRLS IN ARMY',
      link:this.c3431Url + '45_2021-22_NCC_OPPORTUNITY FOR GIRLS IN ARMY_photos.pdf'
    },
    {
      title:'WORLD DRUG DAY RALLY',
      link:this.c3431Url + '46_2021-22_NCC_WORLD DRUG DAY RALLY_photos.pdf'
    },
    {
      title:'ONE DAY VACCINATION CAMP',
      link:this.c3431Url + '47_2021-22_NSS_ONE DAY VACCINATION CAMP_photos.pdf'
    },
    {
      title:'CLEANINESS DRIVE',
      link:this.c3431Url + '48_2021-22_NSS_CLEANINESS DRIVE_photos.pdf'
    },
    {
      title:'AATAMNIRBHAR PROGRAM',
      link:this.c3431Url + '49_2021-22_NSS_AATAMNIRBHAR PROGRAM_photos.pdf'
    },
    {
      title:'MEGHA COVID VACCINATION CAMP',
      link:this.c3431Url + '50_2021-22_NSS_MEGHA COVID VACCINATION CAMP_photos.pdf'
    },



    {
      title:'NSS DAY CELEBRATION',
      link:this.c3431Url + '51_2021-22_NSS__NSS DAY CELEBRATION_photos.pdf'
    },
    {
      title:'PULSE POLIO CAMPAIGN',
      link:this.c3431Url + '52_2021-22_NSS_PULSE POLIO CAMPAIGN_photos.pdf'
    },
    {
      title:'POSHANMAH CAMP',
      link:this.c3431Url + '53_2021-22_NSS_POSHANMAH CAMP_photos.pdf'
    },
    {
      title:'UNIVERSITY LEVEL PRE-RD TRIAL CAMP',
      link:this.c3431Url + '54_2021-22_NSS__UNIVERSITY LEVEL PRE-RD TRIAL CAMP_photos.pdf'
    },
    {
      title:'NORHT ZONE PRE-RD CAMP, BIYANI  UNIVERSITY, JAIPUR',
      link:this.c3431Url + '55_2021-22_NSS_NORHT ZONE PRE-RD CAMP, BIYANI  UNIVERSITY, JAIPUR_photos.pdf'
    },
    {
      title:'STATE LEVEL NSS CAMP',
      link:this.c3431Url + '56_2021-22_NSS_STATE LEVEL NSS CAMP_photos.pdf'
    },
    {
      title:'NATIONAL SEMINAR AND CLEANINESS DRIVE AT GVT COLLEGE AHARWALA',
      link:this.c3431Url + '57_2021-22_NSS_NATIONAL SEMINAR AND CLEANINESS DRIVE AT GVT COLLEGE AHARWALA_photos.pdf'
    },
    {
      title:'UNIVERSITY LEVEL NSS CAMP, Mullana',
      link:this.c3431Url + '58_2021-22_NSS_UNIVERSITY LEVEL NSS CAMP, Mullana_photos.pdf'
    },
    {
      title:'NATIONAL INTERGRATION CAMP AT KUK',
      link:this.c3431Url + '59_2021-22_NSS_NATIONAL INTERGRATION CAMP AT KUK_photos.pdf'
    },
    {
      title:'ARMED FORCED FLAG DAY DONATION COLLECTED CAMP',
      link:this.c3431Url + '60_2021-22_NSS_ARMED FORCED FLAG DAY DONATION COLLECTED CAMP_photos.pdf'
    },



    {
      title:'SEMINAR ON GURUPURAB',
      link:this.c3431Url + '61_2021-22_NSS_SEMINAR ON GURUPURAB_photos.pdf'
    },
    {
      title:'COLLEGE FOUNDATION DAY CELEBRATION',
      link:this.c3431Url + '62_2021-22_NSS_COLLEGE FOUNDATION DAY CELEBRATION_photos.pdf'
    },
    {
      title:'GURU RAVIDAS JAYANTI',
      link:this.c3431Url + '63_2021-22_NSS_GURU RAVIDAS JAYANTI_photos.pdf'
    },
    {
      title:'ANPURNA WEEK',
      link:this.c3431Url + '64_2021-22_Rotract_ANPURNA WEEK_photos.pdf'
    },
    {
      title:'MANGO DONATION ',
      link:this.c3431Url + '65_2021-22_Rotract_MANGO DONATION _photos.pdf'
    },
    {
      title:'SHAHADAT',
      link:this.c3431Url + '66_2021-22_Rotract_SHAHADAT_photos.pdf'
    },
    {
      title:'AZADI DIVAS',
      link:this.c3431Url + '67_2021-22_Rotract_AZADI DIVAS_photos_.pdf'
    },
    {
      title:'RAKHI WITH MEN IN KHAKHI',
      link:this.c3431Url + '68_2021-22_Rotract_RAKHI WITH MEN IN KHAKHI_photos.pdf'
    },
    {
      title:'SIKSHA ',
      link:this.c3431Url + '69_2021-22_Rotract_SIKSHA _photos.pdf'
    },
    {
      title:'HEALTH CHECKUP CAMP',
      link:this.c3431Url + '70_2021-22 Health check up_photos.pdf'
    },



    {
      title:'KHUSIYO KI DIWALI',
      link:this.c3431Url + '71_2021-22_Rotract_KHUSIYO KI DIWALI_photos.pdf'
    },
    {
      title:'MASK DISTRIBUTION DRIVE',
      link:this.c3431Url + '72_2021-22_Rotract_MASK DISTRIBUTION DRIVE_photos.pdf'
    },
    {
      title:'CHRISTMAS DAY',
      link:this.c3431Url + '73_2021-22_Rotract_CHRISTMAS DAY_photos.pdf'
    },
    {
      title:'SMILE DAY',
      link:this.c3431Url + '74_2021-22_Rotract_SMILE DAY_photos.pdf'
    },
    {
      title:'NEW YEAR CELEBRATION',
      link:this.c3431Url + '75_2021-22_Rotract_NEW YEAR CELEBRATION_photos.pdf'
    },
    {
      title:'CLOTHES DONATION DRIVE',
      link:this.c3431Url + '76_2021-22_Rotract_CLOTHES DONATION DRIVE_photos.pdf'
    },
    {
      title:'BONFIRE',
      link:this.c3431Url + '77_2021-22_Rotract_BONFIRE_photos.pdf'
    },
    {
      title:'MAHADAN 7.0 FIRST CAMP',
      link:this.c3431Url + '78_2021-22_Rotract_MAHADAN 7.0 FIRST CAMP_photos.pdf'
    },
    {
      title:'MAHADAN 7.0 SECOND CAMP',
      link:this.c3431Url + '79_2021-22_Rotract_MAHADAN 7.0 SECOND CAMP_photos.pdf'
    },
    {
      title:'MASS MARRIAGE',
      link:this.c3431Url + '80_2021-22_Rotract_MASS MARRIAGE_photos.pdf'
    },



    {
      title:'ROTTI DAY',
      link:this.c3431Url + '81_2021-22_Rotract_ROTTI DAY_photos.pdf'
    },
    {
      title:'CHABEEL ',
      link:this.c3431Url + '82_2021-22_Rotract_CHABEEL _photos_.pdf'
    },
    {
      title:'COVID-19 VACCINATION CAMP ',
      link:this.c3431Url + '83_ 2021-22_YRC_COVID-19 VACCINATION CAMP_Photos1.pdf'
    },
    {
      title:'COVID-19 VACCINATION CAMP ',
      link:this.c3431Url + '84_ 2021-22_YRC_COVID-19 VACCINATION CAMP_ Photos.pdf'
    },
    {
      title:'POLIO DROP DUTY',
      link:this.c3431Url + '85_ 2021-22_YRC_POLIO DROP DUTY_photos1.pdf'
    },
    {
      title:'BLOOD,SUGAR,EYE,&HEALTH CHECKUP CAMP',
      link:this.c3431Url + '86_ 2021-22_YRC_BLOOD,SUGAR,EYE,_HEALTH CHECKUP CAMP _photos.pdf'
    },
    {
      title:'GURUPARV CELEBRATION',
      link:this.c3431Url + '87_ 2021-22_YRC_GURUPARV CELEBRATION_photos.pdf'
    },
    {
      title:'POSTER MAKING &SLOGAN WRITING COMPITITION ON THEME OF BLOOD DONATION',
      link:this.c3431Url + '88_ 2021-22_YRC_BLOOD DONATION POSTER MAKING _SLOGAN WRITING COMPITITION_photos.pdf'
    },
    {
      title:'BLOOD DONATION CAMP',
      link:this.c3431Url + '89_ 2021-22_YRC_BLOOD DONATION CAMP_photos1.pdf'
    },
    {
      title:'"ME AND MY BUDDY"(TO HELP OTHER)DURING LOCKDOWN ',
      link:this.c3431Url + '90_ 2021-22_YRC_ME AND MY BUDDY(TO HELP OTHER)DURING LOCKDOWN_photos.pdf'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
