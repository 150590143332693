import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-c6-six-two-one',
  templateUrl: './c6-six-two-one.component.html',
  styleUrls: ['./c6-six-two-one.component.scss']
})
export class C6SixTwoOneComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Code od counduct.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Divyangjan Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/E-goverance Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/E-waste Management Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Examination and Internal Assessment Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Financial Suppot for teachers.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Gender Equity Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Green Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Grievance redressal policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Library Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Research and Development Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Scholarship and freeship Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Student Welfare Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Teaching Learning Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Waste Management Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Purchase Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Admission Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Prevention and Sexual Harassment Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Anti Ragging Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Placement _Carrier Counseling Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Emargency_First Aid Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Sports Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Teacher welfare Policy.pdf',
    '/assets/pdf/NAAC/Criterion-6/6.2.1_POLICY DOCUMENTS/Hostel Policy.pdf'];
  src: string;

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.fifth').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[9];
    }

    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.ten').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.ten').removeClass('active');
      $('.thirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[11];
    }
    if (year == '13') {
      $('.thirteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.ten').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[12];
    }
    if (year == '14') {
      $('.fourteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[13];
    }
    if (year == '15') {
      $('.fifteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[14];
    }
    if (year == '16') {
      $('.sixteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[15];
    }
    if (year == '17') {
      $('.seventeen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[16];
    }
    if (year == '18') {
      $('.eighteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[17];
    }
    if (year == '19') {
      $('.nineteen').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[18];
    }
    if (year == '20') {
      $('.twenty').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[19];
    }
    if (year == '21') {
      $('.twentyone').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[20];
    }
    if (year == '22') {
      $('.twentytwo').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentythree').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[21];
    }
    if (year == '23') {
      $('.twentythree').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentyfour').removeClass('active');
      this.src = this.pdfSrc[22];
    }
    if (year == '24') {
      $('.twentyfour').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.fifth').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      $('.thhirteen').removeClass('active');
      $('.fourteen').removeClass('active');
      $('.fifteen').removeClass('active');
      $('.sixteen').removeClass('active');
      $('.ten').removeClass('active');
      $('.seventeen').removeClass('active');
      $('.eighteen').removeClass('active');
      $('.nineteen').removeClass('active');
      $('.twenty').removeClass('active');
      $('.twentytwo').removeClass('active');
      $('.twentyone').removeClass('active');
      $('.twentythree').removeClass('active');
      this.src = this.pdfSrc[23];
    }


  }
  hideLoader() {
    this.isLoading = false;
  }
}
