import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swoc',
  templateUrl: './swoc.component.html',
  styleUrls: ['./swoc.component.scss']
})
export class SwocComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
