<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria1"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>1.4.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li class="one">
                        <a (click)="changeRoute('1')">1.4.1.2 Action Taken Report on Feed Back</a>
                    </li>
                    <li class="two">
                        <a (click)="changeRoute('2')">1.4.1.3 Sample Filled in feedback forms from stakeholders</a>
                    </li>
                    <li class="three">
                        <a (click)="changeRoute('3')">1.4.1.4 Communication with the affiliating University for the Feedback</a>
                    </li>
                    <li class="four">
                        <a (click)="changeRoute('4')">1.4.1.5 Certificate by head of the HEI</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center" *ngIf="heading1">Action Taken Report on Feed Back</h5>
                            <h5 class="card-title text-center" *ngIf="heading2">Sample Filled in feedback forms from stakeholders</h5>
                            <h5 class="card-title text-center" *ngIf="heading3">Communication with the affiliating University for the Feedback</h5>
                            <h5 class="card-title text-center" *ngIf="heading4">Certificate by head of the HEI</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>