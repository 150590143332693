import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlogService } from 'src/app/services/blog/blog.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';
import { ShowBlogService } from 'src/app/services/showBlog/show-blog.service';
declare var $:any;
@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit,OnDestroy {
 link: string = '';
  id:string;
  sub:Subscription;
  constructor(private showService:ShowBlogService,
    private route:ActivatedRoute,
    private blogService:BlogService,
    private pathService:PathNameService) { }

  ngOnInit(): void {
    this.link='https://gnkc.ac.in/' + location.pathname;
    console.log(location.pathname);
    this.sub=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        this.pathService.setUrl(this.blogService.getTitle(this.id));
        $('.description').html(this.blogService.searchBlog(this.id));
      }
    )
  }
  
  ngOnDestroy(){
    this.showService.show();
    this.sub.unsubscribe();
  }

}
