import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ncc',
  templateUrl: './ncc.component.html',
  styleUrls: ['./ncc.component.scss']
})
export class NccComponent implements OnInit {
  showAlert=false;
  showSuccess=false;
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(form:NgForm){
    if(form.invalid){
      this.showAlert=true;
      return;
    }
    this.showSuccess=true;
    form.reset();
  }

}
