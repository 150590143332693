<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/dvv/criteria3" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <!-- Header -->
                <header class="header">
                    <h5>3.4.3.1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria3/3-4-3-1/Activities-Reports" (click)="checkRoute()">Extension Activities Reports</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/dvv/criteria3/3-4-3-1/Activities-Photos" (click)="checkRoute()">Extension Activities Photos</a>
                    </li>
                   
                </ul>
            </nav>
        </div>
        <!-- <div class="col-md-9" *ngIf="showHeading">
            <h4 class="text-center"> Teaching-Learning & Evaluation</h4>
        </div> -->
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>