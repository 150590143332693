import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oneeight-onenine',
  templateUrl: './oneeight-onenine.component.html',
  styleUrls: ['./oneeight-onenine.component.scss']
})
export class OneeightOnenineComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1_Extension_Activities_Reports and Attendance_Final_SSR_Folder (2)/3.4.3 2018-19/';
  result:{title:string,link:string}[]=[
    {
      title:'SWACHTTA PAKHWARA',
      link:this.c343Url + '1_NCC_SWACHTTA PAKHWARA.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c343Url + '2_NCC CLEANLINESS DRIVE.pdf'
    },
    {
      title:'SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN',
      link:this.c343Url + '3_NCC_SURGICAL STRIKE DAY AND SWACHHTA ABHIYAN.pdf'
    },
    {
      title:'DRUG CONTROL RALLY',
      link:this.c343Url + '4_NCC_DRUG CONTROL RALLY.pdf'
    },
    {
      title:'SADAK SURAKSHA PRIKSHA',
      link:this.c343Url + '5_NCC_SADAK SURAKSHA PRIKSHA.pdf'
    },
    {
      title:'BLOOD DONATION RALLY',
      link:this.c343Url + '6_NCC_BLOOD DONATION RALLY.pdf'
    },
    {
      title:'BLOOD DONATION CAMP',
      link:this.c343Url + '7_NCC_BLOOD DONATION CAMP.pdf'
    },
    {
      title:'FLAG DAY',
      link:this.c343Url + '8_NCC_FLAG DAY.pdf'
    },
    {
      title:'TRAFFIC AWARNESS RALLY',
      link:this.c343Url + '9_NCC_TRAFFIC AWARNESS RALLY.pdf'
    },
    {
      title:'WORLD WATER DAY',
      link:this.c343Url + '10_NCC_WORLD WATER DAY.pdf'
    },
    {
      title:'BETI BACHAO BETI PADHAO RALLY',
      link:this.c343Url + '11_NCC_BETI BACHAO BETI PADHAO RALLY.pdf'
    },
    {
      title:'ATC CAMP',
      link:this.c343Url + '12_NCC_ATC CAMP.pdf'
    },
    {
      title:'Helping the Poor & Needy person',
      link:this.c343Url + '13_NSS_Helping the Poor _ Needy person.pdf'
    },
    {
      title:'National level Road Safety Week',
      link:this.c343Url + '14 _NSS_National Level Road Ssfety Week.pdf'
    },
    {
      title:'State level Road Safety Seminar',
      link:this.c343Url + '15_NSS_State Level Road Safety Seminar.pdf'
    },
    {
      title:'Health and Blood Checkup Camp',
      link:this.c343Url + '16_NSS_Health and Blood Checkup Camp.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
