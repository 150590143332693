import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireList } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';
import { NCTE } from 'src/app/models/ncte.model';
import { ShowNcteService } from '../showNcte/show-ncte.service';

@Injectable({
  providedIn: 'root'
})
export class NcteService {

  ncteList:AngularFireList<any>;
  private url='https://gnkc-ynr-default-rtdb.firebaseio.com/';
  private nctes:NCTE[]=[];
  newNcte:NCTE[]=[];
  ncteChange=new BehaviorSubject<NCTE[]>([]);
  constructor(private http:HttpClient,
    private router:Router,
    private showService:ShowNcteService) { }

  fetchNcte(){
    this.http.get<NCTE[]>(this.url+'/ncte.json').subscribe(res=>{
      this.setNcte(res);
    },error=>{
      console.log(error);
    });
  }

  setNcte(ncte:NCTE[]){
    this.nctes=ncte;
    this.newNcte=Object.keys(this.nctes).map(key=>({$key:key,...this.nctes[key]}));
    this.ncteChange.next(this.newNcte.reverse());
    this.splitUrl();
    // console.log(this.newContacts);
  }

  searchNcte(id:string){
    for(let ncteItem of this.newNcte){
      if(ncteItem.path===id){
        return ncteItem.link;
      }
    }
  }
  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length===4){
      this.showService.hide();
      this.router.navigate(['/menu-detail/ncte/'+url[3]]);

    }
    
  }
}
