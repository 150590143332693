<div id="audio">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Audio Visual Hall</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The college boasts of a very well furnished Audio Visual Hall, equipped with 65" 4K TV and
                            sound system, to provide educational, motivatonal and entertaining content to make students
                            ready for the society.
                        </p>
                        <p class="lead text-justify">
                            Equipped with a seating capacity of around 200 it is furnished with a wide projection
                            screen, LCD projector, Sound system, computer, audio video terminals, a small stage, podium,
                            chairs and a hall .
                            The hall is used for conferences, seminars, workshops, official meetings and also digitally
                            supported classes.The ambience of the hall provides an academic environment for multimedia
                            assisted learning and Interactive learning sessions.
                            The AV Hall also allows group activities and thereby imparts attainment of group dynamics
                            among students. Interactive learning sessions are held in the audio visual room which is
                            well equipped with a LCD Projector and a large screen.
                        </p>
                        <p class="lead text-justify">
                            The college provides training to its teachers in audio visual methods to introduce new
                            teaching techniques to keep them updated.
                        </p>
                        <h5 class="title">Competitive Exam Corner</h5>
                        <hr class="pb-1 rounded mt-0">
                        <p class="lead text-justify">
                            To make the students contentious for various prestigious competitions, the college has a
                            well stocked library with latest journals, magazines and E content where students prepare fo
                            Civil Service Exams, NET, GATE, SSC
                        </p>
                        <p class="lead text-justify">
                            The specific aim of the Centre is to give insights of MPSC, UPSC and other competitive
                            examinations to the students.
                            The Centre endeavors to accomplish the following objectives in the light of the aim
                            specified.
                        </p>
                        <ul>
                            <li>
                                <p class="lead text-justify">To spread awareness about competitive examinations among
                                    the students (living in rural, remote and hilly area where the college is located),
                                    and to create and sustain their interest in the respective field.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">To introduce the students the nature of different
                                    competitive examinations, its syllabi, various advertisements, the structure of
                                    examinations etc.
                                </p>
                            </li>
                        </ul>
                        <p class="lead text-justify">The reading room equipped with large number of competitive
                            examination books such as current affairs, General knowledge, periodicals, Newspaper etc.
                            It has been setup of competitive exam books like UPSC, MPSC, and Banking examination.
                            Students who would like to prepare for the MPSC, UPSC, Banking and Staff Selection
                            Commission and other examinations can register their name in the centre.
                            Students are always encouraged to use this facility.
                        </p>
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-12 mt-3">
                                <img src="/assets/img/audio/1.JPG" alt="audio" class="img-fluid rounded">
                            </div>

                        </div>
                        
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>