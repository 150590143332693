import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-propectus',
  templateUrl: './propectus.component.html',
  styleUrls: ['./propectus.component.scss']
})
export class PropectusComponent implements OnInit {
  pdfSrc:string[]=[
    '/assets/pdf/Prospectus-24.pdf',
    '/assets/pdf/Prospectus-23.pdf',
    '/assets/pdf/Prospectus-22.pdf',
    '/assets/pdf/Prospectus-21.pdf',
    '/assets/pdf/Prospectus-20.pdf',
    '/assets/pdf/Prospectus-19.pdf',
    '/assets/pdf/Prospectus-18.pdf',
  ];
  src:string;

  isLoading=true;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('2024');
  }

  changeRoute(year:string){
    this.isLoading=true;
    if(year=='2024'){
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[0];
    }
    if(year=='2023'){
      $('.first').removeClass('active');
      $('.second').addClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[1];
    }
    if(year=='2022'){
      $('.second').removeClass('active');
      $('.first').removeClass('active');
      $('.third').addClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[2];
    }
    if(year=='2021'){
      $('.third').removeClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').addClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[3];
    }
    if(year=='2020'){
      $('.fourth').removeClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fifth').addClass('active');
      $('.sixth').removeClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[4];
    }
    if(year=='2019'){
      $('.fifth').removeClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.third').removeClass('active');
      $('.sixth').addClass('active');
      $('.seven').removeClass('active');
      this.src=this.pdfSrc[5];
    }
    if(year=='2018'){
      $('.fifth').removeClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.third').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seven').addClass('active');
      this.src=this.pdfSrc[6];
    }

  }
  hideLoader(){
    this.isLoading=false;
  }

}
