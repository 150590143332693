import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course/course.service';
declare var $:any;
@Component({
  selector: 'app-physical-course',
  templateUrl: './physical-course.component.html',
  styleUrls: ['./physical-course.component.scss']
})
export class PhysicalCourseComponent implements OnInit {
  physicalData:{name:string,sem1:string[],sem2:string[],creteria1:string,creteria2:string}[];
  physicalpo:string[]=[];
  constructor(private courseService:CourseService) { }

  ngOnInit(): void {
    this.physicalData=this.courseService.getphysicalData();
    this.physicalpo=this.courseService.getPhysicalpo();
  }

  setModal(i:number){
    let header=document.getElementById('courseModelTitle');
      header.innerHTML=this.physicalData[i].name;
      let creteria1=document.querySelector('.creteria1');
      creteria1.innerHTML=this.physicalData[i].creteria1;
      $('.sem1').html(this.physicalData[i].sem1[0]);
      if(i==0){ 
        $('.sem1b').hide();
        $('.sem2b').hide();
        $('.se1b-detail').hide();
      }
      if(i==1){
        $('.sem1b').show();
        $('.sem2b').show();
        $('.se1b-detail').show();
        document.querySelector('.sem1b1').innerHTML=this.physicalData[i].sem1[0];
      document.querySelector('.sem1b2').innerHTML=this.physicalData[i].sem1[1];
      document.querySelector('.sem1b3').innerHTML=this.physicalData[i].sem1[2];
      document.querySelector('.se1b-detail1').innerHTML=this.physicalData[i].sem1[3];
      document.querySelector('.se1b-detail2').innerHTML=this.physicalData[i].sem1[4];
      document.querySelector('.se1b-detail3').innerHTML=this.physicalData[i].sem1[5];
      document.querySelector('.sem2b1').innerHTML=this.physicalData[i].sem2[0];
      document.querySelector('.sem2b2').innerHTML=this.physicalData[i].sem2[1];
      document.querySelector('.sem2b3').innerHTML=this.physicalData[i].sem2[2];
      document.querySelector('.sem2b4').innerHTML=this.physicalData[i].sem2[3];
      }
      
      // document.querySelector('.sem1').innerHTML=this.physicalData[i].sem1[0];
      

      // document.querySelector('.creteria2').innerHTML=this.physicalData[i].creteria2;
      // let po=document.querySelector('.po-pso');
      // po.innerHTML=this.physicalpo[i];
      


  }

}
