import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  education:Faculty[];
  constructor(private facultyService:FacultyService) { }

  ngOnInit(): void {
    this.education=this.facultyService.getEducation();
  }

}
