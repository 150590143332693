import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-student-survey',
  templateUrl: './student-survey.component.html',
  styleUrls: ['./student-survey.component.scss']
})
export class StudentSurveyComponent implements OnInit {
  data: { Serial: string, component: string, agree: string, disagree: string, extent: string }[] = [
    {
      Serial: "1",
      component: "Availability of Various Subjects",
      agree: "91.96",
      disagree: "1.27",
      extent: "6.77"
    },
    {
      Serial: "2",
      component: "Teachers Help in Choosing Subjects",
      agree: "86.83",
      disagree: "4.23",
      extent: "8.94"
    },
    {
      Serial: "3",
      component: "Subjects Help in Employability",
      agree: "76.74",
      disagree: "6.34",
      extent: "16.92"
    },
    {
      Serial: "4",
      component: "Regularity in Classes",
      agree: "91.18",
      disagree: "3.44",
      extent: "5.38"
    },
    {
      Serial: "5",
      component: "PPT and Chalk & Talk Methods of Teaching",
      agree: "58.49",
      disagree: "23.63",
      extent: "17.89"
    },
    {
      Serial: "6",
      component: "Books Facilities in Library",
      agree: "71.66",
      disagree: "12.51",
      extent: "15.83"
    },
    {
      Serial: "7",
      component: "Facilities like NSS, NCC and Others",
      agree: "93.6",
      disagree: "1.75",
      extent: "4.65"
    },
    {
      Serial: "8",
      component: "Camps by Training and Placement Cell",
      agree: "73.17",
      disagree: "9.31",
      extent: "17.52"
    },
    {
      Serial: "9",
      component: "Security in Campus",
      agree: "84.23",
      disagree: "5.38",
      extent: "10.39"
    },
    {
      Serial: "10",
      component: "Cultural Activities",
      agree: "85.56",
      disagree: "4.77",
      extent: "9.67"
    },
    {
      Serial: "11",
      component: "Sports Facilities",
      agree: "81.63",
      disagree: "7.25",
      extent: "11.12"
    },
    {
      Serial: "12",
      component: "Fee Concessions & Scholarships",
      agree: "77.82",
      disagree: "8.82",
      extent: "13.35"
    },
    {
      Serial: "13",
      component: "Teaching Methodology",
      agree: "90.09",
      disagree: "2.05",
      extent: "7.85"
    },
    {
      Serial: "14",
      component: "Grievances Redressal",
      agree: "85.62",
      disagree: "4.53",
      extent: "9.85"
    },
    {
      Serial: "15",
      component: "Seating Arrangement",
      agree: "84.41",
      disagree: "6.22",
      extent: "9.37"
    },
    {
      Serial: "16",
      component: "Teaching Sta? Availability",
      agree: "92.39",
      disagree: "2.54",
      extent: "5.08"
    },
    {
      Serial: "17",
      component: "Student Attendance & Session Exam",
      agree: "90.27",
      disagree: "2.78",
      extent: "6.95"
    },
    {
      Serial: "18",
      component: "Moral Education & Community Functions",
      agree: "83.75",
      disagree: "4.65",
      extent: "11.6"
    },
    {
      Serial: "19",
      component: "Computer Awareness & Environmental Studies",
      agree: "76.92",
      disagree: "7.92",
      extent: "15.17"
    },
    {
      Serial: "20",
      component: "Employment Information",
      agree: "72.51",
      disagree: "10.39",
      extent: "17.1"
    }
  ];

  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1jy4HSc8jceh6-fHmwxln92T2yI5TCiHL/preview',];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('2022-23');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '2022-23') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
      this.show22 = true;
    }
    if (year == '2021-22') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.src = this.pdfSrc[1];
      this.show22 = false;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

}
