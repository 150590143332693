<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-md-3" >
            <a routerLink="/naac/ssr/criteria6/6-3"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation">
                Header
                <header class="header">
                    <h5>6.3.2</h5>
                </header>
                /Header
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria6/6-3/6-3-2/6-3-2-1" >6.3.2.1</a>
                    </li>
                    <li class="first">
                        <a (click)="changeRoute('finance')">Financial support to teachers certificate</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div class="container-fluid pdf">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <h5 class="card-title text-center">Financial support to teachers certificate</h5>
                            <div id="detail" class="text-center">
                                <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div> -->

<div class="container-fluid pdf">
    <div class="row">
        <div class="col-12">
            <div class="card card-body">
                <h5 class="card-title text-center">Financial Support For Teachers</h5>
                <div id="detail" class="text-center">
                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</div>