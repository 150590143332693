import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-second-performance',
  templateUrl: './second-performance.component.html',
  styleUrls: ['./second-performance.component.scss']
})
export class SecondPerformanceComponent implements OnInit {
  constructor(private showcaseService:ShowcaseService) { 
    showcaseService.hideShowcase();
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
