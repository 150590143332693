<div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>Students can choose any one of these courses alongside their regular degree course.
          Each course is of 30 hours duration.
      </h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/40.svg">
                    <h3>Financial Services</h3>

                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>Financial Services</p>
                        <a class="btn btn-block"
                        (click)="setModal('Financial Services','https://drive.google.com/file/d/1cKK0ZM2pG_UjtSynsgOMpG9UtJ3H_8gr/preview')"
                        data-toggle="modal" data-target="#courseModel">Read More</a>
                </div>
            </div>
        </div>
        </div>
        <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/41.svg">
                      <h3>Digital Marketing</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Digital Marketing</p>
                          <a class="btn btn-block"
                          (click)="setModal('Digital Marketing','https://drive.google.com/file/d/1OsHyky6wOxKYK2sjBlCOA65lAtYnmelB/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/42.svg">
                      <h3>Hygiene and Fitness</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Hygiene and Fitness</p>
                          <a class="btn btn-block"
                          (click)="setModal('Hygiene and Fitness','https://drive.google.com/file/d/10kGyHrUGC_iQxbVtdJQCaWzHEPt5r0AY/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/43.svg">
                      <h3>Yoga</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Yoga</p>
                          <a class="btn btn-block"
                          (click)="setModal('Yoga','https://drive.google.com/file/d/1em_TrsC0DtGx3RAfUhXnH21lq1TJIABh/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/44.svg">
                      <h3>E-learning</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>E-learning</p>
                          <a class="btn btn-block"
                          (click)="setModal('E-learning','https://drive.google.com/file/d/1i4nAQXZQ5hBYG3d6o9JI8XeN4x457aiR/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/45.svg">
                      <h3>Computer Repair</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Computer Repair</p>
                          <a class="btn btn-block"
                          (click)="setModal('Computer Repair','https://drive.google.com/file/d/1xfRfxNBUcA8wmmHPj-LyTzljSBqkG8MD/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/46.svg">
                      <h3>Library Restorer</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Library Restorer</p>
                          <a class="btn btn-block"
                          (click)="setModal('Library Restorer','https://drive.google.com/file/d/1mENS_joJpgjSkKJr48aKoUQVdGgltZV6/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/47.svg">
                      <h3>Human Rights</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Human Rights</p>
                          <a class="btn btn-block"
                          (click)="setModal('Human Rights','https://drive.google.com/file/d/1CU4_KKpzWwFUot6EdzXHlBtz8YbvQTHm/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/3.svg">
                      <h3>Medical Lab Technician</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Medical Lab Technician</p>
                          <a class="btn btn-block"
                          (click)="setModal('Medical Lab Technician','https://drive.google.com/file/d/1UqEqZDRnf9sapNyIeVDN_aFAQUcI0QSe/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/48.svg">
                      <h3>Networking</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Networking</p>
                          <a class="btn btn-block"
                          (click)="setModal('Networking','https://drive.google.com/file/d/1AS2c1QUnvQVdQ3ZKeRe-Ey_sYemzFEFq/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/56.svg">
                      <h3>Home Electrician</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Home Electrician</p>
                          <a class="btn btn-block"
                          (click)="setModal('Home Electrician','https://drive.google.com/file/d/10MAP8rfNz84RaltlH-RJUdd45ih69tjZ/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/57.svg">
                      <h3>Geography and Remote Sensing</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Geography and Remote Sensing</p>
                          <a class="btn btn-block"
                          (click)="setModal('Geography and Remote Sensing','https://drive.google.com/file/d/1Eq9AiOnyLpiF7oHJqcTtzouAmyEtYVjD/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/58.svg">
                      <h3>Nutrition and Health</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Nutrition and Health</p>
                          <a class="btn btn-block"
                          (click)="setModal('Nutrition and Health','https://drive.google.com/file/d/1bOugodzPUqyNg_-uiK5yngNKwRHKFkHy/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/59.svg">
                      <h3>Gender Sensitization</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Gender Sensitization</p>
                          <a class="btn btn-block"
                          (click)="setModal('Gender Sensitization','https://drive.google.com/file/d/16ZtlnhmC9_Fihf8diOj8ASfJRiLp91j2/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

          <div class="col-md-12 col-xl-6 mb-3">
            <div class="card">
              <div class="face face1 text-center mx-auto">
                  <div class="content">
                      <img src="/assets/svg/courses/60.svg">
                      <h3>Public Relations Management</h3>
                  </div>
              </div>
              <div class="face face2 mx-auto">
                  <div class="content">
                      <p>Public Relations Management</p>
                          <a class="btn btn-block"
                          (click)="setModal('Public Relations Management','https://drive.google.com/file/d/1PKhyEKSJ2Jga0Db1OsW5CQubmRabQiv5/preview')"
                          data-toggle="modal" data-target="#courseModel">Read More</a>
                  </div>
              </div>
          </div>
          </div>

      </div>
    </div>
    
    
   
    
  </div>
 
  
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addTitle"></h5>
          <button type="button" (click)="clearLink()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <iframe id="courseItem" width="100%" height="480" allow="autoplay"></iframe>

        </div>
        <div class="modal-footer">
          <button type="button" (click)="clearLink()" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>