import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-one-two-two-two',
  templateUrl: './ct-one-two-two-two.component.html',
  styleUrls: ['./ct-one-two-two-two.component.scss']
})
export class CtOneTwoTwoTwoComponent implements OnInit {
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
      title:'2021-22_Attendance and Signature of students of add-on course',
      link:this.c241Url + '1.2.2.2_2021-22_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2020-21_Attendance and Signature of students of add-on course',
      link:this.c241Url + '1.2.2.2_2020-21_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2019-20_Attendance and Signature of students of add-on course',
      link:this.c241Url + '1.2.2.2_2019-20_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2018-19_Attendance and Signature of students of add-on course',
      link:this.c241Url + '1.2.2.2_2018-19_Attendance and Signature of students of add-on course.pdf'
    },
    {
      title:'2017-18_Attendance and Signature of students of add-on course',
      link:this.c241Url + '1.2.2.2_2017-18_Attendance and Signature of students of add-on course.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
