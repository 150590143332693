import { Component, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-geography',
  templateUrl: './geography.component.html',
  styleUrls: ['./geography.component.scss']
})
export class GeographyComponent implements OnInit {
  geography:Faculty[];
  constructor(
    private facultyService:FacultyService
  ) { }

  ngOnInit(): void {
    this.geography=this.facultyService.getGeography();
  }

}
