<div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>Diploma Courses (One Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/37.svg">
                    <h3>Diploma in Retail Mgmt.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>Diploma in Retail Mgmt. </p>
                    <!-- <p>Seats 50</p> -->
                        <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/54.svg">
                    <h3>Diploma in Banking and Financial Services.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>Diploma in Banking and Financial Services. </p>
                    <!-- <p>Seats 50</p> -->
                        <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    
    
   
    
  </div>
 
  
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="title"></h5>
          <h3 class="creteria1"></h3>
          <p class="sem1"></p>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>