import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-khelo',
  templateUrl: './khelo.component.html',
  styleUrls: ['./khelo.component.scss']
})
export class KheloComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
