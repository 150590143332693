<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/ssr" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <!-- Header -->
                <header class="header">
                    <h5>CRITERIA 2</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/ssr/criteria2/2-1" (click)="checkRoute()">2.1</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-2" (click)="checkRoute()">2.2</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-3" (click)="checkRoute()">2.3</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-4" (click)="checkRoute()">2.4</a>
                    </li>
                    <!-- <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-5" (click)="checkRoute()">2.5</a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-6" (click)="checkRoute()">2.6</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/naac/ssr/criteria2/2-7" (click)="checkRoute()">2.7</a>
                    </li>
                   
                </ul>
            </nav>
        </div>
        <div class="col-md-9" *ngIf="showHeading">
            <h4 class="text-center"> Teaching-Learning & Evaluation</h4>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>