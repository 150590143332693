<div id="showcase">
    <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
</div>
<div id="heading">
    <h1>Faculty</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item"><a routerLink="/faculty">Faculty</a></li>
          <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Teaching Staff</li>
        </ol>
      </nav>
</div>

<div class="container-fluid mt-4 px-5">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="info-card mx-auto">
                <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/1.jpg" alt="" />
                </div>
                  
                <div class="city-text mx-2">
                   <h1>Humanities</h1>
                </div>
              
                
                <div class="stats">
                  <ul>
                    <li>
                      <p class="heading">“We cannot despair of humanity, since we ourselves are human beings.”</p>
                      <!-- <p class="footing">USA</p> -->
                    </li>
                    <!-- <li>
                      <p class="heading">Districts</p>
                      <p class="footing">97</p>
                    </li>
                    <li>
                      <p class="heading">Population</p>
                      <p class="footing">18,231,987</p>
                    </li> -->
                  </ul>
                  <div class="btn" routerLink="/faculty/humanities/english">Click Here</div>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="info-card mx-auto">
                <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/2.jpg" alt="" style="filter: brightness(.6);"/>
                </div>
                  
                <div class="city-text mx-2">
                   <h1>Science</h1>
                </div>
              
                
                <div class="stats">
                  <ul>
                    <li>
                      <p class="heading">“The science of today is the technology of tomorrow.”</p>
                    </li>
                  </ul>
                  <div class="btn" routerLink="/faculty/science/botony">Click Here</div>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="info-card mx-auto">
                <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/3.jpg" alt="" style="filter: brightness(.6);"/>
                </div>
                  
                <div class="city-text mx-2">
                   <h1>Commerce</h1>
                </div>
              
                
                <div class="stats">
                  <ul>
                    <li>
                      <p class="heading">"Amazing things will happen when you listen to the consumer.” </p>
                    </li>
                  </ul>
                  <div class="btn" routerLink="/faculty/commerce/commerce&management">Click Here</div>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="info-card mx-auto">
                <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/4.jpg" alt="" style="filter: brightness(.6);"/>
                </div>
                  
                <div class="city-text mx-2">
                   <h1>Sports Sciences</h1>
                </div>
              
                
                <div class="stats">
                  <ul>
                    <li>
                      <p class="heading">“Age is no barrier. It’s a limitation you put on your mind.” </p>
                    </li>
                  </ul>
                  <div class="btn" routerLink="/faculty/sports-sciences/health-education">Click Here</div>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
          <div class="info-card mx-auto">
              <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/6.jpg" alt="" style="filter: brightness(.6);"/>
              </div>
                
              <div class="city-text mx-2">
                 <h1>Education</h1>
              </div>
            
              
              <div class="stats">
                <ul>
                  <li>
                    <p class="heading">“Education is not preparation for life; education is life itself.” </p>
                  </li>
                </ul>
                <div class="btn" routerLink="/faculty/education/education-bed">Click Here</div>
              </div>
            </div>
      </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="info-card mx-auto">
                <div class="bg-img"><img src="/assets/img/faculty/teaching-staff/5.jpg" alt="" style="filter: brightness(.8);"/>
                </div>
                  
                <div class="city-text mx-2">
                   <h1>Community College</h1>
                </div>
              
                
                <div class="stats">
                  <ul>
                    <li>
                      <p class="heading">“Education is the most powerful weapon which you can use to change the world.” </p>
                    </li>
                  </ul>
                  <div class="btn" routerLink="/faculty/community">Click Here</div>
                </div>
              </div>
        </div>
    </div>
</div>
