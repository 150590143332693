<div id="digital">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Digital Library</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            GNKC Library is a creative partner in teaching, learning, research and extension activities, which contributes to the attainment of institute's motto "From Ignorance to Enlightenment".
                        </p>
                        <p class="lead text-justify">
                            The library has referencing and lending sections with facilities like Reading rooms, E-Learning room, etc.
                             All Routine Library activities including the OPAC, Circulation and Acquisition have been computerized using an Integrated Library Management Software (KOHA).
                               We also have access to electronic resources like INFLIBNET and DELNET.
                             Well furnished, air conditioned, sound proof, lecture recording  studio, with proper acoustics to record E-content for students.
                        </p>
                        <div id="digCarousel" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#digCarousel" data-slide-to="0" class="active"></li>
                              <li data-target="#digCarousel" data-slide-to="1"></li>
                              <li data-target="#digCarousel" data-slide-to="2"></li>
                            
                            </ol>
                            <div class="carousel-inner text-center py-5 px-3">
                              <div class="carousel-item active">
                                <img src="/assets/img/digital/1.JPG" alt="digital" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/digital/2.JPG" alt="digital" class="img-fluid rounded">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/img/digital/3.JPG" alt="digital" class="img-fluid rounded">
                              </div>
                             
                            </div>
                            <a class="carousel-control-prev" href="#digCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#digCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>