<div id="boys">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Boys-Common-Room</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The college has spacious boys’ common room with a seating capacity of 100 students and carpet area is 2000 sq. ft.
                             The common room has all the modern facilities including LED TV, water cooler and ideal furniture to sit.
                              Students can recreate and enrich by watching current events and studying newspapers and magazines.
                               The indoor games are available on demand from attendant. Canteen service is available on telephonic request for which an intercom is provided.
                             The college has future plan to add one more separate common room in the other building.</p>
                             <div id="boysCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#boysCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#boysCarousel" data-slide-to="1"></li>
                                
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/boys/1.jpeg" alt="boys" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/boys/2.jpeg" alt="boys" class="img-fluid rounded">
                                  </div>
                                 
                                </div>
                                <a class="carousel-control-prev" href="#boysCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#boysCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                         <!-- <div class="row">
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/boys/1.jpeg" alt="boys" class="img-fluid rounded">
                            </div>
                            <div class="col-sm-6  mt-3">
                                <img src="/assets/img/boys/2.jpeg" alt="boys" class="img-fluid rounded">
                            </div>
                            
                           
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>