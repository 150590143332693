<div id="publication">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Publications of Research papers</h4>
                    <div class="card-body text-justify">
                        <h5>Dr. Gian Bhushan, Assistant Professor of Zoology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Avian Biodiversity in and Around Sukhna Lake, Chandigarh”</strong>  the book of Indian Birds, Bombay Natural History Society.</p>
                            </li>
                        </ul>

                        <h5>Dr. Neena Puri, Assistant Prof. of Industrial Microbiology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">N. Kumar, Neena Puri, F. Marotta, F. Dhewa, S. Calabro, M. Puniya, J. Carter (2017), <strong>Diabesity: An Epidemic with its Causes, Prevention an Control with Special Focus on Dietary Regime</strong> , Review, p. 7, J. Functional Food in Health and Disease 7(1) : 1-16, ISSN : 2160-3855.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">D. Kumar, S. Angural, M. Rana, G. Kaur, Neena Puri, N. Gupta (2017), <strong>Cloning Characteristics and its Potential in pulp Biobleaching</strong>  by Alkali Thermostable -mannanose from Bacillus p. 22. European Journal of Pharmaceutical and Medical Research, 4(9): 559-569, p. 9-9J, Impact Factor 4.161, ISSN: 2394-3211.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">N. Gupta, Neena Puri, N. Sharma, S. Sharma, K. Kaur (2017). <strong>Statistical Optimization of Physical and Nutritional Factor for Laccase Production by Environmental Fungal Isolate NS-1</strong> , European Journal of Pharmaceutical and Medical Research 4(9) : 559-569. , Impact Factor 4.161, ISSN: 2394-3211.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Kumar, Laksh, S. Angural, D. Kumar, N. Gupta, Neena Puri (2017), <strong>A Statistical Approach for a Chitinase Production by Bacillus sp. and its antifungal potential</strong> , International Journal of Engineering and Scientific Research 5(10: 28- 47, Impact factor 6-7; ISSN: 2347-6532.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Aditi, D., P.S. Chauhan, A. Kumar, S. Angural, D. Kumar, Neena Puri, N. Gupta (2018). <strong> Co-production of Protease and Mannanase from B. nealsonii PN-11, in SSF and their Combined Application as Detergent Additive</strong>, p. 11, 11a, Internal Journal of Biological Macromolecules, 103: 1176-1134. Impact Factor 3.671, ISSN: 0141-8130</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Served as Reviewer <strong>Glucosinolates and Isothiocyonate from Brocolli seed extract suppresses protein Glycation and Carbonylation</strong> , Bioactive Food in Health and Disease, Internal Journal (print), p. 12, 12a, 09.12.2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Served as Reviewer <strong> Effect of Enzyme Hydrolysate Processes on Protein and b- glucan Content of Song-yod Rice Bran Hydrolysate and their Anti- inflammatory Activity by RAW 264.7 cells</strong> Functional Food in Health and Disease, Internal Journal (print), p. 13, 13a, 28.10.2017.</p>
                            </li>
                        </ul>
                        

                        <h5>Dr. Neelam Behal, Assistant Professor of Zoology</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Some Medicinal Plants with Antidiabetic Activity – A Review”</strong>  in International Journal of Science and Research, Vol. 6, Issue 12, December 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Diabetes and Medicinal Plants : “A Promise for Today and Tomorrow”</strong>. Original Research Paper, Vol. 7, Issue 4, April 2018, ISSN No. 2277-8179 Impact Factor 4.758.</p>
                            </li>
                           
                        </ul>

                        <h5>Mr. Kiranpal Singh Virk, Assistant Prof. of Computer Science</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Comparative Review of Statistical Analysis Programs”</strong>, International Journal of Innovations in Applied Sciences & Engineering, e-ISSN:2454-9258, Vol. 003, Jan.-Dec. 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“History of Software Engineering: Status of Software Component, Reusability and Quality”,</strong>  CSI (Computer Society of India) Communication, ISSN: 0970-647X, Vol. 40, Issue 12, March 2017.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Rai M., Virk K.S. (2018), <strong>Software Component Quality Models : A Survey,</strong>  In: Singh R., Choudhury S., Gehlot A. (eds.) Intelligent Communication, Control and Devices. Advances in Intelligent Systems and Computing, Vol. 624, Springer, Singapore. pp 247-255. https://link.springer.com/chapter/10.1007/978-981-10- 5903-2_27</p>
                            </li>
                        </ul>

                        <h5>Dr. Anurag, Associate Prof. of History</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Intellectual Contribution of Abdul Qadir Badaunt”</strong>  in DAV Academic Review, A Refereed Research Journal, Vol. 1, Issue 2, Dec. 2016, ISSN No. 2455-3999.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“History: Meaning and Scope, Development of Historical Writings and Recent Trends”</strong>  in Asian Journal of Multidimensional Research, A Refereed Research Journal, Vol. 6, Issue 7, July 2017, ISSN No. 2278-4853, IF: SJIF=4.708.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Shekh Ahmed Sirhindi ke Adheen Muslim Punrudhar Andolan: Ek Alochnatmak Adhyan”</strong>  in Hindu, An International Refereed Research Journal, Issue 15, July 2017, ISSN No. 2348-0114, IF: 2.632.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Shekh Abul Fazal ka Baudhik-Sahitik Yogdan”</strong>  in International Research Journal of Human Resource and Social Sciences, Issue 7, Vol. 4, July 2017, ISSN No. 2394-4218, IF: 5.414.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Bharat Mein Islam ka Agman aur Bhartiya Smaj par Iske Prabhav: Ek Alochnatmak Adhyan”</strong>  in Journal of Advances and Scholarly Research in Allied Education, Issue 2, Vol. 13, July 2017, ISSN No. 2230-7540.</p>
                            </li>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Madhyakalin Bharat mein Nagron ka Vikas, Swroop evam Vargikaran 1206-1707: Ek Adhyan”</strong>  in International Journal of Research in Economics and Social Sciences (IJRESS), Issue 6, Vol. 7, June 2017, pp. 403-412, ISSN No. 2249-7382, IF: 6.939.</p>
                            </li>
                        </ul>

                        <h5>Dr. Vinay Kumar, Assistant Prof. of Hindi</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a paper on the topic <strong>“Samkalin Kavita aur Nirala ka Sanskritik Drishtikon” in Upstream Research International Journal,</strong>  a Peer Reviewed Refereed Indexed Journal, Issue I, Vol. VI, January 2018, pp. 8793, ISSN No. 2320-768X, IF: 1.113.</p>
                            </li>
                        </ul>

                        <h4 class="card-title text-center">Publications of Research papers</h4>
                        <br>
                        <h5>Dr. Vinay Kumar, Assistant Prof. of Hindi</h5>
                        <hr>
                        <ul>
                            <li>
                                <p class="lead text-justify">Published a book entitled <strong>“Madhya Varg – Ashk ki Kalam Se”</strong>  Earth Publications, Gurugram,</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>