
    <div id="showcase" >
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading" >
        <h1>Gallery</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Gallery</li>
            </ol>
          </nav>
    </div>


<div id="gallery">
    <div class="container">
        <div id="gallery-title" class="text-center">
            <h2>OUR GALLERY</h2>
            <hr class="pb-2">
        </div>
        <div class="row" *ngIf="showList">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let image of filterImage;let i = index">
                <div class="card" id="{{image.$key}}"  (click)="openGalleryList(image.$key,image.caption)">
                    <img class="card-img-top" [src]="image.imgUrl" alt="Card image cap">

                    <div class="overlay card-body">
                        <p>{{image.caption}}</p>
                    </div>
                </div>
               
            </div>
        </div>

        <!-- <div *ngIf="!showList">
            <router-outlet></router-outlet>
        </div> -->
       

    </div>
</div>

<!-- [routerLink]="['/gallery',image.caption]" -->