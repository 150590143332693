<div id="media">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="font-weight-bold" > Press & Media</h2>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <div class="row">
           <div class="col-12 col-md-4 col-lg-3" *ngFor="let item of media">
               <div class="card shadow-lg mx-auto">
                   <img [src]="item.imgUrl" alt="" class="img-fluid">
                   <div class="card-body">
                    <a>{{item.caption}}</a>
                </div>
                    <div class="overlay">
                        <a class="btn " routerLink="/media">View All</a>
                    </div>
            </div>
           </div>
        </div>
    </div>
</div>