import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-five-one-one',
  templateUrl: './ct-five-one-one.component.html',
  styleUrls: ['./ct-five-one-one.component.scss']
})
export class CtFiveOneOneComponent implements OnInit {
  // pdfSrc: string[] = [
  //   'https://drive.google.com/file/d/1GfLWt9JXxu8hCKj-4gVIMwAlnYW2Kiu4/preview', 
  //   'https://drive.google.com/file/d/1E6LK3shTZ9DNIcs8ztx1g7cihgks3e_t/preview' ,
  //   'https://drive.google.com/file/d/1lvaXBxYQJk04yOauPETBUMbRJ35Tkyx9/preview', 
  //   'https://drive.google.com/file/d/1Mu8KZAK8MGfUneWQGtecAuI5109NQEDo/preview'];
  // src: string;
  heading1=false;
  heading2=false;
  heading3=false;
  heading4=false;
  heading5=false;
  c241Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result1:{title:string,link:string}[]=[
    {
      title:'5.1.1_2021-22_Sanction letters of scholarships',
      link:this.c241Url + '5.1.1_2021-22_Sanction-letters-of-scholarships.pdf'
    },
    {
      title:'5.1.1_2020-21_Sanction letters of scholarships',
      link:this.c241Url + '5.1.1_2020-21_Sanction-letters-of-scholarships.pdf'
    },
    {
      title:'5.1.1_2019-20_Sanction letters of scholarships',
      link:this.c241Url + '5.1.1_2019-20_Sanction-letters-of-scholarships.pdf'
    },
    {
      title:'5.1.1_2018-19_Sanction letters of scholarships',
      link:this.c241Url + '5.1.1_2018-19_Sanction-letters-of-scholarships.pdf'
    },
    {
      title:'5.1.1_2017-18_Sanction letters of scholarships',
      link:this.c241Url + '5.1.1_2017-18_Sanction-letters-of-scholarships.pdf'
    }
  ]

  result2:{title:string,link:string}[]=[
    {
      title:'5.1.1_Scholarship and freeship documents proofs for Goverment scheme_2021-22',
      link:this.c241Url + '5.1.1_Scholarship-and-freeship-documents-proofs-for-Goverment scheme_2021-22.pdf'
    },
    {
      title:'5.1.1_Scholarship and freeship documents proofs or Goverment scheme_2020-21',
      link:this.c241Url + '5.1.1_Scholarship-and-freeship-documents-proofs-or-Goverment-scheme_2020-21.pdf'
    },
    {
      title:'5.1.1_Scholarship and freeship documents proofs for Goverment scheme_2019-20',
      link:this.c241Url + '5.1.1_Scholarship-and-freeship-documents-proofs-for-Goverment-scheme_2019-20.pdf'
    },
    {
      title:'5.1.1_Scholarship and freeship documents proofs for Goverment scheme_2018-19',
      link:this.c241Url + '5.1.1_Scholarship-and-freeship-documents-proofs-for-Goverment-scheme_2018-19.pdf'
    },
    {
      title:'5.1.1_Scholarship and freeship documents proofs for Goverment scheme_2017-18',
      link:this.c241Url + '5.1.1_Scholarship-and-freeship-documents-proofs-for-Goverment-scheme_2017-18.pdf'
    }
  ]

  result3:{title:string,link:string}[]=[
    {
      title:'5.1.1_2021-22_Non Goverment scheme',
      link:this.c241Url + '5.1.1_2021-22_Non-Goverment-scheme.pdf'
    },
    {
      title:'5.1.1_2020-21_Non Goverment scheme',
      link:this.c241Url + '5.1.1_2021-22_Non-Goverment-scheme'
    },
    {
      title:'5.1.1_2019-20_Non Goverment scheme',
      link:this.c241Url + '5.1.1_2021-22_Non-Goverment-scheme.pdf'
    },
    {
      title:'5.1.1_2018-19_Non Goverment scheme',
      link:this.c241Url + '5.1.1_2021-22_Non-Goverment-scheme'
    },
    {
      title:'5.1.1_2017-18_Non Goverment scheme',
      link:this.c241Url + '5.1.1_2021-22_Non-Goverment-scheme.pdf'
    }
  ];
  result4:{title:string,link:string}[]=[
    {
      title:'5.1.1_Data template link_2',
      link:this.c241Url + '5.1.1_Data template link_2.pdf'
    }
  ];
  result5:{title:string,link:string}[]=[
    {
      title:'5.1.1_Policy Documents non Govt. scholarship JAI CSR and HEI_4',
      link:this.c241Url + '5.1.1_Policy Documents non Govt. scholarship JAI CSR and HEI_4.pdf'
    }
  ]

  result:{title:string,link:string}[] = [];

  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.result = this.result1;
      this.heading1=true;
      this.heading2=false;
      this.heading3=false;
      this.heading4=false;
      this.heading5=false;
    }
    if (id === '2') {
      $(`two`).addClass('show');
      $(`one`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.result = this.result2;
      this.heading2=true;
      this.heading1=false;
      this.heading3=false;
      this.heading4=false;
      this.heading5=false;
    }
    if (id === '3') {
      $(`three`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.result = this.result3;
      this.heading3=true;
      this.heading1=false;
      this.heading2=false;
      this.heading4=false;
      this.heading5=false;
    }
    if (id === '4') {
      $(`four`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`five`).removeClass('show');
      this.result = this.result4;
      this.heading3=false;
      this.heading1=false;
      this.heading2=false;
      this.heading4=true;
      this.heading5=false;
    }
    if (id === '5') {
      $(`five`).addClass('show');
      $(`one`).removeClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`three`).removeClass('show');
      this.result = this.result5;
      this.heading3=false;
      this.heading1=false;
      this.heading2=false;
      this.heading4=false;
      this.heading5=true;
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
