<div id="best">
  <div id="showcase">
    <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
  </div>
  <div id="heading">
    <h1>IQAC</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">IQAC</a></li>
        <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">Best Practices</li>
      </ol>
    </nav>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <!-- <a routerLink="/naac/ssr" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a> -->
        <nav id="secondary-navigation" *ngIf="showSideMenu">
          <!-- Header -->
          <header class="header">
            <h5>Best Practices</h5>
          </header>
          <!-- /Header -->
          <ul>
            <li routerLinkActive="active">
              <a routerLink="/iqac/best-practices/2022-23" (click)="checkRoute()">2022-23</a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/iqac/best-practices/2021-22" (click)="checkRoute()">2021-22</a>
            </li>

          </ul>
        </nav>
      </div>
      <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
