<div id="showcase">
    <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
  </div>
  <div id="heading">
    <h1 style="text-transform: capitalize;">{{(routeName.length>25) ? (routeName | slice:0:25)+'...' : (routeName)}}</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
          <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
        </ol>
      </nav>
  </div>
  
  <div id="important">
      <div class="container-fluid" *ngIf="showImportant">
          <section class="important section">
              <div class="container1">
                <div class="row1">
                  <div class="section-title padd-15">
                    <h2>Important Links</h2>
                  </div>
                </div>
                <div class="row1"  >
                  <div class="important-item padd-15 mx-auto" *ngFor="let important of importants">
                    <div class="important-item-inner shadow-dark">
                      <div class="important-img">
                        <img [src]="important.imgUrl" alt="important">
                        <div class="important-date">
                          {{important.author}}
                        </div>
                      </div>
                      <div class="important-info">
                        <h4 class="important-title">{{important.title}}</h4>
                       
                          <p class="important-description" [innerHtml]="important.shortDes"></p>
                        <p class="important-tags"><a [routerLink]="['/important-links',important.$key]" (click)="hideImportant()"> Read More...</a></p>
                      </div>
                    </div>
                  </div> 
                 
                </div>
  
              
              </div>
            </section>
            <!-- End Important -->
  
           
      </div>
      <div  *ngIf="!showImportant">
          <router-outlet></router-outlet>
      </div>
  </div>
  
  