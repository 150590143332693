<div id="Khelo">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">Khelo India Center</h4>
                    <div class="card-body text-justify">
                        <p class="lead text-justify">
                            The importance of sports and fitness in one's life cannot be undermined.
                             Sports inculcates team spirit, develops strategic & analytical thinking, leadership skills, goal setting and risk taking.
                             A fit and healthy individual leads to an equally healthy society and strong nation. Sports is an extremely.
                        </p>
                        <p class="lead text-justify">
                            The importance of sports and fitness in one's life is invaluable.
                             Playing sports inculcates team spirit, develops strategic & analytical thinking, leadership skills, goal setting and risk taking.
                              A fit and healthy individual leads to an equally healthy society and strong nation.
                               Sports is an extremely important component for the overall development of our nation.
                                India, in the last few years has made steady progress in the field of sports.
                                 This tremendous potential needs to be showcased at a global platform.
                                  It's time we inspire young talent, give them top-notch infrastructure and training of the highest level.
                             We need to inculcate a strong spirit of participation in sports that enables.
                        </p>
                        <!-- <p class="lead text-justify">
                            The reading room equipped with large number of competitive examination books such as current affairs, General knowledge, periodicals, Newspaper etc.
                             It has been setup of competitive exam books like UPSC, MPSC, and Banking examination.
                              Students who would like to prepare for the MPSC, UPSC, Banking and Staff Selection Commission and other examinations can register their name in the centre.
                             Students are always encouraged to use this facility.
                              Competitive Exam Guidance Centre.
                        </p> -->
                        <p class="lead text-justify">
                           The college is an exclusive platform for Khelo India Center with special emphasis to Weightlifting.
                        </p>
                        <div class="card-body text-justify">
                            <div id="kheloCarousel" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                  <li data-target="#kheloCarousel" data-slide-to="0" class="active"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="1"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="2"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="3"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="4"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="5"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="6"></li>
                                  <li data-target="#kheloCarousel" data-slide-to="7"></li>
                                </ol>
                                <div class="carousel-inner text-center py-5 px-3">
                                  <div class="carousel-item active">
                                    <img src="/assets/img/khelo/1.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/2.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item ">
                                    <img src="/assets/img/khelo/3.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/4.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/5.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/6.jpeg" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/7.JPG" alt="khelo" class="img-fluid rounded">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="/assets/img/khelo/8.JPG" alt="khelo" class="img-fluid rounded">
                                  </div>
                                </div>
                                <a class="carousel-control-prev" href="#kheloCarousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#kheloCarousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                            <!-- <div class="row text-center">
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/1.jpeg" alt="khelo" class="img-fluid"></div>
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/2.jpeg" alt="khelo" class="img-fluid"></div>
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/3.jpeg" alt="khelo" class="img-fluid"></div>
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/4.jpeg" alt="khelo" class="img-fluid"></div>
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/5.jpeg" alt="khelo" class="img-fluid"></div>
                                <div class="col-sm-12 col-md-6 mt-3"><img src="/assets/img/khelo/6.jpeg" alt="khelo" class="img-fluid"></div>
                               
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div>