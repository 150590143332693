import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.scss']
})
export class PlacementComponent implements OnInit {
  companies:{img:string}[]=[
    {img:'/assets/img/hiring/1.png'},
    {img:'/assets/img/hiring/2.png'},
    {img:'/assets/img/hiring/3.png'},
    {img:'/assets/img/hiring/4.png'},
    {img:'/assets/img/hiring/5.png'},
    {img:'/assets/img/hiring/6.png'},
    {img:'/assets/img/hiring/7.png'},
    {img:'/assets/img/hiring/8.png'},
    {img:'/assets/img/hiring/9.png'},
    {img:'/assets/img/hiring/10.png'},
    {img:'/assets/img/hiring/11.png'},
    {img:'/assets/img/hiring/12.png'},
    {img:'/assets/img/hiring/13.png'},
    {img:'/assets/img/hiring/14.png'},
    {img:'/assets/img/hiring/15.png'},
    {img:'/assets/img/hiring/16.png'}
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
