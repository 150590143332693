<div id="ssr">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>NAAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a >Home</a></li>
              <li class="breadcrumb-item"><a >NAAC</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page" routerLink="/naac/ssr" (click)="checkRoute()">SSR</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>SSR</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/self-study-report" (click)="checkRoute()">SELF STUDY REPORT(CYCLE III)</a>
                        </li>
                        <!-- routerLink="/naac/ssr/executive" (click)="checkRoute()" -->
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/profile" (click)="checkRoute()">PROFILE</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/extended-profile" (click)="checkRoute()">EXTENDED PROFILE</a>
                        </li> -->
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria1" (click)="checkRoute()">CRITERIA 1</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria2" (click)="checkRoute()">CRITERIA 2</a>
                        </li>
                        <li routerLinkActive="active" (click)="checkRoute()">
                            <a routerLink="/naac/ssr/criteria3">CRITERIA 3</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria4" (click)="checkRoute()">CRITERIA 4</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria5" (click)="checkRoute()">CRITERIA 5</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria6" (click)="checkRoute()">CRITERIA 6</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/criteria7" (click)="checkRoute()">CRITERIA 7</a>
                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/naac/ssr/conclusion" (click)="checkRoute()">CONCLUSION</a>
                        </li> -->
                       
                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!-- <div class="container mt-5" >
        <div class="card shadow-lg colorBlue mx-lg-5">
            <div class="row">
                <div class="col-12 text-center mt-5">

                    <h2>SSR</h2>
                   
                </div>
                <div class="card-body text-justify">
                    
                </div>
            </div>
            
        </div>
    </div> -->
</div>