import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CentreComponent } from 'src/app/view/research/centre/centre.component';
import { FdpComponent } from 'src/app/view/research/fdp/fdp.component';
import { GuestComponent } from 'src/app/view/research/guest/guest.component';
import { MajorProjectComponent } from 'src/app/view/research/major-project/major-project.component';
import { MinorProjectComponent } from 'src/app/view/research/minor-project/minor-project.component';
import { PublicationComponent } from 'src/app/view/research/publication/publication.component';
import { ResearchComponent } from 'src/app/view/research/research.component';
import { SeminarComponent } from 'src/app/view/research/seminar/seminar.component';
import { StudentProjectComponent } from 'src/app/view/research/student-project/student-project.component';
import { WorkshopComponent } from 'src/app/view/research/workshop/workshop.component';
import { ResearchRoutingModule } from './research-routing.module';
import { NotFoundModule } from '../not-found/not-found/not-found.module';
import { ScholarshipComponent } from 'src/app/view/research/scholarship/scholarship.component';
import { CollaborativeComponent } from 'src/app/view/research/collaborative/collaborative.component';
import { ResultComponent } from 'src/app/view/research/result/result.component';
import { PoCoComponent } from 'src/app/view/research/po-co/po-co.component';
import { WTwooneTwotwoComponent } from 'src/app/view/research/workshop/w-twoone-twotwo/w-twoone-twotwo.component';
import { WTwotwoTwothreeComponent } from 'src/app/view/research/workshop/w-twotwo-twothree/w-twotwo-twothree.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { SpTwooneTwotwoComponent } from 'src/app/view/research/student-project/sp-twoone-twotwo/sp-twoone-twotwo.component';
import { SpTwotwoTwothreeComponent } from 'src/app/view/research/student-project/sp-twotwo-twothree/sp-twotwo-twothree.component';
import { MouComponent } from 'src/app/view/research/mou/mou.component';
import { ScTwooneTwotwoComponent } from 'src/app/view/research/scholarship/sc-twoone-twotwo/sc-twoone-twotwo.component';
import { ScTwotwoTwothreeComponent } from 'src/app/view/research/scholarship/sc-twotwo-twothree/sc-twotwo-twothree.component';



@NgModule({
  declarations: [
    ResearchComponent,
    CentreComponent,
    MajorProjectComponent,
    MinorProjectComponent,
    StudentProjectComponent,
    PublicationComponent,
    SeminarComponent,
    WorkshopComponent,
    GuestComponent,
    FdpComponent,
    ScholarshipComponent,
    CollaborativeComponent,
    ResultComponent,
    PoCoComponent,
    WTwooneTwotwoComponent,
    WTwotwoTwothreeComponent,
    SpTwooneTwotwoComponent,
    SpTwotwoTwothreeComponent,
    MouComponent,
    ScTwooneTwotwoComponent,
    ScTwotwoTwothreeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ResearchRoutingModule,
    NotFoundModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule
  ]
})
export class ResearchModule { }
