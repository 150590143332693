import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';
import { PathNameService } from 'src/app/services/pathName/path-name.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit,OnDestroy {
  courseName='';
  sub:Subscription
  
  fullWidth=false;
  showSideMenu = true;
  constructor(
    private showcaseService:ShowcaseService,
    private pathNameService:PathNameService
  ) {
    showcaseService.hideShowcase();
   }

  ngOnInit(): void {
    // this.checkRoute();
    setTimeout(()=>{
      this.splitUrl();
    });
    this.sub=this.pathNameService.urlChange.subscribe(res=>{
      this.courseName=res;
    });
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
  splitUrl(){
    let url=location.pathname.split('/');
    this.courseName=url[url.length-1];
    console.log(url);
    if(url.length === 2 || (url.length === 3 && (url[2]=== 'arts' || url[2]=== 'science' || url[2]=== 'commerce' || url[2]=== 'physical' || url[2]=== 'ugc' || url[2]=== 'add-on' || url[2]=== 'soft-skill' || url[2]=== 'sec' || url[2]=== 'vac' || url[2]=== 'aec' || url[2]=== 'mdc'))){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else if(url.length === 3 && (url[2]=== 'lesson-plan' || url[2]=== 'education' || url[2]=== 'elective-courses') ){
      this.showSideMenu=false;
      this.fullWidth=true;
    }
    // else{
    //   this.showSideMenu=false;
    //   this.fullWidth=true;
    // }
  }
  changeCourse(name:string){
    this.courseName=name;
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
