<div id="small">
    <div class="container-fluid">
        <ul>
            <li class="px-5">
                <a routerLink="/menu-detail/placement">
                  <i class="fas fa-briefcase"></i>
                </a> 
                <p class="text-center font-weight-bold">Placement</p>
              </li>
            <li class="px-5">
              <a href="#">
                <i class="bi bi-file-spreadsheet"></i>
              </a>
              <p class="text-center font-weight-bold">Results</p>
              </li>
              <li class="px-5">
                <a routerLink="/student-corner/prospectus">
                  <i class="bi bi-journals"></i>
                  
                </a>
                <p class="text-center font-weight-bold">Prospectus</p>
                
              </li>
            <li class="px-5">
              <a href="#">
                <i class="fas fa-graduation-cap"></i>
              </a>
              <p class="text-center font-weight-bold">Schlorship</p>
            </li>
            
          </ul>
    </div>
</div>

