<div id="human">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Faculty</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item"><a routerLink="/faculty/teaching-staff">Faculty</a></li>
              <li class="breadcrumb-item"><a >Humanities</a></li>
              <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">{{routeName}}</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Humanities</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/english"
                            (click)="changeRoute('Department Of English')">Department Of English</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/economics" (click)="changeRoute('Department Of Economics')">Department Of Economics</a>
                        </li>
                       
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/education-bed" (click)="changeRoute('Department of Education (B.Ed.)')">Department of Education (B.Ed.)</a>
                        </li> -->
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/geography" (click)="changeRoute('Department Of Geography')">Department Of Geography</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/hindi" (click)="changeRoute('Department Of Hindi')">Department Of Hindi</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/history" (click)="changeRoute('Department Of History')">Department Of History</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/political-science"  (click)="changeRoute('Department Of Political Science')">Department Of Political Science</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/public-administration"  (click)="changeRoute('Department Of Public Administration')">Department Of Public Administration</a>
                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/education"
                            (click)="changeRoute('Department of Physical Education (B.P.Ed.)')">Department of Physical Education (B.P.Ed.)</a>
                        </li> -->
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/punjabi" (click)="changeRoute('Department Of Punjabi')">Department Of Punjabi</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/social-work" (click)="changeRoute('Department Of Social Work')">Department Of Social Work</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/sanskirt" (click)="changeRoute('Department Of Sanskirt')">Department Of Sanskirt</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/faculty/humanities/music" (click)="changeRoute('Department Of Music')">Department Of Music</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>