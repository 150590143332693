import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { Important } from 'src/app/models/important.model';

@Injectable({
  providedIn: 'root'
})
export class ImportantService {

  
  importantList:AngularFireList<any>;
  important:Important[]=[];

  constructor(private firebase:AngularFireDatabase) { }
  getImportantList(){
    this.importantList= this.firebase.list('importantDetails');  

  }
  setImportant(importants:any){
    this.important=importants;
   
  }
  
  getTitle(id:string){
    const importantItem = this.important.find(b=>b.$key===id);
    return importantItem.title;
  }
  searchImportant(id:string){
   
    for(let importantItem of this.important){
      if(importantItem.$key===id){
        
        return importantItem.description;
      }
    }
  }
}
