<div id="gender">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card shadow-lg">
            <h4 class="card-title text-center">Gender Equality</h4>
            <div class="card-body text-justify">
              <div class="ranked">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Title</th>
                      <th scope="col">URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Facilities for Girl Students</td>
                      <td class="link"><a href="" target="_blank"></a></td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Gender Equality</td>
                      <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/genderequality"
                          target="_blank">https://gnkc.ac.in/menu-detail/naac/genderequality</a></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Gender Equality Action Plan</td>
                      <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/genderequality-actionplan"
                          target="_blank">https://gnkc.ac.in/menu-detail/naac/genderequality-actionplan</a></td>
                    </tr>
  
                    <!-- <tr>
                                  <th scope="row">4</th>
                                  <td>The promotion of gender equity during the last five years</td>
                                  <td class="link"><a href="https://gnkc.ac.in/menu-detail/naac/promotion-genderequity-during-the-last-five-years" target="_blank">https://gnkc.ac.in/menu-detail/naac/promotion-genderequity-during-the-last-five-years</a></td>
                                </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
  
    </div>
  </div>