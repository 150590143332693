// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  otpURL:'',
  hostName:'smtp.elasticemail.com',
  userName:'gnkcokiedokiepay@gmail.com',
  password:'35DE538AFBCF7A983376808390CED43ED47E',
  firebaseConfig : {
    apiKey: "AIzaSyDOXArJsrkelQYmOHCFgPN5KZwiKLck3Kw",
    authDomain: "gnkc-ynr.firebaseapp.com",
    databaseURL: "https://gnkc-ynr-default-rtdb.firebaseio.com",
    projectId: "gnkc-ynr",
    storageBucket: "gnkc-ynr.appspot.com",
    messagingSenderId: "817872788741",
    appId: "1:817872788741:web:3388be468736c505936b6c",
    measurementId: "G-5505KPHGQ6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
