import { Component,  HostListener,  OnInit } from '@angular/core';

@Component({
  selector: 'app-ssr',
  templateUrl: './ssr.component.html',
  styleUrls: ['./ssr.component.scss']
})
export class SsrComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu=true;
  constructor() {
   }

  ngOnInit(): void {
    this.checkRoute();
  }
  // ngDoCheck(): void {
  //   this.checkRoute();
  //   console.log('inside')
  // }

  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length === 3 || (url.length === 4 && url[3]==='self-study-report')){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

}
