import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-mou',
  templateUrl: './mou.component.html',
  styleUrls: ['./mou.component.scss']
})
export class MouComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/138wpZroOKAi-IjOgxGBTEhMTr5pZx389/preview', 
    'https://drive.google.com/file/d/1SiEMFs-Iz5hjjzJ3md7gUlB7sDGMSgR3/preview' ,
    'https://drive.google.com/file/d/1lCDjzM7Yt4PMx5GHidtV6hpIOhLbUXGr/preview', 
    'https://drive.google.com/file/d/1jFbMzDHwmM3tKv9DqJzkS5lBbj0ardtY/preview',
    'https://drive.google.com/file/d/11U7p1LNW8copa-Po4fITMqV7tJkCVZcc/preview',
    'https://drive.google.com/file/d/1snC96yK9988_lCMedM22M8rxG_cIc60G/preview',
    'https://drive.google.com/file/d/1RGWc4nIJvgROeQIXJIRGZ0Uzts1YU3I4/preview',
    'https://drive.google.com/file/d/1L2_c6EOom2ny9rm7M3gvp9IlX8A1147v/preview',];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[5];
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    
  }
  hideLoader() {
    this.isLoading = false;
  }
}
