import { Component, OnInit } from '@angular/core';
import { ShowcaseService } from 'src/app/services/header-showcase/showcase.service';

@Component({
  selector: 'app-naac',
  templateUrl: './naac.component.html',
  styleUrls: ['./naac.component.scss']
})
export class NaacComponent implements OnInit {

  constructor(private showcaseService:ShowcaseService) {
    showcaseService.hideShowcase();
   }

  ngOnInit(): void {
  }
  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }

}
