<div class="map h-100">
  <p>Route Map</p>
  <a href="https://goo.gl/maps/b7MW1RMGzrJbBnhE7" target="_blank"><img src="/assets/img/footer/routeMap.jpeg" alt="map" class="img-fluid"></a>
  <!-- <iframe
    width="100%"
    height="100%"
    frameborder="0" style="border:0;border-radius: 10px;z-index: 1100;"
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD6JumlPFLkyQHY2TGrGOnebR48Wk4Iw3w
      &q=GURU+NANAK+KHALSA+College+East+Bhatia+Nagar+ Yamuna+Nagar+  135001" allowfullscreen>
  </iframe> -->
</div>