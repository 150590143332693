<!-- <div id="vismad">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <h4 class="card-title text-center">VISMAD</h4>
                    <div class="card-body">
                        <h5>THE COLLEGE MAGAZINE</h5>
                        <hr>
                        <p class="lead text-justify">The college Magazine ‘Vismad’, provides an opportunity to the students having a flair for writing.
                             It is published once a year.
                              The students are encouraged to contribute articles to the Magazine in English, Hindi Punjabi and Sanskrit.
                               Each Section has a student Editor working under the staff Editor. Students learn the art of writing under the guidance of the Editorial Board.
                        </p>
                         <div class="row">
                            <div class="col-sm-6 col-md-6 text-center mt-3">
                                <img src="/assets/img/vismad/1.jpeg" alt="vismad" class="img-fluid rounded">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

       
    </div>
</div> -->


<div id="vismad" class="container-fluid ">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>VISMAD</h5>
                </header>
                <ul>
                    <li class="first">
                        <a (click)="changeRoute('2022-23')">2022-23</a>
                    </li>
                    <li class="second">
                        <a (click)="changeRoute('2020-21')">2020-21</a>
                    </li>

                </ul>
            </nav>
        </div>
        <div class="col-md-9" *ngIf="showPdf">
            <div id="sports">
                <div class="container-fluid pdf">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <div class="container-fluid d-flex justify-content-center align-items-center h-100"
                                    id="loader" *ngIf="isLoading">
                                    <app-loader></app-loader>
                                </div>
                                <div id="detail" class="text-center">
                                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"
                                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9" *ngIf="!showPdf">
            <div id="sports">
                <div class="container-fluid pdf">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <div class="container-fluid d-flex justify-content-center align-items-center h-100"
                                    id="loader" *ngIf="isLoading">
                                    <app-loader></app-loader>
                                </div>
                                <div id="detail" class="text-center">
                                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"
                                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>

                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>




    </div>
</div>