<div id="not-found">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Not Found</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page" style="text-transform: capitalize;">Not Found</li>
            </ol>
          </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/not-found/1.jpg" alt="Page Not Found" class="img-fluid w-100">
            </div>
            <div class="col-12 detail d-flex flex-column justify-content-end align-items-center">
                <h4>Page is Under Maintenance !</h4>
            </div>
        </div>
        
    </div>
</div>