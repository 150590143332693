import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c32-twenty',
  templateUrl: './c32-twenty.component.html',
  styleUrls: ['./c32-twenty.component.scss']
})
export class C32TwentyComponent implements OnInit {
  c3321Url='https://gnkc.ac.in/assets/pdf/NAAC/DVV/';

  result:{title:string,link:string}[]=[
    {
        title: 'One Day Online National Workshop on Intellectual Property Rights: Industry-Academia Interface',
        link: this.c3321Url + '4_4.12.20-IPR-ACADEMIA-INTERFACE-Final.pdf'
    },
    {
        title: 'One Week State Level FDP on \'How to Prepare SSR and AQAR for NAAC\'',
        link: this.c3321Url + '5_27.01.2021-FDP-on-How-to-Prepare-SSR-DVV.pdf'
    },
    {
        title: 'One Day Webinar on World Earth Day',
        link: this.c3321Url + '6_22.04.21-Webinar-on-World-Earth-Day-DVV.pdf'
    },
    {
        title: 'One Day National Webinar on Promoting Academic Integrity & Prevention of Plagiarism in Higher Education Institutions',
        link: this.c3321Url + '7_30.4.21-Promoting-Academic-Integrity-DVV.pdf'
    },
    {
        title: 'One Day National Webinar on ENTREPRENEURSHIP- As a Career Opportunity',
        link: this.c3321Url + '8_18.06.21-Entrepreneurship-as-a-career-opportunity-DVV.pdf'
    }
]
  constructor() { }

  ngOnInit(): void {
  }

}
