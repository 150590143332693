import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twozero-twoone',
  templateUrl: './twozero-twoone.component.html',
  styleUrls: ['./twozero-twoone.component.scss']
})
export class TwozeroTwooneComponent implements OnInit {
  c343Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 3/3.4.3.1_Extension_Activities_Reports and Attendance_Final_SSR_Folder (2)/3.4.3 2020-21/';

  result:{title:string,link:string}[]=[
    {
      title:'VOLUNTARY SERVICE DURING COVID -19',
      link:this.c343Url + '32_NCC_VOLUNTARY SERVICE DURING COVID -19.pdf'
    },
    {
      title:'ARMED FORCED FLAG DAY',
      link:this.c343Url + '33_NCC_ARMED FORCED FLAG DAY.pdf'
    },
    {
      title:'SWATCHHTA PAKHWARA',
      link:this.c343Url + '34_NCC_SWATCHHTA PAKHWARA.pdf'
    },
    {
      title:'CLEANLINESS DRIVE',
      link:this.c343Url + '35_NCC_CLEANLINESS DRIVE.pdf'
    },
    {
      title:'NATIONAL GIRLS HEALTH SERVICE WEEK',
      link:this.c343Url + '36_NCC_NATIONAL GIRLS HEALTH SERVICE WEEK.pdf'
    },
    {
      title:'ROAD SAFETY RALLY',
      link:this.c343Url + '37_NCC_ROAD SAFETY RALLY.pdf'
    },
    {
      title:'DONATION TO SAHEED NISHANT SHARMA',
      link:this.c343Url + '38_NCC_DONATION TO SAHEED NISHANT SHARMA.pdf'
    },
    {
      title:'NO PLASTIC USE ABHIYAN',
      link:this.c343Url + '39_NCC_NO PLASTIC USE ABHIYAN.pdf'
    },
    {
      title:'NASHA MUKTI DIWAS',
      link:this.c343Url + '40_NCC_NASHA MUKTI DIWAS.pdf'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
