import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-bp-twotwo-twothree',
  templateUrl: './bp-twotwo-twothree.component.html',
  styleUrls: ['./bp-twotwo-twothree.component.scss']
})
export class BpTwotwoTwothreeComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1wGQN6da9G-ZgR8pW4DiuPuRfONwsEk4F/preview',
    'https://drive.google.com/file/d/1EZULoJnmyZuPBsO6Pa1mt4Ogksga3tDH/preview'];
  src: string;
  isLoading = true;
  show22 = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      this.src = this.pdfSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
