import { Component, OnInit } from '@angular/core';
import { StarService } from 'src/app/services/star/star.service';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.scss']
})
export class AlumniComponent implements OnInit {
  alumni:{name:string,degree:string,img:string}[];
  constructor(private starService:StarService) { }

  ngOnInit(): void {
    this.alumni=this.starService.getAlumni();
  }

}
