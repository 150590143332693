import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ct-two-one-two',
  templateUrl: './ct-two-one-two.component.html',
  styleUrls: ['./ct-two-one-two.component.scss']
})
export class CtTwoOneTwoComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/NAAC/DVV/2.1.2_Complete data template_1.pdf',
    '/assets/pdf/NAAC/DVV/2.1.2 Minority Certificate_6.pdf',
    '/assets/pdf/NAAC/DVV/2.1.2_Minority Certificate and guidelines-7.pdf',
    '/assets/pdf/NAAC/DVV/2.1.2_Minority Certificate and guidelines-8.pdf',
    '/assets/pdf/NAAC/DVV/2.1.2 Admission list of students indicating their category.pdf'];
  src: string;

  isLoading = true;
  heading1 = false;
  heading2 = false;
  heading3 = false;
  heading4 = false;
  heading5 = false;
  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(id: string) {
    this.isLoading = true;
    if (id === '1') {
      $(`one`).addClass('show');
      $(`two`).removeClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = true;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[0];
    }
    if (id === '2') {
      $(`one`).removeClass('show');
      $(`two`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = true;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[1];
    }
    if (id === '3') {
      $(`one`).removeClass('show');
      $(`three`).addClass('show');
      $(`two`).removeClass('show');
      $(`four`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = true;
      this.heading4 = false;
      this.heading5 = false;
      this.src = this.pdfSrc[2];
    }
    if (id === '4') {
      $(`one`).removeClass('show');
      $(`four`).addClass('show');
      $(`three`).removeClass('show');
      $(`two`).removeClass('show');
      $(`five`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = true;
      this.heading5 = false;
      this.src = this.pdfSrc[3];
    }
    if (id === '5') {
      $(`one`).removeClass('show');
      $(`five`).addClass('show');
      $(`three`).removeClass('show');
      $(`four`).removeClass('show');
      $(`two`).removeClass('show');
      this.heading1 = false;
      this.heading2 = false;
      this.heading3 = false;
      this.heading4 = false;
      this.heading5 = true;
      this.src = this.pdfSrc[4];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }

}
