import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowCareerService {
  private showCareer:boolean=true;
  careerChange=new BehaviorSubject<boolean>(true);
  constructor() { }
  show(){
    this.showCareer=true;
    this.careerChange.next(true);
  }
  hide(){
    this.showCareer=false;
    this.careerChange.next(false);
  }
}
