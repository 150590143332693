<div id="heading">
    <h1>Guru Nanak Khalsa College Managing Committee, Yamunanagar</h1>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">About Us</a></li>
          <li class="breadcrumb-item active" aria-current="page">Guru Nanak Khalsa College Managing Committee, Yamunanagar</li>
        </ol>
      </nav>
</div>
<div id="governer" class="mt-4">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="card-title">List of Members of Guru Nanak Khalsa College Committee, Yamuna Nagar</h2>
                <hr class="pb-1 rounded">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4" *ngFor="let board of boards;">
                <a class="card1" href="#">
                    <h3>{{board.name}}</h3>
                    <p class="small">{{board.designation}}</p>
                    <div class="go-corner" href="#">
                      <div class="go-arrow">
                        →
                      </div>
                    </div>
                  </a>
            </div>
        </div>
    </div>
</div>

<!-- <div id="governing">
    <div class="container-fluid">
        <div class="card" >
            <h4 class="">S. Randeep Singh Jauhar</h4>
            <h4 class="">President</h4>
            <div class="card-body d-flex">
                <i class="bi bi-geo-alt"></i>
                <p class="ml-2">M/s Jamna Auto Ind. Ltd., 2, Park Lane, Kishan Garh, Vasant Kunj, New Delhi</p>
            </div>
        </div>
    </div>
    <div class="container-fluid align-items-end">
        <div class="card " data-scroll>
            <h4 class="">Major Rajinder Singh Bhatti</h4>
            <h4 class="">Vice President</h4>
            <div class="card-body d-flex">
                <i class="bi bi-geo-alt"></i>
                <p class="ml-2">H.No. 85, Santpura Road, Model Town, Yamunanagar</p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="card " data-scroll>
            <h4 class="">S. Balwant Singh Banga</h4>
            <h4 class="">General Secretary</h4>
            <div class="card-body d-flex">
                <i class="bi bi-geo-alt"></i>
                <p class="ml-2">62,Shastri Colony, Yamuna Nagar</p>
            </div>
        </div>
    </div>
    <div class="container-fluid align-items-end">
        <div class="card " data-scroll>
            <h4 class="">S. Amardeep Singh</h4>
            <h4 class="">Treasurer</h4>
            <div class="card-body d-flex">
                <i class="bi bi-geo-alt"></i>
                <p class="ml-2">H.No. 1525, Sector 17, HUDA, Jagadhri</p>
            </div>
        </div>
    </div>
   
</div> -->
<!-- animatable bounceInLeft -->